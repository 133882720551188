import React from "react";
import { Box, Button, Link, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import { SolveError } from "fond/types";
import { Modal } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    header: {
      fontWeight: 500,
    },
    modalHeader: {},
    modalContent: {},
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  error: SolveError;
  open?: boolean;
  onClose?(): void;
}

const SolveErrorModal: React.FC<IProps> = ({ classes, error, open = true, onClose }: IProps) => {
  const rows = [
    {
      name: "Code",
      value: error.id,
    },
    {
      name: "Label",
      value: error.label || "-",
    },
    {
      name: "Summary",
      value: error.summary || "-",
    },
    {
      name: "Description",
      value: error.description ? (
        <>{error.description?.split("\\n").map((line) => (line === "" ? <br /> : <Box key={error.id}>{line}</Box>))}</>
      ) : (
        "-"
      ),
    },
    {
      name: "Knowledge base",
      value: error.link ? (
        <Link color="primary" href={error.link} target="_blank">
          {error.link}
        </Link>
      ) : (
        "-"
      ),
    },
  ];

  return (
    <Modal
      data-testid="solve-error-modal"
      open={Boolean(open)}
      onClose={onClose}
      DialogTitleProps={{ className: classes.modalHeader }}
      header="Error Details"
      DialogContentProps={{ className: classes.modalContent }}
      content={
        <Box height="100%">
          <TableContainer>
            <Table aria-label="Error Information" size="small">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row" className={classes.header}>
                      {`${row.name}:`}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      actions={
        <Button key="error_close" data-testid="solve-error-modal-close-btn" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

SolveErrorModal.displayName = "SolveErrorModal";
export default withStyles(customStyles)(SolveErrorModal);
