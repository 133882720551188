import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Report } from "fond/types";
import { Modal } from "fond/widgets";

interface IProps {
  report: Report;
  closeModalCallback(): void;
}

/**
 * A dialog to:
 *   Warn users of the consequences of report deletion.
 *   Allow them to cancel the deletion.
 *   Seek confirmation to delete.
 */
const RemoveReportDialog: React.FC<IProps> = ({ report, closeModalCallback }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [deleteReport] = useDeleteReportMutation();

  /**
   * Handles the deletion of the report.
   */
  const handleOnDelete = () => {
    setSaving(true);

    deleteReport(report)
      .unwrap()
      .then(() => {
        mixpanel.track("Report", "Delete", "Deleted report", { reportId: report.ID });
        enqueueSnackbar("Report deleted.");
      })
      .catch(() => {
        enqueueSnackbar("Report deletion failed. Please try again...", { variant: "error" });
      })
      .finally(() => {
        setSaving(false);
        closeModalCallback();
      });
  };

  return (
    <Modal
      open
      header="Delete report?"
      DialogTitleProps={{ id: "alert-dialog-title" }}
      data-testid="delete-report-modal"
      DialogContentProps={{ id: "alert-dialog-description" }}
      content={
        <Typography>
          Are you sure you wish to delete the report <strong>{report.Name}</strong>
          ?
          <br />
          This will also affect other users this report is shared with.
        </Typography>
      }
      actions={
        <>
          <Button color="primary" data-testid="cancel-report-deletion" onClick={closeModalCallback} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleOnDelete}
            autoFocus
            data-testid="confirm-report-deletion"
            loading={saving}
          >
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default RemoveReportDialog;
