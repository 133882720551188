import React from "react";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.biarri.primary.midnight,
  },
}));

interface IProps {
  children: React.ReactNode;
}

const SectionHeading: React.FC<IProps> = ({ children }: IProps) => {
  const classes = useStyles();
  return (
    <Box mb={2} width="100%">
      <Typography variant="h4" className={classes.root}>
        {children}
      </Typography>
    </Box>
  );
};

export default SectionHeading;
