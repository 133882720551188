import { Box } from "@mui/material";
import { common } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { ImportIcon } from "fond/svg_icons";

/**
 * The modal logo style.
 */
export interface StyledImportIconProps {}

export const StyledImportIcon = styled(ImportIcon)<StyledImportIconProps>(({ theme }) => ({
  fill: common.white,
}));

export const SetupContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  textAlign: "center",
  alignItems: "center",
}));
