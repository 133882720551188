import React, { useMemo, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Button, FormHelperText, ListItemIcon, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import { convertFromRaw, RawDraftContentState, RawDraftEntity } from "draft-js";
import _ from "lodash";

import { LoadingButton } from "ui";

import { useGetUserMentionSuggestionsQuery } from "fond/api";
import { EditorField, SelectField } from "fond/form/fields";
import { CommentImportance, Store } from "fond/types";
import { StyleOverrideProp } from "fond/utils/draftEditor";
import { isEmailDeliverable } from "fond/utils/email";
import { SupportLink } from "fond/widgets";

import { renderImportanceIcon } from "./Comment";
import NewUserNotification from "./NewUserNotification";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    fontSize: "0.8rem",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  label: {
    fontSize: "0.8rem",
    color: grey[700],
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  warning: {
    maxWidth: 290,
    padding: theme.spacing(1),
  },
}));

export interface IFormData {
  importance: CommentImportance | null;
  rawContent: RawDraftContentState;
}

interface User {
  id: string;
  name: string;
}

interface IProps {
  /**
   * Determines if the Editor should take focus automatically on mount
   */
  autoFocus?: boolean;
  /**
   * Determines if the comment is a reply or not.
   */
  isReply?: boolean;
  /**
   * Flag indicating if the related feature has been created.
   */
  hasFeature: boolean;
  /**
   * The custom styles applied to the editor.  Can be used to set minHeight
   */
  editorStyle?: StyleOverrideProp;
  /**
   * The initialValues for the form
   */
  initialValues?: Partial<IFormData>;
  /**
   * Placeholder content for the TextField;
   */
  placeholder?: string;
  /**
   * Text applied to the submit button of the form
   */
  submitText?: string;
  /**
   * Callback for when the user cancels the form
   */
  onCancel(): void;
  /**
   * Callback for when the user submits the form
   */
  onSubmit(values: IFormData): Promise<void>;

  /**
   * The ID of the current project.
   */
  projectId: string;
}

const AddCommentForm: React.FC<IProps> = ({
  autoFocus = false,
  isReply = false,
  editorStyle,
  hasFeature,
  initialValues,
  placeholder = "Add your comment...",
  submitText = "Comment",
  onCancel,
  onSubmit,
  projectId,
}: IProps) => {
  const { data } = useGetUserMentionSuggestionsQuery({ projectId });
  const classes = useStyles();
  const [undeliverableEmail, setUndeliverableEmail] = useState<string[]>([]);
  const [validating, setValidating] = useState(false);
  const [processing, setProcessing] = useState(false);

  const mentionData: Array<{ id: string; name: string }> = useMemo(
    () =>
      (data ?? []).map((suggestion) => ({
        id: suggestion.User.ID,
        name: suggestion.User.Email,
      })),
    [data]
  );

  const sharedUsers = useMemo(
    () =>
      (data ?? [])
        .filter((suggestion) => suggestion.Shared)
        .map((suggestion) => ({
          id: suggestion.User.ID,
          name: suggestion.User.Email,
        })),
    [data]
  );

  const tags = useSelector((state: Store) =>
    state.comments.tags.map((tag) => ({
      name: tag,
    }))
  );

  const getStartAdornment = (importance?: CommentImportance) => {
    return <ListItemIcon className={classes.icon}>{renderImportanceIcon(importance)}</ListItemIcon>;
  };

  const getUndeliverableEmails = async (values: IFormData) => {
    setValidating(true);
    const userMentions = Object.values(values.rawContent.entityMap).filter(
      (value: { type: string }) => value.type === "mention" || value.type === "+mention"
    );
    const mentionedUsers = userMentions.map((newMention: RawDraftEntity) => newMention.data.mention.name);
    const sharedUserEmails = sharedUsers.map((user) => user.name);
    // Checking for the difference between the list of users who have access to the project and the mentioned users
    const newMentionedUsers = _.difference(mentionedUsers, sharedUserEmails);

    const invalidEmails: string[] = [];
    if (newMentionedUsers.length > 0) {
      const results = await Promise.all(newMentionedUsers.map((email) => isEmailDeliverable(email)));
      results.forEach((emailResult) => {
        if (!emailResult.deliverable) invalidEmails.push(emailResult.email);
      });
    }

    setUndeliverableEmail(invalidEmails);
    setValidating(false);
    return invalidEmails;
  };

  const submit = async (values: IFormData) => {
    setProcessing(true);
    const invalidEmails = await getUndeliverableEmails(values);
    if (invalidEmails.length === 0) {
      try {
        await onSubmit(values);
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <Form<IFormData>
      onSubmit={submit}
      initialValues={initialValues}
      render={({ values, handleSubmit, form, submitting, visited }) => {
        const isEmpty = values.rawContent ? !convertFromRaw(values.rawContent).hasText() : true;
        return (
          <form onSubmit={handleSubmit}>
            {!isReply && (
              <Box mb={1}>
                <SelectField
                  labelId="importance-label"
                  label="Importance"
                  id="importance-helper"
                  name="importance"
                  displayEmpty
                  fullWidth
                  variant="outlined"
                  labelClasses={{ root: classes.label }}
                  classes={{ select: classes.select }}
                  menuStyle={{ fontSize: "0.8rem" }}
                  options={[
                    {
                      value: "blocker",
                      displayValue: "Blocker",
                      startAdornment: <>{getStartAdornment("blocker")}</>,
                    },
                    {
                      value: "critical",
                      displayValue: "Critical",
                      startAdornment: <>{getStartAdornment("critical")}</>,
                    },
                    {
                      value: "major",
                      displayValue: "Major",
                      startAdornment: <>{getStartAdornment("major")}</>,
                    },
                    {
                      value: "minor",
                      displayValue: "Minor",
                      startAdornment: <>{getStartAdornment("minor")}</>,
                    },
                    {
                      value: "trivial",
                      displayValue: "Trivial",
                      startAdornment: <>{getStartAdornment("trivial")}</>,
                    },
                    {
                      value: "",
                      displayValue: "None",
                      startAdornment: <>{getStartAdornment()}</>,
                    },
                  ]}
                />
              </Box>
            )}
            <Box
              draggable
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <EditorField
                autoFocus={autoFocus}
                name="rawContent"
                placeholder={placeholder}
                style={editorStyle}
                mentionsData={mentionData}
                hashtagData={tags}
              />
            </Box>
            {undeliverableEmail.length > 0 && (
              <Box className={classes.warning}>
                <FormHelperText error data-testid="check-email-deliverability">
                  {`The following are not deliverable emails: ${undeliverableEmail.join(", ")}`}
                  <div>
                    If you think this is wrong, please <SupportLink />
                  </div>
                </FormHelperText>
              </Box>
            )}
            <FormSpy subscription={{ values: true }}>
              {(props: any) => <NewUserNotification users={sharedUsers} mentions={props.values.rawContent} />}
            </FormSpy>
            {visited?.rawContent && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Box mr={1}>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => {
                      form.reset();
                      onCancel();
                    }}
                    disabled={processing && !validating}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <LoadingButton
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={processing || isEmpty || !hasFeature}
                    loading={submitting || validating}
                    data-testid="add-comment-submit"
                  >
                    {submitText}
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </form>
        );
      }}
    />
  );
};

export default AddCommentForm;
