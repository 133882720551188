import { Box, Button, styled, Typography } from "@mui/material";

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubText = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.biarri.primary.grey};
` as typeof Typography;

export const CustomButton = styled(Button)`
  font-size: 0.75rem;
  padding: ${({ theme }) => `0 ${theme.spacing(0.5)}`};
`;
