import React, { useState } from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useDebouncedCallback } from "use-debounce";

import { selectVersionMlcId, useUpdateConfigAttributeMutation } from "fond/api";
import { getCurrentProject } from "fond/project";
import { LayerAttributes, LayerHeader } from "fond/styleEditor";
import { ConfigAttribute } from "fond/types";
import { LayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";

import { Tab, Tabs } from "../FieldSelection/fieldSelection.styles";
import { Form } from "../StyleEditor/styleEditor.styles";
import { Container, TabPanel } from "./layerEditor.styles";

type TabType = "attributes";

interface IProps {
  layerConfig: LayerConfig;
}

type IFormData = {
  Attributes: ConfigAttribute[];
};

const LayerEditor: React.FC<IProps> = ({ layerConfig }: IProps) => {
  const dispatch = useAppDispatch();
  const mapLayerConfigId = useAppSelector(selectVersionMlcId);
  const defaultSystemOfMeasurement = useAppSelector((state) => getCurrentProject(state.project).SystemOfMeasurement);
  const [updateAttribute] = useUpdateConfigAttributeMutation();

  const [tabValue, setTabValue] = useState<TabType>("attributes");
  const [selectedId, setSelectedId] = useState(layerConfig.Attributes?.[0]?.ID);

  const handleOnSubmit = useDebouncedCallback((values: IFormData) => {
    const modifiedAttribute = values.Attributes.find((attribute) => attribute.ID === selectedId);
    if (modifiedAttribute) {
      updateAttribute({ attribute: modifiedAttribute, mapLayerConfigId });
    }
  }, 500);

  return (
    <Container>
      <FinalForm<IFormData>
        initialValues={{ Attributes: layerConfig.Attributes }}
        onSubmit={handleOnSubmit}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <LayerHeader layerConfig={layerConfig} />
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
              >
                <Tab value="attributes" label="Attributes" />
              </Tabs>
              <TabPanel>
                {tabValue === "attributes" && (
                  <LayerAttributes
                    attributes={layerConfig.Attributes ?? []}
                    selectedAttributeId={selectedId}
                    onSelect={setSelectedId}
                    defaultSystemOfMeasurement={defaultSystemOfMeasurement}
                  />
                )}
              </TabPanel>
              {/* We use FormSpy to submit the form values on every update */}
              <FormSpy
                subscription={{ values: true, valid: true, pristine: true }}
                onChange={({ valid, pristine, values }) => {
                  if (valid && !pristine) {
                    handleSubmit();
                  }
                }}
              />
            </Form>
          );
        }}
        mutators={{ ...arrayMutators }}
      />
    </Container>
  );
};

export default LayerEditor;
