import React from "react";
import { animated, useSpring } from "@react-spring/web";

interface IProps {
  children: React.ReactNode;
}

const FadeIn: React.FC<IProps> = ({ children }: IProps) => {
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  return <animated.div style={props}>{children}</animated.div>;
};

export default FadeIn;
