import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Addchart } from "@mui/icons-material";
import { Alert, Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectCurrentAllocation, selectFolderById, useGetAccountSubscriptionsQuery } from "fond/api";
import mixpanel from "fond/mixpanel";
import ReportList from "fond/project/panels/ReportPanel/ReportList";
import { MultiProject, MultiReport, Store, SubscriptionStatus } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, licenseCheck, permissionCheck } from "fond/utils/permissions";
import { NavigationButton, NavigationButtonIcon } from "fond/widgets/StackedNavigation/StackedNavigation.styles";

interface IProps {
  multiProject: MultiProject;
  multiReports?: MultiReport[];
}

const ReportPanel: React.FC<IProps> = ({ multiProject, multiReports }: IProps) => {
  const navigate = useNavigate();
  const currentAllocation = useAppSelector(selectCurrentAllocation);
  const currentFolder = useSelector((state: Store) => (multiProject.Folder.ID ? selectFolderById(state, multiProject.Folder.ID) : undefined));
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const hasValidLicense = licenseCheck(currentAllocation, Actions.CITY_PLANNER_REPORT_ADD) && hasActiveSubscription;
  const hasPermission = !!currentFolder && permissionCheck(currentFolder.Permission.Level, Actions.CITY_PLANNER_REPORT_ADD);
  const hasSubAreaProject = multiProject.Areas.some((area) => area.Project?.ID);
  const canCreateMultiReport = hasSubAreaProject && hasValidLicense && hasPermission;
  const handleClick = () => {
    if (canCreateMultiReport) {
      mixpanel.track("Create multi report", "MultiReport", "MultiReport panel");
      navigate({ pathname: "/reports/create", search: new URLSearchParams({ multiProjectId: multiProject.ID }).toString() });
    }
  };

  return (
    <>
      {!hasSubAreaProject && (
        <Box mb={1}>
          <Alert severity="warning">
            <Typography variant="body3">Create at least one subarea project before generating a new report</Typography>
          </Alert>
        </Box>
      )}
      <Typography my={1} fontSize={14} variant="h6">
        {multiReports?.length === 0 ? "Report not generated" : "Generate report"}
      </Typography>
      <Typography variant="body3">Generate a business case analysis based on your subareas.</Typography>

      <NavigationButton onClick={handleClick} checked disabled={!canCreateMultiReport} variant="outlined">
        <Box display="flex" alignItems="center">
          <NavigationButtonIcon size="small" disabled={!canCreateMultiReport}>
            <Addchart />
          </NavigationButtonIcon>

          <Typography variant="body3" fontWeight="500" lineHeight="1.4" display="block" color={!canCreateMultiReport ? "grey" : "inherit"}>
            Configure report
          </Typography>
        </Box>
      </NavigationButton>

      {multiReports && <ReportList reports={multiReports} />}
    </>
  );
};

ReportPanel.displayName = "ReportPanel";
export default ReportPanel;
