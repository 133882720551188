import { Configuration, ConfigurationUpsert, GroupConfig, MultiProject } from "fond/types";
import { FilterConfiguration, LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { fccOverlayConfiguration } from "./fccOverlayConfiguration";
import { subareaConfiguration } from "./subareaConfiguration";

export interface FamilialLayerConfig {
  config: LayerConfig;
  descendants: (LayerStyle | SublayerConfig)[];
}

/**
 * Generate the Configuration upsert based on the Multiproject Boundary & Areas.
 * A LayerConfig is generated for the Boundary & one for each Area.
 */
export const multiProjectConfiguration = (multiProject: MultiProject): ConfigurationUpsert => {
  const subareas = subareaConfiguration(multiProject.Areas);
  const overlays = fccOverlayConfiguration();

  return [...subareas, ...overlays];
};

/**
 * Takes the phases and generates the style configur ation for the report build order map.
 */
export const generateMultiProjectMapConfiguration = (multiProject: MultiProject): Configuration => {
  const data = multiProjectConfiguration(multiProject);

  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [...pickIDs(data.filter((entity) => entity.Type === "GROUP" && entity.ParentID === null))],
    Type: "MapLayerConfig",
  };
};

export const groupConfigTemplate = (
  id: string,
  children: Array<LayerConfig | GroupConfig>,
  label: string,
  visible = true,
  parentId?: string
): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: label,
  Key: null,
  Children: pickIDs(children),
  IsVisible: visible,
  GlobalPosition: 0,
  Position: 0,
  ParentID: parentId ?? null,
  RootID: null,
});

export const layerConfigTemplate = (
  id: string,
  key: string,
  label: string,
  styles: LayerStyle[],
  children?: string[],
  parentId?: string
): LayerConfig => ({
  ID: id,
  Label: label,
  IsVisible: true,
  Styles: pickIDs(styles),
  Position: 0,
  Type: "LAYER",
  GeometryType: "Polygon",
  GlobalPosition: 0,
  ParentID: parentId ?? null,
  Key: key,
  MaxZoomLevel: null,
  MinZoomLevel: null,
  Children: children ?? [],
});

export const sublayerTemplate = (
  id: string,
  key: string,
  layerId: string,
  label: string,
  filter: FilterConfiguration | null,
  styles: LayerStyle[]
): SublayerConfig => ({
  Type: "SUBLAYER",
  ParentID: layerId,
  FilterConfiguration: filter,
  Styles: pickIDs(styles),
  ID: id,
  Label: label,
  Key: key,
  IsVisible: true,
  Position: 0,
  GeometryType: "Polygon",
});
