import { FC } from "react";

import { ActiveChip, CancelledChip, ExpiredChip, UnpaidChip, UpcomingChip } from "fond/settings/AccountManagement/common.styles";
import { Subscription, SubscriptionStatus } from "fond/types";

interface IProps {
  subscription: Subscription;
}

const SubscriptionStatusCell: FC<IProps> = ({ subscription }) => {
  const statusChip: { [key in SubscriptionStatus]: React.ReactElement } = {
    ACTIVE: <ActiveChip />,
    EXPIRED: <ExpiredChip />,
    UNPAID: <UnpaidChip />,
    CANCELLED: <CancelledChip />,
    NOT_STARTED: <UpcomingChip />,
  };
  return statusChip[subscription.Status];
};

export default SubscriptionStatusCell;
