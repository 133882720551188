import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { useGetProjectQuery, useUpdateProjectMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project/redux";
import { Store } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { OnClickInput } from "fond/widgets";

/**
 * Handles displaying the Project's name within an input field that on blur / enter
 * will save the updated project name
 */
const ProjectName: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const projectId = useSelector((state: Store): string => getCurrentProject(state.project)?.ID);
  const { data: project, isLoading } = useGetProjectQuery(projectId);
  const projectName = isLoading ? "" : project?.ProjectName || "Untitled project";

  const canRename = usePermissionCheck(Actions.PROJECT_RENAME, project?.Permission.Level);

  const [updateProject] = useUpdateProjectMutation();

  /**
   * Callback function for handling the saving of project name
   */
  const handleOnCommit = (value: string) => {
    if (project && value !== project.ProjectName) {
      updateProject({ ID: project.ID, ProjectName: value })
        .unwrap()
        .then(() => {
          mixpanel.track("Renamed project", { projectId: project.ID });
          enqueueSnackbar("Project renamed.");
        })
        .catch(() => {
          enqueueSnackbar("Project rename failed. Please try again...");
        });
    }
  };

  return (
    <Box marginRight={0.5}>
      <OnClickInput value={projectName} onCommit={handleOnCommit} readOnly={!canRename} />
    </Box>
  );
};

export default ProjectName;
