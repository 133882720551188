import React from "react";
import { Accept, DropzoneProps as BaseDropzoneProps, FileRejection, useDropzone } from "react-dropzone";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { ReactComponent as DisabledDropzoneIcon } from "svg_icons/disabled-dropzone-icon.svg";
import { ReactComponent as DropzoneIcon } from "svg_icons/dropzone-icon.svg";

import { NonIdealState } from "fond/widgets";

import { composeValidators } from "./utils";

const customStyles = (theme: Theme) => {
  return createStyles({
    dropzone: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      border: "3px dashed #e9e9e9",
      borderRadius: "5px",
      color: "#444",
      textAlign: "center",
    },
    dropzoneActive: {
      borderColor: theme.palette.primary.main,
    },
    dropzoneDisabled: {
      borderColor: theme.palette.biarri.primary.lightGrey,
      backgroundColor: theme.palette.biarri.primary.carbon,
      color: theme.palette.biarri.primary.grey, // text color
      cursor: "not-allowed",
    },
  });
};

export interface DropzoneProps extends WithStyles<typeof customStyles> {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * A list of accepted file extensions and mime types.
   */
  acceptedFiles?: Accept;
  /**
   * The message to display within the dropzone.
   */
  message?: string;
  /**
   * Control whether the dropzone is active.
   */
  disabled?: boolean;
  /**
   * Callback function for files drop. Can be useful when there is extra file validation other than type.
   */
  onDrop?(files: File[]): void;
  /**
   * Callback function for files accepted by the drop file type validation.
   */
  onDropAccepted?(files: File[]): void;
  /**
   * Callback function for files rejected by the drop file type validation.
   */
  onDropRejected?(fileRejections: FileRejection[]): void;
  /**
   * The title displayed within the dropzone.
   */
  title?: string;
  /**
   * Children element
   */
  children?: React.ReactNode;
  /**
   * Classname
   */
  className?: string;
  /**
   * Dropzone height
   */
  height?: string;
  /**
   * Allow multiple files to be uploaded. Default true
   */
  multiple?: boolean;
  /**
   * Custom validator
   */
  validator?: BaseDropzoneProps["validator"];
}

const Dropzone: React.FC<DropzoneProps> = ({
  classes,
  "data-testid": dataTestid = "dropzone",
  onDrop,
  onDropAccepted,
  onDropRejected,
  title,
  message,
  disabled = false,
  acceptedFiles,
  children = null,
  className,
  height = "100%",
  multiple = true,
  validator,
}: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFiles,
    disabled,
    onDrop,
    onDropAccepted,
    onDropRejected,
    validator: composeValidators({ validator, accept: acceptedFiles }),
    multiple,
  });

  return (
    <div
      aria-disabled={disabled}
      {...getRootProps()}
      className={classNames(
        classes.dropzone,
        {
          [classes.dropzoneActive]: isDragActive,
          [classes.dropzoneDisabled]: disabled,
        },
        className
      )}
      data-testid={dataTestid}
      style={{ height }}
    >
      <input {...getInputProps()} />
      {(message || title) && (
        <NonIdealState icon={disabled ? <DisabledDropzoneIcon /> : <DropzoneIcon />} title={title || ""} description={message || ""} />
      )}
      {children}
    </div>
  );
};

export default withStyles(customStyles)(Dropzone);
