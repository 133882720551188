import React, { useContext, useState } from "react";
import { Search } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { MapContext } from "fond/map/MapProvider";
import MapboxSearchBox from "fond/project/polygon/MapboxSearchBox";

const customStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.biarri.primary.midnight,
      boxShadow: theme.custom.shadows.default,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(4),
      pointerEvents: "all",
      "& .mapboxgl-ctrl-geocoder": {
        borderRadius: 5,
        border: "2px solid #01161F",
      },
      "& .mapboxgl-ctrl-geocoder--input": {
        height: 28,
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        "&:focus": {
          outline: "none",
        },
      },
      "& .mapboxgl-ctrl-geocoder--icon-search": {
        display: "none",
      },
      "& .mapboxgl-ctrl-geocoder--icon-loading": {
        marginTop: "-6px",
      },
      "& .mapboxgl-ctrl-geocoder--icon-close": {
        marginTop: 0,
      },
    },
    button: {
      margin: 2,
      padding: 2,
      color: theme.palette.grey.A100,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
  });

interface IProps extends WithStyles<typeof customStyles> {}

const SearchField: React.FC<IProps> = ({ classes }: IProps) => {
  const [showSearching, setShowSearching] = useState<boolean>(false);
  const { map } = useContext(MapContext);

  const toggleShowSearching = () => {
    setShowSearching(!showSearching);
  };

  return (
    <Box className={classes.root}>
      {showSearching && map && (
        <Box>
          <MapboxSearchBox map={map} onSelectSearchResult={toggleShowSearching} />
        </Box>
      )}
      <Tooltip title="Search" placement="bottom" arrow>
        <IconButton aria-label="search" size="small" onClick={toggleShowSearching} className={classes.button}>
          <Search />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default withStyles(customStyles)(SearchField);
