import React from "react";
import {
  CellClassParams,
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  GroupCellRendererParams,
  IAggFuncParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Box } from "@mui/material";
import { uniq } from "lodash";

import theme from "fond/theme";
import { CostBreakdown } from "fond/types";
import { AgGrid } from "fond/widgets";
import { currencyValueFormatter } from "fond/widgets/AgGrid/valueFormatter";

interface IProps {
  onGridReady(event: GridReadyEvent): void;
  onFirstDataRendered(event: FirstDataRenderedEvent): void;
  onFilterChanged(event: FilterChangedEvent): void;
}

const uniqAggFunc = (params: IAggFuncParams) => uniq(params.values.flat());

const columnDefs: ColDef[] = [
  { field: "RuleID", headerName: "ID", chartDataType: "category", rowGroup: true, hide: true, aggFunc: uniqAggFunc },
  { field: "GroupID", headerName: "Category", chartDataType: "category", aggFunc: uniqAggFunc },
  {
    rowGroup: false,
    field: "Tags",
    chartDataType: "category",
    headerName: "Tag",
    // For any rows without tags we set them to "Untagged" & style the value
    // similar to a placeholder.
    valueGetter: (params: ValueGetterParams<CostBreakdown>) => {
      return params.data?.Tags.length === 0 ? ["Untagged"] : params.data?.Tags;
    },
    cellStyle: (params: CellClassParams<CostBreakdown>) => {
      if (params.data?.Tags.length === 0) return { color: theme.palette.action.disabled, fontStyle: "italic" };
      return null;
    },
    aggFunc: uniqAggFunc,
  },
  {
    field: "TotalCost",
    headerName: "Cost",
    chartDataType: "series",
    aggFunc: "sum",
    floatingFilter: false,
    valueFormatter: currencyValueFormatter(),
  },
  { field: "CostBins", headerName: "Cost Bins", aggFunc: (params: IAggFuncParams) => uniq(params.values), hide: true },
];

const defaultColDef: ColDef = {
  flex: 1,
  filter: "agSetColumnFilter",
  floatingFilter: true,
  suppressHeaderMenuButton: true,
  rowGroup: false,
  sortable: true,
};

const ReportCostData: React.FC<IProps> = ({ onGridReady, onFilterChanged, onFirstDataRendered }) => {
  return (
    <Box p={1}>
      <AgGrid
        columnDefs={columnDefs}
        rowData={null}
        gridOptions={{
          defaultColDef,
          domLayout: "autoHeight",
          sideBar: false,
          rowGroupPanelShow: "never",
          suppressAggFuncInHeader: true,
          pagination: true,
          paginationPageSize: 20,
          getContextMenuItems: () => [],
          autoGroupColumnDef: {
            showRowGroup: true,
            // Since we don't want to allow expanding groups we use a custom renderer
            cellRenderer: (params: GroupCellRendererParams) => params.value,
            field: "RuleID",
            headerName: "ID",
            cellRendererParams: {
              suppressCount: true,
            },
          },
        }}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        onGridReady={onGridReady}
        autoSizeColumns={false}
        variant="outlined"
      />
    </Box>
  );
};

export default ReportCostData;
