import React from "react";
import { Box, Slider, Typography } from "@mui/material";
import dayjs from "dayjs";

interface IProps {
  onSliderChange(value: number[]): void;
  buildOrderMapPhases: string[];
  phaseRange: [number, number];
}

const ReportRevenueMapSlider: React.FC<IProps> = ({ onSliderChange, buildOrderMapPhases, phaseRange }) => {
  const formatDisplayDate = (date: string) => dayjs(date).format("YYYY-MM");

  const sliderMarks = buildOrderMapPhases.map((value, index, list) => ({
    value: index,
    label: [0, list.length - 1].includes(index) ? formatDisplayDate(value) : undefined,
  }));

  const onChange = (_: Event, value: number | number[]) => {
    const newValue = value as number[];

    onSliderChange(newValue);
  };

  return (
    <Box>
      <Typography variant="content" fontSize={12} lineHeight={1.2}>
        Date range
      </Typography>
      <Typography variant="body3" component="p" fontWeight={500} lineHeight={2}>
        {phaseRange[0] === phaseRange[1]
          ? formatDisplayDate(buildOrderMapPhases[phaseRange[0]])
          : `${formatDisplayDate(buildOrderMapPhases[phaseRange[0]])} - ${formatDisplayDate(buildOrderMapPhases[phaseRange[1]])}`}
      </Typography>
      <Box px={2}>
        <Slider
          data-testid="report-revenue-map-slider"
          min={0}
          max={buildOrderMapPhases.length - 1}
          track="normal"
          marks={sliderMarks}
          step={null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => buildOrderMapPhases[value]}
          value={phaseRange}
          onChange={onChange}
          sx={{ width: 150 }}
        />
      </Box>
    </Box>
  );
};

export default ReportRevenueMapSlider;
