import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { useGetMultiProjectQuery } from "fond/api";
import { CityPlannerDesignPanel } from "fond/cityPlanner/CityPlannerDesignPanel";
import { Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

import { ITabProps } from "../factory";

interface RouteParams {
  cityId: string;
}

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = () => {
  const { cityId } = useParams<keyof RouteParams>();
  const { data: multiProject } = useGetMultiProjectQuery(cityId as string, { skip: !cityId });

  // other actions within the widget that require license are locked separately
  const canEdit = useSelector((state: Store) => multiProject && permissionCheck(multiProject.Permission?.Level, Actions.PROJECT_EDIT)) || false;

  if (!multiProject) return null;

  return <CityPlannerDesignPanel architecture={multiProject.Architecture} multiProject={multiProject} readOnly={!canEdit} />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="FOND Designer" arrow placement="right">
    <Edit />
  </Tooltip>
);
