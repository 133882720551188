import { useEffect, useReducer } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMount, usePrevious } from "react-use";
import { Add as AddIcon, ArrowBack as ArrowBackIcon, Delete } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";

import {
  addTemplateToBom,
  cancelDeleteAllRows,
  cancelMSTDelete,
  cancelMSTUpdate,
  confirmDeleteAllRows,
  confirmMSTDelete,
  confirmMSTUpdate,
  deleteAllRows,
  findRowIndices,
  getCategoryTemplates,
  getInitialState,
  getSelectedCategory,
  moveRuleIndex,
  reducer,
} from "fond/architecture/bom/state";
import { bomTagSlice } from "fond/redux/bom";
import store from "fond/store";
import { makeUuid } from "fond/utils";
import { ConfirmModal } from "fond/widgets";

import { ColumnHeaders, RuleEditor } from "./RuleEditor";

// This file uses dangerouslySetInnerHTML (https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml)
// to display template.Name. In fond_service, HTML tags were added for styling (italized)
// on text. Setting HTML like this is risky and will need to be re-evaluated if we
// allow users to create their own templates.

const useStyles = makeStyles((theme) => ({
  templateButton: {
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    boxShadow: "3px 3px 3px rgba(0,0,0,0.12)",
    border: "1px solid rgba(0,0,0,0.1)",
  },

  columns: {
    display: "flex",
    alignItems: "stretch",
  },

  templatesColumn: {
    flex: "0 0 200px",
  },

  cardsColumn: {
    flex: "0 0 200px",
  },

  editorColumn: {
    flex: 1,
  },

  expandedRow: {
    "$table + &": {
      marginTop: theme.spacing(4),
    },
    flex: 1,
  },

  editorColumnInner: {
    paddingLeft: theme.spacing(4),
  },

  categoryHeading: {
    margin: theme.spacing(1),
  },

  table: {
    "$expandedRow + &": {
      marginTop: theme.spacing(4),
    },
  },

  circle: {
    width: "1.5rem",
    height: "1.5rem",
  },

  backIconRoot: {
    width: "0.75em",
    height: "0.75em",
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
  },

  templateHeading: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  templateHeadingClickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  droppableWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Some high-level notes about this component and how it fits into the arch panel:
 *
 * - It takes `hook` and `path` parameters in the same way as the other
 *   architecture widgets do. That is, hook is an array [value, setValue], and
 *   BomTab is responsible for calling `setValue(path, <the new value>)` when
 *   the user updates the value of the BOM. `path` will just be ["BOM"].
 * - BomTab maintains its own reference to the bom in its own state. All user
 *   interactions within the BomTab are fed through the `setState` function,
 *   which always updates the internal state, and if the BOM itself has changed
 *   (as opposed to an interaction that doesn't change the BOM like clicking a
 *   template category), the change is forwarded to the parent via the `hook`
 *   parameter mentioned above. So the BomTab has a BOM in its state, which will
 *   be the same BOM that its parents get on each update. It's thought that
 *   maybe this makes it easier to reason about the functions that update the
 *   BOM itself (which all exist in `state.js` in this directory), as they can
 *   be understood in isolation from the larger context, however it's by no
 *   means decided that this is the best way of doing things.
 */
export default function BomTab({
  arch,
  hook: [, setValue],
  path,
  categories,
  selectedCategoryID,
  onSelectCategory,
  editingRowID,
  onEditRow,
  validationResults,
  systemOfMeasurement,
}) {
  const [state, dispatch] = useReducer(reducer, { arch, categories, systemOfMeasurement }, getInitialState);
  const classes = useStyles();

  useMount(() => {
    const foundTags = new Set();
    store.dispatch(bomTagSlice.actions.reset());
    const bom = arch?.BOM;
    if (bom) {
      // iterate through all BOM category rows and find any tags that may be present and collect them
      // in a Set to avoid duplicates before we add that to the store for use in the current BOM's tag autocompletes
      bom.Categories.forEach((cat) =>
        cat.Rows.forEach((row) => {
          if (Array.isArray(row?.Tags)) {
            row.Tags?.forEach((tag) => foundTags.add(tag));
          }
        })
      );
    }

    /**
     * @type {import("./widgets/BomTagAutocomplete").BomTagAutocompleteOption[]}
     */
    const newTags = Array.from(foundTags).map((tag) => ({ title: tag, value: tag }));
    store.dispatch(bomTagSlice.actions.addTags(newTags));
  });

  const prevState = usePrevious(state);

  useEffect(() => {
    /**
     * All interactions with the BOM tab go through the reducer. We update the
     * state, and if the BOM itself has changed, we send the updated value back
     * up.
     */
    if (prevState != null) {
      if (state.bom !== prevState.bom) {
        setValue(path, state.bom);
      }
    }
  });

  const selectedCategory = getSelectedCategory(state, selectedCategoryID);
  const templates = getCategoryTemplates(state, selectedCategoryID);

  /**
   * If we have opened one of the rows, we want:
   * - Heading row ("ID, Description, Cost")
   * - Rows before the selected row
   * - Selected row
   * - Heading row ("ID, Description, Cost")
   * - Rows after the selected row
   *
   * but without spurious heading rows if we have opened the first or last rows.
   */
  let rightPane;

  if (state.bom == null) {
    rightPane = <BomIntro />;
  } else {
    const [editingCategoryIndex, editingRowIndex] = findRowIndices(state, editingRowID);

    const onDragEnd = (bomCategory) => {
      if (bomCategory.destination) {
        dispatch(moveRuleIndex(state, bomCategory.source, bomCategory.destination));
      }
    };

    rightPane = state.bom.Categories.map((bomCategory, categoryIndex) => {
      // If no category is selected we render all the categories, otherwise just render the selected category.
      if (selectedCategoryID == null || selectedCategoryID === bomCategory.ID) {
        const rows = bomCategory.Rows;

        // If a category has no rows we don't want to render the heading or an empty table.
        if (rows.length === 0) {
          return null;
        }

        return (
          <DragDropContext key={bomCategory.ID} onDragEnd={onDragEnd}>
            <Paper elevation={3} className={classes.droppableWrapper}>
              <Droppable droppableId={bomCategory.ID}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {editingRowIndex !== -1 && editingCategoryIndex === categoryIndex ? (
                      <>
                        {renderTable(bomCategory.ID, rows.slice(0, editingRowIndex), provided.placeholder)}
                        {renderRow(bomCategory.ID, rows[editingRowIndex], editingRowIndex, { expanded: true })}
                        {renderTable(bomCategory.ID, rows.slice(editingRowIndex + 1), provided.placeholder)}
                      </>
                    ) : (
                      <>
                        <Typography className={classes.categoryHeading} variant="h6">
                          {bomCategory.Name}
                        </Typography>
                        {renderTable(bomCategory.ID, rows, provided.placeholder)}
                      </>
                    )}
                  </div>
                )}
              </Droppable>
            </Paper>
          </DragDropContext>
        );
      } else {
        return null; // This category is not the selected category.
      }
    });
  }

  function renderTable(categoryID, rows, placeholder) {
    // Placeholder is used to keep the side of the div constant
    // If removed, when the user moves a rule using the drag and drop tool, the height
    // of the table will act as if the rule does not exist
    if (rows.length > 0) {
      return (
        <div className={classes.table}>
          <ColumnHeaders />
          {rows.map((rule, ruleIndex) => {
            return renderRow(categoryID, rule, ruleIndex, { expanded: false });
          })}
          {placeholder}
        </div>
      );
    } else {
      return null;
    }
  }

  function renderRow(categoryID, rule, ruleIndex, { expanded = false }) {
    const categoryTemplates = getCategoryTemplates(state, categoryID);
    const template = categoryTemplates.find((t) => t.ID === rule.TemplateID);
    const ruleValidationResults = _.get(validationResults, ["BOM", categoryID, rule.ID]);

    // Because we do not want to give the user the ability to reorder exanded rules or rules
    // that are MSTs, those rules will not be wrapped in the <Draggable> tool
    if (template.ID === "mst/auto" || expanded) {
      return (
        <RuleEditor
          key={`${categoryID}-${rule.ID}`}
          arch={arch}
          template={template}
          categoryID={categoryID}
          rule={rule}
          ruleIndex={ruleIndex}
          expanded={expanded}
          onEditRow={onEditRow}
          dispatch={dispatch}
          validationResults={ruleValidationResults}
        />
      );
    } else {
      return (
        <Draggable key={rule.ID} draggableId={rule.ID} index={ruleIndex}>
          {(provided, snapshot) => (
            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
              <RuleEditor
                arch={arch}
                template={template}
                categoryID={categoryID}
                rule={rule}
                ruleIndex={ruleIndex}
                expanded={expanded}
                onEditRow={onEditRow}
                dispatch={dispatch}
                validationResults={ruleValidationResults}
                isDragging={snapshot.isDragging}
              />
            </div>
          )}
        </Draggable>
      );
    }
  }

  function renderDeleteAllRowsButton() {
    return (
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Delete />}
        data-testid="delete-all-rows"
        onClick={() => {
          dispatch(deleteAllRows());
        }}
      >
        Delete
      </Button>
    );
  }

  function renderConfirmingDeleteAllRows() {
    return (
      <ConfirmModal
        open
        header="Deleting All Rows"
        data-testid="confirm-delete-all-rows"
        content={
          <div>
            <Typography variant="body2">This will delete all rows in the BOM. Are you sure you want to do this?</Typography>
          </div>
        }
        onConfirm={() => dispatch(confirmDeleteAllRows())}
        onCancel={() => dispatch(cancelDeleteAllRows())}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" data-testid="bom-tab">
      <Box display="flex" justifyContent="space-between" pb={2}>
        <Typography variant="h4">Bill of Materials</Typography>
        {/* Only show the delete all button if we are at the top level looking at all rows.
        The button would be confusing if the user has clicked into a specific category
        eg. in cables category, does "all rows" refer to the cables rows or *all* rows. */}
        {selectedCategoryID == null && state.bom != null && renderDeleteAllRowsButton()}
      </Box>
      {state.confirmingMSTUpdate != null && (
        <ConfirmModal
          open
          header="Removing some MST rules"
          data-testid="confirm-remove-msts"
          content={
            <div>
              <Typography variant="body2">
                Some BOM elements that were previously created with this template will be removed if you continue. Are you sure you want to do this?
              </Typography>
            </div>
          }
          onConfirm={() => dispatch(confirmMSTUpdate())}
          onCancel={() => dispatch(cancelMSTUpdate())}
        />
      )}
      {state.confirmingMSTDelete != null && (
        <ConfirmModal
          open
          header="Removing group of MST rules"
          data-testid="confirm-remove-mst-group"
          content={
            <div>
              <Typography variant="body2">
                {/* We only show this if we're deleting more than one row so "rows" is fine. */}
                This will delete the group of {state.confirmingMSTDelete.numRows} rows. Are you sure you want to do this?
              </Typography>
            </div>
          }
          onConfirm={() => dispatch(confirmMSTDelete())}
          onCancel={() => dispatch(cancelMSTDelete())}
        />
      )}
      {state.confirmingDeleteAllRows != null && renderConfirmingDeleteAllRows()}
      <Box display="flex">
        <div className={classes.templatesColumn}>
          {selectedCategoryID == null ? (
            <List
              subheader={
                <div className={classes.templateHeading}>
                  <Typography variant="body1">Categories</Typography>
                </div>
              }
            >
              {categories.map((category, i) => {
                return (
                  <ListItem
                    button
                    key={i}
                    selected={category.ID === selectedCategoryID}
                    onClick={() => onSelectCategory(category.ID)}
                    data-testid="category"
                    className={classes.templateButton}
                  >
                    <ListItemText data-testid="name">
                      <Typography variant="body2">{category.Name}</Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <List
              subheader={
                <div
                  onClick={() => {
                    onSelectCategory(null);
                    onEditRow(null);
                  }}
                  className={classNames([classes.templateHeading, classes.templateHeadingClickable])}
                  data-testid="back-to-categories"
                >
                  <Typography variant="body1">
                    <SvgIcon className={classes.backIconRoot}>
                      <ArrowBackIcon />
                    </SvgIcon>
                    {selectedCategory.Name}
                  </Typography>
                </div>
              }
            >
              {templates.map((template, i) => {
                const rowID = makeUuid();

                // We let users click either the list item for the AddButton so define this to use in both components.
                const addTemplate = () => {
                  dispatch(addTemplateToBom(template, rowID, selectedCategoryID));
                  onEditRow(rowID); // Immediately begin editing the new row.
                };

                return (
                  <ListItem
                    key={i}
                    className={classNames([classes.templateButton, "MuiButton-contained"])}
                    data-testid="bom-element-template"
                    data-template-id={template.ID}
                    button
                    onClick={addTemplate}
                  >
                    <ListItemText data-testid="name">
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: template.Name,
                        }}
                      />
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Avatar className={classes.circle} onClick={addTemplate}>
                        <IconButton size="small">
                          <AddIcon />
                        </IconButton>
                      </Avatar>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>

        <div className={classes.editorColumn}>
          <div className={classes.editorColumnInner}>{rightPane}</div>
        </div>
      </Box>
    </Box>
  );
}

BomTab.propTypes = {
  arch: PropTypes.object.isRequired,
  hook: PropTypes.array.isRequired,
  path: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategoryID: PropTypes.string,
  editingRowID: PropTypes.string,
  onSelectCategory: PropTypes.func.isRequired,
  onEditRow: PropTypes.func.isRequired,
  validationResults: PropTypes.object.isRequired,
  systemOfMeasurement: PropTypes.string.isRequired,
};

BomTab.defaultProps = {
  selectedCategoryID: null,
  editingRowID: null,
};

const useBomIntroStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  section: {
    marginTop: theme.spacing(2),
  },
}));

function BomIntro() {
  const classes = useBomIntroStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.section}>
        You can configure the Bill of Materials for this architecture by creating elements in the categories on the left hand side.
      </Typography>
      <Typography variant="body1" className={classes.section}>
        This is optional. If you don't configure a BOM, the default BOM for your FOND account will be displayed in your project.
      </Typography>
      <Typography variant="body1" className={classes.section}>
        {"Check out our guide on "}
        <a href="https://fondhelp.biarrinetworks.com/configuring-your-bom" target="_blank" rel="noopener noreferrer">
          how to configure a BOM
        </a>
      </Typography>
    </div>
  );
}
