import { ValueFormatterParams } from "@ag-grid-community/core";

import { formatCurrency } from "fond/utils/currency";

/**
 * valueRenderer that formats a currency value
 */
const currencyValueFormatter =
  (fallback?: string) =>
  ({ value }: ValueFormatterParams): string =>
    formatCurrency(value, { fallback });

export default currencyValueFormatter;
