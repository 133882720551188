import { Accordion, AccordionSummary, alpha, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { BackgroundAreaLines } from "fond/svg_icons";

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.biarri.secondary.whiteSmoke,
}));

export const StyledBackgroundAreaLine = styled(BackgroundAreaLines)(({ theme }) => ({
  fill: alpha(theme.palette.biarri.secondary.whiteSmoke, 0.3),
  width: "100%",
  height: "100%",
}));

export const StyledNoIdealBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  margin: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  // Remove the line before the accordion.
  "::before": {
    height: 0,
  },
}));
