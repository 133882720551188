import { Grid, styled } from "@mui/material";

import { TextField } from "fond/form/fields";

export const LabelGridItem = styled(Grid)`
  width: 160px;
  align-content: center;
`;

export const FieldGridItem = styled(Grid)`
  max-width: 160px;
`;

export const ValidationOnlyTextField = styled(TextField)`
  > * {
    margin: 0;
  }

  fieldset {
    display: none;
  }

  .Mui-error {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const GridRow = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
