import React, { useState } from "react";
import { AccessTime, AttachMoney, DateRange, KeyboardReturn, Store, SwapVert } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";

import { FullReport, Schedules } from "fond/types";
import { formatCurrency } from "fond/utils/currency";
import { formatFractionPercent } from "fond/utils/number";
import { PaperRightIconWrapper } from "fond/views/Report/report.styles";

import GridCard from "../GridCard";

import ReportRevenueChart from "./ReportRevenueChart/ReportRevenueChart";
import { CostSchedule, HHCSchedule, HHPSchedule, OverallPosition, RevenueSchedule } from "./ReportRevenueData";
import { ReportRevenueMap } from "./ReportRevenueMap";
import SummaryItem from "./SummaryItem";

interface IProps {
  report: FullReport;
}

const TooltipTexts: Record<string, string> = {
  NPV: "Net Present Value - The total value of the project (how much do I expect to have made) in dollars.",
  ROI: "Return on Investment - Expresses the percentage return on an investment relative to its cost.",
  IRR: "Internal Rate of Return - A yield value and is typically presented as a percentage.",
  MarketCoverage:
    "The total number of homes passed (as a proportion of the total) which was met during the construction phase of the project, given the input parameters.",
  BreakEven: "The point at which (if achieved) when the “Overall Position” hits zero.",
  PeakFunding: "The lowest point in your overall position curve. This refers to the maximum capital required during a project’s lifecycle.",
};

const ReportRevenuePageContent: React.FC<IProps> = ({ report }) => {
  const { palette } = useTheme();
  const positiveValueColor = palette.success.main;
  const negativeValueColor = palette.error.main;

  const { Schedules: data, CoveragePassings, Npv, Roi, Irr, PeakFunding, BreakEven } = report;

  const [visualizationType, setVisualizationType] = useState<keyof typeof Schedules>("Cashflow");

  const onVisualizationTypeChange = (value: Schedules) => {
    setVisualizationType(value);
  };

  return (
    <Grid container spacing={2.5}>
      <GridCard breakpoints={{ lg: 3, sm: 6, xs: 12 }}>
        <Tooltip title={TooltipTexts.MarketCoverage} followCursor>
          <Box height={105} display="flex" flexDirection="column" justifyContent="space-around" p={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="content" fontSize={12}>
                Market coverage (passing)
              </Typography>
              <PaperRightIconWrapper>
                <Store />
              </PaperRightIconWrapper>
            </Box>
            <Box pl={2}>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatFractionPercent(CoveragePassings)}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </GridCard>
      <GridCard breakpoints={{ lg: 5, xs: 12 }}>
        <Box height={105} display="flex" justifyContent="space-between" px={3} py={2.5}>
          <SummaryItem
            headerTitle="NPV"
            headerIcon={AttachMoney}
            content={
              <Typography
                color={Npv && Npv >= 0 ? positiveValueColor : negativeValueColor}
                variant="h3"
                component="span"
                fontWeight={700}
                whiteSpace="nowrap"
              >
                {Npv !== null && Npv > 0 && "+ "}
                {Npv === null ? "-" : formatCurrency(Npv)}
              </Typography>
            }
            tooltipText={TooltipTexts.NPV}
          />
          <SummaryItem headerTitle="ROI" headerIcon={AccessTime} content={formatFractionPercent(Roi)} tooltipText={TooltipTexts.ROI} />
          <SummaryItem headerTitle="IRR" headerIcon={KeyboardReturn} content={formatFractionPercent(Irr)} tooltipText={TooltipTexts.IRR} />
        </Box>
      </GridCard>
      <GridCard breakpoints={{ lg: 4, sm: 6, xs: 12 }}>
        <Box height={105} display="flex" justifyContent="space-between" px={3} py={2.5} gap={2}>
          <SummaryItem
            headerTitle="Peak funding"
            headerIcon={SwapVert}
            content={formatCurrency(PeakFunding)}
            tooltipText={TooltipTexts.PeakFunding}
          />
          <SummaryItem headerTitle="Time to break even" headerIcon={DateRange} content={BreakEven || "N/A"} tooltipText={TooltipTexts.BreakEven} />
        </Box>
      </GridCard>
      <ReportRevenueChart selected={visualizationType} onVisualizationTypeChange={onVisualizationTypeChange} data={data?.[visualizationType] || []} />
      <ReportRevenueMap visualizationType={visualizationType} report={report} />

      {visualizationType === "Cashflow" && <OverallPosition data={data?.[visualizationType]} />}
      {visualizationType === "Revenue" && <RevenueSchedule data={data?.[visualizationType]} />}
      {visualizationType === "Cost" && <CostSchedule data={data?.[visualizationType]} />}
      {visualizationType === "Hhc" && <HHCSchedule data={data?.[visualizationType]} />}
      {visualizationType === "Hhp" && <HHPSchedule data={data?.[visualizationType]} />}
    </Grid>
  );
};

export default ReportRevenuePageContent;
