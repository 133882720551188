import { MenuItem as BaseMenuItem, Select as BaseSelect, styled } from "@mui/material";

export const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  fontSize: 12,
  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "& svg": {
    marginRight: 4,
    fontSize: 18,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.common.white,
  },
  "& .MuiListItemText-primary": {
    fontSize: 12,
    lineHeight: "12px",
  },
}));

export const Select = styled(BaseSelect)(({ theme }) => ({
  color: "primary",
  fontSize: 11,
  fontWeight: 500,
  padding: 0,
  "& .MuiInputBase-input": {
    padding: "3px 6px",
  },
}));
