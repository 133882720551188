/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomMode, DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

import { prepareFeatureForDraw } from "fond/utils/geojson";

const {
  constants: Constants,
  lib: {
    CommonSelectors: { isEscapeKey },
  },
  modes,
} = MapboxDraw;

interface DrawCustomModeState {
  point: MapboxDraw.DrawFeature;
}
interface DrawCustomModeOptions {
  layerId: string;
}
interface DrawCustomModeThisExtended extends DrawCustomModeThis {
  layerId: string;
}

type ExtendedThis = DrawCustomModeThisExtended & DrawCustomMode<DrawCustomModeState, DrawCustomModeOptions>;

/*
 * A modified draw_point mode that stays in draw_point when a point is created,
 * rather than moving back to simple_select and requiring the user to re-enter
 * draw point mode to create the next point.
 */

function addPoint(this: DrawCustomModeThisExtended, state: unknown, e: MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent): void {
  const feature: Feature = prepareFeatureForDraw(
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: {},
    },
    this.layerId
  );
  this.addFeature(this.newFeature(feature));
  this.map.fire("draw.create", { features: [feature] });
  this._ctx.api.pullInFeature(this.layerId, feature);
}

const drawPoint: DrawCustomMode<DrawCustomModeState, DrawCustomModeOptions> = {
  ...modes.draw_point,

  onSetup: function (this: ExtendedThis, opts) {
    if (opts == null || opts.layerId == null) {
      throw new Error("draw_point requires a layerId");
    }
    this.layerId = opts.layerId;

    // Identical to the Mapbox Draw draw_point onSetup except we pass the
    // feature through `prepareFeatureForDraw`.
    const point = this.newFeature(
      prepareFeatureForDraw(
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [],
          },
        },
        opts.layerId
      )
    );

    this.addFeature(point);
    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.POINT);

    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    return { point: point };
  },

  onClick: function (this, state, e) {
    addPoint.call(this as ExtendedThis, state, e);
  },

  onTap: function (state, e) {
    addPoint.call(this as ExtendedThis, state, e);
  },

  onKeyUp: function (state, e): void {
    // Switch back to select mode when esc is pushed
    if (isEscapeKey(e)) {
      this.changeMode("simple_select");
    }
  },
};

export default drawPoint;
