import React, { useRef, useState } from "react";
import { MoreVert, Redo, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { useSnackbar } from "notistack";

import { useDeleteInvitationMutation, useResendInvitationMutation } from "fond/api";
import { Invitation } from "fond/types";

interface IProps {
  invitation: Invitation;
}

const InviteActions: React.FC<IProps> = ({ invitation }) => {
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const [withdrawInvitation] = useDeleteInvitationMutation();
  const [resendInvite] = useResendInvitationMutation();

  const handleInvitationWithdrawal = async () => {
    try {
      await withdrawInvitation(invitation.ID);
      enqueueSnackbar("Invitation withdrawn", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Unable to withdraw invitation", { variant: "error" });
    }
  };

  const handleInvitationResend = async () => {
    try {
      await resendInvite({ invitationId: invitation.ID, accountId: invitation.AccountID });
      enqueueSnackbar("Invitation sent", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Unable to resend invitation", { variant: "error" });
    }
  };

  return (
    <>
      <IconButton size="small" ref={ref} aria-haspopup="true" data-testid="invite-action-button" onClick={() => setShowMenu(true)}>
        <MoreVert />
      </IconButton>
      <Menu
        data-testid="user-row-action-menu"
        anchorEl={ref.current}
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          {invitation.InvitationStatus === "expired" && (
            <MenuItem onClick={handleInvitationResend}>
              <ListItemIcon>
                <Redo fontSize="small" />
              </ListItemIcon>
              <ListItemText>Resend invite</ListItemText>
            </MenuItem>
          )}
          <MenuItem data-testid="withdraw-invite" onClick={handleInvitationWithdrawal}>
            <ListItemIcon>
              <RemoveCircleOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>Withdraw invite</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default InviteActions;
