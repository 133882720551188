import { Box, styled } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

export const UploadDesignOverlay = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: linear-gradient(180deg, #c6dae3 0%, ${blue[500]} 100%);
    z-index: -1;
  }

  & > svg {
    color: #e1373b;
    margin-bottom: ${({ theme }) => theme.spacing(2.5)};
  }
`;

export const OverlayBlendingLayer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #f4f8fa 21.35%, #75cff0 100%);
  mix-blend-mode: screen;

  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: ${grey[100]};
    z-index: -1;
  }
`;

export const BorderedLine = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 40px;
    width: 1px;
    margin: ${theme.spacing(1)} 0 ${theme.spacing(4.5)};
    border-left: 2px dashed ${theme.palette.primary.main};
  `
);

export const CircleIcon = styled(Box)`
  position: absolute;
  left: 0;
  bottom: -30px;
  transform: translateX(-19px);
  border-radius: 50%;
  background: rgba(33, 150, 243, 0.1);
  height: 36px;
  width: 36px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ theme }) => `linear-gradient(180deg, ${theme.palette.primary.main} 0%, #48CCFF 100%)`};
    border: ${({ theme }) => `3px solid ${theme.palette.common.white}`};
  }
`;
