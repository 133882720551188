import React from "react";
import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

interface IProps extends CircularProgressProps {
  /**
   * The progress value as a percentage.
   */
  value: number;
}

const CircularProgressWithLabel: React.FC<IProps> = ({ value, ...props }: IProps) => (
  <Box position="relative" display="inline-flex">
    <svg height="0" width="0">
      <defs>
        <linearGradient id="circularProgressTrackColor" gradientTransform="rotate(45)">
          <stop offset="0%" stopColor="#B0DCF4" />
          <stop offset="100%" stopColor="#EBF9FF" />
        </linearGradient>
        <linearGradient id="circularProgressValueColor">
          <stop offset="25%" stopColor="#50A0FF" />
          <stop offset="75%" stopColor="#48CDFF" />
        </linearGradient>
      </defs>
    </svg>
    <CircularProgress variant="determinate" value={100} {...props} sx={{ "svg circle": { stroke: "url(#circularProgressTrackColor)" } }} />
    <CircularProgress
      variant="determinate"
      value={value}
      {...props}
      sx={{ position: "absolute", left: 0, "svg circle": { stroke: "url(#circularProgressValueColor)" } }}
    />
    <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
      <Typography variant="caption" fontSize={10} component="div" color="textSecondary">{`${Math.round(value)}%`}</Typography>
    </Box>
  </Box>
);
export default CircularProgressWithLabel;
