import _ from "lodash";

import {
  Architecture,
  BomCategories,
  DemandAddressType,
  DemandConfiguration,
  DemandTier,
  PlannerHubType,
  PlannerTier,
  Tier1HubType,
  Tier2CableType,
} from "fond/types";
import { defaultSizes, largeSizes, smallSizes } from "fond/types/architecture";
import { convertFeetToMeters, makeUuid } from "fond/utils";

export const defaultDemandModel: DemandConfiguration[] = [
  {
    AddressType: DemandAddressType.SFU,
    Description: "Single family residences",
    Tier: DemandTier.DROP,
    NumFibers: 1,
    Default: true,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.DPX,
    Description: "Residential duplexes",
    Tier: DemandTier.DROP,
    NumFibers: 2,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.TPX,
    Description: "Residential triplexes",
    Tier: DemandTier.DROP,
    NumFibers: 3,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.QPX,
    Description: "Residential quadruplexes",
    Tier: DemandTier.DROP,
    NumFibers: 4,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.SMDU,
    Description: "Small residential multi dwelling units such as apartment buildings with 5+ apartments",
    Tier: DemandTier.DISTRIBUTION,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.MMDU,
    Description: "Medium residential multi dwelling units such as multi-storey condominiums, apartment buildings, etc with 10+ apartments",
    Tier: DemandTier.DISTRIBUTION,
    NumFibers: 2,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.LMDU,
    Description: "Large residential multi dwelling units such as multi-storey condominiums, apartment buildings, etc with 100+ apartments",
    Tier: DemandTier.FEEDER,
    NumFibers: 2,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.SCU,
    Description: "Small commercial units such as retail stores, offices, farms, etc. Generally small places of business",
    Tier: DemandTier.DROP,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.MCU,
    Description: "Medium commercial units such as office buildings, department stores, neighbourhood shopping centres, medical clinics, etc",
    Tier: DemandTier.DISTRIBUTION,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.LCU,
    Description: "Large commercial units such as shopping malls, multi-storey office buildings, arenas, etc",
    Tier: DemandTier.FEEDER,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.INSTITUTION,
    Description: "Schools, colleges, hospitals, museums, places of worship, etc",
    Tier: DemandTier.FEEDER,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.GOVT,
    Description: "Government administration offices, military locations, public health care facilities, etc",
    Tier: DemandTier.DISTRIBUTION,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.PUBLIC,
    Description: "Public buildings such as community centres, municipal facilities, etc",
    Tier: DemandTier.DROP,
    NumFibers: 1,
    Default: false,
    Ignore: false,
  },
  {
    AddressType: DemandAddressType.DNS,
    Description: "Do Not Serve",
    Tier: DemandTier.DROP,
    NumFibers: 0,
    Default: false,
    Ignore: true,
  },
];

// All lengths are set to meters
export const defaultArch: Partial<Architecture> = {
  DefaultPlacement: "aerial",
  Description: null,
  IsConfigured: false,
  LastModifiedAt: null,
  LastModifiedBy: null,
  NumberOfTiers: 3,
  HasAttemptedSave: false,
  Tier1: {
    Cables: {
      Sizes: [1],
      ExpandAddresses: true,
    },
    DropRules: {
      DropLength: convertFeetToMeters(300),
      PolesPerDrop: 3,
      PitsPerDrop: 3,
    },
    Hubs: {
      AllowDirectHomeConnect: false,
      SplitRatio: 4,
      Sizes: [4, 8],
      Spare: {
        AbsoluteValue: 0,
      },
      LightSparePorts: false,
      Type: null,
    },
  },
  Tier2: {
    Cables: {
      Sizes: [12, 24, 48, 96, 144],
      Spare: { AbsoluteValue: 0 },
      TaperCables: true,
      Type: null,
    },
    ConnectorizedDropTerminals: {
      Enabled: false,
      MaxTailLength: convertFeetToMeters(1500),
      DaisyChain: false,
      TailsPerSplice: 8,
      TailCableSizes: [1, 4, 8],
    },
    Hubs: {
      SplitRatio: 1,
      Sizes: [96],
      Spare: { AbsoluteValue: 0 },
      LightSparePorts: false,
      UnsplitPorts: 16,
    },
    Topology: {
      ParallelCableThreshold: 0,
      LoopingCableThreshold: 0,
      Type: "tree",
    },
  },
  Tier3: {
    Cables: {
      Sizes: [96, 144, 288, 576],
      Spare: { AbsoluteValue: 0 },
      TaperCables: true,
    },
    Hubs: {
      Sizes: [10000],
    },
    Topology: {
      ParallelCableThreshold: 0,
      LoopingCableThreshold: 0,
      Type: "tree",
    },
  },
  IsFlexNap: false,
  hasUpdatedFlexNap: false,
  hasUpdatedDemandModel: false,
  Demand: { DemandConfigurations: defaultDemandModel },
};

// A default FlexNAP architecture.
// FlexNAP architectures only have 2 tiers.
export const defaultFlexNapArch: Partial<Architecture> = {
  ...defaultArch,
  NumberOfTiers: 2,
  IsFlexNap: true,
  Tier1: {
    ...defaultArch.Tier1,
    Hubs: {
      ...defaultArch.Tier1.Hubs,
      Type: Tier1HubType.Evolv,
      AllowDirectHomeConnect: true,
    },
  },
  Tier2: {
    ...defaultArch.Tier2,
    Cables: {
      ...defaultArch.Tier2.Cables,
      Type: Tier2CableType.Rpx,
    },
  },
};

const defaultBomNoUuids: { Categories: BomCategories[] } = {
  Categories: [
    {
      ID: "cable-installation-aer",
      Name: "Cable installation - Aerial",
      Rows: [
        {
          RowID: "AER-PATH",
          Description:
            "Total distance between poles that have at least 1 cable between them of any size or type (including drops), or total length of strand if using strand",
          Parameters: {
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "cable-installation-aer/length",
        },
        {
          RowID: "AER-PATH-DROP",
          Description: "Total distance between poles that have only 1ct drop cables between them",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            t1CableSizes: [1],
            dropTailCableSizes: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "cable-installation-aer/any-exclusive",
        },
      ],
    },
    {
      ID: "cable-installation-ug",
      Name: "Cable installation - Underground",
      Rows: [
        {
          RowID: "UG-PATH",
          Description: "Total length of path with at least 1 underground cable of any size or type (including drops)",
          Parameters: {
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "cable-installation-ug/length",
        },
        {
          RowID: "UG-PATH-DROP",
          Description: "Total length of path with only 1ct drop cables",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            t1CableSizes: [1],
            dropTailCableSizes: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "cable-installation-ug/any-exclusive",
        },
      ],
    },
    {
      ID: "poles",
      Name: "Poles",
      Rows: [
        {
          RowID: "AER-POLE",
          Description: "Total number of poles that are supporting at least 1 cable (including drops) or device",
          Parameters: {
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "poles/count",
        },
      ],
    },
    {
      ID: "pedestals-etc",
      Name: "Pedestals, flowerpots, pits, vaults etc",
      Rows: [
        {
          RowID: "UGS-XS",
          Description: "Total number of underground structures that do not contain any hubs or splices (e.g. for pulling through a drop)",
          Parameters: {
            t2Hubs: defaultSizes,
            t2SpliceCableSizes: defaultSizes,
            t1Hubs: defaultSizes,
            t3Hubs: [10000],
            t3SpliceCableSizes: defaultSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "pedestals-etc/not",
        },
        {
          RowID: "UGS-S",
          Description: "Total number of underground structures that contain a drop hub (and nothing else)",
          Parameters: {
            t2Hubs: [],
            t2SpliceCableSizes: [],
            t1Hubs: defaultSizes,
            t3Hubs: [],
            t3SpliceCableSizes: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "pedestals-etc/any-exclusive",
        },
        {
          RowID: "UGS-M",
          Description: "Total number of underground structures that contain a splice attached to the end of cables up to 144ct",
          Parameters: {
            t2Hubs: [],
            t2SpliceCableSizes: smallSizes,
            t1Hubs: [],
            t3Hubs: [],
            t3SpliceCableSizes: smallSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "pedestals-etc/any",
        },
        {
          RowID: "UGS-L",
          Description:
            "Total number of underground structures that contain a distribution hub or a splice attached to the end of cables larger than 144ct",
          Parameters: {
            t2Hubs: defaultSizes,
            t2SpliceCableSizes: largeSizes,
            t1Hubs: [],
            t3Hubs: [],
            t3SpliceCableSizes: largeSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "pedestals-etc/any",
        },
      ],
    },
    {
      ID: "cables",
      Name: "Cables",
      Rows: [
        {
          RowID: "AER-1ct-DROP",
          Description: "Total length of Aerial drop cable, 1ct",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-12ct",
          Description: "Total length of Aerial cable, 12ct",
          Parameters: {
            t2CableSizes: [12],
            t3CableSizes: [12],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [12],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-24ct",
          Description: "Total length of Aerial cable, 24ct",
          Parameters: {
            t2CableSizes: [24],
            t3CableSizes: [24],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [24],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-48ct",
          Description: "Total length of Aerial cable, 48ct",
          Parameters: {
            t2CableSizes: [48],
            t3CableSizes: [48],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [48],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-96ct",
          Description: "Total length of Aerial cable, 96ct",
          Parameters: {
            t2CableSizes: [96],
            t3CableSizes: [96],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [96],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-144ct",
          Description: "Total length of Aerial cable, 144ct",
          Parameters: {
            t2CableSizes: [144],
            t3CableSizes: [144],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [144],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-288ct",
          Description: "Total length of Aerial cable, 288ct",
          Parameters: {
            t2CableSizes: [288],
            t3CableSizes: [288],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [288],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-576ct",
          Description: "Total length of Aerial cable, 576ct",
          Parameters: {
            t2CableSizes: [576],
            t3CableSizes: [576],
            multiplier: 1,
            placement: ["aerial"],
            t1CableSizes: [],
            dropTailCableSizes: [576],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-1ct-DROP",
          Description: "Total length of Underground drop cable, 1ct",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-12ct",
          Description: "Total length of Underground cable, 12ct",
          Parameters: {
            t2CableSizes: [12],
            t3CableSizes: [12],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [12],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-24ct",
          Description: "Total length of Underground cable, 24ct",
          Parameters: {
            t2CableSizes: [24],
            t3CableSizes: [24],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [24],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-48ct",
          Description: "Total length of Underground cable, 48ct",
          Parameters: {
            t2CableSizes: [48],
            t3CableSizes: [48],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [48],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-96ct",
          Description: "Total length of Underground cable, 96ct",
          Parameters: {
            t2CableSizes: [96],
            t3CableSizes: [96],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [96],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-144ct",
          Description: "Total length of Underground cable, 144ct",
          Parameters: {
            t2CableSizes: [144],
            t3CableSizes: [144],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [144],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-288ct",
          Description: "Total length of Underground cable, 288ct",
          Parameters: {
            t2CableSizes: [288],
            t3CableSizes: [288],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [288],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "UG-576ct",
          Description: "Total length of Underground cable, 576ct",
          Parameters: {
            t2CableSizes: [576],
            t3CableSizes: [576],
            multiplier: 1,
            placement: ["underground"],
            t1CableSizes: [],
            dropTailCableSizes: [576],
          },
          Cost: 0,
          TemplateID: "cables/length",
        },
        {
          RowID: "AER-D-100",
          Description: "Total count of Aerial and Transitional 1ct drop between 0ft (inclusive) and 100ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: 0,
              max: convertFeetToMeters(100),
              includeMax: false,
            },
            placement: ["aerial", "transition"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "AER-D-200",
          Description: "Total count of Aerial and Transitional 1ct drop between 100ft (inclusive) and 200ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: convertFeetToMeters(100),
              max: convertFeetToMeters(200),
              includeMax: false,
            },
            placement: ["aerial", "transition"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "AER-D-300",
          Description: "Total count of Aerial and Transitional 1ct drop between 200ft (inclusive) and 300ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: convertFeetToMeters(200),
              max: convertFeetToMeters(300),
              includeMax: false,
            },
            placement: ["aerial", "transition"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "AER-D-300+",
          Description: "Total count of Aerial and Transitional 1ct drop 300ft (inclusive) and higher",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: false,
              min: convertFeetToMeters(300),
              max: convertFeetToMeters(10000),
              includeMax: false,
            },
            placement: ["aerial", "transition"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "UG-D-100",
          Description: "Total count of Underground 1ct drop between 0ft (inclusive) and 100ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: 0,
              max: convertFeetToMeters(100),
              includeMax: false,
            },
            placement: ["underground"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "UG-D-200",
          Description: "Total count of Underground 1ct drop between 100ft (inclusive) and 200ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: convertFeetToMeters(100),
              max: convertFeetToMeters(200),
              includeMax: false,
            },
            placement: ["underground"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "UG-D-300",
          Description: "Total count of Underground 1ct drop between 200ft (inclusive) and 300ft (exclusive)",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: convertFeetToMeters(200),
              max: convertFeetToMeters(300),
              includeMax: false,
            },
            placement: ["underground"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
        {
          RowID: "UG-D-300+",
          Description: "Total count of Underground 1ct drop 300ft (inclusive) and higher",
          Parameters: {
            t2CableSizes: [],
            t3CableSizes: [],
            multiplier: 1,
            length: {
              includeMin: true,
              min: convertFeetToMeters(300),
              max: convertFeetToMeters(10000),
              includeMax: false,
            },
            placement: ["underground"],
            t1CableSizes: [1],
            dropTailCableSizes: [],
          },
          Cost: 0,
          TemplateID: "cables/number-in-range",
        },
      ],
    },
    {
      ID: "terminals-cabinets-splitters",
      Name: "Terminals, cabinets, splitters",
      Rows: [
        {
          RowID: "AER-DROP-4",
          Description: "Drop hub with 4 ports, pole mounted or pole adjacent",
          Parameters: {
            t2Hubs: [],
            colocatedWith: ["pole"],
            t1Hubs: [4],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "AER-DROP-8",
          Description: "Drop hub with 8 ports, pole mounted or pole adjacent",
          Parameters: {
            t2Hubs: [],
            colocatedWith: ["pole"],
            t1Hubs: [8],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "AER-DIST-96",
          Description: "Distribution hub with 96 ports, pole mounted or pole adjacent",
          Parameters: {
            t2Hubs: [96],
            colocatedWith: ["pole"],
            t1Hubs: [],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "AER-SPL-M",
          Description: "Aerial splice case to branch cables of size up to 144ct",
          Parameters: {
            colocatedWith: ["pole"],
            t2CableSizes: smallSizes,
            t3CableSizes: smallSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/splices",
        },
        {
          RowID: "AER-SPL-L",
          Description: "Aerial splice case to branch cables of size greater than 144ct",
          Parameters: {
            colocatedWith: ["pole"],
            t2CableSizes: largeSizes,
            t3CableSizes: largeSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/splices",
        },
        {
          RowID: "UG-DROP-4",
          Description: "Drop hub with 4 ports, pedestal or buried",
          Parameters: {
            t2Hubs: [],
            colocatedWith: ["underground"],
            t1Hubs: [4],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "UG-DROP-8",
          Description: "Drop hub with 8 ports, pedestal or buried",
          Parameters: {
            t2Hubs: [],
            colocatedWith: ["underground"],
            t1Hubs: [8],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "UG-DIST-96",
          Description: "Distribution hub with 96 ports, vault mounted or buried",
          Parameters: {
            t2Hubs: [96],
            colocatedWith: ["underground"],
            t1Hubs: [],
            t3Hubs: [],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/hubs",
        },
        {
          RowID: "UG-SPL-M",
          Description: "Underground splice case to branch cables of size up to 144ct",
          Parameters: {
            colocatedWith: ["underground"],
            t2CableSizes: smallSizes,
            t3CableSizes: smallSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/splices",
        },
        {
          RowID: "UG-SPL-L",
          Description: "Underground splice case to branch cables of size greater than 144ct",
          Parameters: {
            colocatedWith: ["underground"],
            t2CableSizes: largeSizes,
            t3CableSizes: largeSizes,
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/splices",
        },
        {
          RowID: "PRI-1x32",
          Description: "Primary Splitter Module - 1x32 (in Distribution hubs)",
          Parameters: {
            t1Splitters: [],
            t2Splitters: [32],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "terminals-cabinets-splitters/splitters",
        },
      ],
    },
    {
      ID: "subscriber-count",
      Name: "Subscriber count",
      Rows: [
        {
          RowID: "NIU",
          Description: "Total count of service locations",
          Parameters: {
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "subscriber-count/demands",
        },
        {
          RowID: "FT-DROP",
          Description: "Total fibers used to directly serve addresses at Drop hubs",
          Parameters: {
            hubTypes: [PlannerHubType.Drop],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "subscriber-count/fiber-terminations",
        },
        {
          RowID: "FT-DROP-DEDICATED",
          Description: "Total fibers used to directly serve addresses at Drop dedicated hubs",
          Parameters: {
            hubTypes: [PlannerHubType.DropDedicated],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "subscriber-count/fiber-terminations",
        },
        {
          RowID: "FT-DROP-UNSPLIT",
          Description: "Total fibers used to directly serve addresses at Drop unsplit hubs",
          Parameters: {
            hubTypes: [PlannerHubType.DropUnsplit],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "subscriber-count/fiber-terminations",
        },
        {
          RowID: "FT-DISTRIBUTION-DEDICATED",
          Description: "Total fibers used to directly serve addresses at Distribution dedicated hubs",
          Parameters: {
            hubTypes: [PlannerHubType.DistributionDedicated],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "subscriber-count/fiber-terminations",
        },
      ],
    },
    {
      ID: "splicing",
      Name: "Splicing",
      Rows: [
        {
          RowID: "SPL-BRANCH-HUB",
          Description: "Total number of branch and hub splices",
          Parameters: {
            tiers: [PlannerTier.Drop, PlannerTier.DropTail, PlannerTier.Distribution, PlannerTier.Feeder],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "splicing/splice-count",
        },
        {
          RowID: "SPL-SPLIT",
          Description: "Total number of outgoing split fibers",
          Parameters: {
            tiers: [PlannerTier.Drop, PlannerTier.DropTail, PlannerTier.Distribution],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "splicing/split-count",
        },
        {
          RowID: "SPL-PASSTHROUGH",
          Description: "Total number of pass-through fibers",
          Parameters: {
            tiers: [PlannerTier.Drop, PlannerTier.DropTail, PlannerTier.Distribution, PlannerTier.Feeder],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "splicing/passthrough-count",
        },
        {
          RowID: "SPL-SPARE",
          Description: "Total number of spare fibers",
          Parameters: {
            tiers: [PlannerTier.Drop, PlannerTier.Distribution],
            multiplier: 1,
          },
          Cost: 0,
          TemplateID: "splicing/spare-count",
        },
      ],
    },
  ],
};

/**
 * We export a function to create the default arch (instead of a variable)
 * so that we can create the Uuids on BOM rows fresh each time.
 */
export const getDefaultArchBOM = (): Partial<Architecture> => {
  // Use cloneDeep so that we don't mutate defaultArch or defaultBomNoUuids
  let defaultArchBOM = { ..._.cloneDeep(defaultArch), BOM: _.cloneDeep(defaultBomNoUuids) };
  for (let group of defaultArchBOM.BOM.Categories) {
    for (let row of group.Rows) {
      row.ID = makeUuid();
    }
  }
  return defaultArchBOM;
};
