import React, { useRef, useState } from "react";
import { AlignHorizontalCenter, DriveFileRenameOutline, LegendToggle } from "@mui/icons-material";
import { List, ListItemIcon, ListItemText, Popover } from "@mui/material";

import { ListItemButton, ToggleButton } from "./FeatureEditor.styles";

interface IProps {
  selected: string;
  onToggleChange: (event: React.MouseEvent<HTMLElement>, nextView: string) => void;
}

const DrawSpanAndPoleButton: React.FC<IProps> = ({ selected, onToggleChange }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleToggleChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    onToggleChange(event, value);
    setIsPopupOpen(false);
  };

  return (
    <>
      <ToggleButton
        ref={buttonRef}
        data-testid="draw-span-pole-button"
        value=""
        selected={selected === "draw_spans" || selected === "draw_pole"}
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        sx={{ minWidth: "unset" }}
      >
        <DriveFileRenameOutline />
      </ToggleButton>
      <Popover
        open={isPopupOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsPopupOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: -8,
        }}
      >
        <List data-testid="draw-span-pole-overlay">
          <ListItemButton selected={selected === "draw_spans"} title="Place a new Span" onClick={(e) => handleToggleChange(e, "draw_spans")}>
            <ListItemIcon>
              <LegendToggle />
            </ListItemIcon>
            <ListItemText primary="Span" />
          </ListItemButton>
          <ListItemButton selected={selected === "draw_pole"} title="Place a new Pole" onClick={(e) => handleToggleChange(e, "draw_pole")}>
            <ListItemIcon>
              <AlignHorizontalCenter />
            </ListItemIcon>
            <ListItemText primary="Pole" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

export default DrawSpanAndPoleButton;
