import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectDraftData } from "fond/api";
import MapPopup from "fond/map/MapPopup";
import mixpanel from "fond/mixpanel";
import { Store } from "fond/types";
import { LayerStyle } from "fond/types/ProjectLayerConfig";

import { StyleRow } from "../StyleRow";

import { Container } from "./stylesPopup.styles";

interface IProps {
  /**
   * A list of styles that the user can select from.
   */
  styles: LayerStyle[];
  /**
   * The LngLat position the popup should be loaded at. This is usually
   * the location take from the mouse click event.
   */
  lngLat: mapboxgl.LngLatLike;
  /**
   * Callback for when the popup closes.
   */
  onClose(): void;
}

const StylesPopup: React.FC<IProps> = ({ lngLat, styles, onClose }: IProps) => {
  const config = useSelector((state: Store) => selectDraftData(state));

  useEffect(() => {
    mixpanel.track("Style Editor : Style Popup");
  }, []);

  return (
    <MapPopup lngLat={lngLat} onClose={onClose} title="Styles">
      <Container className="customScrollbars">
        {styles.map((style) => (
          <StyleRow key={style.ID} styleID={style.ID} config={config} />
        ))}
      </Container>
    </MapPopup>
  );
};

export default StylesPopup;
