import React from "react";
import { useSelector } from "react-redux";
import { PlaylistAdd } from "@mui/icons-material";
import { Box, Button, Theme, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { useGetAccountModulesQuery } from "fond/api";
import { selectAllArchitectures } from "fond/api/architecturesSlice";
import { MultiProject, Project, Store } from "fond/types";
import { accountModuleCheck, Actions } from "fond/utils/permissions";
import { NonIdealState } from "fond/widgets";

import MissingFlexNapModuleError from "./error/MissingFlexNapModuleError";
import ArchitectureEditor from "./ArchitectureEditor";
import ArchitectureList from "./ArchitectureList";
import ArchitectureListHeader from "./ArchitectureListHeader";
import { create, updateArchitecture, useArchitectureEditorContext } from "./context";
import ProjectArchitecture from "./ProjectArchitecture";

interface IProps {
  project: Project | MultiProject;
}

const Panel = styled(Box)`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-weight: 700;
` as typeof Typography;

const useCustomStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    content: {
      flex: 1,
      display: "flex",
      width: "100%",
    },
    leftPane: {
      position: "relative",
      flex: "0 0 350px",
      overflowY: "hidden",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
    },
    rightPane: {
      flex: 1,
      overflowY: "auto",
    },
  };
});

const ArchitecturePanelContent: React.FC<IProps> = ({ project }: IProps) => {
  const [{ widgetArchitecture }, dispatch] = useArchitectureEditorContext();
  const architectures = useSelector((state: Store) => selectAllArchitectures(state));
  const classes = useCustomStyles();
  const hasArchitecture = architectures.length > 0;
  const { data: accountModules } = useGetAccountModulesQuery(project.Account.ID);
  const canEnableFlexNap = accountModuleCheck(accountModules, Actions.ARCHITECTURE_ENABLE_FLEXNAP);

  // Display an error message when users select an architecture for which they don't have the required access.
  const hideContent = widgetArchitecture?.IsFlexNap && !canEnableFlexNap;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.leftPane}>
          <ProjectArchitecture />
          <ArchitectureListHeader />
          <ArchitectureList />
        </div>
        <div className={classes.rightPane}>
          {!widgetArchitecture && (
            <NonIdealState
              icon={<PlaylistAdd />}
              title={hasArchitecture ? "No architecture has been selected." : "No architectures have been created."}
              description={
                hasArchitecture
                  ? "Please select an architecture from the available architectures list or create a new architecture."
                  : "Please create a new architecture."
              }
              action={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(create());
                  }}
                >
                  Create architecture
                </Button>
              }
            />
          )}
          {widgetArchitecture &&
            (hideContent ? (
              <Panel>
                <MissingFlexNapModuleError
                  action={
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ margin: 2 }}
                      data-testid="copy-button"
                      onClick={() => {
                        // Clear flexnap fields.
                        dispatch(
                          updateArchitecture([
                            { path: ["IsFlexNap"], value: false },
                            { path: ["hasUpdatedFlexNap"], value: true },
                            { path: ["Tier1", "Hubs", "Type"], value: null },
                            { path: ["Tier1", "Hubs", "AllowDirectHomeConnect"], value: false },
                            { path: ["Tier2", "Cables", "Type"], value: null },
                          ])
                        );
                      }}
                    >
                      Convert to the standard architecture
                    </Button>
                  }
                />
              </Panel>
            ) : (
              <ArchitectureEditor project={project} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ArchitecturePanelContent;
