import React, { useMemo } from "react";
import { Lock } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";

import { DesignLayerIds, ImportStatus, Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AccordionStepper, AccordionStepperPane, NonIdealState, StackedNavigation } from "fond/widgets";

import BoundaryDrawPanel from "./BoundaryDrawPanel";
import BoundaryPanel from "./BoundaryPanel";
import ConnectionsDrawPanel from "./ConnectionsDrawPanel";
import ConnectionsPanel from "./ConnectionsPanel";
import GenerateDesignPanel from "./GenerateDesignPanel";
import { useGetDesignLayers } from "./useGetDesignLayers";

const inProgress: string[] = [
  ImportStatus.PENDING_UPLOAD,
  ImportStatus.BUILDING_TILES,
  ImportStatus.CONVERTING,
  ImportStatus.IMPORTING,
  ImportStatus.STARTING,
];

const cityPlannerStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "boundaryDraw",
    name: "Boundary",
    component: <BoundaryDrawPanel />,
    useDefaultHeader: false,
  },
  {
    id: "connectionsDraw",
    name: "Connections",
    component: <ConnectionsDrawPanel />,
    useDefaultHeader: false,
  },
];

interface SmallDesignPanelProps {
  /**
   * The project currently being viewed / edited
   */
  project: Project;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
}

const SmallDesignPanel: React.FC<SmallDesignPanelProps> = ({ project, readOnly }: SmallDesignPanelProps) => {
  const versionId = useAppSelector((state) => state.project.versionId);
  const designLayers = useGetDesignLayers(versionId);

  const accordionPanes: AccordionStepperPane[] = useMemo(
    () => [
      {
        header: "Base data",
        id: "boundary",
        content: <BoundaryPanel project={project} />,
        isComplete: false,
        isLoading: inProgress.includes(
          designLayers.get(DesignLayerIds.INPUT_LAYER_BOUNDARY)?.status || designLayers.get(DesignLayerIds.INPUT_LAYER_UG_PATH)?.status || ""
        ),
      },
      {
        header: "Connection Points",
        id: "connections",
        rightAdornment: <Chip label="optional" size="small" />,
        content: <ConnectionsPanel project={project} />,
        optional: true,
        isComplete: !!designLayers.get(DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS)?.layer,
        isLoading: inProgress.includes(designLayers.get(DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS)?.status || ""),
      },
      {
        header: "Design",
        id: "design",
        content: <GenerateDesignPanel />,
        isComplete: !!designLayers.get(DesignLayerIds.OUTPUT_DROP_CABLE)?.layer,
        isLoading: inProgress.includes(designLayers.get(DesignLayerIds.OUTPUT_DROP_CABLE)?.status || ""),
      },
    ],
    [designLayers, project]
  );

  if (!project) return null;

  return (
    <Box data-testid="city-planner-design-panel" sx={{ height: "100%" }}>
      {!permissionCheck(project.Permission?.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <StackedNavigation rootComponent={<AccordionStepper panes={accordionPanes} id={project.ID} />} screens={cityPlannerStackedNavigationPanels} />
      )}
    </Box>
  );
};

export default SmallDesignPanel;
