import React from "react";
import { useNavigate } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

interface IProps {
  currentFolderId?: string;
  currentCityId?: string;
}

const Close: React.FC<IProps> = ({ currentFolderId, currentCityId }: IProps) => {
  const navigate = useNavigate();

  /**
   * Handles closing the project.
   * Navigate to the parent folder or the projects landing page if there is no parent folder.
   */
  const handleCloseProject = () => {
    if (currentFolderId) {
      navigate(`/folders/${currentFolderId}`);
    } else if (currentCityId) {
      navigate(`/cities/${currentCityId}`);
    } else {
      navigate("/");
    }
  };

  return (
    <MenuItem data-testid="close-button" onClick={handleCloseProject}>
      <ListItemIcon>
        <ArrowBack />
      </ListItemIcon>
      Close
    </MenuItem>
  );
};

export default Close;
