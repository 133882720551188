import { getIn } from "fond/utils";

/**
 * Given a BOM template, return the initial state for a row that uses the
 * template.  This will be a row that's filled with the default values for all
 * the parameters defined by the template.
 */
export function getRowInitialState(template) {
  let initialState = {
    TemplateID: template.ID,
    RowID: "",
    Description: "",
    Cost: "",
    Parameters: {},
  };
  for (let parameter of template.Parameters) {
    let value;
    if (parameter.Default != null) {
      value = parameter.Default;
    } else if (parameter.Type === "MultiSelect") {
      value = [];
    } else if (parameter.Type === "Numeric") {
      value = null;
    } else if (parameter.Type === "Range") {
      value = {
        min: null,
        max: null,
        includeMin: false,
        includeMax: false,
      };
    } else if (parameter.Type === "IntegerRange") {
      value = {
        min: null,
        max: null,
        includeMin: true,
        includeMax: true,
      };
    } else if (parameter.Type === "LabelRow") {
      // LabelRow does not have any value attached
      continue;
    } else if (parameter.Type === "NumericChips") {
      value = [];
    } else if (parameter.Type === "TextInput") {
      value = null;
    } else {
      throw new Error(`Unrecognised parameter type: ${parameter.Type}`);
    }

    initialState.Parameters[parameter.ID] = value;
  }

  return initialState;
}

/**
 * Given the settings for a MultiSelect parameter in a BOM template, for example:

    {
      ID: "t1Hubs",
      Type: "MultiSelect",
      Label: "Drop hubs of size",
      Options: {
        Type: "ArchPath",
        Path: "Tier1.Hubs.Sizes",
      }
    }

  Return the list of available options for the parameter, for example [4, 8, 12].

  The options returned may or may not depend on the particular architecture
  passed in.
 */
export function getSelectOptions(parameter, architecture) {
  if (parameter.Options.Type === "ArchPath") {
    return getIn(architecture, parameter.Options.Path.split("."));
  } else if (parameter.Options.Type === "Fixed") {
    return parameter.Options.Choices;
  } else if (parameter.Options.Type === "Union") {
    let valueSet = new Set();
    for (let valuesDescription of parameter.Options.Values) {
      if (valuesDescription.Type !== "ArchPath") {
        throw new Error("Currently only ArchPaths can be used in Union");
      }
      for (let value of getIn(architecture, valuesDescription.Path.split("."))) {
        valueSet.add(value);
      }
    }
    let values = Array.from(valueSet);
    // We currently assume we can only union over numbers
    values.sort((a, b) => a - b);
    return values;
  } else if (parameter.Options.Type === "SplitRatio") {
    const splitRatios = [getIn(architecture, parameter.Options.Path.split("."))];
    return splitRatios.filter((r) => r !== 1).map((r) => ({ ID: r, Label: `1:${r}` }));
  } else {
    throw new Error(`Unrecognised parameter.values.type: ${parameter.Options.Type}`);
  }
}
