import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",

  "& .mapboxgl-ctrl.mapboxgl-ctrl-attrib": {
    backgroundColor: "transparent",
    "& .mapboxgl-ctrl-attrib-button": {
      display: "none",
    },
  },
  "& .mapboxgl-ctrl-bottom-right": {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib": {
    fontSize: 11,
  },
  "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale": {
    position: "absolute",
    bottom: -30,
    right: 150,
    height: 15,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    borderColor: "rgb(232, 234, 237)",
    borderWidth: 1,
    color: "rgb(232, 234, 237)",
  },
}));
