// This is a customised renderer based on the default
// https://github.com/lukasbach/react-complex-tree/blob/main/packages/core/src/renderers/createDefaultRenderers.tsx
import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import { TreeItem, TreeItemRenderContext } from ".";

export interface RenderItemProps<T> extends PropsWithChildren {
  arrow: React.ReactNode;
  depth: number;
  title: React.ReactNode;
  item: TreeItem<T | null>;
  context: TreeItemRenderContext<never>;
  renderDepthOffset?: number;
  getItemClass?(item: TreeItem<T | null>): string | undefined;
}

const Item = <T,>({ item, depth, children, title, context, arrow, getItemClass, renderDepthOffset = 8 }: RenderItemProps<T>): React.ReactElement => {
  const InteractiveComponent = context.isRenaming ? "div" : "button";
  const type = context.isRenaming ? undefined : "button";
  const itemClass = getItemClass?.(item);

  return (
    <li
      {...(context.itemContainerWithChildrenProps as any)}
      className={classNames(
        "rct-tree-item-li",
        item.isFolder && "rct-tree-item-li-isFolder",
        context.isSelected && "rct-tree-item-li-selected",
        context.isExpanded && "rct-tree-item-li-expanded",
        context.isFocused && "rct-tree-item-li-focused",
        context.isDraggingOver && "rct-tree-item-li-dragging-over",
        context.isSearchMatching && "rct-tree-item-li-search-match"
      )}
    >
      <div
        {...(context.itemContainerWithoutChildrenProps as any)}
        style={{ paddingLeft: `${depth * renderDepthOffset}px` }}
        className={classNames(
          "rct-tree-item-title-container",
          item.isFolder && "rct-tree-item-title-container-isFolder",
          context.isSelected && "rct-tree-item-title-container-selected",
          context.isExpanded && "rct-tree-item-title-container-expanded",
          context.isFocused && "rct-tree-item-title-container-focused",
          context.isDraggingOver && "rct-tree-item-title-container-dragging-over",
          context.isSearchMatching && "rct-tree-item-title-container-search-match"
        )}
      >
        {arrow}
        <InteractiveComponent
          type={type}
          {...(context.interactiveElementProps as any)}
          className={classNames(
            "rct-tree-item-button",
            itemClass,
            item.isFolder && "rct-tree-item-button-isFolder",
            context.isSelected && "rct-tree-item-button-selected",
            context.isExpanded && "rct-tree-item-button-expanded",
            context.isFocused && "rct-tree-item-button-focused",
            context.isDraggingOver && "rct-tree-item-button-dragging-over",
            context.isSearchMatching && "rct-tree-item-button-search-match"
          )}
        >
          {title}
        </InteractiveComponent>
      </div>
      {children}
    </li>
  );
};

export default Item;
