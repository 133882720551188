import React, { useState } from "react";
import { useForm } from "react-final-form";
import { AddOutlined as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { PRODUCT_CATEGORY_KEY } from "fond/constants";
import { ProductCategory } from "fond/types/stripe";
import { useQueryParams } from "fond/utils/hooks";

import { LicenseTextField, SelectionBox, StyledIconButton } from "./SubscriptionSelection.style";

import { StyledGrid } from "../Checkout.styles";

const LicenseField: React.FC = () => {
  const productCategory = useQueryParams<ProductCategory>(PRODUCT_CATEGORY_KEY);
  // if user is buying licenses, default to 1 to pre-fill summary
  const minimumLicenses = productCategory === "license" ? 1 : 0;
  const [numLicenses, setNumLicenses] = useState(minimumLicenses);
  const form = useForm();
  const handleFormUpdate = useDebouncedCallback((licenses: number) => {
    form.change("numLicenses", licenses);
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumLicenses(e.target.valueAsNumber);
    handleFormUpdate(e.target.valueAsNumber);
  };

  const decrementCount = () => {
    if (numLicenses > minimumLicenses) {
      const targetValue = numLicenses - 1;
      setNumLicenses(targetValue);
      handleFormUpdate(targetValue);
    }
  };

  const incrementCount = () => {
    const targetValue = numLicenses + 1;
    setNumLicenses(targetValue);
    handleFormUpdate(targetValue);
  };

  return (
    <SelectionBox marginY={2}>
      <Typography variant="h6">Additional user license(s)</Typography>
      <StyledGrid container spacing={4}>
        <Grid item xs={8} lg={6} xl={4}>
          <LicenseTextField
            name="numLicenses"
            value={numLicenses}
            type="number"
            fullWidth
            inputProps={{ min: minimumLicenses.toString() }}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <StyledIconButton onClick={decrementCount} disabled={numLicenses <= minimumLicenses}>
                  <RemoveIcon />
                </StyledIconButton>
              ),
              endAdornment: (
                <StyledIconButton onClick={incrementCount}>
                  <AddIcon />
                </StyledIconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4} lg={6} xl={8}>
          <Typography>users/year</Typography>
        </Grid>
      </StyledGrid>
    </SelectionBox>
  );
};

export default LicenseField;
