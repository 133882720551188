import React from "react";
import { Attachment } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import AttachmentModal from "fond/attachments/AttachmentModal";
import mixpanel from "fond/mixpanel";
import { PermissionLevel } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

interface IProps {
  permissionLevel: PermissionLevel;
  handleClose: () => void;
}

const Files: React.FC<IProps> = ({ permissionLevel, handleClose }: IProps) => {
  const [isAttachmentModalOpen, setAttachmentModalOpen] = React.useState<boolean>(false);
  const canViewAttachments = permissionCheck(permissionLevel, Actions.PROJECT_VIEW_ATTACHMENT);

  /**
   * Handles opening the Project Attachments modal
   */
  const handleAttachments = () => {
    if (canViewAttachments) {
      setAttachmentModalOpen(true);
      mixpanel.track("Opened attachments modal");
    }
    handleClose();
  };

  const onModalClose = () => {
    setAttachmentModalOpen(false);
    mixpanel.track("Closed attachments modal");
  };

  return (
    <>
      <MenuItem data-testid="files-button" onClick={handleAttachments} disabled={!canViewAttachments}>
        <ListItemIcon>
          <Attachment />
        </ListItemIcon>
        Files
      </MenuItem>
      <AttachmentModal onClose={onModalClose} isOpen={isAttachmentModalOpen} />
    </>
  );
};

export default Files;
