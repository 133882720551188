import { reduceReducers } from "fond/utils";

export const toggleShared = {
  type: "ToggleShared" as const,
};

type Action = typeof toggleShared;

const initialState = {
  shared: true,
};

export const reducer = reduceReducers((state = initialState, action: Action) => {
  switch (action.type) {
    case "ToggleShared":
      return {
        ...state,
        shared: !state.shared,
      };
    default:
      return state;
  }
});
