import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { selectCurrentAccount, selectUserId } from "fond/api";
import { STARRED_PROJECTS_KEY } from "fond/constants";
import { Folder, MultiProject, ProjectPreview, Report } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";

type UseStarredReturn = {
  starredKey: string;
  starred: string[];
  addToStarred: (entity: Folder | ProjectPreview | Report | MultiProject) => void;
  removeFromStarred: (entity: Folder | ProjectPreview | Report | MultiProject) => void;
};

export const useStarred = (): UseStarredReturn => {
  const userId = useSelector(selectUserId);
  const accountId = useSelector(selectCurrentAccount)?.ID;
  const starredKey = `${STARRED_PROJECTS_KEY}_${userId}_${accountId}`;
  const { enqueueSnackbar } = useSnackbar();
  const [starred, setStarred] = useState<string[]>(getItem(starredKey, []));

  const addToStarred = useCallback(
    async (entity: Folder | ProjectPreview | Report | MultiProject) => {
      const starredItems: string[] = getItem(starredKey, []);

      // Attempt to add to starred only if it is not in starred
      if (!starredItems.includes(entity.ID)) {
        const newStarredItems = [...starredItems, entity.ID];
        setItem(starredKey, newStarredItems);
        // Forces components to rerender with storage event update
        const newStorageEvent = new StorageEvent("storage", {
          key: starredKey,
          oldValue: JSON.stringify(starredItems),
          newValue: JSON.stringify(newStarredItems),
        });
        window.dispatchEvent(newStorageEvent);

        const entityName = entity.EntityType === "project" ? entity.ProjectName : entity.Name;

        enqueueSnackbar(`"${entityName}" added to Starred`, {
          action: (
            <Button color="primary" onClick={() => removeFromStarred(entity)}>
              Undo
            </Button>
          ),
        });
      }
    },
    [starredKey]
  );

  const removeFromStarred = useCallback(
    async (entity: Folder | ProjectPreview | Report | MultiProject) => {
      const starredItems: string[] = getItem(starredKey, []);

      // Attempt to remove from starred only if it is in starred
      if (starredItems.includes(entity.ID)) {
        const entityName = entity.EntityType === "project" ? entity.ProjectName : entity.Name;
        const newStarredItems = starredItems.filter((item) => item !== entity.ID);
        setItem(starredKey, newStarredItems);
        const newStorageEvent = new StorageEvent("storage", {
          key: starredKey,
          oldValue: JSON.stringify(starredItems),
          newValue: JSON.stringify(newStarredItems),
        });
        window.dispatchEvent(newStorageEvent);

        enqueueSnackbar(`"${entityName}" removed from Starred`, {
          action: (
            <Button color="primary" onClick={() => addToStarred(entity)}>
              Undo
            </Button>
          ),
        });
      }
    },
    [starredKey]
  );

  useEffect(() => {
    const detectAccountItemChanges = (event: StorageEvent) => {
      if (event.key === starredKey) {
        setStarred(getItem(starredKey, []));
      }
    };
    window.addEventListener("storage", detectAccountItemChanges);
    return () => window.removeEventListener("storage", detectAccountItemChanges);
  }, [starredKey]);

  return { starredKey, starred, addToStarred, removeFromStarred };
};
