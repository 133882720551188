import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";

import { selectCurrentAllocation, selectCurrentSubscription, useGetAccountRenewStatusQuery, useGetAccountSubscriptionsQuery } from "fond/api";
import { EXCEED_SUBSCRIPTION_QUOTA_KEY, EXPIRED_BANNER_KEY, NO_LICENSE_ADMIN, NO_LICENSE_MEMBER, SOON_TO_EXPIRED_BANNER_KEY } from "fond/constants";
import { useFeatureFlag } from "fond/featureFlags";
import { Bonus, Subscription, SubscriptionStatus } from "fond/types";

const RENEWAL_PERIOD_IN_DAYS = 30;
const SOON_TO_EXPIRE_IN_DAYS = 14;

interface UseUpgradePlannerReturn {
  canUpgrade: boolean;
}

interface UseRenewPlannerReturn {
  canRenew: boolean;
}

export type BannerType = "soon to expire" | "expired" | "quota exceeded" | "admin without license" | "member without license";
interface useUserManagementBannerReturn {
  showBanner: boolean;
  subscription?: Subscription;
  bannerType?: BannerType;
}

interface useBonusBannerReturn {
  showBanner: boolean;
  bonus?: Bonus;
}

/**
 * Returns true if the logged in user is allowed to upgrade to planner subscription.
 *
 * We oly allow upgrade to planner subscriptions when there is no existing planner subscription.
 */
export const useUpgradePlanner = (): UseUpgradePlannerReturn => {
  const { value: isCreateSubscriptionsEnabled } = useFeatureFlag("stripe_create_subscriptions");

  const currentAllocation = useSelector(selectCurrentAllocation);
  const isAdmin = currentAllocation?.Role === "admin";

  const { data: accountSubscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);

  const plannerSubscription = accountSubscriptions?.find((sub) => sub.Planner !== null);
  const canUpgrade = isCreateSubscriptionsEnabled && isAdmin && !plannerSubscription;

  return { canUpgrade };
};

/**
 * Returns true if the logged-in user is allowed to renew a planner subscription.
 *
 * We only allow renewal when:
 * 1. The renew status shows that it can be renewed.
 * 2. It's less than 30 days left in the current active subscription
 *    or the existing subscription is cancelled or expired.
 */
export const useRenewPlanner = (): UseRenewPlannerReturn => {
  const { value: isRenewSubscriptionsEnabled } = useFeatureFlag("stripe_renew_subscriptions");
  const currentAllocation = useSelector(selectCurrentAllocation);
  const isAdmin = currentAllocation?.Role === "admin";
  const { data: renewStatus } = useGetAccountRenewStatusQuery(currentAllocation?.Account.ID ?? skipToken);

  const baseSubscription = renewStatus?.Renewal?.BaseSubscription;
  const readyToRenew =
    !!baseSubscription &&
    (baseSubscription.Status !== SubscriptionStatus.Active ||
      Boolean(baseSubscription && dayjs(baseSubscription.EndTime).utc(true).diff(dayjs(), "days") <= RENEWAL_PERIOD_IN_DAYS));

  const canRenew = isRenewSubscriptionsEnabled && isAdmin && Boolean(renewStatus?.Renewal) && readyToRenew;
  return { canRenew };
};

/**
 * Determine whether the user management banner is to be displayed
 */
export const useUserManagementBanner = (): useUserManagementBannerReturn => {
  const currentAllocation = useSelector(selectCurrentAllocation);
  const accountId = currentAllocation?.Account.ID;
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(accountId ?? skipToken);
  const currentSubscripion = useSelector((state) => accountId && selectCurrentSubscription(state, accountId)) || null;

  // Do not show the banner if no subscription at all.
  if (!subscriptions || subscriptions.length === 0) return { showBanner: false };

  // Expire
  if (currentSubscripion?.Status === SubscriptionStatus.Expired) {
    const showExpiredBanner = !sessionStorage.getItem(EXPIRED_BANNER_KEY);
    return { showBanner: showExpiredBanner, subscription: currentSubscripion, bannerType: "expired" };
  }

  // Quota exceeded
  if (
    currentSubscripion?.Status === SubscriptionStatus.Active &&
    currentSubscripion.Planner &&
    currentSubscripion.Planner.QuotaUsage >= currentSubscripion.Planner.Quota
  ) {
    const showExceedQuotaBanner = !sessionStorage.getItem(EXCEED_SUBSCRIPTION_QUOTA_KEY);
    return { showBanner: showExceedQuotaBanner, subscription: currentSubscripion, bannerType: "quota exceeded" };
  }

  // Soon to expire
  //
  // Subscriptions are sorted by dates descendingly and we want to pick up the latest subscription to determine if we
  // want to show the `soon to expire` banner.
  //
  // Possible scenarios:
  // 1. Currently soon to expire with no incoming subscription: ["soon to expire"] -> show the banner
  // 2. Currently soon to expire with an incoming subscription: ["incoming", "soon to expire"] -> do not show the banner
  //
  // Note that we don't have a "soon to expire" status, it's actually just "active"
  const latestSubscription = subscriptions[0];
  if (
    latestSubscription.Status === SubscriptionStatus.Active &&
    dayjs(latestSubscription.EndTime).utc(true).diff(dayjs(), "days") <= SOON_TO_EXPIRE_IN_DAYS
  ) {
    const showSoonToExpireBanner = !sessionStorage.getItem(SOON_TO_EXPIRED_BANNER_KEY);
    return { showBanner: showSoonToExpireBanner, subscription: latestSubscription, bannerType: "soon to expire" };
  }

  /**
   * User is an admin to an account with subscription, but does not have license
   */
  if (currentSubscripion?.Status === SubscriptionStatus.Active && currentAllocation?.Role === "admin" && !currentAllocation.License) {
    return { showBanner: !sessionStorage.getItem(NO_LICENSE_ADMIN), subscription: currentSubscripion, bannerType: "admin without license" };
  }

  /**
   * User is a member to an account with subscription, but does not have license
   */
  if (currentSubscripion?.Status === SubscriptionStatus.Active && currentAllocation?.Role === "member" && !currentAllocation.License) {
    return { showBanner: !sessionStorage.getItem(NO_LICENSE_MEMBER), subscription: currentSubscripion, bannerType: "member without license" };
  }

  return { showBanner: false };
};

/**
 * Determine whether to show the limited time bonus offer
 */

export const useBonusBanner = (): useBonusBannerReturn => {
  const currentAllocation = useSelector(selectCurrentAllocation);
  const { data: renewStatus } = useGetAccountRenewStatusQuery(currentAllocation?.Account.ID ?? skipToken);

  const baseSubscription = renewStatus?.Renewal?.BaseSubscription;
  if (!baseSubscription) return { showBanner: false };

  const soonToEpxire = Boolean(dayjs(baseSubscription.EndTime).utc(true).diff(dayjs(), "days") <= SOON_TO_EXPIRE_IN_DAYS);
  const offerAvailable = Boolean(dayjs(renewStatus.Renewal?.Bonus.AvailableUntilTime) >= dayjs());
  return { showBanner: soonToEpxire && offerAvailable, bonus: renewStatus.Renewal?.Bonus };
};
