import { useSelector } from "react-redux";
import Button from "@mui/material/Button";

import { Store } from "fond/types";

import { getImpersonatedUser, stopImpersonating } from "./index";

import { StyledBanner, StyledText } from "./ImpersonateBanner.styles";

export default function ImpersonateBanner(): JSX.Element | null {
  const impersonate = getImpersonatedUser();
  const loggedIn = useSelector((state: Store) => state.cognito.isLoggedIn);

  return loggedIn && impersonate != null ? (
    <StyledBanner>
      <StyledText variant="h6">You are currently impersonating: {impersonate}</StyledText>
      <Button onClick={endImpersonating} color="inherit" size="large">
        TURN OFF
      </Button>
    </StyledBanner>
  ) : null;
}

function endImpersonating() {
  stopImpersonating();
  window.location.href = "/signout";
}
