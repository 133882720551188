import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  AccessTimeFilled as AccessTimeFilledIcon,
  AccountCircle as AccountCircleIcon,
  Clear as ClearIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

import { selectLayerLabelByLayerKey } from "fond/api";
import { getCurrentProject } from "fond/project/redux";
import { Export, ExportLayerWithLabel, getExportFormat, Project, Store } from "fond/types";
import { formatBytes } from "fond/utils";

import { BoldText, StyledInfoPanel, TextBox } from "./ExportInfoPanel.styles";

interface ExportInfoPanelProps {
  exportItem: Export;
  onClose: () => void;
}

const ExportInfoPanel: React.FC<ExportInfoPanelProps> = ({ exportItem, onClose }: ExportInfoPanelProps) => {
  const theme = useTheme();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const [fileSize, setFileSize] = useState<string | null>(exportItem.FileSizeBytes ? formatBytes(exportItem.FileSizeBytes) : "calculating...");

  // Get layer configs and select labels by layer key.
  const layerLabelByLayerKey = useSelector((state: Store) => selectLayerLabelByLayerKey(state, exportItem.VersionID));

  // Sort layers by label.
  const sortedLayers = useMemo(() => {
    const copiedLayers = [...exportItem.Layers];
    const layersWithLabel: ExportLayerWithLabel[] = copiedLayers.map((item) => {
      return { ...item, Label: layerLabelByLayerKey[item.LayerKey] || item.LayerKey };
    });
    return layersWithLabel.sort((a, b) => a.Label.localeCompare(b.Label));
  }, [exportItem.Layers]);

  const getFileSize = (url: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";

    xhr.onerror = () => {
      setFileSize(null);
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          setFileSize(formatBytes(xhr.response?.byteLength || 0));
        }
      }
    };
    xhr.send(null);
  };

  // If file size is not available from the data query, get the bytes length from the download URL.
  if (!exportItem.FileSizeBytes && exportItem.Url) getFileSize(exportItem.Url);

  return (
    <StyledInfoPanel width={280} data-testid="export-info-panel">
      <Box ml={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <BoldText mt={1} variant="caption">
          <InfoIcon color="primary" />
          File Details
        </BoldText>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box>
        <Box pl={3} bgcolor={theme.palette.biarri.secondary.whiteSmoke}>
          <Box>
            <Typography variant="caption">
              <AccountCircleIcon color="primary" />
              {exportItem.Creator.Email}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              <AccessTimeFilledIcon color="primary" />
              {dayjs(exportItem.CreatedAt).format("D MMM YYYY | h:mm A")}
            </Typography>
          </Box>
        </Box>

        <Box pl={3} my={0.5} className="customScrollbars">
          <Box>
            <Typography variant="caption">File Name: </Typography>
            <BoldText variant="caption">{exportItem.Name || `FOND-${project?.ProjectName}-${exportItem.Format}`}</BoldText>
          </Box>
          <Box>
            <Typography variant="caption">Format: </Typography>
            <BoldText variant="caption">{getExportFormat(exportItem.Format)}</BoldText>
          </Box>
          {fileSize && (
            <Box>
              <Typography variant="caption">File Size: </Typography>
              <BoldText variant="caption">{fileSize}</BoldText>
            </Box>
          )}
          <Box>
            <BoldText variant="caption">Layers</BoldText>
            <TextBox bgcolor={theme.palette.biarri.secondary.skyBlue} className="customScrollbars">
              {sortedLayers.map((layer: ExportLayerWithLabel) => (
                <Typography key={layer.ID} variant="caption" display="block">
                  {layer.Label}
                </Typography>
              ))}
            </TextBox>
          </Box>
          {exportItem.Description && (
            <Box>
              <BoldText variant="caption">Description</BoldText>
              <TextBox className="customScrollbars">
                <Typography variant="caption" display="block">
                  {exportItem.Description}
                </Typography>
              </TextBox>
            </Box>
          )}
        </Box>
      </Box>
    </StyledInfoPanel>
  );
};

export default ExportInfoPanel;
