import { grey } from "@mui/material/colors";
import chroma from "chroma-js";

import { NO_PHASE } from "fond/constants";
import { Configuration, ConfigurationUpsert, GroupConfig } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { layerPalette } from "./colors";

export const BO_GROUPCONFIG_ID = "build_order_group";

/**
 * Takes the build order phases and converts them to the correct format for display
 */
export const buildOrderConfiguration = (phases: string[]): ConfigurationUpsert => {
  /**
   * Since the phases length is defined by the user we potentially need to dynamically create colours.
   * If the phases.length is <= our defined colors use those, otherwise we use chroma to dynamically
   * create additional colors.
   * See: https://www.vis4.net/chromajs/#chroma-scale for more color options.
   */
  const colorRange = phases.length > layerPalette.length ? chroma.scale(layerPalette).colors(phases.length) : layerPalette;
  const groupId = BO_GROUPCONFIG_ID;
  const layerConfigs: LayerConfig[] = [];
  const layerStyles: LayerStyle[] = [];
  const sublayerConfigs: SublayerConfig[] = [];
  const layerConfigId = "build_order_address_layer";

  phases.forEach((step, index) => {
    const sublayerConfigId = `${layerConfigId}_sublayer_${step}`;
    const styleId = `${layerConfigId}_style_${step}`;
    const style = styleTemplate(styleId, sublayerConfigId, step === NO_PHASE ? grey[400] : colorRange[index], step);
    const sublayerConfig = sublayerConfigTemplate(sublayerConfigId, layerConfigId, step, style);

    layerStyles.push(style);
    sublayerConfigs.push(sublayerConfig);
  });

  const layerConfig = layerConfigTemplate(layerConfigId, groupId, sublayerConfigs);
  layerConfigs.push(layerConfig);

  const groupConfig = groupConfigTemplate(groupId, layerConfigs);

  return [layerConfig, ...layerStyles, ...sublayerConfigs, groupConfig];
};

/**
 * Takes the phases and generates the style configuration for the report build order map.
 */
export const generateBuildOrderMapConfiguration = (phases: string[]): Configuration => {
  const data = buildOrderConfiguration(phases);
  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [],
    Type: "MapLayerConfig",
  };
};

const styleTemplate = (id: string, sublayerId: string, color: string, step: string): LayerStyle => ({
  ID: id,
  Name: id,
  ConfigurationID: sublayerId,
  ConfigurationType: "LAYER",
  Position: 0,
  GlobalPosition: 0,
  Type: "STYLE",
  MapboxStyle: {
    type: "circle",
    paint: {
      "circle-color": color,
      "circle-radius": ["interpolate", ["linear"], ["zoom"], 10, 1, 14, 4, 15, 5, 16, 6],
      "circle-stroke-width": ["case", ["boolean", ["==", ["feature-state", "isSelected"], true]], 2, 0],
      "circle-stroke-color": ["case", ["boolean", ["feature-state", "isSelected"], true], "#FFFF00", "#FFFFFF"],
    },
  },
  RawStyles: {
    CircleColor: color,
    Type: "circle",
  },
});

const sublayerConfigTemplate = (id: string, layerId: string, step: string, style: LayerStyle): SublayerConfig => ({
  ID: id,
  Label: step,
  IsVisible: true,
  Styles: [style.ID],
  Position: 0,
  Type: "SUBLAYER",
  SubType: "BUILD_ORDER",
  GeometryType: "Point",
  ParentID: layerId,
  Key: "output/service_location",
  FilterConfiguration: {
    Type: "group",
    Mapbox: ["==", ["get", "Phase"], step],
  },
});

const layerConfigTemplate = (id: string, groupId: string | null, children: SublayerConfig[]): LayerConfig => ({
  ID: id,
  Label: "Build order",
  IsVisible: true,
  Styles: [],
  Position: 0,
  Type: "LAYER",
  SubType: "COST_TO_SERVE",
  GeometryType: "Point",
  GlobalPosition: 0,
  ParentID: groupId,
  Key: "output/service_location",
  MaxZoomLevel: 24,
  MinZoomLevel: 0,
  Children: pickIDs(children),
});

const groupConfigTemplate = (id: string, children: LayerConfig[]): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: "Build order",
  Key: null,
  Children: pickIDs(children),
  IsVisible: true,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
});
