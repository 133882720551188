import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tooltip } from "@mui/material";

import { selectUserId } from "fond/api";
import { UserAccountAllocationResponse } from "fond/types";

import { TOOLTIP_HEIGHT_OFFSET } from "./EditUserForm";
import RemoveUserModal from "./RemoveUserModal";

import { UserDetail, UserDetailBox, UserDetailDescription, UserWarningButton } from "./EditUser.styles";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
  closeModal(): void;
}

const RemoveUserBox: React.FC<IProps> = ({ userAccountAllocation, closeModal }) => {
  const userId = useSelector(selectUserId);
  const isCurrentUser = userAccountAllocation.User.ID === userId;
  const disabledTooltip = isCurrentUser ? "Users cannot remove themselves" : "";
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const onClick = () => {
    setShowRemoveUserModal(true);
  };
  return (
    <>
      <UserDetailBox display="flex" justifyContent="space-between">
        <Box>
          <UserDetail>Remove</UserDetail>
          <UserDetailDescription>Remove user from the account</UserDetailDescription>
        </Box>
        <Tooltip
          title={disabledTooltip}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, TOOLTIP_HEIGHT_OFFSET],
                },
              },
            ],
          }}
        >
          <span>
            <UserWarningButton variant="contained" color="error" disabled={isCurrentUser} onClick={onClick}>
              Remove
            </UserWarningButton>
          </span>
        </Tooltip>
      </UserDetailBox>
      {showRemoveUserModal && (
        <RemoveUserModal
          userAccountAllocation={userAccountAllocation}
          onClose={() => {
            setShowRemoveUserModal(false);
          }}
          onCloseAll={() => {
            setShowRemoveUserModal(false);
            closeModal();
          }}
        />
      )}
    </>
  );
};

export default RemoveUserBox;
