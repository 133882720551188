import React from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, Props as JoyrideProps, STATUS } from "react-joyride";

import mixpanel from "fond/mixpanel";
import { Tour } from "fond/types";

import ToolTip from "./ToolTip";

interface IProps extends JoyrideProps {
  tourKey: Tour;
  onComplete?(): void;
  onSkip?(): void;
}

const BaseTour: React.FC<IProps> = ({ tourKey, onComplete, onSkip, ...props }: IProps) => {
  /**
   * Called when Joyride's state changes. it returns a single parameter with the tour state.
   * We record when the user Starts, Skips or Completes a tour
   */
  const handleOnChange = ({ step, ...data }: CallBackProps) => {
    const { action, status, type } = data;

    if (type === EVENTS.TOUR_START) {
      mixpanel.track("Tour Started", { tourKey, ...data });
    } else if (type === EVENTS.TOUR_END && status === STATUS.FINISHED) {
      mixpanel.track("Tour Completed", { tourKey, ...data });
      onComplete?.();
    } else if (type === EVENTS.TOUR_END && action === ACTIONS.SKIP) {
      mixpanel.track("Tour Skipped", { tourKey, ...data });
      onSkip?.();
    }
  };

  return <Joyride tooltipComponent={ToolTip} {...props} callback={handleOnChange} />;
};

export default BaseTour;
