import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";

import { LineItem, Product, PurchaseSummary, SetupIntentClientSecretResponse, SubscriptionTier } from "fond/types/stripe";
import { Subscription } from "fond/types/usage";

import { apiSlice } from "./apiSlice";

type GetSubscriptionTiersResponse = {
  SubscriptionTiers: SubscriptionTier[];
};

type GetProductsResponse = {
  Products: Product[];
};

type CreatePurchaseSummaryRequest = {
  LineItems: LineItem[];
  PromoCode?: string;
  Address?: StripeAddressElementChangeEvent["value"]["address"];
};

type CreatePurchaseRequest = CreatePurchaseSummaryRequest & {
  PaymentMethod: string;
};

type RenewSubscriptionRequest = CreatePurchaseRequest & {
  accountId: string;
};

type RenewSubscriptionResponse = Subscription;

type CreatePurchaseResponse = {
  Invoice: string;
  HostedInvoiceUrl: string;
  Status: "incomplete" | "incomplete_expired" | "trialing" | "active" | "past_due" | "canceled" | "unpaid";
};

export const stripeSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getClientSecret: build.query<SetupIntentClientSecretResponse, string>({
      query: (accountId) => ({
        url: "/v2/stripe/client-secret",
        params: { account_id: accountId },
      }),
      transformResponse: (response: SetupIntentClientSecretResponse) => response,
    }),
    getSubscriptionTiers: build.query<SubscriptionTier[], void>({
      query: () => "/v1/stripe/subscription-tiers",
      transformResponse: (response: GetSubscriptionTiersResponse) => response.SubscriptionTiers,
    }),
    getProducts: build.query<Product[], void>({
      query: () => "/v1/stripe/products",
      transformResponse: (response: GetProductsResponse) => response.Products,
    }),
    createPurchaseSummary: build.mutation<PurchaseSummary, CreatePurchaseSummaryRequest>({
      query: (lineItems) => ({
        url: "/v1/stripe/purchase-summary",
        method: "POST",
        body: lineItems,
      }),
      transformErrorResponse: (response) => response.data,
    }),
    purchaseProducts: build.mutation<CreatePurchaseResponse, CreatePurchaseRequest>({
      query: (request) => ({
        url: "/v1/stripe/purchase",
        method: "POST",
        body: request,
      }),
    }),
    renewSubscription: build.mutation<RenewSubscriptionResponse, RenewSubscriptionRequest>({
      query: ({ accountId, ...request }) => ({
        url: `/v1/accounts/${accountId}/renew-subscription`,
        method: "POST",
        body: request,
      }),
    }),
  }),
});

export const {
  useCreatePurchaseSummaryMutation,
  useGetClientSecretQuery,
  useGetSubscriptionTiersQuery,
  useGetProductsQuery,
  usePurchaseProductsMutation,
  useRenewSubscriptionMutation,
} = stripeSlice;
