import React, { useEffect, useState } from "react";
import { Accept } from "react-dropzone";
import { InsertDriveFileOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { FeatureCollection } from "geojson";

import validateFile, { extname, getAllAcceptedExtensions, loadFilesAsGeoJsonFile, validateFilePackage } from "fond/fileValidation";
import { LayerIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import { BackgroundAreaLines, staticUrl, UploadIcon } from "fond/svg_icons";
import { LayerShape } from "fond/types";
import { Dropzone } from "fond/widgets";

interface IProps {
  layer: LayerShape;
  autoUpload?: boolean;
  includeLayerName?: boolean;
  onBeginUpload: (files: File[]) => void;
  onSelectFiles: (files: File[]) => void;
  onRemove: () => void;
  layerValidator?: (layer: FeatureCollection) => string | void;
  convertToDualSidedField?: React.ReactNode | null;
}

const Upload: React.FC<IProps> = ({
  layer,
  autoUpload = true,
  includeLayerName = true,
  onBeginUpload,
  onSelectFiles,
  onRemove,
  layerValidator,
  convertToDualSidedField,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [dropCompleted, setDropCompleted] = useState(false);
  const [expectedExtensions, setExpectedExtensions] = useState<string[] | null>(null);
  const acceptedFiles: Accept = getAllAcceptedExtensions();

  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    if (files && files.length > 0 && expectedExtensions) {
      const receivedExtensions = files.map((file) => extname(file.name).toLowerCase());
      setDropCompleted(expectedExtensions.every((ext) => receivedExtensions.includes(ext)));
    } else {
      setDropCompleted(false);
    }
  }, [files, expectedExtensions]);

  const FILE_UPLOAD_SIZE_LIMIT_MB = 100;
  const DEFAULT_MAX_FEATURES = 80000;
  // The max number of features allowed is 10,000 for addresses, and 80,000 for every other layer (eg. pole, span)
  // Note there are similar checks in the backend to check that the 80k limit is not exceeded (the backend does
  // some processing that can increase the feature count).  Keep this number in sync with the backend.
  const LAYER_KEY_TO_MAX_FEATURES = {
    [LayerIds.inAddress]: 10000,
  };

  const validateFiles = async (filesToValidate: File[], onSuccess: (filesArray: File[]) => void) => {
    try {
      const filesArray = Array.from(filesToValidate);
      mixpanel.track("Selected files", {
        layerId: layer.id,
        filenames: filesArray.map((f) => f.name),
      });

      const geojsonFile = await loadFilesAsGeoJsonFile(filesArray);

      // Convert file size in bytes to MiB to be checked against FILE_UPLOAD_SIZE_LIMIT_MB
      if (geojsonFile.size / 1048576 >= FILE_UPLOAD_SIZE_LIMIT_MB) {
        setValidationError(`Data is over the ${FILE_UPLOAD_SIZE_LIMIT_MB} MB limit.`);
      } else {
        // apply any feature count limitations
        let maxFeatures = DEFAULT_MAX_FEATURES;
        if (layer.id in LAYER_KEY_TO_MAX_FEATURES) {
          maxFeatures = LAYER_KEY_TO_MAX_FEATURES[layer.id];
        }

        validateFile(geojsonFile, layer.geometryType, maxFeatures, layerValidator).then(
          async () => {
            if (onSuccess != null) {
              onSuccess(filesArray);
            }
          },
          (message) => {
            mixpanel.track("Upload validation error", {
              message,
            });
            setValidationError(message);
          }
        );
      }
    } catch (e: any) {
      // TODO-panel_and_shape differentiate validation errors from other errors.
      mixpanel.track("Upload exception", {
        message: e.message,
      });
      setValidationError(e.message);
    }
  };

  /** We return a promise entirely for testing purposes: a test can do
   * `await wrapper.find(Dropzone).props()['onDrop']([streets])` to make sure
   * that the (necessarily) asynchronous validation is complete before
   * continuing.
   */
  const handleDrop = (newFiles: File[]) => {
    return new Promise<void>((resolve) => {
      // merge the new and old files. Replace the old files with new if filename matches.
      // old files refers to the existing file collection. Only expected during a piecemeal drop.
      const oldFiles = files;
      const newFilenames = newFiles.map((o) => o.name);
      const mergedFiles = [...newFiles, ...oldFiles.filter((f) => !newFilenames.includes(f.name))];

      const { expectedExtensions: expectedExtensionsFromValidation, error, validFiles, isPackageValid } = validateFilePackage(mergedFiles);
      if (isPackageValid) {
        validateFiles(validFiles, () => {
          setExpectedExtensions(expectedExtensionsFromValidation);
          setFiles(validFiles);
          if (autoUpload) {
            onBeginUpload(validFiles);
          } else {
            onSelectFiles(validFiles);
          }
          resolve();
        });
      } else {
        setExpectedExtensions(expectedExtensionsFromValidation);
        setFiles(validFiles);
        setValidationError(error ? error.message : null);
      }
    });
  };

  const handleRetryClick = () => {
    setValidationError(null);
    setExpectedExtensions(null);
  };

  const removeFile = (index: number) => {
    setFiles((currFiles) => {
      const copyOfFiles = [...currFiles];
      copyOfFiles.splice(index, 1);
      return copyOfFiles;
    });
    onRemove();
  };

  if (validationError) {
    return (
      <Box my={2}>
        {includeLayerName && <Typography sx={{ fontWeight: 500, my: 1 }}>Upload {layer.name}</Typography>}
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <Typography variant="body3" component="p" mb={1}>
            Sorry, that is not a valid file.
          </Typography>
          <Typography variant="body3" component="p" mb={1}>
            {validationError}
          </Typography>
          <Button onClick={handleRetryClick} size="small">
            Retry
          </Button>
        </Alert>
      </Box>
    );
  }

  return (
    <Box data-testid={`upload-box--${layer.id}`} my={2} sx={{ "& > div[data-testid=dropzone]": { borderWidth: 0 } }}>
      {includeLayerName && <Typography sx={{ fontWeight: 500, my: 1 }}>Upload {layer.name}</Typography>}
      <Dropzone onDropAccepted={handleDrop} acceptedFiles={acceptedFiles}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          py={4}
          borderRadius="4px"
          border="2px dashed rgba(0, 0, 0, 0.25)"
          sx={{ backgroundImage: staticUrl(<BackgroundAreaLines fill="rgba(245, 245, 245, 0.6)" />), backgroundSize: "cover" }}
        >
          <UploadIcon />
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.biarri.primary.darkGrey }}>
            Drag and drop files or click to browse
          </Typography>
          <Typography variant="caption" sx={{ color: "rgba(101, 101, 101, 0.80)" }}>
            SHAPE, TAB, KML, and GEOJSON files
          </Typography>
        </Box>
      </Dropzone>
      {convertToDualSidedField}
      {dropCompleted && (
        <Box my={2} px={0.5}>
          <Typography component="h5" mb={1}>
            Pending file
          </Typography>
          {files.map((file, index) => {
            const fileNameArr = file.name.split(".");
            const extension = fileNameArr.pop();
            return (
              <Box key={layer.id} display="flex" gap={1} alignItems="center" overflow="hidden">
                <InsertDriveFileOutlined color="primary" />
                <Box data-testid="pending-file-name" display="flex" flex="1" overflow="hidden">
                  <Typography variant="caption" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {fileNameArr.join(".")}
                  </Typography>
                  <Typography variant="caption">.{extension}</Typography>
                </Box>
                <Button color="primary" onClick={() => removeFile(index)}>
                  Remove
                </Button>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Upload;
