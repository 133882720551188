import React, { useRef, useState } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Box, Button, ButtonProps, FormControl, FormHelperText, Paper, Popover, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FieldValidator } from "final-form";
import fontIcons from "fonts/fontello/config.json";

import { FontIcon } from "fond/types";

type FieldValue = FontIcon | undefined;

export type FontPickerProps = {
  name: string;
  fieldProps?: Partial<FieldProps<FieldValue, any>>;
  buttonProps?: Partial<ButtonProps>;
  validate?: FieldValidator<FieldValue>;
};

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: "fontello Regular",
  },
  button: {
    fontFamily: "fontello Regular",
  },
  image: {
    cursor: "pointer",
    transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
    "&:hover, .selected": {
      transform: "scale(2)",
      zIndex: 2,
      background: "#fff",
      color: theme.palette.primary.main,
    },
  },
  images: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    maxHeight: "272px",
    overflow: "auto",
  },
}));

const FontPickerField: React.FC<FontPickerProps> = (props: FontPickerProps) => {
  const { buttonProps, fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <FontPickerWrapper input={input} meta={meta} name={name} buttonProps={buttonProps} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

FontPickerField.defaultProps = {
  fieldProps: undefined,
  buttonProps: undefined,
  validate: undefined,
};

type FontPickerWrapperProps = Pick<FontPickerProps, "buttonProps"> & FieldRenderProps<FieldValue, HTMLElement>;

const FontPickerWrapper: React.FC<FontPickerWrapperProps> = ({
  input: { buttonProps, name, onChange, value, ...restInput },
  helperText,
}: FontPickerWrapperProps) => {
  const [open, setOpen] = useState(false);
  const classes = useCustomStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOnChange = (font: FontIcon) => (event: React.MouseEvent<HTMLDivElement>) => {
    onChange(font);
    handleOnClose();
  };

  const handleOnOpen = () => setOpen(true);
  const handleOnClose = () => setOpen(false);

  return (
    <FormControl data-testid={`${name}-form-field`} className={classes.root}>
      <Box ref={anchorRef}>
        {value ? (
          <Button color="inherit" onClick={handleOnOpen} size="small" sx={{ minWidth: 0 }} className={classes.button}>
            {String.fromCharCode(value.code)}
          </Button>
        ) : (
          <Button onClick={handleOnOpen} size="small" variant="outlined" {...buttonProps}>
            Select icon
          </Button>
        )}
      </Box>
      <Popover open={open} anchorEl={anchorRef.current} onClose={handleOnClose} className={classes.root}>
        <Paper>
          <Box data-testid="select-icon-popover" className={classNames(classes.images, "customScrollbars")}>
            {fontIcons.glyphs.map((font) => (
              <Box data-testid="icon-option" key={font.uid} onClick={handleOnChange(font)} className={classes.image}>
                {String.fromCharCode(font.code)}
              </Box>
            ))}
          </Box>
        </Paper>
      </Popover>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FontPickerField;
