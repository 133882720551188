import { styled, TextField as MuiTextField } from "@mui/material";

/**
 * Hiding the arrows from the number input
 */
export const TextField = styled(MuiTextField)`
  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type="number"] {
    -moz-appearance: textfield;
  }
`;
