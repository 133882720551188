import { UserBase } from "./identities";

export type ArchitectureBase = {
  BOM?: { Categories: BomCategories[] };
  DefaultPlacement: Placement;
  Demand: DemandModel;
  Description: string | null;
  IsConfigured: boolean;
  Name: string;
  NumberOfTiers: number;
  // Below needs to be outlined further
  Tier1: any;
  Tier2: any;
  Tier3: any;
  HasAttemptedSave: boolean;
  IsFlexNap?: boolean;
};

export type Architecture = ArchitectureBase & {
  ID: string;
  CreatedAt: string;
  Creator: UserBase;
  LastModifiedAt: string | null;
  LastModifiedBy: UserBase | null;
  hasUpdatedFlexNap?: boolean;
  hasUpdatedDemandModel?: boolean;
};

export type DemandModel = { DemandConfigurations: DemandConfiguration[] } | null;

export type Placement = "aerial" | "underground" | "transition" | "internal";

export type BomCategories = {
  ID: string;
  Name: string;
  // rows need to be outlined further
  Rows: BomCategoryRow[];
};

export const smallSizes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 60, 64, 72, 84, 96, 128, 144,
] as const;
export const largeSizes = [160, 192, 224, 256, 288, 360, 416, 432, 512, 576, 672, 720, 864, 1024, 2048] as const;
// Create a fairly comprehensive list for all relatively ‘common’ cable and hub sizes
export const defaultSizes = [...smallSizes, ...largeSizes] as const;

export type smallSizes = typeof smallSizes;
export type largeSizes = typeof largeSizes;
export type defaultSizes = typeof defaultSizes;

type CableSizes = number[] | smallSizes | largeSizes | defaultSizes;

export type BomCategoryRow = {
  ID?: string;
  RowID: string;
  Description: string;
  Parameters: BomCategoryRowParameters;
  Cost: number;
  TemplateID: string;
  Tags?: string[];
};

// make this partial because each row will have different parameters
export type BomCategoryRowParameters = Partial<{
  colocatedWith: string[];
  dropTailCableSizes: number[];
  hubTypes: PlannerHubType[];
  length: {
    includeMin: boolean;
    min: number;
    max: number;
    includeMax: boolean;
  };
  multiplier: number;
  placement: Placement[];
  t1CableSizes: CableSizes;
  t2CableSizes: CableSizes;
  t3CableSizes: CableSizes;
  t1Hubs: CableSizes;
  t2Hubs: CableSizes;
  t3Hubs: CableSizes;
  t2SpliceCableSizes: CableSizes;
  t3SpliceCableSizes: CableSizes;
  t1Splitters: number[];
  t2Splitters: number[];
  tiers: PlannerTier[];
}>;

export enum DemandAddressType {
  SFU = "SFU",
  DPX = "DPX",
  TPX = "TPX",
  QPX = "QPX",
  SMDU = "SMDU",
  MMDU = "MMDU",
  LMDU = "LMDU",
  SCU = "SCU",
  MCU = "MCU",
  LCU = "LCU",
  INSTITUTION = "INSTITUTION",
  GOVT = "GOVT",
  PUBLIC = "PUBLIC",
  DNS = "DNS",
}

export enum DemandTier {
  DROP = "Drop",
  DISTRIBUTION = "Distribution",
  DISTRIBUTION_BYPASS_SPLITTERS = "Distribution bypass splitters",
  FEEDER = "Feeder",
}

export type DemandConfiguration = {
  AddressType: string;
  Description: string;
  Tier: DemandTier;
  NumFibers: number;
  Default: boolean;
  Ignore: boolean;
};

export type DemandModelRule = DemandConfiguration & {
  ID: string;
  New: boolean;
};

export enum Tier1HubType {
  Evolv = "evolv",
  LongSpanEvolv = "long_span_evolv",
}

export enum Tier2CableType {
  ArmoredAltos = "armored_altos",
  DielectricAltos = "dielectric_altos",
  Rpx = "rpx",
  Figure8 = "figure_8",
}

export enum PlannerHubType {
  Drop = "drop",
  DropDedicated = "drop_dedicated",
  DropUnsplit = "drop_unsplit",
  DistributionDedicated = "distribution_dedicated",
}

export enum PlannerTier {
  Drop = "drop",
  DropTail = "drop_tail",
  Distribution = "distribution",
  Feeder = "feeder",
}
