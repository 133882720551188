import { Box, Link as MuiLink, LinkTypeMap, styled } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const Link = styled(MuiLink)(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginLeft: 8,
  fontWeight: 500,
})) as OverridableComponent<LinkTypeMap<unknown, "a">>;

export const CellWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
`;
