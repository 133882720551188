import React from "react";
import { Box, Slider, Typography } from "@mui/material";

import { formatCurrency } from "fond/utils/currency";
import { formatNumber } from "fond/utils/number";

interface IProps {
  onSliderChange(value: number[]): void;
  binRanges: number[];
  costRange: number[];
}

const ReportCostMapSlider: React.FC<IProps> = ({ binRanges, onSliderChange, costRange }) => {
  const sliderMarks = binRanges.map((value, index, list) => ({
    value: index,
    label: [0, list.length - 1].includes(index)
      ? Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 1 }).format(value)
      : undefined,
  }));

  const onChange = (_: Event, value: number | number[]) => {
    const newValue = value as number[];

    onSliderChange(newValue);
  };

  const renderCostRangeDisplay = () => {
    if (costRange[0] === costRange[1]) return formatCurrency(binRanges[costRange[0]]);
    return `${formatCurrency(binRanges[costRange[0]])} - ${formatCurrency(binRanges[costRange[1]])}`;
  };

  return (
    <Box>
      <Typography variant="content" fontSize={12} lineHeight={1.2}>
        Cost range
      </Typography>
      <Typography variant="body3" component="p" fontWeight={500} lineHeight={2}>
        {renderCostRangeDisplay()}
      </Typography>
      <Box px={1}>
        <Slider
          data-testid="report-cost-map-slider"
          min={0}
          max={binRanges.length - 1}
          track="normal"
          marks={sliderMarks}
          step={null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => formatNumber(binRanges[value])}
          value={costRange}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default ReportCostMapSlider;
