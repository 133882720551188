/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis, DrawLineString } from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

import { MapboxDrawExtended, Project } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import snapTo, { withSnapping } from "./snap_to";

const { constants: Constants, modes } = MapboxDraw;

interface DrawCustomModeState {
  feature: DrawLineString;
  featureId: string;
  selectedCoordPaths: string[];
}

interface DrawCustomModeOptions {
  project: Project;
  layerConfigs: Array<LayerConfig | SublayerConfig>;
}

interface DrawCustomModeExtension {
  dragVertex?(state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent, delta: { lng: number; lat: number }): void;
  enter?(
    draw: MapboxDrawExtended,
    opts: {
      event: MapboxDraw.MapMouseEvent;
      feature: Feature;
      layerId: string;
    }
  ): void;
  fireActionable?(this: DrawCustomModeThis, state: DrawCustomModeState): void;
  fireUpdate?(this: DrawCustomModeThis): void;
  pathsToCoordinates(featureId: string, paths: string[]): Array<{ coord_path: string; feature_id: string }>;
}

const DirectSelect = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.direct_select,

  enter: function (mapboxDraw, opts) {
    if (mapboxDraw.getMode() === "draw_line_string") {
      return;
    }

    mapboxDraw.pullInFeature(opts.layerId, opts.feature);
    mapboxDraw.changeMode("direct_select", {
      featureId: opts.feature.id,
    });

    const { type, ...rest } = opts.feature;
    opts.event.featureTarget = { ...opts.event.featureTarget, ...rest };
    mapboxDraw.ctx.events.getCurrentMode().click(opts.event);
  },

  fireActionable: function (state) {
    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: state.selectedCoordPaths.length > 0,
    });
  },

  fireUpdate: function () {
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.CHANGE_COORDINATES,
      features: this.getSelected().map((f) => f.toGeoJSON()),
    });
  },

  onTrash: function (state) {
    if (state.selectedCoordPaths.length === 0) {
      this._ctx.api.markFeatureDeleted(state.featureId);
      this.changeMode("simple_select", {});
    } else {
      const reversedCoords = state.selectedCoordPaths.map(parseInt).sort().reverse();
      reversedCoords.forEach((id) => state.feature.removeCoordinate(id));

      this.fireUpdate?.();
      state.selectedCoordPaths = [];
      this.clearSelectedCoordinates();

      this.fireActionable?.(state);
      if (state.feature.isValid() === false) {
        this._ctx.api.markFeatureDeleted(state.featureId);
        this.changeMode("simple_select", {});
      } else if (reversedCoords.length > 0) {
        // If we deleted a vertex, select another vertex.
        const ix = Math.min(reversedCoords[0], state.feature.coordinates.length - 1);
        state.selectedCoordPaths = [ix.toString()];
        this.setSelectedCoordinates(this.pathsToCoordinates(state.featureId, state.selectedCoordPaths));
      }
    }
  },

  pathsToCoordinates: function (featureId, paths) {
    return paths.map((path) => {
      return { feature_id: featureId, coord_path: path };
    });
  },

  dragVertex: function (state, e, delta) {
    state.feature.updateCoordinate(state.selectedCoordPaths[0], e.lngLat.lng, e.lngLat.lat);
  },

  snap: function (state, e) {
    if (e.point) {
      return snapTo(e, this._ctx, state.featureId);
    } else {
      return e;
    }
  },
});

export default DirectSelect;
