import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";

import Modal from "fond/widgets/Modal";

interface ErrorModalProps {
  onClose: () => void;
  message?: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  onClose,
  message = "There was a problem making a request to the server. Please try again.",
}: ErrorModalProps) => (
  <Modal
    className="error-modal"
    open
    header="Oops"
    content={
      <div>
        <span style={{ fontSize: 50, float: "left", color: "#db2828", marginRight: 10 }}>
          <ErrorIcon color="inherit" fontSize="inherit" />
        </span>
        <p style={{ paddingTop: "0.5em" }}>{message}</p>
      </div>
    }
    actions={<Button onClick={onClose}>Close</Button>}
  />
);

export default ErrorModal;
