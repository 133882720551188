import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Typography, useTheme } from "@mui/material";

import { useGetSubscriptionTiersQuery } from "fond/api";
import { PRODUCT_CATEGORY_KEY, PRODUCT_SELECTION_KEY, PURCHASE_TYPE_KEY } from "fond/constants";
import { ProductCategory, PurchaseType } from "fond/types/stripe";
import { BlockSpinner } from "fond/widgets";

import PricingTableItem from "./PricingTableItem";

import { PricingTableBox } from "./PricingTable.styles";

const POPULAR_TIER = 2;

const PricingTable: React.FC = () => {
  const [selected, setSelected] = useState("PLUS");
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useGetSubscriptionTiersQuery();

  const navigateToCheckout = () => {
    navigate(
      `/checkout?${PRODUCT_CATEGORY_KEY}=${ProductCategory.subscription}&${PRODUCT_SELECTION_KEY}=${selected}&${PURCHASE_TYPE_KEY}=${PurchaseType.first_time_purchase}`
    );
  };

  return (
    <PricingTableBox data-testid="pricing-table">
      <Typography variant="h3" marginBottom={2}>
        Choose a subscription plan that's right for you.
      </Typography>
      {data && !isLoading ? (
        <>
          <Box display="flex" justifyContent="space-around">
            {data.map((tier, i) => (
              <PricingTableItem
                key={tier.ID}
                subscriptionTier={tier}
                selected={selected === tier.Product.ID}
                popular={i === POPULAR_TIER}
                onClick={() => {
                  setSelected(tier.Product.ID);
                }}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between" marginTop={6}>
            <Box>
              <Typography fontWeight="bold" color={theme.palette.biarri.secondary.grey}>
                Prices listed are tax exclusive
              </Typography>
              <Typography color={theme.palette.biarri.secondary.grey}>
                Your final total including tax will be displayed before you finalize your purchase.
              </Typography>
            </Box>
            <Button variant="contained" onClick={navigateToCheckout} data-testid="proceed-to-purchase-button">
              Continue
            </Button>
          </Box>
        </>
      ) : (
        <BlockSpinner />
      )}
    </PricingTableBox>
  );
};

export default PricingTable;
