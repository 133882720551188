import { Box, styled, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: 1,
  overflow: "hidden",
}));

export const Fields = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  display: "flex",
  flexGrow: 1,
  overflow: "hidden",
}));

Fields.defaultProps = {
  className: "customScrollbars",
};

export const FieldValues = styled(Box)(({ theme }) => ({
  overflow: "auto",
  fontWeight: 400,
  width: 125,
  minWidth: 125,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}));

FieldValues.defaultProps = {
  className: "customScrollbars greyBackground",
};

export const FieldForm = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  width: "100%",
  overflow: "auto",
}));

FieldForm.defaultProps = {
  className: "customScrollbars",
};

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: 34,
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  padding: `0 ${theme.spacing(1)}`,
  minHeight: theme.spacing(4),
  minWidth: 0,
}));
