import { Button, styled } from "@mui/material";

export const ButtonLink = styled(Button)(({ theme }) => ({
  color: theme.palette.biarri.secondary.activeBlue,
  font: "inherited",
  fontSize: "0.75rem",
  padding: `0 ${theme.spacing(0.5)} `,
  border: "none!important",
  background: "none!important",
  cursor: "pointer",
}));
