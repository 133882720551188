import React, { useContext } from "react";
import { HighlightAlt } from "@mui/icons-material";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const AutoCarveButton: React.FC<IButtonProps> = () => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("passing_draw_polygon");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "passing_draw_polygon" });
  };

  return (
    <ToolbarToggleButton title="Draw auto carve boundary" value="passing_draw_polygon" onClick={handleOnClick}>
      <HighlightAlt />
    </ToolbarToggleButton>
  );
};

export default AutoCarveButton;
