import React, { PropsWithChildren } from "react";
import { Tooltip } from "@mui/material";

import { Button } from "./toolbar.styles";

interface IProps {
  disabled?: boolean;
  title: string;
  onClick(): void;
}

const ToolbarButton: React.FC<PropsWithChildren<IProps>> = ({ disabled = false, children, title, onClick }: PropsWithChildren<IProps>) => {
  return (
    <Tooltip title={title} placement="right">
      <span>
        <Button onClick={onClick} disabled={disabled}>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

export default ToolbarButton;
