import React from "react";
import { useNavigate } from "react-router";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Button } from "@mui/material";

import { PRODUCT_CATEGORY_KEY, PURCHASE_TYPE_KEY } from "fond/constants";
import { useRenewPlanner } from "fond/hooks/useUpgradePlanner";
import { ProductCategory, PurchaseType } from "fond/types/stripe";

interface RenewButtonProps {
  category: ProductCategory;
}

const RenewButton: React.FC<RenewButtonProps> = ({ category }) => {
  const navigate = useNavigate();
  const { canRenew } = useRenewPlanner();

  return canRenew ? (
    <Button
      variant="contained"
      onClick={() => navigate(`/checkout?${PRODUCT_CATEGORY_KEY}=${category}&${PURCHASE_TYPE_KEY}=${PurchaseType.renew}`)}
      sx={{ marginLeft: 1 }}
      startIcon={<AutorenewIcon />}
      data-testid="renew-button"
    >
      Renew
    </Button>
  ) : null;
};

export default RenewButton;
