import React from "react";
import { useNavigate, useParams } from "react-router";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteDraftMutation } from "fond/api";
import { Modal } from "fond/widgets";

interface IProps {
  onClose: () => void;
  draftId: string;
}

const DiscardDraftConfirmationModal: React.FC<IProps> = ({ onClose, draftId }) => {
  const [deleteDraft, status] = useDeleteDraftMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { uuid: projectId } = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const handleRevertChanges = async () => {
    try {
      await deleteDraft(draftId).unwrap();
      navigate(`/project/${projectId}`);
    } catch {
      onClose();
      enqueueSnackbar("There was a problem reverting your changes...", {
        variant: "error",
        action: (
          <Button onClick={handleRevertChanges} color="inherit">
            Retry
          </Button>
        ),
      });
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      header="Discard changes"
      content="Are you sure you want to discard your changes? This action will revert the styles to the published version."
      actions={
        <>
          <Button onClick={onClose} sx={{ mr: 1 }} data-testid="cancel-discard-button">
            Cancel
          </Button>
          <LoadingButton loading={status.isLoading} onClick={handleRevertChanges} data-testid="discard-button">
            Confirm
          </LoadingButton>
        </>
      }
      data-testid="discard-modal"
    />
  );
};

export default DiscardDraftConfirmationModal;
