import { Home } from "@mui/icons-material";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";

import { GenericContainer } from "fond/settings/AccountManagement/common.styles";
import { Icon } from "fond/settings/AccountManagement/SubscriptionUsage.styles";
import { Subscription, SubscriptionStatus } from "fond/types";

interface SubscriptionUsageProps {
  subscriptions: Subscription[] | undefined;
}

const SubscriptionUsage: React.FC<SubscriptionUsageProps> = ({ subscriptions }: SubscriptionUsageProps) => {
  const activeSubscriptions = subscriptions?.filter((subscription) => subscription.Status === SubscriptionStatus.Active);
  const totalAvailablePremises =
    activeSubscriptions?.reduce((quota, accountSubscription) => quota + (accountSubscription.Planner?.Quota ?? 0), 0) ?? 0;
  const totalUsedPremises =
    activeSubscriptions?.reduce((usage, accountSubscription) => usage + (accountSubscription.Planner?.QuotaUsage ?? 0), 0) ?? 0;
  const premisesUsedPercentage = totalAvailablePremises && Math.round((totalUsedPremises / totalAvailablePremises) * 100);

  return (
    <Grid container sx={{ padding: 2.5 }} data-testid="subscription-usage">
      <Grid item xs={6} display="flex">
        <Icon data-testid="subscription-usage-avatar">
          <Home />
        </Icon>
        <Box ml={2}>
          <Typography variant="body1" fontWeight={500}>
            Premises
          </Typography>
          <Typography variant="body2" data-testid="total-available-prems">
            Total &nbsp;
            <b>{totalAvailablePremises.toLocaleString()}</b>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mt={1} pb={1}>
          <LinearProgress data-testid="subscription-usage-linear-progress" variant="determinate" color="primary" value={premisesUsedPercentage} />
        </Box>
        <GenericContainer>
          <Typography variant="body2" data-testid="total-used-prems">
            <b>{totalUsedPremises.toLocaleString()}</b>
            &nbsp; Used
          </Typography>
          <Typography variant="body2">{premisesUsedPercentage}%</Typography>
        </GenericContainer>
      </Grid>
    </Grid>
  );
};

export default SubscriptionUsage;
