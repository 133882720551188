export interface Module {
  Name: string;
  Title: string;
  Description?: string;
}

export enum AccountModules {
  FlexNap = "flexnap",
  SpliceTables = "splice_tables",
  ImportReport = "financial_analytics_report_import",
  AttachmentsBulkImport = "attachments_bulk_import",
}
