import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { IconProps } from "@mui/material";

interface IProps extends ICellRendererParams {
  icon: React.ElementType<IconProps>;
  iconProps?: object;
}

const IconCellRenderer: React.FC<IProps> = memo(({ value, icon: Icon, iconProps }: IProps) => {
  // Uses empty span instead of null because otherwise a default '-' is applied in .ag-cell:empty:before in ag-base.scss
  return value ? <Icon {...iconProps} /> : <span />;
});
IconCellRenderer.displayName = "IconCellRenderer";

export default IconCellRenderer;
