import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gridTemplateColumns: "min-content auto",
  gridGap: theme.spacing(1),
}));

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& svg": {
    width: 16,
    height: 16,
  },
}));

export const Text = styled(Box)(({ theme }) => ({}));
