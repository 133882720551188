import { Box, Button, ButtonProps, Chip, ChipProps, styled } from "@mui/material";

export const PricingTableBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
}));

type PricingTableItemButtonProp = ButtonProps & { mostPopular?: boolean; selected?: boolean };

export const PricingTableItemButton = styled(({ mostPopular, selected, ...props }: PricingTableItemButtonProp) => <Button {...props} />)(
  ({ mostPopular, selected, theme }) => ({
    display: "block",
    border: selected ? `3px solid ${theme.palette.biarri.secondary.activeBlue}` : "1px solid rgba(0, 0, 0, 0.12)",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderRadius: mostPopular ? "0 0 4px 4px" : "4px",

    "&:hover": {
      boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    },
  })
);

export const PopularLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.5),
  borderRadius: "4px 4px 0 0",
  backgroundColor: theme.palette.primary.main,
}));

export const CurrentChip = styled((props: ChipProps) => <Chip label="Current" size="small" color="success" {...props} />)(({ theme }) => ({
  color: theme.palette.common.white,
}));
