import React from "react";
import { useNavigate } from "react-router";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteFolderMutation } from "fond/api";
import { Folder } from "fond/types";
import { Message, Modal } from "fond/widgets";

interface IProps {
  folder: Folder;
  onClose(): void;
}

const DeleteFolderModal: React.FC<IProps> = ({ folder, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [deleteFolder, { isLoading, isError, error }] = useDeleteFolderMutation();

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    deleteFolder(folder)
      .unwrap()
      .then(() => {
        onClose();
        enqueueSnackbar("The folder has been deleted");

        // Navigate to the updated folder
        if (folder?.ParentID) {
          navigate(`/folders/${folder.ParentID}/`);
        }
      });
  };

  return (
    <Modal
      open
      header="Delete this folder"
      data-testid="delete-folder-modal"
      content={
        <>
          {isError && (
            <Box mb={2}>
              {(error as any)?.status === 403 ? (
                <Message type="error" data-testid="error">
                  A folder with content cannot be deleted. Please delete the folder contents and try again.
                </Message>
              ) : (
                <Message type="error" data-testid="error">
                  There was an issue deleting this folder. Please try again.
                </Message>
              )}
            </Box>
          )}
          <Typography>
            Are you sure you wish to delete the folder <strong>{folder.Name}</strong>?
          </Typography>
          <Typography>The folder will be removed for all users within this account.</Typography>
        </>
      }
      actions={
        <>
          <Button data-testid="delete-folder-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="delete-folder-save-button" color="primary" onClick={handleOnClick} loading={isLoading}>
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default DeleteFolderModal;
