import { LineItem, PaymentFrequency, Product } from "fond/types/stripe";
/**
 * Generates subscription line item
 * @param products All available products
 * @param billingPeriod Billing period to determine price to use
 * @param subscriptionProductId Product id for selected tier
 * @returns {LineItem} A line item of a subscription tier product with quantity 1
 */
export const getSubscriptionLineItem = (
  products: Product[] | undefined,
  billingPeriod: PaymentFrequency,
  subscriptionProductId: string
): LineItem | undefined => {
  const selectedTier = products?.find((product) => product.ID === subscriptionProductId);
  const subscriptionPrice = selectedTier?.Prices.find((price) => price.PaymentFrequency === billingPeriod);
  if (!subscriptionPrice) {
    return undefined;
  }

  return {
    PriceID: subscriptionPrice.StripePriceID,
    Quantity: 1,
  };
};

export const getLicenseLineItem = (products: Product[] | undefined, billingPeriod: PaymentFrequency, numLicenses: number): LineItem | undefined => {
  const license = products?.find((product) => product.Category === "license");
  const licensePrice = license?.Prices.find((price) => price.PaymentFrequency === billingPeriod);
  if (!licensePrice) {
    return undefined;
  }

  return numLicenses > 0
    ? {
        PriceID: licensePrice.StripePriceID,
        Quantity: numLicenses,
      }
    : undefined;
};
