import React from "react";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { RawDraftContentState, RawDraftEntity } from "draft-js";
import _ from "lodash";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
      maxWidth: 290,
    },
  });
};
interface IProps extends WithStyles<typeof customStyles> {
  users: User[];
  mentions: RawDraftContentState | null;
}

interface User {
  id: string;
  name: string;
}

const NewUserNotification: React.FC<IProps> = ({ classes, users, mentions }: IProps) => {
  let newMentionedUsers: string[] = [];

  // Wait for mentions to exist
  if (mentions) {
    // Check mentions for both emails added with @ and +
    const userMentions = Object.values(mentions.entityMap).filter((value: { type: string }) => value.type === "mention" || value.type === "+mention");
    const mentionedUsers = userMentions.map((newMention: RawDraftEntity) => newMention.data.mention.name);
    const sharedUsers = users.map((user: User) => user.name);
    // Checking for the difference between the list of users who have access to the project and the mentioned users
    newMentionedUsers = _.difference(mentionedUsers, sharedUsers);
  }
  // If there is a difference, return a message stating that user will be added to the project
  return (
    <>
      {newMentionedUsers.length > 0 && (
        <Box className={classes.root}>
          <Typography variant="caption">
            {`${newMentionedUsers.join(", ")} ${newMentionedUsers.length > 1 ? "do" : "does"} not have access to this project. `}
          </Typography>
          <Typography variant="caption">Your @mention will add them to the project as a view only user.</Typography>
        </Box>
      )}
    </>
  );
};

export default withStyles(customStyles)(NewUserNotification);
