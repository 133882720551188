import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { PRIVACY_URL, TOS_URL } from "fond/constants";

/**
 * Footer to be displayed on relevant pages that contains the
 * terms and condition / privacy policy links.
 */
function Footer(): React.ReactElement {
  return (
    <Typography variant="caption">
      &copy; Biarri Networks &middot;
      <Link color="inherit" target="_blank" rel="noopener noreferrer" href={TOS_URL}>
        Terms
      </Link>{" "}
      &middot;{" "}
      <Link color="inherit" target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
        Privacy
      </Link>
    </Typography>
  );
}

export default Footer;
