import { FC } from "react";
import { Link, Typography } from "@mui/material";

import { HELP_CENTER_URL } from "fond/constants";
import ErrorPage from "fond/errorPage/ErrorPage";
import { PageNotFoundIcon } from "fond/svg_icons";

/**
 * The component to show in the case that the URL can't be matched to a component
 * by the router in index.js, i.e. a 404.
 */
const NoMatch: FC = () => (
  <ErrorPage
    title="Page not found"
    icon={<PageNotFoundIcon />}
    heading="Page not found"
    message={
      <Typography data-testid="no-match-found">
        We're sorry, we can't find the page you're looking for. If you still need help, visit our&nbsp;
        <Link color="primary" target="_blank" rel="noopener noreferrer" href={HELP_CENTER_URL}>
          <b>Help Center</b>
        </Link>
        .
      </Typography>
    }
  />
);

export default NoMatch;
