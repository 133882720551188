import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, PopoverOrigin } from "@mui/material";

import MultiProjectMenu from "./MultiProjectMenu";
import ProjectMenu from "./ProjectMenu";

import { Container } from "./PageMenu.styles";

interface IProps {
  type: "project" | "multiProject";
}

const PageMenu: React.FC<IProps> = ({ type }: IProps) => {
  const [open, setOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const anchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };

  /**
   * Callback function that opens the menu.
   */
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setOpen(true);
  };

  /**
   * Function that closes clear the timeout.
   */
  const clear = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(null);
  }, [timeoutId]);

  /**
   * Callback function that closes the menu.
   */
  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
    setOpen(false);
    clear();
  }, [clear]);

  /**
   * Callback function that starts the timeout when a mouse leave event is triggered. This is to support the behavior that the menu will be closed
   * after the set time(2s) when the user moves away from the menu system.
   */
  const handleMouseLeave = () => {
    if (open) {
      clear();
      const newTimeoutId = setTimeout(() => {
        handleClose();
      }, 2000);
      setTimeoutId(newTimeoutId);
    }
  };

  /**
   * Callback function that clears the timeout when a mouse enter event is triggered. This is to support the behavior that the menu will be closed
   * after the set time(2s) when the user moves away from the menu system.
   */
  const handleMouseEnter = () => {
    if (open) {
      clear();
    }
  };

  /**
   * As we disabled the menu focus trap (with pointerEvents: "none") to allow users to focus on other menus while one menu is opened,
   * we can no longer listen to dropbackClick events to close the menu. This useEffect detects any mouseclick event outside the menu element
   * and triggers a menu close action.
   */
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /**
     * Close the menu if clicked outside
     */
    const handleClickOutside = () => {
      if (menuRef.current && window.event?.target instanceof Element && !menuRef.current.contains(window.event?.target)) {
        handleClose();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose, menuRef]);

  const Menu = type === "multiProject" ? MultiProjectMenu : ProjectMenu;

  return (
    <Container data-testid="project-menu-bar">
      <Box width="100%" display="flex" justifyContent="space-between" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Box ml={1.5} display="flex" alignItems="center" flexDirection="row" ref={menuRef}>
          <Menu anchorOrigin={anchorOrigin} open={open} menuAnchorEl={menuAnchorEl} onClose={handleClose} onMenuClick={handleMenuClick} />
        </Box>
      </Box>
    </Container>
  );
};

export default PageMenu;
