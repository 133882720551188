import React from "react";
import { FileRejection } from "react-dropzone";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

import { Modal } from "fond/widgets";

interface IProps extends WithStyles<typeof customStyles> {
  onClose: () => void;
  rejectedFile: FileRejection;
}

const customStyles = (theme: Theme) => {
  return createStyles({
    rejectedFileModalContent: {
      overflow: "hidden",
    },
    rejectedFileList: {
      maxHeight: "50vh",
      overflowY: "auto",
      marginTop: 10,
    },
  });
};

const RejectedMappingFileModal: React.FC<IProps> = ({ classes, onClose, rejectedFile }: IProps) => {
  return (
    <Modal
      data-testid="attachment-mapping-reject-drop-modal"
      open
      header="File could not be accepted"
      onClose={onClose}
      DialogContentProps={{ className: classes.rejectedFileModalContent }}
      content={
        <Box>
          <Box>The file can not be accepted. Only csv file allowed. </Box>
          <Box className={classNames(classes.rejectedFileList, "customScrollbars")}>
            <ul>
              <li key={rejectedFile?.file.name}>{rejectedFile?.file.name}</li>
            </ul>
          </Box>
        </Box>
      }
      actions={
        <Button data-testid="dropzone-reject-file-modal-close-button" color="primary" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

RejectedMappingFileModal.displayName = "RejectedMappingFileModal";
export default withStyles(customStyles)(RejectedMappingFileModal);
