import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WarningAmber } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";

import { FullReport } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";
import { Page } from "fond/views/Report/constants";

import { Alert, ShowWarningButton } from "./ReportCostPassingsWarning.styles";

interface IProps {
  report: FullReport;
}

const ReportCostPassingsWarning: React.FC<IProps> = ({ report }: IProps) => {
  const { TotalConstructionCost, Passings } = report;
  const missingPassings = TotalConstructionCost && TotalConstructionCost > 0 && !Passings?.SharedCost;
  const navigate = useNavigate();

  const [collapse, setCollapse] = useState<boolean>(getItem(`${report.ID}_collapse_missing_passings_warnings`, false));

  useEffect(() => {
    setItem(`${report.ID}_collapse_missing_passings_warnings`, collapse);
  }, [collapse, report.ID]);

  return (
    <>
      {Boolean(missingPassings) && collapse && (
        <Tooltip title="Show warnings of the report cost page">
          <ShowWarningButton
            onClick={() => {
              setCollapse(false);
            }}
          >
            <WarningAmber color="warning" />
          </ShowWarningButton>
        </Tooltip>
      )}
      {Boolean(missingPassings) && !collapse && (
        <Alert
          onClose={() => {
            setCollapse(true);
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            The passings and service location data are outdated, re-run the report to refresh.
            <Button
              color="inherit"
              onClick={() => {
                navigate(`/reports/${report.ID}/${Page.SETTINGS}`);
              }}
            >
              SETTINGS
            </Button>
          </Box>
        </Alert>
      )}
    </>
  );
};

export default ReportCostPassingsWarning;
