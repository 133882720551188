import _ from "lodash";

import { enumerate, formatLength, getIn, toMeters } from "fond/utils";

import { applyRuleIf } from "./fieldRules";

/**
 * Here, `restrictions` and `restriction` are as defined in archrestrictions.py in
 * fond_customer_configs (currently in unmerged branch fd-422).
 */

function lastTabIndex(widgetArchitecture) {
  return widgetArchitecture.NumberOfTiers + 2;
}

export function applyBomRestrictions(fieldRules, restrictions) {
  /**
   * Takes the `fieldRules` from `context.js`, and a list of BOM restrictions,
   * and returns a new copy of `fieldRules` with extra rules added reflecting
   * the restrictions.
   *
   * Eg. if `restrictions` is:
   *    [[['Tier2', 'ConnectorizedDropTerminals', 'Enabled'], '==', false]]
   * then the new output will have a new rule at `.Tier2.ConnectorizedDropTerminals.Enabled`
   * that will raise a warning if connectorized drop terminals are enabled.
   */
  const fieldRulesCopy = _.cloneDeep(fieldRules);

  for (let restriction of restrictions) {
    const path = restriction[0];

    /**
    fieldRules looks like:

      {
        Tier1: {
          Cables: { // A section
            Sizes: [ // A field
              rule 1,
              rule 2,
              ...
            ],
            ...
          },
          ...
        },
        ...
      }

    However sections & fields will not already exist if there are no rules for them.
    */

    // Make sure the section (eg Tier1.Cables) exists.
    if (getIn(fieldRulesCopy, path.slice(2)) == null) {
      _.set(fieldRulesCopy, path.slice(2), {});
    }

    // Make sure the field (eg. Tier1.Cables.Sizes) exists.
    if (getIn(fieldRulesCopy, path) == null) {
      _.set(fieldRulesCopy, path, []);
    }

    // Place the new rule after any existing error rules but before any
    // existing warning rules.
    let pathRules = getIn(fieldRulesCopy, path);
    pathRules.splice(
      _.findIndex(pathRules, (r) => r.state === "warning"),
      0,
      // We currently only do arch-BOM validation for projects without custom
      // BOMs (ie. using Biarri-created rulesets). We also only show these
      // validation warnings when the user has visited the BOM tab for the
      // architecture at least once.
      applyRuleIf(
        (arch, state) => {
          return state.highestConfiguredTabIndex === lastTabIndex(arch) && arch.BOM == null;
        },
        {
          ...makeRule(restriction),
          state: "warning",
        }
      )
    );
  }

  return fieldRulesCopy;
}

/**
 * Converts a restriction into a rule object similar to those in rules.js,
 * except with an additional `bomMessage` property which is used to display a
 * message on the BOM page.
 */
export function makeRule(restriction) {
  let [path, operator, ...args] = restriction;

  switch (operator) {
    case "all_in": {
      // For all_in, there's one argument, which is a list of the allowed
      // values.
      const allowedValues = args[0];
      const { tierNum, hubOrCable } = parseHubOrCablePath(path);

      return {
        condition: (val) => val.some((v) => !allowedValues.includes(v)),
        message: (val) => {
          return (
            <span>
              Currently, only {hubOrCable} sizes of {formatList(allowedValues)} are counted in your BOM. You can continue designing without updating
              your BOM.
            </span>
          );
        },
        bomMessage: (val) => {
          const invalidValues = val.filter((v) => !allowedValues.includes(v));
          return (
            <span>
              Tier {tierNum} {hubOrCable} sizes of {formatList(invalidValues)}; only {hubOrCable} sizes of {formatList(allowedValues)} are counted.
            </span>
          );
        },
      };
    }
    case "all_between": {
      const [low, high] = args;
      const { hubOrCable } = parseHubOrCablePath(path);

      return {
        condition: (val) => val.some((v) => v < low || v > high),
        message: (val) => {
          return (
            <span>
              Currently, only {hubOrCable} sizes between {low} and {high} are counted in your BOM. You can continue designing without updating your
              BOM.
            </span>
          );
        },
        bomMessage: (val) => {
          const invalidValues = val.filter((v) => v < low || v > high);
          return (
            <span>
              {formatField(path)} of {formatList(invalidValues)}; only values between {low} and {high} are counted.
            </span>
          );
        },
      };
    }
    case "==": {
      const [requiredVal] = args;
      if (!_.isEqual(path, ["Tier2", "ConnectorizedDropTerminals", "Enabled"])) {
        throw new Error("Currently the `==` rules can only apply to Tier2.ConnectorizedDropTerminals.Enabled");
      }

      return {
        condition: (val) => val !== requiredVal,
        message: (
          <span>Currently, your BOM isn't configured to count connectorized drop hubs. You can continue designing without updating your BOM.</span>
        ),
        bomMessage: (val) => <span>{formatField(path)}.</span>,
      };
    }
    case "<=": {
      /**
       * Note: we're assuming here that the <= operator is only used for
       * lengths (also, none of the other operators are used with lengths).
       * The "required val" (ie. the value that appears in the rule) is
       * expected to be in meters, but the user-input `val`s we get passed in
       * to the `condition`, `message` and `bomMessage` are in the project's
       * system of measurement.
       */
      const [requiredVal] = args;

      let itemName;
      if (_.isEqual(path, ["Tier1", "DropRules", "DropLength"])) {
        itemName = "drops";
      } else if (_.isEqual(path, ["Tier2", "ConnectorizedDropTerminals", "MaxTailLength"])) {
        itemName = "connectorized drop hub tails";
      } else {
        throw new Error("Currently `<=` rules can only apply to Tier1.DropRules.DropLength and Tier2.ConnectorizedDropTerminals.MaxTailLength");
      }

      return {
        condition: (val, arch, state, systemOfMeasurement) => {
          return toMeters(parseFloat(val), { from: systemOfMeasurement }) > requiredVal;
        },
        message: (val, arch, state, systemOfMeasurement) => {
          return (
            <span>
              Currently, your BOM is only counting {itemName} with lengths less than or equal to{" "}
              {formatLength(requiredVal, { from: "metric", to: systemOfMeasurement })}. You can continue designing without updating your BOM.
            </span>
          );
        },
        bomMessage: (val, systemOfMeasurement) => {
          const length = formatLength(requiredVal, { from: "metric", to: systemOfMeasurement });
          return (
            <span>
              {formatField(path)} greater than {length}; only {itemName} less than or equal to {length} are counted.
            </span>
          );
        },
      };
    }
    default:
      throw new Error(operator);
  }
}

/**
 * Eg.
 * >>> formatList([1, 2, 3])
 * '1, 2 and 3'
 *
 * >>> formatList([1, 2])
 * '1 and 2'
 *
 * >>> formatList([1])
 * '1'
 *
 * >>> formatList([])
 * ''
 */
function formatList(items) {
  let s = "";
  for (let [i, item] of enumerate(items)) {
    s += item;
    if (i === items.length - 2) {
      s += " and ";
    } else if (i < items.length - 2) {
      s += ", ";
    }
  }
  return s;
}

/**
 * Used in the messages on the BOM side.
 */
function formatField(path) {
  if (_.isEqual(path, ["Tier2", "ConnectorizedDropTerminals", "MaxTailLength"])) {
    return "Drop hub tails (e.g MST tails)";
  } else if (_.isEqual(path, ["Tier2", "ConnectorizedDropTerminals", "Enabled"])) {
    return "Connectorized drop hubs (e.g MSTs)";
  } else if (_.isEqual(path, ["Tier1", "DropRules", "DropLength"])) {
    return "Drop cable length";
  } else {
    return path.join(" ");
  }
}

/**
 * >>> parseHubOrCablePath(['Tier1', 'Cables', 'Sizes'])
 * {tierNum: 1, hubOrCable: 'cable'}
 *
 * >>> parseHubOrCablePath(['Tier1', 'SomethingElse', 'Sizes'])
 * <throws an error>
 */
function parseHubOrCablePath(path) {
  let [tierName, hubOrCable] = path;
  const tierNum = parseInt(tierName.substr("Tier".length));
  if (hubOrCable === "Hubs") {
    hubOrCable = "hub";
  } else if (hubOrCable === "Cables") {
    hubOrCable = "cable";
  } else {
    throw new Error("Expected the second path component to be 'Hubs' or 'Cables'");
  }

  return { tierNum, hubOrCable };
}
