import { Button, styled } from "@mui/material";

export const ViewButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  fontSize: 12,
  lineHeight: 1,
  color: theme.palette.text.primary,
  "&:not(.Mui-disabled) svg": {
    color: theme.palette.primary.main,
  },
  textAlign: "left",
}));
