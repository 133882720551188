import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AccessTimeFilled, Dashboard, Star } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useTheme } from "@mui/material/styles";

import { selectCurrentAccount } from "fond/api";
import { SEARCH_KEY } from "fond/constants";
import { ProjectsView, Store } from "fond/types";
import { useAppDispatch, useQueryParams } from "fond/utils/hooks";
import { NavigationContainer, NavigationItem } from "fond/widgets";
import SharedWithMeNavigationItem from "fond/widgets/Navigation/SharedWithMeNavigationItem";

import { clearSharedAccount, updateFilter } from "../redux";

import { StyledDivider } from "./navigation.styles";

const ProjectsNavigation: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = useQueryParams(SEARCH_KEY);
  const account = useSelector(selectCurrentAccount);
  const { filters, account: filteredAccount } = useSelector((state: Store) => state.projects);

  React.useEffect(() => {
    // matches first part of path
    // .com/{match}/foo/bar
    const regex = /\/(workspace|shared|recent|starred).*/;
    const match = location.pathname.match(regex);
    const filter = match && match[1];

    // clear selected account
    if (filter === "workspace") dispatch(clearSharedAccount);
    // catch up if user uses browser navigation
    if ((filter === "workspace" && !searchQuery) || filter === "shared" || filter === "recent" || filter === "starred")
      dispatch(updateFilter(filter));
  }, [dispatch, location.pathname, searchQuery]);

  const handleOnClick = (filter: ProjectsView) => {
    dispatch(updateFilter(filter));
    navigate(`/${filter}/`);
  };

  return (
    <NavigationContainer testId="project-navigation">
      {account && (
        <>
          <NavigationItem
            selected={filters === "workspace" && !searchQuery}
            onClick={() => handleOnClick("workspace")}
            testId="navigation-item-workspace"
            icon={<Dashboard />}
            title="Main Workspace"
          />
          <StyledDivider />
        </>
      )}

      <SharedWithMeNavigationItem
        selected={filters === "shared" && !filteredAccount}
        onClick={() => {
          dispatch(clearSharedAccount);
          handleOnClick("shared");
        }}
        testId="navigation-item-shared"
        title="Shared with me"
        icon={<PeopleAltIcon />}
      />
      <StyledDivider sx={{ mb: theme.spacing(2) }} />
      <NavigationItem
        selected={filters === "recent" && !searchQuery}
        onClick={() => handleOnClick("recent")}
        testId="navigation-item-recent"
        icon={<AccessTimeFilled />}
        title="Recent"
      />
      <NavigationItem
        selected={filters === "starred" && !searchQuery}
        onClick={() => handleOnClick("starred")}
        testId="navigation-item-starred"
        icon={<Star />}
        title="Starred"
      />
    </NavigationContainer>
  );
};

export default React.memo(ProjectsNavigation);
