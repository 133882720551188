import mapboxgl from "mapbox-gl";

import * as API from "fond/api";
import { FeaturesActionTypes, GET_FEATURE_FAILURE, GET_FEATURE_REQUEST, GET_FEATURE_SUCCESS } from "fond/redux/features";
import { AppThunk } from "fond/types";

/**
 * GET Feature
 */

const getFeatureRequest = (): FeaturesActionTypes.GetFeatureRequest => ({ type: GET_FEATURE_REQUEST });
const getFeatureSuccess = (payload: mapboxgl.MapboxGeoJSONFeature): FeaturesActionTypes.GetFeatureSuccess => ({
  type: GET_FEATURE_SUCCESS,
  payload,
});
const getFeatureFailure = (error: Error): FeaturesActionTypes.GetFeatureFailure => ({ type: GET_FEATURE_FAILURE, error });

export const getFeature =
  (featureId: string): AppThunk<Promise<mapboxgl.MapboxGeoJSONFeature>> =>
  async (dispatch) => {
    dispatch(getFeatureRequest());

    return API.getFeature(featureId).then(
      (feature) => {
        dispatch(getFeatureSuccess(feature));
        return Promise.resolve(feature);
      },
      (error: Error) => {
        dispatch(getFeatureFailure(error));
        return Promise.reject(error);
      }
    );
  };
