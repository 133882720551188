import React from "react";
import { Navigate } from "react-router";
import { Auth } from "@aws-amplify/auth";

import mixpanel from "fond/mixpanel";

import { stopImpersonating } from "../impersonate";

/**
 * When rendered, signs the user out and redirects the user
 * to the /signin route.
 */
const SignOut: React.FC = () => {
  stopImpersonating();
  Auth.signOut();
  mixpanel.track("Signed out");
  mixpanel.reset();
  sessionStorage.clear();
  return <Navigate to="/signin" />;
};

export default SignOut;
