import React from "react";
import { SetFilterModel } from "@ag-grid-community/core";
import { Box, Breadcrumbs, Link } from "@mui/material";

interface IProps {
  navigation: Array<NavigationItem>;
  navigateBackTo(value: number): void;
}

export interface NavigationItem {
  label: string;
  filter: {
    [key: string]: SetFilterModel;
  };
}

const ReportChartNavigation: React.FC<IProps> = ({ navigation, navigateBackTo }: IProps) => {
  return (
    <Box px={2.5} pt={3}>
      <Breadcrumbs>
        {navigation.map((item, index: number) => {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              component="button"
              color="inherit"
              key={item.label}
              onClick={() => {
                // Only navigate if the user didn't click on the current level
                if (index !== navigation.length - 1) {
                  navigateBackTo(index - (navigation.length - 1));
                }
              }}
              underline="hover"
            >
              {item.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default ReportChartNavigation;
