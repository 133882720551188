import React, { useState } from "react";
import { Delete, Edit, FileCopyOutlined, Redo, Share as ShareIcon } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { useUpdateProjectMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import CopyProjectDialog from "fond/project/CopyProjectDialog";
import MoveProjectDialog from "fond/project/MoveProjectDialog";
import RemoveProjectDialog from "fond/project/RemoveProjectDialog";
import Share from "fond/share/Share";
import { Project, ProjectPreview } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { replaceAllSpecialCharacters } from "fond/utils/strings";
import { required } from "fond/utils/validation";
import { RenameModal } from "fond/widgets";

import StarMenuItem from "./StarMenuItem";

interface IProps extends Pick<MenuProps, "anchorEl"> {
  /**
   * The project the menu actions relate to
   */
  projectPreview: ProjectPreview;
  onMenuClose: () => void;
}

type ProjectModal = "delete" | "copy" | "move" | "rename" | "share";
const ProjectRowMenu: React.FC<IProps> = ({ projectPreview, onMenuClose, anchorEl }: IProps) => {
  const [showModal, setShowModal] = useState<ProjectModal>();
  const { enqueueSnackbar } = useSnackbar();
  const [updateProject] = useUpdateProjectMutation();

  const canCopyProject = usePermissionCheck(Actions.PROJECT_COPY, projectPreview.Permission.Level);
  const canRenameProject = usePermissionCheck(Actions.PROJECT_RENAME, projectPreview.Permission.Level);
  const canMoveProject = usePermissionCheck(Actions.PROJECT_MOVE, projectPreview.Permission.Level);
  const canDeleteProject = usePermissionCheck(Actions.PROJECT_DELETE, projectPreview.Permission.Level);
  const canShareProject = usePermissionCheck(Actions.PROJECT_SHARE_VIEW, projectPreview.Permission.Level);
  /**
   * Generic Callback function that updates any of the modal open states and closes the menu
   */
  const openModal = (modalType: ProjectModal) => () => {
    onMenuClose();
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(undefined);
  };

  const handleRenameSubmit = async (value: { name: string }) => {
    if (value.name !== (projectPreview as ProjectPreview).ProjectName) {
      try {
        await updateProject({ ID: projectPreview.ID, ProjectName: value.name }).unwrap();
      } catch {
        enqueueSnackbar("Project rename failed. Please try again...", { variant: "error" });
      } finally {
        closeModal();
      }
    }
  };

  return (
    <>
      <Menu
        data-testid={`project-list-item-menu-${replaceAllSpecialCharacters(projectPreview.ProjectName, "")}`}
        anchorEl={anchorEl}
        open={!!anchorEl}
        keepMounted
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          aria-label={projectPreview.HasCustomLayerConfig ? "Unable to copy projects with custom layers" : "Copy"}
          data-testid="copy-button"
          onClick={openModal("copy")}
          disabled={projectPreview.HasCustomLayerConfig || !canCopyProject}
        >
          <ListItemIcon>
            <FileCopyOutlined fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Copy</Typography>
        </MenuItem>

        <MenuItem aria-label="Rename" data-testid="rename-button" onClick={openModal("rename")} disabled={!canRenameProject}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Rename</Typography>
        </MenuItem>

        <MenuItem aria-label="Move" data-testid="move-button" onClick={openModal("move")} disabled={!canMoveProject}>
          <ListItemIcon>
            <Redo fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Move</Typography>
        </MenuItem>

        {projectPreview.Deletable && (
          <MenuItem aria-label="Delete" data-testid="delete-button" onClick={openModal("delete")} disabled={!canDeleteProject}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
        )}

        <MenuItem aria-label="Share" onClick={openModal("share")} data-testid="share-button" disabled={!canShareProject}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <Typography variant="inherit">Share</Typography>
        </MenuItem>
        <StarMenuItem onMenuClose={onMenuClose} resource={projectPreview} />
      </Menu>
      {showModal === "delete" && <RemoveProjectDialog project={projectPreview} closeModalCallback={closeModal} />}
      {showModal === "copy" && <CopyProjectDialog project={projectPreview} closeModalCallback={closeModal} />}
      {showModal === "move" && <MoveProjectDialog project={projectPreview} onClose={closeModal} />}
      {showModal === "rename" && (
        <RenameModal
          entity={projectPreview}
          title="Rename project"
          defaultValue={projectPreview?.ProjectName || "Untitled project"}
          onRename={(formData) => handleRenameSubmit(formData)}
          onClose={closeModal}
          onValidate={required}
        />
      )}
      {showModal === "share" && <Share resource={projectPreview as Project} onClose={closeModal} />}
    </>
  );
};

export default ProjectRowMenu;
