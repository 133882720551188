import { Schedules, SchedulesData } from "fond/types";

import { VisualizationTypes } from "../VisualizationTypes";

/**
 * TODO - We could look to the BE returning the data in the correct format to avoid this conversion.
 */

/**
 * Transform cashflow data to the required format for display
 */
export const transformCashflow = (data?: SchedulesData[Schedules.Cashflow]): Record<string, string | number>[] =>
  VisualizationTypes.Cashflow.dataPoints.map((row) => {
    // For each row in data we create a new object
    const rowValue: Record<string, string | number> = {
      Key: row.name,
    };

    data?.forEach((item) => {
      rowValue[item.Phase] = item[row.key];
    });

    return rowValue;
  });

/**
 * Transform cost data to the required format for display
 */
export const transformCost = (
  tags: string[],
  phases: string[],
  data?: SchedulesData[Schedules.Cost]
): Record<string, string | number | undefined>[] =>
  tags
    .map((tag) => {
      return VisualizationTypes.Cost.dataPoints.map((row) => {
        // For each row in data we create a new object
        const rowValue: Record<string, string | number | undefined> = {
          Key: row.name,
          Tag: tag,
        };

        phases.forEach((phase) => {
          rowValue[phase] = data?.find((value) => value.Phase === phase && value.Tag === tag)?.[row.key];
        });
        return rowValue;
      });
    })
    .flat();

/**
 * Transform data grouped by AddressType to the required format for display
 */
export function addressTypeTransformer<T>(
  key: keyof typeof Schedules,
  addressTypes: string[],
  phases: string[],
  data?: T
): Record<string, string | number | undefined>[] {
  return addressTypes
    .map((type) => {
      return VisualizationTypes[key].dataPoints.map((row) => {
        // For each row in data we create a new object
        const rowValue: Record<string, string | number | undefined> = {
          Key: row.name,
          AddressType: type,
        };

        if (Array.isArray(data)) {
          phases.forEach((phase) => {
            rowValue[phase] = data?.find((value) => value.Phase === phase && value.AddressType === type)?.[row.key];
          });
        }
        return rowValue;
      });
    })
    .flat();
}
