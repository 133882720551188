import { createRoot } from "react-dom/client";

import { App } from "./app";

if (process.env.REACT_APP_API_MOCKING === "enabled" && process.env.NODE_ENV === "development") {
  const { worker } = await import("./__mocks__/browser");
  await worker.start();
}

createRoot(document.getElementById("root")!).render(<App />);
