import React from "react";
import { Grid, GridProps, Paper, RegularBreakpoints, Typography } from "@mui/material";

import { PaperHeader } from "./report.styles";

interface IProps extends GridProps {
  breakpoints: RegularBreakpoints;
  title?: string;
  headerRightElement?: React.ReactNode;
  children?: React.ReactNode | Array<React.ReactNode>;
}

const GridCard: React.FC<IProps> = ({ breakpoints, title, headerRightElement, children, ...rest }) => (
  <Grid data-testid="summary-card" item {...breakpoints} {...rest}>
    <Paper variant="elevation" elevation={1} square={false} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {title && (
        <PaperHeader>
          <Typography component="h6" fontWeight={700}>
            {title}
          </Typography>
          {headerRightElement}
        </PaperHeader>
      )}
      {children}
    </Paper>
  </Grid>
);

export default GridCard;
