import React from "react";

import { AccountManagement as AccountManagementContent, Navigation } from "fond/settings";
import { PageContent } from "fond/widgets";
/**
 * A top level component used for displaying Account Settings.
 */
const AccountManagement: React.FC = () => {
  return <PageContent title="Account Settings" mainContent={<AccountManagementContent />} navigation={<Navigation />} />;
};

export default AccountManagement;
