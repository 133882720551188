import React from "react";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, selectCurrentSubscription, useGetAccountSubscriptionsQuery } from "fond/api";
import { Navigation } from "fond/settings";
import { Store, Subscription } from "fond/types";
import { NonIdealState, PageContent } from "fond/widgets";

import InsightsPage from "./InsightsPage/InsightsPage";
import SubscriptionPeriod from "./SubscriptionPeriod/SubscriptionPeriod";

/**
 * A top level component used for displaying Insights Settings.
 */
const Insights: React.FC = () => {
  const account = useSelector(selectCurrentAccount);
  useGetAccountSubscriptionsQuery(account?.ID ?? skipToken, { refetchOnMountOrArgChange: true });
  const subscription = useSelector((state: Store): Subscription | null => (account?.ID && selectCurrentSubscription(state, account.ID)) || null);

  return (
    <>
      {account && subscription ? (
        <PageContent
          title="Insights"
          subTitle="DASHBOARD"
          action={<SubscriptionPeriod subscription={subscription} />}
          mainContent={<InsightsPage subscription={subscription} />}
          navigation={<Navigation />}
        />
      ) : (
        <PageContent
          testId="insights-page-not-available"
          title="Insights"
          mainContent={<NonIdealState title="Oops" description="This page is currently not available." icon={<Info />} />}
          navigation={<Navigation />}
        />
      )}
    </>
  );
};

export default Insights;
