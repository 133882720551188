import React, { useCallback, useEffect, useRef } from "react";
import trimCanvas from "trim-canvas";

import { useGetIconsQuery } from "fond/api";
import { LayerStyle } from "fond/types/ProjectLayerConfig";

import { CANVAS_HEIGHT, CANVAS_WIDTH, createCanvas, drawCircle, drawFill, drawFontIcon, drawLineString, drawSymbol, SymbolIcon } from "./helper";

import { Container } from "./StyleIcon.styles";

interface IProps {
  styles: LayerStyle[];
}

const StyleIcon: React.FC<IProps> = ({ styles }: IProps) => {
  const { data: icons } = useGetIconsQuery(undefined);
  const divRef = useRef<HTMLDivElement>(null);

  /**
   * We need to pre-load all symbol images prior to drawing the canvas as
   * we need to draw all styles before finally trimming the canvas.
   */
  const loadImages = useCallback(async () => {
    const imagePromises: Promise<SymbolIcon>[] = [];
    styles.forEach((style) => {
      // Find all symbol styles that have an icon image specified
      if (style.RawStyles.Type === "symbol" && style.RawStyles.IconImageID) {
        // Get the base64 data for the image
        const src = style.RawStyles.IconImageID ? icons?.find((base) => base.ID === style.RawStyles.IconImageID)?.GeneratedPng : undefined;

        // Create a promise that loads the image & extracts the HTMLImageElement
        // the the width and height to be used during canvas.drawImage()
        imagePromises.push(
          new Promise((fulfill, reject) => {
            let img = new Image(20, 20);
            img.onload = () => fulfill({ ID: style.RawStyles.IconImageID, Image: img, Width: img.width, Height: img.height });
            img.src = `data:image/png;base64,${src}`;
          })
        );
      }
    });

    // Await all images being loaded & return the results.
    return Promise.all(imagePromises).then((response) => {
      return response;
    });
  }, [icons, styles]);

  useEffect(() => {
    const load = async () => {
      const loadedIcons = await loadImages();
      const canvas = createCanvas(CANVAS_WIDTH, CANVAS_HEIGHT, true);
      const ctx = canvas.getContext("2d");

      if (ctx) {
        // All icons are based off being centered so shift canvas origin to match that
        ctx.translate(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);

        [...styles].reverse().forEach(async (style) => {
          if (style.RawStyles?.Type === "circle") {
            drawCircle(ctx, style);
          } else if (style.RawStyles?.Type === "icon") {
            drawFontIcon(ctx, style);
          } else if (style.RawStyles?.Type === "line") {
            drawLineString(ctx, style, styles);
          } else if (style.RawStyles?.Type === "symbol") {
            drawSymbol(ctx, style, loadedIcons);
          } else if (style.RawStyles?.Type === "fill") {
            drawFill(ctx, style);
          }
        });

        /**
         * Since we draw our canvas much larger to accommodate the mapbox styles,
         * on render we trim the excess white spaces around the canvas & then resize
         * the canvas down to the icon size.
         */
        divRef.current?.replaceChildren(trimCanvas(canvas));
      }
    };

    load();
  }, [icons, styles, loadImages]);

  return <Container ref={divRef} style={{ width: 24, height: 24 }} data-testid="style-icon" />;
};
export default StyleIcon;
