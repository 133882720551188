import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

interface IProps {
  isHidden: boolean;
  update: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisibilityAdornment: React.FC<IProps> = ({ isHidden, update }: IProps) => (
  <InputAdornment position="end">
    <IconButton aria-label="toggle password visibility" onClick={() => update((prevIsHidden) => !prevIsHidden)} edge="end">
      {isHidden ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);

export default VisibilityAdornment;
