import mixpanel, { Dict, Mixpanel } from "mixpanel-browser";

/**
 * Initializes mixpanel with a specific mixpanel project. This needs to be called before events
 * can be captured.
 * @param {string} token - mixpanel api token.
 */
export function init(token: string): void {
  // A mixpanel token is required
  // 'no_mixpanel_token' is acceptable, but the token must
  // be specifically set.
  if (!token || token.trim() === "") {
    throw new Error("Mixpanel token is required.");
  }

  mixpanel.init(token, {
    loaded: (mp: Mixpanel) => {
      /**
       * We currently disable mixpanel event tracking for all stages except production
       * to avoid paying for monitoring we don't need.
       */
      if (token === "no_mixpanel_token") {
        mp.disable();
      }
    },
  });
}

/**
 * Wraps the mixpanel track function. Automatically inserts the projectId into properties
 * if the event is triggered from a page that has /project/projectId in the url.
 * @param category - The category of the event
 * @param subCategory - The sub-category of the event
 * @param event - the mixpanel event i.e. 'Entered project page'.
 * @param properties - properties to attach to the mixpanel event.
 */
export function track(event: string): void;
export function track(event: string, properties?: Dict): void;
export function track(event: string, category: string, properties?: Dict): void;
export function track(event: string, category: string, subCategory: string, properties?: Dict): void;
export function track(...args: string[] | [string, Dict?] | [string, string, Dict?] | [string, string, string, Dict?]): void {
  let pathname = "";

  // try/catch so we don't fail in tests where `window.location` can crash.
  try {
    pathname = window.location.pathname;
  } catch (e) {
    /* empty */
  }

  // Add the props if they exist,
  // otherwise set as undefined
  let props = args.at(-1);
  if (typeof props !== "string") {
    const pathSplit = pathname.split("/");
    if (pathSplit.length >= 3) {
      if (pathSplit[1] === "project") {
        props = { ...props, projectId: pathSplit[2] }; // Don't mutate the input
      }
    }
  } else {
    props = undefined;
  }

  mixpanel.track([...args.slice(0, typeof args.at(-1) === "object" ? -1 : undefined)].join("."), props);
  setProfile({ $last_seen: new Date().toISOString() }); // make sure the 'Last Seen' property always reflects the latest event
}

/**
 * Identifies the current user to mixpanel. All subsequent events will be attributed to this user.
 * @param {string} identifier - a unique id for a user
 */
export function identify(identifier: string): void {
  mixpanel.identify(identifier);
}

/**
 * Set or update properties on a user record, i.e. {'gender': 'm'}.
 * @param {Object} profile - object containing profile properties
 */
export function setProfile(profile: Dict): void {
  mixpanel.people.set(profile);
}

/**
 * Reassigns an unidentified user to a new id.
 * @param {string} alias - A unique identifier that you want to use for this going forward.
 */
export function alias(newId: string): void {
  mixpanel.alias(newId);
}

/**
 * Used on sign out. Subsequent events will be anonymous until identify is called again.
 */
export function reset(): void {
  mixpanel.reset();
}
