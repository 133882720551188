import React, { useCallback, useContext, useEffect, useState } from "react";

import { UnmergeIcon } from "fond/svg_icons";
import { isMultiPolygon } from "fond/types/geojson";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const UncombineButton: React.FC<IButtonProps> = () => {
  const { drawControl, map } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on if a single Multipolygon is selected.
   */
  const handleOnSelect = useCallback(() => {
    const selectedFeatures = drawControl.current.getSelected().features;

    // Confirm that the selected feature is a multiPolygon but also have multiple coordinates.
    if (selectedFeatures.length === 1 && isMultiPolygon(selectedFeatures[0]) && selectedFeatures[0].geometry.coordinates.length > 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnClick = () => {
    drawControl.current?.uncombineFeatures();
  };

  return (
    <ToolbarButton title="Uncombine features" onClick={handleOnClick} disabled={isDisabled}>
      <UnmergeIcon />
    </ToolbarButton>
  );
};

export default UncombineButton;
