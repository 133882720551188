import { RawDraftContentState } from "draft-js";
import { Feature } from "geojson";

import { User } from "fond/types";

export type CommentState = "open" | "resolved";

export type CommentFilters = {
  State: Array<CommentState>;
  Importance: Array<CommentImportance | null>;
  Version: string[];
};

export type CommentSortOrder = "created" | "latest" | "importance";

export type CommentType = "point" | "polygon" | "project" | "lineString" | "arrowLine";

export type CommentImportance = "blocker" | "critical" | "major" | "minor" | "trivial";

export const commentImportanceLevel = {
  blocker: 0,
  critical: 1,
  major: 2,
  minor: 3,
  trivial: 4,
};

export type Comment = {
  /**
   * Unique identifier for the comment
   */
  ID: string;
  /**
   * Timestamp (UTC) of the time the comment was created
   */
  CreatedAt: string;
  /**
   * Details of the user that created the comment
   */
  Creator: User;
  /**
   * The feature information of the map marker
   */
  Features: Feature[];
  /**
   * The username of the last user to edit the comment
   */
  LastModifiedAt: string;
  /**
   * Timestamp (UTC) of the last time the comment was modified
   */
  LastModifiedBy: string;
  /**
   * The importance level of the comment
   */
  Importance: CommentImportance | null;
  /**
   * The text only rendering of the RawContent Block
   * NOTE: The is not used in the UI - only in emails
   */
  Content: string;
  /**
   * The Raw Content State of the comment - Draft-JS handles
   * displaying of this rich content
   */
  RawContent: RawDraftContentState;
  /**
   * The ID of the version that the comment relates to
   */
  Version: string;
  /**
   * The resolution status of the comment
   */
  State: CommentState;
  /**
   * A collection of replies to the comment
   */
  Replies: Reply[];
  /**
   * The Type of comment
   */
  Type: "point" | "polygon" | "project" | "lineString" | "arrowLine";
  /**
   * The resolver of the comment
   */
  ResolvedBy: User | null;
  /**
   * Grouped replies
   */
  GroupedReplies: Reply[][];
  /**
   * Match
   */
  match: boolean;
};

export type Reply = {
  /**
   * Unique identifier for the reply
   */
  ID: string;
  /**
   * Comments that are replies to a comment have a parent ID (which is the original comment)
   */
  CommentID: string;
  /**
   * The text only rendering of the RawContent Block
   * NOTE: The is not used in the UI - only in emails
   */
  Content: string;
  /**
   * The Raw Content State of the reply - Draft-JS handles
   * displaying of this rich content
   */
  RawContent: RawDraftContentState;
  /**
   * Timestamp (UTC) of the time the reply was created
   */
  CreatedAt: string;
  /**
   * Username of the user that created the reply
   */
  Creator: User;
  /**
   * The username of the last user to edit the reply
   */
  LastModifiedAt: string;
  /**
   * Timestamp (UTC) of the last time the reply was modified
   */
  LastModifiedBy: User;
  /**
   * String of the project id
   */
  ProjectID: string;
  /**
   * a boolean value indicates if match or not
   */
  match: boolean;
};
