import { useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { removeNotification } from "./redux";

/**
 * Component for displaying notifications. Note that this component always renders null.
 * It displays the notification by using `enqueueSnackbar`, a notistack function that
 * is only avaiable in a component by wrapping it with the `withSnackbar` HOC.
 *
 * To display a notification, use the `addNotification` action in `notifications/redux/actions.js`.
 */
function Notifier(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    for (let notification of props.notifications) {
      // remove the notificaion from the redux store
      props.removeNotification(notification.key);
      // Display notification using the material Snackbar
      enqueueSnackbar(notification.message, notification.options);
    }
  }, [props.notifications, enqueueSnackbar, props]);

  return null;
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeNotification }, dispatch);
};

Notifier.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
      options: PropTypes.any,
    })
  ),
  removeNotification: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
