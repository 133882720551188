import React, { useMemo } from "react";
import { CloudUpload, DriveFileRenameOutline, HighlightAlt } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

import { useGetVersionStatusQuery } from "fond/api";
import { inputLayerGroups, InputMethods, LayerGroupIds, LayerIds } from "fond/layers";
import { beginEditing, hasLayer, openUploadPanel, StatusTypes } from "fond/project/redux";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { StackedNavigationButton, useStackedNavigationContext } from "fond/widgets";

import { UploadSources } from "../upload";

const InputMethodsPanel: React.FC<{ layerGroupId: string }> = ({ layerGroupId }) => {
  const dispatch = useAppDispatch();
  const versionId = useAppSelector((state) => state.project.versionId);
  const { data } = useAppSelector((state) => state.project.projects[state.project.projectId]);
  const { data: status } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const { open } = useStackedNavigationContext();
  const { inputMethods } = inputLayerGroups[layerGroupId];

  const isComplete = status?.Status === StatusTypes.Complete;

  const handleOnSelectLayerUpload = () => {
    dispatch(openUploadPanel(layerGroupId, isComplete));
    open("upload");
  };

  const handleOnSelectLayerEnterManually = () => {
    dispatch(beginEditing(layerGroupId));
    open("draw");
  };

  /**
   * Determines if the user has the ability to area select the current inputMethod.
   * We prevent the user from area selecting both UG and AER layers (only either).
   */
  const isAreaSelectDisabled = useMemo(() => {
    if (layerGroupId === LayerGroupIds.inStreet) {
      const hasSpanPoleData = hasLayer(data, LayerIds.inSpan) || hasLayer(data, LayerIds.inPole);
      const dataSource: string[] | null = data.layers[LayerIds.inSpan]?.sources ?? data.layers[LayerIds.inSpan]?.sources;

      if (hasSpanPoleData && dataSource && dataSource.includes(UploadSources.polygonSelect)) return true;
    }

    if (layerGroupId === LayerGroupIds.spanPole) {
      const hasUgPathData = hasLayer(data, LayerIds.inStreet);
      const dataSource: string[] | null = data.layers[LayerIds.inStreet]?.sources;

      if (hasUgPathData && dataSource && dataSource.includes(UploadSources.polygonSelect)) return true;
    }

    return false;
  }, [data, layerGroupId]);

  return (
    <Box px={1} data-testid="input-methods-panel">
      <Box mb={5}>
        <Typography fontWeight="500">Import data</Typography>
        {inputMethods.includes(InputMethods.polygonSelect) && (
          <Tooltip title={isAreaSelectDisabled && "Area select can only be used for either Underground path or Aerial and not both."}>
            <Box>
              <StackedNavigationButton
                data-testid="input-method-navigation-area-select"
                icon={<HighlightAlt />}
                title="Area Select"
                subtitle="Use FOND data"
                target="area-select"
                disabled={isAreaSelectDisabled}
              />
            </Box>
          </Tooltip>
        )}
        {inputMethods.includes(InputMethods.upload) && (
          <StackedNavigationButton
            data-testid="input-method-navigation-upload"
            icon={<CloudUpload />}
            title="Upload"
            subtitle="Use my data"
            onClick={handleOnSelectLayerUpload}
          />
        )}
      </Box>
      <Box mb={5}>
        <Typography fontWeight="500">Manual selection and edit</Typography>
        {inputMethods.includes(InputMethods.enterManually) && (
          <StackedNavigationButton
            data-testid="input-method-navigation-draw"
            icon={<DriveFileRenameOutline />}
            title="Draw"
            subtitle="Add and edit data on map"
            onClick={handleOnSelectLayerEnterManually}
          />
        )}
      </Box>
    </Box>
  );
};

export default InputMethodsPanel;
