import { Value } from "./TextPartsField";

export default (values: Value[] | null): Array<any> | undefined => {
  let errors: Array<any> = [];
  if (values && values.length) {
    errors = values.map(({ AttributeName, Literal, Transformations }) => {
      const error: any = {};
      if (!AttributeName && !Literal) {
        error.Literal = "Required";
        error.AttributeName = "Required";
      }
      if (AttributeName && Transformations) {
        const transformationsErrors = Transformations.map((transformation) => {
          if (!transformation.Operation) return { Operation: "Required" };
          if (["+", "-", "*", "/", "%", "^", "max", "min"].includes(transformation.Operation) && transformation.Value === "") {
            return { Value: "Required" };
          }
          if (transformation.Operation === "number-format") {
            const numberFormatFieldsError = {
              MinFractionDigits: transformation.MinFractionDigits === "" && "Required",
              MaxFractionDigits: transformation.MaxFractionDigits === "" && "Required",
            };
            if (!numberFormatFieldsError.MinFractionDigits && !numberFormatFieldsError.MaxFractionDigits) {
              return {};
            }
            return numberFormatFieldsError;
          }
          return {};
        });
        if (!transformationsErrors.every((transformationsError) => Object.keys(transformationsError).length === 0)) {
          error.Transformations = transformationsErrors;
        }
      }
      return error;
    });
  }

  if (!errors.every((error) => Object.keys(error).length === 0)) return errors;

  return undefined;
};
