/**
 * A thin wrapper around the Material-UI Autocomplete component to be used
 * in the BOM form. Note that this autocomplete component itself does not take
 * any options and instead gets them from a redux store slice.
 */

import { useMemo, useState } from "react";
import { Autocomplete, createFilterOptions, styled, TextField, TextFieldProps, Tooltip } from "@mui/material";

import { bomTagSlice } from "fond/redux/bom/bomTagSlice";
import store from "fond/store";

const StyledAutocompleteWrapper = styled("div")`
  margin-top: ${({ theme }) => theme.spacing(1)};

  /* Only show the clear icon when hovering/focused as we have very limited space */
  &:not(:hover, :focus-within) {
    .MuiAutocomplete-clearIndicator {
      display: none;
    }
    .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
      padding-right: 6px;
    }
  }
`;

const tagsSelector = () => store.getState().bomTags.tags;

export type BomTagAutocompleteOption = {
  value: string;
  title: string;
};

type CreatableBomTagAutocompleteOption = BomTagAutocompleteOption & {
  isNew?: boolean;
};

export type BomAutocompleteProps = {
  /**
   * Note that while we may store values as arrays for BOM tags we only accept a single value
   * When the value is passed in as an array we only take the first value.
   */
  value: string | string[];
  onChange: (value?: [string?]) => void;
  "data-t-input"?: string;
};

function getOptionLabel(option: string | CreatableBomTagAutocompleteOption): string {
  if (typeof option === "string") {
    return option;
  }
  return option.title;
}

function renderOption(option: string | CreatableBomTagAutocompleteOption): React.ReactNode {
  if (typeof option === "string") {
    return option;
  }
  if (option.isNew) {
    return `Add "${option.value}"`;
  }
  return option.title;
}

const filter = createFilterOptions<CreatableBomTagAutocompleteOption>();

export function BomTagAutocomplete({ value, onChange, "data-t-input": dataTInput }: BomAutocompleteProps): JSX.Element {
  // Default the value to an empty string to make the autocomplete a controlled component
  const [selectedValue, setSelectedValue] = useState<BomTagAutocompleteOption | string>((Array.isArray(value) ? value[0] : value) || "");
  const [options, setOptions] = useState<BomTagAutocompleteOption[]>([]);
  const selectedValueString = useMemo(() => {
    if (typeof selectedValue === "string") {
      return selectedValue;
    }
    return selectedValue.title;
  }, [selectedValue]);
  const onValueChange = (event: React.SyntheticEvent<Element, Event>, val: string | CreatableBomTagAutocompleteOption | null) => {
    let optionValue: BomTagAutocompleteOption;
    if (val === null) {
      setSelectedValue("");
      onChange([]);
      return;
    }
    if (typeof val === "string") {
      optionValue = { value: val, title: val };
    } else if (val.isNew) {
      optionValue = { value: val.value, title: val.value };
      store.dispatch(bomTagSlice.actions.addTag(optionValue));
    } else {
      optionValue = val;
    }
    setSelectedValue(optionValue);
    onChange([optionValue?.value]);
  };

  // only set the options from the store when opening the dropdown
  // this avoid unnecessary re-renders of other autocomplete components
  // when adding tags.
  // TODO: Replace with a selector from the slice when upgrading to RTK 2.x
  const setOptionsOnOpen = () => {
    setOptions(tagsSelector());
  };

  return (
    <StyledAutocompleteWrapper>
      <Tooltip
        title={selectedValueString}
        placement="top"
        enterDelay={1000}
        leaveDelay={0}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}
      >
        <Autocomplete
          data-testid={`${dataTInput || "bom-tag-autocomplete"}-input`}
          autoHighlight
          value={selectedValue}
          options={options}
          onOpen={setOptionsOnOpen}
          isOptionEqualToValue={(option, selectedOption) =>
            typeof option !== "string" && typeof selectedOption !== "string" && option.value === selectedOption.value
          }
          getOptionLabel={getOptionLabel}
          size="small"
          sx={{ marginTop: "8px" }}
          componentsProps={{
            paper: {
              sx: {
                width: "max-content", // get the dropdown to expand to the width of the longest option
                minWidth: "100%", // 100% is the "natural" width of the dropdown
                maxWidth: "300px",
              },
            },
          }}
          popupIcon={null}
          clearOnBlur
          handleHomeEndKeys
          selectOnFocus
          freeSolo
          filterOptions={(opts, params): CreatableBomTagAutocompleteOption[] => {
            const filtered = filter(opts as CreatableBomTagAutocompleteOption[], params);
            const { inputValue: inputVal } = params;

            if (inputVal === "") {
              filtered.push({
                isNew: false,
                value: "",
                title: "Type to add your own",
              });
            }

            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputVal === option.value);
            if (inputVal !== "" && !isExisting) {
              filtered.push({
                isNew: true,
                value: inputVal,
                title: inputVal,
              });
            }
            return filtered;
          }}
          onChange={onValueChange}
          aria-label="Category"
          renderInput={(params: TextFieldProps) => <TextField {...params} variant="outlined" />}
          renderOption={(props, option: string | BomTagAutocompleteOption) => {
            const key = typeof option === "string" ? option : option.value;
            return <li {...{ ...props, key: key || "none" }}>{renderOption(option)}</li>;
          }}
          getOptionDisabled={(option) => typeof option !== "string" && option.value === ""}
        />
      </Tooltip>
    </StyledAutocompleteWrapper>
  );
}
