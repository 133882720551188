import { forwardRef } from "react";
import { ListItem, ListItemText, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { Architecture } from "fond/types";

interface IProps {
  arch: Architecture;
  isSelected: boolean;
  onClick?(): void;
}

const useCustomStyles = makeStyles((theme: Theme) => {
  return {
    listTitle: {
      // https://caniuse.com/#feat=css-line-clamp
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    listDescription: {
      // https://caniuse.com/#feat=css-line-clamp
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    unnamedArchitecture: {
      fontStyle: "italic",
    },
    noProjectArch: {
      fontSize: "1.3em",
      fontStyle: "italic",
      margin: "8px 0",
    },
  };
});

const ArchitectureListItem = forwardRef<HTMLDivElement, IProps>(({ arch, isSelected, onClick }: IProps, ref) => {
  const classes = useCustomStyles();

  return (
    <ListItem data-testid="architecture-list-item" dense button selected={isSelected} ref={ref} onClick={onClick}>
      {arch != null ? (
        <ListItemText
          primary={
            <Typography variant="body1" className={classNames(classes.listTitle, !arch.Name ? classes.unnamedArchitecture : null)} data-testid="text">
              {arch.Name || "(Unnamed architecture)"}
            </Typography>
          }
          secondary={
            arch.Description && (
              <Typography variant="caption" color="textSecondary" className={classes.listDescription}>
                {arch.Description}
              </Typography>
            )
          }
          disableTypography
        />
      ) : (
        <Typography variant="body1" color="textSecondary" className={(classes.listTitle, classes.noProjectArch)} data-testid="text">
          None
        </Typography>
      )}
    </ListItem>
  );
});

ArchitectureListItem.displayName = "ArchitectureListItem";
export default ArchitectureListItem;
