import React, { useContext } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";

import { MoveIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const StaticButton: React.FC<IButtonProps> = () => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("static");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "static" } as DrawModeChangeEvent);
  };

  return (
    <ToolbarToggleButton title="Move" value="static" onClick={handleOnClick}>
      <MoveIcon />
    </ToolbarToggleButton>
  );
};

export default StaticButton;
