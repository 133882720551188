import { ListItemButton, styled } from "@mui/material";

export const BaseAccountButton = styled(ListItemButton)(
  () => `
  font-size: 0.875rem;
  font-weight: regular;

  &:hover {
    font-weight: bold;
  }
`
);

export const AccountSelectionButton = styled(BaseAccountButton)(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  border-radius: 4px;
`
);

export const AddAccountButton = styled(BaseAccountButton)(
  ({ theme }) => `
  padding: ${theme.spacing(1.5)} ${theme.spacing(2.5)};
  gap: ${theme.spacing(1.7)};
`
);
