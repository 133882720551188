import React from "react";
import { Box, TableContainer, TableProps as MuiTableProps } from "@mui/material";

import { BaseTable } from "./Table.styles";

export interface TableProps extends MuiTableProps {
  variant?: "ag-grid-compact";
}

const Table: React.FC<TableProps> = ({ variant, ...props }: TableProps) => {
  if (variant === "ag-grid-compact")
    return (
      <TableContainer component={Box} sx={{ borderRadius: 1, border: "1px solid #ddddde", overflow: "hidden" }}>
        <BaseTable {...props} variant={variant} />
      </TableContainer>
    );

  return <BaseTable {...props} variant={variant} />;
};

export default Table;
