import { apiSlice, refetchVersionStatus, requestJson, selectCurrentVersionStatus, versionsSlice } from "fond/api";
import { AsyncOperationState, makeAsyncActionCreator, makeAsyncReducer } from "fond/async/redux";
import { getCurrentUser } from "fond/cognito/aws_cognito_utils";
import { getCurrentProject, getRunningStepIndex, isRunningSolve, loadProjectData, ProjectAction, StatusTypes } from "fond/project/redux";
import { AppThunkDispatch, Store, WorkflowQuality } from "fond/types";
import { logErrorToBrowser, reduceReducers } from "fond/utils";

const POLLING_INTERVAL_NO_SOLVE_MS = 30000;
const POLLING_INTERVAL_SOLVE_MS = 5000;

export interface UnloadAction {
  type: typeof actions.UNLOAD;
}

export const actions = {
  LOAD: "SOLVE/LOAD",
  UNLOAD: "SOLVE/UNLOAD",
};

let timeout: ReturnType<typeof setTimeout> | null;

function pollAndRepeat(dispatch: AppThunkDispatch, getState: () => Store) {
  const project = getCurrentProject(getState().project);

  if (project != null) {
    // To reduce noise, only make the request to get the project status if the
    // browser window is visible and the user's session is still valid.
    if (!document.hidden && getCurrentUser() != null) {
      dispatch(poll());
    }

    const status = selectCurrentVersionStatus(getState());
    const timeoutInterval = isRunningSolve(status?.Status) ? POLLING_INTERVAL_SOLVE_MS : POLLING_INTERVAL_NO_SOLVE_MS;
    timeout = setTimeout(() => pollAndRepeat(dispatch, getState), timeoutInterval);
  }
}

export function isPolling(): boolean {
  return timeout != null;
}

function stopPolling() {
  if (timeout != null) {
    clearTimeout(timeout);
    timeout = null;
  }
}

export function load(): (dispatch: AppThunkDispatch, getState: () => Store) => void {
  return (dispatch: AppThunkDispatch, getState: () => Store) => {
    pollAndRepeat(dispatch, getState);
  };
}

export function unload(): UnloadAction {
  stopPolling();
  return { type: actions.UNLOAD };
}

export function poll(): (dispatch: AppThunkDispatch, getState: () => Store) => Promise<null | void> {
  return async (dispatch: AppThunkDispatch, getState: () => Store) => {
    const state = getState();
    const project = getCurrentProject(state.project);
    const { versionId } = state.project;

    if (project == null) {
      return;
    }
    try {
      const requestTimestamp = +new Date();
      const currentStatus = selectCurrentVersionStatus(state);
      const status = await refetchVersionStatus(dispatch, versionId);

      if (state.solve.updateDataStatus === AsyncOperationState.failure || status?.StatusCode !== currentStatus?.StatusCode) {
        const projectData = await requestJson("GET", `/v2/projects/${project.ID}`);
        const { solve } = getState();

        if (status?.Status === StatusTypes.Terminated) {
          dispatch(await loadProjectData(projectData));
        }

        if (requestTimestamp > solve.lastUserOperationTimestamp && !solve.isOperationPending) {
          const oldStepIndex = getRunningStepIndex(currentStatus);
          const newStepIndex = getRunningStepIndex(status);
          const shouldLoadNewFiles = newStepIndex !== oldStepIndex;
          if (shouldLoadNewFiles) {
            dispatch(await loadProjectData(projectData));
          }
          dispatch({
            type: ProjectAction.UPDATE_DATA.SUCCESS,
            project: projectData,
            loadingFiles: shouldLoadNewFiles,
          });
        }
      }
    } catch (err) {
      logErrorToBrowser(err);
      dispatch({ type: ProjectAction.UPDATE_DATA.FAILURE });
    }
  };
}

export const run = makeAsyncActionCreator(
  ProjectAction.RUN_SOLVE,
  function getInitiateArgs() {
    return {};
  },
  function execute(getState: () => Store, versionId: string, quality: WorkflowQuality) {
    return requestJson("POST", "/v2/workflows", { VersionID: versionId, Quality: quality });
  },
  function success(dispatch: AppThunkDispatch, getState: () => Store) {
    // Clear and restart the timeout immediately, otherwise we might have to
    // wait a long time before we start polling more quickly.
    stopPolling();
    pollAndRepeat(dispatch, getState);
  }
);

export const cancelDismissError = () => ({ type: ProjectAction.CANCEL_SOLVE.DISMISS_ERROR });

export const cancel = (versionId: string) => {
  return async (dispatch: AppThunkDispatch, getState: () => Store) => {
    dispatch({ type: ProjectAction.CANCEL_SOLVE.INITIATE });
    try {
      const result = dispatch(versionsSlice.endpoints.cancelVersionWorkflow.initiate(versionId));
      await result;
      dispatch({ type: ProjectAction.CANCEL_SOLVE.SUCCESS });
      result.unsubscribe();
    } catch (error) {
      dispatch({ type: ProjectAction.CANCEL_SOLVE.FAILURE, error: null });
      dispatch(apiSlice.util.invalidateTags([{ type: "VersionStatus", id: getState().project.versionId }]));
      throw error;
    }
  };
};

const initialState = {
  project: null,
  runStatus: null,
  cancelStatus: null,
  lastUserOperationTimestamp: null,
  isOperationPending: false,
};

export const reducer = reduceReducers(
  (state = initialState, action: any) => {
    switch (action.type) {
      case actions.UNLOAD:
        return initialState;
      case ProjectAction.RUN_SOLVE.INITIATE:
      case ProjectAction.CANCEL_SOLVE.INITIATE:
        return {
          ...state,
          lastUserOperationTimestamp: +new Date(),
          isOperationPending: true,
        };
      case ProjectAction.RUN_SOLVE.SUCCESS:
        return {
          ...state,
          lastUserOperationTimestamp: +new Date(),
          isOperationPending: false,
        };
      case ProjectAction.CANCEL_SOLVE.SUCCESS:
        return {
          ...state,
          lastUserOperationTimestamp: +new Date(),
          isOperationPending: false,
        };
      case ProjectAction.RUN_SOLVE.FAILURE:
      case ProjectAction.CANCEL_SOLVE.FAILURE:
        return {
          ...state,
          isOperationPending: false,
        };
      default:
        return state;
    }
  },
  makeAsyncReducer(ProjectAction.RUN_SOLVE, "runStatus"),
  makeAsyncReducer(ProjectAction.CANCEL_SOLVE, "cancelStatus"),
  makeAsyncReducer(ProjectAction.UPDATE_DATA, "updateDataStatus")
);
