import { Hub } from "@aws-amplify/core";
import { AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js";

import { getImpersonatedUser } from "fond/impersonate";
import mixpanel from "fond/mixpanel";

import { getAllAttrs, getCurrentUser, getUserPool, getUserWithEmail, isLoggedIn } from "./aws_cognito_utils";

export const CognitoAction = {
  // On successful initialisation. This means either successfully recognising
  // that the user is logged in or successfully recognising that they are not
  // logged in.
  INIT_SUCCESS: "INIT_SUCCESS",

  AUTHENTICATED: "AUTHENTICATED", // user successfully authenticated
  AUTHENTICATION_FAILURE: "AUTHENTICATION_FAILURE", // user authentication failed

  // User authentication failed because the user hasn't confirmed their email yet.
  AUTHENTICATION_FAILURE_NOT_CONFIRMED: "AUTHENTICATION_FAILURE_NOT_CONFIRMED",

  NEW_PASSWORD_REQUIRED: "LOGIN_NEW_PASSWORD_REQUIRED", // user successfully authenticated but required to choose a new password
  PROCESSING: "PROCESSING", // have started a cognito async api call
  NEW_PASSWORD_REQUIRED_FAILURE: "NEW_PASSWORD_REQUIRED_FAILURE", // an error occured choosing a new password
  FORGOT_PASSWORD_CODE_SENT: "FORGOT_PASSWORD_CODE_SENT", // a verification code has been sent to the user
  FORGOT_PASSWORD_CODE_SEND_FAILURE: "FORGOT_PASSWORD_CODE_SEND_FAILURE", // sending the verification code failed
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAILURE: "PASSWORD_CHANGE_FAILURE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  CONFIRM_SIGNUP_SUCCESS: "CONFIRM_SIGNUP_SUCCESS",
  CONFIRM_SIGNUP_FAILURE: "CONFIRM_SIGNUP_FAILURE",
  RESEND_CONFIRM_CODE_SUCCESS: "RESEND_CONFIRM_CODE_SUCCESS",
  RESEND_CONFIRM_CODE_FAILURE: "RESEND_CONFIRM_CODE_FAILURE",
  LOGGED_OUT: "LOGGED_OUT",
};

function cognitoStart() {
  return {
    type: CognitoAction.PROCESSING,
  };
}

export function resendConfirmationCode(email) {
  return (dispatch) => {
    dispatch(cognitoStart());

    const cognitoUser = getUserWithEmail(email);

    cognitoUser.resendConfirmationCode((err, result) => {
      let action = null;

      if (err) {
        action = {
          type: CognitoAction.RESEND_CONFIRM_CODE_FAILURE,
          errorMsg: err.message,
        };
      } else {
        action = {
          type: CognitoAction.RESEND_CONFIRM_CODE_SUCCESS,
        };
        mixpanel.track("Re-sent email verification code");
      }

      dispatch(action);
    });
  };
}

export function confirmSignUp(email, code) {
  return (dispatch) => {
    dispatch(cognitoStart());

    const cognitoUser = getUserWithEmail(email);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        dispatch({
          type: CognitoAction.CONFIRM_SIGNUP_FAILURE,
          errorMsg: err.message,
        });
      } else {
        dispatch({
          type: CognitoAction.CONFIRM_SIGNUP_SUCCESS,
          email,
        });
        mixpanel.track("Verified email");
      }
    });
  };
}

// TODO-{"__type":"InvalidParameterException","message":"User is already confirmed."} on reset password

export function signUp(email, password, firstName, lastName) {
  return (dispatch) => {
    dispatch(cognitoStart());

    const attributeList = [
      new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
      new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
      new CognitoUserAttribute({ Name: "email", Value: email }),
    ];

    getUserPool().signUp(email, password, attributeList, null, (err, result) => {
      let action = null;

      if (err) {
        action = {
          type: CognitoAction.SIGNUP_FAILURE,
          errorMsg: err.message,
        };
      } else {
        action = {
          type: CognitoAction.SIGNUP_SUCCESS,
        };

        mixpanel.alias(email);
        mixpanel.track("Signed up");
        mixpanel.setProfile({
          $first_name: firstName,
          $last_name: lastName,
          $created: new Date().toISOString(),
          $email: email,
        });
      }

      dispatch(action);
    });
  };
}

export function signIn(email, password, navigate) {
  return async (dispatch) => {
    dispatch(cognitoStart());

    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      const cognitoUser = getUserWithEmail(email);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          dispatch({
            type: CognitoAction.AUTHENTICATED,
            user: cognitoUser,
            userAttrs: await getAllAttrs(cognitoUser),
          });
          mixpanel.identify(email);
          mixpanel.track("Signed in");
          /*
           * This should have been set when the user sign up. However we will have
           * some users who existed before we integrated mixpanel.
           */
          mixpanel.setProfile({
            $email: email,
          });

          resolve();
        },

        onFailure: (err) => {
          if (err.code === "UserNotConfirmedException") {
            dispatch({
              type: CognitoAction.AUTHENTICATION_FAILURE_NOT_CONFIRMED,
              errorMsg: err.message,
            });
            navigate(`/confirm/${email}`);
          } else {
            dispatch({
              type: CognitoAction.AUTHENTICATION_FAILURE,
              errorMsg: err.message,
            });
          }
          reject(err);
        },

        newPasswordRequired: (userAttributes, requiredAttributes) => {
          dispatch({
            type: CognitoAction.NEW_PASSWORD_REQUIRED,
            user: cognitoUser,
          });

          resolve();
        },
      });
    });
  };
}

export function changePassword(cognitoUser, password, newPassword) {
  return (dispatch) => {
    dispatch(cognitoStart());

    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(password, newPassword, (err, result) => {
        if (err) {
          let action = {
            type: CognitoAction.PASSWORD_CHANGE_FAILURE,
            errorMsg: err.message,
            user: cognitoUser,
          };
          dispatch(action);
          reject(err);
        } else {
          let action = {
            type: CognitoAction.PASSWORD_CHANGE_SUCCESS,
            user: cognitoUser,
          };
          mixpanel.track("Changed password");
          dispatch(action);
          resolve();
        }
      });
    });
  };
}

/** States stored in store.cognito.state */

export const CognitoState = {
  NEW_PASSWORD_REQUIRED: "PASSWORD_CHANGE_REQUIRED",
  FORGOT_PASSWORD_CODE_SENT: "FORGOT_PASSWORD_CODE_SENT",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  CONFIRM_SIGNUP_SUCCESS: "CONFIRM_SIGNUP_SUCCESS",
  CONFIRM_SIGNUP_FAILURE: "CONFIRM_SIGNUP_FAILURE",
  RESEND_CONFIRM_CODE_SUCCESS: "RESEND_CONFIRM_CODE_SUCCESS",
  RESEND_CONFIRM_CODE_FAILURE: "RESEND_CONFIRM_CODE_FAILURE",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAILURE: "PASSWORD_CHANGE_FAILURE",
};

/*
 * Creates an initial state for cognito which includes checking whether a user is logged in or not.
 *
 * @returns {object} the initial state for the cognito section of the store
 */
export async function init(store) {
  try {
    const currentUser = await getCurrentUser();
    if (currentUser != null) {
      store.dispatch({
        type: CognitoAction.INIT_SUCCESS,
        user: currentUser,
        isLoggedIn: await isLoggedIn(),
        userAttrs: await getAllAttrs(currentUser),
      });
    } else {
      store.dispatch({
        type: CognitoAction.INIT_SUCCESS,
        user: null,
        isLoggedIn: false,
        userAttrs: {},
      });
    }
  } catch (e) {
    console.error(e);
  }

  Hub.listen("auth", async ({ payload: { event, data } }) => {
    const currentUser = await getCurrentUser();
    if (event === "signIn") {
      store.dispatch({
        type: CognitoAction.INIT_SUCCESS,
        user: currentUser,
        isLoggedIn: await isLoggedIn(),
        userAttrs: await getAllAttrs(currentUser),
      });
    } else if (event === "signOut") {
      store.dispatch({
        type: CognitoAction.LOGGED_OUT,
      });
    }
  });
}

export function createInitialState() {
  return {
    // is the current user authenticated?
    // `null` indicates that we don't even know whether they're logged in yet,
    // we're waiting to find out.
    isLoggedIn: null,
    workflowState: null, // cognito workflow state
    user: null, // a cognito user
    userAttrs: {},

    // `null` or {type: 'success' | 'error', text: 'text'}
    message: null,

    isProcessing: false, // awaiting a response from cognito

    errors: [],

    // Used to auto-fill the email address when we redirect from confirm signup
    // to login.
    confirmSignupEmail: null,
  };
}

export function reducer(state = createInitialState(), action) {
  switch (action.type) {
    case CognitoAction.INIT_SUCCESS:
      return {
        ...state,
        user: getImpersonatedUser() ? { ...action.user, username: getImpersonatedUser() } : action.user,
        isLoggedIn: action.isLoggedIn,
        userAttrs: action.userAttrs,
      };
    case CognitoAction.SIGNUP_SUCCESS:
      return {
        ...state,
        message: null,
        workflowState: CognitoState.SIGNUP_SUCCESS,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
      };

    case CognitoAction.SIGNUP_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        workflowState: CognitoState.SIGNUP_FAILURE,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
      };

    case CognitoAction.CONFIRM_SIGNUP_SUCCESS:
      return {
        ...state,
        message: successMessage("Your email has been confirmed! Please sign in."),
        workflowState: CognitoState.CONFIRM_SIGNUP_SUCCESS,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
        confirmSignupEmail: action.email,
      };

    case CognitoAction.CONFIRM_SIGNUP_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        workflowState: CognitoState.CONFIRM_SIGNUP_FAILURE,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
      };

    case CognitoAction.RESEND_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        message: null,
        workflowState: CognitoState.RESEND_CONFIRM_CODE_SUCCESS,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
      };

    case CognitoAction.RESEND_CONFIRM_CODE_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        workflowState: CognitoState.RESEND_CONFIRM_CODE_FAILURE,
        user: null,
        isLoggedIn: false,
        isProcessing: false,
      };

    case CognitoAction.AUTHENTICATED:
      return {
        ...state,
        message: null,
        workflowState: null,
        user: getImpersonatedUser() ? { ...action.user, username: getImpersonatedUser() } : action.user,
        userAttrs: action.userAttrs,
        isLoggedIn: true,
        isProcessing: false,
        confirmSignupEmail: null,
      };

    case CognitoAction.AUTHENTICATION_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        workflowState: null,
        isProcessing: false,
      };

    case CognitoAction.AUTHENTICATION_FAILURE_NOT_CONFIRMED:
      return {
        ...state,
        message: null,
        workflowState: null,
        isProcessing: false,
      };

    case CognitoAction.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        message: null,
        user: action.user,
        workflowState: CognitoState.NEW_PASSWORD_REQUIRED,
        isProcessing: false,
      };

    case CognitoAction.NEW_PASSWORD_REQUIRED_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        user: action.user,
        workflowState: CognitoState.NEW_PASSWORD_REQUIRED,
        isProcessing: false,
      };

    case CognitoAction.LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false,
        workflowState: null,
        user: null,
        message: null,
        isProcessing: false,
        confirmSignupEmail: null,
      };

    case CognitoAction.FORGOT_PASSWORD_CODE_SENT:
      return {
        ...state,
        message: null,
        workflowState: CognitoState.FORGOT_PASSWORD_CODE_SENT,
        user: action.user,
        isProcessing: false,
      };

    case CognitoAction.FORGOT_PASSWORD_CODE_SEND_FAILURE:
      return {
        ...state,
        message: errorMessage(action.errorMsg),
        workflowState: null,
        isProcessing: false,
      };

    case CognitoAction.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        message: null,
        workflowState: CognitoState.PASSWORD_CHANGE_SUCCESS,
        user: action.user,
        isProcessing: false,
      };

    case CognitoAction.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        // This reducer seems to be used from both the reset password page,
        // which uses .message, and from the user profile page, which uses
        // .errorMsg. Would be good to clean this up.
        message: errorMessage(action.errorMsg),
        errorMsg: action.errorMsg,
        workflowState: CognitoState.PASSWORD_CHANGE_FAILURE,
        user: action.user,
        isProcessing: false,
        errors: action.errors,
      };

    case CognitoAction.PROCESSING:
      return {
        ...state,
        isProcessing: true,
        errors: [],
      };

    default:
      return state;
  }
}

const MessageTypes = {
  error: "error",
  success: "success",
};

function errorMessage(text) {
  return {
    type: MessageTypes.error,
    text,
  };
}

function successMessage(text) {
  return {
    type: MessageTypes.success,
    text,
  };
}
