import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormHelperText, FormLabel, TextField as BaseTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { FieldValidator } from "final-form";

export type NumArrayFieldValue = Array<number | string> | null;
export type NumArrayFieldProps = Partial<Omit<MuiTextFieldProps, "type" | "onChange">> & {
  name: string;
  hideError?: boolean;
  fieldProps?: Partial<FieldProps<NumArrayFieldValue, NumArrayWrapperProps, HTMLElement, Array<number | string>>>;
  defaultValue: number[];
  length: number;
  validate?: FieldValidator<NumArrayFieldValue>;
};

const NumArrayField: React.FC<NumArrayFieldProps> = (props: NumArrayFieldProps) => {
  const { defaultValue, length, name, hideError, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <TextFieldWrapper input={input} meta={meta} name={name} defaultValue={defaultValue} length={length} hideError={hideError} {...rest} />
      )}
      validate={validate}
      {...fieldProps}
    />
  );
};

type NumArrayWrapperProps = { defaultValue: number[]; length: number } & Partial<Omit<MuiTextFieldProps, "onChange">> &
  FieldRenderProps<Array<number | string>, HTMLElement>;

const TextFieldWrapper: React.FC<NumArrayWrapperProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  disabled,
  label,
  length,
  className,
  classes,
  required,
  fullWidth,
  hideError,
  size,
  helperText,
  variant,
  defaultValue,
  inputProps,
  InputProps,
}: NumArrayWrapperProps) => {
  const hasError = meta.error && meta.touched;
  const handleOnChange = (index: number) => (event: any) => {
    const newValue = [...(value || defaultValue)];
    newValue.splice(index, 1, event.currentTarget.value);
    onChange(newValue);
  };

  return (
    <FormControl fullWidth={fullWidth} data-testid={`${name}-numArray-field`} className={className}>
      {(label || required) && (
        <FormLabel required={required} error={hasError}>
          {label}
        </FormLabel>
      )}
      {Array.from(Array(length))?.map((val, index) => (
        <BaseTextField
          name={name}
          type="number"
          data-testid={`${name}-input-${index}`}
          key={`${name}-input-${index}`}
          margin="dense"
          error={hasError && meta.error?.[index]}
          fullWidth={fullWidth}
          inputProps={{
            ...inputProps,
            ...restInput,
          }}
          InputProps={InputProps}
          onChange={handleOnChange(index)}
          value={value?.[index] || ""}
          size={size || "small"}
          placeholder={`${defaultValue?.[index] || 0}`}
          disabled={disabled}
          variant={variant}
          classes={classes}
        />
      ))}

      {((hasError && !hideError) || helperText) && <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>}
    </FormControl>
  );
};

export default NumArrayField;
