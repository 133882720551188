import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { MapboxStyleLayer } from "fond/types";

import { HIGHLIGHT_PROPERTY_NAME as CUT_POLYGON } from "./modes/base/polygonCut/constants";
import { HIGHLIGHT_PROPERTY_NAME as SPLIT_POLYGON } from "./modes/base/polygonSplit/constants";

const { theme: defaultTheme } = MapboxDraw.lib;

const customizedStyleIds = ["gl-draw-polygon-midpoint", "gl-draw-polygon-fill-inactive", "gl-draw-polygon-stroke-inactive"];

const styles: MapboxStyleLayer[] = [
  ...defaultTheme.filter(({ id }) => !customizedStyleIds.includes(id) && !id.includes("static")),
  /**
   * We customise the polygon fill style to use the color value in properties if supplied.
   */
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": ["coalesce", ["get", "user_color"], "#3bb2d0"],
      "fill-outline-color": ["coalesce", ["get", "user_color"], "#3bb2d0"],
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": ["coalesce", ["get", "user_color"], "#3bb2d0"],
      "line-width": 2,
    },
  },

  /**
   * We adjust the midpoint feature to be slightly larger to improve selection &
   * its order on the map (based on index in this array).
   */
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 4,
      "circle-color": "#fbb03b",
    },
  },
  // The styles below control the "highlighted" feature styles during the "polygon_cut" mode.
  {
    id: `gl-draw-cut-polygon-fill-active`,
    type: "fill",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["has", `user_${CUT_POLYGON}`]],
    paint: {
      "fill-color": ["get", `user_${CUT_POLYGON}`],
      "fill-outline-color": ["get", `user_${CUT_POLYGON}`],
      "fill-opacity": 0.2,
    },
  },
  {
    id: `gl-draw-cut-polygon-stroke-active`,
    type: "line",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["has", `user_${CUT_POLYGON}`]],
    paint: {
      "line-color": ["get", `user_${CUT_POLYGON}`],
      "line-width": 2,
    },
  },
  // The styles below control the "highlighted" feature styles during the "polygon_split" mode.
  {
    id: `gl-draw-split-polygon-fill-active`,
    type: "fill",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["has", `user_${SPLIT_POLYGON}`]],
    paint: {
      "fill-color": ["get", `user_${SPLIT_POLYGON}`],
      "fill-outline-color": ["get", `user_${SPLIT_POLYGON}`],
      "fill-opacity": 0.2,
    },
  },
  {
    id: `gl-draw-split-polygon-stroke-active`,
    type: "line",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["has", `user_${SPLIT_POLYGON}`]],
    paint: {
      "line-color": ["get", `user_${SPLIT_POLYGON}`],
      "line-width": 2,
    },
  },
];

export default styles;
