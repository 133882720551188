import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Close } from "@mui/icons-material";
import { AlertTitle, Button, IconButton, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { selectCurrentAllocation, selectCurrentRole, useRequestLicenseAllocationMutation } from "fond/api";
import { EXCEED_SUBSCRIPTION_QUOTA_KEY, EXPIRED_BANNER_KEY, NO_LICENSE_ADMIN, NO_LICENSE_MEMBER, SOON_TO_EXPIRED_BANNER_KEY } from "fond/constants";
import { type BannerType, useUserManagementBanner } from "fond/hooks/useUpgradePlanner";
import { Subscription } from "fond/types";
import { formatDateStringWithTimezone } from "fond/utils/dates";

import BonusBanner from "./BonusBanner";

import { Banner as StyledBanner, ManageAccountButton } from "./topbar.styles";

interface BannerContent {
  title?: string;
  text: string;
  action: React.ReactNode;
}

const UserManagementBanner: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [requestLicense] = useRequestLicenseAllocationMutation();
  const currentAllocation = useSelector(selectCurrentAllocation);
  const isAdmin = useSelector(selectCurrentRole) === "admin";
  const navigate = useNavigate();

  const onLicenseRequest = async () => {
    if (currentAllocation) {
      try {
        await requestLicense(currentAllocation.ID).unwrap();
        enqueueSnackbar("Request sent to the administrator");
        handleClose();
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar("Failed to send request", {
          action: (
            <Button color="primary" onClick={onLicenseRequest}>
              Retry
            </Button>
          ),
        });
      }
    }
  };

  const getBannerContent = (subscription: Subscription, bannerType: BannerType): BannerContent => {
    const subscriptionType = subscription.Planner ? "subscription" : "license";
    const manageAccountButton = isAdmin && (
      <ManageAccountButton
        data-testid="manage-account-button"
        color="error"
        onClick={() => {
          handleClose();
          navigate("/settings/account", { state: { tab: subscriptionType } });
        }}
      >
        {subscriptionType === "subscription" ? "MANAGE SUBSCRIPTION" : "MANAGE ACCOUNT"}
      </ManageAccountButton>
    );

    if (bannerType === "soon to expire") {
      return {
        text: `Your ${subscriptionType} is expiring on ${formatDateStringWithTimezone(subscription.EndTime)}. ${
          isAdmin ? "Renew as soon as possible to continue using our premium features." : "Contact your administrator for further assistance."
        }`,
        action: manageAccountButton,
      };
    } else if (bannerType === "expired") {
      return {
        text: `Your ${subscriptionType} has expired. ${
          isAdmin ? "Renew as soon as possible to continue using our premium features." : "Contact your administrator for further assistance."
        }`,
        action: manageAccountButton,
      };
    } else if (bannerType === "admin without license") {
      return {
        title: "No licenses are currently assigned to this account",
        text: "Users without license has no access to FOND’S premium features. As the Administrator, you have the ability to allocate license to yourself and all other users in this account.",
        action: (
          <ManageAccountButton
            data-testid="manage-users-button"
            color="error"
            onClick={() => {
              handleClose();
              navigate("/settings/users");
            }}
          >
            MANAGE USERS
          </ManageAccountButton>
        ),
      };
    } else if (bannerType === "member without license") {
      return {
        title: "Unlicensed user",
        text: "You currently do not have access to FOND’s premium features. Click Request a license button or you may contact the account administrator to allocate a license for you.",
        action: (
          <ManageAccountButton data-testid="request-license-button" color="error" onClick={onLicenseRequest}>
            REQUEST A LICENSE
          </ManageAccountButton>
        ),
      };
    } else {
      return {
        text: `You have exceeded your total subscription quota. ${
          isAdmin
            ? "Upgrade your plan or purchase data bundles as soon as possible to continue using our premium features."
            : "Contact your administrator for further assistance."
        }`,
        action: manageAccountButton,
      };
    }
  };

  const [expanded, setExpanded] = useState(true);
  const { showBanner, subscription, bannerType } = useUserManagementBanner();

  if (!showBanner || !subscription || !bannerType) return null;

  const bannerContent = getBannerContent(subscription, bannerType);

  const handleClose = () => {
    if (!bannerType) return;

    if (bannerType === "soon to expire") {
      sessionStorage.setItem(SOON_TO_EXPIRED_BANNER_KEY, "true");
    } else if (bannerType === "expired") {
      sessionStorage.setItem(EXPIRED_BANNER_KEY, "true");
    } else if (bannerType === "quota exceeded") {
      sessionStorage.setItem(EXCEED_SUBSCRIPTION_QUOTA_KEY, "true");
    } else if (bannerType === "admin without license") {
      sessionStorage.setItem(NO_LICENSE_ADMIN, "true");
    } else if (bannerType === "member without license") {
      sessionStorage.setItem(NO_LICENSE_MEMBER, "true");
    }
    setExpanded(false);
  };

  return expanded ? (
    <StyledBanner
      data-testid="user-management-banner-error"
      severity="error"
      action={
        <>
          {bannerContent.action}
          <IconButton onClick={handleClose} aria-label="Close Banner">
            <Close />
          </IconButton>
        </>
      }
    >
      {bannerContent.title && <AlertTitle sx={{ my: 0 }}>{bannerContent.title}</AlertTitle>}
      <Typography>
        {bannerContent.text}
        <BonusBanner />
      </Typography>
    </StyledBanner>
  ) : null;
};

export default UserManagementBanner;
