import ArrowBack from "@mui/icons-material/ArrowBack";

import { BackButton, ButtonText } from "./GoBackButton.styles";

interface IProps {
  /**
   * `onClick` handler applied to the root button element.
   */
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

/**
 * Back navigation button used to indicate the user will be taken back to a previous step.
 */
const GoBackButton: React.FC<IProps> = ({ onClick }: IProps) => {
  return (
    <BackButton variant="text" onClick={onClick} data-testid="back-button">
      <ArrowBack />
      <ButtonText>Back</ButtonText>
    </BackButton>
  );
};

export default GoBackButton;
