import React, { useMemo, useState } from "react";
import { Add, AttachFile, Edit, GridView, List } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, IconButton, ToggleButtonGroup } from "@mui/material";
import { useSnackbar } from "notistack";

import { AttachmentModal } from "fond/attachments";
import { ModalViews } from "fond/attachments/AttachmentModal";
import { updateAttachments } from "fond/redux/attachments";
import { Attachment, Project } from "fond/types";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { FilesViewer, NonIdealState, RenameModal } from "fond/widgets";

import { ToggleButton } from "./propertiesPanel.styles";

interface IProps {
  project: Project;
}

const FeatureAttachments: React.FC<IProps> = ({ project }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const attachments = useAppSelector((state) => state.attachments.items.Feature);
  const canUpload = permissionCheck(project.Permission.Level, Actions.PROJECT_UPLOAD_ATTACHMENT);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showRenameAttachmentModal, setShowRenameAttachmentModal] = useState(false);
  const [view, setView] = useState<"gallery" | "list">("list");
  const [selected, setSelected] = useState<Attachment[]>([]);

  const hasAttachments = useMemo(() => attachments && attachments.length > 0, [attachments]);

  return (
    <Box
      data-testid="feature-attachments-tab-content"
      flex={1}
      display="flex"
      flexDirection="column"
      overflow="hidden auto"
      className="customScrollbars"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" p={1.5}>
        <Button
          disabled={!canUpload}
          onClick={() => setShowAttachmentModal(true)}
          variant="contained"
          color="primary"
          startIcon={!hasAttachments && <Add />}
        >
          {hasAttachments ? "Manage files" : "Upload a file"}
        </Button>
        <ToggleButtonGroup
          data-testid="view-mode-toggle-buttons"
          value={view}
          onChange={(_, newView) => {
            if (newView) {
              setView(newView);
            }
          }}
          exclusive
          size="small"
        >
          <ToggleButton value="gallery">
            <GridView fontSize="small" />
          </ToggleButton>

          <ToggleButton value="list">
            <List fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Divider />
      {attachments && attachments.length > 0 ? (
        <Box flex={1}>
          <FilesViewer
            files={attachments}
            view={view}
            selected={selected}
            onSelectionChange={(newSelection) => setSelected(newSelection as Attachment[])}
            listViewPagination={false}
            actions={
              <ButtonGroup>
                <IconButton
                  title="Rename selected file"
                  size="small"
                  color="primary"
                  disabled={selected.length !== 1}
                  onClick={() => setShowRenameAttachmentModal(true)}
                >
                  <Edit />
                </IconButton>
                {/*
                  * TODO: Add appropriate functionality once UI/UX for this feature is available
                  <IconButton size="small" disabled={selected.length !== 1}>
                    <InfoOutlined />
                  </IconButton>
                */}
              </ButtonGroup>
            }
          />
        </Box>
      ) : (
        <NonIdealState icon={<AttachFile />} title="" description="Selected feature has no attachments" />
      )}
      {showAttachmentModal && (
        <AttachmentModal
          isOpen
          onClose={() => setShowAttachmentModal(false)}
          attachmentEntityType="Feature"
          initialView={!hasAttachments ? ModalViews.ATTACHMENTS_STAGING : ModalViews.ATTACHMENTS_LIST}
        />
      )}
      {showRenameAttachmentModal && (
        <RenameModal
          title="Rename Attachment"
          defaultValue={selected[0].Name}
          message="Enter a new name for this attachment"
          onRename={async (formData) => {
            await dispatch(updateAttachments([{ ...selected[0], Name: formData.name }])).then(() =>
              enqueueSnackbar(`Renamed selected[0]: ${selected[0].Name} -> ${formData.name}`)
            );
          }}
          onClose={() => setShowRenameAttachmentModal(false)}
          onValidate={(value) => {
            const isChanged = value !== selected[0].Name;
            const nameExists = attachments?.find((item) => item.Name === value && item.Extension === selected[0].Extension);
            return isChanged && nameExists ? `There is already an attachment named ${value}.${selected[0].Extension}.` : "";
          }}
        />
      )}
    </Box>
  );
};

export default FeatureAttachments;
