import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  minWidth: 150,
  overflow: "auto",
  minHeight: 0,
  maxHeight: 300,
  "& > div": {
    paddingInlineStart: 0,
  },
}));
