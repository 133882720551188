import MapboxDraw, { DrawCustomMode, DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

const { constants: Constants } = MapboxDraw;

const {
  modes,
  lib: {
    doubleClickZoom,
    CommonSelectors: { isFeature, isShiftDown, isShiftMousedown },
  },
} = MapboxDraw;

interface DrawCustomModeState {}

type ExtendedDrawCustomMode = DrawCustomMode & {
  clickOnFeature?(state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent): void;
  fireActionable?(): void;
  whileBoxSelect?(state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent): void;
  startOnActiveFeature(state: DrawCustomModeState, event: MapboxDraw.MapMouseEvent): void;
};

interface DrawCustomModeThisExtended extends DrawCustomModeThis {
  deselect(id: string | number): void;
  stopExtendedInteractions(state: DrawCustomModeState): void;
}

/**
 * A custom draw mode that allows the user to select a feature & nothing else.
 */
const readOnlySelect: DrawCustomMode & ExtendedDrawCustomMode = {
  ...modes.simple_select,

  clickOnFeature: function (this: DrawCustomModeThisExtended, state, event) {
    // Stop everything
    doubleClickZoom.disable(this);
    this.stopExtendedInteractions(state);

    const isShiftClick = isShiftDown(event);
    const selectedFeatureIds = this.getSelectedIds();
    const featureId = event.featureTarget.properties?.id;
    const isFeatureSelected = this.isSelected(featureId);

    // Click (without shift) on any selected feature but a point
    if (!isShiftClick && isFeatureSelected && this.getFeature(featureId).type !== Constants.geojsonTypes.POINT) {
      return;
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId);
      this.updateUIClasses({ mouse: Constants.cursors.POINTER });
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this);
      }
      // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId);
      // this.updateUIClasses({ mouse: Constants.cursors.MOVE });
      // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach((id) => this.doRender(id));
      this.setSelected(featureId);
      // this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    }

    // No matter what, re-render the clicked feature
    this.doRender(featureId);
  },

  /**
   * We customise the toDisplayFeatures function to prevent the creation of
   * supplementart points (Vertices) when in read only mode.
   */
  toDisplayFeatures: function (state, geojson: Feature, display) {
    if (geojson.properties) {
      geojson.properties.active = this.isSelected(geojson.properties.id) ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    }
    display(geojson);
    this.fireActionable?.();
  },

  onDrag: function (state, event) {
    if (this.drawConfig.boxSelect && state.canBoxSelect) return this.whileBoxSelect?.(state, event);
  },

  /**
   * We customise the onMouseDown event to allow the user to drag an unselected
   * Point Feature without first selecting it.
   */
  onMouseDown: function (state, event) {
    state.initialDragPanState = this.map.dragPan.isEnabled();
    if (isFeature(event)) {
      const featureId = event.featureTarget.properties?.id;
      const { type } = this.getFeature(featureId);

      if (type !== "Point" || isShiftMousedown(event)) {
        // For all other feature types we default to the original onMouseDown
        return modes.simple_select.onMouseDown?.call(this, state, event);
      }
      this.clickOnFeature?.(state, event);
      return this.startOnActiveFeature?.(state, event);
    }
    if (this.drawConfig.boxSelect && isShiftMousedown(event)) return this.startBoxSelect?.(state, event);
    return undefined;
  },

  startOnActiveFeature: function (this: DrawCustomModeThisExtended, state) {
    this.stopExtendedInteractions(state);
    this.updateUIClasses({ mouse: Constants.cursors.NONE });
  },

  onMouseMove: function (state, e) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    // Skip render
    return true;
  },
};

export default readOnlySelect;
