import { EntityState } from "@reduxjs/toolkit";

import { GroupConfig, MapLayerConfig } from "fond/types";
import { Item, Layer as _Layer, LayerConfig, LayerStyle, ProjectLayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { formatLength } from "fond/utils";

/**
 * Return the children of an item (ProjectLayerConfig, Group, Layer, or
 * SubLayer) - returning an empty array if it has none.
 */
export function getItemChildren(item: ProjectLayerConfig | Item): Array<Item> {
  if (item.type === "ProjectLayerConfig" || item.type === "Group") {
    return item.items;
  } else if (item.type === "Layer") {
    return item.sublayers;
  } else {
    return [];
  }
}

/**
 * Yield all the items (Groups, Layers, and SubLayers) in the layer config.
 * Parents are yielded before their children.
 */
export function* iterItems(item: ProjectLayerConfig | Item): Generator<Item> {
  for (let child of getItemChildren(item)) {
    yield child;
    yield* iterItems(child);
  }
}

/**
 * Return the `Layer` that has the specified ID, or `undefined` if no such layer exists.
 */
export function getLayerById(layerConfig: ProjectLayerConfig | undefined, id: string): _Layer | undefined {
  if (!layerConfig) return undefined;

  for (let item of iterItems(layerConfig)) {
    if (item.type === "Layer" && item.id === id) {
      return item as _Layer;
    }
  }
}

/**
 * Return the `LayerConfig` that has the specified LayerKey, or `undefined` if no such layer exists.
 */
export function getLayerByKey(layerConfig: LayerConfig[], key: string): LayerConfig | undefined {
  if (!layerConfig) return undefined;

  for (let item of layerConfig) {
    if (item.Type === "LAYER" && item.Key === key) {
      return item;
    }
  }
  return undefined;
}

/**
 * Use the `isVisible` properties of the items in the layer config to construct
 * the initial mapping from item IDs their toggle state.
 */
export function getInitialLayerToggles({
  layers,
  groups,
}: {
  layers: Array<LayerConfig | SublayerConfig>;
  groups: GroupConfig[];
}): Record<string, boolean> {
  let toggles: Record<string, boolean> = {};
  [...layers, ...groups].forEach((layer) => {
    if (layer) {
      toggles[layer.ID] = layer.IsVisible;
    }
  });

  toggles = {
    ...toggles,
    projectComments: true,
    "comments-polygon": true,
    "comments-lineString": true,
    "comments-point": true,
    "comments-arrowLine": true,
  };

  return toggles;
}

// Determines the highest container entity (MLC or Layer) for a child entity
// and returns both that & the layer entity.
type AncestorResult = {
  container: MapLayerConfig | GroupConfig | null;
  layer: GroupConfig | LayerConfig;
  type: "MapLayerConfig" | "GROUP";
};
export function getParentGroup({
  child,
  layerConfigs,
  groupConfigs,
  mlc,
}: {
  child: GroupConfig | LayerConfig | SublayerConfig | LayerStyle;
  layerConfigs: EntityState<LayerConfig | SublayerConfig>;
  groupConfigs: EntityState<GroupConfig>;
  mlc: MapLayerConfig | null;
}): AncestorResult | undefined {
  let result: AncestorResult | undefined;

  const getParent = (item: GroupConfig | LayerConfig | SublayerConfig | LayerStyle | undefined) => {
    if (item) {
      if (item.Type === "GROUP") {
        result = {
          container: mlc,
          layer: item,
          type: "MapLayerConfig",
        };
      } else if (item.Type === "LAYER") {
        if (item.ParentID) {
          result = {
            container: groupConfigs.entities[item.ParentID || ""] || null,
            layer: item,
            type: "GROUP",
          };
        } else {
          result = {
            container: mlc,
            layer: item,
            type: "MapLayerConfig",
          };
        }
      } else if (item.Type === "STYLE") {
        const layerId = item.ConfigurationID;
        if (layerId) getParent(layerConfigs.entities[layerId]);
      } else if (item.Type === "SUBLAYER") {
        const layerId = item.ParentID;
        if (layerId) getParent(layerConfigs.entities[layerId]);
      }
    }
  };

  getParent(child);

  return result;
}

/**
 * Returns the list of stylesIDs in the order they should be
 * addded to the map
 */
export const getLayerStyleOrderIDs = (layers: Array<LayerConfig | SublayerConfig>): string[] => {
  const styleIds: string[] = [];
  layers.forEach((layer) => {
    styleIds.push(...layer.Styles);
  });

  return styleIds;
};

/*
 * Returns the converted total length if this layer has a total length count, otherwise returns the feature count.
 */
export const convertAndFormatLegendCount = (featureTotals: any, systemOfMeasurement: "metric" | "imperial"): string => {
  // We check count before length to capture the scenario when
  // a layer does not yet have data & both length and count are set
  // to "0".  When that occurs we preference displaying (0) over (0 feet)
  if (featureTotals) {
    if (featureTotals.count != null) {
      return Number(featureTotals.count).toLocaleString();
    } else if (featureTotals.length != null) {
      return `${formatLength(featureTotals.length, { from: "metric", to: systemOfMeasurement, decimalPlaces: 0 })}`;
    }
  }

  // Fallback
  return "-";
};
