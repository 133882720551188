import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { MapOutlined, Notifications } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Actions } from "flexlayout-react";

import {
  selectAllVersionLayerConfigsInOrder,
  selectAllVersionStyles,
  selectByVersionId,
  useGetProjectQuery,
  useGetVersionConfigQuery,
  useGetVersionQuery,
} from "fond/api";
import SearchField from "fond/map/Field/SearchField";
import Map from "fond/map/Map";
import MapButtons from "fond/map/MapButtons";
import { MapContext } from "fond/map/MapProvider";
import MapStyleToggle from "fond/map/MapStyleToggle";
import { getCurrentProject, getCurrentProjectView, openImportModal } from "fond/project/redux";
import { Store, View } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { ITabProps } from "./factory";

import { BorderedLine, CircleIcon, OverlayBlendingLayer, UploadDesignOverlay } from "./Map.styles";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  const dispatch = useAppDispatch();
  const projectId = useSelector((state: Store): string => getCurrentProject(state.project)?.ID);
  const projectVersionId = useSelector((state: Store): string => state.project.versionId);
  const activeImports = useSelector((state: Store) => state.imports?.[projectVersionId]);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayerConfigsInOrder(state, projectVersionId));

  const styles = useSelector((state: Store) => selectAllVersionStyles(state, projectVersionId));
  const hasCustomLayerConfig = useSelector((state: Store): boolean => !!getCurrentProject(state.project)?.HasCustomLayerConfig);
  const { layers: layerView } = useSelector((state: Store): View => getCurrentProjectView(state.project));
  const { data: project } = useGetProjectQuery(projectId);
  const editMode = useSelector((state: Store) => state.project.editMode);
  const version = useSelector((state: Store) => selectByVersionId(state, { projectId: project?.ID || "", versionId: state.project.versionId }));
  const { map } = useContext(MapContext);
  const { isFetching: isVersionFetching } = useGetVersionQuery(projectVersionId);
  const { isFetching: isVersionConfigFetching } = useGetVersionConfigQuery(projectVersionId);
  const hasUploadedDesign = layerConfigs.length > 0;

  useEffect(() => {
    node.setEventListener("resize", () => {
      setTimeout(() => {
        map?.resize();
      }, 0);
    });

    node.setEventListener("visibility", () => {
      setTimeout(() => {
        map?.resize();
      }, 0);
    });

    return () => {
      node.removeEventListener("resize");
      node.removeEventListener("visibility");
    };
  }, [map]);

  useEffect(() => {
    // Set the name of the tab to the current project
    node.getModel().doAction(Actions.renameTab(node.getId(), `${project?.ProjectName} ${hasCustomLayerConfig ? `(${version?.Name})` : ""}`));
  }, [project, version]);

  if (!layerConfigs) return null;

  return (
    <>
      <Map key={projectVersionId} editMode={editMode} layerConfigs={layerConfigs} styles={styles} layerView={layerView}>
        <Box className="left-sidebar-section">
          <MapStyleToggle />
        </Box>
        <Box className="right-sidebar-section">
          <Box display="flex" overflow="hidden" pb={1} flexGrow={1}>
            <SearchField />
          </Box>
          <MapButtons version={version} />
        </Box>
      </Map>
      {hasCustomLayerConfig && !hasUploadedDesign && !activeImports && !isVersionFetching && !isVersionConfigFetching && (
        <>
          <OverlayBlendingLayer />
          <UploadDesignOverlay>
            <Notifications fontSize="large" />
            <Typography variant="h5" fontWeight={700}>
              Upload Design
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              Hey! It looks like your project has no design data...
            </Typography>
            <BorderedLine>
              <CircleIcon />
            </BorderedLine>
            <Button variant="contained" onClick={() => dispatch(openImportModal())} data-testid="get-started-button">
              Click here to get started
            </Button>
          </UploadDesignOverlay>
        </>
      )}
    </>
  );
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title={node.getName()}>
    <MapOutlined />
  </Tooltip>
);

/**
 * Title component used by the titleFactory to generate the tabs title text.
 */
export const Title: React.FC<ITabProps> = ({ node }: ITabProps) => {
  return (
    <Tooltip title={node.getName()}>
      <span>{node.getName()}</span>
    </Tooltip>
  );
};
