import React from "react";
import { useField } from "react-final-form";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import { Box, Paper, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { fieldName, fileMap } from "./files";

import { Table, TableContainer } from "./RequiredFiles.styles";

interface IProps {
  type: fieldName;
}

const RequiredFiles: React.FC<IProps> = ({ type }) => {
  const {
    input: { value },
    meta: { error, touched, submitError },
  } = useField<File[] | undefined>(type);
  const hasError = (error || submitError) && touched;

  const hasFile = (name: string) => {
    return (value || []).some(({ name: fileName }) => name === fileName);
  };

  const getRow = ({ title, file }: { title: string; file: string }): JSX.Element => {
    const uploaded = hasFile(file);
    const IconComponent = uploaded ? CheckCircle : CheckCircleOutline;
    // eslint-disable-next-line no-nested-ternary
    const color = uploaded ? "success" : hasError ? "error" : "action";

    return (
      <TableRow key={title}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <IconComponent color={color} sx={{ mr: 1 }} />
            <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
            <Typography variant="body3" sx={{ ml: 1 }}>
              ({file})
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <TableContainer component={Paper} elevation={0} sx={{ mt: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Required input files</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{fileMap[type].map(getRow)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequiredFiles;
