import React from "react";
import { Box, Typography } from "@mui/material";

import { Passing } from "fond/types";
import { formatCurrency } from "fond/utils/currency";

import { SemiDonutChart, SemiDonutChartSlice } from "./reportOverview.styles";

interface IProps {
  passings?: Passing;
  totalConstructionCost: number;
}

const CostPerServiceLocationChart: React.FC<IProps> = ({ passings, totalConstructionCost }) => {
  const passingMinCost = passings?.SharedCost?.at(0)?.PassingMinCost ?? 0;
  const passingMaxCost = passings?.SharedCost?.at(-1)?.PassingMaxCost ?? 0;
  const totalPassingCount = passings?.SharedCost?.reduce((sum, { PassingCount }) => sum + PassingCount, 0);
  const passingAvgCost = totalPassingCount && totalPassingCount > 0 ? totalConstructionCost / totalPassingCount : null;

  return (
    <Box height={268} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <SemiDonutChart>
        <SemiDonutChartSlice />
        <SemiDonutChartSlice />
        <SemiDonutChartSlice />
      </SemiDonutChart>
      <Box width={238} position="relative" display="flex" alignItems="center" justifyContent="center">
        <Box position="absolute" left={18} sx={{ transform: "translateX(-50%)" }}>
          <Typography component="p" fontWeight={700}>
            {formatCurrency(passingMinCost)}
          </Typography>
          <Typography fontSize={10} sx={{ opacity: 0.38, textAlign: "center" }}>
            Min
          </Typography>
        </Box>
        <Box>
          <Typography component="p" fontWeight={700}>
            {formatCurrency(passingAvgCost)}
          </Typography>
          <Typography fontSize={10} sx={{ opacity: 0.38, textAlign: "center" }}>
            Average
          </Typography>
        </Box>
        <Box position="absolute" right={18} sx={{ transform: "translateX(50%)" }}>
          <Typography component="p" fontWeight={700}>
            {formatCurrency(passingMaxCost)}
          </Typography>
          <Typography fontSize={10} sx={{ opacity: 0.38, textAlign: "center" }}>
            Max
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CostPerServiceLocationChart;
