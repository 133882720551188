const colors = {
  red: [219, 40, 40],
  orange: [242, 113, 28],
  yellow: [251, 189, 8],
  olive: [181, 204, 24],
  green: [33, 186, 69],
  teal: [0, 181, 173],
  blue: [33, 133, 208],
  violet: [100, 53, 201],
  purple: [163, 51, 200],
  pink: [224, 57, 151],
  brown: [165, 103, 63],
  grey: [118, 118, 118],
  black: [27, 28, 29],

  biarriRed: htmlHexToArray("#EE1C24"),
  biarriGrey: htmlHexToArray("#9D9D9D"),
  biarriDarkGrey: htmlHexToArray("#525252"),
  biarriLightGrey: htmlHexToArray("#E0E0E0"),
  biarriYellow: htmlHexToArray("#FFF000"),
  biarriOrange: htmlHexToArray("#FFC800"),
  biarriBlue: htmlHexToArray("#5BB1E2"),
  biarriLightBlue: htmlHexToArray("#6EC8F0"),
  biarriGreen: htmlHexToArray("#A0C850"),
};

function htmlHexToArray(h) {
  return [parseInt(h.slice(1, 3), 16), parseInt(h.slice(3, 5), 16), parseInt(h.slice(5, 7), 16)];
}

const opacity = {
  /**
   * `secondary` and `tertiary` correspond to real semantic-ui concepts,
   * `light` is something we made up ourselves.
   */
  secondary: 0.8,
  tertiary: 0.65,
  light: 0.25,
  veryLight: 0.1,
};

export function getColor(color, modifier) {
  const [r, g, b] = colors[color];
  if (modifier != null) {
    return `rgba(${r}, ${g}, ${b}, ${opacity[modifier]})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}
