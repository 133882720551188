import _ from "lodash";

// Note: most of the draw code works with parametrised layers, but doing that
// for the methods in this file is a little bit more tricky so that's been
// deferred.
import { LayerIds } from "fond/layers";
import * as turf from "fond/turf";
import { iterItems, iterKeys } from "fond/utils";
import { prepareFeatureForDraw } from "fond/utils/geojson";

/**
 * NOTE: If you add or change a method here, you need to update the FondMapboxDraw type in fond/map/MapProvider
 */
export function addStoreMethods(draw) {
  draw.getFeatureById = function (id) {
    if (id == null) {
      throw new Error("Trying to getFeatureById without an ID");
    }
    const fl = this.ctx.featureLookup[id];
    if (fl == null || fl.isDeleted) {
      return null;
    }
    let feature;
    if (fl.isEditing) {
      feature = this.ctx.store._features[id].toGeoJSON();
    } else {
      feature = _.find(this.ctx.layers[fl.layerId].features, (f) => f.id === id);
    }
    return {
      feature: feature,
      layerId: fl.layerId,
      isEditing: fl.isEditing,
    };
  };

  draw.isDeletedFeature = function (id) {
    const fl = this.ctx.featureLookup[id];
    return fl != null && fl.isDeleted;
  };

  draw.isEditingFeature = function (id) {
    return this.ctx.featureLookup[id]?.isEditing;
  };

  /**
   * Yields [GeoJSON feature, isEditing: true|false] pairs.
   */
  draw.iterFeatures = function* (layerId) {
    let featureIds = new Set();
    for (let featureId of iterKeys(this.ctx.store._features)) {
      const fl = this.ctx.featureLookup[featureId];
      if (fl != null && fl.layerId === layerId && fl.isEditing && !fl.isDeleted) {
        featureIds.add(featureId);
        yield [this.getFeatureById(featureId).feature, true];
      }
    }
    if (this.ctx.layers[layerId] != null) {
      for (let f of this.ctx.layers[layerId].features) {
        const fl = this.getFeatureById(f.id);
        if (fl != null && !fl.isEditing && !featureIds.has(f.id)) {
          yield [fl.feature, false];
        }
      }
    }
  };

  draw.pullInFeature = function (layerId, feature) {
    this.pullInFeatures([{ layerId, feature }]);
  };

  draw.pullInFeatures = function (features) {
    for (let { layerId, feature } of features) {
      if (!_.isNumber(feature.id)) {
        throw new Error("A feature must have a numeric ID");
      }
      this.ctx.map.setFeatureState({ source: layerId, id: feature.id }, { isEditing: true });
    }
    this.ctx.api.add({
      type: "FeatureCollection",
      features: features.map((f) => f.feature),
    });
    for (let { layerId, feature } of features) {
      if (this.ctx.featureLookup[feature.id] == null) {
        this.ctx.featureLookup[feature.id] = {
          layerId: layerId,
        };
      }
      this.ctx.featureLookup[feature.id].isEditing = true;
    }
  };

  draw.revertAll = function () {
    for (let [featureId, fl] of iterItems(this.ctx.featureLookup)) {
      if (fl.isEditing || fl.isDeleted) {
        this.ctx.map.setFeatureState(
          { source: fl.layerId, id: featureId },
          {
            isEditing: false,
            isDeleted: false,
          }
        );
      }
    }
    this.initLookup();
    this.deleteAll();
  };

  /**
   * "public" mark feature deleted method -- deletes the feature, and if it's a
   * pole, deletes attached spans.
   */
  draw.markFeatureDeleted = function (featureId) {
    const fl = this.getFeatureById(featureId);

    this._markFeatureDeleted(featureId);
    if (fl != null) {
      if (fl.layerId === LayerIds.inPole) {
        // If we delete a pole, delete all spans attached to that pole.
        const poleCoords = fl.feature.geometry.coordinates;
        for (let [span] of this.iterFeatures(LayerIds.inSpan)) {
          if (span.geometry.coordinates.some((c) => _.isEqual(c, poleCoords))) {
            this._markFeatureDeleted(span.id);
          }
        }
      }
      if (fl.layerId === LayerIds.inPole || fl.layerId === LayerIds.inSpan) {
        // If we delete a span, we may need to mark additional poles as
        // unsnapped. If we delete a pole, we may have deleted spans as a
        // result so that might also result in a pole becoming unsnapped.
        this.refreshUnsnappedPoles();
      }
    }
  };

  /**
   * Internal mark feature deleted method -- just deletes the feature itself.
   */
  draw._markFeatureDeleted = function (featureId) {
    const fl = this.ctx.featureLookup[featureId];
    if (fl != null) {
      fl.isEditing = true;
      fl.isDeleted = true;
      this.ctx.map.setFeatureState(
        { source: fl.layerId, id: featureId },
        {
          isEditing: true,
          isDeleted: true,
        }
      );
    }
    this.ctx.store.delete([featureId]);
  };

  /**
   * Pull in the pole and any spans attached to it.
   */
  draw.pullInPole = function (clickedPole) {
    const pole = this.getFeatureById(clickedPole.id).feature;

    let poles;
    if (!this.isEditingFeature(clickedPole.id)) {
      poles = [pole];
    } else {
      poles = [];
    }

    let spans = this.ctx.layers[LayerIds.inSpan].features.filter((span) => {
      return !this.isEditingFeature(span.id) && span.geometry.coordinates.some((c) => _.isEqual(c, pole.coordinates || pole.geometry.coordinates));
    });

    this.pullInFeatures([
      ...poles.map((item) => {
        return { layerId: LayerIds.inPole, feature: item };
      }),
      ...spans.map((span) => {
        return { layerId: LayerIds.inSpan, feature: span };
      }),
    ]);
  };

  /**
   * Create a new pole or move an existing one.
   *
   * 1. If we dragged a pole to a pole it was directly adjacent to, delete the
   *    span connecting the two locations.
   * 2. If there are multiple poles at the new location, delete them all except
   *    `selectedPole`.
   * 3. If we snapped to a ug_path don't split the strand.
   * 4. If we snapped to a strand, split the strand at the point where we
   *    snapped.
   *
   * @param {a GeoJSON Feature} selectedPole the pole that was moved or created.
   * @param {a GeoJSON Feature} originalPole (optional) the pole as it was before it was moved
   *   Should be provided if moving an existing pole, not if creating a new pole.
   * @param {Array<GeoJSON Feature>} (optional) connectedSpans the spans connected to the pole,
   *   as they were before the user started dragging. Also should be provided
   *   only when moving existing poles.
   * @param {{feature: <a GeoJSON Feature>, coords: [x: number, y: number]}} snap (optional)
   *   If provided, and if the `feature` is a span, split it at `coords`.
   *
     Explanation for #1:

     If we have something like this:

           o----o----o

     And we drag the middle pole to (say) the right poles, we end up with this:

           o---------o

     If we didn't delete the span we would have spans on top of each other. The one
     connecting the left to the right, and one connecting the middle to the right. But
     there's no longer anything at the middle so the latter one doesn't make any sense
     anymore, so we delete it.
   */
  draw.commitPole = function ({ selectedPole, originalPole = null, connectedSpans = [], snap = null }) {
    if (originalPole != null) {
      const origCoords = originalPole.geometry.coordinates;
      const newCoords = selectedPole.geometry.coordinates;
      for (let span of connectedSpans) {
        const coords = span.geometry.coordinates;
        if (
          (_.isEqual(origCoords, coords[0]) && _.isEqual(newCoords, coords[1])) ||
          (_.isEqual(origCoords, coords[1]) && _.isEqual(newCoords, coords[0]))
        ) {
          this._markFeatureDeleted(span.id);
        }
      }
    }

    if (snap != null && snap.feature.properties.layerId !== LayerIds.inStreet) {
      // If we create a new pole on a span, we must split the span before calling `mergePoles`,
      // because `mergePoles` is what calls `refreshUnsnappedPoles` and `refreshUnsnappedPoles`
      // won't know that the new pole is snapped if we haven't split the span yet.
      if (snap.feature.geometry.type === "LineString") {
        this.splitSpanAtCoords(snap.feature, snap.coords);
      }

      this.mergePoles(selectedPole);
    } else if (!snap) {
      this.markPoleSnapped(selectedPole.id, false);
    }
  };

  /**
   * Remove any poles colocated with `selectedPole`.
   */
  draw.mergePoles = function (selectedPole) {
    for (let [pole] of this.iterFeatures(LayerIds.inPole)) {
      if (pole.id !== selectedPole.id && _.isEqual(pole.geometry.coordinates, selectedPole.geometry.coordinates)) {
        // Use the internal _markFeatureDeleted because there's still another pole
        // at the point, so we don't want to delete connected spans.
        this._markFeatureDeleted(pole.id);
      }
    }
    this.refreshUnsnappedPoles();
  };

  /**
   * Split the LineString into one span per line, and create one pole at each vertex.
   * Select the last line in the LineString.
   *
   * If provided, `snaps` should be a list of
   *   {feature: <GeoJSON Feature>, coords: [x: number, y: number]}
   * objects, and will be used to split any strands that were snapped to.
   */
  draw.commitSpans = function ({ lineString, snaps = [] }) {
    const existingCoordinates = new Set();

    for (let { feature, coords } of snaps) {
      // If we snapped to any LineStrings, split them at the points we snapped
      // to.
      if (feature.geometry.type === "LineString" && feature.properties.layerId !== LayerIds.inStreet) {
        this.splitSpanAtCoords(feature, coords);
      }
    }

    function coordsKey(coords) {
      return coords.join("-");
    }

    for (let [pole] of this.iterFeatures(LayerIds.inPole)) {
      existingCoordinates.add(coordsKey(pole.geometry.coordinates));
    }

    const poles = lineString.geometry.coordinates
      .filter((c) => !existingCoordinates.has(coordsKey(c)))
      .map((coords) => {
        return prepareFeatureForDraw(
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
          },
          LayerIds.inPole
        );
      });

    // Split linestring into components.
    let spans = [];
    for (let i = 0; i < lineString.geometry.coordinates.length - 1; i++) {
      spans.push(
        prepareFeatureForDraw(
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [lineString.geometry.coordinates[i], lineString.geometry.coordinates[i + 1]],
            },
            properties: {},
          },
          LayerIds.inSpan
        )
      );
    }

    this.pullInFeatures([
      ...spans.map((f) => ({ layerId: LayerIds.inSpan, feature: f })),
      ...poles.map((f) => ({ layerId: LayerIds.inPole, feature: f })),
    ]);

    let selectedFeatureId;
    if (spans.length > 0) {
      const selectedSpan = spans[spans.length - 1];
      this.ctx.store.select([selectedSpan.id]);
      selectedFeatureId = selectedSpan.id;
    }

    this.ctx.store.delete(lineString.id);

    this.refreshUnsnappedPoles();

    return {
      features: [...spans, ...poles],
      selectedFeatureId: selectedFeatureId,
    };
  };

  /**
   * @param {a GeoJSON Feature} span
   * @param {[x: number, y: number]} coords
   *
   * Split the span into two spans at the specified point.
   */
  draw.splitSpanAtCoords = function (span, coords) {
    this.pullInFeature(LayerIds.inSpan, span);
    this.ctx.store._features[span.id].incomingCoords([span.geometry.coordinates[0], coords]);
    this.pullInFeature(LayerIds.inSpan, prepareFeatureForDraw(turf.lineString([coords, span.geometry.coordinates[1]]), LayerIds.inSpan));
  };

  /**
   * For each pole, set its `isUnsnapped` feature state to `true` if it is not
   * snapped to the end of any span, or `false` otherwise.
   */
  draw.refreshUnsnappedPoles = function () {
    let points = new Set();

    function getKey(coord) {
      return coord.join("-");
    }

    for (let [span] of this.iterFeatures(LayerIds.inSpan)) {
      for (let coord of span.geometry.coordinates) {
        points.add(getKey(coord));
      }
    }

    for (let [pole] of this.iterFeatures(LayerIds.inPole)) {
      this.markPoleSnapped(pole.id, points.has(getKey(pole.geometry.coordinates)));
    }
  };

  /**
   * Sets a pole's isSnapped feature state so it will be highlighted if it's
   * not snapped to a span.
   *
   * @param poleId the ID of the pole
   * @param {boolean} isSnapped whether the pole is snapped to a span
   */
  draw.markPoleSnapped = function (poleId, isSnapped) {
    for (let source of [LayerIds.inPole, "mapbox-gl-draw-cold", "mapbox-gl-draw-hot"]) {
      this.ctx.map.setFeatureState({ source: source, id: poleId }, { isSnapped: isSnapped });
    }
  };
}
