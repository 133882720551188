import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { FeatureListTypes, FeaturesList } from "fond/project/features";

import { ITabProps } from "./factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  return <FeaturesList type={FeatureListTypes.ERRORS} />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title="Error log">
    <ErrorOutline />
  </Tooltip>
);
