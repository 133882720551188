import React, { useContext } from "react";
import { HighlightAlt } from "@mui/icons-material";
import Box from "@mui/material/Box";

import LoadingButton from "ui/LoadingButton/LoadingButton";

import { Popup } from "fond/layout";
import { MapContext } from "fond/map/MapProvider";

interface AutoCarvePopupProps {
  isLoading?: boolean;
  onClose(): void;
}

const AutoCarvePopup: React.FC<AutoCarvePopupProps> = ({ isLoading = false, onClose }: AutoCarvePopupProps) => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("passing_draw_polygon");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "passing_draw_polygon" });
  };

  return (
    <Popup width={250} title="Auto Carve Tool" onClose={onClose} offset={{ x: 56, y: 6 }}>
      <Box sx={{ fontSize: 12, mb: 1 }}>
        Manually drawing subareas can be time consuming! So we have added the ability to auto carve an area.{" "}
        <HighlightAlt sx={{ width: 16, height: 16, verticalAlign: "middle" }} />
        <p>To get started, draw an area on the map & we will create some subareas for you.</p>
        <LoadingButton size="small" color="primary" variant="contained" fullWidth onClick={handleOnClick} loading={isLoading}>
          Draw area to carve
        </LoadingButton>
      </Box>
    </Popup>
  );
};

export default AutoCarvePopup;
