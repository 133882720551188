import React from "react";
import { Typography } from "@mui/material";

import { Content, CustomButton, SubText } from "./MissingFlexNapModuleError.styles";

interface IProps {
  /**
   * Extra actions
   */
  action?: React.ReactNode;
}

const MissingFlexNapModuleError: React.FC<IProps> = ({ action }: IProps) => (
  <Content>
    <Typography component="h4" variant="h6">
      FlexNAP Module Required
    </Typography>
    <SubText component="h4" variant="h7">
      You can convert the architcture to a standard one.
    </SubText>
    {action}
    <SubText component="h4" variant="h7">
      or talk to our
      <CustomButton variant="text" href="mailto:bn.sales@biarrinetworks.com">
        sales team
      </CustomButton>
      and book a quick meeting to upgrade your plan.
    </SubText>
  </Content>
);

export default MissingFlexNapModuleError;
