import React, { useRef, useState } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Box, Button, ButtonProps, FormControl, FormHelperText, Paper, Popover, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FieldValidator } from "final-form";

import { BaseIcon } from "fond/types";
import { Image } from "fond/widgets";

type FieldValue = BaseIcon | undefined;

export type IconPickerProps = {
  name: string;
  icons: BaseIcon[] | undefined;
  fieldProps?: Partial<FieldProps<any, any>>;
  buttonProps?: Partial<ButtonProps>;
  validate?: FieldValidator<FieldValue>;
};

const useCustomStyles = makeStyles((theme: Theme) => ({
  image: {
    cursor: "pointer",
    transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
    "&:hover, .selected": {
      padding: 2,
      transform: "scale(2)",
      zIndex: 2,
      boxShadow: theme.shadows[1],
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
    },
  },
  images: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    maxHeight: "272px",
    overflow: "auto",
  },
  iconSelectButton: {
    height: 40,
    width: 40,
    minWidth: "unset",
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
  },
}));

const IconPickerField: React.FC<IconPickerProps> = (props: IconPickerProps) => {
  const { buttonProps, fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <IconPickerWrapper input={input} meta={meta} name={name} buttonProps={buttonProps} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type IconPickerWrapperProps = Pick<IconPickerProps, "buttonProps" | "icons"> & FieldRenderProps<FieldValue, HTMLElement>;

const IconPickerWrapper: React.FC<IconPickerWrapperProps> = ({
  icons,
  input: { buttonProps, name, onChange, value, ...restInput },
  helperText,
}: IconPickerWrapperProps) => {
  const [open, setOpen] = useState(false);
  const classes = useCustomStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOnChange = (icon: BaseIcon) => (event: any) => {
    onChange(icon);
    handleOnClose();
  };

  const handleOnOpen = () => setOpen(true);
  const handleOnClose = () => setOpen(false);

  return (
    <FormControl data-testid={`${name}-icon-field`}>
      <Box ref={anchorRef}>
        {value ? (
          <Tooltip title="Select icon">
            <Button onClick={handleOnOpen} size="small" className={classes.iconSelectButton}>
              <Image base64String={value.GeneratedPng} width="16" height="16" />
            </Button>
          </Tooltip>
        ) : (
          <Button onClick={handleOnOpen} size="small" variant="outlined" {...buttonProps} data-testid="select-icon">
            Select icon
          </Button>
        )}
      </Box>
      <Popover open={open} anchorEl={anchorRef.current} onClose={handleOnClose}>
        <Paper data-testid="select-icon-popover">
          <Box className={classNames(classes.images, "customScrollbars")}>
            {icons?.map((icon) => (
              <Box key={icon.ID} onClick={handleOnChange(icon)}>
                <Image
                  base64String={icon.GeneratedPng}
                  width="16"
                  height="16"
                  className={classNames(classes.image, { selected: icon.ID === value?.ID })}
                />
              </Box>
            ))}
          </Box>
        </Paper>
      </Popover>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default IconPickerField;
