import { useFormState } from "react-final-form";
import { omit } from "lodash";

import { FondStyleType, Layer, LayerClassification, StyleWithoutID } from "fond/types";

import { IClassifyFormData } from "./ClassifyForm";
import { IDefaultStyleFormData } from "./StyleSettings";

/**
 * Generates a NewStyle object that can be used to create new styles based on a
 * layerClassification.
 *
 * @param layerClassification that determines the type of style created & the colors.
 * @returns a newStyle object.
 */
export const generateStyleFromClassification = (layerClassification: LayerClassification, color: string): StyleWithoutID => {
  let newStyle: StyleWithoutID = {
    ID: null,
    ConfigurationID: null,
    ConfigurationType: "SUBLAYER",
    Name: "Style",
    Position: 0,
  };
  if (layerClassification.GeometryType === "Point") {
    newStyle = {
      ...newStyle,
      Type: "circle",
      MapboxStyle: { type: "circle" },
      CircleColor: color,
    };
  }

  if (layerClassification.GeometryType === "LineString") {
    newStyle = {
      ...newStyle,
      Type: "line",
      MapboxStyle: { type: "line" },
      LineColor: color,
      LineWidth: 3,
    };
  }

  if (layerClassification.GeometryType === "Polygon") {
    newStyle = {
      ...newStyle,
      Type: "fill",
      MapboxStyle: { type: "fill" },
      FillColor: color,
    };
  }

  return newStyle;
};

/**
 * Maps a Layer Geometry to a Fond Style Type
 */
export const getFondStyleTypeFromGeometry = (type: Layer["Geometry"]): FondStyleType => {
  if (type === null) return "circle";

  const map: Record<typeof type, FondStyleType> = {
    LineString: "line",
    MultiLineString: "line",
    MultiPoint: "circle",
    MultiPolygon: "fill",
    Point: "circle",
    Polygon: "fill",
    Null: "circle",
  };

  return map[type];
};

/**
 * Gets the styles values from the react-final-form values
 */
export const useDefaultStyle = (): Partial<IDefaultStyleFormData> => {
  const { values } = useFormState<IClassifyFormData>();

  return getDefaultStyleValues(values);
};

export const getDefaultStyleValues = (values: IClassifyFormData): Partial<IDefaultStyleFormData> =>
  omit(values, "attributeId", "colorRamp", "groupSize", "range", "selected");
