import { Box, styled } from "@mui/material";

export const Container = styled(Box)(
  ({ theme }) => `
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
    `
);
