import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { selectCurrentAccount, selectFoldersByParentId, useCreateFolderMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { TextFieldValue } from "fond/form/fields/TextField";
import { AnyObject, Folder, Store } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { compose, required } from "fond/utils/validation";
import { Message, Modal } from "fond/widgets";

interface IFormData {
  Name: string;
  Description?: string;
}

interface IProps {
  /**
   * The parent folders Id
   */
  parentId?: string | null;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Optional Callback function for when the form is submitted
   */
  onSubmit?(values: IFormData): void;
}

const NewFolder: React.FC<IProps> = ({ parentId = null, onClose, onSubmit }: IProps) => {
  const navigate = useNavigate();
  const siblings = useSelector((state: Store) => selectFoldersByParentId(state, parentId));
  const currentAccount = useSelector(selectCurrentAccount);
  const [createFolder, { isLoading: isSaving, isError }] = useCreateFolderMutation();
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = () => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit is validation passes)
    submit();
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name, Description = "" }: IFormData) => {
    createFolder({ Name, Description, ParentID: parentId, AccountID: currentAccount?.ID ?? "" })
      .unwrap()
      .then((folder) => {
        onClose();

        // Navigate to the newly created folder
        navigate(`/folders/${folder.ID}/`);
      });
  };

  /**
   * Validates that the new folders Name is currently not in use
   * @param value New Name value
   */
  const uniqueName = (value: TextFieldValue) => {
    const newName = value?.toString().toLowerCase();
    const existingFolders = siblings.some((folder: Folder) => folder.Name.toLowerCase() === newName);
    if (existingFolders || newName === "home") {
      return "This name is already in use. Please use another.";
    }
    return undefined;
  };

  return (
    <Modal
      open
      header="Create a new folder"
      data-testid="new-folder-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <Message type="error">There was an issue creating this folder. Please try again.</Message>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{
              Name: "",
              Description: "",
            }}
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;

              return (
                <form onSubmit={handleSubmit}>
                  <Box maxWidth={300}>
                    <TextField name="Name" required label="Name" fullWidth validate={compose(required, uniqueName)} />
                  </Box>
                  <Box mt={2}>
                    <TextField name="Description" label="Description" fullWidth multiline rows={3} />
                  </Box>
                </form>
              );
            }}
            mutators={{ setValue }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="new-folder-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="new-folder-save-button" color="primary" onClick={handleOnClick} loading={isSaving}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default NewFolder;
