import React, { forwardRef } from "react";
import { Fullscreen, FullscreenExit, SaveAlt } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AgCartesianSeriesOptions, AgChartOptions } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";

import GridCard from "../../GridCard";
import { VisualizationTypes } from "../VisualizationTypes";

interface IProps {
  fullscreen?: boolean;
  visualizationType: string;
  chartData: any[];
  chartSeries: AgCartesianSeriesOptions[];
  yAxisTitle: string;
  handleVisualizationTypeChange(e: SelectChangeEvent): void;
  downloadChart(): void;
  toggleFullscreen(): void;
}

const ReportRevenueChartTile = forwardRef<AgChartsReact, IProps>(function ReportRevenueChartTile(
  { visualizationType, chartData, chartSeries, yAxisTitle, handleVisualizationTypeChange, fullscreen, downloadChart, toggleFullscreen }: IProps,
  chartRef
) {
  const options: AgChartOptions = {
    data: chartData,
    series: chartSeries,
    autoSize: true,
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          fontSize: 10,
        },
      },
      {
        type: "number",
        position: "left",
        title: {
          text: yAxisTitle,
          fontSize: 12,
        },
        label: {
          format: "~s",
          formatter: (params) => params.formatter!(params.value).replace("G", "B"),
        },
      },
    ],
  };

  return (
    <GridCard
      breakpoints={{ lg: fullscreen ? 12 : 6, xs: 12 }}
      title="Data visualization"
      headerRightElement={
        <Box display="flex" alignItems="center" gap={0.5}>
          <Box width={200}>
            <Select fullWidth value={visualizationType} size="small" onChange={handleVisualizationTypeChange}>
              {Object.entries(VisualizationTypes).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <IconButton size="small" onClick={downloadChart}>
            <SaveAlt fontSize="inherit" />
          </IconButton>
          <IconButton size="small" onClick={() => toggleFullscreen()}>
            {fullscreen ? <FullscreenExit fontSize="inherit" /> : <Fullscreen fontSize="inherit" />}
          </IconButton>
        </Box>
      }
      {...(fullscreen ? { height: "100%" } : {})}
    >
      <Box flex={1} px={2.5} py={3}>
        <AgChartsReact ref={chartRef} options={options} />
      </Box>
    </GridCard>
  );
});

export default ReportRevenueChartTile;
