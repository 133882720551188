import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Box, SxProps } from "@mui/material";

import { useUpdateDocTitle } from "fond/hooks/useUpdateDocTitle";

import { Content, Description, IconContainer, Logo, PageContainer, Title, Top, TopBox } from "./pageCentred.styles";

interface IProps {
  description?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  icon?: React.ReactNode;
  title?: string;
  sx?: SxProps;
  /**
   * The title to display in the browser tab.
   * If not provided, the title will be used.
   */
  windowTitle?: string;
}

const PageCentred: React.FC<PropsWithChildren<IProps>> = ({
  children,
  description,
  rightAdornment,
  icon,
  sx,
  title,
  windowTitle,
}: PropsWithChildren<IProps>) => {
  useUpdateDocTitle(windowTitle ?? title ?? "");

  return (
    <PageContainer>
      <Top>
        <Link to="/" data-testid="link-home">
          <Logo data-testid="fond-home" />
        </Link>
        {rightAdornment && <TopBox>{rightAdornment}</TopBox>}
      </Top>
      <Box>
        <Content sx={sx}>
          {icon && <IconContainer disableGutters>{icon}</IconContainer>}
          {title && <Title data-testid="page-header">{title}</Title>}
          {description && <Description>{description}</Description>}
          {children}
        </Content>
      </Box>
    </PageContainer>
  );
};

export default PageCentred;
