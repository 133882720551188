import chroma from "chroma-js";

import { Configuration, ConfigurationUpsert, CTSBinRangesResponse, CTSCalculationMethod, CTSType, CTSTypeListOrdered, GroupConfig } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

export const CTS_GROUPCONFIG_ID = "cost_to_serve_group";

const titles: Record<CTSType, string> = {
  IndivCost: "Individual Cost",
  SharedCost: "Shared Cost",
  AccCost: "Accumulated Cost",
};

const colors: string[] = ["#30A255", "#83C73D", "#D6EC26", "#F8CC20", "#EB662B", "#DE0036"];

/**
 * Check if the provided cost to serve ranges contain only zero values.
 */
export const allZeroCostToServeRanges = (ranges: CTSBinRangesResponse): boolean => {
  const { BinRanges: binRanges } = ranges;

  return CTSTypeListOrdered.every((ctsType: CTSType) => {
    const item = binRanges[ctsType];
    return item?.equal_counts?.length > 0 && item.equal_counts.every((value) => value === 0);
  });
};

/**
 * Takes the cost to serve ranges and converts them to the correct format for display.
 *
 * @param ranges the cost to serve bin ranges response object
 * @param maxNumberOfBins an optional parameter to control the max bin ranges to display.
 *    The `ranges` param may contain more bins (e.g., 50) than what we want to display (e.g., 5 in the legend panel).
 *    In this case, we would like to consolidate the 50 bins into 5 when displaying them. To achieve it, we specify
 *    a `maxNumberOfBins` value of 5.
 */
export const costToServeRanges = (ranges: CTSBinRangesResponse, maxNumberOfBins?: number): ConfigurationUpsert => {
  const { BinRanges: binRanges } = ranges;
  const result: Array<GroupConfig | LayerConfig | SublayerConfig | LayerStyle> = [];

  const groupId = CTS_GROUPCONFIG_ID;
  const layerConfigs: LayerConfig[] = [];

  CTSTypeListOrdered.filter((ctsType: CTSType) => (Object.keys(binRanges) as CTSType[]).includes(ctsType)).forEach((type) => {
    const layerConfigId = `cost_to_serve_addresses_layer_${type}`;
    (Object.keys(binRanges[type]) as CTSCalculationMethod[])
      // For now we only include CTSCalculationType.EQUAL_COUNTS
      .filter((key) => {
        return key === "equal_counts";
      })
      .forEach((calcType) => {
        const values = binRanges[type][calcType];
        const layerStyles: LayerStyle[] = [];
        const sublayerConfigs: SublayerConfig[] = [];

        const lastIndex = values.length - 1;
        const numBins = Math.min(lastIndex, maxNumberOfBins ?? lastIndex);
        // We need a start color and a end color for each bin range. So for X bin ranges, we need X + 1 colors.
        // https://www.vis4.net/chromajs/#chroma-scale
        const colorRange = numBins + 1 > colors.length ? chroma.scale(colors).colors(numBins + 1) : colors;
        // Calculate the size of each bin range.
        const stepSize = Math.floor(lastIndex / numBins);

        // Create styles for each sublayer
        for (let step = 0; step < numBins; step += 1) {
          // The min index will be 0 * stepSize, 1 * stepSize, 2 * stepSize, etc.
          let minIndex = step * stepSize;

          // The max index can be either:
          // 1. The `min + stepSize` if not the last step
          // 2. The last index of the bin ranges if it is the last step.
          //    Because we round down when calculating the step size, so the last step can be bigger than the others
          let maxIndex = step === numBins - 1 ? lastIndex : minIndex + stepSize;

          const styleId = `cost_to_serve_addresses_${type}_${calcType}_style_${step}`;
          const sublayerConfigId = `cost_to_serve_addresses_${type}_${calcType}_sublayer_${step}`;

          const style = styleTemplate(
            styleId,
            sublayerConfigId,
            type as CTSType,
            values[minIndex],
            values[maxIndex],
            colorRange[step],
            colorRange[step + 1]
          );
          const sublayerConfig = sublayerConfigTemplate(sublayerConfigId, layerConfigId, type as CTSType, values[minIndex], values[maxIndex], style);

          layerStyles.push(style);
          sublayerConfigs.push(sublayerConfig);
        }
        result.push(...layerStyles);
        result.push(...sublayerConfigs);

        // Create the layer
        const layerConfig = layerConfigTemplate(layerConfigId, groupId, type as CTSType, sublayerConfigs);
        layerConfigs.push(layerConfig);
        result.push(layerConfig);
      });
  });

  // Create GroupConfig
  const groupConfig = groupConfigTemplate(groupId, layerConfigs);
  result.push(groupConfig);

  return result;
};

/**
 * Takes the cost to serve ranges and generates the style configuration for the report cost map.
 */
export const generateCostMapConfiguration = (ranges: CTSBinRangesResponse): Configuration => {
  const data = costToServeRanges(ranges);
  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [],
    Type: "MapLayerConfig",
  };
};

const styleTemplate = (id: string, sublayerId: string, type: CTSType, min: number, max: number, minColor: string, maxColor: string): LayerStyle => ({
  ID: id,
  Name: id,
  ConfigurationID: sublayerId,
  ConfigurationType: "LAYER",
  Position: 0,
  GlobalPosition: 0,
  Type: "STYLE",
  MapboxStyle: {
    type: "circle",
    paint: {
      "circle-color": min === max ? minColor : ["interpolate", ["linear"], ["to-number", ["get", type]], min, minColor, max, maxColor],
      "circle-radius": ["interpolate", ["linear"], ["zoom"], 10, 1, 14, 4, 15, 5, 16, 6],
      "circle-stroke-width": ["case", ["boolean", ["==", ["feature-state", "isSelected"], true]], 2, 0],
      "circle-stroke-color": ["case", ["boolean", ["feature-state", "isSelected"], true], "#FFFF00", "#FFFFFF"],
    },
  },
  RawStyles: {
    CircleColorInterpolate: [min, minColor, max, maxColor],
    Type: "circle",
  },
});

const sublayerConfigTemplate = (id: string, layerId: string, type: CTSType, min: number, max: number, style: LayerStyle): SublayerConfig => ({
  ID: id,
  Label: `${min === max ? `$${min.toLocaleString()}` : `$${min.toLocaleString()} - $${max.toLocaleString()}`}`,
  IsVisible: true,
  Styles: [style.ID],
  Position: 0,
  Type: "SUBLAYER",
  SubType: "COST_TO_SERVE",
  GeometryType: "Point",
  ParentID: layerId,
  Key: "output/service_location",
  FilterConfiguration: {
    Type: "group",
    Mapbox: ["all", [">=", ["get", type], min], [min === max ? "<=" : "<", ["get", type], max]],
  },
});

const layerConfigTemplate = (id: string, groupId: string, type: CTSType, children: SublayerConfig[]): LayerConfig => ({
  ID: id,
  Label: titles[type],
  IsVisible: true,
  Styles: [],
  Position: 0,
  Type: "LAYER",
  SubType: "COST_TO_SERVE",
  GeometryType: "Point",
  GlobalPosition: 0,
  ParentID: groupId,
  Key: "output/service_location",
  MaxZoomLevel: 24,
  MinZoomLevel: 0,
  Children: pickIDs(children),
});

const groupConfigTemplate = (id: string, children: LayerConfig[]): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: "Cost to Serve",
  Key: null,
  Children: pickIDs(children),
  IsVisible: true,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
});
