import { Box, Chip, styled, TextField, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const SelectedContainer = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  fontSize: theme.spacing(1.5),
}));

export const UserSearchField = styled(TextField)(({ theme }) => ({
  width: "25%",
  marginLeft: "auto",
  marginBottom: theme.spacing(1.75),
}));

export const AdminChip = styled(Chip)(({ theme }) => ({
  height: theme.spacing(2),
  marginLeft: theme.spacing(1),

  "& .MuiChip-label": {
    padding: `0 ${theme.spacing(1.25)}`,
  },
}));

AdminChip.defaultProps = {
  label: "Admin",
};
