import React, { useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { HelpOutline } from "@mui/icons-material";
import { Alert, Box, Button, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import {
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useGetAccountUsersQuery,
  useGetPermissionsQuery,
  useGetUserMeAccountsQuery,
  useGetUserShareSuggestionsQuery,
  useInviteUserMutation,
  useUpdatePermissionMutation,
} from "fond/api";
import { PermissionsField, SwitchField, TextField } from "fond/form/fields";
import mixpanel from "fond/mixpanel";
import { AccountBase, EntityLabel, ExtendedPermission, ResourceEntity, Store, User, UserShareSuggestion } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { Actions, parsePermissions, permissionCheck } from "fond/utils/permissions";
import { BlockSpinner, Message as ErrorMessage, Modal } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    infoIcon: {
      marginLeft: theme.spacing(1),
    },
  });
};

interface IFormData {
  Message: string;
  SendEmailNotifications: boolean;
  Users: ExtendedPermission[];
}

interface IProps extends WithStyles<typeof customStyles> {
  resource: ResourceEntity;
  /**
   * Callback function for when the modal requests closure;
   */
  onClose(): void;
}

const Share: React.FC<IProps> = ({ classes, resource, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const account = resource.Account;
  const { data: userAccounts, isLoading: isLoadingUserAccounts } = useGetUserMeAccountsQuery();
  const { data: users, isLoading: isUserShareSuggestionsLoading } = useGetUserShareSuggestionsQuery({
    scenario: `${resource.EntityType}.share`,
    entityId: resource.ID,
  });
  const currentUserInResourceAccount = useMemo(
    () => userAccounts?.some((allocation) => allocation.Account.ID === account.ID),
    [userAccounts, account]
  );

  const {
    data: accountUsers,
    isLoading: isAccountUsersLoading,
    isError: isAccountUsersError,
  } = useGetAccountUsersQuery(
    // Account membership information is only available to account members, so avoid querying for an account
    // if we're not a member of it. This is completely fine: a user that isn't in a resource's account
    // can't create WRITE/MANAGE permissions in the first place, and so doesn't need to know about account users.
    account?.ID && currentUserInResourceAccount ? account.ID : skipToken
  );

  const {
    data: rawPermissions,
    isLoading: isPermissionsLoading,
    isError: isPermissionsError,
    refetch: refetchPermissions,
  } = useGetPermissionsQuery({
    type: resource.EntityType,
    id: resource.ID,
  });

  const hasErrors = useMemo(() => isPermissionsError || isAccountUsersError, [isPermissionsError, isAccountUsersError]);
  const isLoading = useMemo(
    () => isUserShareSuggestionsLoading || isLoadingUserAccounts || isAccountUsersLoading || isPermissionsLoading,
    [isUserShareSuggestionsLoading, isLoadingUserAccounts, isAccountUsersLoading, isPermissionsLoading]
  );
  const [inviteUser] = useInviteUserMutation();
  const [createPermission] = useCreatePermissionMutation();
  const [deletePermission] = useDeletePermissionMutation();
  const [updatePermission] = useUpdatePermissionMutation();
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const formRef: React.MutableRefObject<FormApi<IFormData, Partial<IFormData>> | undefined> = useRef();

  const permissions: ExtendedPermission[] = useMemo(
    () =>
      parsePermissions({
        permissions: rawPermissions,
        resourceId: resource.ID,
        username: currentUsername,
        suggestions: users,
        account,
        accountUsers,
      }),
    [rawPermissions, users, accountUsers, account, currentUsername, resource.ID]
  );

  const suggestions: Array<UserShareSuggestion | AccountBase> = useMemo(() => {
    if (resource.Permission?.Level === "manage") {
      return [
        {
          ID: account?.ID ?? "",
          Name: account?.Name ?? "",
        },
        ...(users || []),
      ];
    }
    return users || [];
  }, [users, account?.ID, account?.Name, resource.Permission?.Level]);

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Users, SendEmailNotifications, Message }: IFormData) => {
    setIsSaving(true);

    // Determine what action (if any) needs to be done for each permission submitted
    let promises: any[] = Users.map(async (permission) => {
      const modifiedExistingPermission =
        permissions.find((existingPermission) => existingPermission.ID === permission.ID)?.Level !== permission.Level;

      if (permission.Submitted) {
        // Permission has already been saved so we dont want to re-process
        // This will occur when only some permissions have save errors.
        return undefined;
      } else if (permission.ID && (permission.Remove || ((permission.Revert || modifiedExistingPermission) && permission.Level === "inherited"))) {
        // If an existing permission is being deleted or a permission that is overriding
        // an inherited permission is being removed.
        return deletePermission(permission.ID).unwrap();
      } else if (permission.Level === "inherited") {
        // Inherited permissions unchanged require no action.
        return undefined;
      } else if (!permission.ID || permission.New || (permission.Inherited && permission.ReadOnly)) {
        let newUser: User | undefined;
        if (!permission.Identity.ID && permission.IdentityType === "user" && permission.Identity.Email) {
          newUser = await inviteUser(permission.Identity.Email).unwrap();
        }
        // User has added a new permission or overridden an inherited permission
        // that does not already have a permission overriding it.
        return createPermission({
          IdentityID: permission.IdentityType === "user" ? permission.Identity.ID || newUser?.ID : permission.Identity.ID,
          IdentityType: permission.IdentityType,
          ResourceType: resource.EntityType,
          ResourceID: resource.ID,
          Level: permission.Level,
          Message,
          SendEmailNotifications,
        }).unwrap();
      } else if (modifiedExistingPermission) {
        // User has modified an existing permission.
        return updatePermission({ ID: permission.ID, Level: permission.Level }).unwrap();
      } else {
        return undefined;
      }
    });

    if (promises.length === 0) {
      onClose();
    } else {
      return Promise.allSettled(promises).then((values): any => {
        // Build any errors that need to be returned
        const errors: { [key: string]: string } = {};
        const reason = new Map();
        reason.set(403, "You do not have adequate permission to complete this change.");
        reason.set(404, "You are not allowed to change this user or groups permissions.");
        reason.set(422, "There was an issue completing this request.");
        reason.set(500, "There was an issue completing this request.");

        values.forEach((result: PromiseSettledResult<{ status: "rejected" | "fulfilled"; reason: any }>, index: number) => {
          if (result.status === "rejected") {
            errors[index] = reason.get(result.reason.status) || reason.get(500);
          } else {
            // We update the Submitted value of the permissions that successfully saved.
            // We use this to prevent users from modifying saved values if other permissions failed
            formRef.current?.mutators.setValue(`Users[${index}]`, { ...Users[index], Submitted: true });
          }
        });

        setIsSaving(false);
        refetchPermissions();

        // Return any errors
        if (Object.keys(errors).length > 0) {
          return { Users: errors };
        } else {
          enqueueSnackbar("Update complete.");
          onClose();
          return Promise.resolve();
        }
      });
    }
  };

  let modalContent: React.ReactNode;

  if (isPermissionsError) {
    modalContent = (
      <Box>
        <ErrorMessage type="error">There was an issue loading permissions. Please try again.</ErrorMessage>
      </Box>
    );
  } else if (isAccountUsersError) {
    modalContent = (
      <Box>
        <ErrorMessage type="error">There was an issue loading account users. Please try again.</ErrorMessage>
      </Box>
    );
  } else {
    modalContent = (
      <>
        <Box mb={2}>
          {permissionCheck(resource.Permission?.Level, Actions.FOLDER_SHARE_EDIT) ? (
            <Typography>
              {`Set which users and groups have access to ${resource.EntityType === "project" ? resource.ProjectName : resource.Name}.`}
            </Typography>
          ) : (
            <Alert severity="info">{`You do not have permission to share this ${EntityLabel[resource.EntityType]} with other people or groups.`}</Alert>
          )}
        </Box>
        <Form<IFormData>
          initialValues={{ Message: "", Users: permissions, SendEmailNotifications: true }}
          onSubmit={handleOnSubmit}
          keepDirtyOnReinitialize
          render={({
            handleSubmit,
            values,
            dirtyFields,
            form,
            form: {
              mutators: { push },
            },
          }) => {
            formRef.current = form;

            return (
              <form id="share-modal-form" onSubmit={handleSubmit}>
                <Box>
                  <PermissionsField
                    resource={resource}
                    name="Users"
                    push={push}
                    currentPermission={resource.Permission?.Level}
                    currentUsername={currentUsername}
                    values={values.Users}
                    suggestions={suggestions}
                  />
                </Box>
                {dirtyFields.Users && values.Users.some((perm) => perm.New && perm.Level !== "deny") && (
                  <>
                    <Box mt={2}>
                      <SwitchField
                        name="SendEmailNotifications"
                        color="primary"
                        label="Send a notification to new people"
                        data-testid="share-permission-send-switch"
                      />
                    </Box>
                    {values.SendEmailNotifications && (
                      <Box mt={2}>
                        <TextField name="Message" multiline rows={6} placeholder="Add a message" />
                      </Box>
                    )}
                  </>
                )}
              </form>
            );
          }}
          mutators={{ ...arrayMutators, setValue }}
        />
      </>
    );
  }

  return (
    <Modal
      open
      data-testid="share-modal"
      className="share-modal"
      variant="primary"
      onClose={onClose}
      header={
        <Box display="flex" alignItems="center">
          Share with people and groups
          <Tooltip
            title={
              <span>
                <Typography variant="subtitle2">
                  <strong>Manager: </strong>
                  Administrative control.
                </Typography>
                <br />
                <Typography variant="subtitle2">
                  <strong>Contributor: </strong>
                  Full control over the designs, attachments, architecture and configuration
                </Typography>
                <br />
                <Typography variant="subtitle2">
                  <strong>Collaborator: </strong>
                  The ability to view, comment on and download designs and attachments
                </Typography>
                <br />
                <Typography variant="subtitle2">
                  <strong>Viewer: </strong>
                  The ability to view projects and designs
                </Typography>
              </span>
            }
          >
            <HelpOutline color="primary" className={classes.infoIcon} />
          </Tooltip>
        </Box>
      }
      content={<Box>{isLoading ? <BlockSpinner /> : modalContent}</Box>}
      actions={
        permissionCheck(resource.Permission?.Level, Actions.FOLDER_SHARE_EDIT) ? (
          <>
            <Button
              color="primary"
              data-testid="share-project-cancel-button"
              onClick={() => {
                onClose();
                mixpanel.track("Closed share modal");
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <LoadingButton
              data-testid="share-project-save-button"
              color="primary"
              form="share-modal-form"
              type="submit"
              disabled={hasErrors || isSaving}
              loading={isSaving}
            >
              {formRef.current?.getState().hasSubmitErrors ? "Retry" : "Share"}
            </LoadingButton>
          </>
        ) : (
          <Button
            data-testid="share-project-done-button"
            onClick={() => {
              onClose();
              mixpanel.track("Closed share modal");
            }}
            variant="contained"
            color="primary"
          >
            Done
          </Button>
        )
      }
    />
  );
};

export default withStyles(customStyles)(Share);
