import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import { ErrorNavigationState } from "./types";

type NavigateErrorProps = ErrorNavigationState & {
  to?: string;
  replace?: boolean;
};

/**
 * Navigate to an error page with the provided state.
 *
 * All props are optional.
 *
 * @param to - the error page to navigate to, defaults to "/error"
 * @param replace - whether to replace the current entry in the history stack, defaults to true
 * @param resourceType - the type of resource that was not found
 * @param errorMessage - the custom error message to display if provided
 * @returns
 */
export const NavigateError = ({ to, replace, ...state }: NavigateErrorProps): ReactElement => {
  return <Navigate to={to ?? "/error"} state={state} replace={replace ?? true} />;
};
