import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Box, Button, Typography } from "@mui/material";
import { identity } from "lodash";

import { LoadingButton } from "ui";

import { useGetReportsQuery, useLazyGetReportQuery } from "fond/api";
import { Autocomplete } from "fond/form/fields";
import { FullReport, Report } from "fond/types";
import { required } from "fond/utils/validation";
import { Modal } from "fond/widgets";

type ImportReportDialogProps = {
  onClose: () => void;
  onImport: (report: FullReport | undefined) => void;
};

type IFormData = {
  Report: ReportOption;
};

type ReportOption = {
  ID: string;
  Name: string;
};

/**
 * Sort method for sorting the reports by LastModified date so most recently modified reports are at the top
 * @param r1 - report 1
 * @param r2 - report 2
 * @returns
 */
const sortReportsByLastModified = (r1: Report | undefined, r2: Report | undefined) => {
  const d1 = new Date(r1?.LastModified ?? "");
  const d2 = new Date(r2?.LastModified ?? "");
  if (d1 > d2) {
    return -1;
  }
  if (d1 < d2) {
    return 1;
  }
  return 0;
};

export const ImportReportDialog: React.FC<ImportReportDialogProps> = ({ onClose, onImport }: ImportReportDialogProps) => {
  const { data, isLoading: isReportsLoading } = useGetReportsQuery(undefined);
  const [getReportQuery] = useLazyGetReportQuery();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (values: IFormData) => {
    setIsLoading(true);
    const { data: reportToImport } = await getReportQuery(values.Report.ID);
    setIsLoading(false);
    onImport(reportToImport);
    onClose();
  };

  const reportsOptions = useMemo((): ReportOption[] => {
    if (!data?.entities) return [];
    // the cast to Report[] is necessary because RTK returns their own Dictionary<T> where they union T with undefined
    const reports = Object.values(data.entities)
      .filter(identity)
      // also filter out any reports that do not have a version.
      .filter((report) => report?.Version !== null)
      .sort(sortReportsByLastModified) as Report[];
    return reports.map((report): ReportOption => ({ ID: report.ID, Name: report.Name }));
  }, [data?.entities]);

  const modalContent = (
    <Form<IFormData>
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form id="import-report-configuration-form" onSubmit={handleSubmit}>
            <Box>
              <Typography mb={2}>
                This automatically fills in configuration details based on the report you select, saving you time and effort.
              </Typography>
              <Autocomplete
                name="Report"
                required
                label="Report"
                fullWidth
                value={values?.Report ?? null}
                options={reportsOptions}
                getOptionLabel={(option) => option.Name}
                filterOptions={(options, state) => options.filter((option) => option.Name.toLowerCase().includes(state.inputValue.toLowerCase()))}
                validate={required}
                disabled={isLoading || isReportsLoading}
              />
            </Box>
          </form>
        );
      }}
    />
  );
  const actions = (
    <>
      <Button type="button" data-testid="import-report-configuration-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
        Cancel
      </Button>
      <LoadingButton
        type="submit"
        data-testid="import-report-configuration-save-button"
        color="primary"
        loading={isLoading || isReportsLoading}
        form="import-report-configuration-form"
      >
        Import
      </LoadingButton>
    </>
  );

  return <Modal open header="Import report configuration" data-testid="import-report-configuration-modal" content={modalContent} actions={actions} />;
};
