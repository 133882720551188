import React, { useMemo } from "react";
import { Alert, Chip, Typography } from "@mui/material";

import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import { MultiProject } from "fond/types";
import { formatNumber } from "fond/utils/number";
import { StackedNavigationButton, SvgIcon, useStackedNavigationContext } from "fond/widgets";

interface AreaPanelProps {
  multiProject: MultiProject;
}

const AreaPanel: React.FC<AreaPanelProps> = ({ multiProject }: AreaPanelProps) => {
  const hasProjects = useMemo(() => multiProject?.Areas.some((area) => area.Project?.ID), [multiProject]);
  const { open } = useStackedNavigationContext();

  const handleOnClick = () => {
    open("areaDraw");
  };

  return (
    <>
      <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 500 }}>
        Define your subareas
      </Typography>
      <Typography sx={{ fontSize: 12 }}>Subareas are projects that will generate a plan.</Typography>
      <br />
      <Typography component="p" variant="caption" color="biarri.secondary.grey">
        Note that there is a {formatNumber(CITY_PLANNER_AREA_MAX)} prem limit per subarea.
      </Typography>
      <StackedNavigationButton
        data-testid="area-method-navigation"
        icon={<SvgIcon icon="polygon" />}
        title="Identify subareas"
        onClick={handleOnClick}
        disabled={hasProjects}
        rightElement={
          multiProject.Areas.length > 0 ? (
            <Chip size="small" label={<Typography variant="body3">{multiProject.Areas.length}</Typography>} sx={{ mr: 1 }} />
          ) : null
        }
        sx={{ m: 0, mt: 1 }}
      />

      {hasProjects && (
        <Alert severity="info" icon={false} sx={{ mt: 1, fontSize: 12 }}>
          <b>Note:</b> the subareas cannot be edited once projects have been created.
        </Alert>
      )}
    </>
  );
};

export default AreaPanel;
