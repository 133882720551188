import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";

/**
 * Typography with a gradual gradient from start to end color applied.
 */
export interface GradientTypographyProps extends TypographyProps {
  startcolor: string;
  endcolor: string;
}

export const GradientTypography = styled(Typography)<GradientTypographyProps>(({ theme, startcolor, endcolor }) => ({
  background: `linear-gradient(90deg, ${startcolor} 0%, ${endcolor} 50%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
}));
