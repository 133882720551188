import { FC } from "react";
import { useLocation } from "react-router";
import { Typography } from "@mui/material";

import ErrorPage from "fond/errorPage/ErrorPage";
import { InvalidLinkIcon } from "fond/svg_icons";
import { SupportLink } from "fond/widgets";

/**
 * The component to display if invitation the user opened is invalid
 */
const InvalidInvitation: FC = () => {
  const location = useLocation();
  return location.state?.multipleAccountsError ? (
    <ErrorPage
      title="Invalid invitation"
      icon={<InvalidLinkIcon />}
      heading="Sorry, you can't join another account."
      message={
        <>
          <Typography>You are already a member of an account.</Typography>
          <Typography>
            Please contact our
            <b>
              <SupportLink text=" Support team " />
            </b>
            for assistance.
          </Typography>
        </>
      }
    />
  ) : (
    <ErrorPage
      title="Invalid invitation"
      icon={<InvalidLinkIcon />}
      heading="Sorry, this link is no longer valid"
      message={<Typography>Please check with the person who invited you.</Typography>}
    />
  );
};

export default InvalidInvitation;
