import { cloneDeep } from "lodash";

import { PlannerFeatures } from "fond/types";

import { applyRulesIf, fieldRules as baseFieldRules, isUsingConnectorizedDropTerminals } from "./fieldRules";
import { rules } from "./rules";

/**
 * Find the preferred architecture rules from the fieldRules based on the planner feature.
 */
export const getPreferredRules = (plannerFeature: PlannerFeatures | undefined): any => {
  if (!plannerFeature) return null;
  return fieldRules[plannerFeature];
};

let FlexNapFieldRules: any = cloneDeep(baseFieldRules);
FlexNapFieldRules.Tier2.ConnectorizedDropTerminals.MaxTailLength = applyRulesIf(isUsingConnectorizedDropTerminals, [
  ...rules.nonNegativeNumber,
  ...rules.maxLength({
    maxFeet: 3400,
    maxMeters: 1000,
    state: "error",
    messageFunc: (length: number) => `Cannot set tail lengths over ${length}`,
  }),
  ...rules.maxLength({
    maxFeet: 2000,
    maxMeters: 600,
    state: "warning",
    messageFunc: (length: number) => `Tail lengths over ${length} may greatly increase the time taken to generate a design`,
  }),
]);

const fieldRules: { [feature in PlannerFeatures]: any } = {
  FlexNap: FlexNapFieldRules,
};
