import { useMemo } from "react";
import { ColDef, ColumnMenuTab, GetRowIdParams, GridOptions, ICellRendererParams } from "@ag-grid-community/core";
import dayjs from "dayjs";

import SubscriptionStatusCell from "fond/settings/AccountManagement/SubscriptionStatusCell";
import { Subscription } from "fond/types";
import { dateDisplayFormat } from "fond/utils/dates";

interface IUseLicenseInfoList {
  columns: ColDef[];
  gridOptions: GridOptions;
}

export const useLicenseInfoList = (): IUseLicenseInfoList => {
  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "autoHeight",
    getRowId: (params: GetRowIdParams) => params.data.ID,
  };

  const columns: ColDef[] = useMemo(() => {
    const columnMenu: ColumnMenuTab[] = ["filterMenuTab"];
    return [
      {
        headerName: "Status",
        field: "Status",
        cellRenderer: (row: ICellRendererParams) => <SubscriptionStatusCell subscription={row.data as Subscription} />,
        flex: 1,
        menuTabs: columnMenu,
        cellClass: "vertically-align",
      },
      {
        headerName: "No. of licenses",
        field: "LicenseCount",
        flex: 1,
        filter: "agNumberColumnFilter",
        menuTabs: columnMenu,
      },
      {
        headerName: "Start date",
        field: "StartTime",
        valueFormatter: (cell) => dayjs(cell.value).format(dateDisplayFormat),
        flex: 1,
        filter: "agDateColumnFilter",
        menuTabs: columnMenu,
      },
      {
        headerName: "Expiry date",
        field: "EndTime",
        valueFormatter: (cell) => dayjs(cell.value).format(dateDisplayFormat),
        flex: 1,
        filter: "agDateColumnFilter",
        menuTabs: columnMenu,
      },
    ];
  }, []);

  return { columns, gridOptions };
};
