import React from "react";
import { FileRejection } from "react-dropzone";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

import { Modal } from "fond/widgets";

interface IProps extends WithStyles<typeof customStyles> {
  onClose: () => void;
  rejectedFiles: FileRejection[];
}

const customStyles = (theme: Theme) => {
  return createStyles({
    rejectedFileModalContent: {
      overflow: "hidden",
    },
    rejectedFileList: {
      maxHeight: "50vh",
      overflowY: "auto",
      marginTop: 10,
    },
  });
};

const RejectedFilesModal: React.FC<IProps> = ({ classes, onClose, rejectedFiles }: IProps) => {
  return (
    <Modal
      data-testid="attachment-staging-reject-drop-modal"
      open
      header="Some files could not be accepted"
      onClose={onClose}
      DialogContentProps={{ className: classes.rejectedFileModalContent }}
      content={
        <Box>
          <Box>The following files are of types that can not be accepted.</Box>
          <Box>These files may be uploaded within a zip file.</Box>
          <Box className={classNames(classes.rejectedFileList, "customScrollbars")}>
            <ul>
              {rejectedFiles.map(({ file }) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </Box>
        </Box>
      }
      actions={
        <Button data-testid="dropzone-reject-file-modal-close-button" color="primary" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

RejectedFilesModal.displayName = "RejectedFilesModal";
export default withStyles(customStyles)(RejectedFilesModal);
