/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomMode, DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import { defer } from "lodash";
import polygonSplitter from "polygon-splitter";

import { booleanDisjoint } from "fond/turf";
import { isAnyPolygon } from "fond/types/geojson";

import { defaultOptions, HIGHLIGHT_PROPERTY_NAME, PASSING_MODE_NAME } from "./constants";

const {
  constants: { events },
} = MapboxDraw;

type ExtendedDrawCustomMode = DrawCustomMode & {
  fireUpdate(feature: MapboxDraw.DrawFeature): void;
};

/**
 * A custom drawing mode that allows the user to select polygon(s) & then draw
 * a overlapping LineString that will split the selected polygons where the overlap
 * occurs.
 */
const splitPolygonMode: ExtendedDrawCustomMode = {
  toDisplayFeatures: function toDisplayFeatures(_, geojson, display) {
    display(geojson);
  },

  onSetup: function onSetup(opt) {
    const { highlightColor = defaultOptions.highlightColor, uncombine = defaultOptions.uncombine } = opt || {};
    const originalFeatures = this.getSelected()
      .filter((feat) => feat.type === "Polygon" || feat.type === "MultiPolygon")
      .map((feat) => feat.toGeoJSON());

    if (originalFeatures.length < 1) {
      throw new Error("Please select a feature/features (Polygon or MultiPolygon) to split!");
    }

    const { api } = this._ctx;

    // The `onSetup` job should complete for this mode to work.
    // so we defer to bypass mode change until after `onSetup` is done executing.
    defer(() => {
      this.changeMode(PASSING_MODE_NAME, {
        onDraw: (splittingLineString) => {
          const ids: Array<string | number> = [];

          originalFeatures.forEach((feature) => {
            if (feature.type === "Feature" && isAnyPolygon(feature)) {
              if (!booleanDisjoint(feature, splittingLineString)) {
                const newFeature = polygonSplitter(feature, splittingLineString);
                if (feature.id) {
                  newFeature.id = feature.id;
                  newFeature.properties = feature.properties;

                  const drawFeature = this.newFeature(newFeature);
                  this.addFeature(drawFeature);
                  this.fireUpdate(drawFeature);

                  ids.push(feature.id);
                }
              }
            } else {
              throw new Error("The feature is not a Polygon or MultiPolygon");
            }
          });

          // Automatically uncombine the MultiPolygon features created by the splitting
          if (uncombine && ids.length > 0) {
            defer(() => {
              this._ctx.api.changeMode("simple_select", { featureIds: ids });
              this._ctx.api.uncombineFeatures();
            });
          }
        },
        onCancel: () => {
          // Remove highlighted features
          originalFeatures.forEach((feature) => {
            if (feature.type === "Feature" && feature.id) api.setFeatureProperty(String(feature.id), HIGHLIGHT_PROPERTY_NAME, undefined);
          });
        },
      });
    });

    // Set highlight feature property for features to split
    originalFeatures.forEach((feature) => {
      if (feature.type === "Feature" && feature.id) api.setFeatureProperty(String(feature.id), HIGHLIGHT_PROPERTY_NAME, highlightColor);
    });

    return {
      originalFeatures,
    };
  },

  fireUpdate: function fireUpdate(this: DrawCustomModeThis, newFeature) {
    this.map.fire(events.UPDATE, {
      action: "split_feature",
      features: [newFeature.toGeoJSON()],
    });
  },
};

export default splitPolygonMode;
