import React, { useContext } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";
import { Place } from "@mui/icons-material";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const PointButton: React.FC<IButtonProps> = () => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("draw_point");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "draw_point" } as DrawModeChangeEvent);
  };

  return (
    <ToolbarToggleButton title="Draw a Point" value="draw_point" onClick={handleOnClick}>
      <Place />
    </ToolbarToggleButton>
  );
};

export default PointButton;
