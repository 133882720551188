import React, { useEffect, useMemo, useState } from "react";
import { Box, Modal, Zoom } from "@mui/material";

import mixpanel from "fond/mixpanel";
import { CTSReportType, CTSType, FullReport } from "fond/types";
import { selectLayersFromConfig } from "fond/utils/configurations";
import { generateCostMapConfiguration } from "fond/utils/costToServeTransformers";

import ReportCostMapTile from "./ReportCostMapTile";

interface IProps {
  report: FullReport;
  method: CTSType;
  onMethodChange(value: CTSType): void;
  activeBins?: string[];
  setActiveBins(indices: string[]): void;
}

const ReportCostMap: React.FC<IProps> = ({ method, onMethodChange, activeBins, report, setActiveBins }: IProps) => {
  const [layerVisibilities, setLayerVisibilities] = useState<Record<string, boolean>>({});
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const binRanges = report?.CostMap?.CostBinRanges?.BinRanges[method].equal_counts ?? [];
  const [costRange, setCostRange] = useState<number[]>([0, binRanges.length - 1]);

  // cost to serve style configurations for service locations are generated in the FE based on CostBinRanges;
  // this is in contrast to projects where styles are determined by the backend.
  const costMapConfiguration = useMemo(() => {
    if (!report?.CostMap?.CostBinRanges) return undefined;
    return generateCostMapConfiguration(report.CostMap.CostBinRanges);
  }, [report?.CostMap?.CostBinRanges]);

  const layerConfigs = useMemo(
    () =>
      (costMapConfiguration && selectLayersFromConfig(costMapConfiguration, ["SUBLAYER"]))?.filter((layerConfig) =>
        layerConfig.ID.includes(method)
      ) || [],
    [costMapConfiguration, method]
  );

  useEffect(() => {
    setLayerVisibilities(
      Object.fromEntries(
        layerConfigs.map((layerConfig) => {
          const include = layerConfig.ID.includes(method);
          return [layerConfig.ID, include];
        })
      )
    );
  }, [layerConfigs, method]);

  useEffect(() => {
    // Reset the cost range if the user changes the Cost to Serve method.
    setCostRange([0, binRanges.length - 1]);
  }, [method]);

  const passingsCount = useMemo(() => {
    if (report?.Passings && ["SharedCost", "AccCost", "IndivCost"].includes(method)) {
      const activeBinIndexes = activeBins?.map(Number) || [];
      return activeBins
        ? report.Passings?.[method as CTSReportType]
            ?.filter((passing) => activeBinIndexes.includes(passing.BinIndex))
            ?.reduce((sum, { PassingCount }) => sum + PassingCount, 0)
        : report.Passings?.[method as CTSReportType]?.reduce((sum, { PassingCount }) => sum + PassingCount, 0);
    } else {
      return null;
    }
  }, [activeBins, method, report]);

  const onSliderChange = (value: number[]) => {
    setCostRange(value);
    const binRangesKeys = Array.from(Array(binRanges.length).keys());
    const newCostBins = binRangesKeys.slice(value[0], value[1] + 1).map(String);
    setActiveBins(newCostBins);
    setLayerVisibilities((prev) => {
      const keys = Object.keys(prev);
      const activeBinIndices = newCostBins.map(Number);
      let result: Record<string, boolean> = {};
      keys.forEach((key, index) => {
        result[key] = activeBinIndices.includes(index);
      });
      return result;
    });

    mixpanel.track("Report", "Cost", "Changed cost map layers visibility");
  };

  const toggleFullscreen = () => {
    setIsFullscreenOpen((prev) => !prev);
  };

  const mapTileProps = {
    toggleFullscreen,
    method,
    onMethodChange,
    report,
    costMapConfiguration,
    layerVisibilities,
    binRanges,
    onSliderChange,
    passingsCount,
    costRange,
  };

  return (
    <>
      <ReportCostMapTile {...mapTileProps} />
      <Modal open={isFullscreenOpen}>
        <Zoom in={isFullscreenOpen}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box width="90vw" height="45vw">
              <ReportCostMapTile {...mapTileProps} fullscreen />
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};

export default ReportCostMap;
