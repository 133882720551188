export enum Page {
  SETTINGS = "settings",
  OVERVIEW = "overview",
  COST = "cost",
  REVENUE = "revenue",
}

export const DEFAULT_REPORT_LOADING_SKELETON = [
  { height: 105, sizes: { xs: 12, sm: 6, lg: 3 } },
  { height: 105, sizes: { xs: 12, sm: 6, lg: 3 } },
  { height: 105, sizes: { xs: 12, sm: 6, lg: 3 } },
  { height: 105, sizes: { xs: 12, sm: 6, lg: 3 } },
  { height: 416, sizes: { xs: 12, lg: 8 } },
  { height: 416, sizes: { xs: 12, lg: 4 } },
  { height: 313, sizes: { xs: 12, lg: 8 } },
  { height: 313, sizes: { xs: 12, lg: 4 } },
];
