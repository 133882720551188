import React, { useEffect, useState } from "react";
import { Box, Divider, FormHelperText } from "@mui/material";

import { DisplayValue, Documentation, FieldActions } from "fond/styleEditor";
import { FondStyleType, StyleField } from "fond/types";

import { config } from "../config/layout";
import { Field } from "../Field";
import { getFieldSpec } from "../helper";

import { Container, FieldForm, Fields, FieldValues, Tab, Tabs } from "./fieldSelection.styles";

interface IProps {
  type: FondStyleType;
  /**
   * The current selected field
   */
  selectedField?: StyleField;
  /**
   * Callback to handle the selection of the value for editing
   */
  onSelect(value: StyleField): void;
  /**
   * Optional field filter used to present the user with only a subset
   * of all editable fields.
   */
  fieldFilter?(field: StyleField): boolean;
}

const FieldSelection: React.FC<IProps> = ({ selectedField, type, fieldFilter = () => true, onSelect }: IProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const fieldSpec = selectedField && getFieldSpec(type, selectedField.mapboxKey);

  useEffect(() => {
    onSelect(config[type][tabIndex].fields.filter(fieldFilter)[0]);
  }, [type]);

  /**
   * Callback for handling tab change. When a tab changes we need to
   * selected value to the top field
   */
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTabIndex(newValue);
    onSelect(config[type][newValue].fields[0]);
  };

  return (
    <Container data-testid="field-selection">
      {config[type].length > 1 && (
        <Tabs value={tabIndex} onChange={handleChange}>
          {config[type].map(({ groupLabel }, index) => (
            <Tab key={`${type}.${groupLabel}`} value={index} label={groupLabel} data-testid={`${groupLabel}-style-tab`} />
          ))}
        </Tabs>
      )}
      <Fields>
        <FieldValues>
          {config[type][tabIndex]?.fields
            .filter(fieldFilter)
            .map((field) => (
              <DisplayValue
                key={field.fieldName}
                onClick={onSelect}
                type={type}
                field={field}
                isSelected={selectedField?.fieldName === field.fieldName}
              />
            ))}
        </FieldValues>
        <FieldForm display="flex" flexDirection="column" justifyContent="space-between">
          <Box className="customScrollbars" sx={{ flex: 1, overflow: "auto" }}>
            {selectedField && <Field field={selectedField} fieldSpec={fieldSpec} />}
          </Box>
          <div>
            <Divider sx={{ my: 1 }} />
            {selectedField && <FieldActions field={selectedField} fieldSpec={fieldSpec} />}
            <Divider sx={{ my: 1 }} />
            {fieldSpec && (
              <FormHelperText component="div">
                <Documentation fieldSpec={fieldSpec} />
              </FormHelperText>
            )}
          </div>
        </FieldForm>
      </Fields>
    </Container>
  );
};

export default FieldSelection;
