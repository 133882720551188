/**
 * Capitalize the first letter of a string.
 *
 *
 * @param str The string to process.
 * @returns The string with its first character capitalized.
 * @example
 *   capitalizeFirstLetter("abc") // "Abc"
 *   capitalizeFirstLetter("")    // ""
 *   capitalizeFirstLetter("a")   // "A""
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Replace all instances of special characters in a string.
 *
 * @param str The string to process.
 * @param replacement A string containing the text to replace for every successful matchin this string, or
 *                    A function that returns the replacement text.
 * @returns The string with special characters replaced.
 * @example
 *   replaceSpecialCharacters("a&c", "")  // "ac"
 *   replaceSpecialCharacters("A.B", "-") // "A-B"
 */
export const replaceAllSpecialCharacters = (value: string, replacement: string | ((substring: string, ...args: any[]) => string)): string => {
  if (typeof replacement === "string") return value.replaceAll(/[^0-9a-zA-Z]/g, replacement);

  return value.replaceAll(/[^0-9a-zA-Z]/g, replacement);
};
