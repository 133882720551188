import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import { useAppDispatch } from "fond/utils/hooks";

import { Store } from "../../types";

import { generate } from "./redux";

export function RegenerateBomBanner() {
  const dispatch = useAppDispatch();
  const versionId = useSelector((state: Store): string => state.project.versionId);

  const [snackOpen, setSnackOpen] = useState(true);

  if (!snackOpen) {
    return null;
  }

  return (
    <div data-testid="regenerate-bom-banner">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="You have made changes to your BOM rules. You can apply them when you're ready."
        action={
          <Button
            data-testid="update-bom-button"
            color="primary"
            size="small"
            onClick={() => {
              setSnackOpen(false);
              dispatch(generate(versionId));
            }}
          >
            Update BOM
          </Button>
        }
        open
        onClose={(event, reason) => {
          if (reason !== "clickaway") {
            setSnackOpen(false);
          }
        }}
      />
    </div>
  );
}
