import { Box, Button, styled, Typography } from "@mui/material";

import { MediumAvatar } from "fond/widgets";

export const UserDetailBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.biarri.primary.lightGrey}`,
  borderRadius: "4px",
  padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
}));

export const UserSubtext = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.biarri.primary.darkGrey,
}));

export const UserDetail = styled(Typography)``;
UserDetail.defaultProps = {
  variant: "h6",
  fontSize: "0.875rem",
};

export const UserDetailDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
}));

export const UserLastActivity = styled(UserDetailDescription)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1.5),
}));

export const Avatar = styled(MediumAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.biarri.primary.red,
}));

export const UserWarningButton = styled(Button)(({ theme }) => ({
  height: "2rem",
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
}));
