import { IRowNode } from "@ag-grid-community/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

/**
 * Comparator for ordering dates
 * https://www.ag-grid.com/javascript-data-grid/row-sorting/#custom-sorting
 */
const dateComparator = (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isInverted: boolean): number => {
  if (dayjs.utc(valueA).isSame(dayjs.utc(valueB))) return 0;
  return dayjs.utc(valueA).isAfter(dayjs.utc(valueB)) ? 1 : -1;
};

export default dateComparator;
