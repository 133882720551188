import React, { useMemo } from "react";
import { CheckCircle, FilePresent, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Checkbox, ImageListItem } from "@mui/material";

import { formatBytes } from "fond/utils";

import MimeTypeIcon from "../MimeTypeIcon";

import { AttachmentFile, Sort } from "./types";

import { ImageList, ImageListItemBar, ImagePlaceholder, ImageWrapper, SelectFileLabel } from "./GalleryView.styles";

interface IProps {
  files: AttachmentFile[];
  selected: AttachmentFile[];
  onSelectionChange(files: AttachmentFile[]): void;
  sort: Sort | null;
}

const GalleryView: React.FC<IProps> = ({ files, selected, onSelectionChange, sort }) => {
  const selectedFileIDs = useMemo(() => selected.map((file) => file.ID), [selected]);
  const sortedFiles = useMemo(() => {
    if (!sort) return files;
    let sorted = files.sort((prev, next) => {
      if (typeof prev[sort.colId] === "number") {
        return (prev[sort.colId] as number) - (next[sort.colId] as number);
      } else {
        return (prev[sort.colId] as string).localeCompare(next[sort.colId] as string);
      }
    });
    if (sort.sort === "desc") return sorted.toReversed();
    return sorted;
  }, [files, sort]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, file: AttachmentFile) => {
    if (event.target.checked) {
      onSelectionChange([...selected, file]);
    } else {
      const selectedClone = [...selected];
      const index = selected.findIndex((selectedFile) => selectedFile.ID === file.ID);
      if (index === -1) return;
      selectedClone.splice(index, 1);
      onSelectionChange(selectedClone);
    }
  };

  return (
    <ImageList variant="standard" cols={2} gap={12}>
      {sortedFiles.map((file) => {
        const isSelected = selectedFileIDs.includes(file.ID);
        const fileDescriptionId = `file-description-${file.ID}`;
        return (
          <ImageListItem key={file.ID}>
            <Box height="100%" maxWidth="100%" display="flex" alignItems="end" justifyContent="center">
              <ImageWrapper isSelected={isSelected}>
                {file.MimeType.split("/")[0] === "image" ? (
                  <img src={file.Urls?.View} alt="" loading="lazy" />
                ) : (
                  <ImagePlaceholder data-testid="image-placeholder">
                    <MimeTypeIcon
                      mimeType={file.MimeType}
                      color="inherit"
                      fontSize="large"
                      defaultIcon={<FilePresent color="action" fontSize="large" />}
                    />
                  </ImagePlaceholder>
                )}

                <SelectFileLabel
                  label={`Select ${file.Name}.${file.Extension}`}
                  control={
                    <Checkbox
                      id={file.ID}
                      checked={isSelected}
                      onChange={(event) => onCheckboxChange(event, file)}
                      size="small"
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle sx={{ backgroundColor: "#ffffff", borderRadius: "100%" }} />}
                    />
                  }
                />
              </ImageWrapper>
            </Box>
            <ImageListItemBar id={fileDescriptionId} title={`${file.Name}.${file.Extension}`} subtitle={formatBytes(file.Size)} position="below" />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default GalleryView;
