import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, useGetMultiProjectQuery, useGetProjectsQuery } from "fond/api";
import { useUpdateSubareaScores } from "fond/cityPlanner/useUpdateSubareaScores";
import MapProvider from "fond/map/MapProvider";
import { loadMultiProject } from "fond/project/redux";
import { CityPlannerList } from "fond/projects/CityPlanner";
import CityPlannerFolderMap from "fond/projects/CityPlanner/CityPlannerFolderMap";
import CityPlannerInfoGrid from "fond/projects/CityPlanner/CityPlannerInfoGrid";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import GridCard from "fond/views/Report/GridCard";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

interface RouteParams {
  folderId: string;
}

/**
 * A main page content displaying city planner projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityPlannerFolderPageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const { data: multiProject } = useGetMultiProjectQuery(folder?.MultiProject?.ID ?? skipToken);
  const [loaded, setLoaded] = useState(false);

  // Keep polling until all projects are in a terminal state.
  const allFinished = multiProject?.Areas?.every((area) => ["COMPLETE", "ERROR"].includes(area?.ImportStatus?.State ?? "")) ?? false;
  useGetProjectsQuery(undefined, { pollingInterval: allFinished ? 0 : 30_000 });

  useUpdateSubareaScores(multiProject);

  useEffect(() => {
    if (folder?.MultiProject?.ID) {
      dispatch(loadMultiProject({ uuid: folder?.MultiProject?.ID })).then(async () => {
        setLoaded(true);
      });
    }
  }, [dispatch, folder?.MultiProject?.ID, folderId]);

  if (!multiProject || !loaded)
    return (
      <SkeletonLoadingGrid
        items={[
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 12 } },
        ]}
      />
    );

  return (
    <>
      <Typography>{multiProject?.Description}</Typography>
      <Grid container spacing={2.5} sx={{ mt: 2 }}>
        {multiProject && <CityPlannerInfoGrid id={multiProject.ID} project={multiProject} />}
        <GridCard breakpoints={{ lg: 6, sm: 12, xs: 12 }} title="Project map">
          <Box sx={{ width: "100%", height: 246, position: "relative", borderRadius: 1, overflow: "hidden" }}>
            <MapProvider mapStyle="monochrome">{folder?.MultiProject?.ID && <CityPlannerFolderMap cityId={folder?.MultiProject?.ID} />}</MapProvider>
          </Box>
        </GridCard>
      </Grid>
      <CityPlannerList />
    </>
  );
};

export default CityPlannerFolderPageContent;
