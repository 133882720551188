import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectUserId, useDeleteAllocationMutation, useGetAccountUsersQuery, useTransferPermissionsMutation } from "fond/api";
import { Autocomplete } from "fond/form/fields";
import { UserAccountAllocationResponse } from "fond/types";
import { required } from "fond/utils/validation";
import { Modal } from "fond/widgets";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
  onClose(): void;
  onCloseAll(): void;
}
interface IFormData {
  userId: string;
}
const RemoveUserModal: React.FC<IProps> = ({ userAccountAllocation, onClose, onCloseAll }) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUserId = useSelector(selectUserId);
  const selectAccount = userAccountAllocation.Account;
  const [transferPermissions] = useTransferPermissionsMutation();
  const [deleteAllocation] = useDeleteAllocationMutation();
  const { data: users } = useGetAccountUsersQuery(selectAccount.ID);

  const userOptions = useMemo(() => {
    // Cannot transfer the selected user permission to itself.
    return users?.filter((user) => user.User.ID !== userAccountAllocation.User.ID);
  }, [users, userAccountAllocation]);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  /**
   * Callback function to dispatch the remove action.
   *
   * We first transfer all permissions then remove the user from the account.
   */
  const onFormSubmit = async (values: IFormData) => {
    setSubmitting(true);

    try {
      await transferPermissions({
        accountId: selectAccount.ID,
        sourceUserId: userAccountAllocation.User.ID,
        targetUserId: values.userId,
      });
      await deleteAllocation(userAccountAllocation.ID);
      enqueueSnackbar("User removed", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to remove the user. Please try again...", { variant: "error" });
    } finally {
      setSubmitting(false);
      onCloseAll();
    }
  };

  return (
    <Modal
      open
      header="Transfer content and remove user"
      content={
        <>
          <Typography mb={2}>Removing this user from the account will revoke their access to projects and associated contents.</Typography>
          <Form<IFormData>
            onSubmit={onFormSubmit}
            initialValues={{ userId: currentUserId }}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="remove-user-form">
                  <Box>
                    <Autocomplete
                      name="userId"
                      required
                      disablePortal
                      label="Transfer all projects managed by user to"
                      fullWidth
                      options={userOptions || []}
                      getOptionLabel={(option) => option?.User?.Email}
                      getOptionValue={(option) => option?.User?.ID}
                      validate={required}
                    />
                  </Box>
                </form>
              );
            }}
          />
        </>
      }
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton color="error" type="submit" variant="contained" loading={isSubmitting} form="remove-user-form">
            Confirm
          </LoadingButton>
        </>
      }
    />
  );
};

export default RemoveUserModal;
