import { styled, Table } from "@mui/material";

import { TableProps } from "./Table";

export const BaseTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant: TableProps["variant"] }>(({ variant }) => {
  if (variant !== "ag-grid-compact") return {};

  return {
    "& .MuiTableCell-root": {
      fontSize: 11,
      padding: "6px 12px 6px 12px",
      height: "unset",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#f8f8f8",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#fcfcfc",
    },
    "& tr:last-child td": {
      borderBottom: "none",
    },
  };
});
