import SnackbarContent from "./SnackbarContent";

const snackbarComponentVariantMap = {
  default: SnackbarContent,
  success: SnackbarContent,
  error: SnackbarContent,
  warning: SnackbarContent,
  info: SnackbarContent,
};

export { SnackbarContent, snackbarComponentVariantMap };
