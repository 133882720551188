import React, { useContext, useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";

import { fondServiceURL } from "fond/api";
import { Configuration, SystemOfMeasurement } from "fond/types";
import { isValidBoundingBox } from "fond/utils";
import { selectLayersFromConfig, selectStylesFromConfig } from "fond/utils/configurations";

import BaseMap from "../BaseMap";
import BaseMapButtons from "../BaseMapButtons";
import { MapContext } from "../MapProvider";
import VectorTileLayer from "../VectorTileLayer";

import { Container } from "./ReportMap.styles";

interface ReportMapProps {
  layerVisibilities: Record<string, boolean>;
  reportID: string;
  boundingBox: [[number, number], [number, number]];
  config: Configuration;
  systemOfMeasurement: SystemOfMeasurement;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

/**
 * The service location cost map appearing in a financial analysis report.
 */
const ReportMap: React.FC<ReportMapProps> = ({
  layerVisibilities,
  reportID,
  config,
  boundingBox,
  systemOfMeasurement,
  onZoomIn,
  onZoomOut,
}: ReportMapProps) => {
  const { map } = useContext(MapContext);
  const contentRef = useRef<HTMLElement | null>(null);
  const layerConfigs = useMemo(() => selectLayersFromConfig(config), [config]);
  const styles = useMemo(() => selectStylesFromConfig(config), [config]);

  useEffect(() => {
    map?.resize();
  }, [map]);

  const isVisible = (id: string, layerView: { [key: string]: boolean }) => {
    return layerView[id];
  };

  const onClickZoomToDesignHandler = () => {
    if (isValidBoundingBox(boundingBox)) {
      // The report bounding box already has a buffer applied, so no need to apply extra
      // padding here. This means that clicking the "zoom to design" button takes you to
      // the exact same view as when the map first loads.
      map?.fitBounds(boundingBox);
    }
  };

  const onClickZoomInHandler = () => {
    map?.zoomIn();
    onZoomIn?.();
  };

  const onClickZoomOutHandler = () => {
    map?.zoomOut();
    onZoomOut?.();
  };

  return (
    <Container ref={contentRef} data-testid="report-map">
      <BaseMap bbox={boundingBox} editMode="none" systemOfMeasurement={systemOfMeasurement}>
        <Box className="right-sidebar-section">
          <div />
          <BaseMapButtons
            editMode="none"
            onClickZoomToDesign={onClickZoomToDesignHandler}
            onClickZoomIn={onClickZoomInHandler}
            onClickZoomOut={onClickZoomOutHandler}
            currentLocationButton={false}
          />
        </Box>
        <VectorTileLayer
          sourceKey={`report-service-location-${reportID}`}
          tileURL={`${fondServiceURL}/v2/reports/${reportID}/service-location-tiles/{z}/{x}/{y}`}
          layerConfigs={layerConfigs}
          styles={styles}
          layerVisibilities={layerVisibilities}
          isVisible={isVisible}
        />
      </BaseMap>
    </Container>
  );
};

export default ReportMap;
