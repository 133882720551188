export const MODE_NAME = "cut_polygon";

// This mode uses the `mapbox-gl-draw-passing-mode` mode to draw the splitting lineString.
export const PASSING_MODE_NAME = `${MODE_NAME}_passing_draw_polygon`;

// When a (multi-)polygon feature is selected to be splitted, it gets highlighted.
// This is the name of the property indicating the highlight.
export const HIGHLIGHT_PROPERTY_NAME = `${MODE_NAME}_highlight`;

export const defaultOptions = {
  highlightColor: "#FFF",
};
