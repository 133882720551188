import React from "react";
import { useField, useFormState } from "react-final-form";
import { Undo, ZoomIn } from "@mui/icons-material";
import { Divider } from "@mui/material";

import { StyleField } from "fond/types";

import { allowInterpolateExpression, makeCameraExpression } from "../helper";

import { Button } from "./fieldActions.styles";

interface IProps {
  /**
   * The current field
   */
  field: StyleField;
  /**
   * Mapbox fieldspec for the field
   */
  fieldSpec: any;
}

const FieldActions: React.FC<IProps> = ({ field, fieldSpec }: IProps) => {
  const {
    input: { value, onChange },
  } = useField(field.fieldName);
  const {
    input: { value: interpolateValue, onChange: interpolateOnChange },
  } = useField(`${field.fieldName}Interpolate`);
  const { values } = useFormState();
  const hasValue = values[field.fieldName] !== null || values[`${field.fieldName}Interpolate`] !== undefined;

  const handleOnClear = () => {
    onChange(null);
    if (interpolateValue) interpolateOnChange?.(null);
  };

  /**
   * Convert the current value to an interpolate value
   */
  const handleOnInterpolateValue = () => {
    interpolateOnChange(makeCameraExpression(value, fieldSpec));
  };

  return (
    <>
      <Button startIcon={<Undo />} onClick={handleOnClear} disabled={!hasValue} data-testid="style-clear">
        Clear value
      </Button>
      {!interpolateValue && (
        <>
          <Divider sx={{ my: 1 }} />
          <Button
            startIcon={<ZoomIn />}
            onClick={handleOnInterpolateValue}
            disabled={!allowInterpolateExpression(field.mapboxKey, field.fieldName)}
            data-testid="style-zoom-range"
          >
            Style across zoom range
          </Button>
        </>
      )}
    </>
  );
};

export default FieldActions;
