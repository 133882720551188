import { MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { booleanIntersects, difference, featureCollection } from "fond/turf";
import { isAnyPolygon } from "fond/types/geojson";

// Default snapping options used for drawing within city planner.
export const snappingOptions: Pick<MapboxDrawOptions, "snap" | "snapOptions"> = {
  snap: true,
  snapOptions: {
    snapPx: 15,
    snapToMidPoints: true,
    snapVertexPriorityDistance: 0.0025,
  },
};

/**
 * Validates that a feature (B) is either contained within, or intersects with with another feature (A).
 * @param featureA The feature.
 * @param featureB The feature being tested to see if it with within FeatureA
 */
export const isWithin = (featureA: Feature<Polygon | MultiPolygon>, featureB: Feature<Polygon | MultiPolygon>): boolean =>
  !difference(featureB, featureA) || booleanIntersects(featureA, featureB);

/**
 * Updates features within a feature collection.
 * Note: that the order of the features within the collection is not maintained.
 * @param collection the FeatureCollection that will be updated
 * @param features the features to be upserted into the collection
 */
export const updateFeatureCollection = (collection: FeatureCollection, features: Feature[]): FeatureCollection => {
  return featureCollection([...collection.features.filter((feature) => !features.some((feat) => feat.id === feature.id)), ...features]);
};

/**
 * Prevents the polygon overlapping any existing features.
 */
export const intersect = (polygon: Feature<Polygon | MultiPolygon>, all: Feature[]): Feature<Polygon | MultiPolygon> => {
  const newFeatures: Feature[] = [];
  let diffPoly: Feature<Polygon | MultiPolygon> = { ...polygon };

  all
    .filter(isAnyPolygon)
    .filter((feature) => feature.id !== polygon.id)
    .forEach((feature) => {
      if (isAnyPolygon(feature)) {
        const diff = difference(diffPoly, feature);
        if (diff) diffPoly = diff;
      }
      newFeatures.push(feature);
    });

  return { ...diffPoly, id: polygon.id };
};

/**
 * Clips a feature so that there is no overlap with any other features
 *
 * @param feature The feature to be clipped
 * @param all All features to be clipped against
 * @returns A clipped feature that has no overlap with any existing features
 */
export const clip = (feature: Feature<Polygon | MultiPolygon>, all: Feature[] = []): Feature<Polygon | MultiPolygon> => {
  return intersect(feature, all);
};

/**
 * Validates that polygon features have a turf "difference" when compared to the existing features.
 * In other words, the feature must not be completely contained by another feature.
 *
 * @param features A collection of features to validate
 * @param all A collection of existing features
 * @returns boolean indicating if all the features have a valid difference
 */
export const validateFeatures = (features: Feature[], all: Feature[] = []): boolean => {
  return features.every((newFeature) => {
    if (!newFeature.geometry) return false;

    if (isAnyPolygon(newFeature)) {
      return all
        .filter(({ id }) => id !== newFeature.id)
        .filter(isAnyPolygon)
        .every((feature) => difference(newFeature, feature));
    }

    return true;
  });
};
