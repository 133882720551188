import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";

export const MapOverlayCloseButtonContainer = styled("div")(() => ({
  position: "absolute",
  right: "0",
  top: "0",
  height: "24",
  textAlign: "right",
  zIndex: "100",
}));

export const StyledCloseIcon = styled(CloseIcon)(() => ({
  height: "24px",
  fontSize: "2em",
  cursor: "pointer",
  color: "#333",
  margin: "8px",
  "&:hover": {
    color: "black",
  },
}));
