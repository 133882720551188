import React from "react";
import { DoNotDisturbOnTotalSilenceSharp as DoNotDisturbIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Accordion, AccordionDetails, Badge, Box, Typography } from "@mui/material";

import { ExportAccordionPanel, ExportsByStatus } from "fond/types";
import { NonIdealState } from "fond/widgets";

import CompleteExportTable from "../ExportTable/CompleteExportTable";
import InProgressExportTable from "../ExportTable/InProgressExportTable";

import { StyledAccordion, StyledAccordionSummary, StyledBackgroundAreaLine, StyledNoIdealBox } from "./ExportAccordion.styles";

interface ExportAccordionProps {
  /**
   * Exports grouped by status
   */
  exportsByStatus: ExportsByStatus;
  /**
   * A flag determines which panel is expanded
   */
  expanded: string | false;
  /**
   * A callback function to handle expand accordion events
   */
  onExpand(panel: string): any;
}

const ExportAccordion: React.FC<ExportAccordionProps> = ({ exportsByStatus, expanded, onExpand }: ExportAccordionProps) => {
  const numComplete = exportsByStatus.Complete.length;
  const numInProgress = exportsByStatus.InProgress.length + exportsByStatus.Error.length;

  return (
    <Box my={2} data-testid="export-accordion">
      <Box pb={2}>
        <Accordion
          data-testid="project-exports-accordion"
          disableGutters
          expanded={expanded === ExportAccordionPanel.Complete}
          onChange={onExpand(ExportAccordionPanel.Complete)}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="project-exports-content" id="project-exports-header">
            <Box display="flex" alignItems="center">
              <Typography mr={4}>Project Exports</Typography>
              <Badge badgeContent={numComplete} color="primary" />
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails>
            {exportsByStatus.Complete.length ? (
              <CompleteExportTable exports={exportsByStatus.Complete} />
            ) : (
              <Box>
                <StyledBackgroundAreaLine />
                <StyledNoIdealBox>
                  <NonIdealState icon={<DoNotDisturbIcon />} size="small" description="No Exports Yet" />
                </StyledNoIdealBox>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {(Boolean(exportsByStatus.InProgress.length) || Boolean(exportsByStatus.Error.length)) && (
        <StyledAccordion
          data-testid="inprogress-exports-accordion"
          disableGutters
          expanded={expanded === ExportAccordionPanel.InProgress}
          onChange={onExpand(ExportAccordionPanel.InProgress)}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="exports-in-progress-content" id="exports-in-progress-header">
            <Box display="flex" alignItems="center">
              <Typography mr={4}>In Progress</Typography>
              <Badge badgeContent={numInProgress} color="primary" />
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails>
            <InProgressExportTable exportsByStatus={exportsByStatus} />
          </AccordionDetails>
        </StyledAccordion>
      )}
    </Box>
  );
};

export default ExportAccordion;
