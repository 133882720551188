import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { selectUserId, useUpdateInvitationMutation } from "fond/api";
import { ACCOUNT_CONTEXT } from "fond/constants";
import { NoContentIcon } from "fond/svg_icons";
import { AccountInvitation } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";

import { MessagePanel, NoContentMessage } from "./BaseProjectList.styles";

type AcceptInvitationPanelProps = {
  invitations: AccountInvitation[];
};
export const AcceptInvitationPanel = ({ invitations }: AcceptInvitationPanelProps): JSX.Element => {
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const [updateInvitation] = useUpdateInvitationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const invitation = invitations[0];
  const accountName = invitation.Account?.Name;

  const acceptInvitationClick = async () => {
    try {
      const data = await updateInvitation({ invitation: { ID: invitation.ID, InvitationStatus: "accepted" }, userType: "existing" }).unwrap();
      if (userId && data.User.ID === userId) {
        enqueueSnackbar("Switching to account...");
        const currentAccountContext = getItem(ACCOUNT_CONTEXT);
        setItem(ACCOUNT_CONTEXT, currentAccountContext ? { ...currentAccountContext, [userId]: data.AccountID } : { [userId]: data.AccountID });
      } else {
        Sentry.captureMessage("Invited user doesn't match current logged in user");
        navigate("/invitations/invalid");
      }
      navigate(0);
    } catch (error) {
      Sentry.captureException(error);
      navigate("/invitations/invalid");
    }
  };

  return (
    <MessagePanel as={Paper} data-testid="accept-invitation-panel">
      <NoContentMessage data-testid="no-content-message">
        <NoContentIcon />
        <Box maxWidth="70ch">
          <Typography variant="h3">No content?</Typography>
          <Typography variant="body1" fontWeight={700}>
            It looks like you've been invited to join {accountName ? `the ${accountName}` : "an"} account.
          </Typography>
          <Typography maxWidth="60ch">If you join this account you will be able to access a shared workspace to create and share designs.</Typography>
        </Box>
      </NoContentMessage>
      <Box display="flex" flex="1" pr={5} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ maxWidth: 230, whiteSpace: "nowrap" }}
          onClick={() => acceptInvitationClick()}
          data-testid="accept-invite-button"
        >
          Accept invitation
        </Button>
      </Box>
    </MessagePanel>
  );
};
