import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";

import { PageCentred } from "fond/widgets/PageCentred";

import SignInAdornment from "./SignInAdornment";

interface IProps {
  email: string;
}

/**
 * A resend email component that uses @aws-amplify/auth
 * to resend an email to a newly signed up user
 */
const ResendEmail: React.FC<IProps> = ({ email }) => {
  const [linkResent, setLinkResent] = useState(false);

  const handleResendVerificationEmail = () => {
    Auth.resendSignUp(email.toLowerCase());
    setLinkResent(true);
  };

  return (
    <PageCentred
      title="Check your email"
      description={
        <Typography data-testid="success-message">
          Thanks! You're almost done. Click the link we have emailed you to finish creating your account.
        </Typography>
      }
      rightAdornment={<SignInAdornment />}
    >
      {linkResent ? (
        <Alert severity="success" sx={{ mt: 4 }}>
          <AlertTitle>Verification email sent!</AlertTitle>
          Click on the link we've emailed you to verify your email.
        </Alert>
      ) : (
        <>
          <Button fullWidth variant="contained" color="primary" onClick={handleResendVerificationEmail}>
            Resend verification
          </Button>
          <Box sx={{ mt: 4, textAlign: "right" }}>
            <Button color="primary" component={Link} to="/signin">
              Back to Log In
            </Button>
          </Box>
        </>
      )}
    </PageCentred>
  );
};

export default ResendEmail;
