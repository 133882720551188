import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Alert, AlertTitle } from "../panels/InputDataPanel.styles";

interface IProps {
  unknownAddressTypes: string[];
}

const UnknownAddressTypesAlert: React.FC<IProps> = ({ unknownAddressTypes }: IProps) => {
  if (unknownAddressTypes.length === 0) {
    return null;
  }

  const sortedAddressTypes = [...unknownAddressTypes].sort();
  const addressTypePlural = sortedAddressTypes.length > 1 ? "s" : "";
  const addressTypeMessage = sortedAddressTypes.length > 1 ? "There are Address Types that are" : "There is an Address Type that is";
  const resolutionMessage = sortedAddressTypes.length > 1 ? "default values for these types" : "default value for this type";

  const heading = `Address type${addressTypePlural} not found`;

  return (
    <Alert severity="warning" sx={{ mb: 1 }} data-testid="unknown-address-type-alert">
      <AlertTitle data-testid="alert-header">{heading}</AlertTitle>
      <Typography variant="body3">{addressTypeMessage} not specified in the demand model:</Typography>
      <Box my={2} sx={{ maxHeight: 200, overflow: "auto" }} className="customScrollbars" data-testid="address-item-list">
        {sortedAddressTypes.map((item) => (
          <Typography key={item} component="li" variant="body3">
            {item || <i>{"<Empty>"}</i>}
          </Typography>
        ))}
      </Box>
      <Typography variant="body3">FOND will use the {resolutionMessage}.</Typography>
    </Alert>
  );
};

export default UnknownAddressTypesAlert;
