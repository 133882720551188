import React, { useEffect } from "react";

import { useContents } from "fond/hooks/useContents";
import { BaseProjectList } from "fond/projects/BaseProjectList";
import { updateSharedAccount } from "fond/projects/redux";
import { useAppDispatch, useQueryParams } from "fond/utils/hooks";

/**
 * A filterable list of projects displayed to the user within a DataGrid including Column Sorting and Pagination
 */
const SharedContentList: React.FC = () => {
  const { sharedItems } = useContents();
  const dispatch = useAppDispatch();
  const selectedAccount = useQueryParams("account");

  useEffect(() => {
    // if entering the page manually, we want to update store
    if (selectedAccount) {
      dispatch(updateSharedAccount(selectedAccount));
    }
  }, [dispatch, selectedAccount]);

  const filteredItems = selectedAccount ? sharedItems.filter((item) => item.Account.ID === selectedAccount) : sharedItems;

  return <BaseProjectList items={filteredItems} />;
};

export default SharedContentList;
