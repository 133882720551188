import stringSimilarity from "string-similarity";

/**
 * Returns matching search results from a collection, arranged in descending order
 * @param collection
 * @param searchStr
 * @param key
 */
export const searchCollectionByKey = <T>(collection: Array<T>, searchStr: string, key: keyof T): Array<T & { score: number }> =>
  collection
    .map((item) => {
      const value = item[key];
      return typeof value === "string"
        ? {
            ...item,
            score: stringSimilarity.compareTwoStrings(searchStr.toLowerCase(), value.toLowerCase()),
          }
        : { ...item, score: 0 };
    })
    .filter((item) => item.score > 0.2)
    .sort((a, b) => b.score - a.score);
