import React from "react";
import { Segment } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { NonIdealState } from "fond/widgets";

export const Empty: React.FC = () => {
  const theme = useTheme();

  return (
    <NonIdealState
      title="Nothing to show..."
      description="Select an attribute & generate some preview classifications."
      icon={<Segment sx={{ fill: theme.palette.biarri.primary.lightGrey }} />}
      size="small"
    />
  );
};
