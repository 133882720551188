export enum ToolbarAction {
  AUTO_CARVE = "auto_carve",
  SIMPLE_SELECT = "simple_select",
  DRAW_POLYGON = "draw_polygon",
  DRAW_POINT = "draw_point",
  // DRAW_LINE_STRING = "draw_line_string",
  DELETE = "delete",
  STATIC = "static",
  SNAP = "snap",
  COMBINE = "combine",
  CUT = "cut",
  UNCOMBINE = "uncombine",
  UNION = "union",
  SPLIT = "split",
  UNDO = "undo",
  REDO = "redo",
}

type Action = "mode" | "action";
export type ToolbarConfig = Array<{ type: Action; actions: ToolbarAction[] }>;

/**
 * Default drawing tools when drawing any feature type
 */
export const defaultToolbarConfig: ToolbarConfig = [
  {
    type: "mode",
    actions: [ToolbarAction.SIMPLE_SELECT, ToolbarAction.DRAW_POLYGON, ToolbarAction.DRAW_POINT],
  },
  {
    type: "action",
    actions: [ToolbarAction.SNAP, ToolbarAction.CUT, ToolbarAction.SPLIT, ToolbarAction.UNION, ToolbarAction.COMBINE, ToolbarAction.UNCOMBINE],
  },
  { type: "action", actions: [ToolbarAction.UNDO, ToolbarAction.REDO, ToolbarAction.DELETE] },
];

/**
 * Default drawing tools when drawing polygons
 */
export const polygonDrawingConfig: ToolbarConfig = [
  { type: "mode", actions: [ToolbarAction.SIMPLE_SELECT, ToolbarAction.DRAW_POLYGON] },
  {
    type: "action",
    actions: [ToolbarAction.SNAP, ToolbarAction.CUT, ToolbarAction.SPLIT, ToolbarAction.UNION, ToolbarAction.COMBINE, ToolbarAction.UNCOMBINE],
  },
  { type: "action", actions: [ToolbarAction.UNDO, ToolbarAction.REDO, ToolbarAction.DELETE] },
];

/**
 * Default drawing tools when drawing points
 */
export const pointDrawingConfig: ToolbarConfig = [
  {
    type: "mode",
    actions: [ToolbarAction.SIMPLE_SELECT, ToolbarAction.DRAW_POINT],
  },
  { type: "action", actions: [ToolbarAction.UNDO, ToolbarAction.REDO, ToolbarAction.DELETE] },
];
