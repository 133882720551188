import { Box, styled } from "@mui/material";

export const Container = styled(Box)({
  marginLeft: 4,
  marginRight: 4,
  "& .rct-tree-root > div": {
    // Applying a padding at the bottom fixes re-ordering items to last position
    paddingBottom: 24,
  },
  "& .rct-tree-item-button-GROUP": {
    backgroundColor: "#F5F5F5",
    "&.rct-tree-item-button-selected": {
      backgroundColor: "var(--rct-color-focustree-item-selected-bg)",
    },
  },
});

export const Row = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  alignItems: "center",
  display: "flex",
  flexGrow: 1,
  color: isVisible ? "inherit" : theme.palette.action.disabled,
  width: "100%",

  "&.rct-tree-item-title-GROUP": {
    color: isVisible ? theme.palette.biarri.primary.red : theme.palette.action.disabled,
    fontWeight: 700,
    fontSize: 13,
  },
}));

export const Label = styled(Box, {
  shouldForwardProp: (prop) => prop !== "italic",
})<{ italic?: boolean }>(({ theme, italic }) => ({
  fontFamily: theme.typography.fontFamily,
  textOverflow: "ellipsis",
  fontStyle: italic ? "italic" : "none",
  whiteSpace: "nowrap",
  overflow: "hidden",
  flexGrow: 1,
  width: "100%",
}));
