import React, { useEffect, useState } from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import * as Sentry from "@sentry/react";
import arrayMutators from "final-form-arrays";
import { useDebouncedCallback } from "use-debounce";

import { selectVersionMlcId, useUpdateStyleConfigMutation } from "fond/api";
import { FieldSelection, StyleHeader } from "fond/styleEditor";
import { config } from "fond/styleEditor/config/layout";
import { FondStyleType, StyleField, StyleFieldName } from "fond/types";
import { LayerStyle } from "fond/types/ProjectLayerConfig";
import { setValue } from "fond/utils/formMutators";
import { useAppSelector } from "fond/utils/hooks";

import { Value } from "../TextPartsField/TextPartsField";

import { Form } from "./styleEditor.styles";

type FormValues = {
  [key in StyleFieldName]: any;
};
export interface IFormData extends FormValues {
  Type: FondStyleType;
  TextFields: Value[];
}

interface IProps {
  style: LayerStyle;
}

const StyleEditor: React.FC<IProps> = ({ style }: IProps) => {
  const {
    ID,
    MapboxStyle: { type },
  } = style;
  const fondType: FondStyleType = style.RawStyles.Type;
  const defaultField = config[fondType][0].fields[0];
  const [selectedField, setSelectedField] = useState<StyleField | undefined>(defaultField);
  const mapLayerConfigId = useAppSelector(selectVersionMlcId);
  const [updateStyleConfig] = useUpdateStyleConfigMutation();

  useEffect(() => {
    return () => {
      setSelectedField(undefined);
    };
  }, []);

  useEffect(() => {
    setSelectedField(defaultField);
  }, [type]);

  /**
   * Submits the normalised form data to the backend to generate
   * valid mapbox styles.
   */
  const onChange = useDebouncedCallback((values: IFormData) => {
    if (style) {
      const updatedStyle = { ...style, MapboxStyle: undefined, RawStyles: values };
      updateStyleConfig({ mapLayerConfigId, style: updatedStyle })
        .unwrap()
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }, 500);

  const onSelect = (field: StyleField) => {
    setSelectedField(field);
  };

  return (
    <FinalForm<IFormData>
      key={ID}
      initialValues={style.RawStyles}
      onSubmit={onChange}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <StyleHeader style={style} />
            <FieldSelection onSelect={onSelect} selectedField={selectedField} type={fondType} />
            {/* We use FormSpy to submit the form values on every update */}
            <FormSpy
              subscription={{ values: true, valid: true, pristine: true }}
              onChange={({ valid, pristine }) => {
                if (valid && !pristine) handleSubmit();
              }}
            />
          </Form>
        );
      }}
      mutators={{ ...arrayMutators, setValue }}
    />
  );
};

export default StyleEditor;
