import { Box, styled } from "@mui/material";

const PANEL_WIDTH = 240;
const PANEL_WIDTH_EXPANDED = PANEL_WIDTH + 360;

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  height: "100%",
  position: "relative",
}));

export const Panel = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const MapContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  position: "relative",
  marginLeft: expanded ? PANEL_WIDTH_EXPANDED : PANEL_WIDTH,
  width: "100%",
  height: "100%",
}));
