import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, IconButtonProps, MenuItem, styled, Typography } from "@mui/material";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "inherit",
  boxShadow: "none",
  paddingTop: theme.spacing(1),

  "&.MuiAccordion-root:before": {
    display: "none",
  },
}));

interface StyledAccordionSummaryProps extends React.ComponentProps<typeof AccordionSummary> {
  selected?: boolean;
}

export const StyledAccordionSummary = styled(({ selected, ...rest }: StyledAccordionSummaryProps) => <AccordionSummary {...rest} />)(
  ({ theme, selected }) => ({
    padding: 0,
    marginBottom: theme.spacing(2),
    backgroundColor: selected ? theme.palette.common.white : "inherit",
    boxShadow: selected ? "0px 1px 2px 1px rgba(0, 0, 0, 0.05)" : "none",

    "& .MuiListItemIcon-root": {
      color: selected && theme.palette.secondary.main,
    },

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },

    "& .MuiAccordionSummary-content": {
      margin: 0,
    },

    "&.MuiAccordionSummary-gutters, &.Mui-expanded": {
      height: theme.spacing(6),
      minHeight: 0,
    },

    "&.Mui-expanded .MuiIconButton-root": {
      transform: "rotate(-180deg)",
    },
  })
);

export const CollapsibleMenuItem = styled(MenuItem)(() => ({
  boxShadow: "none",
  width: "100%",
  "&.Mui-selected": {
    boxShadow: "none",
  },

  "&.Mui-selected:hover": {
    backgroundColor: "none",
  },
}));

export const AccordionSummaryButton = styled(Button)(() => ({
  width: "100%",
  color: "inherit",

  "&:hover": {
    backgroundColor: "none",
  },
}));

export const NavigationItemContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const NavigationItemDropdownIcon = styled(({ onClick, ...rest }: IconButtonProps) => (
  <IconButton
    onClick={(e) => {
      e.stopPropagation(); // stop propagating event to styled accordion
      onClick?.(e);
    }}
    {...rest}
  >
    <ArrowDropDownIcon />
  </IconButton>
))(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(1),
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

export const EllipsisTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));
