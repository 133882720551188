import React from "react";
import { BoxProps } from "@mui/material";

import { Container } from "./palette.styles";

interface IProps extends BoxProps {
  colors: string[];
}

const Palette: React.FC<IProps> = ({ colors, ...boxProps }: IProps) => {
  return <Container colors={colors} {...boxProps} />;
};

export default Palette;
