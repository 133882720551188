import { styled, Typography } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "0.85rem",
  fontWeight: 500,
  paddingBottom: theme.spacing(1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  fontWeight: 500,
  paddingTop: theme.spacing(1),
}));

export const Paragraphy = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  fontSize: "0.75rem",
}));

export const Text = styled(Typography)({
  fontSize: "0.75rem",
});
