import { FC } from "react";
import { Location, useLocation } from "react-router";
import { Link, Typography } from "@mui/material";

import { HELP_CENTER_URL } from "fond/constants";
import ErrorPage from "fond/errorPage/ErrorPage";
import { PageErrorIcon } from "fond/svg_icons";
import { ResourceType } from "fond/types";

type LocationState = {
  resourceType?: ResourceType;
  errorMessage?: string;
};

/**
 * The component to show in the case that the URL can't be matched to a component
 * by the router in index.js, i.e. a 404.
 */
const GenericErrorPage: FC = () => {
  const location = useLocation() as Location<LocationState>;

  let resourceType = "resource";
  if (location?.state?.resourceType && ResourceType?.[location.state.resourceType]) {
    resourceType = ResourceType[location.state.resourceType];
  }

  let message = `We're sorry, something went wrong and we couldn't show you the ${resourceType} you wanted to view.`;
  if (location?.state?.errorMessage) {
    message = location.state.errorMessage;
  }

  return (
    <ErrorPage
      title="Something went wrong"
      icon={<PageErrorIcon />}
      heading="Something went wrong"
      message={
        <Typography data-testid="generic-error-page-message" component="div">
          <p>{message}</p>
          <p>
            If you need help, visit our&nbsp;
            <Link color="primary" target="_blank" rel="noopener noreferrer" href={HELP_CENTER_URL}>
              <b>Help Center</b>
            </Link>
            .
          </p>
        </Typography>
      }
    />
  );
};
export default GenericErrorPage;
