export type ConditionalMessage = { condition: boolean; message: string };

/**
 * Returns the first message that has a true condition or the fallback message if provided.
 * If no condition is true and no fallback is provided, an empty string is returned.
 *
 * @param messages - an array of objects with a condition and a message
 * @param fallback - a message to return if no condition is true
 * @example
 *   conditionalMessages([
 *     { condition: canDoThing, message: "Please do XYZ" },
 *     { condition: !canDoThing, message: "You cannot do XYZ" },
 *   ]);
 *
 *   conditionalMessages([
 *     { condition: situationOne, message: "Hello world" },
 *     { condition: situationTwo, message: "Goodbye world" },
 *     { condition: situationThree, message: "Goodnight world" },
 *   ], "No message found");
 * @returns
 */
export function conditionalMessage(messages: ConditionalMessage[], fallback?: string): string {
  // iterate the config object and return the first key that is true
  for (const { condition, message } of messages) {
    if (condition) {
      return message;
    }
  }
  return fallback ?? "";
}
