import { Box, Paper, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export const ChartSection = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(4.5)} ${theme.spacing(11)}`,
}));

export const TotalPremsBanner = styled(Paper)(({ theme }) => ({
  backgroundColor: grey[100],
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  margin: `${theme.spacing(3)} 0 ${theme.spacing(6)}`,
  boxShadow: `0px 1px 1px 1px rgba(0, 0, 0, 0.05)`,
  borderRadius: 8,
}));

export const TooltipContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  background: theme.palette.common.white,
  borderRadius: 4,
  border: "1px solid rgba(0, 0, 0, 0.05)",
  boxShadow: theme.shadows[3],
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  "&: after": {
    content: '""',
    position: "absolute",
    width: 0,
    height: 0,
    borderWidth: 8,
    borderStyle: "solid",
    borderColor: "transparent #FFFFFF transparent transparent",
    top: "50%",
    transform: "translateY(-50%)",
    left: -16,
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  paddingTop: theme.spacing(1),
  color: theme.palette.biarri.secondary.lightGrey,
  textAlign: "center",
}));
