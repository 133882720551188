import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Apply the styles class to the component.
   */
  className?: string | undefined;
  /**
   * Set the panel icon.
   */
  icon: React.ReactNode;
  /**
   * Set the panel title.
   */
  title: string;
  /**
   * Set the onHover highlight color.
   */
  highlight?: string | undefined;
  /**
   * Set the panel width.
   */
  width?: string | undefined;
  /**
   * Set the panel height.
   */
  height?: string | undefined;
  /**
   * Callback on panel click.
   */
  onClick(): void;
  /**
   * Disable ButtonPanel
   */
  disabled?: boolean;
}

/*
 * A panel shaped Paper with an on hover highlight and layout:
 *
 *                     +---------------+
 *                     | +-----------+ |
 *                     | +           + |
 *                     | + ICON NODE + |
 *                     | +           + |
 *                     | +-----------+ |
 *                     |     TITLE     |
 *                     |      -->      |
 *                     +---------------+
 *
 * Applies the onHover 'highlight' to the inner border, icon fill (if SVG) and arrow.
 * The inactive color can not be configured, it is always biarri 'darkGrey'.
 */
export interface PaperPanelProps {
  width?: string | number | undefined;
  height?: string | number | undefined;
  highlight?: string | undefined;
}

const PaperPanel = styled(Paper)<PaperPanelProps>(({ theme, highlight, width, height }) => ({
  position: "relative",
  alignItems: "center",
  border: "solid 2px white",
  borderRadius: "0.5em",
  display: "flex",
  fill: theme.palette.biarri.primary.darkGrey,
  flexDirection: "column",
  height,
  justifyContent: "center",
  padding: `${theme.spacing(3)} 0`,
  width,
  "&:hover": {
    borderColor: highlight,
    color: highlight,
    cursor: "pointer",
    fill: highlight,
  },
  "&:hover > svg": {
    opacity: 1,
    transform: "translateX(-50%)",
  },
}));

const ArrowIcon = styled(ArrowForward)(({ theme }) => ({
  opacity: 0,
  height: "16px",
  width: "16px",
  position: "absolute",
  left: "50%",
  bottom: theme.spacing(1.5),
  transform: "translateX(-100%)",
  transition: "0.3s transform, 0.3s opacity",
}));

/*
 * A clickable panel shaped button with a configurable icon, title, hover highlight and click callback.
 */
const ButtonPanel: React.FC<IProps> = ({
  "data-testid": dataTestid = "button-panel",
  className,
  icon,
  title,
  highlight,
  width = "200px",
  height = "210px",
  onClick,
  disabled,
}: IProps) => (
  <PaperPanel
    data-testid={dataTestid}
    className={className}
    highlight={highlight}
    width={width}
    height={height}
    elevation={2}
    onClick={disabled ? undefined : onClick}
    aria-disabled={disabled}
  >
    {icon}
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="overline" fontSize={12}>
        {title}
      </Typography>
    </Box>
    <ArrowIcon />
  </PaperPanel>
);

export default ButtonPanel;
