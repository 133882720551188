import { styled, TableCell, TableRow as BaseTableRow } from "@mui/material";
import BaseToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";

import { fixSsrWarning } from "fond/utils/css";

export const TableRow = styled(BaseTableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  [`& > td:first-child ${fixSsrWarning}`]: {
    paddingLeft: theme.spacing(2.5),
  },
  [`& > td:last-child ${fixSsrWarning}`]: {
    paddingRight: theme.spacing(2.5),
  },
}));

export const PropertyNameCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  padding: `0 ${theme.spacing(2)} 0 0`,
  minWidth: "6em",
}));

export const ToggleButton = styled(BaseToggleButton)(({ theme }) => ({
  borderWidth: 0,
  borderRadius: theme.spacing(0.5),

  [`&.${toggleButtonClasses.selected}, &.${toggleButtonClasses.root}:hover`]: {
    backgroundColor: theme.palette.background.gridRow.active,
    color: theme.palette.primary.main,
  },

  [`&.${toggleButtonClasses.root}.MuiToggleButtonGroup-firstButton, &.${toggleButtonClasses.root}.MuiToggleButtonGroup-middleButton`]: {
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  [`&.${toggleButtonClasses.root}.MuiToggleButtonGroup-lastButton, &.${toggleButtonClasses.root}.MuiToggleButtonGroup-middleButton`]: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
}));
