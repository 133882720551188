import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, OutlinedInput, SelectChangeEvent, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { useGetProjectQuery, useUpdateProjectMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project";
import { Store, SystemsOfMeasurement } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { Modal } from "fond/widgets";

import { SelectHelperText, ShortSelect } from "./projectSettings.styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProjectSettings: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const projectId = useSelector((state: Store) => state.project.projectId);
  const { data: project } = useGetProjectQuery(projectId);
  const [systemOfMeasurement, setSystemOfMeasurement] = useState(project?.SystemOfMeasurement);
  const [updateProject] = useUpdateProjectMutation();
  const currentProject = useSelector((state: Store) => getCurrentProject(state.project));

  const canEditSettings = usePermissionCheck(
    currentProject.HasCustomLayerConfig ? Actions.PROJECT_EDIT : Actions.PLANNER_PROJECT_EDIT,
    currentProject.Permission.Level
  );

  const handleOnSave = async () => {
    if (project) {
      mixpanel.track("Update system of measurement", { systemOfMeasurement });
      try {
        await updateProject({ ID: project.ID, SystemOfMeasurement: systemOfMeasurement }).unwrap();

        enqueueSnackbar("System of measurement updated.");
        onClose();
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handleChange = (e: SelectChangeEvent<`${SystemsOfMeasurement}`>) => {
    setSystemOfMeasurement(e.target.value as `${SystemsOfMeasurement}`); // Mui select value does not get type narrowed with generics
  };

  const handleOnClose = () => {
    mixpanel.track("Closed project setting modal");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      header="Project settings"
      onClose={handleOnClose}
      data-testid="project-settings"
      content={
        <Grid container direction="column">
          <Typography>General options for the current project.</Typography>
          <Box mt={2}>
            <FormControl fullWidth>
              <FormLabel>Unit type</FormLabel>
              <ShortSelect
                value={systemOfMeasurement}
                data-testid="measurement-input"
                onChange={handleChange}
                input={<OutlinedInput size="small" />}
                margin="dense"
                disabled={!canEditSettings}
              >
                <MenuItem value="imperial">Imperial</MenuItem>
                <MenuItem value="metric">Metric</MenuItem>
              </ShortSelect>
              <SelectHelperText>Determines the unit of measurements the project will use.</SelectHelperText>
            </FormControl>
          </Box>
        </Grid>
      }
      actions={
        <>
          <Button data-testid="close-button" onClick={handleOnClose}>
            Close
          </Button>
          <Button data-testid="save-button" variant="contained" disabled={!canEditSettings} color="primary" onClick={handleOnSave}>
            Update Settings
          </Button>
        </>
      }
    />
  );
};

export default ProjectSettings;
