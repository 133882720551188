import { useSelector } from "react-redux";

import { selectCurrentAccount } from "fond/api";
import { Account, AccountBase } from "fond/types";

interface UseAccountSortReturn<T extends Account | AccountBase> {
  sortedAccounts: T[];
}

/**
 * @param accounts All accounts to sort
 * @returns Sorted account by name, where selected account is first
 */
export const useAccountSort = <T extends Account | AccountBase>(accounts: T[]): UseAccountSortReturn<T> => {
  const selectedAccount = useSelector(selectCurrentAccount);

  const sortedAccounts = accounts.sort((a, b) => {
    // put selected account at a higher rank
    if (a.Name === selectedAccount?.Name) return -1;
    if (b.Name === selectedAccount?.Name) return 1;

    return a.Name.localeCompare(b.Name);
  });

  return { sortedAccounts };
};
