import { Link as BaseLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Footer = styled(BaseLink)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontSize: 12,
}));

export const Link = styled(BaseLink)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export const NavbarButtonHelper = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
