import { useSelector } from "react-redux";
import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { inputLayerGroupList, LayerGroupIds, LayerIds } from "fond/layers";
import { getCurrentProject, hasLayer, isSolveActive as getIsSolveActive, setActiveLayerGroupId } from "fond/project/redux";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";
import { StackedNavigationButton, useStackedNavigationContext } from "fond/widgets";

import NoDataTooltip from "./NoDataTooltip";

interface IProps {
  readOnly: boolean;
}

const InputDataPanel: React.FC<IProps> = ({ readOnly }: IProps) => {
  const dispatch = useAppDispatch();
  const { data } = useSelector((state: Store) => state.project.projects[state.project.projectId]);
  const isSolveActive = useSelector((state: Store) => getIsSolveActive(state));
  const currentProject = useSelector((state: Store) => getCurrentProject(state.project));
  const canEditProject = usePermissionCheck(Actions.PLANNER_PROJECT_EDIT, currentProject.Permission.Level);

  const { open } = useStackedNavigationContext();

  const parcelCount = data?.layers[LayerIds.inAddress]?.features?.length ?? data?.layers[LayerIds.inParcel]?.features?.length;

  const hasStreetOrSpanPole = hasLayer(data, LayerIds.inStreet) || hasLayer(data, LayerIds.inSpan) || hasLayer(data, LayerIds.inPole);

  const renderNoDataRightElement = (id: string) => {
    switch (id) {
      case "addresses": {
        return (
          <Tooltip title="Required">
            <Error
              data-testid="no-data-icon"
              sx={{
                color: (theme) => theme.palette.biarri.secondary.inactiveGrey,
                fontSize: 18,
                "&:hover": { color: (theme) => theme.palette.biarri.secondary.activeGrey },
              }}
            />
          </Tooltip>
        );
      }
      case "undergroundPath":
      case "aerialSpanAndPoles": {
        return !hasStreetOrSpanPole ? <NoDataTooltip /> : null;
      }
      default: {
        return null;
      }
    }
  };

  const openInputLayerPanel = (id: string, layerGroupId: string) => {
    open(id);
    dispatch(setActiveLayerGroupId(layerGroupId));
  };

  return (
    <Box className="input-data">
      {inputLayerGroupList.map(({ id, name, icon: Icon, group: inputGroup }) => {
        const hasData =
          hasLayer(data, LayerIds[inputGroup.id]) ||
          (inputGroup.id === LayerGroupIds.spanPole && (hasLayer(data, LayerIds.inSpan) || hasLayer(data, LayerIds.inPole)));
        return (
          <StackedNavigationButton
            key={id}
            data-testid={`layer-navigation-${inputGroup.id}`}
            icon={<Icon />}
            title={name}
            onClick={() => openInputLayerPanel(id, inputGroup.id)}
            checked={hasData}
            disabled={readOnly || isSolveActive || !canEditProject}
            rightElement={
              hasData ? (
                <Box display="flex" alignItems="center">
                  {id === "addresses" && (
                    <Chip size="small" label={<Typography variant="body3">{parcelCount.toLocaleString()}</Typography>} sx={{ mr: 1 }} />
                  )}
                  <CheckCircle sx={{ color: (theme) => theme.palette.success.main, fontSize: 18 }} />
                </Box>
              ) : (
                renderNoDataRightElement(id)
              )
            }
            variant="outlined"
            elevation={0}
            sx={{ mt: 0, mb: 1, "&:last-child": { mb: 0 } }}
          />
        );
      })}
    </Box>
  );
};

export default InputDataPanel;
