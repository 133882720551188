import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { LoadingButton } from "ui";

import { Message, Modal } from "fond/widgets";

interface IProps {
  /**
   * The confirmation modal title
   */
  title: string;
  /**
   * The confirmation modal message
   */
  message: string;
  /**
   * The callback action when an item is to be deleted
   */
  onDelete(): Promise<void>;
  /**
   * Callback function for when the modal is closing
   */
  onClose(event: React.MouseEvent<EventTarget>): void;
}

const DeleteConfirmation: React.FC<IProps> = ({ message, title, onClose, onDelete }: IProps) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  /**
   * Callback function for the confirmation to delete
   */
  const handleOnDelete = (event: React.MouseEvent<EventTarget>) => {
    event.stopPropagation();

    setSaving(true);
    onDelete()
      .then(() => {
        setSaving(false);
      })
      .catch((e: any) => {
        // An error has occurred. Inform the user & cancel the saving indicator.
        setError(new Error("There was an issue with deletion, please try again."));
        setSaving(false);
      });
  };

  return (
    <Modal
      open
      header={title}
      data-testid="delete-confirmation-modal"
      content={
        <>
          {error && (
            <Box mb={2}>
              <Message type="error" data-testid="error">
                {error.message}
              </Message>
            </Box>
          )}
          <Typography>{message}</Typography>
        </>
      }
      actions={
        <>
          <Button data-testid="delete-confirmation-cancel-button" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton data-testid="delete-confirmation-save-button" color="primary" onClick={handleOnDelete} loading={saving}>
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default DeleteConfirmation;
