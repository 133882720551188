import React, { useState } from "react";
import { Redo } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import MoveProjectDialog from "fond/project/MoveProjectDialog";
import { Project } from "fond/types";
import { Actions } from "fond/utils/permissions";

interface IProps {
  project: Project;
  handleClose: () => void;
  isFetching: boolean;
}

const Move: React.FC<IProps> = ({ project, isFetching, handleClose }: IProps) => {
  const [moveModal, showMoveModal] = useState(false);

  const canMove = usePermissionCheck(Actions.PROJECT_MOVE, project.Permission.Level);

  const handleClick = () => {
    if (canMove) {
      showMoveModal(true);
      handleClose();
    }
  };

  return (
    <>
      <MenuItem data-testid="move-button" onClick={handleClick} disabled={!canMove}>
        <ListItemIcon>
          <Redo />
        </ListItemIcon>
        Move
      </MenuItem>
      {moveModal && !isFetching && <MoveProjectDialog project={project} onClose={() => showMoveModal(false)} />}
    </>
  );
};

export default Move;
