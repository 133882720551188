import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

import { getAllAttrs } from "fond/cognito/aws_cognito_utils";
import { CognitoAction } from "fond/cognito/redux";
import { RETURN_URL } from "fond/constants";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { BlockSpinner } from "./widgets";

interface IProps {
  component: React.ComponentType;
}
/**
 * PrivateRoute wraps the standard Route component and only renders the child if
 * the user is authenticated. If the user is not authenticated they are redirected to
 * /signin. PrivateRoute also sets up a listener on the history object which will
 * authenticate on any location (url) changes.
 *
 * Note that this component does not listen to the users authentication events. if a user is
 * signed out, they will not be redirected to the /signin until they try to change location.
 */
const PrivateRoute: React.FC<IProps> = ({ component: Component }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const authenticate = useCallback(() => {
    const returnUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        if (!currentUsername) {
          dispatch({
            type: CognitoAction.AUTHENTICATED,
            user,
            userAttrs: await getAllAttrs(user),
          });
        }
        setAuthenticated(true);
        setLoaded(true);
      })
      .catch(() => {
        navigate(`/signin?${RETURN_URL}=${returnUrl}`);
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      if (authenticated) {
        setAuthenticated(false);
      }
    });
  }, [authenticated, location]);

  if (!loaded) {
    return <BlockSpinner containerProps={{ height: "100%" }} />;
  } else if (authenticated) {
    return <Component />;
  } else {
    return <Navigate to="/signin" />;
  }
};

export default PrivateRoute;
