import {
  Box,
  IconButton as BaseIconButton,
  ListItemButton as BaseListItemButton,
  Paper as BasePaper,
  styled,
  ToggleButton as BaseToggleButton,
} from "@mui/material";

export const MainContainer = styled(Box)`
  position: absolute;
  inset: 0;
  z-index: 100;
  pointer-events: none;
`;

export const Paper = styled(BasePaper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  pointerEvents: "auto",
  padding: theme.spacing(0.5),
}));

export const ToggleButton = styled(BaseToggleButton)(({ theme, selected }) => ({
  color: theme.palette.biarri.secondary.darkGrey,
  border: "none",
  marginBottom: theme.spacing(0.5),
  height: 32,
  width: 32,

  "&&&": {
    borderRadius: `${theme.shape.borderRadius}px`,
  },

  "&.MuiToggleButton-root:hover": {
    color: theme.palette.primary.main,
  },

  "&.Mui-selected, &.Mui-selected.MuiToggleButton-root:hover": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const IconButton = styled(BaseIconButton)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.biarri.secondary.inactiveGrey : theme.palette.biarri.secondary.darkGrey,
}));

export const ListItemButton = styled(BaseListItemButton)(({ theme }) => ({
  "&.MuiListItemText-primary": {
    fontSize: 12,
  },

  "&.MuiListItemButton-root:hover": {
    ".MuiListItemText-primary": {
      fontWeight: "500",
    },
    ".MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },

  "&.Mui-selected, &.Mui-selected.MuiListItemButton-root:hover": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    ".MuiListItemIcon-root": {
      color: theme.palette.common.white,
    },
  },
}));
