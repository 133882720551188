import React, { useState } from "react";
import { Box, Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IProps {
  leftAdornments?: React.ReactNode | React.ReactNodeArray;
  rightAdornments?: React.ReactNode | React.ReactNodeArray;
  tabs?: Array<{ id: string; label: string }>;
  onTabChange?(event: React.ChangeEvent<any>, value: number): void;
}

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 32,
    minHeight: 32,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    // References Layout theme variables
    backgroundColor: "var(--color-1)",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 10,
    color: theme.palette.grey[500],
  },
  adornments: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& svg": {
      width: 20,
      height: 20,
    },
    fontSize: 12,
    fontWeight: 700,
  },
  tabs: {
    minHeight: 0,
  },
  tab: {
    fontSize: 12,
    "&.MuiTab-root": {
      minWidth: 0,
    },
    "&.MuiButtonBase-root": {
      minHeight: 0,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
}));

const Header: React.FC<IProps> = ({ leftAdornments, rightAdornments, tabs, onTabChange }: IProps) => {
  const classes = useCustomStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleOnTabSelect = (event: React.ChangeEvent<any>, value: number) => {
    setSelectedTabIndex(value);
    onTabChange?.(event, value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.adornments}>{leftAdornments}</Box>
      {tabs && (
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleOnTabSelect}
          value={selectedTabIndex}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          className={classes.tabs}
        >
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} className={classes.tab} />
          ))}
        </Tabs>
      )}
      <Box className={classes.adornments}>{rightAdornments}</Box>
    </Box>
  );
};

export default Header;
