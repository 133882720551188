import { FieldValidator } from "final-form";

import { NumArrayFieldValue } from "fond/form/fields/NumArrayField";
import { TextFieldValue } from "fond/form/fields/TextField";

/**
 * Can be used to validate multiple requirements on a single field
 */
export function compose<T>(...validators: FieldValidator<T>[]) {
  return (value: T, allValues: object): string | string[] | undefined => {
    for (let validator of validators) {
      const error = validator(value, allValues);
      if (error !== undefined) {
        return error;
      }
    }
    return undefined;
  };
}

/**
 * Required value validator
 */
export const required = (value?: unknown): string | undefined => {
  return value !== null && value !== undefined && value !== "" ? undefined : "This is a required field";
};

/**
 * Validate a field with a custom message for the invalid state
 * @param customMessage - The custom message to display when the field is required
 * @returns
 */
export const requiredWithMessage =
  (customMessage?: string) =>
  (value?: TextFieldValue): string | undefined => {
    const validationResult = required(value);
    if (validationResult) {
      return customMessage || validationResult;
    } else {
      return undefined;
    }
  };

/**
 * Required value validator for an array of values
 */
export const requireAll =
  (length: number) =>
  (value?: NumArrayFieldValue): Array<string | undefined> | undefined => {
    if (value?.length === length) {
      return undefined;
    } else {
      const errors = Array.from(Array(length));
      return errors.map((_, index) => (value?.[index] === undefined ? "This is a required field" : undefined));
    }
  };

/**
 * Required value validator for an array of values
 */
export const requireAllOrNone =
  (length: number) =>
  (value?: NumArrayFieldValue): Array<string | undefined> | undefined => {
    if (value == null || (value?.length === length && !value.some((val) => val === ""))) {
      return undefined;
    } else {
      const errors = Array.from(Array(length));
      return errors.map((err, index) => (value?.[index] === undefined || value?.[index] === "" ? "This is a required field" : undefined));
    }
  };

/**
 * URL Part Valid characters only
 */
export const alphanumeric = (value?: TextFieldValue): string | undefined =>
  value?.toString().match(/^[0-9a-zA-Z]+$/) ? undefined : "Please enter alphanumeric characters only";

/**
 * Numbers only
 */
export const numeric = (value?: TextFieldValue): string | undefined => {
  if (value == null || value === "") {
    // if the value is required it should be validated with the required validator
    return undefined;
  }
  if (!Array.isArray(value) && !Number.isNaN(Number(value?.toString()))) {
    return undefined;
  }
  return "Please enter a valid number";
};

/**
 * Mininum value
 */
export const minimum =
  (min: number) =>
  (value?: TextFieldValue): string | undefined => {
    if (value == null) return undefined;

    if (Number(value) < min) {
      return `Value must be ${min} or higher`;
    }

    return undefined;
  };

/**
 * Maximum value
 */
export const maximum =
  (max: number) =>
  (value?: TextFieldValue): string | undefined => {
    if (value == null) return undefined;

    if (Number(value) > max) {
      return `Value must be ${max} or lower`;
    }

    return undefined;
  };

/**
 * Mininum length of a string value
 */
export const minChars =
  (min: number) =>
  (value?: TextFieldValue): string | undefined => {
    if (value == null) return undefined;

    if (value.toString().length < min) {
      return `A minimum length of ${min} characters is required`;
    }

    return undefined;
  };

/**
 * Value must be not equal to another value
 */
export const notEqual =
  (current: number | string, error?: string) =>
  (value?: TextFieldValue): string | undefined => {
    if (current === value) {
      return error ?? `The value must be equal to ${current}`;
    }

    return undefined;
  };

/**
 * Value must be equal to another value
 */
export const equal =
  (current: number | string | null, error?: string) =>
  (value?: TextFieldValue): string | undefined => {
    if (current !== value) {
      return error ?? `The value must be equal to ${current}`;
    }

    return undefined;
  };

/**
 * Checks that an email address is valid.
 */
export const validEmailFormat = (value?: TextFieldValue): string | undefined => {
  if (value == null) return undefined;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(value.toString()) ? undefined : "Please enter a valid email address";
};
