import { Invitation, User } from "fond/types";

import { apiSlice } from "./apiSlice";

type GetInvitationsResponse = {
  Invitations: Array<Invitation>;
};

type InviteUsersParam = { accountId: string; invitees: Array<{ Email: string }> };

type ResendInvitationParams = { accountId: string; invitationId: string };

type InviteUsersResponse = {
  Items: Array<Invitation>;
};

export const invitationSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getInvitations: build.query<Invitation[], string>({
      query: (accountId) => ({
        url: "/v2/invitations",
        params: { account_id: accountId },
      }),
      transformResponse: (response: GetInvitationsResponse) => response.Invitations,
      providesTags: (result) =>
        result
          ? [...result.map((invitation) => ({ type: <const>"Invitations", id: invitation.ID })), { type: "Invitations", id: "LIST" }]
          : [{ type: "Invitations", id: "LIST" }],
    }),
    inviteUser: build.mutation<User, string>({
      query: (email) => ({
        url: "/v2/users",
        method: "POST",
        body: { Email: email },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    inviteUsersBulk: build.mutation<InviteUsersResponse, InviteUsersParam>({
      query: ({ accountId, invitees }) => ({
        url: "/v2/invitations/bulk",
        method: "POST",
        body: { AccountID: accountId, Invitees: invitees },
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [{ type: "Invitations", id: "LIST" }],
    }),
    resendInvitation: build.mutation<Invitation, ResendInvitationParams>({
      query: ({ invitationId }) => ({
        url: `/v2/invitations/${invitationId}/resend`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Invitations", id: "LIST" }],
      onQueryStarted: async ({ invitationId, accountId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          invitationSlice.util.updateQueryData("getInvitations", accountId, (draft) => {
            const inviteToDelete = draft.findIndex((invite) => invite.ID === invitationId);
            draft.splice(inviteToDelete, 1);
          })
        );
        queryFulfilled.catch(() => {
          patchResult.undo();
        });
      },
    }),
    updateInvitation: build.mutation<
      Invitation,
      { invitation: Pick<Invitation, "ID" | "InvitationStatus">; userType: "new" | "existing"; token?: string }
    >({
      query: ({ invitation, userType, token }) => ({
        ...(userType === "new" ? { headers: new Headers({ Authorization: `JWT ${token}` }) } : {}),
        url: `/v2/invitations/${invitation.ID}`,
        method: "PATCH",
        body: { InvitationStatus: invitation.InvitationStatus },
      }),
    }),
    deleteInvitation: build.mutation<undefined, string>({
      query: (invitationId) => ({
        url: `/v2/invitations/${invitationId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Invitations", id: "LIST" },
        { type: "Invitations", id: arg },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetInvitationsQuery,
  useInviteUserMutation,
  useInviteUsersBulkMutation,
  useResendInvitationMutation,
  useUpdateInvitationMutation,
  useDeleteInvitationMutation,
} = invitationSlice;
