import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Segment } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { hydrateLayer, selectDraftConfigEntities, useUpdateLayerConfigMutation } from "fond/api";
import { LayerClassification } from "fond/types";
import { LayerConfig, LayerConfigHydrated, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";
import { makeUuid } from "fond/utils";
import { generateColors } from "fond/utils/colors";
import { Modal } from "fond/widgets";

import ClassifyForm, { IClassifyFormData } from "./ClassifyForm";
import { generateStyleFromClassification, getDefaultStyleValues } from "./helper";

interface IProps {
  layerConfig: LayerConfig;
  mapLayerConfigId: string;
  onClose(): void;
}

const Classification: React.FC<IProps> = ({ layerConfig, mapLayerConfigId, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const draft = useSelector(selectDraftConfigEntities);
  const [updateLayerConfig] = useUpdateLayerConfigMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rowData, setRowData] = useState<LayerClassification[] | null>([]);
  const [formValues, setFormValues] = useState<IClassifyFormData>({
    colorRamp: undefined,
    range: "equal_intervals",
    groupSize: 4,
    selected: [],
  });

  const colors = useMemo(() => generateColors(formValues.colorRamp, rowData?.length), [rowData?.length, formValues.colorRamp]);

  useEffect(() => {
    setRowData([]);
  }, [formValues.attributeId]);

  /**
   * Handles the cancellation of the classification by calling the
   * onClose callback.
   */
  const handleOnCancel = () => {
    onClose();
  };

  /**
   * Handles the creation of the new sublayers and styles
   */
  const handleOnSubmit = async () => {
    const newLayer = hydrateLayer(draft, layerConfig) as LayerConfigHydrated;
    // Clear existing styles at the layer level
    newLayer.Styles = [];

    // Create new Sublayers
    newLayer.Children = formValues.selected.map((layerClassification, index) => {
      const sublayerKey = makeUuid();
      return {
        ID: null,
        Type: "SUBLAYER",
        Label: layerClassification.Label,
        IsVisible: true,
        ParentID: layerConfig.ID,
        Styles: [{ ...generateStyleFromClassification(layerClassification, colors[index]), ...getDefaultStyleValues(formValues) }],
        FilterConfiguration: layerClassification.FilterConfiguration,
        Position: index,
        Key: sublayerKey,
      } as SublayerConfigHydrated & { ID: null };
    });

    setIsSubmitting(true);
    try {
      await updateLayerConfig({ layerConfig: newLayer, mapLayerConfigId });
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to classify layer", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open
      headerIcon={<Segment />}
      header="Classify layer"
      data-testid="classify-layer-modal"
      variant="primary"
      content={
        <ClassifyForm
          colors={colors}
          data={rowData}
          initialValues={formValues}
          isSubmitting={isSubmitting}
          layerConfig={layerConfig}
          onChange={setFormValues}
          setData={setRowData}
        />
      }
      actions={
        <>
          <Button data-testid="new-style-cancel-button" color="primary" onClick={handleOnCancel} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            data-testid="new-style-save-button"
            color="primary"
            type="submit"
            onClick={handleOnSubmit}
            disabled={formValues.selected.length === 0}
            loading={isSubmitting}
          >
            Apply
          </LoadingButton>
        </>
      }
    />
  );
};

export default Classification;
