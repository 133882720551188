import * as turf from "fond/turf";
import { sqMilesToSqMetres } from "fond/utils/area";

import { roundLength } from "../../utils";

export const MAX_AREA_IN_SQ_MILES = 1500;
export const MAX_AREA_IN_SQ_KILOMETERS = roundLength(MAX_AREA_IN_SQ_MILES * 2.58998811);
export const MAX_NUM_ADDRESSES = 10000;
export const POLYGON_UPLOAD_LAYER_ID = "polygon-select-polygon";

export const PolygonStatus = {
  ValidArea: "ValidArea",
  InvalidArea: "InvalidArea",
  UnsupportedArea: "UnsupportedArea",
  QueryTooLarge: "QueryTooLarge",
};

export function isAcceptableSize(area) {
  return turf.area(area) < sqMilesToSqMetres(MAX_AREA_IN_SQ_MILES);
}
