import React, { useEffect, useMemo, useState } from "react";
import { Delete } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { ConfigAttribute } from "fond/types";
import { FilterConfiguration } from "fond/types/ProjectLayerConfig";
import { convertFeetToMeters, convertMetersToFeet } from "fond/utils";

import { FormLabel } from "../styles";

import { FilterContainer, InlineFieldWrapper, MenuItemWithIcon, OperatorIcon, SelectWithIcon, TextField } from "./sublayerFilterField.styles";

interface FilterOperatorsMap {
  [key: string]: {
    displayValue: string;
    iconIndex: number;
  };
}

type Key = Extract<keyof FilterConfiguration, "Values" | "Operator" | "ValueKey">;

const filterOperatorsMap: FilterOperatorsMap = {
  "==": { displayValue: "Equal", iconIndex: 1 },
  "!=": { displayValue: "Not equal", iconIndex: 0 },
  in: { displayValue: "In", iconIndex: 2 },
  "!in": { displayValue: "Not in", iconIndex: 3 },
  contains: { displayValue: "Contains", iconIndex: 8 },
  "!contains": { displayValue: "Not Contains", iconIndex: 9 },
  "<": { displayValue: "Less than", iconIndex: 4 },
  ">": { displayValue: "Greater than", iconIndex: 5 },
  "<=": { displayValue: "Less than or equal to", iconIndex: 6 },
  ">=": { displayValue: "Greater than or equal to", iconIndex: 7 },
};

interface IProps {
  expression: FilterConfiguration;
  filterIndex: number;
  attributes: ConfigAttribute[];
  generateExpressionMapbox: (expression: FilterConfiguration) => any[];
  generateGroupMapbox: (expression: FilterConfiguration) => any[];
  value: FilterConfiguration;
  onChange: (newValue: FilterConfiguration | null) => void;
}

const SingleFilterForm: React.FC<IProps> = ({
  expression,
  filterIndex,
  attributes,
  generateExpressionMapbox,
  generateGroupMapbox,
  value,
  onChange,
}) => {
  const attributeReference = useMemo(
    () =>
      attributes.find(({ ID }) => {
        return (value.Expressions?.[filterIndex] || value).Values?.[0].Attribute?.ID === ID;
      }),
    [filterIndex, attributes, value]
  );
  const [inputValue, setInputValue] = useState(() => {
    // If the inputValue is a Length Field & the System of Measurements is "imperial"
    // We need to convert the value (which is always stored in meters) to feet.
    const meters =
      attributeReference?.SourceSystemOfMeasurement === "imperial" ? `${convertMetersToFeet(expression.ValueKey || 0)}` : expression.ValueKey;

    return meters;
  });

  useEffect(() => {
    handleOnValueChange(inputValue, "ValueKey", filterIndex);
  }, [inputValue]);

  const removeFilter = (index: number) => {
    const copyOfValue = JSON.parse(JSON.stringify(value));
    if (copyOfValue.Type !== "group" || !copyOfValue.Expressions) {
      onChange(null);
      return;
    }

    copyOfValue.Expressions.splice(index, 1);
    if (copyOfValue.Expressions.length > 1) {
      copyOfValue.Mapbox.splice(index + 1, 1);
      onChange(copyOfValue);
    } else {
      onChange(copyOfValue.Expressions[0]);
    }
  };

  const getUpdatedExpression = (currentExpression: FilterConfiguration, newValue: string, key: Key) => {
    const updatedExpression = { ...currentExpression };
    if (key === "Values") {
      const attribute = attributes.find((attr) => attr.Name === newValue);
      if (attribute) updatedExpression.Values = [{ Attribute: attribute, Literal: null, SliceEnd: null, SliceStart: null }];
    } else if (key === "Operator") {
      /**
       * When updating operator and the newly selected operator is "!in" or "!contains",
       * they should be transformed to just "in"/"contains" since the backend doesn't accept them
       * and the negate should be set to true
       */
      if (newValue === "!in" || newValue === "!contains") {
        updatedExpression.Operator = newValue.substring(1);
        updatedExpression.Negate = true;
      } else {
        updatedExpression.Operator = newValue;
        updatedExpression.Negate = false;
      }
    } else {
      // key === "ValueKey"
      const attributeType = currentExpression?.Values?.[0].Attribute?.Type ?? "STRING";
      updatedExpression.ValueKey = ["INTEGER", "REAL"].includes(attributeType) ? +newValue : newValue;
    }
    return updatedExpression;
  };

  const handleOnValueChange = useDebouncedCallback((newValue: string, key: Key, index = 0) => {
    // If the inputValue is a Length Field & the System of Measurements is "imperial"
    // We need to convert the value meters as we always store in the backend a metric value.
    const meters = key === "ValueKey" && attributeReference?.SourceSystemOfMeasurement === "imperial" ? `${convertFeetToMeters(newValue)}` : newValue;

    if (value.Type === "expression") {
      const modifiedValue = getUpdatedExpression(value, meters, key);
      const updatedMapbox = generateExpressionMapbox(modifiedValue);
      onChange({ ...modifiedValue, Mapbox: updatedMapbox });
    } else {
      if (!value.Expressions) return;
      const updatedExpression = getUpdatedExpression(value.Expressions[index], meters, key);
      const updatedExpressionMapbox = generateExpressionMapbox(updatedExpression);
      const updatedValue = {
        ...value,
        Expressions: [
          ...value.Expressions.slice(0, index),
          { ...updatedExpression, Mapbox: updatedExpressionMapbox },
          ...value.Expressions.slice(index + 1),
        ],
      };
      const updatedGroupMapbox = generateGroupMapbox(updatedValue);
      onChange({ ...updatedValue, Mapbox: updatedGroupMapbox });
    }
  }, 500);

  /**
   *  This sets operator select value from expression.Operator
   *  and translates in/contains to !in/!contains if expression.Negate is true
   */
  const operatorValue = `${expression.Operator && expression.Negate && ["in", "contains"].includes(expression.Operator) ? "!" : ""}${
    expression.Operator
  }`;

  const getAdornment = () => {
    const systemOfMeasurement = attributeReference?.SourceSystemOfMeasurement;
    return systemOfMeasurement && <InputAdornment position="end">{systemOfMeasurement === "imperial" ? "feet" : "meters"}</InputAdornment>;
  };

  return (
    <FilterContainer data-testid={`filter-item-${filterIndex}`}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel sx={{ marginBottom: 0 }}>
          Filter&nbsp;
          {filterIndex + 1}
        </FormLabel>
        <IconButton data-testid="remove-filter-btn" size="small" onClick={() => removeFilter(filterIndex)}>
          <Delete sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
      <InlineFieldWrapper>
        <FormLabel>Attribute</FormLabel>
        <Select
          inputProps={{ "data-testid": "filter-attribute-select" }}
          value={(expression?.Values && expression.Values[0].Attribute?.Name) || ""}
          onChange={(event: SelectChangeEvent) => handleOnValueChange(event.target.value, "Values", filterIndex)}
          sx={{ fontSize: 12 }}
          size="small"
          margin="none"
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {attributes.map((attribute) => (
            <MenuItem key={attribute.Name} value={attribute.Name}>
              {attribute.Name}
            </MenuItem>
          ))}
        </Select>
      </InlineFieldWrapper>
      <InlineFieldWrapper>
        <FormLabel>Operator</FormLabel>
        <SelectWithIcon
          inputProps={{ "data-testid": "filter-operator-select" }}
          value={operatorValue}
          onChange={(event: SelectChangeEvent<string>) => handleOnValueChange(event.target.value, "Operator", filterIndex)}
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {Object.entries(filterOperatorsMap).map(([operator, { displayValue, iconIndex }]) => (
            <MenuItemWithIcon key={operator} value={operator}>
              <OperatorIcon index={iconIndex} />
              {displayValue}
            </MenuItemWithIcon>
          ))}
        </SelectWithIcon>
      </InlineFieldWrapper>
      <InlineFieldWrapper>
        <FormLabel>Input</FormLabel>
        <TextField
          inputProps={{ "data-testid": "filter-value-field" }}
          value={inputValue || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
          InputProps={{
            endAdornment: getAdornment(),
          }}
        />
      </InlineFieldWrapper>
    </FilterContainer>
  );
};

export default SingleFilterForm;
