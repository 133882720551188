/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis, DrawMode } from "@mapbox/mapbox-gl-draw";

import drawLineString, { ExtendedLineString } from "./draw_line_string";
import snapTo, { withSnapping } from "./snap_to";

const {
  constants: Constants,
  lib: {
    CommonSelectors: { isEnterKey, isEscapeKey },
  },
  modes,
} = MapboxDraw;

interface DrawCustomModeState {
  currentVertexPosition: number;
  direction: string;
  layerId: string;
  line: ExtendedLineString;
  lineLayerId: string;
  pointLayerId: string;
}

interface DrawCustomModeOptions {
  lineLayerId: string;
  pointLayerId: string;
}

interface DrawCustomModeExtension {
  commitLine(this: DrawCustomModeThis, state: DrawCustomModeState): void;
}

const drawSpans = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.draw_line_string,

  onSetup: function (opts) {
    const state = drawLineString.onSetup.call(this as any, { featureId: undefined, layerId: opts.lineLayerId, from: undefined });

    return {
      ...state,
      pointLayerId: opts.pointLayerId,
      lineLayerId: opts.lineLayerId,
    };
  },

  commitLine: function (state) {
    const { features, selectedFeatureId } = this._ctx.api.commitSpans({
      lineString: state.line.toGeoJSON(),
      snaps: state.line.snaps,
    });
    this.map.fire(Constants.events.CREATE, { features: features });
    return this.changeMode("span_pole_select" as DrawMode, {
      featureIds: selectedFeatureId != null ? [selectedFeatureId] : null,
      lineLayerId: state.lineLayerId,
      pointLayerId: state.pointLayerId,
    });
  },

  onClick: function (state, e) {
    drawLineString.onClick?.call(this as any, state, e);
  },

  onKeyUp: function (state, e) {
    if (isEnterKey(e)) {
      this.commitLine(state);
    } else if (isEscapeKey(e)) {
      this.deleteFeature(state.line.id.toString(), { silent: true });
      this.changeMode("span_pole_select" as DrawMode, {
        featureIds: null,
        lineLayerId: state.lineLayerId,
        pointLayerId: state.pointLayerId,
      });
    }
  },

  snap: function (this: DrawCustomModeThis, state, e) {
    return snapTo(e, this._ctx, state.line.id);
  },
});

export default drawSpans;
