import { Alert as BaseAlert, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TermsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  margin: `${theme.spacing(2)} 0`,
  border: `1px solid ${theme.palette.biarri.primary.lightGrey}`,
  borderRadius: theme.shape.borderRadius,
  maxHeight: "16rem",
  overflow: "auto",
}));

export const Alert = styled(BaseAlert)({
  fontSize: "0.85rem",
  "& .MuiAlert-icon": {
    fontSize: "1.2rem",
    alignItems: "center",
  },
});

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: "0.6rem",
  color: theme.palette.biarri.primary.grey,
}));
