import React, { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Alert, Box } from "@mui/material";
import { FORM_ERROR } from "final-form";

import { LoadingButton } from "ui";

import { selectCurrentAccount, useGetProjectsQuery, useGetUserMeQuery, useUpdateAccountMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { required } from "fond/utils/validation";

interface IFormData {
  accountName: string;
}

const AccountDetails: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const account = useSelector(selectCurrentAccount);

  const [updateAccount, { isLoading }] = useUpdateAccountMutation();
  const { refetch: refetchUserDetail } = useGetUserMeQuery();
  const { refetch: refetchProjectsList } = useGetProjectsQuery(undefined);

  const onSubmit = async ({ accountName }: IFormData) => {
    if (account && accountName && account?.Name !== accountName) {
      try {
        await updateAccount({ ID: account?.ID, Name: accountName }).unwrap();
        refetchUserDetail();
        refetchProjectsList();
        setSuccess(true);
      } catch (err) {
        setSuccess(false);
        return { [FORM_ERROR]: "Failed to update account details" };
      }
    }
    return {};
  };

  return (
    <Form<IFormData>
      initialValues={{ accountName: account?.Name }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Box width="50%">
            <TextField
              inputProps={{ "data-testid": "account-name" }}
              variant="outlined"
              validate={required}
              label="Account name"
              name="accountName"
              defaultValue={account?.Name}
            />

            {submitError && (
              <Box mt={2}>
                <Alert data-testid="update-account-details-error-message" severity="error">
                  {submitError}
                </Alert>
              </Box>
            )}
            {success && (
              <Box mt={2}>
                <Alert data-testid="update-account-details-success-message" severity="success">
                  Your account details have been updated.
                </Alert>
              </Box>
            )}

            <Box mt={2}>
              <LoadingButton data-testid="update-account-details-button" variant="contained" color="primary" type="submit" loading={isLoading}>
                Save settings
              </LoadingButton>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default AccountDetails;
