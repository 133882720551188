import { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import { useSnackbar } from "notistack";

import {
  apiSlice,
  useExecuteCustomWorkflowMutation,
  useGetCustomWorkflowExecutionsQuery,
  useGetCustomWorkflowsQuery,
  useGetVersionQuery,
} from "fond/api";
import { refreshTileSource } from "fond/map/redux";
import { useAppDispatch } from "fond/utils/hooks";

type Outputs = {
  workflowState: undefined | "IN_PROGRESS" | "COMPLETE" | "ERROR" | "PENDING" | "STARTING";
  workflowProgress: undefined | number;
  runWorkflow: null | (() => void);
};

export function useCustomWorkflow(versionId: string, workflowKey: string): Outputs {
  const [pollingInterval, setPollingInterval] = useState<number | null>(null);
  const { refetch: refetchVersion } = useGetVersionQuery(versionId);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { data: customWorkflowExecutions } = useGetCustomWorkflowExecutionsQuery(
    { versionId },
    { pollingInterval: pollingInterval !== null ? pollingInterval : 30000 }
  );
  const { data: customWorkflows } = useGetCustomWorkflowsQuery({ versionId });
  const [executeCustomWorkflow, { isLoading, isSuccess }] = useExecuteCustomWorkflowMutation();

  const workflowExecution = useMemo(() => {
    return (
      customWorkflowExecutions && customWorkflowExecutions.CustomWorkflowExecutions.find((execution) => execution.CustomWorkflow.Key === workflowKey)
    );
  }, [customWorkflowExecutions, workflowKey]);
  const workflowState = useMemo(() => {
    if (isLoading || (isSuccess && !workflowExecution)) return "STARTING";
    return workflowExecution ? workflowExecution.Status.State : undefined;
  }, [isLoading, isSuccess, workflowExecution]);
  const workflowProgress = workflowExecution && workflowExecution.Status.Progress !== null ? workflowExecution.Status.Progress : undefined;
  const previousWorkflowState = usePrevious(workflowState);

  // Get the custom workflow object
  const workflow = useMemo(() => {
    return customWorkflows && customWorkflows.CustomWorkflows.find((wf) => wf.Key === workflowKey);
  }, [customWorkflows, workflowKey]);

  // Run the custom workflow
  const runWorkflow = useMemo(() => {
    if (!workflow) {
      return null;
    }

    return () => {
      executeCustomWorkflow({ versionId, workflowId: workflow.ID })
        .unwrap()
        .catch(() => {
          enqueueSnackbar("Operation failed. Please try again...", { variant: "error" });
        });
    };
  }, [workflow, executeCustomWorkflow, versionId, enqueueSnackbar]);

  useEffect(() => {
    // Trigger a refresh on the version if we observe the workflow move to the complete state from elsewhere
    if (previousWorkflowState && previousWorkflowState !== "COMPLETE" && workflowState === "COMPLETE") {
      dispatch(refreshTileSource("project-layers"));
      dispatch(
        apiSlice.util.invalidateTags([
          { type: "FeatureTotals", id: versionId },
          { type: "Layers", id: versionId },
        ])
      );
      refetchVersion();
    }

    // Alter the polling interval dynamically based on whether the workflow is currently executing
    if (workflowState === "IN_PROGRESS") {
      setPollingInterval(2000);
    } else {
      setPollingInterval(null);
    }
  }, [dispatch, previousWorkflowState, refetchVersion, versionId, workflowState]);

  return { workflowState, workflowProgress, runWorkflow };
}
