import { styled, TableCell as BaseTableCell, TableRow as BaseTableRow } from "@mui/material";

export const TableRow = styled(BaseTableRow)`
  &:nth-of-type(even), &.table-head-row {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  &:last-child td {
    border: 0;
  },
`;

export const TableCell = styled(BaseTableCell)`
  padding: ${({ theme }) => theme.spacing(0.5)};

  & .MuiInputBase-root {
    background: white;
  }
`;

export const TableHeadCell = styled(TableCell)`
  height: 42px;
  padding: 0;

  & > .MuiTypography-root {
    font-size: 13px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 12px;
  }
  &:not(:last-child) > .MuiTypography-root {
    border-right: 1px solid ${({ theme }) => theme.palette.biarri.primary.lightGrey};
  }
`;
