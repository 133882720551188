import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import NavigationItem from "./NavigationItem";
import { toggleShared } from "./redux";

import {
  NavigationItemContainer,
  NavigationItemDropdownIcon,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./collapsibleNavigationItem.styles";
import { ListItemIcon } from "./navigation.styles";

export type CollapsibleItem = {
  key: string;
  element: ReactNode;
};

interface CollapsibleNavigationItemProps extends React.ComponentProps<typeof NavigationItem> {
  onClick: () => void;
  nestedItems: CollapsibleItem[];
}

const CollapsibleNavigationItem: React.FC<CollapsibleNavigationItemProps> = ({
  title,
  icon,
  testId,
  selected,
  onClick,
  nestedItems,
}: CollapsibleNavigationItemProps) => {
  const dispatch = useAppDispatch();
  const expanded = useSelector((state: Store) => state.expanded.shared);

  return (
    <StyledAccordion expanded={expanded}>
      <StyledAccordionSummary selected={selected} data-testid={testId} onClick={onClick}>
        <NavigationItemDropdownIcon onClick={() => dispatch(toggleShared)} />
        <NavigationItemContainer>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography fontWeight={selected ? 500 : undefined}>{title}</Typography>
        </NavigationItemContainer>
      </StyledAccordionSummary>
      {nestedItems.map(({ key, element }) => (
        <StyledAccordionDetails data-testid="shared-accounts" key={key}>
          {element}
        </StyledAccordionDetails>
      ))}
    </StyledAccordion>
  );
};

export default CollapsibleNavigationItem;
