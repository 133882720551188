import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectAllFolders, selectAllProjects, selectAllReports, useGetFoldersQuery, useGetProjectsQuery, useGetReportsQuery } from "fond/api";
import { useStarred } from "fond/projects/ItemMenu/hooks/useStarred";
import { Folder, ProjectPreview, Report, Store } from "fond/types";

interface UseContentsReturn {
  items: (Folder | ProjectPreview | Report)[];
  sharedItems: (Folder | ProjectPreview | Report)[];
  starredItems: (Folder | ProjectPreview | Report)[];
}

const currentUserIsCreator = (currentUserName: string, item: Folder | ProjectPreview | Report) => {
  if (item.EntityType === "report") {
    return item.Creator.Email === currentUserName;
  }
  return item.Creator === currentUserName;
};

/**
 * Gets all shared content to currently logged in user
 */
export const useContents = (): UseContentsReturn => {
  useGetFoldersQuery(undefined);
  useGetProjectsQuery(undefined);
  useGetReportsQuery(undefined);
  const folders = useSelector(selectAllFolders);
  const projects = useSelector(selectAllProjects);
  const reports = useSelector(selectAllReports);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const { starred } = useStarred();

  const sharedItems = useMemo(
    () =>
      [...folders, ...projects, ...reports].filter(
        (item) => !item.Permission?.Inherited && item.Permission?.IdentityType === "user" && !currentUserIsCreator(currentUsername, item)
      ),
    [currentUsername, folders, projects, reports]
  );

  const starredItems = [...folders, ...projects, ...reports].filter((item) => starred.includes(item.ID));

  return { items: [...folders, ...projects, ...reports], sharedItems, starredItems };
};
