import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { ColDef, GetRowIdParams, GridApi, GridOptions, ICellRendererParams, ValueFormatterParams } from "@ag-grid-community/core";
import { Check, Error, Search } from "@mui/icons-material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";

import { selectCurrentAccount, useGetInvitationsQuery } from "fond/api";
import { Invitation } from "fond/types";
import { AgGrid, dateComparator, IconCellRenderer } from "fond/widgets/AgGrid";

import InviteActions from "./InviteActions";

import { ExpirationCell, GridWrapper, InviteSearchField, MainContainer } from "./PendingInvites.styles";

const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();

const renderExpirationCell = (data: Invitation) => {
  const isExpired = data.InvitationStatus === "expired";
  return (
    <ExpirationCell isExpired={isExpired}>
      {isExpired && <Error data-testid="expired-invite-indicator" />}
      {dayjs.utc(data.ExpireTime).tz(timeZone).format("D MMM YYYY h:mmA")}
    </ExpirationCell>
  );
};

const columns: ColDef[] = [
  {
    field: "User.Email",
    headerName: "Email",
    flex: 2,
    menuTabs: ["filterMenuTab"],
    filter: "agTextColumnFilter",
  },
  {
    field: "Creator.Email",
    headerName: "Invited by",
    flex: 2,
    menuTabs: ["filterMenuTab"],
    filter: "agTextColumnFilter",
  },
  {
    field: "CreatedAt",
    headerName: "Date invited",
    valueFormatter: ({ value }) => dayjs.utc(value).tz(timeZone).format("D MMM YYYY"), // formats date to something like 9 May 2023
    menuTabs: ["filterMenuTab"],
    filter: "agDateColumnFilter",
    comparator: dateComparator,
    sort: "desc",
  },
  {
    field: "License",
    headerName: "License",
    cellRenderer: IconCellRenderer,
    cellRendererParams: {
      icon: Check,
      iconProps: {
        color: "primary",
      },
    },
    suppressNavigable: true,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      valueFormatter: (params: ValueFormatterParams) => (params.value ? "With License" : "Without License"),
    },
    menuTabs: ["filterMenuTab"],
    width: 100,
    cellClass: "vertically-align",
  },
  {
    field: "ExpireTime",
    headerName: "Invitation expires on",
    cellRenderer: (cell: ICellRendererParams) => renderExpirationCell(cell.data as Invitation),
    menuTabs: ["filterMenuTab"],
    filter: "agDateColumnFilter",
  },
  {
    field: "InviteAction",
    headerName: "",
    cellRenderer: (cell: ICellRendererParams) => <InviteActions invitation={cell.data} />,
    width: 64,
    sortable: false,
    suppressMovable: true,
    type: "rightAligned",
    suppressHeaderMenuButton: true,
  },
];

const PendingInvites: React.FC = () => {
  const gridApiRef = useRef<GridApi | null>(null);
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data } = useGetInvitationsQuery(selectedAccount?.ID ?? skipToken);

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "autoHeight",
    getRowId: (params: GetRowIdParams) => params.data.ID,
  };

  return (
    <MainContainer>
      <InviteSearchField
        size="small"
        placeholder="Search by keywords"
        InputProps={{ startAdornment: <Search fontSize="small" /> }}
        onChange={(event) => gridApiRef.current?.setQuickFilter(event.target.value ?? "")}
        data-testid="invite-search-field"
      />
      <GridWrapper data-testid="pending-invites-list">
        <AgGrid
          columnDefs={columns}
          rowData={data ?? []}
          gridOptions={gridOptions}
          onGridReady={(params) => {
            gridApiRef.current = params.api;
          }}
          autoSizeColumns={false}
          variant="outlined"
        />
      </GridWrapper>
    </MainContainer>
  );
};

export default PendingInvites;
