/**
 * Converts square miles to square metres.
 */
export function sqMilesToSqMetres(squareMiles: number): number {
  return squareMiles * 2589988.1;
}

/**
 * Converts square metres to square miles.
 */
export function sqMetresToSqMiles(squareMeters: number): number {
  return squareMeters / 2589988.1;
}

export function sqMetersToSqKilometers(squareMeters: number): number {
  return squareMeters * 0.000001;
}
