import React, { createRef, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close, Today } from "@mui/icons-material";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { AllGeoJSON } from "@turf/helpers";
import classNames from "classnames";
import dayjs from "dayjs";
import { Point } from "geojson";
import { groupBy, includes, startCase } from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { MapContext } from "fond/map/MapProvider";
import { getOne, selectComment } from "fond/redux/comments";
import * as turf from "fond/turf";
import { Comment, CommentImportance, Reply, Store } from "fond/types";
import { relativeDate } from "fond/utils/dates";
import { useOnScreen } from "fond/utils/hooks";
import { BlockSpinner, FadeIn } from "fond/widgets";

import BaseComment, { renderImportanceIcon } from "./Comment";

// Due to the performance hit of rendering comments & their replies
// we dynamically render the comments when they are visible on the
// screen. The PAGE_SIZE is the number we pre-load each request.
const PAGE_SIZE = 5;

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${grey[300]}`,
    },
    alert: {
      boxShadow: theme.shadows[1],
    },
    selected: {},
    close: {
      padding: theme.spacing(0.5),
    },
    groupedByWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    gropuByIconWrapper: {
      backgroundColor: theme.palette.common.white,
      zIndex: 2,
      position: "sticky",
      top: -1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    groupedByTitle: {
      paddingLeft: theme.spacing(1),
      display: "flex",
      width: "100%",
      "&::after": {
        content: "''",
        flex: "1 1",
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: "auto",
        marginLeft: theme.spacing(1),
      },
      "&::before": {
        marginRight: theme.spacing(1),
      },
    },
    groupedByIconFirst: {
      marginTop: 0,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * A timestamp indicating when replies should no longer be
   * considered new.  (New replies are not grouped)
   */
  beforeTimestamp: number;
  /**
   * A filtered set of comments to be rendered
   */
  comments: Comment[];
  /**
   * The search criteria to use to further filter the comments
   * being rendered.
   */
  searchText: string;
}

const CommentsList: React.FC<IProps> = ({ beforeTimestamp, classes, comments, searchText }: IProps) => {
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();
  const [groupedComments, setGroupedComments] = useState<{ [key: string]: Comment[] }>({});
  const selectedComment = useSelector((state: Store) => getOne(state)(state.project.selectedComment?.commentID));
  const view = useSelector((state: Store) => state.project.projects[state.project.projectId].view);
  const mapFilter = useSelector((state: Store) => state.comments.mapFilter);
  const sortOrder = useSelector((state: Store) => state.comments.sortOrder);
  const [error, setError] = useState<string | undefined>(undefined);
  const selectedCommentRef = useRef<HTMLDivElement>(null);
  const [loadingRef, setLoadingRef] = useState<RefObject<HTMLElement>>(createRef<HTMLDivElement>());
  const { map } = useContext(MapContext);
  const [totalComments, setTotalComments] = useState(comments.length);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  /**
   * As the comments, search text or view change we need to determine
   * the new set of filtered comments that should be shown to the user
   * and then render them.
   */
  useEffect(() => {
    if (!mapFilter) {
      updateComments(comments);
    } else {
      // Update the comments to render based on map visibility
      updateCommentFeaturesInView();
    }
  }, [comments, searchText, mapFilter, view]);

  /**
   * Similar to the above useEffect we need to wait for the comment
   * features to be re-rendered onto the map to then calculate which
   * features to display (which may have changed with add / delete comment)
   */
  useEffect(() => {
    if (map && mapFilter) {
      map.once("idle", (e) => {
        // Wait for the map layer to load then update the comments to render based on map visibility
        updateCommentFeaturesInView();
      });
    }
  }, [comments, mapFilter]);

  /**
   * Monitors for changes to the search & sort order.  If changes have
   * occurred we need to reset the PAGE_SIZE to that it only renders comments
   * that are current visible & we set a timestamp so that search does not
   * hide any newly created replies or comments (for UX)
   */
  useEffect(() => {
    setPageSize(PAGE_SIZE);
  }, [searchText, sortOrder, mapFilter]);

  /**
   * When using the mapFilter we need to filter the comments list to only
   * include comments with features currently visible on the map view.
   */
  const updateCommentFeaturesInView = () => {
    const layerIds = [
      "comments-polygon-line-style",
      "comments-polygon-fill-style",
      "comments-point-style",
      "comments-lineString-style",
      "comments-arrowLine-1-style",
      "comments-arrowLine-2-style",
      "comments-arrowLine-3-style",
      "comments-arrowLine-4-style",
    ];
    const currentFeatures = map?.queryRenderedFeatures(undefined, { layers: layerIds }).map((feature) => feature.id);
    updateComments(comments.filter((comment) => includes(currentFeatures, comment.ID)));
  };

  /**
   * Determines the total number of comments to be rendered & groups them together
   * by date (based on sort order settings).
   */
  const updateComments = (items: Comment[]) => {
    const filteredComments = groupReplies(items, searchText, beforeTimestamp);
    setTotalComments(filteredComments.length);
    setGroupedComments(
      groupBy(filteredComments, (comment) => {
        // If we are sorting by latest activity, the grouping is on the last replies creation date
        // If we are sorting by importance, the grouping is  on the importance level
        // Otherwise we base it on the comments creation date
        if (sortOrder === "latest" && comment.Replies.length > 0) {
          return relativeDate(comment.Replies[comment.Replies.length - 1].CreatedAt);
        }
        if (sortOrder === "importance") {
          return comment.Importance ? startCase(comment.Importance) : "None";
        }
        return relativeDate(comment.CreatedAt);
      })
    );
  };

  // Monitor the visibility of the comments and only render
  // them when they come onto the screen
  useOnScreen(loadingRef, (entry) => {
    if (entry.isIntersecting) {
      setPageSize(pageSize + PAGE_SIZE);
    }
  });

  const onLoadingRefChange = (node: HTMLElement | null) => {
    if (loadingRef?.current !== node) {
      setLoadingRef({ ...loadingRef, current: node });
    }
  };

  /**
   * Renders an individual comment & its replies
   */
  const renderComment = (comment: Comment): JSX.Element | null => {
    return (
      <Box data-testid="comment-item" style={{ fontSize: 12 }}>
        <BaseComment
          comment={comment}
          ref={selectedComment?.ID === comment.ID ? selectedCommentRef : null}
          selected={selectedComment?.ID === comment.ID}
          onClick={handleSelectOnClick}
          onNavigate={handleNavigationOnClick}
        />
      </Box>
    );
  };

  /*
   * Callback function for handling comment navigation
   */
  const handleNavigationOnClick = useCallback(
    (comment: Comment) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      dispatch(selectComment({ commentID: comment.ID, features: comment.Features, showPopup: false }));
      if (map) {
        if (comment.Type === "point") {
          // Move the map to the Point & zoom to an appropriate level
          map.flyTo({
            center: (comment.Features[0].geometry as Point).coordinates as mapboxgl.LngLatLike,
            zoom: Math.max(15, map.getZoom()),
          });
        } else if (includes(["polygon", "lineString", "arrowLine"], comment.Type)) {
          // Determine the Polygon Bounding Box and then fit that within the view
          const bbox = turf.extent({ type: "FeatureCollection", features: comment.Features });
          map.fitBounds(bbox, { padding: 10 });
        }
      }

      event.stopPropagation();
    },
    [map]
  );

  /**
   * Callback function that handles the selection of a comment & its features
   */
  const handleSelectOnClick = useCallback(
    (comment: Comment) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      dispatch(selectComment({ commentID: comment.ID, features: comment.Features }));

      // Navigate to the comment without changing zoom
      if (map && comment.Features.length > 0) {
        const bestPoint = turf.pointOnFeature(comment.Features[0] as AllGeoJSON);
        map.flyTo({
          center: bestPoint.geometry.coordinates as [number, number],
        });
      }
    },
    [map]
  );

  let renderedComments = 0;
  return (
    <>
      {/* Comments are initially grouped by a relative time based on the creation data  */}
      {Object.keys(groupedComments).map((key: string, index) => {
        return renderedComments <= pageSize ? (
          <Box key={`dateGroup_${key}`} className={classes.groupedByWrapper}>
            <Box className={classNames(classes.gropuByIconWrapper, { [classes.groupedByIconFirst]: index === 0 })}>
              {sortOrder === "importance" ? renderImportanceIcon(key.toLowerCase() as CommentImportance) : <Today color="primary" />}
              <Typography variant="subtitle2" color="primary" className={classes.groupedByTitle}>
                {`${key} (${groupedComments[key].length} of ${totalComments})`}
              </Typography>
            </Box>
            <Box width="100%" px={1}>
              {/* Render the comments within the group if the pageSize allows it */}
              {groupedComments[key].map((comment) => {
                renderedComments += 1;
                if (renderedComments > pageSize) return null;
                return (
                  <FadeIn key={comment.ID}>
                    <Box className={classNames(classes.root, { [classes.selected]: selectedComment?.ID === comment.ID })}>
                      {renderComment(comment)}
                    </Box>
                  </FadeIn>
                );
              })}
            </Box>
          </Box>
        ) : null;
      })}

      {totalComments > pageSize ? (
        <div ref={onLoadingRefChange}>
          <BlockSpinner />
        </div>
      ) : (
        <Box display="flex" justifyContent="center" py={2}>
          <Typography variant="caption">No more comments</Typography>
        </Box>
      )}

      {error && (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" className={classes.close} onClick={() => setError(undefined)} size="large">
                <Close />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default withStyles(customStyles)(CommentsList);

/**
 * Determines if a comment or reply has a postive match to the searchText passed
 * Currently we match either the message or the creator's email
 */
const filterItem = (item: Comment | Reply, searchText: string): boolean =>
  item.Content.toLowerCase().includes(searchText.toLowerCase()) ||
  item.Creator.Email.toLowerCase().includes(searchText.toLowerCase()) ||
  item.ID === searchText;

/**
 * Adds to each comment a grouped version of replies.
 * The grouping is a consecutive set of replies that either match or dont match
 * the search term.
 *
 * This allows us to collapse reply chains while still showing matching
 * replies.
 *
 * @param {Array<Comment>} comments The comments to iterate over
 * @param {string} search The search text used to filter comments and replies on
 * @param {string} timestamp The timestamp is  the last time the user changed the searchText.  This is used to prevent grouping of replies if they are edited or added after the initial grouping
 */
export const groupReplies = (comments: Comment[], searchText: string, timestamp: number): Comment[] =>
  comments.reduce((filtered: Comment[], comment: Comment) => {
    // If the comment contains the search criteria or has been edited after the search
    // was conducted make sure it is shown
    const commentMatch = filterItem(comment, searchText) || dayjs(comment.LastModifiedAt).isAfter(dayjs(timestamp));
    let repliesMatch = false;

    const groupedReplies = comment.Replies.reduce((group: Array<Reply[]>, reply: Reply) => {
      const lastSubArray: Reply[] = group[group.length - 1];
      // If the reply contains the search criteria or has been edited / added after the search
      // was conducted make sure it is shown
      const match = filterItem(reply, searchText) || dayjs(reply.LastModifiedAt).isAfter(dayjs(timestamp));
      repliesMatch = repliesMatch || match;
      if (!lastSubArray || filterItem(lastSubArray[lastSubArray.length - 1], searchText) !== match) {
        // Create a new grouping
        group.push([]);
      }

      // Add the reply to the current group
      group[group.length - 1].push({ ...reply, match: !match });
      return group;
    }, []);

    if (commentMatch || repliesMatch) {
      filtered.push({
        ...comment,
        match: commentMatch,
        GroupedReplies: groupedReplies,
      });
    }

    // If comment & replies have not match just return existing filter list
    return filtered;
  }, []);
