import { Alert, alertClasses, Box, IconButton, styled } from "@mui/material";

import { BlockSpinner as Spinner } from "fond/widgets";

import { LegendItemType } from "./Legend";

export const LegendContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  minWidth: 212,
  maxHeight: "100%",
}));

export const LegendInner = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  height: "100%",
  overflowY: "auto",
  paddingLeft: 2,
  paddingRight: 4,
  "& .rct-tree-item-button-GROUP": {
    backgroundColor: "#F5F5F5",
    "&.rct-tree-item-button-selected": {
      backgroundColor: "var(--rct-color-focustree-item-selected-bg)",
    },
  },
}));

export const Row = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>(({ theme, isVisible }) => {
  return {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    fontSize: 12,
    color: isVisible ? "inherit" : theme.palette.action.disabled,
    width: "100%",

    "&.rct-tree-item-title-GROUP": {
      color: isVisible ? theme.palette.biarri.primary.red : theme.palette.action.disabled,
      fontWeight: 700,
      fontSize: 13,
    },
  };
});

export const StyledAlert = styled(Alert)({
  [`& .${alertClasses.message}`]: {
    width: "100%",
  },
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  "& > svg": {
    fontSize: "1.25rem",
  },
});

export const Label = styled(Box, {
  shouldForwardProp: (prop) => prop !== "entityType" && prop !== "isVisible",
})<{ entityType: LegendItemType["Type"]; isVisible: boolean }>(({ theme, entityType, isVisible }) => ({
  fontFamily: theme.typography.fontFamily,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  flexGrow: 1,
  width: "100%",
}));

export const Total = styled(Box)(({ theme }) => ({
  fontSize: 12,
  whiteSpace: "nowrap",
}));

export const VisibilityIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  color: isVisible ? theme.palette.primary.main : theme.palette.action.disabled,
  height: 16,
  paddingInline: theme.spacing(1),
  marginLeft: theme.spacing(0.5),
  "&&&": {
    background: "none",
  },
  "& svg": {
    fontSize: "1rem",
  },
})).withComponent("a");

export const BlockSpinner = styled(Spinner)(({ theme }) => ({}));

BlockSpinner.defaultProps = {
  spinnerProps: { size: 16, color: "inherit" },
  containerProps: { style: { minHeight: 16, height: 16, width: 16 }, width: "24px !important" },
};
