import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Box } from "@mui/material";

import { transformStyleToLayerStyle } from "fond/api";
import { StyleIcon } from "fond/map/Legend/InferredIcon/StyleIcon";
import { LayerClassification, StyleWithoutID } from "fond/types";

import { generateStyleFromClassification } from "./helper";
import { IDefaultStyleFormData } from "./StyleSettings";

const geometryTypeMap: Record<string, string> = {
  LineString: "Line",
  MultiLineString: "Line",
  MultiPoint: "-",
  MultiPolygon: "Fill",
  Null: "-",
  Point: "Circle",
  Polygon: "Fill",
};

interface IProps extends ICellRendererParams<LayerClassification> {
  style: Partial<IDefaultStyleFormData>;
  getRowColor(index: number): string;
}

/**
 * AgGrid cell renderer that renders the Style Icon based on the colorRamp
 * value currently set within the Classify Form.
 */
const CellRenderer = memo((params: IProps) => {
  if (!params.data?.GeometryType) return null;

  // Create a LayerStyle based on the row data & the default style settings.
  const style = transformStyleToLayerStyle({
    ...generateStyleFromClassification(params.data, params.getRowColor(params.node.rowIndex || 0)),
    ...params.style,
  } as StyleWithoutID);

  return (
    <Box sx={{ display: "flex", flexDirection: " row" }}>
      <Box sx={{ width: 24, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <StyleIcon styles={[style]} />
      </Box>
      <span>{geometryTypeMap[params.data.GeometryType]}</span>
    </Box>
  );
});
CellRenderer.displayName = "CellRenderer";

export default CellRenderer;
