import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { MapContext } from "fond/map/MapProvider";
import { getCurrentProjectView, updateProjectView } from "fond/project/redux";
import { MapStyle, Store, View } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import BaseMapStyleToggle from "./BaseMapStyleToggle";

/*
 * A panel for selecting map styles from streets, satellite, hybrid, monochrome
 */
const MapStyleToggle: React.FC = () => {
  const dispatch = useAppDispatch();
  const view = useSelector((state: Store): View => getCurrentProjectView(state.project));
  const { loading } = useSelector((state: Store): View => getCurrentProjectView(state.project));
  const { projectId } = useSelector((state: Store) => state.project);
  const { mapStyle: style, setMapStyle } = useContext(MapContext);

  // Delay close the panel when the mouse is leaving
  const onStyleChangeHandler = (newStyle: MapStyle) => {
    // Only try to update the style when the map is not loading. This is to prevent updating the style multiple times
    // before the map finishes loading, as it may end up hiding the project design content from the map.
    if (!loading && newStyle !== style) {
      dispatch(updateProjectView(projectId, { ...view, loading: true }));
      setMapStyle(newStyle);
    }
  };

  return <BaseMapStyleToggle onChange={onStyleChangeHandler} />;
};

export default MapStyleToggle;
