import React from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { useGetReportQuery, useUpdateReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { OnClickInput } from "fond/widgets";

import { getReportPatchFromReport } from "./util";

const ReportName: React.FC<{ reportId: string }> = ({ reportId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: report } = useGetReportQuery(reportId);
  const reportName = report?.Name || "Untitled report";
  const canRename = permissionCheck(report?.Permission?.Level, Actions.REPORT_RENAME);

  const [updateReport] = useUpdateReportMutation();

  const handleOnCommit = (value: string) => {
    if (report && value !== report.Name) {
      updateReport({
        ...getReportPatchFromReport(report),
        Name: value || "Untitled report",
      })
        .unwrap()
        .then(() => {
          mixpanel.track("Report", "ReportName", "Renamed report", { reportId: report.ID });
          enqueueSnackbar("Report renamed.");
        })
        .catch(() => {
          enqueueSnackbar("Report rename failed. Please try again...");
        });
    }
  };

  return (
    <Box marginRight={0.5}>
      <OnClickInput value={reportName} onCommit={handleOnCommit} readOnly={!canRename} />
    </Box>
  );
};

export default ReportName;
