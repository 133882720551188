import { tableToCsv } from "fond/utils";

export const columns = [
  {
    label: "ID",
    prop: "id",
    type: "text",
    width: "20%",
  },
  {
    label: "Description",
    prop: "description",
    type: "text",
    width: "30%",
  },
  {
    label: "Unit",
    prop: "unit",
    type: "text",
    width: "12%",
  },
  {
    label: "Quantity",
    prop: "count",
    type: "number",
    width: "12%",
  },
  {
    label: "Unit cost",
    prop: "unitCost",
    type: "number",
    width: "12%",
  },
  {
    label: "Total cost",
    prop: "total",
    type: "number",
    width: "14%",
  },
];

/**
 * Given a BoM and a project, returns a filename and a blob. Suitable for use
 * with `file-saver.saveAs`.
 */
export function getCsvFile(bom, project) {
  return {
    filename: `FOND - ${project.ProjectName}-BOM.csv`,
    blob: new Blob([getCsvContent(bom)], { type: "text/plain;charset=utf-8" }),
  };
}

/**
 * Formats a BoM as a CSV (returns a string).
 */
export function getCsvContent(bom) {
  let rows = [];
  rows.push(columns.map((c) => c.label));
  for (let group of bom.groups) {
    for (let rule of group.ruleOutput) {
      rows.push(columns.map((c) => rule[c.prop]));
    }
  }
  return tableToCsv(rows);
}
