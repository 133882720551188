import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";

import { selectCurrentAccount } from "fond/api";
import { useBreadcrumbs } from "fond/projects/Breadcrumbs/hooks/useBreadcrumbs";
import { Store } from "fond/types";

import FolderMenu from "../Folders/FolderMenu";
import CityPlannerRowMenu from "../ItemMenu/CityPlannerRowMenu";

import { CrumbedChip, Crumbs } from "./breadcrumbs.styles";

const MainWorkspaceBreadcrumbs: React.FC = () => {
  const { folderId, reverseAncestors, handleOnClickNavigate } = useBreadcrumbs();
  const { filters } = useSelector((state: Store) => state.projects);
  const currentAccountName = useSelector(selectCurrentAccount)?.Name;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const currentEntity = reverseAncestors[reverseAncestors.length - 1];

  const handleOnClickMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Crumbs>
        <CrumbedChip
          label={currentAccountName}
          deleteIcon={<ExpandMore data-testid="breadcrumb-menu" />}
          onClick={reverseAncestors.length === 0 ? handleOnClickMenu : handleOnClickNavigate("/workspace/")}
          onDelete={reverseAncestors.length === 0 ? handleOnClickMenu : undefined}
          ref={reverseAncestors.length === 0 ? anchorRef : null}
        />
        {reverseAncestors.slice(0, -1).map((folder) => (
          <CrumbedChip key={folder.ID} label={folder.Name} onClick={handleOnClickNavigate(`/folders/${folder.ID}`)} />
        ))}
        {!!reverseAncestors.length && (
          <CrumbedChip
            label={reverseAncestors[reverseAncestors.length - 1].Name}
            deleteIcon={<ExpandMore />}
            ref={anchorRef}
            onClick={handleOnClickMenu}
            onDelete={handleOnClickMenu}
          />
        )}
      </Crumbs>

      {currentEntity?.MultiProject ? (
        <CityPlannerRowMenu
          cityPlannerFolder={reverseAncestors[reverseAncestors.length - 1]}
          onMenuClose={handleClose}
          anchorEl={anchorRef.current}
          open={open}
        />
      ) : (
        <FolderMenu anchorEl={anchorRef.current} folderId={folderId} open={open} onClose={handleClose} filters={filters} />
      )}
    </>
  );
};

export default MainWorkspaceBreadcrumbs;
