import React, { useContext, useMemo, useRef, useState } from "react";
import {
  CellEditingStoppedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ProcessCellForExportParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueSetterParams,
} from "@ag-grid-community/core";
import { Delete, Undo } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";

import { MapContext } from "fond/map/MapProvider";
import { AgGrid } from "fond/widgets";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

import { setFeatureProperty } from "../helper";

import { SubareaProperties } from "./AreaDrawPanel";

interface AreaListProps {
  rowData: SubareaProperties[];
  isDisabled?: boolean;
  onReset(): void;
}

const AreaList: React.FC<AreaListProps> = ({ isDisabled, onReset, rowData }: AreaListProps) => {
  const { map, drawControl } = useContext(MapContext);
  const [selectedNodes, setSelectedNodes] = useState<IRowNode[]>([]);
  const gridApi = useRef<GridApi | null>();

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        flex: 1,
        resizable: false,
        suppressHeaderMenuButton: true,
        editable: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        valueSetter: (params: ValueSetterParams): boolean => {
          const names = rowData.map(({ name }) => name.toLowerCase()).filter((name) => name !== params.oldValue);
          if (isEmpty(params.newValue) || names.includes(params.newValue.toLowerCase())) return false;
          params.data.name = params.newValue;
          return true;
        },
        suppressPaste: true,
      },
      {
        headerName: "Path type",
        field: "importMethod",
        width: 100,
        resizable: false,
        suppressHeaderMenuButton: true,
        valueFormatter: (params: ValueFormatterParams) => (params.value === "area_select_underground" ? "Underground" : "Aerial"),
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: ["area_select_underground", "area_select_aerial"],
          formatValue: (value: string) => (value === "area_select_underground" ? "Underground" : "Aerial"),
        },
      },
    ],
    [rowData]
  );

  if (!map?.hasControl(drawControl.current)) return null;

  /**
   * Callback that allows the user to delete subareas
   */
  const handleOnDelete = () => {
    gridApi.current?.forEachNode((node) => {
      if (node.isSelected()) {
        drawControl.current?.delete(node.data.id);
      }
    });

    map?.fire("draw.delete");
    map?.fire("draw.selectionchange");
  };

  /**
   * Callback function for handling the selection of a row
   */
  const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
    // If the user is attempting to deselect the current version without selecting another then we re-select
    let selection: IRowNode[] = [];
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        selection.push(node);
      }
    });
    setSelectedNodes(selection);
  };

  /**
   * Callback function called when the AgGrid is ready
   */
  const onGridReady = (event: GridReadyEvent) => {
    gridApi.current = event.api;
  };

  // When copying values we want the raw data, not the valueFormatter data
  const processCellForClipboard = (params: ProcessCellForExportParams) => {
    return params.value;
  };

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "normal",
    enableRangeSelection: true,
    processCellForClipboard,
    singleClickEdit: true,
    suppressHeaderFocus: true,
    suppressCellFocus: false,
    suppressCutToClipboard: true,
    rowSelection: "multiple",
    stopEditingWhenCellsLoseFocus: true,
    onCellEditingStopped: (event: CellEditingStoppedEvent) => {
      // Only save the value if a valid change has been made
      if (event.valueChanged) {
        setFeatureProperty(drawControl.current, `${event.data.id}`, event.column.getId(), event.newValue);
      }
    },
  };

  return (
    <>
      <Typography fontWeight="500" sx={{ my: 2 }}>
        Subareas
      </Typography>
      <Box sx={{ pl: 2, pr: 1 }}>
        <DataGridToolbar
          selected={selectedNodes.length}
          title={`${rowData.length} subareas`}
          actions={
            <Tooltip title="Delete">
              <IconButton color="primary" data-testid="delete-row-button" onClick={handleOnDelete} size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          }
          size="small"
        />
      </Box>
      <AgGrid
        columnDefs={columns}
        rowData={rowData}
        gridOptions={gridOptions}
        autoSizeColumns={false}
        containerProps={{ height: 300 }}
        variant="outlined"
        size="compact"
        onSelectionChanged={handleOnSelectionChanged}
        onGridReady={onGridReady}
      />
      <Divider sx={{ mt: 2 }} />
      <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset}>
        <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
          Reset
        </Typography>
      </Button>
      <Divider />
    </>
  );
};

export default AreaList;
