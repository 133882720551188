import React, { useRef, useState } from "react";
import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { keyframes, styled, useTheme } from "@mui/material/styles";
import { ReactComponent as ResizeIcon } from "svg_icons/resize_icon.svg";

interface ResizableBoxProps extends BoxProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The tooltip displayed when hovering on the resizing zone.
   */
  tooltip: string;
  /**
   * The initial height of the box.
   */
  initialHeight?: number;
  /**
   * The minimum height of the resizable box.
   */
  minHeight?: undefined | number;
  /**
   * The maximum height of the resizable box.
   */
  maxHeight?: undefined | number;
}

enum ExpansionState {
  MAX = "MAX",
  SEMI = "SEMI",
  MIN = "MIN",
}

/**
 * The ResizableBox component.
 * A Box component with a draggable top bar that can be used to stretch the vertical height of the outer box.
 *
 *        +-----------------+
 *        |        🡙        |  Clicking the bar and dragging upward expands the body,
 *        +-----------------+
 *        |                 |
 *        |  <>children</>  |  This section expands upward when the top bar is dragged.
 *        |                 |
 *        +-----------------+
 *
 * TODO: This component may be extended with props to allow and resize in any direction, including the diagonals.
 */
const ResizableBox: React.FC<ResizableBoxProps> = ({
  "data-testid": dataTestid = "resize-bar",
  tooltip,
  initialHeight = 100,
  minHeight,
  maxHeight,
  children,
  ...rest
}: ResizableBoxProps) => {
  const theme = useTheme();

  // Refs.
  const containerRef = useRef<HTMLElement>(null);

  // States.
  const [outOfBounds, setOutOfBounds] = useState(false);
  const [height, setHeight] = useState<number>(initialHeight as number);
  const [resizing, setResizing] = useState(false);

  const [expansionState, setExpansionState] = useState(ExpansionState.MIN);

  /**
   * Handles the drag and drop resize action.
   */
  const handleResize = (startEvent: React.MouseEvent<HTMLDivElement>) => {
    // Disable default behaviour.
    startEvent.stopPropagation();
    startEvent.preventDefault();

    // Set the onResizing state.
    setResizing(true);

    // Keep track of the starting height and cursor position.
    const startHeight = (containerRef.current as HTMLElement).clientHeight;
    const startPosition = startEvent.pageY;

    // Handle rerenders.
    const onMouseMove = (updateEvent: MouseEvent) => {
      // Calculate the height that the user input is requesting.
      const delta = startPosition - updateEvent.pageY;
      const requestedHeight = startHeight + delta;

      // The actual height cannot be less than the minHeight or greater than the maxHeight.
      let newHeight = requestedHeight;
      if (minHeight) {
        newHeight = Math.max(requestedHeight, minHeight);
      }
      if (maxHeight) {
        newHeight = Math.min(requestedHeight, maxHeight);
      }

      // Save on re-renders by only updating every 2 pixels or if on the min/max height.
      if (newHeight % 2 === 0 || [minHeight, maxHeight].includes(newHeight)) {
        setHeight(newHeight);

        // Display an out of bounds warning if the component refuses to grow to the requested size.
        const updatedHeight = (containerRef.current as HTMLElement).clientHeight;
        setOutOfBounds(Math.abs(updatedHeight - requestedHeight) > 30);

        if (Math.abs(updatedHeight - requestedHeight) > 5) {
          if (updatedHeight - requestedHeight > 0) {
            setExpansionState(ExpansionState.MIN);
          } else {
            setExpansionState(ExpansionState.MAX);
          }
        } else {
          setExpansionState(ExpansionState.SEMI);
        }
      }
    };

    // Handle releasing the drag.
    const onMouseUp = (endEvent: MouseEvent) => {
      // Remove out of bounds indicator.
      setOutOfBounds(false);

      // Set the final height
      setHeight((containerRef.current as HTMLElement).clientHeight + 2);

      // Remove event listener.
      window.removeEventListener("mousemove", onMouseMove);

      // Set the onResizing state.
      setResizing(false);
    };

    // Add drag and drop event listeners.
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp, { once: true });
  };

  return (
    <Box data-testid={dataTestid} maxHeight={height} minHeight={minHeight} ref={containerRef} {...rest}>
      <Tooltip title={tooltip}>
        <Box
          display="flex"
          justifyContent="center"
          minHeight="15px"
          onMouseDown={handleResize}
          sx={{
            width: "100%",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            backgroundColor: resizing ? `${outOfBounds ? theme.palette.biarri.secondary.orange : theme.palette.biarri.secondary.blue}50` : "unset",
            cursor: "ns-resize",
            "&:hover": {
              backgroundColor: `${theme.palette.biarri.secondary.blue}50`,
            },
          }}
        >
          {expansionState === ExpansionState.MAX && <ArrowDownIcon style={{ fontSize: 15 }} />}
          {expansionState === ExpansionState.SEMI && <ResizeIcon style={{ height: 11, color: theme.palette.common.black }} />}
          {expansionState === ExpansionState.MIN && <BouncingUpIcon style={{ fontSize: 15 }} />}
        </Box>
      </Tooltip>
      {children}
    </Box>
  );
};

const bounceTransition = keyframes`
  from {
    transform: translateY(-1px);
  }

  to {
    transform: translateY(1px);
  }
`;

const BouncingUpIcon = styled(ArrowUpIcon)(({ theme, style }) => {
  return {
    animation: `${bounceTransition} 0.5s`,
    animationDirection: "alternate",
    animationIterationCount: "infinite",
    ...style,
  };
});

export default ResizableBox;
