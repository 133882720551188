import { useEffect, useMemo, useState } from "react";
import { useField } from "react-final-form";
import { Box, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, Typography } from "@mui/material";
import { capitalize, compact } from "lodash";

import { NumericField } from "fond/form/fields/NumericField";
import { StepFrequency } from "fond/types";
import { formatUnit } from "fond/utils";
import { requiredWithMessage } from "fond/utils/validation";

import { ReportFormData } from "../../types";

import { FieldGridItem, GridRow, LabelGridItem, ValidationOnlyTextField } from "./ResourceAllocationFields.styles";

export type ResourceAllocationFieldsProps = {
  disabled?: boolean;
};

/**
 * When compacting the resource allocation values we also explicitly want to skip the value "0".
 * This is because the user can enter a value of 0 and then blur the field, which will set the value to "0" before
 * the field is then parsed as a number and set to 0.
 *
 * @param arr - array of resource allocation values
 * @returns
 */
const compactCombinedValue = (arr: Array<string | number | undefined>) => {
  return compact(arr)
    .filter((v) => v !== "0")
    .join();
};

const ResourceAllocationFields: React.FC<ResourceAllocationFieldsProps> = ({ disabled = false }: ResourceAllocationFieldsProps) => {
  const {
    input: { onChange },
  } = useField("ReportConfiguration.ResourceAllocation.ExpansionRateCombinedValue");
  const {
    input: { value: stepFreq },
  } = useField<ReportFormData["ReportConfiguration"]["StepFrequency"]>("ReportConfiguration.StepFrequency");
  const {
    input: { value: resourceAllocation },
  } = useField<ReportFormData["ReportConfiguration"]["ResourceAllocation"]>("ReportConfiguration.ResourceAllocation");
  const {
    input: { value: project },
  } = useField("Project");

  const [unit, setUnit] = useState<string>();
  const stepFrequency = useMemo(() => capitalize(StepFrequency[stepFreq]), [stepFreq]);

  useEffect(() => {
    setUnit(formatUnit(project?.SystemOfMeasurement));
  }, [project]);

  useEffect(() => {
    // if the user has set the value of the expansion rate, we set the value of the
    // hidden field that serves as the validation for the group
    const resourceAllocationValues = [
      resourceAllocation?.FibreBudget,
      resourceAllocation?.PathBudget,
      resourceAllocation?.AddressBudget,
      resourceAllocation?.MonetaryBudget,
    ];
    onChange(compactCombinedValue(resourceAllocationValues));
  }, [
    resourceAllocation?.FibreBudget,
    resourceAllocation?.PathBudget,
    resourceAllocation?.AddressBudget,
    resourceAllocation?.MonetaryBudget,
    onChange,
  ]);

  return (
    <Box flex={1}>
      <FormGroup data-testid="resource-allocation-expansion-rate">
        <FormLabel required>Deployment expansion rate</FormLabel>
        <FormHelperText>Enter a value for at least one deployment type</FormHelperText>
        <GridRow container mt={2}>
          <LabelGridItem item>
            <FormLabel htmlFor="ReportConfiguration.ResourceAllocation.FibreBudget">Fiber</FormLabel>
          </LabelGridItem>
          <FieldGridItem item>
            <NumericField
              name="ReportConfiguration.ResourceAllocation.FibreBudget"
              InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment> }}
              inputProps={{ "aria-label": "Fiber deployment rate" }}
              size="small"
              margin="none"
              min={0}
              disabled={disabled}
            />
          </FieldGridItem>
          <StepFrequencyGridItem stepFrequency={stepFrequency} />
        </GridRow>
        <GridRow container>
          <LabelGridItem item>
            <FormLabel htmlFor="ReportConfiguration.ResourceAllocation.PathBudget">Linear path</FormLabel>
          </LabelGridItem>
          <FieldGridItem item>
            <NumericField
              name="ReportConfiguration.ResourceAllocation.PathBudget"
              InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment> }}
              inputProps={{ "aria-label": "Path deployment rate" }}
              size="small"
              margin="none"
              min={0}
              disabled={disabled}
            />
          </FieldGridItem>
          <StepFrequencyGridItem stepFrequency={stepFrequency} />
        </GridRow>
        <GridRow container>
          <LabelGridItem item>
            <FormLabel htmlFor="ReportConfiguration.ResourceAllocation.AddressBudget">Service locations</FormLabel>
          </LabelGridItem>
          <FieldGridItem item>
            <NumericField
              name="ReportConfiguration.ResourceAllocation.AddressBudget"
              inputProps={{ "aria-label": "Service location deployment rate" }}
              size="small"
              margin="none"
              min={0}
              disabled={disabled}
            />
          </FieldGridItem>
          <StepFrequencyGridItem stepFrequency={stepFrequency} />
        </GridRow>
        <GridRow container>
          <LabelGridItem item>
            <FormLabel htmlFor="ReportConfiguration.ResourceAllocation.MonetaryBudget">Total cash budget</FormLabel>
          </LabelGridItem>
          <FieldGridItem item>
            <NumericField
              name="ReportConfiguration.ResourceAllocation.MonetaryBudget"
              InputProps={{ startAdornment: <InputAdornment position="end">$</InputAdornment> }}
              inputProps={{ sx: { paddingLeft: 0.5 }, "aria-label": "Total cash budget" }}
              size="small"
              margin="none"
              min={0}
              disabled={disabled}
            />
          </FieldGridItem>
          <StepFrequencyGridItem stepFrequency={stepFrequency} />
        </GridRow>
        <ValidationOnlyTextField
          type="hidden"
          name="ReportConfiguration.ResourceAllocation.ExpansionRateCombinedValue"
          validate={requiredWithMessage("You must choose at least one deployment and set the expansion rate for it")}
        />
      </FormGroup>
    </Box>
  );
};

const StepFrequencyText = ({ stepFrequency }: { stepFrequency: string }) => <Typography>per {stepFrequency}</Typography>;
const StepFrequencyGridItem = ({ stepFrequency }: { stepFrequency: string }) => (
  <Grid item xs={3} alignContent="center">
    <StepFrequencyText stepFrequency={stepFrequency} />
  </Grid>
);
export default ResourceAllocationFields;
