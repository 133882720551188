import PropTypes from "prop-types";

import { getIn } from "fond/utils";

import ArchitectureField from "./ArchitectureField";

export default function TopologyFields({ hook, path, fieldDescriptions }) {
  const paths = {
    Type: [...path, "Type"],
    ParallelCableThreshold: [...path, "ParallelCableThreshold"],
    LoopingCableThreshold: [...path, "LoopingCableThreshold"],
  };

  return (
    <>
      <ArchitectureField
        hook={hook}
        label="Parallel cable threshold"
        type="length"
        path={paths.ParallelCableThreshold}
        helpText={getIn(fieldDescriptions, paths.ParallelCableThreshold)}
        required
        width={192}
      />

      <ArchitectureField
        hook={hook}
        label="Looping cable threshold"
        type="length"
        path={paths.LoopingCableThreshold}
        helpText={getIn(fieldDescriptions, paths.LoopingCableThreshold)}
        required
        width={192}
      />
    </>
  );
}

TopologyFields.propTypes = {
  hook: PropTypes.array.isRequired,
  path: PropTypes.array.isRequired,
  fieldDescriptions: PropTypes.object.isRequired,
};
