import styled from "@emotion/styled";

import { AccountSelectionButton } from "fond/topBar/accountMenuItems.styles";

export const AccountFilterButton = styled(AccountSelectionButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.05)",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
  },
}));
