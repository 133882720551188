import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Add } from "@mui/icons-material";
import { Box, Divider, FormHelperText, Switch } from "@mui/material";

import { ConfigAttribute } from "fond/types";
import { FilterConfiguration } from "fond/types/ProjectLayerConfig";

import { Documentation } from "../Documentation";
import { FormLabel } from "../styles";

import GroupFilterSelect from "./GroupFilterSelect";
import SingleFilterForm from "./SingleFilterForm";

import { Button, CustomHelperText, MainContainer } from "./sublayerFilterField.styles";

interface IProps {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
  attributes?: ConfigAttribute[];
}

type SublayerFilterFieldWrapperProps = FieldRenderProps<FilterConfiguration | null, HTMLElement> & { attributes: ConfigAttribute[] };

const SublayerFilterFieldWrapper: React.FC<SublayerFilterFieldWrapperProps> = ({ input: { onChange, value: rawValue }, attributes }) => {
  const value = rawValue || { Mapbox: [], Type: "expression" };
  const addFilter = () => {
    const defaultGroupOperator = "all";
    const newFilter = {
      Mapbox: [],
      Operator: null,
      Type: "expression",
      Values: [{ Attribute: null, Literal: null, SliceEnd: null, SliceStart: null }],
      ValueKey: null,
    };
    if (!value) {
      onChange(newFilter);
      return;
    }
    // if single expression, switch to group
    if (value.Type === "expression") {
      onChange({
        Expressions: [value, newFilter],
        Mapbox: [defaultGroupOperator, value.Mapbox, newFilter.Mapbox],
        Operator: defaultGroupOperator,
        Type: "group",
      });
    } else {
      onChange({
        ...value,
        Expressions: [...(value.Expressions ?? []), newFilter],
        Mapbox: [...value.Mapbox, newFilter],
      });
    }
  };

  const generateExpressionMapbox = (expression: FilterConfiguration) => {
    const { Operator: operator, Values: values, ValueKey: valueKey, Negate: negate } = expression;
    const attributeName = values?.[0].Attribute?.Name;

    /**
     * "contains" operator is essentially "in" operator,
     * but we need to invert the query array and value key in the mapbox array
     * SAMPLE RESULT:
     * contains: ["in", "value", ["get", "ID"]]
     * others: ["==", ["get", "ID"], "value"]
     */
    const mapbox = operator === "contains" ? ["in", valueKey, ["get", attributeName]] : [operator, ["get", attributeName], valueKey];

    return negate ? ["!", mapbox] : mapbox;
  };

  const generateGroupMapbox = (filterConfiguration: FilterConfiguration) => {
    const expressionsMapboxValues = filterConfiguration?.Expressions?.map(generateExpressionMapbox) ?? [];
    const groupMapbox = [filterConfiguration.Operator, ...expressionsMapboxValues];
    if (filterConfiguration.Negate) return ["!", groupMapbox];
    return groupMapbox;
  };

  return (
    <MainContainer className="customScrollbars" data-testid="sublayer-filter-field">
      <FormLabel sx={{ mt: 2 }}>Other</FormLabel>
      <CustomHelperText>
        Make this an “other” sublayer, which lists all features not captured in the filters defined under this layer. Only one sublayer can have this
        turned on per layer
      </CustomHelperText>
      <Box mb={2}>
        <Switch
          size="small"
          checked={value.Type === "other"}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            onChange(checked ? { Type: "other" } : null);
          }}
        />
      </Box>

      {value.Type !== "other" && (
        <>
          <FormLabel>Set provider filter</FormLabel>
          <CustomHelperText>Only features that match the filter are displayed</CustomHelperText>
          <Box my={2} width="170px">
            {value?.Type === "group" && (
              <GroupFilterSelect
                value={(value.Operator === "any" && value.Negate ? "none" : value.Operator) ?? ""}
                onChange={(newValue) => {
                  const updatedValue = {
                    ...value,
                    ...(newValue === "none" ? { Operator: "any", Negate: true } : { Operator: newValue, Negate: false }),
                  };
                  const updatedMapbox = generateGroupMapbox(updatedValue);
                  onChange({ ...updatedValue, Mapbox: updatedMapbox });
                }}
              />
            )}
          </Box>
          {value &&
            [...(value?.Type === "group" ? (value.Expressions ?? []) : [value])].map((expression: FilterConfiguration, index: number) => (
              <SingleFilterForm
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                expression={expression}
                filterIndex={index}
                attributes={attributes}
                generateExpressionMapbox={generateExpressionMapbox}
                generateGroupMapbox={generateGroupMapbox}
                value={value}
                onChange={onChange}
              />
            ))}
          <Button data-testid="add-filter-btn" onClick={addFilter} startIcon={<Add />} size="small">
            Add filter value
          </Button>
          <Divider sx={{ my: 2 }} />
        </>
      )}

      <FormHelperText component="div">
        <Documentation
          fieldSpec={{
            doc:
              value.Type === "other"
                ? "A dynamic filter on source features. Only features that don't match other sublayer filters within this layer are displayed."
                : "An expression specifying conditions on source features. Only features that match the filter are displayed. Combine multiple filters together by using a compound filter.",
          }}
        />
      </FormHelperText>
    </MainContainer>
  );
};

const SublayerFilterField: React.FC<IProps> = ({ name, fieldProps, validate, attributes, ...rest }: IProps) => (
  <Field
    name={name}
    render={({ input, meta }) => <SublayerFilterFieldWrapper input={input} name={name} meta={meta} attributes={attributes || []} {...rest} />}
    validate={validate}
    {...fieldProps}
  />
);

export default SublayerFilterField;
