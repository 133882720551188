import { FeatureCollection } from "geojson";

import { Architecture, DemandConfiguration, DemandTier } from "../../types";

/**
 * Return address types in the given architecture, or null if the architecture doesn't have any demand configurations.
 * Optionally can filter out the AddressTypes marked to ignore.
 */
export const getArchitectureAddressTypes = ({
  architecture,
  excludeIgnore = false,
}: {
  architecture: Architecture;
  excludeIgnore?: boolean;
}): string[] | null => {
  const demandConfigurations = architecture.Demand?.DemandConfigurations ?? [];
  if (demandConfigurations.length === 0) {
    return null;
  }

  return demandConfigurations.filter((cfg) => cfg.AddressType !== null && (!excludeIgnore || !cfg.Ignore)).map((cfg) => cfg.AddressType as string);
};

/**
 * Return all unique address types within the given FeatureCollection.
 */
const getAddressTypes = (
  featureCollection: FeatureCollection,
  addressTypeField: string
): {
  addressTypes: Set<string>;
  addressTypeFieldPresent: boolean;
} => {
  const addressTypes: Set<string> = new Set();
  let addressTypeFieldPresent = false;

  for (const feature of featureCollection.features) {
    const addressType = feature.properties?.[addressTypeField];
    addressTypeFieldPresent = addressTypeFieldPresent || addressType !== undefined;
    if (addressType !== undefined) {
      const addressTypeString = addressType ? addressType.toString() : "";
      addressTypes.add(addressTypeString);
    }
  }

  return { addressTypes, addressTypeFieldPresent };
};

/**
 * Return a sorted array of all unique address types in `featureCollection` that aren't in `knownAddressTypes`.
 */
export const getUnknownAddressTypes = ({
  featureCollection,
  knownAddressTypes,
  addressTypeField = "AddressType",
}: {
  featureCollection: FeatureCollection;
  knownAddressTypes: string[];
  addressTypeField?: string;
}): { unknownAddressTypes: string[]; addressTypeFieldPresent: boolean } => {
  let { addressTypes, addressTypeFieldPresent } = getAddressTypes(featureCollection, addressTypeField);
  for (const addressType of knownAddressTypes) {
    addressTypes.delete(addressType);
  }

  const unknownAddressTypes = Array.from(addressTypes.values());
  unknownAddressTypes.sort();
  return { unknownAddressTypes, addressTypeFieldPresent };
};

/**
 * Return a sorted array of all unique available feature properties within the given FeatureCollection.
 */
export const getFeatureProperties = (featureCollection: FeatureCollection): string[] => {
  let featurePropertiesSet = new Set<string>();
  featureCollection?.features.map(
    (feature) => feature?.properties && Object.keys(feature?.properties).forEach((property) => featurePropertiesSet.add(property))
  );
  return Array.from(featurePropertiesSet).sort();
};

type Demand = "T1_DEMAND" | "T2_DEMAND" | "T2_EXP_DEMAND" | "T3_DEMAND" | "";
const TierMap = {
  [DemandTier.DROP]: "T1_DEMAND",
  [DemandTier.DISTRIBUTION]: "T2_DEMAND",
  [DemandTier.DISTRIBUTION_BYPASS_SPLITTERS]: "T2_EXP_DEMAND",
  [DemandTier.FEEDER]: "T3_DEMAND",
} as { [key in DemandConfiguration["Tier"]]: Demand };

/**
 * Return the NumFibers and Type values based on the address type value
 */
export const getAddressTypeValues = (
  demandConfigurations: DemandConfiguration[],
  addressType: string
): { NumFibers: DemandConfiguration["NumFibers"]; Type: Demand; SubTypes: "express" | "" } | null => {
  const demandRow = demandConfigurations.find((row) => row.AddressType === addressType);

  if (demandRow) {
    return {
      NumFibers: demandRow.NumFibers,
      Type: TierMap[demandRow.Tier],
      SubTypes: demandRow.Tier === DemandTier.DISTRIBUTION_BYPASS_SPLITTERS ? "express" : "",
    };
  }

  return null;
};
