import { ValueFormatterParams } from "@ag-grid-community/core";

import { formatBytes } from "fond/utils";

/**
 * valueRenderer that formats file size
 */
const fileSizeValueFormatter = (params: ValueFormatterParams): string => {
  return formatBytes(params.value);
};

export default fileSizeValueFormatter;
