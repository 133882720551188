import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";

import { BomTagAutocompleteOption } from "fond/architecture/bom/widgets/BomTagAutocomplete";

export type BomStoreState = {
  tags: BomTagAutocompleteOption[];
};

const DEFAULT_TAGS = ["Labor", "Material", "Labor and Materials"];
const initialState: BomStoreState = {
  tags: DEFAULT_TAGS.map((value): BomTagAutocompleteOption => ({ value, title: value })),
};

export const bomTagSlice = createSlice({
  name: "bomTag",
  initialState,
  reducers: {
    addTag: (state, action: PayloadAction<BomTagAutocompleteOption>) => {
      if (state.tags.find((tag) => tag.value === action.payload.value)) {
        return state;
      }
      return {
        ...state,
        tags: [...state.tags, action.payload],
      };
    },
    addTags: (state, action: PayloadAction<BomTagAutocompleteOption[]>) => {
      // dedupe the set of tags first before adding
      const tags = uniqBy([...state.tags, ...action.payload], "value");

      return {
        ...state,
        tags,
      };
    },
    reset: () => initialState,
  },
  // TODO: See if it's feasible to upgrade to RTK 2.x and use the `selectors` field
  // selectors: {
  //   getTags: (state: BomStoreState) => state.tags,
  // },
});
export const { addTag, addTags, reset } = bomTagSlice.actions;
