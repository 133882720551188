import { Lock as MuiLockIcon } from "@mui/icons-material";
import { Box, Grid, ListItemIcon as MuiListItemIcon, MenuItem as MuiMenuItem, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: grey[100],
  height: "100%",
  padding: theme.spacing(1),
}));

interface MenuItemProps extends React.ComponentProps<typeof MuiMenuItem> {
  isDisabled?: boolean;
}

export const MenuItem = styled(({ isDisabled, ...rest }: MenuItemProps) => <MuiMenuItem {...rest} />)(({ theme, isDisabled = false }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  paddingLeft: theme.spacing(5),
  marginBottom: theme.spacing(0.5),
  fontSize: 14,
  color: isDisabled ? theme.palette.text.disabled : undefined,
  cursor: isDisabled ? "default" : "pointer",
  "&.Mui-selected": {
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.05)",
    "& .MuiListItemIcon-root": {
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
  },
  "&:hover": {
    backgroundColor: isDisabled ? theme.palette.biarri.secondary.whiteSmoke : "rgba(255, 255, 255, 0.6)",
  },
}));

export const MenuItemContainer = styled(Box)({
  width: "100%",
});

export const GridItem = styled(Grid)({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export const NavigationHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  "& > div": {
    marginRight: theme.spacing(1.5),
  },
}));

export const AvatarTitle = styled(Typography)({
  fontWeight: 600,
});

AvatarTitle.defaultProps = {
  noWrap: true,
};

interface ListItemIconProps extends React.ComponentProps<typeof MuiListItemIcon> {
  isDisabled?: boolean;
}

export const ListItemIcon = styled(({ isDisabled, ...rest }: ListItemIconProps) => <MuiListItemIcon {...rest} />)(({ isDisabled = false }) => ({
  "& svg": {
    fontSize: "1.5rem !important",
    color: isDisabled ? "rgba(0, 0, 0, 0.25)" : undefined,
  },
}));

export const LockIcon = styled(MuiLockIcon)({
  fontSize: "1rem",
  color: "rgba(0, 0, 0, 0.25)",
});
