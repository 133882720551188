import React from "react";
import { Box, Table, TableBody, TableCell } from "@mui/material";

import { LayerIds } from "fond/layers";
import { Attribute, LayerId, Project, Version } from "fond/types";
import { formatUnit } from "fond/utils";
import { Actions, permissionCheck } from "fond/utils/permissions";

import AddressPreferenceEdit from "./AddressPreferenceEdit";
import PathPreferenceEdit from "./PathPreferenceEdit";

import { PropertyNameCell, TableRow } from "./propertiesPanel.styles";

export interface IProps {
  project: Project;
  layerPropertiesSchema?: Attribute[];
  editableProperties: string[];
  layerId: LayerId;
  version?: Version;
  featureProperties: Record<string, string | number>;
  architectureAddressTypes: string[] | null;
  setFieldValues(updates: Record<string, string | number>): void;
  lengthFields: string[];
  isFetching: boolean;
}

const FeaturePropertiesTable: React.FC<IProps> = ({
  project,
  layerPropertiesSchema,
  editableProperties,
  layerId,
  version,
  featureProperties,
  architectureAddressTypes,
  setFieldValues,
  lengthFields,
  isFetching,
}) => {
  const canEdit = permissionCheck(project.Permission.Level, Actions.PROJECT_EDIT);

  /**
   * Sanitize the feature property value to string. The backend allows
   * for JSON to be set as the value which needs to be handled.
   */
  const sanitizedToString = (value: unknown): string => {
    if (value === undefined || value === null) return "-";

    if (typeof value === "object") return JSON.stringify(value);

    return value.toString();
  };

  return (
    <Box flex={1} overflow="hidden auto" className="customScrollbars" data-testid="feature-details">
      <Table size="small">
        <TableBody>
          {canEdit && editableProperties.length > 0 && layerId === LayerIds.inAddress && (
            <AddressPreferenceEdit
              demandConfigurations={version?.Architecture?.Demand?.DemandConfigurations}
              addressTypeField={version?.AddressTypeField}
              properties={featureProperties}
              addressTypes={architectureAddressTypes}
              onChange={setFieldValues}
            />
          )}
          {canEdit && editableProperties.length > 0 && (layerId === LayerIds.inSpan || layerId === LayerIds.inStreet) && (
            <PathPreferenceEdit properties={featureProperties} onChange={setFieldValues} />
          )}
          {layerPropertiesSchema?.map((propertySchema) => {
            // Address is handled by AddressPreferenceEdit above
            if (layerId === LayerIds.inAddress && propertySchema.Name === version?.AddressTypeField) return null;
            const value = sanitizedToString(featureProperties?.[propertySchema.Name]);
            return (
              <TableRow key={propertySchema.Name} data-testid="feature-property-row">
                <PropertyNameCell>{propertySchema.Name}</PropertyNameCell>
                <TableCell>
                  {lengthFields.includes(propertySchema.Name) ? (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <span data-testid="length-value">{value}</span>
                      <span data-testid="length-unit" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        {formatUnit(project.SystemOfMeasurement)}
                      </span>
                    </Box>
                  ) : (
                    <span data-testid="table-property">{isFetching ? "Loading..." : value}</span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default FeaturePropertiesTable;
