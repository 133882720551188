import { FilterConfiguration, SublayerConfig } from "fond/types/ProjectLayerConfig";

// For FilterConfigurations that have not set a filter we show all features
// This blank filter will match all features
const BLANK_FILTER = ["!", false];

/**
 * Builds a Mapbox filter based on the negative of other sublayer FilterConfigurations
 * @returns FilterConfiguration
 */
export const negativeFilterConfiguration = (sublayers: SublayerConfig[]): FilterConfiguration["Mapbox"] => {
  return ["!", ["any", ...sublayers.map(({ FilterConfiguration: filter }) => filter?.Mapbox || BLANK_FILTER)]];
};
