import { Container, styled, Typography } from "@mui/material";

export const Content = styled(Container)(({ theme }) => ({
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(3),
}));

export const AccountName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  letterSpacing: 2.5,
  color: theme.palette.secondary.main,
}));
