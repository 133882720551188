import { useState } from "react";
import { GridApi } from "@ag-grid-community/core";
import { Box } from "@mui/material";

import { useLicenseInfoList } from "fond/settings/AccountManagement/useLicenseInfoList";
import { Subscription } from "fond/types";
import { AgGrid } from "fond/widgets";

interface LicenseInfoListProps {
  accountSubscriptions: Subscription[] | undefined;
}

const LicenseInfoList: React.FC<LicenseInfoListProps> = ({ accountSubscriptions }: LicenseInfoListProps) => {
  const [_, setGridApi] = useState<GridApi>();
  const { columns, gridOptions } = useLicenseInfoList();

  return (
    <Box flexGrow={1} data-testid="license-info-list" mb={3}>
      <AgGrid
        columnDefs={columns}
        rowData={accountSubscriptions ?? null}
        gridOptions={gridOptions}
        onGridReady={(params) => setGridApi(params.api)}
        autoSizeColumns={false}
        variant="outlined"
      />
    </Box>
  );
};

export default LicenseInfoList;
