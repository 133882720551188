import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";

import NewPasswordForm from "fond/signIn/NewPasswordForm";
import { Wave } from "fond/svg_icons";
import { PageCentred } from "fond/widgets/PageCentred";

import SignInForm from "./SignInForm";
import SignUpAdornment from "./SignUpAdornment";

/**
 * The top level component for the sign in route. Presents the SignInForm in a SignInContainer.
 * In the event that cognitoUser is set, then the NewPasswordForm is displayed instead.
 * cognitoUser can be set by the SignInForm using the onNewPasswordRequired callback.
 */
const SignIn: React.FC = () => {
  // the cognito user object for the current user. Required by the NewPasswordForm.
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();

  return (
    <PageCentred
      title="Log in"
      description={
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography>Welcome back!</Typography>
          <Wave />
        </Box>
      }
      rightAdornment={<SignUpAdornment />}
    >
      {cognitoUser ? <NewPasswordForm cognitoUser={cognitoUser} /> : <SignInForm onNewPasswordRequired={setCognitoUser} />}
    </PageCentred>
  );
};

export default SignIn;
