import React, { useCallback, useContext, useEffect, useState } from "react";
import { Mouse } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { MapContext } from "fond/map/MapProvider";
import { BlockSpinner, SvgIcon } from "fond/widgets";

const customStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${theme.palette.background.sidebar.primary}a6`,
      color: theme.palette.common.white,
      fontSize: 12,
      height: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  });

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Whether data is loading in the background
   */
  loading?: boolean;
  /**
   * The distance being measured using the ruler
   */
  distance: string | undefined;
}

let timeout: number;

const Footer: React.FC<IProps> = ({ classes, distance, loading }: IProps) => {
  const [showClickedCoords, setShowClickedCoords] = useState(false);
  const [mouseCoords, setMouseCoords] = useState<mapboxgl.LngLat | undefined>(undefined);
  const [clickedCoords, setClickedCoords] = useState<mapboxgl.LngLat | undefined>(undefined);
  const { map } = useContext(MapContext);

  useEffect(() => {
    setMouseCoords(map?.getCenter());
    map?.on("mousemove", handleOnMouseMove);
    map?.on("click", handleOnMapClick);
    return () => {
      map?.off("mousemove", handleOnMouseMove);
      map?.off("click", handleOnMapClick);
    };
  }, [map]);

  /**
   * We monitor the clicked coords & when they change we display those
   * coordinates for several seconds to allow the user to copy them.
   */
  useEffect(() => {
    if (clickedCoords) {
      handleCoordReset();
      setShowClickedCoords(true);
    }
  }, [clickedCoords]);

  /**
   * Sets a timeout to reset the coordinates back to the mouse location
   */
  const handleCoordReset = () => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      setShowClickedCoords(false);
    }, 5000);
  };

  /**
   * Handles the click event for the map.
   * Callback is used to update the function when map or editMode changes
   */
  const handleOnMapClick = useCallback((event: mapboxgl.MapMouseEvent) => {
    setClickedCoords(event.lngLat);
  }, []);

  /**
   * Handles the mouse move event for the map.
   */
  const handleOnMouseMove = useCallback((event: mapboxgl.MapMouseEvent) => {
    setMouseCoords(event.lngLat);
  }, []);

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {distance && (
          <Box display="flex" alignItems="center">
            <SvgIcon icon="ruler" />
            <Box mx={1} whiteSpace="nowrap">
              {`Distance: ${distance}`}
            </Box>
          </Box>
        )}
        {((loading ?? false) || !map?.areTilesLoaded()) && (
          <Box display="flex">
            <BlockSpinner containerProps={{ minHeight: 0 }} spinnerProps={{ style: { height: 16, width: 16, color: "rgb(232, 234, 237)" } }} />
            <Box mx={1} whiteSpace="nowrap">
              Loading design data...
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex">
        {showClickedCoords ? (
          <>
            <Mouse style={{ width: 16, height: 16, marginRight: 4 }} />
            {clickedCoords ? `${clickedCoords.lat?.toFixed(6)}, ${clickedCoords.lng?.toFixed(6)}` : " - "}
          </>
        ) : (
          <>{mouseCoords && `${mouseCoords.lat?.toFixed(6)}, ${mouseCoords.lng?.toFixed(6)}`}</>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(customStyles)(Footer);
