import React, { createRef, RefObject, useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useSelector } from "react-redux";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import CSVFileValidator from "csv-file-validator";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useSnackbar } from "notistack";

import { useGetAttachmentWhitelistQuery } from "fond/api";
import { BulkFileProps, Store } from "fond/types";
import { Dropzone, ListItemScrollNotification, MimeTypeIcon } from "fond/widgets";

import BulkAttachmentListItem from "./BulkAttachmentListItem";
import { MappingData, mappingFileConfig, ValidatedFile, validateFiles, validateMappingFile } from "./bulkAttachmentValidation";
import InvalidMappingFileModal from "./InvalidMappingFileModal";
import RejectedFilesModal from "./RejectedFilesModal";
import RejectedMappingFileModal from "./RejectedMappingFileModal";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      height: "100%",
    },
    filesList: {
      height: "100%",
      maxHeight: 250,
      overflowY: "auto",
    },
    alert: {
      margin: "0 10px",
      padding: "0px 10px",
      width: 215,
    },
  });
};

export interface BulkAttachmentMappingProps extends WithStyles<typeof customStyles> {
  /**
   * Callback function that returns the staged files when the stage is ready for upload.
   */
  onAcceptedStage(file: BulkFileProps[]): void;
  stagedFiles: BulkFileProps[];
  setIsReadyToUpload(isReady: boolean): void;
}

const BulkAttachmentMapping: React.FC<BulkAttachmentMappingProps> = ({
  classes,
  onAcceptedStage,
  stagedFiles,
  setIsReadyToUpload,
}: BulkAttachmentMappingProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: attachmentWhitelist } = useGetAttachmentWhitelistQuery();
  const versionId = useSelector((state: Store) => state.project.versionId);

  const theme = useTheme();

  /*
   * ListItemScrollNotification control.
   * Track references to the file list and the first file to fail validation (if exists).
   * These references are passed to the ListItemScrollNotification to point the invalid file when off screen.
   */
  const [invalidFileRef, setInvalidFileRef] = useState<RefObject<HTMLElement>>(createRef<HTMLElement>());
  const listRef = createRef<HTMLUListElement>();
  const onRefChange = (node: HTMLElement | null) => {
    if (invalidFileRef?.current !== node) {
      setInvalidFileRef({ ...invalidFileRef, current: node });
    }
  };

  /*
   * File validation control.
   */
  const [mappingFile, setMappingFile] = useState<File | null>(null);
  const [mappingFileErrors, setMappingFileErrors] = useState<string[]>([]);
  const [mappingData, setMappingData] = useState<MappingData[]>([]);
  const [attachmentsDropzoneReady, setStagedFilesDropzoneReady] = useState(false);
  const [validatedFiles, setValidatedFiles] = useState<ValidatedFile[]>([]);

  // Run validation when mapping file is added
  useEffect(() => {
    const validateAndLoadData = async () => {
      if (mappingFile) {
        validateMappingFile(mappingFile);
        try {
          const csvData = await CSVFileValidator(mappingFile, mappingFileConfig);
          setMappingData(csvData.data);
          setMappingFileErrors(csvData.inValidData.map((data) => data.message));

          if (csvData.inValidData.length === 0) {
            try {
              setStagedFilesDropzoneReady(true);
            } catch {
              enqueueSnackbar("Failed to load required data. Please cancel and try again.");
            }
          }
        } catch (error) {
          enqueueSnackbar("Failed to load required data. Please cancel and try again.");
        }
      }
    };

    validateAndLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingFile]);

  useEffect(() => {
    const validationFilesOutput = validateFiles(stagedFiles, mappingData, versionId);

    // Check if validated files have actually changed before updating the state
    const validatedFilesChanged = !isEqual(validatedFiles, validationFilesOutput.validatedFiles);
    setIsReadyToUpload(validationFilesOutput.isValid && stagedFiles?.length > 0);
    if (validatedFilesChanged) {
      setValidatedFiles(validationFilesOutput.validatedFiles);

      const filesWithFeatureDetails = validationFilesOutput.validatedFiles.map((item) => ({
        file: item.file,
        featureDetails: item.featureDetails,
      }));

      if (validationFilesOutput.isValid) {
        onAcceptedStage(filesWithFeatureDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagedFiles, mappingData, versionId, validatedFiles, onAcceptedStage]);

  /*
   * Dropzone file drop handlers.
   */
  const [rejectedMappingFile, setRejectedMappingFile] = useState<FileRejection[] | null>(null);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[] | null>(null);

  const handleOnDropMappingAccepted = (file: File[]) => setMappingFile(file[0]);
  const handleOnDropFilesAccepted = (files: File[]) => {
    const newBulkFiles: BulkFileProps[] = files.map((file) => ({
      file,
      featureDetails: undefined,
    }));

    // Merge new files with existing stagedFiles without resetting them
    const updatedStagedFiles = [...stagedFiles, ...newBulkFiles];
    onAcceptedStage(updatedStagedFiles);
  };
  const handleOnDropMappingRejected = (file: FileRejection[]) => setRejectedMappingFile(file || null);
  const handleOnDropFilesRejected = (files: FileRejection[]) => setRejectedFiles(files || []);

  /*
   * User controls.
   */
  const handleOnRemove = (file: File) => {
    const updatedStagedFiles = stagedFiles.filter((stagedFile) => stagedFile.file !== file);
    const updatedValidatedFiles = validatedFiles.filter((validatedFile) => validatedFile.file !== file);

    onAcceptedStage(updatedStagedFiles);
    setValidatedFiles(updatedValidatedFiles);

    enqueueSnackbar(`Removed file: ${file.name}`);
  };

  const handleCloseInvalidMappingFileModal = () => {
    setMappingFileErrors([]);
    setMappingFile(null);
  };

  const handleCloseRejectedMappingFileModal = () => {
    setRejectedMappingFile(null);
  };

  const handleCloseRejectedFilesModal = () => {
    setRejectedFiles(null);
  };

  const showMappingFileHeader = () => (
    <Typography mb={2} mt={1} fontWeight={700}>
      Mapping file {!attachmentsDropzoneReady && <em>(*.csv)</em>}
    </Typography>
  );

  const showMappingFile = (file: File) => (
    <ListItem divider>
      <ListItemIcon>
        <MimeTypeIcon mimeType={file.type} />
      </ListItemIcon>
      <ListItemText primary={file.name} />
    </ListItem>
  );

  const hasRejectedMappingFile: boolean = useMemo(() => (rejectedMappingFile && rejectedMappingFile.length > 0) || false, [rejectedMappingFile]);
  const hasRejectedFiles: boolean = useMemo(() => (rejectedFiles && rejectedFiles.length > 0) || false, [rejectedFiles]);
  const hasMappingFileErrors: boolean = useMemo(() => (mappingFileErrors && mappingFileErrors.length > 0) || false, [mappingFileErrors]);
  const hasStagedFiles: boolean = useMemo(() => (stagedFiles && stagedFiles.length > 0) || false, [stagedFiles]);
  const hasValidatedFiles: boolean = useMemo(() => (validatedFiles && validatedFiles.length > 0) || false, [validatedFiles]);

  return (
    <div className={classes.container}>
      {hasRejectedMappingFile && <RejectedMappingFileModal rejectedFile={rejectedMappingFile[0]} onClose={handleCloseRejectedMappingFileModal} />}
      {hasRejectedFiles && <RejectedFilesModal rejectedFiles={rejectedFiles} onClose={handleCloseRejectedFilesModal} />}
      {hasMappingFileErrors && <InvalidMappingFileModal onClose={handleCloseInvalidMappingFileModal} />}

      {!hasStagedFiles && showMappingFileHeader()}
      {!mappingFile && (
        <Box height="25%">
          <Dropzone
            data-testid="attachment-mapping-dropzone"
            onDropAccepted={handleOnDropMappingAccepted}
            onDropRejected={handleOnDropMappingRejected}
            acceptedFiles={{ "application/unknown": [".csv"] }}
            title="Attachment Dropzone"
            message="Drag and drop file or click to open explorer."
            multiple={false}
          />
        </Box>
      )}
      {!hasStagedFiles && mappingFile && showMappingFile(mappingFile)}
      {!hasStagedFiles && (
        <Typography
          mt={attachmentsDropzoneReady ? 4 : 2}
          color={attachmentsDropzoneReady ? "default" : theme.palette.biarri.primary.grey}
          fontWeight={700}
        >
          Files
        </Typography>
      )}
      <Box mt={2} height="50%" maxHeight={stagedFiles?.length > 0 ? 100 : "50%"}>
        <Dropzone
          data-testid="bulk-attachment-dropzone"
          disabled={!attachmentsDropzoneReady || attachmentWhitelist === undefined}
          onDropAccepted={handleOnDropFilesAccepted}
          onDropRejected={handleOnDropFilesRejected}
          acceptedFiles={attachmentWhitelist}
          title={attachmentsDropzoneReady ? "Attachment Dropzone" : ""}
          message={
            attachmentsDropzoneReady ? "Drag and drop files or click to open explorer." : "You must upload the mapping file prior to uploading files"
          }
        />
      </Box>
      {hasStagedFiles && showMappingFileHeader()}
      {hasStagedFiles && mappingFile && showMappingFile(mappingFile)}
      {hasValidatedFiles && (
        <>
          <ListItemScrollNotification
            color="secondary"
            message="A file is invalid and requires changes."
            listRef={listRef}
            listItemRef={invalidFileRef}
          />
          <Typography mb={1} mt={3} fontWeight={700}>
            Pending Files
          </Typography>
          <List ref={listRef} className={classNames(classes.filesList, "customScrollbars")}>
            {sortBy(validatedFiles, ({ file }: ValidatedFile) => file.name).map(({ file, status, isFirstInvalid }: ValidatedFile, index: number) => {
              return (
                <BulkAttachmentListItem
                  data-testid="attachment-staging-list-item"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${file.name}_${index}`}
                  ref={(node) => (isFirstInvalid ? onRefChange(node) : null)}
                  file={file}
                  status={status}
                  onRemove={handleOnRemove}
                />
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};

BulkAttachmentMapping.displayName = "BulkAttachmentMapping";
export default withStyles(customStyles)(BulkAttachmentMapping);
