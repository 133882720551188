import { AccountBase, CTSBinRangesResponse, CTSType, DemandConfiguration, Permission, SystemsOfMeasurement } from "fond/types";
import { UserTracking } from "fond/types/userTracking";

export type ReportCostMap = {
  CostBinRanges: CTSBinRangesResponse | null;
  BoundingBox: [[number, number], [number, number]] | null;
};

export type BuildOrderMap = {
  Phases?: string[];
  BoundingBox: [[number, number], [number, number]] | null;
};

export enum StepFrequency {
  monthly = "month",
  quarterly = "quarter",
  yearly = "year",
}

type FixedOneOffCost = {
  Type: "fixed_one_off";
  Timestep: number;
};

type FixedRecurringCost = {
  Type: "fixed_recurring";
  TimestepFrequency: number;
  TimestepStart?: number;
};

export type OverheadCost = {
  Name: string;
  Cost: {
    CashFlow: number;
  } & (FixedOneOffCost | FixedRecurringCost);
};

export type ReportConfiguration = {
  StartTime: string;
  PlanningHorizon: number;
  StepFrequency: keyof typeof StepFrequency;
  DiscountRate: number;
  Revenue: { RevenueConfigurations: RevenueConfiguration[] };
  ResourceAllocation?: ResourceAllocation;
  CostConfiguration: { Name: "OverheadCost"; Costs: OverheadCost[] } | null;
};

export type ResourceAllocation = Partial<{
  FibreBudget: number;
  PathBudget: number;
  AddressBudget: number;
  MonetaryBudget: number;
}>;

export type RevenueConfiguration = {
  AddressType: DemandConfiguration["AddressType"];
  Description: DemandConfiguration["Description"];
  /**
   * The CashFlow value a.k.a. ARPU - represents the average revenue per user
   */
  CashFlow: number;
  /**
   * A list of TakeRate values that represent the percentage of the market that will be captured at each time step
   * If the value is an array, it must have the same length as the PlanningHorizon
   * Note that the backend will also allow a single value (i.e. just a `number` instead of `number[]`)
   * however to keep the type consistent, we will only allow an array of values
   */
  TakeRate: number[];
  /**
   * The Terminal or "Horizon" value
   */
  TmnlVal: number;
};

export type CostBreakdown = {
  CostBins: number[];
  Count: number;
  GroupID: string;
  RuleID: string;
  Tags: string[];
  TotalCost: number;
  Unit: "meters" | "ft" | "count";
};

export type PassingSummary = {
  BinIndex: number;
  PassingCount: number;
  PassingMinCost: number | null;
  PassingMaxCost: number | null;
};

export type Passing = {
  AccCost?: PassingSummary[];
  SharedCost?: PassingSummary[];
  IndivCost?: PassingSummary[];
};

type ReportCost = {
  TotalConstructionCost: number | null;
  AverageCostPerDistance: number | null;
  Passings?: Passing;
  IndexedCostSummary: Partial<Record<CTSType, Array<CostBreakdown>>> | null;
  CostMap: ReportCostMap | null;
};

export enum Schedules {
  Hhp = "Hhp",
  Hhc = "Hhc",
  Revenue = "Revenue",
  Cashflow = "Cashflow",
  Cost = "Cost",
}

export type CashflowDataItem = {
  CumulativeNetPosition: number;
  NetCost: number;
  NetPosition: number;
  NetRevenue: number;
  Phase: string;
};

export type CostDataItem = {
  ConstructionCost: number;
  CumulativeNetCost: number;
  NetCost: number;
  Phase: string;
  Tag: string;
};

export type HhcDataItem = {
  AddressType: string;
  CumulativePercent: number;
  CumulativeTotal: number;
  NewConnections: number;
  Phase: string;
};

export type HhpDataItem = {
  AddressType: string;
  CumulativePercent: number;
  CumulativeTotal: number;
  NewPassings: number;
  Phase: string;
};

export type RevenueDataItem = {
  AddressType: string;
  CumulativeNetRevenue: number;
  NetRevenue: number;
  Phase: string;
  SubscriptionRevenue: number;
};

export type SchedulesData = {
  [Schedules.Cashflow]: CashflowDataItem[];
  [Schedules.Cost]: CostDataItem[];
  [Schedules.Hhc]: HhcDataItem[];
  [Schedules.Hhp]: HhpDataItem[];
  [Schedules.Revenue]: RevenueDataItem[];
};

export type SchedulesDataItem = CashflowDataItem | CostDataItem | HhcDataItem | HhpDataItem | RevenueDataItem;

type ReportROI = {
  Roi: number | null;
  Npv: number | null;
  Irr: number | null;
  BreakEven: string | null;
  PeakFunding: number | null;
  Schedules: SchedulesData | null;
  BuildOrderMap: BuildOrderMap;
  NetRevenue: number | null;
  NetCost: number | null;
  CoveragePassings: number | null;
  CoverageConnections: number | null;
  CoverageFootprint: number | null;
};

export type Report = UserTracking & {
  Account: AccountBase;
  Description: string | null;
  EntityType: "report";
  FolderID?: string | null;
  Folder: { ID: string } | null;
  ID: string;
  Name: string;
  /**
   * The permission applied to the report for the current logged in user
   */
  Permission: Permission | null;
  Version: { ID: string };
  Type: "financial_analytics" | "financial_analytics_imported";
};

type ReportStatus = {
  State: "PENDING" | "IN_PROGRESS" | "COMPLETE" | "ERROR";
  CurrentStepDescription: string;
  CurrentStepIndex: number;
  Progress: number | null;
};

export type FullReport = Report &
  ReportCost &
  ReportROI & {
    SystemOfMeasurement: SystemsOfMeasurement;
    ReportConfiguration: ReportConfiguration | null;
    Status: ReportStatus | null;
  };

export const DefaultCashFlowForAddressType = {
  SFU: 100,
  DPX: 200,
  TPX: 300,
  QPX: 400,
  SMDU: 600,
  MMDU: 1200,
  LMDU: 6000,
  SCU: 125,
  MCU: 750,
  LCU: 10000,
  INSTITUTION: 500,
  GOVT: 1000,
  PUBLIC: 500,
};
