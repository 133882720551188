import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { selectArchitectureById } from "fond/api";
import { Architecture, DemandModelRule, DemandTier } from "fond/types";
import { getIn, makeUuid } from "fond/utils";
import { useAppSelector } from "fond/utils/hooks";

import DemandModelRow from "./DemandModelRow";

interface IProps {
  hook: [any, any];
}

const PATH = ["Demand"];
const DemandTab: React.FC<IProps> = ({ hook: [state, setValue] }) => {
  const architectureId = getIn(state, ["ID"]);
  const demandConfig: Architecture["Demand"] = getIn(state, PATH);
  const initialDemandConfig: DemandModelRule[] | null =
    demandConfig && demandConfig?.DemandConfigurations.map((config, index) => ({ ...config, ID: `${index + 1}`, New: false }));

  const initialDemandValue = useAppSelector((appState) => selectArchitectureById(appState, architectureId))?.Demand;

  const [rows, setRows] = useState(initialDemandConfig ?? []);

  const generateDemandConfigValue = (demandRows: DemandModelRule[]) => ({
    DemandConfigurations: demandRows.map(({ ID, New, ...config }) => config),
  });

  const addRule = () => {
    setRows((currentRows) => [
      ...currentRows,
      {
        ID: makeUuid(),
        AddressType: "",
        Description: "",
        Tier: DemandTier.DROP,
        NumFibers: 1,
        Default: currentRows.length === 0, // If rows are empty, the first one should automatically be the default
        Ignore: false,
        New: true,
      },
    ]);
  };

  const deleteRule = (id: string) => {
    setRows((currentRows) => {
      const updatedRows = [...currentRows];
      updatedRows.splice(
        rows.findIndex((row) => row.ID === id),
        1
      );

      /**
       * if all rows are deleted and demand is initially null,
       * revert it back to null instead of setting it as empty array
       */
      if (updatedRows.length === 0 && initialDemandValue === null) {
        setValue(PATH, null);
      } else {
        setValue(PATH, generateDemandConfigValue(updatedRows));
      }

      return updatedRows;
    });
  };

  const onRowChange = (rowValues: DemandModelRule, index: number) => {
    setRows((currentRows) => {
      const updatedRows = [...currentRows];
      updatedRows[index] = rowValues;

      setValue(PATH, generateDemandConfigValue(updatedRows));

      return updatedRows;
    });
  };

  const handleUnsetPreviousDefaultAddressType = () => {
    const prevDefaultAddressTypeRuleIndex = rows.findIndex((row) => row.Default);
    if (prevDefaultAddressTypeRuleIndex !== -1) {
      setRows((currentRows) => {
        const updatedRows = [...currentRows];
        updatedRows[prevDefaultAddressTypeRuleIndex] = { ...updatedRows[prevDefaultAddressTypeRuleIndex], Default: false };
        return updatedRows;
      });
    }
  };

  const getSelectedAddressTypes = (index: number) => {
    const selectedAddressTypes = rows.map(({ AddressType }) => AddressType);
    selectedAddressTypes.splice(index, 1);
    return selectedAddressTypes;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Typography variant="h4">Demand Model</Typography>
      <Box my={3}>
        <Typography component="label">
          FOND uses the predefined values listed below, but you can customize the Address type attributes by editing each feature row.
        </Typography>
        <Typography variant="h7" component="p" color={(theme) => theme.palette.primary.main}>
          Note: Consider customizing this template when uploading your own data. Make sure to include all address type attributes.
        </Typography>
      </Box>
      <Typography>Default values</Typography>
      {rows.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small" data-testid="demand-model-table">
            <TableHead>
              <TableRow>
                <TableCell>Address Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Tier</TableCell>
                <TableCell>NumFibers</TableCell>
                <TableCell>{}</TableCell>
                <TableCell align="center">Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <DemandModelRow
                  key={row.ID}
                  values={row}
                  onChange={(rowValues: DemandModelRule) => onRowChange(rowValues, index)}
                  deleteRow={deleteRule}
                  selectedAddressTypes={getSelectedAddressTypes(index)}
                  handleUnsetPreviousDefaultAddressType={handleUnsetPreviousDefaultAddressType}
                  onlyRow={rows.length === 1}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box display="flex" justifyContent="center" my={2}>
        <Button variant="outlined" onClick={addRule} data-testid="add-address-type">
          <Add />
          Add address type
        </Button>
      </Box>
    </Box>
  );
};

export default DemandTab;
