import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectCurrentAccount, selectUserId } from "fond/api";
import { RECENT_PROJECTS_KEY } from "fond/constants";
import { RecentlyViewed } from "fond/types/recent";
import { getItem, setItem } from "fond/utils/localStorage";

/*
 * Hook to capture recent views for select entities.
 */
const useEntityViews = (): void => {
  const { uuid: projectId, reportId } = useParams<"uuid" | "reportId">();
  const currentItemId = projectId || reportId;
  const userId = useSelector(selectUserId);
  const accountId = useSelector(selectCurrentAccount)?.ID;

  useEffect(() => {
    // Return if no item is being viewed.
    if (!currentItemId) return;

    // Load recent items from local storage
    const recentItems: RecentlyViewed[] = getItem(`${RECENT_PROJECTS_KEY}_${userId}_${accountId}`, []);

    // Update last viewed time if project is already in recently viewed list.
    const currentItem = recentItems.find((recentItem) => recentItem.projectId === currentItemId);

    const currentDateTimeString = new Date().toISOString();
    if (currentItem) {
      currentItem.lastViewed = currentDateTimeString;
    } else {
      // Add project and view time if project is not in recently viewed list.
      recentItems.push({
        projectId: currentItemId,
        lastViewed: currentDateTimeString,
      });
    }

    // Update local storage only if there is a user and account id
    if (userId && accountId) setItem(`${RECENT_PROJECTS_KEY}_${userId}_${accountId}`, recentItems);
  }, [accountId, currentItemId, userId]);
};

export default useEntityViews;
