import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { INVITATION_ID_KEY, INVITATION_TOKEN_KEY } from "fond/constants";
import { useQueryParams } from "fond/utils/hooks";

import { NavbarButtonHelper } from "./signInForm.styles";

const SignUpAdornment: React.FC = () => {
  const invitationId = useQueryParams(INVITATION_ID_KEY);
  const inviteToken = useQueryParams(INVITATION_TOKEN_KEY);
  const signUpUrl = invitationId && inviteToken ? `/signup?${INVITATION_ID_KEY}=${invitationId}&${INVITATION_TOKEN_KEY}=${inviteToken}` : "/signup";

  return (
    <>
      <NavbarButtonHelper>No account yet?</NavbarButtonHelper>
      <Button variant="outlined" component={Link} to={signUpUrl} data-testid="sign-up-link" sx={{ ml: 2, textTransform: "uppercase" }}>
        Sign up
      </Button>
    </>
  );
};

export default SignUpAdornment;
