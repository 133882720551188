import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CardBody, CardIcon, CardPaper, RowContainer } from "./insightsCard.styles";

interface InsightsCardProps {
  /**
   * The title text typography.
   */
  title: string;
  /**
   * The value.
   */
  value: number;
  /**
   * The color of the card
   */
  color?: "primary" | "warning";
  /**
   * The icon element.
   */
  icon: ReactNode;
  /**
   * The content.
   */
  content?: ReactNode;
}

const InsightsCard: React.FC<InsightsCardProps> = ({ title, value, color = "primary", icon = null, content = null }: InsightsCardProps) => {
  const theme = useTheme();

  return (
    <CardPaper elevation={1} data-testid="account-total-usage-card">
      <CardBody>
        <RowContainer>
          <Box>
            <Typography
              color={color === "primary" ? theme.palette.biarri.primary.darkGrey : theme.palette.secondary.main}
              variant="subtitle2"
              data-testid="prem-count-header"
            >
              {title}
            </Typography>
            <Typography pt={1} variant="h1" data-testid="prem-count-total">
              {value?.toLocaleString()}
            </Typography>
          </Box>
          <CardIcon>{icon}</CardIcon>
        </RowContainer>
        <Box>{content}</Box>
      </CardBody>
    </CardPaper>
  );
};

export default InsightsCard;
