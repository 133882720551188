import _ from "lodash";
import { createSelectorCreator, defaultMemoize } from "reselect";

import { Comment, CommentFilters, Store } from "fond/types";

// Creates a selection that memoizes & uses deep equals
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual);

/**
 * Returns all comments within redux as an array of comments
 */
export const getAll = createDeepEqualSelector(
  (state: Store) => state.comments.items,
  (comments) => Object.values(comments)
);

/**
 * Returns a comment matching the ID
 * @param id the id of the item to find parents of
 */
export const getOne = createDeepEqualSelector(
  (state: Store): { [key: string]: Comment } => state.comments.items,
  (comments) => (commentId?: string) => comments[commentId || ""]
);

/**
 * Returns all comments of a version
 */
export const getByVersion = createDeepEqualSelector(getAll, (comments) => (versionId?: string) => {
  return comments.filter((comment) => comment.Version === versionId);
});

/**
 * Returns all unresolved comments of a project
 */
export const getUnresolved = createDeepEqualSelector(getAll, (comments) => comments.filter((comment) => comment.State !== "resolved"));

/**
 * Returns all unresolved comments of a project
 */
export const getResolved = createDeepEqualSelector(getAll, (comments) => comments.filter((comment) => comment.State === "resolved"));

/**
 * Returns an object of each comment layers count based on resolution state
 *
 * example:
 * {
 *     point: 1,
 *     polygon: 2,
 *     lineString: 3,
 *     arrowLine: 4,
 *     project: 5,
 *   }
 */
export const getTypeCountByResolutionState = createDeepEqualSelector(getAll, (comments) =>
  _.memoize((filters: CommentFilters) => {
    const result = {
      "comments-point": { count: 0, length: null },
      "comments-polygon": { count: 0, length: null },
      "comments-lineString": { count: 0, length: null },
      "comments-arrowLine": { count: 0, length: null },
      "comments-project": { count: 0, length: null },
    };

    comments.forEach((comment) => {
      if (
        (filters.State.length === 0 || filters.State.indexOf(comment.State) > -1) &&
        (filters.Importance.length === 0 || filters.Importance.indexOf(comment.Importance) > -1)
      ) {
        result[`comments-${comment.Type}`].count += 1;
      }
    });

    return result;
  })
);
