import React, { useContext, useEffect } from "react";
import { Undo } from "@mui/icons-material";
import { AlertTitle, Box, Button, Divider, Typography } from "@mui/material";

import { inputLayerGroups } from "fond/layers";
import { MapContext } from "fond/map/MapProvider";
import { featureCollection } from "fond/turf";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { closeEditing, EditMode, saveEditing, setFeatureEditorConfirm } from "../redux";

import { Alert } from "./InputDataPanel.styles";

const Draw: React.FC = () => {
  const { drawControl } = useContext(MapContext);
  const dispatch = useAppDispatch();
  const inputLayerGroupId = useAppSelector((state) => state.project.editingLayerGroupId);
  const { isDirty } = useAppSelector((state) => state.project.editing);
  const editMode = useAppSelector((state) => state.project.editMode);
  const { goBack, clear } = useStackedNavigationContext();

  const layerGroup = inputLayerGroups[inputLayerGroupId];

  useEffect(() => {
    if (editMode === EditMode.none) {
      clear();
    }
  }, [clear, editMode]);

  const handleBack = () => {
    if (isDirty) {
      dispatch(setFeatureEditorConfirm("close"));
    } else {
      // If a user selected any features but didn't actually make any edits then
      // those features are `isEditing = true` and the original feature is hidden,
      // but `props.isDirty = false`.
      // We need to call `revertAll` to make sure we get the original features back.
      drawControl.current.revertAll();
      dispatch(closeEditing());
      goBack();
    }
  };

  const onImport = async () => {
    const layerGroupIds = inputLayerGroups[inputLayerGroupId].layers.map(({ id }) => id);
    let features: any[] = layerGroupIds.reduce(
      (currFeatures: any[], layerId) => [...currFeatures, ...Array.from(drawControl.current.iterFeatures(layerId) ?? []).map(([f]: any) => f)],
      []
    );
    const featuresToClear =
      features.reduce((result: any, next: any) => {
        const list: any[] = [];
        layerGroupIds.forEach((layerId) => {
          list.push({ layerId, featureId: next.id });
        });
        return [...result, ...list];
      }, []) ?? [];

    // Workaround https://github.com/mapbox/mapbox-gl-draw/issues/774
    features = features.filter((feature: any) => feature.geometry.coordinates.length > 0) ?? [];

    dispatch(saveEditing(featureCollection(features), featuresToClear));
    // clear();
  };

  const onReset = () => {
    if (isDirty) {
      dispatch(setFeatureEditorConfirm("revert"));
    }
  };

  const layerGroupTitle = {
    [inputLayerGroups.inAddress.id]: "Draw addresses",
    [inputLayerGroups.inStreet.id]: "Draw underground path",
    [inputLayerGroups.inExchange.id]: "Draw central offices",
    [inputLayerGroups.spanPole.id]: "Draw spans & poles",
    [inputLayerGroups.hub.id]: "Move hubs to your preferred location",
  };

  return (
    <>
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      <Box data-testid="draw-panel" padding={1}>
        <Alert severity="info" data-testid="layer-alert">
          <AlertTitle sx={{ mb: 0 }}>{layerGroupTitle[layerGroup.id]}</AlertTitle>
        </Alert>
        <Divider sx={{ mt: 3 }} />
        <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset} disabled={!isDirty}>
          <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
            Reset
          </Typography>
        </Button>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button color="primary" size="small" onClick={handleBack}>
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={onImport} sx={{ ml: 1, px: 2 }} data-testid="finish-button" disabled={!isDirty}>
            Finish
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Draw;
