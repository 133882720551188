import { Box, styled } from "@mui/material";

export const Tag = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContents: "center",

  padding: theme.spacing(1),
  borderRadius: "4px",
  backgroundColor: theme.palette.biarri.secondary.whiteSmoke,
  width: "fit-content",
}));
