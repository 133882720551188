import { Feature, FeatureCollection } from "geojson";
import _ from "lodash";

type PathProperty = Array<string | number | symbol>;

/**
 * The purpose of this class is to make features conform to Mapbox's
 * expectations.  In particular, Mapbox's `setFeatureState` method only works
 * on features that have numeric ids (sigh). We also set the `meta` property to
 * "feature" for more subtle reasons documented below. Additionally, because
 * the values in the ID fields may be important to the user, we keep a backup
 * of everything we change and make it possible to retrieve the original values
 * for when we save feature collections back to the server.
 */
export class FeaturePreparer {
  private featureBackups: { [key: string | number]: { path: PathProperty; value: unknown }[] } = {};

  private nextId;

  constructor() {
    /**
      this.featureBackups maps generated IDs to lists of property paths
      and their original values. Eg:

      {
        // The keys here are the generated IDs
        1: [
          {path: ['id'], value: 'my old id'},
          {path: ['properties', 'id'], value: 'my old properties.id'},
          {path: ['meta'], value: 'my old meta value'}
        ],
        // ...
      }

      Note, for paths that do not exist in the original feature, we use a value
      of `undefined`, so we can completely remove the values from the restored
      object. Eg:

        {
          1: [
            {path: ['id'], value: undefined},
            {path: ['properties', 'id'], value: undefined},
            {path: ['meta'], value: undefined}
          ],
        }

    */
    this.featureBackups = {};

    // Some of Mapbox's checks do not recognise 0 as a valid id.
    this.nextId = 1;
  }

  /**
   * Gives `feature` a numeric ID and a `meta` property of "feature", and
   * backs up its current values so they can be restored later.
   * Set the FOND spatial DB id as properties.featureId.
   *
   * Modifies and returns `feature`.
   */
  prepareFeatureForDraw(feature: Feature, layerId: string): Feature {
    if (layerId == null) {
      throw new Error("layerId required");
    }

    if (feature == null) {
      throw new Error("feature is null");
    }

    if (feature.properties == null) {
      feature.properties = {}; // eslint-disable-line no-param-reassign
    }

    const mapboxId = this.nextId;
    const databaseId = feature.id;

    this.featureBackups[mapboxId] = [];

    this.setFeatureValue(mapboxId, feature, ["id"], mapboxId);
    this.setFeatureValue(mapboxId, feature, ["properties", "id"], mapboxId);
    this.setFeatureValue(mapboxId, feature, ["properties", "featureId"], databaseId);
    this.setFeatureValue(mapboxId, feature, ["properties", "layerId"], layerId);

    // Mapbox Draw consults this to check if a feature really is a feature.
    // Mapbox Draw inserts this on features it creates, but since we shove our
    // own features into Mapbox Draw and then immediately call the `mousedown`
    // event on the feature, it seems to be up to us to make sure `meta.feature
    // === 'feature'` because otherwise Mapbox Draw won't be ready to drag it
    // around in the same operation.
    this.setFeatureValue(mapboxId, feature, ["properties", "meta"], "feature");
    this.nextId += 1;
    return feature;
  }

  /**
   * Prepares all features in the given feature collection. Modifies and returns
   * the feature collection.
   */
  prepareFeatureCollectionForDraw(featureCollection: FeatureCollection, layerId: string): FeatureCollection {
    if (layerId == null) {
      throw new Error("layerId required");
    }
    for (let feature of featureCollection.features) {
      this.prepareFeatureForDraw(feature, layerId);
    }
    return featureCollection;
  }

  /**
   * Returns a copy of `feature` with properties restored to their original values.
   * Doesn't mutate anything.
   */
  getRestoredFeature(feature: Feature): Feature {
    const restoredFeature = _.cloneDeep(feature);
    if (feature.id) {
      for (let { path, value } of this.featureBackups[feature.id]) {
        if (value !== undefined) {
          _.set(restoredFeature, path, value);
        } else {
          _.unset(restoredFeature, path);
        }
      }
    }
    return restoredFeature;
  }

  setFeatureValue(id: number, feature: Feature, path: PathProperty, value: unknown): void {
    this.featureBackups[id].push({
      path,
      value: _.get(feature, path),
    });
    _.set(feature, path, value);
  }
}

// We currently just have one global feature preparer used across the app.

const featurePreparer = new FeaturePreparer();

export function prepareFeatureForDraw(feature: Feature, layerId: string): Feature {
  return featurePreparer.prepareFeatureForDraw(feature, layerId);
}

export function prepareFeatureCollectionForDraw(featureCollection: FeatureCollection, layerId: string): FeatureCollection {
  return featurePreparer.prepareFeatureCollectionForDraw(featureCollection, layerId);
}

export function getRestoredFeature(feature: Feature): Feature {
  return featurePreparer.getRestoredFeature(feature);
}
