import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { selectCurrentAccount } from "fond/api";
import { Avatar } from "fond/widgets";

import { AvatarTitle, Container, NavigationHeader } from "./navigation.styles";

interface IProps {
  /**
   * The `testId` prop is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests.
   */
  testId?: string;
  /**
   * Indicates if the account name should be shown at the top of the navigation
   */
  showAcccount?: boolean;
}

const NavigationContainer: React.FC<PropsWithChildren<IProps>> = ({ children, showAcccount = true, testId }) => {
  const accountName = useSelector(selectCurrentAccount)?.Name;

  return (
    <Container data-testid={testId}>
      {showAcccount && accountName && (
        <NavigationHeader>
          <Avatar name={accountName} variant="rounded" sx={{ width: 32, height: 32 }} />
          <AvatarTitle data-testid="account-name">{accountName}</AvatarTitle>
        </NavigationHeader>
      )}
      {children}
    </Container>
  );
};

export default NavigationContainer;
