import React, { useState } from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useUpdateUserPreferencesMutation } from "fond/api";
import { TOS_URL } from "fond/constants";
import { CheckboxField } from "fond/form/fields";
import { UserPreferenceKey, UserPreferenceSubKey } from "fond/types";
import { Modal } from "fond/widgets";

import TOSTerms from "./content/TOSTerms";

import { Alert, GreyText, TermsBox } from "./TermsofServiceChange.styles";

interface IFormData {
  termsOfServiceAgreement: boolean;
}

const TermsofServiceChange: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [updatePreference] = useUpdateUserPreferencesMutation();

  const handleOnSubmit = async (values: IFormData) => {
    if (values.termsOfServiceAgreement) {
      setSaving(true);

      try {
        await updatePreference({ Key: UserPreferenceKey.TOS_CHANGE, Subkey: UserPreferenceSubKey.MAY_2023_TOS_CHANGE, Value: false });
        enqueueSnackbar("Thank you for accepting the new terms of service.");
      } catch {
        enqueueSnackbar("Failed to accept the terms of service. Please try again...");
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Modal
      open
      header="Terms of Service"
      data-testid="terms-of-service-modal"
      content={
        <Form<IFormData>
          initialValues={{ termsOfServiceAgreement: false }}
          onSubmit={handleOnSubmit}
          render={({ handleSubmit }) => {
            return (
              <form id="tos-update-form" onSubmit={handleSubmit}>
                <Alert severity="info">
                  Just a friendly reminder that we've updated our terms of service. To keep using our services, please take a moment to review and
                  agree to the new terms.
                </Alert>
                <TermsBox className="customScrollbars">
                  <TOSTerms />
                </TermsBox>

                <GreyText>
                  A copy of these terms of service is available on{" "}
                  <a rel="noopener noreferrer" href={TOS_URL} target="_blank">
                    {TOS_URL}
                  </a>
                </GreyText>
                <CheckboxField
                  name="termsOfServiceAgreement"
                  label="I have read and agreed to FOND's terms of service"
                  sxProps={{ ".MuiSvgIcon-root": { fontSize: "1.25rem" }, ".MuiFormControlLabel-label": { fontSize: "0.85rem" } }}
                  validate={(value?: boolean) =>
                    value ? undefined : "Please confirm that you have read and agree to the Terms of Service policy of this site."
                  }
                />
              </form>
            );
          }}
        />
      }
      actions={
        <>
          <Button sx={{ mr: 1 }} data-testid="disagree-logout-button" component={Link} to="/signout">
            Disagree and logout
          </Button>
          <LoadingButton data-testid="continue-button" form="tos-update-form" type="submit" variant="contained" color="primary" loading={saving}>
            Continue
          </LoadingButton>
        </>
      }
    />
  );
};

export default TermsofServiceChange;
