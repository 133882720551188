import { useState } from "react";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import makeStyles from "@mui/styles/makeStyles";
import round from "lodash/round";

const useStyles = makeStyles((theme) => ({
  chipAdornment: {
    paddingRight: "4px",
  },
}));

interface ChipInputProps extends OutlinedInputProps {
  values: number[];
  onCommit: (chips: number[]) => void;
}

// Note: This currently only handles integer inputs.
// Currently for all the uses of ChipInput we do not allow zero as a value, so
// that's hardcoded into this widget; we may want to parametrise that later.
export default function ChipInput({ values, onCommit, ...props }: ChipInputProps): React.ReactElement {
  const classes = useStyles();
  const [chips, setChips] = useState<number[]>(values);
  const [text, setText] = useState("");

  /**
   * Parse the text input; if it's valid, return its value as an integer,
   * otherwise return null.
   */
  function parse(s: string) {
    const value = parseInt(s);
    return Number.isInteger(value) && // We only accept integers
      !chips.includes(value) && // We don't accept numbers already in the list
      value !== 0 // Currently zero is not a valid value in any of our `ChipInput`s
      ? value
      : null;
  }

  function commit(newChips: number[]) {
    setChips(newChips);
    onCommit(newChips);
  }

  return (
    <OutlinedInput
      value={text}
      startAdornment={chips.map((value, i) => (
        <InputAdornment key={i} className={classes.chipAdornment} position="start">
          <Chip
            label={round(value, 2)}
            onDelete={() => {
              commit(chips.filter((v) => v !== value));
            }}
          />
        </InputAdornment>
      ))}
      onChange={(event) => setText(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
          const value = parse(text);

          if (value != null) {
            setChips([...chips, value]);
          }
          // Clear the input value so the user can type the next value.
          setText("");
        }
      }}
      onBlur={() => {
        const value = parse(text);
        // If we blur while there's a valid input, add the new value and commit
        // it, else commit the current value.
        commit(value != null ? [...chips, value] : chips);
        setText("");
      }}
      {...props}
    />
  );
}
