import { Box, IconButton as MuiIconButton, styled, Typography } from "@mui/material";

import { OnClickInput as BaseOnClickInput } from "fond/widgets";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 32,
  padding: theme.spacing(0.5),
  paddingRight: 0,
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Title = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  flexGrow: 1,
  fontSize: "0.85rem",
  fontWeight: 500,
}));

export const Actions = styled(Box)(({ theme }) => ({
  height: 32,
  display: "flex",
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  borderRadius: 4,
  "&.active": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  "&:hover.active": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& svg": {
    width: 20,
    height: 20,
  },
}));

IconButton.defaultProps = {
  size: "small",
};

// TODO - I really should not have to override most of these
// But onClickInput was requiring !important - review
export const OnClickInput = styled(BaseOnClickInput)(({ theme }) => ({
  display: "inline-flex",
  color: "inherit",
  fontSize: "0.85rem",
  fontWeight: 500,
  flexGrow: 1,
  padding: 2,
  border: `1px solid transparent`,
  borderRadius: theme.shape.borderRadius,
  whiteSpace: "nowrap",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.biarri.primary.midnight,
  },
  "&:focus": {
    backgroundColor: theme.palette.common.white,
    outlineColor: theme.palette.primary.main,
  },
}));
