import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Business } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { selectFolderById, useGetFoldersQuery } from "fond/api";
import { NavigateError } from "fond/navigation/NavigateError";
import { Navigation } from "fond/projects";
import { Store } from "fond/types";
import { PageContent } from "fond/widgets";

import CityPlannerFolderPageContent from "./CityPlannerFolderPageContent";
import ViewButton from "./ViewButton";

interface RouteParams {
  folderId: string;
}

/**
 * A top level component used for displaying a city planner folder.
 */
const CityPlannerFolder: React.FC = () => {
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { folderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const folderName = currentFolder?.MultiProject?.Name ?? "";

  /**
   * If folderId is not null but currentFolder is null, it indicates that the current user has no access to the multi project,
   * redirect to projects landing page.
   */
  if (folderId && !currentFolder && !isFoldersFetching) {
    return <NavigateError to="/404" resourceType="MULTI_PROJECT" />;
  }

  return (
    <PageContent
      windowTitle={folderName}
      title={
        <Box display="flex" alignItems="center">
          <Business color="primary" sx={{ mr: 1 }} />
          <Typography variant="h1" data-testid="page-title">
            {folderName}
          </Typography>
        </Box>
      }
      action={<ViewButton folder={currentFolder} />}
      mainContent={<CityPlannerFolderPageContent />}
      navigation={<Navigation />}
      testId="multi-project-content"
    />
  );
};

export default CityPlannerFolder;
