import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps as MuiAutocompleteProps,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  UseAutocompleteProps as MuiUseAutocompleteProps,
} from "@mui/material";

export interface AutocompleteProps<T>
  extends Omit<MuiAutocompleteProps<T, false, false, false> & MuiUseAutocompleteProps<T, false, false, false>, "renderInput"> {
  fieldProps?: Partial<FieldProps<any, any>>;
  fullWidth?: boolean;
  label: string;
  name: string;
  options: T[];
  placeholder?: string;
  required?: boolean;
  textFieldProps?: Partial<MuiTextFieldProps>;
  validate?: any;
  getOptionValue?: (option: T | null) => any;
}

export function Autocomplete<T>(props: AutocompleteProps<T>): JSX.Element {
  const { name, fieldProps, validate, ...rest } = props;
  return (
    <Field
      name={name}
      render={({ input, meta }) => <AutocompleteWrapper input={input} meta={meta} name={name} {...rest} />}
      validate={validate}
      allowNull
      {...fieldProps}
    />
  );
}

function AutocompleteWrapper<T>(props: AutocompleteProps<T> & FieldRenderProps<T, HTMLElement>): JSX.Element {
  const {
    filterOptions,
    input: { name, onChange: onFieldChange, value, ...restInput },
    label,
    loading,
    helperText,
    meta,
    options,
    placeholder,
    onChange,
    getOptionLabel,
    getOptionValue,
    renderOption,
    required,
    size,
    ...restProps
  } = props;
  const hasError = meta.error && meta.touched;

  /**
   * Callback function for the onChange event
   */
  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => {
    let optionValue = newValue;
    if (getOptionValue) {
      optionValue = getOptionValue(newValue);
    }

    onFieldChange(optionValue);
    onChange?.(event, optionValue, reason, details);
    onChange?.(event, newValue, reason, details);
  };

  /**
   * Determines the current value for the autocomplete
   */
  const getValue = (): T | undefined => {
    if (getOptionValue) {
      return options.find((option) => getOptionValue(option) === value);
    } else {
      return value;
    }
  };

  return (
    <FormControl fullWidth data-testid={`${name}-form-field`}>
      <FormLabel required={required} error={hasError}>
        {label}
      </FormLabel>
      <MuiAutocomplete
        data-testid={`${name}-input`}
        autoHighlight
        value={getValue()}
        options={options}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        onChange={handleOnChange}
        renderInput={(params) => (
          <MuiTextField
            value={value}
            {...params}
            {...restInput}
            placeholder={placeholder}
            name={name}
            margin="dense"
            error={hasError}
            variant="outlined"
          />
        )}
        renderOption={renderOption}
        ListboxProps={{ className: "customScrollbars" }}
        loading={loading}
        size={size}
        {...restProps}
      />
      <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>
    </FormControl>
  );
}

export default Autocomplete;
