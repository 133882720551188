import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import {
  useCreateMultiReportMutation,
  useCreateReportMutation,
  useGenerateMultiReportMutation,
  useGenerateReportMutation,
  useGetMultiProjectQuery,
  useGetProjectQuery,
  useGetVersionQuery,
} from "fond/api";
import mixpanel from "fond/mixpanel";
import { useQueryParams } from "fond/utils/hooks";

import ReportSettingsForm from "../ReportSettings/ReportSettingsForm";
import type { ReportFormData } from "../types";
import { transformReportFormToReportSettings } from "../util";

const CreateReportForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const folderId = useQueryParams("folderId");
  const versionId = useQueryParams("versionId");
  const multiProjectId = useQueryParams("multiProjectId");
  const { data: version } = useGetVersionQuery(versionId ?? skipToken);
  const { data: project } = useGetProjectQuery(version?.Project ?? skipToken);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId ?? skipToken);
  const navigate = useNavigate();
  const [createReport, { isLoading: isSaving }] = useCreateReportMutation();
  const [generateReport, { isLoading: isGenerating }] = useGenerateReportMutation();
  const [createMultiReport, { isLoading: isSavingMultiReport }] = useCreateMultiReportMutation();
  const [generateMultiReport, { isLoading: isGeneratingMultiReport }] = useGenerateMultiReportMutation();

  const initialValues: Partial<ReportFormData> = useMemo(
    () => ({
      Name: "",
      Description: "",
      Project: multiProject || project,
      Version: version,
      ReportConfiguration: { StepFrequency: "quarterly", StartTime: dayjs().toString(), CostConfiguration: { Name: "OverheadCost", Costs: [] } },
    }),
    [project, version]
  );

  const handleOnCancel = () => {
    navigate(-1);
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async (values: ReportFormData) => {
    try {
      const { Name, Description, VersionID, MultiProjectID, ReportConfiguration } = transformReportFormToReportSettings(values);
      if (VersionID) {
        const report = await createReport({
          Name,
          Description: Description ?? "",
          // an empty folder ID string is equivalent to null for the API
          FolderID: folderId === "" ? null : folderId,
          VersionID,
          ReportConfiguration,
        }).unwrap();
        // Track creation of a report
        mixpanel.track("Report", "Create", "Report Created", { reportId: report.ID });
        await generateReport(report.ID);
        navigate(`/reports/${report.ID}/poll`);
        // Track initial generation of a report
        mixpanel.track("Report", "Create", "Report Generated", { reportId: report.ID });
      }
      if (MultiProjectID) {
        const multiReport = await createMultiReport({
          Name,
          Description: Description ?? "",
          MultiProjectID,
          ReportConfiguration,
        }).unwrap();
        // Track creation of a multi report
        mixpanel.track("MultiReport", "Create", "MultiReport Created", { multiReportId: multiReport.ID });
        const generatedMultiReport = await generateMultiReport(multiReport.ID).unwrap();
        navigate(`/folders/${generatedMultiReport.Folder.ID}`);
        // Track initial generation of a muli report
        mixpanel.track("MultiReport", "Create", "MultiReport Generated", { reportId: multiReport.ID });
      }
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to create report", {
        action: (
          <Button onClick={() => handleOnSubmit(values)} color="inherit">
            Retry
          </Button>
        ),
      });
    }
  };

  return (
    <Box pt={4}>
      <ReportSettingsForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={handleOnCancel}
        isLoading={isSaving || isGenerating || isSavingMultiReport || isGeneratingMultiReport}
      />
    </Box>
  );
};

export default CreateReportForm;
