import React, { useCallback, useContext, useEffect, useState } from "react";
import { Feature, MultiPolygon, Polygon } from "geojson";

import { UnionIcon } from "fond/svg_icons";
import { booleanOverlap, union } from "fond/turf";
import { isAnyPolygon } from "fond/types/geojson";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const UnionButton: React.FC<IButtonProps> = () => {
  const { drawControl, map } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on if a single Multipolygon is selected.
   */
  const handleOnSelect = useCallback(() => {
    const selectedFeatures = drawControl.current.getSelected().features;

    if (selectedFeatures.length === 2 && booleanOverlap(selectedFeatures[0], selectedFeatures[1])) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnClick = () => {
    const selectedFeatures = drawControl.current?.getSelected().features || [];
    let unionPoly: Feature<Polygon | MultiPolygon>;

    if (isAnyPolygon(selectedFeatures?.[0]) && isAnyPolygon(selectedFeatures?.[1])) {
      try {
        unionPoly = union(selectedFeatures[0], selectedFeatures[1]) as Feature<Polygon | MultiPolygon>;
      } catch {
        throw new Error("Union of features failed");
      }

      // Delete the feature being merged into the other
      drawControl.current?.delete([String(selectedFeatures[1].id)]);

      // Maintain id & properties of the unioned feature
      unionPoly.id = selectedFeatures[0].id;
      unionPoly.properties = selectedFeatures[0].properties;

      drawControl.current?.add(unionPoly);
      drawControl.current?.changeMode("simple_select", { featureIds: [unionPoly.id] });
      map?.fire("draw.union", {
        action: "union",
        features: [unionPoly],
      });
      map?.fire("draw.union", { modifiedFeature: unionPoly, deletedFeature: selectedFeatures[1] });
    }
  };

  return (
    <ToolbarButton title="Union features" onClick={handleOnClick} disabled={isDisabled}>
      <UnionIcon />
    </ToolbarButton>
  );
};

export default UnionButton;
