import React from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";

import { CustomButton } from "./MissingFlexNapModuleError.styles";

const MissingFlexNapModuleError: React.FC = () => {
  return (
    <Alert severity="error" sx={{ textAlign: "left", marginBottom: 1 }} data-testid="missing-flexnap-module">
      <AlertTitle>FlexNap Module Required</AlertTitle>
      <Typography variant="body3" textAlign="center">
        Design generation is currently disabled. To access this feature, consider talking to our
        <CustomButton variant="text" href="mailto:bn.sales@biarrinetworks.com">
          sales team
        </CustomButton>
        to add FlexNap to your account or simply toggle off FlexNap in your architecture settings.
      </Typography>
    </Alert>
  );
};

export default MissingFlexNapModuleError;
