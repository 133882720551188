import { Box, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: 24,
  height: 24,
  padding: 4,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& canvas": {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));
