import React, { useState } from "react";
import { FileCopyOutlined } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import CopyProjectDialog from "fond/project/CopyProjectDialog";
import { Project } from "fond/types";
import { Actions } from "fond/utils/permissions";

interface IProps {
  project: Project;
  handleClose: () => void;
}

const Copy: React.FC<IProps> = ({ project, handleClose }: IProps) => {
  const [copyModal, showCopyModal] = useState(false);

  const canCopy = usePermissionCheck(Actions.PROJECT_COPY, project?.Permission.Level);

  /**
   * Callback function to handle the copy project action.
   */

  const handleClick = () => {
    if (canCopy && !project?.HasCustomLayerConfig) {
      showCopyModal(true);
      handleClose();
    }
  };

  return (
    <>
      <MenuItem data-testid="copy-button" onClick={handleClick} disabled={!canCopy || project?.HasCustomLayerConfig}>
        <ListItemIcon>
          <FileCopyOutlined />
        </ListItemIcon>
        Make a Copy
      </MenuItem>
      {copyModal && <CopyProjectDialog project={project} closeModalCallback={() => showCopyModal(false)} />}
    </>
  );
};

export default Copy;
