import React, { forwardRef } from "react";
import { Fullscreen, FullscreenExit, SaveAlt } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { AgChartOptions } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";

import GridCard from "../../GridCard";
import ReportChartNavigation, { NavigationItem } from "../ReportChartNavigation";

import { Container } from "./ReportCostChart.styles";

interface IProps {
  options: AgChartOptions;
  downloadChart(): void;
  navigation: NavigationItem[];
  navigateBackTo(value: number): void;
  fullscreen?: boolean;
  toggleFullscreen(): void;
}

const ReportCostChartTile = forwardRef<AgChartsReact, IProps>(function ChartTile(
  { options, downloadChart, navigation, navigateBackTo, fullscreen, toggleFullscreen }: IProps,
  chartRef
) {
  return (
    <GridCard
      breakpoints={{ lg: fullscreen ? 12 : 6, xs: 12 }}
      title="Cost breakdown"
      headerRightElement={
        <Box display="flex" alignItems="center" gap={0.5}>
          <IconButton data-testid="download-cost-button" size="small" onClick={downloadChart}>
            <SaveAlt fontSize="inherit" />
          </IconButton>
          <IconButton data-testid="fullscreen-button" size="small" onClick={() => toggleFullscreen()}>
            {fullscreen ? <FullscreenExit fontSize="inherit" /> : <Fullscreen fontSize="inherit" />}
          </IconButton>
        </Box>
      }
      height={fullscreen ? "100%" : undefined}
    >
      <ReportChartNavigation navigation={navigation} navigateBackTo={navigateBackTo} />
      <Box flex={1}>
        <Container>
          <AgChartsReact ref={chartRef} options={options} />
        </Container>
      </Box>
    </GridCard>
  );
});

export default ReportCostChartTile;
