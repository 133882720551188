import _ from "lodash";
import mapboxgl from "mapbox-gl";
import { createSelector } from "reselect";

import { Store } from "fond/types";

/**
 * Returns all features within redux as an array of MapboxGeoJSONFeature
 */
export const getAll = (state: Store): mapboxgl.MapboxGeoJSONFeature[] => Object.values(state.features.items);

/**
 * Returns a MapboxGeoJSONFeature matching the ID
 * @param id the id of the item to find parents of
 */
export const getOne = createSelector(
  (state: Store): { [key: string]: mapboxgl.MapboxGeoJSONFeature } => state.features.items,
  (features) => _.memoize((featureId: string) => features[featureId])
);
