import { useSelector } from "react-redux";
import { User } from "configcat-js";
import { useFeatureFlag as useFeatureFlagConfigCat } from "configcat-react";

import { selectCurrentAccount, selectUserId } from "fond/api";

export const useFeatureFlag = (flagName: string, defaultValue: boolean = false): { value: boolean; loading: boolean } => {
  const userId = useSelector(selectUserId);
  const currentAccount = useSelector(selectCurrentAccount);
  const user = new User(
    userId || "default", // identifier
    undefined, // email
    undefined, // country
    { AccountId: currentAccount?.ID || "default" } // custom attributes
  );
  return useFeatureFlagConfigCat<boolean>(flagName, defaultValue, user);
};
