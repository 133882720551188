import { enumerate } from "fond/utils";

/**
 * Functions for manipulating MSTs, that are called by the functions in state.js. These functions
 * are pure functions that only know about MST rules; there are no actions or reducers here.
 */

/**
 * When the user saves a set of hub sizes / tail lengths / placement settings
 * for an MST group, we call this to determine whether to display a warning
 * that some of the existing rows in the group will be removed.
 */
export function hasRemovedRows(mstRule) {
  // Note that we get the "existing" hub sizes / tail lengths from the
  // generated rows, if any, not from the widget parameters themselves. This
  // means we don't need to look at the backup rule to get the existing values,
  // and is also a bit future-proof; it will continue to work when we add the
  // ability to add/remove specific rows.
  const existingRows = mstRule.Rows || [];
  const existingHubSizes = new Set(existingRows.map((r) => r.hubSize));
  const existingTailLengths = new Set(existingRows.map((r) => r.tailLengths[1]));
  const existingPlacements = new Set(existingRows.map((r) => r.colocatedWith));

  return (
    Array.from(existingHubSizes).some((val) => !mstRule.Parameters.t1Hubs.includes(val)) ||
    Array.from(existingTailLengths).some((val) => !mstRule.Parameters.tailLengths.includes(val)) ||
    Array.from(existingPlacements).some((val) => !mstRule.Parameters.colocatedWith.includes(val))
  );
}

/**
 * We call this when we save an MST group's hub sizes / tail lengths /
 * placements.
 *
 * Return the `mstRule`'s new `Rows` - there will be one row for each combination
 * of hub sizes, tail lengths, and placement described in the rule's `Parameters`.
 *
 * Any Rows which still exist in the Parameters will be kept. This means rows
 * whose hub size, *max* tail length, and placement exist in the Parameters. A
 * row's `tailLengths` attribute is a 2-element list [min length, max length], and
 * since we match on the max length, that's why there are a few instances of
 * `tailLengths[1]` in the code.
 */
export function generateMSTRows(mstRule) {
  const existingRows = mstRule.Rows || [];

  // Get the rows which will still exist after we save.
  let rows = existingRows.filter((row) => {
    return (
      mstRule.Parameters.t1Hubs.includes(row.hubSize) &&
      mstRule.Parameters.tailLengths.includes(row.tailLengths[1]) &&
      mstRule.Parameters.colocatedWith.includes(row.colocatedWith)
    );
  });

  let existingHubSizes = new Set(existingRows.map((r) => r.hubSize));
  let existingTailLengths = new Set(existingRows.map((r) => r.tailLengths[1]));
  let existingPlacements = new Set(existingRows.map((r) => r.colocatedWith));

  // Aerial rows have "AR" in the ID, underground have "UG".
  const placementToId = {
    pole: "AR",
    underground: "UG",
  };

  for (let colocatedWith of mstRule.Parameters.colocatedWith) {
    for (let hubSize of mstRule.Parameters.t1Hubs) {
      for (let tailLength of mstRule.Parameters.tailLengths) {
        if (existingHubSizes.has(hubSize) && existingTailLengths.has(tailLength) && existingPlacements.has(colocatedWith)) {
          continue;
        }
        rows.push({
          hubSize,
          // We'll fill in the min tail length later after we've sorted the rows.
          tailLengths: [null, tailLength],
          colocatedWith,
          RowID: `MST-${placementToId[colocatedWith]}${hubSize}-${tailLength}`,
          Description: "",
          Cost: null,
        });
      }
    }
  }

  // Sort by placement, then hub size, then max tail length
  rows.sort((a, b) => {
    if (a.colocatedWith !== b.colocatedWith) {
      return a.colocatedWith.localeCompare(b.colocatedWith);
    } else if (a.hubSize !== b.hubSize) {
      return a.hubSize - b.hubSize;
    } else {
      return a.tailLengths[1] - b.tailLengths[1];
    }
  });

  // Fill in the min tail lengths we promised to fill in above
  let previousTailLength;
  for (let [i, row] of enumerate(rows)) {
    if (i === 0 || row.hubSize !== rows[i - 1].hubSize) {
      previousTailLength = 0;
    }
    row.tailLengths = [previousTailLength, row.tailLengths[1]];
    previousTailLength = row.tailLengths[1];
  }

  return rows;
}
