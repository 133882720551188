import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import { ChangePasswordForm } from "./ChangePasswordForm";
import { EditProfileForm } from "./EditProfileForm";

import { Container, TabContent } from "../common.styles";

const UserProfile: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleOnTabSelect = (_: React.SyntheticEvent<Element, Event>, value: number) => {
    setSelectedTabIndex(value);
  };

  return (
    <Container data-testid="user-profile">
      <Tabs onChange={handleOnTabSelect} value={selectedTabIndex}>
        <Tab label="Details" data-testid="user-profile-details" />
        <Tab label="Change Password" data-testid="user-profile-password" />
      </Tabs>
      <TabContent>
        {selectedTabIndex === 0 && <EditProfileForm />}
        {selectedTabIndex === 1 && <ChangePasswordForm />}
      </TabContent>
    </Container>
  );
};

export default UserProfile;
