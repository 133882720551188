import { Box, IconButton as MuiIconButton, styled, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 32,
  padding: theme.spacing(0.5),
  backgroundColor: "var(--color-1)",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Title = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  flexGrow: 1,
  fontSize: "0.85rem",
  fontWeight: 500,
}));

export const Actions = styled(Box)(({ theme }) => ({
  height: 32,
  display: "flex",
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  borderRadius: 4,
  "&.active": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  "&:hover.active": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& svg": {
    width: 20,
    height: 20,
  },
}));

IconButton.defaultProps = {
  size: "small",
};
