import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Check, Close } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { MultiProjectAreaImportStatus } from "fond/types";

import { Chip } from "./MultiProjectAreaImportStatusCellRenderer.styles";
import { CellWrapper } from "./rowMenuRenderer.styles";

interface IProps extends ICellRendererParams {
  importStatus: MultiProjectAreaImportStatus;
}

const importStatusEntries = {
  PENDING: null, // Should not be observable through the API
  IN_PROGRESS: {
    text: "Creating",
    icon: <CircularProgress size={18} color="info" />,
  },
  COMPLETE: {
    text: "Created",
    icon: <Check />,
  },
  ERROR: {
    text: "Error",
    icon: <Close />,
  },
};

const MultiProjectAreaImportStatusCellRenderer: React.FC<IProps> = memo(({ importStatus }: IProps) => {
  const spec = importStatusEntries[importStatus.State];
  if (!spec) {
    return null;
  }

  return (
    <CellWrapper>
      <Chip label={spec.text} icon={spec.icon} size="small" className={`status-${importStatus.State}`} />
    </CellWrapper>
  );
});

MultiProjectAreaImportStatusCellRenderer.displayName = "MultiProjectAreaImportStatusCellRenderer";

export default MultiProjectAreaImportStatusCellRenderer;
