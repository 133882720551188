import React, { useState } from "react";
import { useNavigate } from "react-router";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { ListItemIcon } from "@mui/material";
import { useSnackbar } from "notistack";

import { useGetAccountsQuery, useGetUserMeAccountsQuery } from "fond/api";
import AccountMenuItems from "fond/topBar/AccountMenuItems";
import { MenuItem, MenuItemContentWrapper, Tooltip } from "fond/topBar/switchAccountMenuItem.styles";

interface IProps {
  onClose: () => void;
}

const SwitchAccountMenuItem: React.FC<IProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useGetAccountsQuery();
  useGetUserMeAccountsQuery();

  const onAccountSwitch = () => {
    setShowTooltip(false);
    onClose();
    enqueueSnackbar("Switching accounts...");
    navigate(0);
  };

  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      placement="left-start"
      title={<AccountMenuItems onAccountSwitch={onAccountSwitch} />}
      leaveDelay={500}
    >
      <MenuItem data-testid="switch-account">
        <MenuItemContentWrapper>
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          Switch account
        </MenuItemContentWrapper>
        <ArrowRightIcon />
      </MenuItem>
    </Tooltip>
  );
};

export default SwitchAccountMenuItem;
