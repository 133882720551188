import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Box, SxProps, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import classNames from "classnames";
import { orderBy } from "lodash";

import {
  selectAllFolders,
  selectAllProjects,
  selectVersionsByProjectId,
  useGetFoldersQuery,
  useGetProjectsQuery,
  useGetVersionsQuery,
} from "fond/api";
import { Folder, Project, Store, Version } from "fond/types";
import { getPath } from "fond/utils/folder";

/**
 * The ProjectVersionSelection component.
 * This component provides the user with the ability to select a project using two autocompleting fields.
 *   - The first field selects the project.
 *   - Once selected the API is queried for all version in the project.
 *   - The second field now provides an autocomplete of all the project versions, from latest to earliest.
 *   - Upon selecting a version the OnSelect callback will proivide the version to the implementing component.
 */
interface RequiredProps {
  /**
   * Callback on version selection.
   */
  onSelect(version: Version): void;
}

interface OptionalProps {
  /**
   * Set the component test identifier.
   */
  "data-testid": string;
  /**
   * An optional placeholder on the project selection field.
   */
  placeholder: string;
  /**
   * A loading flag.
   */
  loading: boolean;
  /**
   * Disable the selections.
   */
  disabled: boolean;
  /**
   * A reset flag.
   */
  reset: boolean;
  /**
   * Display the loading indicator
   */
  projectFilter: (project: Project) => boolean;
  activeProjectVersion: Version;
  targetSettingsVersion: Version;
}

interface ProjectVersionSelectionProps extends RequiredProps, Partial<OptionalProps> {}

const ProjectVersionSelection: React.FC<ProjectVersionSelectionProps> = ({
  onSelect,
  "data-testid": dataTestid = "project-version-selection",
  placeholder = "Select a project",
  loading = false,
  disabled = false,
  reset = false,
  projectFilter = () => true,
  activeProjectVersion,
  targetSettingsVersion,
}: ProjectVersionSelectionProps) => {
  // States.
  /**
   * The project state tracks the selected project.
   * The project versions are fetched on state update.
   */
  const [project, setProject] = useState<Project | null>(null);
  const [resetProject, setResetProject] = useState(false);
  const [resetVersion, setResetVersion] = useState(false);

  // Data.
  const { isLoading: isFoldersLoading } = useGetFoldersQuery(undefined);
  const folders: Folder[] = useSelector((state: Store): Folder[] => selectAllFolders(state));
  const { isLoading: isProjectsLoading } = useGetProjectsQuery(undefined);
  const projects = useSelector((state: Store): Project[] => selectAllProjects(state));
  const { isLoading: isVersionsLoading } = useGetVersionsQuery(project?.ID || "", { skip: !project?.ID });
  const versions = useSelector((state: Store): Version[] => (project && selectVersionsByProjectId(state, project.ID)) || []);

  useEffect(() => {
    if (reset) {
      setProject(null);
      setResetProject(true);
    }
  }, [reset]);

  useEffect(() => {
    if (resetProject) {
      setResetProject(false);
    }
  }, [resetProject]);

  useEffect(() => {
    setResetVersion(true);
  }, [project]);

  useEffect(() => {
    if (resetVersion) {
      setResetVersion(false);
    }
  }, [resetVersion]);

  // callbacks
  /**
   * Sets the project state on autocomplete project selection.
   */
  const selectProject = (_: React.SyntheticEvent, selected: Project | null) => setProject(selected);
  /**
   * Passes the selected version into a callback event fire.
   */
  const selectVersion = (_: React.SyntheticEvent, selected: Version | null) => {
    if (selected) {
      onSelect(selected);
    }
  };

  // Calculate the folder path for each folder. This information is presented in the drop down display.
  const folderPaths: { [index: string]: string[] } = {};
  for (const folder of folders) {
    folderPaths[folder.ID] = getPath(folders, folder.ID);
  }

  return (
    <Box data-testid={dataTestid} display="flex" justifyContent="space-between" width="424px" gap="10px">
      {!resetProject && (
        <StyledFormControl size="small">
          <ResourceAutocomplete<Project>
            data-testid="project-selection"
            disableClearable={!project || !versions}
            disabled={disabled}
            loading={loading || isFoldersLoading || isProjectsLoading}
            value={project}
            onChange={selectProject}
            options={orderBy(projects, "CreatedAt", "desc").filter(projectFilter)}
            getOptionLabel={(option: Project) => option.ProjectName}
            placeholder={loading ? "Loading..." : (placeholder as string)}
            renderOption={(option: Project) => {
              const folderPath = option?.FolderID && folderPaths[option.FolderID];
              return (
                <Box data-testid="option-project" display="flex" flexDirection="column">
                  <Typography data-testid="option-name" noWrap>
                    {option.ProjectName}
                  </Typography>
                  <Typography data-testid="option-path" noWrap variant="caption">
                    {`Home ${folderPath ? ">" : ""} ${folderPath ? folderPath.join(" > ") : ""}`}
                  </Typography>
                </Box>
              );
            }}
          />
        </StyledFormControl>
      )}
      {!resetVersion && (
        <StyledFormControl size="small">
          <ResourceAutocomplete<Version>
            data-testid="version-selection"
            disableClearable
            disabled={disabled}
            loading={loading || isVersionsLoading}
            value={targetSettingsVersion}
            onChange={selectVersion}
            options={versions.filter((version) => version.ID !== activeProjectVersion?.ID)}
            getOptionLabel={(option: Version) => option.Name}
            placeholder="Select Version"
            renderOption={(option: Version) => (
              <Box data-testid="option-version">
                <Typography data-testid="option-name">{option.Name}</Typography>
              </Box>
            )}
          />
        </StyledFormControl>
      )}
    </Box>
  );
};

/**
 * ResourceAutocompleteProps component.
 * This component may be used to simplifythe implementation of an Autocomplete for any resource list.
 * The resource entities must have an ID field.
 * The options list must be a list of resource entities.
 */
interface ResourceAutocompleteProps<T> {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  sx?: SxProps;
  value?: null | T;
  /**
   * Pass through the standard Autocomplete disableClearable flag.
   */
  disableClearable: boolean;
  /**
   * Replaces the options with a 'Loading..." placeholder.
   */
  loading: boolean;
  /**
   * Disable the complete.
   */
  disabled?: boolean;
  /**
   * An array of entities to select from.
   */
  options: ReadonlyArray<T>;
  /**
   * A transformation of a resource entity yo it's autocomplete label string value.
   */
  getOptionLabel(option: T): string;
  /**
   * A condition that renders the option disabled from selection.
   */
  getOptionDisabled?(option: T): boolean;
  /**
   * The input text field placeholder value.
   */
  placeholder: string;
  /**
   * An optional transformation of a resource entity to dropdown element.
   */
  renderOption(option: T): React.ReactNode;
  /**
   * An optional override of the default input render.
   */
  renderInput?(params: TextFieldProps): React.ReactNode;
  /**
   * Callback on resource selection. Passes back the selected entity.
   */
  onChange(event: React.SyntheticEvent, selected: T | null): void;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
}));

export function ResourceAutocomplete<T extends { ID: string }>({
  sx,
  value,
  disableClearable,
  loading,
  onChange,
  options,
  getOptionLabel,
  getOptionDisabled,
  placeholder,
  renderOption,
  renderInput,
  "data-testid": dataTestid = "resource-autocomplete",
  disabled = false,
}: ResourceAutocompleteProps<T>): React.ReactElement {
  // Override the renderInput value with the default if not set.
  if (renderInput == null) {
    // eslint-disable-next-line no-param-reassign
    renderInput = (params: TextFieldProps) => <TextField {...params} placeholder={placeholder} variant="outlined" />;
  }
  return (
    <Autocomplete
      blurOnSelect
      sx={sx}
      value={value}
      data-testid={dataTestid}
      loading={loading}
      disabled={disabled}
      size="small"
      disableClearable={disableClearable}
      ListboxProps={{ className: classNames("customScrollbars") }}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={(option: T, selectedValue: T) => option.ID === selectedValue.ID}
      renderInput={renderInput}
      renderOption={(props, option: T) => {
        return (
          <li {...{ ...props }} key={option.ID || ""}>
            {renderOption(option)}
          </li>
        );
      }}
    />
  );
}

export default ProjectVersionSelection;
