import React from "react";
import { useSelector } from "react-redux";

import {
  getUserPreferenceValue,
  selectCurrentRole,
  useGetAccountsQuery,
  useGetUserMeAccountsQuery,
  useGetUserMeQuery,
  useGetUserPreferencesQuery,
} from "fond/api";
import TermsofServiceChange from "fond/terms/TermsofServiceChange/TermsofServiceChange";
import { Store, UserPreferenceKey, UserPreferenceSubKey, UserRole, UserRoles } from "fond/types";
import NoMatch from "fond/views/Error/NoMatch";
import { BlockSpinner, ErrorModal } from "fond/widgets";

export default function withUserInfo<T extends object>(
  WrappedComponent: React.ComponentType<T>,
  minimumRole: (typeof UserRoles)[UserRole] = 0
): (props: T) => React.ReactElement<T> {
  return (props: T) => {
    const {
      isLoading: isLoadingUserPreferences,
      isError: isErrorUserPreferences,
      refetch: refetchUserPreferences,
    } = useGetUserPreferencesQuery(undefined);
    const { isLoading: isLoadingUser, isError: isErrorUser, refetch: refetchUser } = useGetUserMeQuery();
    const { isLoading: isLoadingUserAccounts, isError: isErrorUserAccounts, refetch: refetchUserAccounts } = useGetUserMeAccountsQuery();
    const { isLoading: isLoadingAccounts, isError: isErrorAccounts, refetch: refetchAccounts } = useGetAccountsQuery();
    const role = useSelector(selectCurrentRole);

    const showTermsofServiceChange = useSelector((state: Store) =>
      getUserPreferenceValue(state, UserPreferenceKey.TOS_CHANGE, UserPreferenceSubKey.MAY_2023_TOS_CHANGE)
    );

    const onDismiss = () => {
      if (isErrorAccounts) refetchAccounts();

      if (isErrorUser) refetchUser();

      if (isErrorUserAccounts) refetchUserAccounts();

      if (isErrorUserPreferences) refetchUserPreferences();
    };

    const getComponentWithUserInfo = () => {
      switch (true) {
        case isErrorUserPreferences || isErrorUser || isErrorUserAccounts || isErrorAccounts:
          return <ErrorModal onClose={onDismiss} />;
        case isLoadingUserPreferences || isLoadingUser || isLoadingUserAccounts || isLoadingAccounts:
          return <BlockSpinner containerProps={{ height: "100%" }} />;
        case UserRoles[role ?? "member"] < minimumRole:
          return <NoMatch />;
        default:
          // This is to avoid overriding the wrapped components `onLoad`. For more info see:
          // https://bitbucket.org/biarrinetworks/fond_ui/pull-requests/222/mag-1190-add-feature-set-enforcement-to/diff#Lsrc/fond/accounts/withUserInfo.jsT29
          return (
            <>
              {showTermsofServiceChange && <TermsofServiceChange />}
              <WrappedComponent {...props} />
            </>
          );
      }
    };
    const ComponentWithUserInfo = getComponentWithUserInfo();

    return ComponentWithUserInfo;
  };
}
