import { Lock as MuiLockIcon } from "@mui/icons-material";
import { Box, Button, IconButton, styled, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: 280,
  padding: theme.spacing(1.5),
}));

export const Body = styled(Box)({ display: "flex" });

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
}));

export const Title = styled(Typography)({ fontSize: "1rem", fontWeight: 700 });

export const LockIcon = styled(MuiLockIcon)(({ theme }) => ({
  fontSize: "1rem",
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  fontSize: "0.85rem",
  padding: theme.spacing(1),
}));

export const Action = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(6)}`,
  marginRight: theme.spacing(2),
}));

export const CloseButton = styled(IconButton)({
  padding: 0,
  height: 15,
  width: 15,
  alignSelf: "flex-start",

  "& > svg": {
    fontSize: "0.75rem",
  },
});
