import { passing_draw_line_string as passingMode } from "mapbox-gl-draw-passing-mode";

import { MODE_NAME, PASSING_MODE_NAME } from "./constants";
import splitPolygonMode from "./polygonSplit";

const modes = {
  [PASSING_MODE_NAME]: passingMode,
  [MODE_NAME]: splitPolygonMode,
};

export default modes;
