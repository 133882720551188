import { Alert as BaseAlert, Box, styled } from "@mui/material";

export const Alert = styled(BaseAlert)({
  "& .MuiAlert-icon": {
    alignItems: "center",
  },
});

export const NoContentMessage = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(5),
  paddingLeft: theme.spacing(5),
}));

export const MessagePanel = styled(NoContentMessage)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`,
  padding: `${theme.spacing(5)} 0`,
}));
