import { Feature, FeatureCollection, Geometry, LineString, MultiPoint, MultiPolygon, Point, Polygon } from "geojson";
import { isEmpty } from "lodash";

export interface ParcelProperties {
  City: string;
  Country: string;
  Demand: number;
  LandUse: string;
  layerId: string;
  PARCELAPN: string;
  Propdmpid: string;
  Plus4Code: string;
  Region: string;
  State: string;
  StreetDirection: string;
  StreetName: string;
  StreetNumber: string;
  StreetQuadrant: string;
  StreetSuffix: string;
  UnitNumber: string;
  UnitPrefix: string;
  Zip: string;
}
export interface ParcelFeature extends Feature {
  geometry: MultiPolygon;
  properties: ParcelProperties;
}
export interface ParcelCollection extends FeatureCollection {
  features: Array<ParcelFeature>;
}

export interface AddressFeature extends Feature {
  geometry: Point;
  properties: ParcelProperties;
}
export interface AddressCollection extends FeatureCollection {
  features: Array<AddressFeature>;
}

export interface StreetProperties {
  bridge: string;
  CostFactor: number;
  highway: string;
  layerId: string;
}

export interface StreetFeature extends Feature {
  geometry: LineString;
  properties: StreetProperties;
}

export interface SpanFeature extends Feature {
  geometry: LineString;
  properties: StreetProperties;
}

export interface PoleFeature extends Feature {
  geometry: Point;
}
export interface StreetCollection extends FeatureCollection {
  features: Array<StreetFeature>;
}

export interface SpanCollection extends FeatureCollection {
  features: Array<SpanFeature>;
}
export interface PoleCollection extends FeatureCollection {
  features: Array<PoleFeature>;
}

// Type narrowing for Geojson Polygons
export const isPolygon = (feature: Feature<Geometry>): feature is Feature<Polygon> => feature.geometry.type === "Polygon";
export const isMultiPolygon = (feature: Feature): feature is Feature<MultiPolygon> => feature.geometry.type === "MultiPolygon";
export const isAnyPolygon = (feature: Feature<Geometry>): feature is Feature<Polygon | MultiPolygon> => isPolygon(feature) || isMultiPolygon(feature);
export const isValuePolygon = (feature: Feature<Polygon | MultiPolygon>): boolean =>
  feature.geometry.coordinates.flat().filter((value) => !isEmpty(value)).length > 0;

export const isPoint = (feature: Feature<Geometry>): feature is Feature<Point> => feature.geometry.type === "Point";
export const isMultiPoint = (feature: Feature): feature is Feature<MultiPoint> => feature.geometry.type === "MultiPoint";
export const isAnyPoint = (feature: Feature<Geometry>): feature is Feature<Point | MultiPoint> => isPoint(feature) || isMultiPoint(feature);
export const isValuePoint = (feature: Feature<Point | MultiPoint>): boolean => feature.geometry.coordinates.flat().length > 0;
