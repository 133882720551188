import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";

import { defaultArch } from "fond/architecture/defaultArch";
import { Field as BaseField } from "fond/form";
import { FieldProps } from "fond/form/Field";
import { FieldType, PlannerFeatures } from "fond/types";
import { formatUnit, getIn } from "fond/utils";

import { getPreferredDescription } from "./architecturePreferredDescription";
import { getPreferredLabel } from "./architecturePreferredLabel";
import { useArchitectureEditorContext } from "./context";

/**
 * A wrapper over the base Field which injects help text and validation error /
 * warning messages.
 *
 * It also provides an additional 'length' type, which translates to a 'text'
 * type but the text box has an adornment showing the architecture's currently
 * selected unit of measurement.
 */

type ArchitectureFieldProps = Partial<Omit<FieldProps, "type">> & {
  type: FieldType | "length";
  path: string[];
  hook: [any, any];
  label: string;
  preference?: PlannerFeatures;
  // All other props
  [x: string]: any;
};

const ArchitectureField: React.FC<ArchitectureFieldProps> = ({ type, path, hook, label, preference, ...rest }: ArchitectureFieldProps) => {
  const [state] = useArchitectureEditorContext();

  let widgetState;
  let widgetMessage;
  let widgetProps;

  const result = _.get(state.validationResults, path);
  if (result != null) {
    widgetState = result.state;
    widgetMessage = result.message;
  }

  if (type === "length") {
    widgetProps = {
      endAdornment: (
        <InputAdornment position="end" data-testid={`field-${path.join("-")}-adornment`}>
          {formatUnit(state.systemOfMeasurement)}
        </InputAdornment>
      ),
    };
  }

  return (
    <BaseField
      type={type === "length" ? "text" : type}
      hook={hook}
      label={getPreferredLabel(preference, path) || label}
      path={path}
      helpText={getPreferredDescription(preference, path) || getIn(fieldDescriptions, path)}
      widgetState={widgetState}
      widgetMessage={widgetMessage}
      widgetProps={widgetProps}
      focusNumber={_.isEqual(state.scrollToFieldPath, path) ? state.scrollToFieldPathNum : null}
      defaultValue={getIn(defaultArch, path)}
      {...rest}
    />
  );
};

export default ArchitectureField;

const topologyDescriptions = (tier: string) => ({
  Type: "Choose the topology for your network. If unsure select Tree.",
  ParallelCableThreshold: `Maximum length of ${tier} cable that can run parallel in order to remove a splice`,
  LoopingCableThreshold: `Maximum length of ${tier} cable that can be looped in order to remove a splice`,
});

const cablesDescriptions = (tier: string) => ({
  Sizes: `Enter the ${tier} cable sizes, in terms of the fiber count`,
  Spare: "Specify the absolute number or percentage of fibers to be kept spare; these won't be connected upstream",
  TaperCables: "Toggle on/off whether cable segments should always be the smallest possible size, or size should be maintained along trunk paths.",
  Type: "Select the cable type from the list",
});

export const fieldDescriptions = {
  DefaultPlacement: (
    <>
      If you select <em>Prefer to use aerial</em>, FOND will use aerial where feasible, but may still use underground. <br />
      If you select <em>Preference aerial and underground equally</em>, no preferencing will be applied.
    </>
  ),
  Tier1: {
    Cables: {
      ...cablesDescriptions("drop"),
      ExpandAddresses:
        "Choose to expand specific demand types into separate single demand points. For instance if the design has a demand type indicating the need for 2 fibres to be dropped at the demand, this can be served via either a single cable serving a single point or, with the use of this parameter, two separate drop fibres serving two distinct demand points.",
    },
    Hubs: {
      Sizes: "Enter the sizes of drop hubs, in terms of their port count",
      Type: "Select the type of drop hubs from the list",
      SplitRatio: "If splitting in the drop hub choose the split ratio, otherwise choose None",
      Spare: "Specify the absolute number or percentage of ports to be kept spare",
      LightSparePorts: "Toggle on if you need the spare ports to be fully connected all the way up to the central office",
      AllowDirectHomeConnect: "Toggle on to include terminal free service options in the design. Predominantly used in sparse demand environments.",
    },
    DropRules: {
      // propose removing this section - we don't need a special section for 'drop rules', they are just T1 cable rules.
      DropLength:
        "Specify the maximum drop cable length. Lead-in length is not taken into account. A higher length can mean longer solve times, especially in areas of high address density.",
      PolesPerDrop: "The maximum number of poles that a drop cable can pass through between hub and address",
      PitsPerDrop: "The maximum number of underground structures that a drop cable can pass through between hub and address",
    },
  },
  Tier2: {
    ConnectorizedDropTerminals: {
      Enabled: "Toggle on if using drop hubs with connectorized tails",
      DaisyChain: "Toggle on to enable drop hubs to daisy chain to others",
      MaxTailLength: "Specify the maximum length of tails in your inventory",
      TailsPerSplice: "Specify the maximum number of tails that can be spliced into a single splice closure",
    },
    Cables: cablesDescriptions("distribution"),
    Hubs: {
      Sizes: "Enter the distribution hub sizes, in terms of their port count",
      SplitRatio: "If splitting in the distribution hub choose the split ratio, otherwise choose None",
      Spare: "Specify the absolute number or percentage of ports to be kept spare",
      LightSparePorts: "Toggle on if you need the spare ports to be fully connected all the way up to the central office",
      UnsplitPorts:
        "Enter the number of ports that are available for unsplit fibers. This is the number of fibres for Type Distribution-Bypass Splitters address points which can be served through one Distribution Hub.",
    },
    Topology: topologyDescriptions("distribution"),
  },
  Tier3: {
    Cables: cablesDescriptions("feeder"),
    Topology: topologyDescriptions("feeder"),
    Hubs: {
      Sizes: "List the sizes of feeder hubs you'd like to use in terms of the number of demand",
    },
  },
  IsFlexNap: (
    <>
      Toggle on/off whether the design should be generated using a FlexNAP architecture.
      <br />
      FlexNAP-related parameters will be reset after this switch is toggled.
    </>
  ),
};
