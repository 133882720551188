import { Box, Grid, styled, Typography } from "@mui/material";

export const CheckoutContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: auto;
  gap: 2em 2em;
  grid-template-areas:
    "product summary"
    "payment summary"
    "payment question";
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 2% 5%;
`;

const CheckoutBox = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)`,
  borderRadius: "4px",
}));

export const Product = styled(CheckoutBox)`
  grid-area: product;
`;

export const Payment = styled(CheckoutBox)`
  grid-area: payment;
`;

export const Summary = styled(CheckoutBox)`
  grid-area: summary;
`;

export const Question = styled(Box)`
  grid-area: question;
`;

export const SummaryRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(1)} 0`,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.biarri.secondary.grey,
}));
