import { Box, styled, Typography } from "@mui/material";

export const StyledTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}));

StyledTitle.defaultProps = {
  variant: "h3",
};

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.biarri.secondary.grey,
  paddingBottom: theme.spacing(1.5),
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  paddingBottom: theme.spacing(1.5),
}));

export const GreyText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.biarri.secondary.grey,
}));

GreyText.defaultProps = {
  variant: "subtitle2",
};

export const DarkGreyText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.biarri.primary.darkGrey,
}));

export const RowContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
