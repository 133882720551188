import {
  Box,
  checkboxClasses,
  css,
  FormControlLabel,
  formControlLabelClasses,
  ImageList as BaseImageList,
  ImageListItemBar as BaseImageListItemBar,
  imageListItemBarClasses,
  styled,
} from "@mui/material";

import { visuallyHiddenCss } from "fond/utils/css";

export const ImageList = styled(BaseImageList)(({ theme }) => ({
  padding: theme.spacing(3),
  /**
   * Below overrides the inline style "grid-template-columns: repeat(2, 1fr)"
   * that MUI applies to ImageList on render. This is shorthand for "grid-template-columns: repeat(2, minmax(auto, 1fr))"
   * that allows images in the grid to have width beyond 1 fraction of the space
   */
  gridTemplateColumns: "repeat(2, minmax(0, 1fr)) !important",
}));

export const ImageListItemBar = styled(BaseImageListItemBar)`
  text-align: center;

  & .${imageListItemBarClasses.title} {
    font-size: 12px;
  }
  & .${imageListItemBarClasses.subtitle} {
    font-size: 10px;
  }
`;

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(
  ({ isSelected, theme }) => css`
    position: relative;
    font-size: 0;
    text-align: center;
    border: 4px solid ${isSelected ? theme.palette.primary.main : "transparent"};
    width: 100%;
    min-height: 50px;

    &:hover,
    &:focus-within {
      border-color: ${isSelected ? theme.palette.primary.main : theme.palette.biarri.secondary.darkCarbon};
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  `
);

export const ImagePlaceholder = styled(Box)`
  position: relative;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.biarri.secondary.whiteSmoke};
  display: grid;
  place-items: center;
  aspect-ratio: 1;
  color: ${({ theme }) => theme.palette.biarri.secondary.activeGrey};
`;

export const SelectFileLabel = styled(FormControlLabel)`
  position: absolute;
  display: block;
  text-align: left;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding-left: ${({ theme }) => theme.spacing(2)};

  & .${formControlLabelClasses.label} {
    ${visuallyHiddenCss}
  }
  & .${checkboxClasses.root} {
    opacity: 0;
  }
  & .${checkboxClasses.checked}, &:hover .${checkboxClasses.root}, &:focus-visible .${checkboxClasses.root} {
    opacity: 1;
  }
  &:hover,
  &:focus-within,
  &:focus-visible {
    svg {
      color: ${({ theme }) => theme.palette.biarri.secondary.darkCarbon};
    }
  }
`;
