import { forwardRef, memo } from "react";
import { Alert, Box, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { AttachmentStagingListItemAction } from "fond/attachments";
import { MimeTypeIcon } from "fond/widgets";

import { FileStatus } from "./bulkAttachmentValidation";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    margin: "0 10px",
    padding: "0px 10px",
    width: 215,
  },
  fileStatusActions: {
    textAlign: "right",
  },
}));

interface IProps {
  file: File;
  /*
   * The validation status of the file.
   */
  status: FileStatus;
  /**
   * Callback function for when the remove staged file button is clicked.
   */
  onRemove(file: File): void;
}

const BulkAttachmentListItem = forwardRef<HTMLDivElement, IProps>(({ file, status, onRemove }: IProps, ref) => {
  const classes = useStyles();

  return (
    <div data-testid="attachment-file-list-item" ref={ref}>
      <ListItem divider>
        <ListItemIcon>
          <MimeTypeIcon mimeType={file.type} />
        </ListItemIcon>
        <ListItemText primary={file.name} />
        <>
          {status === FileStatus.NO_EXTENSION && (
            <Alert data-testid="attachment-file-list-item-alert-missing-ext" className={classes.alert} severity="error">
              Missing extension
            </Alert>
          )}
          {status === FileStatus.DROP_CONFLICT && (
            <Alert data-testid="attachment-file-list-item-alert-duplicate" className={classes.alert} severity="error">
              Duplicated filename
            </Alert>
          )}
          {status === FileStatus.CANNOT_ACCEPT && (
            <Alert data-testid="attachment-file-list-item-alert-existing" className={classes.alert} severity="warning">
              No match in mapping file
            </Alert>
          )}
        </>
        <Box minWidth={200} className={classes.fileStatusActions}>
          <AttachmentStagingListItemAction data-testid="attachment-file-list-item-remove" title="Remove" action={() => onRemove(file)} />
        </Box>
      </ListItem>
    </div>
  );
});

BulkAttachmentListItem.displayName = "BulkAttachmentListItem";
export default memo(BulkAttachmentListItem);
