export const mapboxFonts = [
  {
    value: "Arial Unicode MS Regular",
    displayValue: "Arial Regular",
    styles: { fontFamily: "Arial", fontWeight: 400 },
  },
  {
    value: "Arial Unicode MS Bold",
    displayValue: "Arial Bold",
    styles: { fontFamily: "Arial", fontWeight: 700 },
  },

  {
    value: "Courgette Regular",
    displayValue: "Courgette Regular",
    styles: { fontFamily: "Courgette", fontWeight: 400 },
  },

  {
    value: "Kievit Offc Pro Light",
    displayValue: "Kievit Offc Pro Light",
    styles: { fontFamily: "Kievit Pro Light", fontWeight: 300 },
  },
  {
    value: "Kievit Offc Pro Regular",
    displayValue: "Kievit Offc Pro",
    styles: { fontFamily: "Kievit Pro Regular", fontWeight: 400 },
  },

  {
    value: "Lato Light",
    displayValue: "Lato Light",
    styles: { fontFamily: "Lato", fontWeight: 300 },
  },
  {
    value: "Lato Regular",
    displayValue: "Lato Regular",
    styles: { fontFamily: "Lato", fontWeight: 400 },
  },
  {
    value: "Lato Bold",
    displayValue: "Lato Bold",
    styles: { fontFamily: "Lato", fontWeight: 600 },
  },

  {
    value: "Montserrat Light",
    displayValue: "Montserrat Light",
    styles: { fontFamily: "Montserrat", fontWeight: 300 },
  },
  {
    value: "Montserrat Regular",
    displayValue: "Montserrat Regular",
    styles: { fontFamily: "Montserrat", fontWeight: 400 },
  },
  {
    value: "Montserrat Bold",
    displayValue: "Montserrat Bold",
    styles: { fontFamily: "Montserrat", fontWeight: 700 },
  },

  {
    value: "Open Sans Light",
    displayValue: "Open Sans Light",
    styles: { fontFamily: "Open Sans", fontWeight: 300 },
  },
  {
    value: "Open Sans Regular",
    displayValue: "Open Sans Regular",
    styles: { fontFamily: "Open Sans", fontWeight: 400 },
  },
  {
    value: "Open Sans Bold",
    displayValue: "Open Sans Bold",
    styles: { fontFamily: "Open Sans", fontWeight: 700 },
  },

  {
    value: "PT Sans Regular",
    displayValue: "PT Sans Regular",
    styles: { fontFamily: "PT Sans", fontWeight: 400 },
  },
  {
    value: "PT Sans Bold",
    displayValue: "PT Sans Bold",
    styles: { fontFamily: "PT Sans", fontWeight: 700 },
  },

  {
    value: "PT Serif Regular",
    displayValue: "PT Serif Regular",
    styles: { fontFamily: "PT Serif", fontWeight: 400 },
  },
  {
    value: "PT Serif Regular",
    displayValue: "PT Serif Bold",
    styles: { fontFamily: "PT Serif", fontWeight: 700 },
  },

  {
    value: "Roboto Light",
    displayValue: "Roboto Light",
    styles: { fontFamily: "Roboto", fontWeight: 300 },
  },
  {
    value: "Roboto Regular",
    displayValue: "Roboto Regular",
    styles: { fontFamily: "Roboto", fontWeight: 400 },
  },
  {
    value: "Roboto Bold",
    displayValue: "Roboto Bold",
    styles: { fontFamily: "Roboto", fontWeight: 700 },
  },

  {
    value: "Source Code Pro Light",
    displayValue: "Source Code Light",
    styles: { fontFamily: "Source Code Pro", fontWeight: 300 },
  },
  {
    value: "Source Code Pro Regular",
    displayValue: "Source Code Regular",
    styles: { fontFamily: "Source Code Pro", fontWeight: 400 },
  },
  {
    value: "Source Code Pro Bold",
    displayValue: "Source Code Bold",
    styles: { fontFamily: "Source Code Pro", fontWeight: 700 },
  },

  {
    value: "Ubuntu Regular",
    displayValue: "Ubuntu Regular",
    styles: { fontFamily: "Ubuntu", fontWeight: 400 },
  },
  {
    value: "Ubuntu Bold",
    displayValue: "Ubuntu Bold",
    styles: { fontFamily: "Ubuntu", fontWeight: 700 },
  },
];

export const fontIcons = {
  name: "biarri-base14",
  css_prefix_text: "icon-",
  css_use_suffix: false,
  hinting: true,
  units_per_em: 1000,
  ascent: 860,
  glyphs: [
    {
      uid: "d17030afaecc1e1c22349b99f3c4992a",
      css: "star-empty",
      code: 59393,
      src: "fontawesome",
    },
    {
      uid: "f755a58fb985eeb70bd47d9b31892a34",
      css: "minus-squared",
      code: 61766,
      src: "fontawesome",
    },
    {
      uid: "18ef25350258541e8e54148ed79845c0",
      css: "minus-squared-alt",
      code: 61767,
      src: "fontawesome",
    },
    {
      uid: "fe6697b391355dec12f3d86d6d490397",
      css: "up-open",
      code: 59394,
      src: "fontawesome",
    },
    {
      uid: "399ef63b1e23ab1b761dfbb5591fa4da",
      css: "right-open",
      code: 59395,
      src: "fontawesome",
    },
    {
      uid: "d870630ff8f81e6de3958ecaeac532f2",
      css: "left-open",
      code: 59396,
      src: "fontawesome",
    },
    {
      uid: "ccddff8e8670dcd130e3cb55fdfc2fd0",
      css: "down-open",
      code: 59397,
      src: "fontawesome",
    },
    {
      uid: "fb1c799ffe5bf8fb7f8bcb647c8fe9e6",
      css: "right-dir",
      code: 59398,
      src: "fontawesome",
    },
    {
      uid: "9dc654095085167524602c9acc0c5570",
      css: "left-dir",
      code: 59399,
      src: "fontawesome",
    },
    {
      uid: "80cd1022bd9ea151d554bec1fa05f2de",
      css: "up-dir",
      code: 59401,
      src: "fontawesome",
    },
    {
      uid: "2d6150442079cbda7df64522dc24f482",
      css: "down-dir",
      code: 59402,
      src: "fontawesome",
    },
    {
      uid: "f3f90c8c89795da30f7444634476ea4f",
      css: "angle-left",
      code: 61700,
      src: "fontawesome",
    },
    {
      uid: "7bf14281af5633a597f85b061ef1cfb9",
      css: "angle-right",
      code: 61701,
      src: "fontawesome",
    },
    {
      uid: "5de9370846a26947e03f63142a3f1c07",
      css: "angle-up",
      code: 61702,
      src: "fontawesome",
    },
    {
      uid: "e4dde1992f787163e2e2b534b8c8067d",
      css: "angle-down",
      code: 61703,
      src: "fontawesome",
    },
    {
      uid: "745f12abe1472d14f8f658de7e5aba66",
      css: "angle-double-left",
      code: 61696,
      src: "fontawesome",
    },
    {
      uid: "fdfbd1fcbd4cb229716a810801a5f207",
      css: "angle-double-right",
      code: 61697,
      src: "fontawesome",
    },
    {
      uid: "63b3012c8cbe3654ba5bea598235aa3a",
      css: "angle-double-up",
      code: 61698,
      src: "fontawesome",
    },
    {
      uid: "dfec4ffa849d8594c2e4b86f6320b8a6",
      css: "angle-double-down",
      code: 61699,
      src: "fontawesome",
    },
    {
      uid: "130380e481a7defc690dfb24123a1f0c",
      css: "circle",
      code: 61713,
      src: "fontawesome",
    },
    {
      uid: "422e07e5afb80258a9c4ed1706498f8a",
      css: "circle-empty",
      code: 61708,
      src: "fontawesome",
    },
    {
      uid: "5774d0a0e50f6eefc8be01bd761e5dd3",
      css: "circle-thin",
      code: 61915,
      src: "fontawesome",
    },
    {
      uid: "4ffd8122933b9ee0183b925e1554969f",
      css: "circle-notch",
      code: 61902,
      src: "fontawesome",
    },
    {
      uid: "81bb68665e8e595505272a746db07c7a",
      css: "dot-circled",
      code: 61842,
      src: "fontawesome",
    },
    {
      uid: "823a9e02e643318116fea40a00190e4e",
      css: "asterisk",
      code: 59403,
      src: "fontawesome",
    },
    {
      uid: "474656633f79ea2f1dad59ff63f6bf07",
      css: "star",
      code: 59404,
      src: "fontawesome",
    },
    {
      uid: "4ba33d2607902cf690dd45df09774cb0",
      css: "plus-circled",
      code: 59392,
      src: "fontawesome",
    },
    {
      uid: "1a5cfa186647e8c929c2b17b9fc4dac1",
      css: "plus-squared",
      code: 61694,
      src: "fontawesome",
    },
    {
      uid: "43ab845088317bd348dee1d975700c48",
      css: "ok-circled",
      code: 59405,
      src: "fontawesome",
    },
    {
      uid: "ad33e708f4d2e25c5056c931da1528d6",
      css: "ok-circled2",
      code: 59406,
      src: "fontawesome",
    },
    {
      uid: "17ebadd1e3f274ff0205601eef7b9cc4",
      css: "help-circled",
      code: 59407,
      src: "fontawesome",
    },
    {
      uid: "e82cedfa1d5f15b00c5a81c9bd731ea2",
      css: "info-circled",
      code: 59408,
      src: "fontawesome",
    },
    {
      uid: "c76b7947c957c9b78b11741173c8349b",
      css: "attention",
      code: 59409,
      src: "fontawesome",
    },
    {
      uid: "b035c28eba2b35c6ffe92aee8b0df507",
      css: "attention-circled",
      code: 59410,
      src: "fontawesome",
    },
    {
      uid: "0d08dbb1dd648a43bdea81b7e6c9e036",
      css: "location",
      code: 59411,
      src: "fontawesome",
    },
    {
      uid: "8933c2579166c2ee56ae40dc6a0b4dc6",
      css: "angle-circled-left",
      code: 61751,
      src: "fontawesome",
    },
    {
      uid: "94089b37297572e936b0943bcfa041d3",
      css: "angle-circled-right",
      code: 61752,
      src: "fontawesome",
    },
    {
      uid: "8cbd5bcfb00043b8094fd7ac21ae5e06",
      css: "angle-circled-up",
      code: 61753,
      src: "fontawesome",
    },
    {
      uid: "c35e0796f6f806945a44b1655ce7bbe7",
      css: "angle-circled-down",
      code: 61754,
      src: "fontawesome",
    },
    {
      uid: "5717236f6134afe2d2a278a5c9b3927a",
      css: "play-circled",
      code: 61764,
      src: "fontawesome",
    },
    {
      uid: "e835d9d0f2ee39ae01b01cc6a6fa24ab",
      css: "play-circled2",
      code: 59412,
      src: "fontawesome",
    },
    {
      uid: "b624a1e512819d410ddbee84e6918b9d",
      css: "stop",
      code: 59413,
      src: "fontawesome",
    },
    {
      uid: "2c2ca8a96b31781c9c8056d05c0a8980",
      css: "blank",
      code: 61640,
      src: "fontawesome",
    },
    {
      uid: "5434b2bf3a3a6c4c260a11a386e3f5d1",
      css: "stop-circle-o",
      code: 62094,
      src: "fontawesome",
    },
    {
      uid: "3363990fa5a224d75ed1740e1ec50bb6",
      css: "stop-circle",
      code: 62093,
      src: "fontawesome",
    },
    {
      uid: "9c0fabd22e5b65b3ef7ed847eb864bcb",
      css: "pause-circle",
      code: 62091,
      src: "fontawesome",
    },
    {
      uid: "4ae8cbc2c1329cb5dbe6e6995d8c68be",
      css: "pause-circle-o",
      code: 62092,
      src: "fontawesome",
    },
    {
      uid: "d2c6b59d9a239140cb76be0627480855",
      css: "dot",
      code: 59414,
      src: "entypo",
    },
    {
      uid: "13b11ab72c6ae9c97ee12428e17f3a4e",
      css: "dot-2",
      code: 59415,
      src: "entypo",
    },
    {
      uid: "d10920db2e79c997c5e783279291970c",
      css: "dot-3",
      code: 59416,
      src: "entypo",
    },
    {
      uid: "4815ddddea76d90e367a9694a93b7e24",
      css: "resize-full",
      code: 59417,
      src: "iconic",
    },
    {
      uid: "776687efbdb973ebc67e049cc4c41b13",
      css: "resize-full-alt",
      code: 59418,
      src: "iconic",
    },
    {
      uid: "b05c5a209b56e7ea6632a31e4bd0b41d",
      css: "resize-small",
      code: 59419,
      src: "iconic",
    },
    {
      uid: "8596dc69a5e7e0297e8926b6e2563509",
      css: "resize-small-alt",
      code: 59420,
      src: "iconic",
    },
    {
      uid: "7dde84b4f180e3c07523faecda81caa6",
      css: "resize-vertical",
      code: 59421,
      src: "iconic",
    },
    {
      uid: "63493102fc6b827e4a7be30b13cbf3b4",
      css: "resize-horizontal",
      code: 59422,
      src: "iconic",
    },
    {
      uid: "be25d23b15c3294588947a6b959811ec",
      css: "move",
      code: 59423,
      src: "iconic",
    },
    {
      uid: "d6944e1d9af302093d43b299bbecbbbe02",
      css: "home",
      code: 59424,
      src: "iconic",
    },
    {
      uid: "too2rspp5e6z6m0y6lconmv23uw3qlqo",
      css: "location-1",
      code: 59425,
      src: "modernpics",
    },
    {
      uid: "9de7039d5c9559926fe13880aa03ff51",
      css: "asterisk-1",
      code: 59426,
      src: "elusive",
    },
    {
      uid: "3fe5b810589a16bc691e3e979b2789dc",
      css: "warning",
      code: 59427,
      src: "elusive",
    },
    {
      uid: "f84e243b91683403debed23b950f7efb",
      css: "bell",
      code: 59428,
      src: "elusive",
    },
    {
      uid: "c54c00a5b7fba94b9fbc940de38a7beb",
      css: "location-circled",
      code: 59429,
      src: "elusive",
    },
    {
      uid: "5d2d07f112b8de19f2c0dbfec3e42c05",
      css: "spin5",
      code: 59448,
      src: "fontelico",
    },
    {
      uid: "bc71f4c6e53394d5ba46b063040014f1",
      css: "cw",
      code: 59400,
      src: "fontawesome",
    },
    {
      uid: "f9c3205df26e7778abac86183aefdc99",
      css: "ccw",
      code: 59430,
      src: "fontawesome",
    },
    {
      uid: "a73c5deb486c8d66249811642e5d719a",
      css: "arrows-cw",
      code: 59431,
      src: "fontawesome",
    },
    {
      uid: "aa035df0908c4665c269b7b09a5596f3",
      css: "sun",
      code: 61829,
      src: "fontawesome",
    },
    {
      uid: "2d14369ed7f129e779eaa30195368218",
      css: "light-down",
      code: 59432,
      src: "entypo",
    },
    {
      uid: "f8e120c48c070f8afd2d072651b4fb9f",
      css: "light-up",
      code: 59433,
      src: "entypo",
    },
    {
      uid: "f6fa10cb3ffec7becb60834f50bb0182",
      css: "adjust",
      code: 59434,
      src: "entypo",
    },
    {
      uid: "5f3a0ebf2d13f60ec7ed76eb41ba9bb2",
      css: "air",
      code: 59435,
      src: "entypo",
    },
    {
      uid: "898ddc67cb7d9ae53dbd4cce78043e9e",
      css: "flow-cascade",
      code: 59436,
      src: "entypo",
    },
    {
      uid: "3d349daaea304032a7669b4f24931b6c",
      css: "shuffle",
      code: 59437,
      src: "entypo",
    },
    {
      uid: "8e0220796066428d6ddea96a4f1b5131",
      css: "loop",
      code: 59438,
      src: "entypo",
    },
    {
      uid: "7ad4d2306ebda8452e5e3eff3cd8241c",
      css: "thumbs-up",
      code: 59439,
      src: "entypo",
    },
    {
      uid: "429fba993b729174468a9493613ad1ba",
      css: "thumbs-down",
      code: 59440,
      src: "entypo",
    },
    {
      uid: "3a230e539a4f3b904debd04c6c155034",
      css: "help",
      code: 59441,
      src: "entypo",
    },
    {
      uid: "d3d5f318c8e9a58245c2e48c7ce0f57d",
      css: "tag",
      code: 59442,
      src: "iconic",
    },
    {
      uid: "e713848e7db2b6593236d147dd4e81ad",
      css: "tag-empty",
      code: 59443,
      src: "iconic",
    },
    {
      uid: "f1ecd8633b0ec659877c02cd796df1fd",
      css: "chart-bar",
      code: 59444,
      src: "iconic",
    },
    {
      uid: "de456a594a2a4e68413f60d7e2910596",
      css: "layers-alt",
      code: 59445,
      src: "iconic",
    },
    {
      uid: "d2c499942f8a7c037d5a94f123eeb478",
      css: "layers",
      code: 59446,
      src: "iconic",
    },
    {
      uid: "o1c30odwfvgcvi5b4jsg95sur8v6ghsp",
      css: "grid",
      code: 59447,
      src: "modernpics",
    },
    {
      uid: "5b5931e9c43dba30bf21cf90d8fcdd3e",
      css: "fond-address",
      code: 59449,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M0 500C0 776.1 223.9 1000 500 1000S1000 776.1 1000 500 776.1 0 500 0 0 223.9 0 500ZM906.3 500C906.3 724.4 724.4 906.3 500 906.3S93.8 724.4 93.8 500 275.6 93.8 500 93.8 906.3 275.6 906.3 500ZM690.2 653.6L778.6 565.2 500 286.6 221.4 565.2 309.8 653.6 500 463.4Z",
        width: 1000,
      },
      search: ["fond-address"],
    },
    {
      uid: "22e6d1209d8bdee539224fc88c991bfc",
      css: "fond-address-distribution-unsplit",
      code: 59450,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M493.5 940.5C245 940.5 43.5 739 43.5 490.5S245 40.5 493.5 40.5 943.5 242 943.5 490.5 742 940.5 493.5 940.5ZM493.5 215.5C341.5 215.5 218.5 338.5 218.5 490.5S341.5 765.5 493.5 765.5 768.5 642.5 768.5 490.5 645.5 216 493.5 215.5ZM495.5 96C715.5 96 894 274 894 493.5S715.5 891 495.5 891 97 713 97 493.5 276 96 495.5 96M495.5 816.5C674 816.5 819 672 819 493.5S674 170.5 495.5 170.5 172 315.5 172 493.5 317 816.5 495.5 816.5M495.5-3C220.5-3-2.5 219.5-2.5 494S220.5 991 495.5 991 993.5 768.5 993.5 494 770.5-3 495.5-3ZM495.5 717C372 717 271.5 617 271.5 493.5S372 270 495.5 270 719.5 370 719.5 493.5 619.5 717 495.5 717Z",
        width: 1000,
      },
      search: ["fond-address-distribution-unsplit"],
    },
    {
      uid: "c56681e603c2da31ce5186ba2d81b18a",
      css: "fond-cabinet",
      code: 59451,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M124.1 725C51.5 592.5-0.6 495.2 0 493.2 0.6 491.4 56.5 388.6 124.4 264.9L247.7 39.9 750 38.5 875 266.8 1000 495 962.6 563.4C942 601 885.6 704.1 837.3 792.4L749.5 952.9 499.3 953 249.1 953 124.1 724.7ZM771.9 783.3L829.6 678.2 664.5 677.5H334.1L168.8 678.2 219.2 770.2C246.9 820.9 272.9 868.2 276.9 875.4L284.1 888.5H714.3L771.9 783.3ZM334.5 385.6C424.1 230.4 497.4 103 497.4 102.5 497.4 101.8 449.3 101.8 390.5 101.8L283.7 102.5 176.2 298.8 68.6 495.1 115.2 580.3C140.9 627.1 163.1 667.7 164.5 670.5 167.1 675.3 167.3 675.3 169.4 671.5 170.6 669.3 244.9 540.6 334.5 385.4ZM884.5 577.9C921.9 509.5 928.7 495.8 927.2 491.7 926.1 489.1 877.8 400.3 819.7 294.3L714 101.7H606.7C547.7 101.7 499.5 101.7 499.5 102.4 499.5 103.2 822.6 663.3 827.3 670.6L830.5 675.6 835.2 667.5C837.8 663.1 860 622.8 884.5 578Z",
        width: 1000,
      },
      search: ["fond-cabinet"],
    },
    {
      uid: "df22d3da2e9dafa88997120870862e5a",
      css: "fond-closure",
      code: 59452,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M439.6 962.5C236.3 934.7 74.3 780.9 36.8 579.9 28.8 537.1 29.6 444.9 38.2 404.7 52.9 336.4 83 269.2 122.5 216.7 261.2 32.2 507.3-25.6 711.5 78.5 758.6 102.5 793.6 127.9 830.8 165.1 996.3 330.5 1015.1 590.7 875.1 777.3 805.6 869.9 703 934.4 589.3 957.1 555.1 963.9 471.9 966.9 439.6 962.5ZM885.6 720.1C885.6 718.4 498.6 48 495.8 45 494.1 43.2 103.1 715.5 103.1 720.2 103.1 720.9 279.2 721.5 494.3 721.5S885.5 720.9 885.5 720.1Z",
        width: 1000,
      },
      search: ["fond-closure"],
    },
    {
      uid: "31638293fab4646af5eccf6499a60e30",
      css: "fond-exchange-buffer",
      code: 59453,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M630.5 951.5A115.4 115.4 0 0 1 514.1 830.3V689.6C514.1 665.4 500 665.4 494.7 665.4 481.6 665.4 475.3 673.6 475.3 689.6V830.3A115.4 115.4 0 0 1 358.9 951.5H291A115.9 115.9 0 0 1 174.6 830.3V597.5H155.2A101.4 101.4 0 0 1 55.3 533 116.9 116.9 0 0 1 89.2 405.9L485 63.5 900.1 403A117.4 117.4 0 0 1 935 531 101.4 101.4 0 0 1 834.1 597.5H814.7V830.3A115.9 115.9 0 0 1 698.4 951.5ZM485 127.1L868.1 442.3C906.9 476.2 902 549 834.1 549H766.2V830.3S766.2 903 698.4 903H630.5A67.9 67.9 0 0 1 562.6 830.3V689.6S562.6 616.9 494.7 616.9 426.8 689.6 426.8 689.6V830.3A67.9 67.9 0 0 1 358.9 903H291C223.1 903 223.1 830.3 223.1 830.3V549H155.2C87.3 549 82.4 476.2 121.2 442.3L485 127.1M485 0L421.4 53.8 57.7 369.5A167.3 167.3 0 0 0 9.2 550.4 145.5 145.5 0 0 0 126.1 643.5V830.3A163.9 163.9 0 0 0 291 1000H358.9A165.9 165.9 0 0 0 494.7 929.7 165.9 165.9 0 0 0 630.5 1000H698.4A163.9 163.9 0 0 0 863.2 830.3V643.5A145.5 145.5 0 0 0 979.6 550 167.8 167.8 0 0 0 931.1 369.1L931.1 369.1H931.1L548 53.8 485 0Z",
        width: 989,
      },
      search: ["fond-exchange-buffer"],
    },
    {
      uid: "8af7496d135b4d55b77a932865d5a9dd",
      css: "fond-joint",
      code: 59454,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M445.2 962.5C359.5 952.6 273.8 916 201.1 858.4L194.1 852.8 499.2 547.8 805.1 853.8 777.6 874.3C747 897.1 682.4 930.4 645.5 942.4 583 962.7 510.4 970 445.2 962.5ZM127 781.3C82.2 721.9 54.6 661.3 38.6 587.3 28.8 541.8 28.8 449.1 38.6 403.6 54.9 328.5 91.6 250.6 135.5 198.3L141.8 190.8 294.9 343.9 448.1 497 142.8 802.2 127 781.3ZM346.5 293.7L193.9 141.1 219.6 122.3C292.1 69 368.5 39.8 457.5 31.2 562.9 21 672.5 49.3 763.2 109.9 774.7 117.6 789 128.1 794.9 133.2L805.8 142.5 654 294.4C570.6 378 501.6 446.4 500.7 446.4 499.8 446.4 430.5 377.7 346.5 293.7ZM703.6 650L551.8 498.2 857.2 193.2 865.1 202.5C883.3 224 914.8 275.5 928.4 306 957 370.1 968.8 426 968.8 498.2 968.8 604.4 939.1 694.1 875.2 781.1 866.8 792.5 859 801.8 857.7 801.8S787 733.5 703.6 650Z",
        width: 1000,
      },
      search: ["fond-joint"],
    },
    {
      uid: "0556ed5749f51ce4c202844d9cb3cc64",
      css: "fond-pit",
      code: 59455,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M875 0H125C56.2 0 0 56.3 0 125V875C0 943.8 56.3 1000 125 1000H875C943.8 1000 1000 943.8 1000 875V125C1000 56.2 943.8 0 875 0ZM875 875H125V125H875V875Z",
        width: 1000,
      },
      search: ["fond-pit"],
    },
    {
      uid: "b686cf0f031356a3864e671863495149",
      css: "fond-pole",
      code: 59456,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M200.4 873.7C183.6 870.9 161.3 858.6 149.3 845.5 116.2 809.2 116.2 756.9 149.4 721.8 166.8 703.3 184.4 694.8 210.1 692.1L224.2 690.6 322.8 595.5C377 543.2 421.1 499.8 420.8 499 420.4 498.2 375.7 454.7 321.4 402.3L222.5 306.9 207 305.9C156.4 302.7 116.9 251.7 125.9 201.2 131.3 170.8 149.3 147.2 177.7 133.2 193.3 125.5 194.5 125.2 215.9 125.2 237 125.2 238.7 125.6 253.8 132.8 283.6 147 302.1 172.4 306.8 205.7L309.2 222.5 370 283.8C403.5 317.5 446.2 361 465 380.4L499.1 415.7 690.3 221.9 692.8 207.1C695.9 188.3 700 176.7 707.8 165.4 753.4 99.2 851.9 116.4 872.8 194.3 886.6 246 846.6 302.3 793.5 305.8L778.2 306.8 714.4 370C679.3 404.8 634.8 448.4 615.5 467L580.6 500.8 777.3 690.6 790 692.1C834.6 697.2 866.7 726.6 873.9 769 878.7 796.7 870.1 824.7 850.3 845.6 821.9 875.6 781.9 883.2 744.5 865.6 716.9 852.7 698.3 826.5 693 793L690.3 776 498.5 581.7 478.2 602.7C467 614.2 424.4 658 383.5 699.9L309.2 776.1 306.7 793.5C303.3 816.7 295.7 832.1 280 847.7 265.4 862.3 249.4 870.6 230.2 873.7 215.5 876.1 215 876.1 200.4 873.7V873.7Z",
        width: 1000,
      },
      search: ["fond-pole"],
    },
    {
      uid: "278e7976836b9edd741f2d4d6bfeae00",
      css: "fond-splice",
      code: 59457,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M380.8 612.5C440.7 551.9 489.2 501.8 488.6 501.2 488 500.6 381 544.3 250.7 598.2S10.7 697.2 6.9 698.3C0.4 700.2 0 688.3 0 488.8 0 372.6 0.9 277.5 2.1 277.5 3.8 277.5 411 444.9 479.3 473.6 490.3 478.3 498.1 484.2 498.3 487.9 498.5 493 499.2 492.8 501.5 487 503.6 481.8 575 450.5 749.1 378.6 883.7 323 995.2 277.5 996.9 277.5 998.6 277.5 1000 373.1 1000 489.9V702.4L759.9 603.2C627.8 548.6 516.2 503 511.9 501.7 507.5 500.4 554.6 549.6 616.6 611.1L729.2 722.8H272.1L380.8 612.5Z",
        width: 1000,
      },
      search: ["fond-splice"],
    },
    {
      uid: "4e7814a780592aeb4119a66e37938297",
      css: "fond-wireless-terminal",
      code: 59458,
      src: "custom_icons",
      selected: true,
      svg: {
        path: "M497.4 570L437.4 998.2C437.4 998.5 437.6 998.8 437.8 998.8H585.6C585.9 998.8 586.1 998.5 586 998.2L526 570M511.7 692.4C405 692.4 318.5 602.8 318.5 492.1S405 291.8 511.7 291.8 704.9 381.5 704.9 492.1 618.4 692.4 511.7 692.4Z",
        width: 1000,
      },
      search: ["fond-wireless-terminal"],
    },
    {
      uid: "ebffa4e734c8379ffee4fbfe49264d94",
      css: "lifebuoy",
      code: 61901,
      src: "fontawesome",
    },
  ],
};
