import { Box, styled, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

export const Container = styled(Box)<{ selected: boolean; isDragging?: boolean; indent: number }>(
  ({ theme, selected, isDragging = false, indent = 2 }) => ({
    display: "flex",
    paddingInlineStart: theme.spacing(indent),
    paddingInlineEnd: theme.spacing(0.5),
    cursor: "pointer",
    height: 24,
    alignItems: "center",
    backgroundColor: selected ? blue[50] : undefined,
  })
);

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  "& svg": {
    width: 12,
    height: 12,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  flexGrow: 1,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));
