import _ from "lodash";

import { findMapStyleWithId } from "fond/map/styles";

export class MapListener {
  constructor(map, callback) {
    this.map = map;
    this.callback = callback;
    this.viewState = null;

    const onViewChange = this.onViewChange.bind(this);
    map.on("idle", onViewChange);
  }

  onViewChange() {
    const viewState = getMapViewState(this.map);
    if (!_.isEqual(viewState, this.viewState)) {
      this.callback(viewState);
      this.viewState = viewState;
    }
  }
}

function getMapViewState(map) {
  return {
    location: {
      camera: {
        center: map.getCenter(),
        zoom: map.getZoom(),
      },
    },
    style: findMapStyleWithId(map.style.stylesheet.id),
    loading: false,
  };
}
