import { styled } from "@mui/material/styles";

import { LinearProgressWithLabel } from "fond/widgets";

/**
 * LinearProgressWithLabel with a gradual gradient from start to end color applied.
 */
export interface GradientLinearProgressWithLabelProps {
  startcolor: string;
  endcolor: string;
}

export const GradientLinearProgressWithLabel = styled(LinearProgressWithLabel)<GradientLinearProgressWithLabelProps>(
  ({ theme, startcolor, endcolor }) => ({
    height: 4,
    borderRadius: 8,

    "&.MuiLinearProgress-colorPrimary": { background: "#d3eafd" },
    "& .MuiLinearProgress-bar": { background: `linear-gradient(90deg, ${startcolor} 3.01%, ${endcolor} 88.89%)` },
  })
);
