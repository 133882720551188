import { useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, useGetInvitationsQuery } from "fond/api";

import PendingInvites from "./PendingInvites/PendingInvites";
import UserList from "./UserList/UserList";

import { Container, UserManagementTabContent } from "../common.styles";
import { TabBadge } from "./PendingInvites/PendingInvites.styles";

const UserManagement: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: pendingInvites } = useGetInvitationsQuery(selectedAccount?.ID ?? skipToken);

  const handleOnTabSelect = (_: React.SyntheticEvent<Element, Event>, value: number) => {
    setSelectedTabIndex(value);
  };

  return (
    <Container data-testid="user-management">
      <Tabs onChange={handleOnTabSelect} value={selectedTabIndex}>
        <Tab label="User List" data-testid="user-list-tab" />
        <Tab
          sx={{ overflow: "visible" }}
          label={<TabBadge badgeContent={pendingInvites?.length}>Pending Invites</TabBadge>}
          data-testid="pending-invites-tab"
        />
      </Tabs>
      <UserManagementTabContent>
        {selectedTabIndex === 0 && <UserList />}
        {selectedTabIndex === 1 && <PendingInvites />}
      </UserManagementTabContent>
    </Container>
  );
};

export default UserManagement;
