import { ComponentProps, forwardRef } from "react";
import { Breadcrumbs, Chip, styled } from "@mui/material";

const CrumbedChipRef = forwardRef(({ ...props }: ComponentProps<typeof Chip>, ref: React.Ref<HTMLDivElement>) => (
  <Chip data-testid="breadcrumb-item" ref={ref} {...props} />
));
CrumbedChipRef.displayName = "CrumbedChip";

export const CrumbedChip = styled(CrumbedChipRef)(({ theme, onClick }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  height: theme.spacing(4),
  color: theme.palette.grey[800],
  cursor: onClick ? "pointer" : "default",
  fontSize: "1rem",
  fontWeight: theme.typography.fontWeightRegular,
  "&:hover, &:focus": {
    backgroundColor: onClick ? theme.palette.grey[100] : "none",
  },
  "&:active": {
    boxShadow: "none",
  },
}));

const MAX_CRUMBS = 3;
export const Crumbs = styled((props: ComponentProps<typeof Breadcrumbs>) => (
  <Breadcrumbs data-testid="breadcrumb" aria-label="breadcrumb" maxItems={MAX_CRUMBS} {...props} />
))(({ theme }) => ({
  "& .MuiBreadcrumbs-separator": {
    margin: theme.spacing(0.5),
  },
}));
