import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import { actionRefMiddleware } from "fond/actionref";
import { apiSlice } from "fond/api";
import { CognitoAction, reducer as cognitoReducer } from "fond/cognito/redux";
import { localStorageMiddleware } from "fond/middleware/localStorage";
import { reducer as notificationReducer } from "fond/notifications/redux";
import { reducer as pageReducer } from "fond/page/redux";
import { reducer as bomReducer } from "fond/project/bom/redux";
import { reducer as projectReducer } from "fond/project/redux";
import { reducer as solveReducer } from "fond/project/solve/redux";
import { reducer as projectsReducer } from "fond/projects/redux";
import { reducer as redirectPathReducer } from "fond/redirectPath/redux";
import { attachmentsReducer } from "fond/redux/attachments";
import { bomTagSlice } from "fond/redux/bom";
import { commentReducer } from "fond/redux/comments";
import { featuresReducer } from "fond/redux/features";
import { importsReducer } from "fond/redux/imports";
import { stylesSlice } from "fond/redux/styles";
import { reducer as expandedReducer } from "fond/widgets/Navigation/redux";

const appReducer = combineReducers({
  bom: bomReducer,
  page: pageReducer,
  expanded: expandedReducer,
  redirectPath: redirectPathReducer,
  cognito: cognitoReducer,
  projects: projectsReducer,
  project: projectReducer,
  attachments: attachmentsReducer,
  solve: solveReducer,
  notifications: notificationReducer,
  features: featuresReducer,
  imports: importsReducer,
  comments: commentReducer,
  styles: stylesSlice.reducer,
  bomTags: bomTagSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === CognitoAction.LOGGED_OUT) {
    state = undefined; // eslint-disable-line no-param-reassign
  }

  return appReducer(state, action);
};

export function createStore(initialState, extraMiddlewares = []) {
  /* Enable dev only tools */
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== "production") {
    // Used with Redux dev tools, found here:
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  let middlewares = [localStorageMiddleware, actionRefMiddleware, thunkMiddleware, apiSlice.middleware];

  if (process.env.NODE_ENV === "test") {
    // If we're running tests, add a middleware that logs all actions and make
    // the list of actions accessible via `store.getActions()` as if we were
    // using a redux-mock-store.
    const loggerMiddleware = (s) => {
      return (next) => {
        return (action) => {
          store.actions.push(action);
          return next(action);
        };
      };
    };
    middlewares = [loggerMiddleware, ...middlewares];
  }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    // Todo - immutableCheck should be set to true
    // middleware: (gdm) => gdm({ serializableCheck: false, immutableCheck: false }).concat(...middlewares, ...extraMiddlewares),
    middleware: [...middlewares, ...extraMiddlewares],
  });

  if (process.env.NODE_ENV === "test") {
    store.actions = [];
    store.getActions = () => store.actions;

    // Filter out meta-actions eg. the (dispatch, getState, RTK api) functions returned
    // by redux-thunk actions.
    store.getRegularActions = () => store.actions.filter((a) => a != null && a.type != null && a.type.indexOf("api/config") === -1);

    store.clearActions = () => {
      store.actions = [];
    };
  }

  return store;
}

const store = createStore();

if (typeof window !== "undefined") {
  /**
   * Make the store and some convenience functions available use in browser dev
   * tools.
   */
  window.store = store;

  /**
   * Return the current project.

     @returns {
       project: <project payload>,
       data: <geojson layers and bom>,
       uploads: <any current uploads in progress>,
       view: <current map view settings (camera, visible layers, map style)>
     }
   */
  store.getCurrentProject = function getCurrentProject() {
    const state = store.getState();
    return state.project.projects[state.project.projectId];
  };

  /**
   * Returns the layer `layerId` for the current project.
   * Eg. store.getLayer('inAddress')
   *
   * @returns a GeoJSON FeatureCollection
   */
  store.getLayer = function getLayer(layerId) {
    return this.getCurrentProject().data.layers[layerId];
  };

  /**
   * If Mapbox Draw is active, return all the (editing and non-editing) features
   * for the layer `layerId`.
   *
   * @returns an array of GeoJSON Features
   */
  store.getDrawFeatures = function getDrawFeatures(layerId) {
    return Array.from(window.mapboxDraw.iterFeatures(layerId)).map((fs) => fs[0]);
  };
}

export default store;
