import React from "react";

import { CrumbedChip, Crumbs } from "fond/projects/Breadcrumbs/breadcrumbs.styles";
import { useBreadcrumbs } from "fond/projects/Breadcrumbs/hooks/useBreadcrumbs";

const StarredBreadcrumbs: React.FC = () => {
  const { reverseAncestors, handleOnClickNavigate } = useBreadcrumbs();

  return (
    <Crumbs>
      <CrumbedChip label="Starred" onClick={reverseAncestors.length === 0 ? undefined : handleOnClickNavigate("/starred/")} />
      {reverseAncestors.slice(0, -1).map((folder) => (
        <CrumbedChip key={folder.ID} label={folder.Name} onClick={handleOnClickNavigate(`/folders/${folder.ID}`)} />
      ))}
      {!!reverseAncestors.length && <CrumbedChip label={reverseAncestors[reverseAncestors.length - 1].Name} />}
    </Crumbs>
  );
};

export default StarredBreadcrumbs;
