import { forwardRef } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { selectDraftData } from "fond/api";
import { StyleRow } from "fond/styleEditor";
import { Store } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { Container } from "./styleList.styles";

interface IProps {
  selectedId?: string;
  layer: LayerConfig | SublayerConfig;
  onSelect(id: string): void;
  onDragEnd(result: DropResult): void;
}

const StyleList = forwardRef(({ selectedId, layer, onSelect, onDragEnd }: IProps, ref: any) => {
  const config = useSelector((state: Store) => selectDraftData(state));

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="StyleList">
          {(drop) => (
            <Box {...drop.droppableProps} ref={drop.innerRef}>
              {layer.Styles.map((styleId, index) => {
                return (
                  <Draggable key={styleId} draggableId={styleId} index={index}>
                    {(drag, snapshot) => (
                      <StyleRow
                        ref={drag.innerRef}
                        styleID={styleId}
                        draggableProps={drag.draggableProps}
                        dragHandleProps={drag.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        indent={layer.Type === "LAYER" ? 5 : 6}
                        config={config}
                      />
                    )}
                  </Draggable>
                );
              })}
              {drop.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
});

StyleList.displayName = "StyleList";
export default StyleList;
