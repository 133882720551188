import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * A column shaped Box positioned along the right margin of the screen as an absolute, on top of the page content.
 * The children are flexed by column with a flex-end justification.
 *
 *               +---------------------+
 *               |              +-----+|
 *               |              |     ||
 *               |              |     ||
 *               |              |     ||
 *               |              | </> ||
 *               |              |     ||
 *               |              |     ||
 *               |              |     ||
 *               |              +-----+|
 *               +---------------------+
 */
export interface AbsoluteFlexColumnBoxProps {}

export const AbsoluteFlexColumnBox = styled(Box)<AbsoluteFlexColumnBoxProps>(({ theme }) => ({
  // The contents is flexed in a column and justified to the bottom of the page.
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  // Absolute positioning. The height calc achieves a consistent bottom and top margin when at full height.
  position: "absolute",
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  gap: theme.spacing(2),
  maxHeight: `calc(100vh - ${theme.spacing(2)})`,
  // The index is set to be above our modals but below snackbar notifications.
  zIndex: 1400,
}));
