import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Tooltip, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, selectCurrentSubscription, useGetAccountSubscriptionsQuery, useGetAccountUsersQuery } from "fond/api";
import { PRODUCT_CATEGORY_KEY } from "fond/constants";
import { useFeatureFlag } from "fond/featureFlags";
import { HeaderBox, UsageBox } from "fond/settings/AccountManagement/common.styles";
import LicenseAssignmentList from "fond/settings/AccountManagement/LicenseAssignmentList";
import LicenseInfoList from "fond/settings/AccountManagement/LicenseInfoList";
import LicenseUsage from "fond/settings/AccountManagement/LicenseUsage";
import { SubscriptionStatus } from "fond/types";
import { ProductCategory } from "fond/types/stripe";

interface IFormData {
  accountName: string;
}

const LicenseDetails: React.FC = () => {
  const currentAccount = useSelector(selectCurrentAccount);
  const { data: allocations } = useGetAccountUsersQuery(currentAccount?.ID ?? skipToken);
  const { data: accountSubscriptions } = useGetAccountSubscriptionsQuery(currentAccount?.ID ?? skipToken);
  const currentSubscription = useSelector((state) => currentAccount && selectCurrentSubscription(state, currentAccount.ID));
  const isActiveSubscription = currentSubscription?.Status === SubscriptionStatus.Active;
  const { value: isBuyLicenseFlagEnabled } = useFeatureFlag("buy_licenses");
  const navigate = useNavigate();

  const navigateToCheckout = () => {
    navigate(`/checkout?${PRODUCT_CATEGORY_KEY}=${ProductCategory.license}`);
  };

  return (
    <Form<IFormData>
      onSubmit={() => {}}
      render={() => (
        <form>
          <HeaderBox>
            <Typography variant="h3" mt={2}>
              License Details
            </Typography>
            {/* <Button variant="contained" color="primary">
              Manage licenses
            </Button> */}
            {isBuyLicenseFlagEnabled && (
              <Tooltip title={!isActiveSubscription ? "A subscription needs to be purchased in order to buy licenses" : ""}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={navigateToCheckout}
                    disabled={!isActiveSubscription}
                    data-testid="buy-license-button"
                  >
                    Buy licenses
                  </Button>
                </span>
              </Tooltip>
            )}
          </HeaderBox>
          <UsageBox>
            <LicenseUsage accountSubscriptions={accountSubscriptions} allocations={allocations} />
          </UsageBox>
          <LicenseInfoList accountSubscriptions={accountSubscriptions} />
          <Typography variant="h3" mt={2} mb={3}>
            Assignments
          </Typography>
          <LicenseAssignmentList allocations={allocations} />
        </form>
      )}
    />
  );
};

export default LicenseDetails;
