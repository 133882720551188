import React from "react";
import { Box, Typography } from "@mui/material";
import { PaperProps } from "@mui/material/Paper";

import { useStackedNavigationContext } from "./StackedNavigation";

import { NavigationButton, NavigationButtonIcon } from "./StackedNavigation.styles";

interface IProps extends Pick<PaperProps, "variant" | "elevation" | "sx"> {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  subtitle?: string;
  checked?: boolean;
  // This sets the next screen when navigation button is clicked
  target?: string;
  // Intended for calling functions aside from opening a new screen;
  // Will only be called if target is not set
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  disabled?: boolean;
  rightElement?: React.ReactNode;
}

const StackedNavigationButton: React.FC<IProps> = ({
  icon,
  title,
  subtitle,
  checked = false,
  target,
  onClick,
  disabled = false,
  rightElement,
  ...props
}) => {
  const { open } = useStackedNavigationContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return;
    if (typeof target !== "undefined") {
      open(target);
      return;
    }
    onClick?.(event);
  };

  return (
    <NavigationButton elevation={0} onClick={handleClick} disabled={disabled} checked={checked} variant="outlined" {...props}>
      <Box display="flex" alignItems="center">
        <NavigationButtonIcon className="navigation-button-icon" size={subtitle ? "large" : "small"}>
          {icon}
        </NavigationButtonIcon>
        <Box>
          <Typography variant="body3" fontWeight="500" lineHeight="1.4" display="block">
            {title}
          </Typography>
          {subtitle && (
            <Typography fontSize={11} lineHeight="1.4" color={(theme) => theme.palette.biarri.secondary.grey}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      {rightElement || <Box />}
    </NavigationButton>
  );
};

export default StackedNavigationButton;
