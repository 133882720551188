import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: 12,
}));

export const Stop = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})<{ error?: boolean }>(({ theme, error }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

export const StopHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5),
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  backgroundColor: theme.palette.background.default,
}));

export const StopTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
}));

export const StopDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
}));

export const Tooltip = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: theme.palette.common.white,
  backgroundColor: "rgba(1, 22, 31, 0.90)",
  borderRadius: theme.shape.borderRadius,
}));
