import React, { SVGProps } from "react";
import { Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { useGetAccountSubscriptionUsageQuery } from "fond/api";
import { DailyUsageItem, Subscription } from "fond/types";
import { BlockSpinner } from "fond/widgets";

import InsightsChart from "./InsightsChart";

import { StyledText, TooltipContainer } from "./insightsChart.styles";

interface PremsUsageChartProps {
  subscription: Subscription;
}

const XAxisTick = ({ payload, x, y, textAnchor }: SVGProps<SVGTextElement> & Payload<string, string>) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={12} textAnchor={textAnchor} fontSize={10} fill="rgba(0, 0, 0, 0.5)">
      {dayjs(payload.value).format("DD")}
    </text>
    <text x={0} y={12} dy={12} textAnchor={textAnchor} fontSize={10} fill="rgba(0, 0, 0, 0.5)">
      {dayjs(payload.value).format("MMM YYYY")}
    </text>
  </g>
);

const YAxisTick = ({ payload, x, y, textAnchor }: SVGProps<SVGTextElement> & Payload<string, string>) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={4} textAnchor={textAnchor} fontSize={10} fill="rgba(0, 0, 0, 0.5)">
      {payload.value}
    </text>
  </g>
);

const CustomTooltip = ({ active, label, payload }: TooltipProps<string, string>) => {
  if (active && payload && payload.length) {
    const premisesCount = payload[0].value ?? "0";
    return (
      <TooltipContainer>
        <Typography component="p" variant="h7">
          {dayjs(label).format("ddd, DD MMM")}
        </Typography>
        <Typography variant="body1" component="span" sx={(theme) => ({ color: theme.palette.primary.main, fontWeight: "700" })}>
          {(+premisesCount).toLocaleString()}
        </Typography>
        <Typography variant="h7" component="span">
          &nbsp; premises generated
        </Typography>
      </TooltipContainer>
    );
  }
  return null;
};

type DailyUsage = { date: string; accountUsage: number; [email: `${string}${"@"}${string}`]: number };

const transformDailyUsage = (dailyUsage: DailyUsageItem[]): DailyUsage[] => {
  return dailyUsage.map((item: DailyUsageItem) => {
    const usage = { date: item.Date, accountUsage: item.DailyUsageByAccount };
    const usageByUser = item.DailyUsageByUser.reduce<Omit<DailyUsage, "date" | "accountUsage">>(
      (prev, curr) => ({ ...prev, [curr.Email]: curr.Usage }),
      {}
    );
    return { ...usage, ...usageByUser };
  });
};

const PremsUsageChart: React.FC<PremsUsageChartProps> = ({ subscription }: PremsUsageChartProps) => {
  const theme = useTheme();
  const { data: usage, isLoading } = useGetAccountSubscriptionUsageQuery(subscription.ID);
  const dailyUsage = transformDailyUsage(usage?.DailyUsage || []);

  return (
    <InsightsChart
      title="Premises usage"
      subtitle="Total premises generated within the account"
      totalUsage={subscription.Planner?.QuotaUsage ?? 0}
      content={
        isLoading ? (
          <BlockSpinner />
        ) : (
          <>
            <ResponsiveContainer height={400}>
              <AreaChart data={dailyUsage}>
                <defs>
                  <linearGradient id="gradient-fill" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis dataKey="date" tick={<XAxisTick />} minTickGap={160} tickLine={false} stroke="rgba(0, 0, 0, 0.2)" />
                <YAxis tickCount={7} tick={<YAxisTick />} stroke="rgba(0, 0, 0, 0.2)" />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  iconType="square"
                  iconSize={10}
                  wrapperStyle={{ paddingTop: theme.spacing(1.5) }}
                  formatter={() => (
                    <Typography component="span" fontSize={10} color={theme.palette.biarri.secondary.lightGrey}>
                      Premises
                    </Typography>
                  )}
                />
                <Area
                  type="monotone"
                  dataKey="accountUsage"
                  stackId="1"
                  stroke={theme.palette.primary.main}
                  fillOpacity={1}
                  fill="url(#gradient-fill)"
                />
              </AreaChart>
            </ResponsiveContainer>
            <StyledText>All dates are in Coordinated Universal Time, or UTC</StyledText>
          </>
        )
      }
    />
  );
};

export default PremsUsageChart;
