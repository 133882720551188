import React from "react";
import { useSelector } from "react-redux";
import { PlaylistAdd } from "@mui/icons-material";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { Architecture, Store } from "fond/types";
import { conditionalMessage } from "fond/utils/message";
import { Actions } from "fond/utils/permissions";

import { getCurrentProject, isSolveActive } from "../redux";

const customStyles = () => {
  return createStyles({
    description: {
      maxHeight: "25vh",
      overflow: "auto",
      display: "block",
    },
    noSelectedArch: {
      textAlign: "center",
    },
    selectedArch: {
      textAlign: "left",
    },
    nonIdealIcon: {
      fontSize: "3em",
      color: "#9D9D9D",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The current architecture applied to the project
   */
  architecture: Architecture | null;
  /**
   * Flag indicating that the user is presented with the ability to select / change architectures
   */
  readOnly: boolean;
  /**
   * Callback function for the Change Architecture button
   */
  onChangeClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Flag indicating the project is a subarea project of a city planner project
   */
  isMultiProjectArea: boolean;
}

const ArchitecturePanel: React.FC<IProps> = ({ classes, architecture, readOnly = false, onChangeClick, isMultiProjectArea }: IProps) => {
  const solveIsActive = useSelector((state: Store) => isSolveActive(state));
  const currentProject = useSelector((state: Store) => getCurrentProject(state.project));
  const canEditArchitecture = usePermissionCheck(Actions.ARCHITECTURE_EDIT, currentProject.Permission.Level);

  return (
    <Box>
      {architecture && (
        <Box>
          <Typography variant="h6" className="architecture-name">
            {architecture.Name}
          </Typography>
          <Box className={classNames(classes.description, "customScrollbars")}>
            <Typography variant="caption">{architecture.Description}</Typography>
          </Box>
        </Box>
      )}
      {!readOnly && (
        <Box className={architecture == null ? classes.noSelectedArch : classes.selectedArch}>
          {!architecture && (
            <Grid container justifyContent="center">
              <Grid item>
                <PlaylistAdd className={classes.nonIdealIcon} />
              </Grid>
              <Grid item>
                <Typography variant="h6">No architecture has been selected.</Typography>
                <Typography variant="body2">
                  {conditionalMessage([
                    {
                      condition: canEditArchitecture,
                      message: "Please select an architecture from the available architectures list or create a new architecture.",
                    },
                    { condition: !canEditArchitecture, message: "You require a license and subscription to create or select an architecture" },
                  ])}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Tooltip
            title={conditionalMessage([
              { condition: solveIsActive, message: "You cannot change the architecture while a design is being generated." },
              { condition: isMultiProjectArea, message: "You cannot change the architecture while in a multi-project area." },
              { condition: !canEditArchitecture, message: "You require a license and subscription to create or select an architecture" },
            ])}
            placement="bottom"
          >
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                data-testid="select-architecture-button"
                className="select-architecture-button"
                disabled={isMultiProjectArea || solveIsActive || !canEditArchitecture}
                onClick={onChangeClick}
                style={{ marginTop: "0.5em" }}
              >
                {architecture != null ? "Change architecture" : "Select architecture"}
              </Button>
            </div>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(customStyles)(ArchitecturePanel);
