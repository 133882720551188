import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { useCreateFollowMutation, useDeleteFollowMutation, useGetFollowsQuery } from "fond/api/followSlice";
import { FollowType } from "fond/types/followTarget";

interface UseFollowsReturn {
  isFollowing: boolean;
  isLoading: boolean;
  setFollowing: (follow: boolean) => void;
}

interface IProps {
  type: FollowType;
  id: string;
  name: string;
}

export const useFollows = ({ type, id, name }: IProps): UseFollowsReturn => {
  const { data, isLoading } = useGetFollowsQuery({ type, id });
  const [createFollow] = useCreateFollowMutation();
  const [deleteFollow] = useDeleteFollowMutation();
  const { enqueueSnackbar } = useSnackbar();

  const isFollowing = data?.some((f) => f.Target.Type === type && f.Target.ID === id) ?? false;

  const setFollowing = (followed: boolean) => {
    if (followed) {
      createFollow({ Type: type, ID: id })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`"${name}" added to Watched`, {
            action: (
              <Button color="primary" onClick={() => setFollowing(false)}>
                Undo
              </Button>
            ),
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          enqueueSnackbar(`"${name}" could not be Watched.`);
        });
    } else {
      deleteFollow({ Type: type, ID: id })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`"${name}" removed from Watched`, {
            action: (
              <Button color="primary" onClick={() => setFollowing(true)}>
                Undo
              </Button>
            ),
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          enqueueSnackbar(`"${name}" could not be removed from Watched.`);
        });
    }
  };

  return { isLoading, isFollowing, setFollowing };
};
