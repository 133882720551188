import { Box, Divider, styled, TextField, Typography } from "@mui/material";

export const SpecialOffer = styled(Box)(({ theme }) => ({
  color: theme.palette.biarri.secondary.grey,
}));

export const LineItemPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.biarri.secondary.grey,
}));

export const SpecialOfferDivider = styled(Divider)(() => ({
  borderStyle: "dashed",
}));

export const PromoCodeInput = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(2),
  flexGrow: 1,
}));

export const SummaryCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.biarri.secondary.grey,
}));

export const CurrencyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));
