import React from "react";
import { createPortal } from "react-dom";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { jssPreset, StylesProvider } from "@mui/styles";
import { Rect } from "flexlayout-react";
import { create } from "jss";

export interface IFloating {
  /**
   * Flag indicating if the node is floating
   */
  enabled: boolean;
  /**
   * The list of all floating node ids
   */
  ids: string[];
  /**
   * Current Window
   */
  window: Window;
}

/**
 * This is a modified version of the flexlayout-react/FloatingWindow.tsx that
 * has been customised to support our Mui style setup.
 */
export interface IFloatingWindowProps {
  /**
   * The title of the popup to be opened
   */
  title: string;
  /**
   * Unique identifier used to set the windowName of the popup
   */
  id: string;
  /**
   * The URL of the resource to be loaded within the popup
   */
  url: string;
  /**
   * The Rect (x, y, width and Height) to be used to determine the
   * size and location the popup should load.
   *
   * This is usually based on the layout tab size and location.
   */
  rect: Rect;
  /**
   * Callback function to handle the closing (beforeunload) event.
   */
  onCloseWindow: (id: string) => void;
  /**
   * Callback function to handle the creation of the window.  Can be used to
   * reference the popup directly.
   */
  onSetWindow: (id: string, window: Window) => void;
}

export const FloatingWindow = (props: React.PropsWithChildren<IFloatingWindowProps>) => {
  const { title, id, url, onCloseWindow, onSetWindow, children, rect } = props;
  const popoutWindow = React.useRef<Window | null>(null);
  const [content, setContent] = React.useState<HTMLElement | undefined>(undefined);

  const unload = () => {
    popoutWindow.current = null;
    onCloseWindow(id);
  };

  React.useLayoutEffect(() => {
    const r = rect;
    popoutWindow.current = window.open(url, id, `left=${r.x},top=${r.y},width=${r.width},height=${r.height}`);
    if (popoutWindow.current !== null) {
      onSetWindow(id, popoutWindow.current);

      // listen for parent unloading to remove all popouts
      window.addEventListener("beforeunload", () => {
        if (popoutWindow.current) {
          popoutWindow.current.close();
          popoutWindow.current = null;
        }
      });

      popoutWindow.current.addEventListener("load", () => {
        if (popoutWindow.current) {
          const popoutDocument = popoutWindow.current.document;
          popoutDocument.title = title;
          const popoutContent = popoutDocument.createElement("div");
          popoutContent.className = "flexlayout__floating_window_content";
          popoutDocument.getElementsByTagName("html")[0].className = "customScrollbars";
          popoutDocument.getElementById("root")?.remove();
          popoutDocument.body.appendChild(popoutContent);
          setContent(popoutContent);

          // listen for popout unloading (needs to be after load for safari)
          popoutWindow.current.addEventListener("beforeunload", unload);
        }
      });
    } else {
      console.warn(`Unable to open window ${url}`);
      unload();
    }

    return () => {
      // delay so refresh will close window
      setTimeout(() => {
        if (popoutWindow.current) {
          popoutWindow.current.close();
          popoutWindow.current = null;
        }
      }, 0);
    };
  }, []);

  if (content !== undefined) {
    const jss = create({ ...jssPreset(), insertionPoint: content.ownerDocument.head });
    const cache = createCache({ key: "css", container: content.ownerDocument.head });

    /**
     * We are required to wrapper the component with the StylesProvider
     * that allows us to inject styles into the popup not the parent window
     */
    const wrapper = (
      <StylesProvider jss={jss} sheetsManager={new Map()}>
        <CacheProvider value={cache}>
          <CssBaseline />
          {children}
        </CacheProvider>
      </StylesProvider>
    );
    return createPortal(wrapper, content);
  } else {
    return null;
  }
};
