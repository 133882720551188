import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectCurrentAccount, useGetAccountsQuery, useGetUserMeAccountsQuery, useGetUserMeQuery } from "fond/api";
import { Store } from "fond/types";

interface UseNoAccountRedirectReturn {
  isFetching: boolean;
}

/**
 * Redirects user to /shared if they have no account
 */
export const useNoAccountRedirect = (): UseNoAccountRedirectReturn => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: Store) => state.cognito.isLoggedIn);

  const { isFetching: isFetchingAccounts } = useGetAccountsQuery();
  const { isFetching: isFetchingMe } = useGetUserMeQuery();
  const { isFetching: isFetchingMeAccounts } = useGetUserMeAccountsQuery();

  const account = useSelector(selectCurrentAccount);
  const isFetching = isFetchingMeAccounts || isFetchingAccounts || isFetchingMe;

  useEffect(() => {
    if (isLoggedIn && !isFetching && !account) {
      navigate("/shared");
    }
  }, [account, isFetching, isFetchingMe, isLoggedIn, navigate]);

  return { isFetching };
};
