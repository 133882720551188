import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Share as ShareIcon } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import { getCurrentProject } from "fond/project";
import { Share as ShareModal } from "fond/share";
import { Project, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

interface IProps {
  handleClose: () => void;
}

const Share: React.FC<IProps> = ({ handleClose }: IProps) => {
  const [shareModal, showShareModal] = useState(false);
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const canViewShare = permissionCheck(project?.Permission.Level, Actions.PROJECT_SHARE_VIEW);

  const handleClick = () => {
    if (canViewShare) {
      showShareModal(true);
      handleClose();
    }
  };

  return (
    <>
      <MenuItem data-testid="share-button" onClick={handleClick} disabled={!canViewShare}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        Share
      </MenuItem>
      {shareModal && <ShareModal resource={project} onClose={() => showShareModal(false)} />}
    </>
  );
};

export default Share;
