import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip as RechartTooltip, TooltipProps, XAxis } from "recharts";
import { ActiveShape } from "recharts/types/util/types";

import { STYLE_EDITOR_MAX_ZOOM, STYLE_EDITOR_MIN_ZOOM } from "fond/constants";
import { MapContext } from "fond/map/MapProvider";

import { Tooltip } from "./cameraExpressionField.styles";

interface IProps {
  // A single array of data in the format of
  // [<zoom>, <value>, <zoom>, <value>]
  data: Array<number | string>;
  fieldLabel: string;
}

const ZoomChart: React.FC<IProps> = ({ data, fieldLabel }: IProps) => {
  const { map } = useContext(MapContext);
  const [currentZoom, setCurrentZoom] = useState<number>(0);

  useEffect(() => {
    map?.on("zoom", handleZoomChange);

    handleZoomChange();
    return () => {
      map?.off("zoom", handleZoomChange);
    };
  }, [map]);

  const handleZoomChange = useCallback(() => {
    setCurrentZoom(Math.round((map?.getZoom() || 0) * 100) / 100);
  }, [map]);

  const chartData = useMemo(() => {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      result.push({
        zoom: data[i],
        value: +data[i + 1],
      });
    }

    // insert min value
    if (result?.[0].zoom !== STYLE_EDITOR_MIN_ZOOM) {
      result.unshift({
        zoom: STYLE_EDITOR_MIN_ZOOM,
        value: result?.[0].value,
      });
    }

    // insert max value
    if (result?.[result.length - 1].zoom !== STYLE_EDITOR_MAX_ZOOM) {
      result.push({
        zoom: STYLE_EDITOR_MAX_ZOOM,
        value: result?.[result.length - 1].value,
      });
    }

    return result;
  }, [data]);

  const renderTooltip = ({ payload }: TooltipProps<any, "name">): JSX.Element => {
    return <Tooltip>{`${fieldLabel}: ${payload?.[0]?.value}`}</Tooltip>;
  };
  return (
    <Box data-testid="zoom-chart">
      <ResponsiveContainer width="100%" height={120}>
        <LineChart data={chartData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <XAxis
            dataKey="zoom"
            interval="preserveStartEnd"
            type="number"
            tickCount={10}
            tickSize={8}
            ticks={[0, 4, 8, 12, 16, 20]}
            scale="linear"
            fontSize={9}
          />
          <RechartTooltip content={renderTooltip} isAnimationActive={false} />
          <CartesianGrid stroke="#f5f5f5" />
          <Line
            type="linear"
            dataKey="value"
            stroke="#2196F3"
            yAxisId={0}
            strokeWidth={1.5}
            isAnimationActive={false}
            dot={{
              r: 3.5,
              fill: "#2196F3",
              stroke: "white",
              strokeWidth: 1,
            }}
            activeDot={
              {
                r: 5,
                onClick: (event, { payload: { zoom } }) => {
                  map?.zoomTo(zoom);
                },
              } as ActiveShape & { onClick(props: unknown, values: { payload: { zoom: number } }): void }
            }
          />
          <ReferenceLine x={currentZoom} stroke="#01161F" strokeWidth={1.5} strokeOpacity={1} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ZoomChart;
