import React from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";

import { useUpgradePlanner } from "fond/hooks/useUpgradePlanner";
import { ProjectsView, Store } from "fond/types";
import PricingTableButton from "fond/views/Payment/PricingTable/PricingTableButton";

/**
 * Default project header that will return text based on
 * user's ability to create projects
 */
const ProjectListHeader: React.FC = () => {
  const filters = useSelector((state: Store) => state.projects.filters);
  const { canUpgrade } = useUpgradePlanner();

  const workspaceMessage = "This is your account workspace, where you can design, manage, and collaborate on network projects with others.";
  const headerDescription: Record<ProjectsView, string | JSX.Element> = {
    workspace: canUpgrade ? (
      <>
        {workspaceMessage}
        <br />
        If you would like to access all these premium features,{" "}
        <PricingTableButton
          renderButton={(onClick) => (
            <Button onClick={onClick} sx={{ padding: 0, minWidth: "unset" }}>
              upgrade
            </Button>
          )}
        />{" "}
        to a planner subscription now.
      </>
    ) : (
      workspaceMessage
    ),
    shared: "Access projects and folders that others have granted permission for you to view and/or edit.",
    recent: "Projects you visited recently.",
    starred: "Quickly navigate to your most important and frequently accessed content.",
  };

  return <Typography width="70%">{headerDescription[filters]}</Typography>;
};

export default ProjectListHeader;
