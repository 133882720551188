import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import isEqual from "lodash/isEqual";

import { FollowTarget, FollowType } from "fond/types/followTarget";

import { apiSlice } from "./apiSlice";

export type FollowResponse = {
  Target: FollowTarget;
};

type ListFollowsResponse = {
  Follows: FollowResponse[];
};

type GetFollowsQueryParams = {
  type: FollowType;
  id?: string;
};

const addFollow = (target: FollowTarget) => (draft: MaybeDrafted<FollowResponse[]>) => {
  const following = draft.some((f) => isEqual(f.Target, target));
  if (!following) {
    draft.push({ Target: target });
  }
};

const deleteFollow = (target: FollowTarget) => (draft: MaybeDrafted<FollowResponse[]>) => {
  const followToDelete = draft.findIndex((f) => isEqual(f.Target, target));
  if (followToDelete !== -1) {
    draft.splice(followToDelete, 1);
  }
};

export const followSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFollows: build.query<FollowResponse[], GetFollowsQueryParams>({
      query: ({ type, id }) => ({
        url: "/v2/follows",
        params: { type, id },
      }),
      transformResponse: (response: ListFollowsResponse) => response?.Follows ?? [],
      providesTags: (result) =>
        result
          ? [
              ...result.map((body) => ({
                type: "Follow" as const,
                id: body.Target.ID,
              })),
              { type: "Follow", id: "LIST" },
            ]
          : [{ type: "Follow", id: "LIST" }],
    }),
    createFollow: build.mutation<FollowResponse, FollowTarget>({
      query: (follow) => ({
        url: "/v2/follows",
        method: "POST",
        body: { Target: follow },
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Follow", id: "LIST" }],
      onQueryStarted: async (target, { dispatch, queryFulfilled }) => {
        const patchGetFollowsByTypeAndId = dispatch(
          followSlice.util.updateQueryData("getFollows", { type: target.Type, id: target.ID }, addFollow(target))
        );
        const patchGetFollowsByType = dispatch(followSlice.util.updateQueryData("getFollows", { type: target.Type }, addFollow(target)));
        queryFulfilled.catch(() => {
          patchGetFollowsByType.undo();
          patchGetFollowsByTypeAndId.undo();
        });
      },
    }),
    deleteFollow: build.mutation<undefined, FollowTarget>({
      query: ({ Type: type, ID: id }) => ({
        url: "/v2/follows",
        method: "DELETE",
        params: { type, id },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "Follow", id: arg.ID },
        { type: "Follow", id: "LIST" },
      ],
      onQueryStarted: async (target, { dispatch, queryFulfilled }) => {
        const patchGetFollowsByTypeAndId = dispatch(
          followSlice.util.updateQueryData("getFollows", { type: target.Type, id: target.ID }, deleteFollow(target))
        );
        const patchGetFollowsByType = dispatch(followSlice.util.updateQueryData("getFollows", { type: target.Type }, deleteFollow(target)));
        queryFulfilled.catch(() => {
          patchGetFollowsByType.undo();
          patchGetFollowsByTypeAndId.undo();
        });
      },
    }),
  }),
});

/**
 * Endpoint hooks
 */
export const { useGetFollowsQuery, useCreateFollowMutation, useDeleteFollowMutation } = followSlice;
