import { extname } from "fond/fileValidation";
import { Attachment } from "fond/types";

export enum FileStatus {
  READY,
  NO_EXTENSION,
  DROP_CONFLICT,
  ATTACHMENT_CONFLICT,
}

export interface validationOutput {
  isValid: boolean;
  validatedFiles: ValidatedFile[];
}

export interface ValidatedFile {
  file: File;
  status: FileStatus;
  isFirstInvalid: boolean;
}

export const validateFiles = (files: File[], attachments: Attachment[] | null, allowOverwrites: File[]): validationOutput => {
  const fileDuplicated = (file: File) =>
    files.filter((targetFile: File) => file !== targetFile).find((targetFile: File) => file.name === targetFile.name);
  const fileExists = (file: File) =>
    !allowOverwrites.includes(file) && attachments?.find((attachment: Attachment) => `${attachment.Name}.${attachment.Extension}` === file.name);

  let isValid = true;
  const validatedFiles = files.map((file) => {
    let status;
    if (!extname(file.name)) {
      status = FileStatus.NO_EXTENSION;
    } else if (fileDuplicated(file)) {
      status = FileStatus.DROP_CONFLICT;
    } else if (fileExists(file)) {
      status = FileStatus.ATTACHMENT_CONFLICT;
    } else {
      status = FileStatus.READY;
    }

    let isFirstInvalid = false;
    if (isValid && status !== FileStatus.READY) {
      isValid = false;
      isFirstInvalid = true;
    }

    return {
      file,
      status,
      isFirstInvalid,
    };
  });

  return {
    isValid,
    validatedFiles,
  };
};
