import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import { Box, Chip, Tooltip } from "@mui/material";

/**
 * Warning for write locked projects (i.e. projects which can no longer be edited)
 * At the time of writing, the only write locked projects are old UsesBlueSteelV11 projects.
 */
const WriteLockedWarning: React.FC = () => (
  <Box component="span" ml={1}>
    <Tooltip title="This project was generated with an older version of FOND and has been converted to read only mode.">
      <Chip icon={<InfoOutlined />} size="small" label="Legacy" variant="outlined" />
    </Tooltip>
  </Box>
);

export default WriteLockedWarning;
