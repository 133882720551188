import { Project, ProjectStatus } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

function designNeedsUpdating(status: ProjectStatus): boolean {
  // Use `=== true` for HasSolution and IsDirty so we'll always return a boolean
  // even if they don't exist.
  return status?.HasSolution === true && ["Complete", "Cancelled", "Terminated"].includes(status.Status) && status.IsDirty;
}

/**
 * Return true if the design needs updating and the user as the appropriate permissions/features.
 * Currently used to determine if the update design banner should be displayed.
 */
export function showUpdateDesignBanner(project: Project, status: ProjectStatus): boolean {
  return designNeedsUpdating(status) && permissionCheck(project.Permission.Level, Actions.PROJECT_EDIT);
}
