import { useEffect, useState } from "react";
import { VisibilityOffOutlined as VisibilityOff, VisibilityOutlined as Visibility } from "@mui/icons-material";
import { InputBaseComponentProps } from "@mui/material";
import { blue, green, red, yellow } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import zxcvbn from "zxcvbn";

const PasswordStrengthStyles = makeStyles((theme) => ({
  labelContainer: {
    marginTop: 5,
    marginLeft: 12,
    marginRight: 12,
    height: 21,
  },
  counter: {
    float: "right",
  },
  indicator: {
    backgroundColor: "initial",
    marginTop: -6,
    marginLeft: 2,
    marginRight: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  red: {
    backgroundColor: red[400],
  },
  yellow: {
    backgroundColor: yellow[400],
  },
  blue: {
    backgroundColor: blue[400],
  },
  green: {
    backgroundColor: green[400],
  },
}));

interface IPasswordProps {
  password: string;
}

/**
 * A password strength indicator using zxcvbn (https://github.com/dropbox/zxcvbn)
 * to estimate password strength. Password strength defaults to 'Weak' while the password
 * is less than 8 characters and then switches to the result given by zxcvbn (one of
 * Weak, Fair, Good, Strong).
 */
const PasswordStrength: React.FC<IPasswordProps> = ({ password = "" }: IPasswordProps) => {
  const classes: any = PasswordStrengthStyles();
  const [didMount, setDidMount] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    if (didMount) {
      const analysePassword = async () => {
        if (!password) {
          setScore(0);
        } else {
          let result = zxcvbn(password);
          /*
            zxcvbn can return a score of 0, however I would prefer that we score at atleast 1
            so that the 'weak' indicator appears, which makes it more obvious to the user that
            there is a problem.
          */
          if (result.score === 0) {
            setScore(1);
          } else {
            setScore(result.score);
          }
        }
      };
      analysePassword();
    }
  }, [didMount, password]);

  const scorePresentation = [
    {
      barColour: "red",
      label: "Weak",
    },
    {
      barColour: "red",
      label: "Weak",
    },
    {
      barColour: "yellow",
      label: "Fair",
    },
    {
      barColour: "blue",
      label: "Good",
    },
    {
      barColour: "green",
      label: "Strong",
    },
  ];

  return (
    <>
      <LinearProgress
        variant="determinate"
        className={classes.indicator}
        classes={{
          barColorPrimary: classes[scorePresentation[score].barColour],
        }}
        value={(score * 100) / 4}
      />
      <div className={classes.labelContainer}>
        {password && <Typography variant="caption">{scorePresentation[score].label}</Typography>}
        <Typography variant="caption" className={classes.counter}>
          {password.length} / 8
        </Typography>
      </div>
    </>
  );
};

interface IProps {
  className?: string;
  error: boolean;
  fullWidth?: boolean;
  helperText?: any;
  inputProps?: InputBaseComponentProps;
  label: string;
  name: string;
  onChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  passwordStrength?: boolean;
  required?: boolean;
  value: string;
}

const PasswordField: React.FC<IProps> = ({
  className,
  error,
  fullWidth = false,
  helperText,
  inputProps,
  label,
  name,
  onChange,
  passwordStrength = true,
  required = true,
  value,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl className={className} variant="outlined" fullWidth={fullWidth}>
      <TextField
        fullWidth
        inputProps={{
          "aria-label": "password",
          ...inputProps,
        }}
        variant="outlined"
        label={label}
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        error={error}
        InputProps={{
          endAdornment: (
            <InputAdornment variant="filled" position="end">
              <IconButton data-test="show-password" onClick={handleShowPasswordClick} size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        required={required}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      {passwordStrength && <PasswordStrength password={value} />}
    </FormControl>
  );
};

export default PasswordField;
