import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteProjectMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { ProjectPreview } from "fond/types";
import { Modal } from "fond/widgets";

interface IProps {
  project: ProjectPreview;
  closeModalCallback(): void;
}

/**
 * A dialog to:
 *   Warn users of the consequences of project deletion.
 *   Allow them to cancel the deletion.
 *   Seek confirmation to delete.
 */
const RemoveProjectDialog: React.FC<IProps> = ({ project, closeModalCallback }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [deleteProject] = useDeleteProjectMutation();

  /**
   * Handles the deletion of the project.
   */
  const handleOnDelete = () => {
    setSaving(true);

    deleteProject(project)
      .unwrap()
      .then(() => {
        mixpanel.track("Deleted project", { projectId: project.ID });
        enqueueSnackbar("Project deleted.");
      })
      .catch(() => {
        enqueueSnackbar("Project deletion failed. Please try again...");
      })
      .finally(() => {
        setSaving(false);
        closeModalCallback();
      });
  };

  return (
    <Modal
      open
      header="Delete project?"
      DialogTitleProps={{ id: "alert-dialog-title" }}
      data-testid="delete-project-modal"
      DialogContentProps={{ id: "alert-dialog-description" }}
      content={
        <Typography>
          Are you sure you wish to delete the project <strong>{project.ProjectName}</strong>
          ?
          <br />
          This will also affect other users this project is shared with.
        </Typography>
      }
      actions={
        <>
          <Button color="primary" data-testid="cancel-project-deletion" onClick={closeModalCallback} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleOnDelete}
            autoFocus
            data-testid="confirm-project-deletion"
            loading={saving}
          >
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default RemoveProjectDialog;
