/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Box, Link } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { useResizeObserver } from "fond/utils/hooks";

const DEFAULT_COLLAPSED_SIZE = 40;
const TEXT_ROW_HEIGHT = 20; // The total row height of the "Show more" <Link />

const customStyles = (theme: Theme) => {
  return createStyles({
    content: {
      overflow: "hidden",
      willChange: "height",
      transition: "max-height 1s ease",
    },
    link: {
      color: theme.palette.primary.main,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The child content that will be height restricted
   */
  children: React.ReactNode;
  /**
   * The minimum height required before the Show more / Show less options are used
   */
  collapsedHeight?: number;
  /**
   * The height of each row of text (based on the css styles currently being used)
   */
  textRowHeight?: number;
}

const ShowMore: React.FC<IProps> = ({ children, classes, collapsedHeight = DEFAULT_COLLAPSED_SIZE, textRowHeight = TEXT_ROW_HEIGHT }: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const [elementHeight, setElementHeight] = useState<number | undefined>(undefined);
  const [showMoreLess, setShowMoreLess] = useState(false);
  const contentRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    onResize();
  }, [contentRef.current]);

  /**
   * Callback function fired when the observed element is resized
   */
  const onResize = () => {
    const height = contentRef.current?.offsetHeight || 0;
    // Given that the "Show more" / "Show less" takes up a text row itself
    // we check if the height is actually > that the collapsedHeight another row of text
    if (height > collapsedHeight + textRowHeight) {
      setElementHeight(height);
      setShowMoreLess(true);
    }
  };

  useResizeObserver(contentRef, onResize);

  /**
   * Toggles the expanded / collapsed state
   */
  const toggle = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setExpanded(!expanded);
  };

  return (
    <Box>
      <Box ref={contentRef} className={classes.content} style={{ maxHeight: expanded || elementHeight === undefined ? "unset" : collapsedHeight }}>
        {children}
      </Box>
      {showMoreLess && (
        <Link href="#" className={classes.link} onClick={toggle}>
          {expanded ? "Show less" : "Show more"}
        </Link>
      )}
    </Box>
  );
};

export default withStyles(customStyles)(ShowMore);
