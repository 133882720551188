import React from "react";
import { Button, Link, Typography } from "@mui/material";

import { Modal } from "fond/widgets";

interface IProps {
  onClose: () => void;
}

const InvalidMappingFileModal: React.FC<IProps> = ({ onClose }) => {
  return (
    <Modal
      open
      header="Invalid mapping file"
      onClose={onClose}
      data-testid="invalid-mapping-file-modal"
      content={
        <Typography>
          Ensure you're using the correct{" "}
          <Link
            href="https://biarrinetworks-confluence.atlassian.net/wiki/spaces/FOND/pages/619020628/FOND+Bulk+Import"
            target="_blank"
            rel="noopener noreferrer"
          >
            mapping file
          </Link>{" "}
          and try again.
        </Typography>
      }
      actions={
        <Button data-testid="close-button" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default InvalidMappingFileModal;
