import { Box, Chip, styled, ToggleButton } from "@mui/material";
import { blue } from "@mui/material/colors";

export const BillingPeriodToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.biarri.secondary.activeBlue,
  textTransform: "capitalize",
  borderColor: blue[100],
  opacity: 0.8,
  width: "100%",

  "&:hover": {
    backgroundColor: blue[100],
  },

  "&.Mui-selected": {
    backgroundColor: blue[100],
    color: theme.palette.biarri.secondary.activeBlue,
    "&:hover": {
      backgroundColor: blue[200],
    },
  },
}));

export const BillingPeriodChip = styled(Chip)(() => ({
  margin: "auto",
  fontSize: "0.75rem",
  color: "#fff",
}));

export const BillingPeriodChipContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: ". . .",
  gridTemplateColumns: "1fr 1fr 1fr",
  marginTop: theme.spacing(1),
}));
