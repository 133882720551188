/**
 * Convenience wrapper around some turf submodules.
 */

import bboxPolygon from "@turf/bbox-polygon";

export { default as area } from "@turf/area";
export { default as bbox } from "@turf/bbox";

export { default as booleanDisjoint } from "@turf/boolean-disjoint";
export { default as booleanOverlap } from "@turf/boolean-overlap";
export { default as booleanPointInPolygon } from "@turf/boolean-point-in-polygon";
export { feature, featureCollection, lineString, multiPolygon, point, polygon } from "@turf/helpers";
export { randomPoint } from "@turf/random";
export { randomLineString } from "@turf/random";
export { bboxPolygon };
export { default as booleanContains } from "@turf/boolean-contains";
export { default as booleanEqual } from "@turf/boolean-equal";
export { default as booleanIntersects } from "@turf/boolean-intersects";
export { default as buffer } from "@turf/buffer";
export { default as centroid } from "@turf/centroid";
export { default as circle } from "@turf/circle";
export { default as combine } from "@turf/combine";
export { default as difference } from "@turf/difference";
export { default as intersect } from "@turf/intersect";
export { default as length } from "@turf/length";
export { default as pointOnFeature } from "@turf/point-on-feature";
export { default as union } from "@turf/union";
export { default as extent } from "turf-extent";

export function nullPolygon() {
  return bboxPolygon([0, 0, 0, 0]);
}
