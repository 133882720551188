import React from "react";
import { Typography } from "@mui/material";

import ForgotPasswordSubmitForm from "fond/forgotPassword/ForgotPasswordSubmitForm";
import SignUpAdornment from "fond/signIn/SignUpAdornment";
import { PageCentred } from "fond/widgets/PageCentred";

const ForgotPasswordSubmit: React.FC = () => {
  return (
    <PageCentred
      title="Password reset"
      description={<Typography sx={{ mr: 1 }}>Please choose a new password.</Typography>}
      rightAdornment={<SignUpAdornment />}
    >
      <ForgotPasswordSubmitForm />
    </PageCentred>
  );
};

export default ForgotPasswordSubmit;
