import React from "react";
import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";

interface IProps {
  /**
   * Instance of map page
   */
  map: mapboxgl.Map;
  /**
   * Callback function when search happens
   */
  onSelectSearchResult(): void;
}

const marker = new mapboxgl.Marker({ draggable: false });

export default class MapboxSearchBox extends React.Component<IProps> {
  private container: React.RefObject<HTMLDivElement>;

  props: IProps;

  constructor(props: IProps) {
    super(props);
    this.props = props;
    this.container = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const geocoder = new MapboxGeocoder({
      accessToken: `${process.env.REACT_APP_MAPBOX_KEY}`,
      localGeocoder: this.coordinatesGeocoder,
      marker: false,
    });
    geocoder.on("result", (e: any) => {
      marker.remove();
      this.props.onSelectSearchResult();
      marker.setLngLat(e.result.center).addTo(this.props.map);
    });
    this.container.current?.appendChild(geocoder.onAdd(this.props.map));
  }

  coordinatesGeocoder = (query: string) => {
    // Match anything which looks like decimal degrees coordinate pair.
    const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);

    if (!matches) {
      return undefined;
    }

    const coordinateFeature = (lng: number, lat: number): Result => {
      return {
        center: [lng, lat],
        place_name: `Lat: ${lat} Lng: ${lng}`,
        place_type: ["coordinate"],
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        properties: {},
      };
    };

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  render() {
    return <div ref={this.container} />;
  }
}
