import { Avatar, Box, Paper, styled } from "@mui/material";

export const CardPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: 192,
  minWidth: 200,
}));

export const CardBody = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const RowContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const CardIcon = styled(Avatar)(({ theme }) => ({
  height: 38,
  width: 38,
  background: theme.palette.secondary.main,
  marginRight: theme.spacing(1),
  "& > svg": {
    color: theme.palette.common.white,
    fontSize: "1.25rem",
  },
}));
