import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { selectCurrentAccount, useGetClientSecretQuery } from "fond/api";
import { useNavigateError } from "fond/navigation/useNavigateError";
import TopBar from "fond/topBar/TopBar";
import { BlockSpinner } from "fond/widgets";

import CheckoutForm from "./CheckoutForm";
import { appearance } from "./stripeStyles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const Checkout: FC = () => {
  const currentAccount = useSelector(selectCurrentAccount);
  const { data, isLoading, isError } = useGetClientSecretQuery(currentAccount?.ID ?? skipToken);
  const navigateError = useNavigateError();

  useEffect(() => {
    if (isError) navigateError("/error");
  }, [isError, navigateError]);

  return (
    <Box data-testid="checkout-page">
      <TopBar />
      {data && !isLoading ? (
        <Elements stripe={stripePromise} options={{ clientSecret: data.ClientSecret, appearance }}>
          <CheckoutForm />
        </Elements>
      ) : (
        <BlockSpinner containerProps={{ height: "50vw" }} />
      )}
    </Box>
  );
};

export default Checkout;
