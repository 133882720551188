import { alpha, Box, Card, CardContent, CSSObject } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * A container for the CardButton.
 */
export interface ClickableCardProps {
  selected?: boolean;
  disabled?: boolean;
}

export const ClickableCard = styled(Card)<ClickableCardProps>(({ theme, selected, disabled }) => {
  let styles: CSSObject = {
    display: "inline-flex",
  };

  if (disabled) {
    styles = { ...styles, backgroundColor: theme.palette.biarri.primary.lightGrey, cursor: "not-allowed" };
  } else {
    styles = {
      ...styles,
      cursor: "pointer",
      "&:hover": {
        background: alpha(theme.palette.primary.main, 0.05),
      },
    };
  }

  if (selected) {
    styles = {
      ...styles,
      background: alpha(theme.palette.primary.main, 0.05),
      border: `1px solid ${disabled ? theme.palette.biarri.primary.grey : theme.palette.primary.main}`,
    };
  }

  return styles;
});

/**
 * A The ClickableCard content.
 */
export interface ClickableCardContentProps {}
export const ClickableCardContent = styled(CardContent)<ClickableCardContentProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "left",
  margin: "auto 0px",
  padding: "unset",
  "&:last-child": { paddingBottom: "unset" },
}));

/**
 * A container for the CardButton icon.
 */
export interface IconContainerProps {
  selected?: boolean;
  disabled?: boolean;
}

export const IconContainer = styled(Box)<IconContainerProps>(({ theme, selected, disabled }) => {
  let styles: CSSObject = {
    margin: "auto 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    width: 43,
    borderRadius: 4,
    marginRight: theme.spacing(1.75),
  };

  if (selected) {
    styles = {
      ...styles,
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.main,
      "& svg": { fill: theme.palette.common.white },
    };
  } else {
    styles = {
      ...styles,
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.common.white,
      "& svg": { fill: theme.palette.primary.main },
    };
  }

  if (disabled) {
    styles = {
      ...styles,
      border: `1px solid ${theme.palette.biarri.primary.darkGrey}`,
      background: theme.palette.biarri.primary.grey,
      "& svg": { fill: theme.palette.biarri.primary.darkGrey },
    };
  }

  return styles;
});
