import { Box, Paper, styled, Typography } from "@mui/material";

export const StackablePanel = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  overflowY: "auto",
}));

export const StackablePanelHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 40,
  borderRadius: theme.shape.borderRadius,
  background: "#f5f5f5",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const StackablePanelHeaderTitle = styled(Typography)`
  font-weight: 700;
  color: #e1373b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NavigationButtonIcon = styled(Box)<{ size: "large" | "small"; disabled?: boolean }>(({ theme, size, disabled = false }) => ({
  display: "flex",
  height: size === "small" ? 24 : 32,
  width: size === "small" ? 30 : 40,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 4,
  color: disabled ? theme.palette.biarri.primary.grey : theme.palette.primary.main,
  borderColor: disabled ? theme.palette.biarri.primary.grey : theme.palette.primary.main,
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1),

  "& svg": {
    height: size === "small" ? 18 : 24,
  },
}));

export const NavigationButton = styled(Paper)<{ checked: boolean; disabled: boolean }>(({ theme, checked, disabled, variant }) => ({
  display: "flex",
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "space-between",
  margin: ` ${theme.spacing(2)} 0`,

  ...(variant === "elevation" ? { border: `1px solid ${checked ? theme.palette.primary.main : "transparent"}` } : {}),

  ...(checked
    ? {
        "& .navigation-button-icon": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      }
    : {}),

  ...(disabled
    ? {
        backgroundColor: "rgba(156, 156, 157, 0.25)",
        cursor: "not-allowed",
      }
    : {
        backgroundColor: "#F9FAFB",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(33, 150, 243, 0.05)",
          borderColor: theme.palette.primary.main,
        },
        "&:hover .navigation-button-icon": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      }),
}));
