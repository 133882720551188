import { Box, styled, Typography } from "@mui/material";

export const StyledBanner = styled(Box)(({ theme }) => ({
  backgroundColor: "#8fcaf7",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: 48,
  zIndex: 10,
  color: "#ffffff",
  boxShadow: "2px 4px 10px rgba(0,0,0,.25)",
  paddingRight: theme.spacing(2),
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  width: "90%",
  padding: 10,
  paddingLeft: theme.spacing(2.5),
}));
