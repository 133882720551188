import { get } from "lodash";

import { PlannerFeatures } from "fond/types";

/**
 * Find the preferred description from the fieldLabels based on the planner feature and the path.
 */
export const getPreferredDescription = (plannerFeature: PlannerFeatures | undefined, path: string[]): string | null => {
  if (!plannerFeature) return null;
  const preferredDescription = fieldDescription[plannerFeature];
  return get(preferredDescription, path);
};

const fieldDescription: { [feature in PlannerFeatures]: any } = {
  FlexNap: {
    Tier1: {
      Hubs: {
        Sizes: "Enter the sizes of drop terminals, in terms of their port count",
        Types: "Select the type of drop terminals from the list",
        SplitRatio: "If splitting in the drop terminals choose the split ratio, otherwise choose None",
      },
    },
    Tier2: {
      ConnectorizedDropTerminals: {
        Enabled: "Use fewer splice locations by consolidating terminal tails",
      },
    },
  },
};
