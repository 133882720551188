import { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EditAttributes } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from "@mui/material";

import { UserAccountAllocationResponse } from "fond/types";

import EditUserModal from "./EditUserModal";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
}

const UserRowAction: FC<IProps> = ({ userAccountAllocation }) => {
  const [searchParams] = useSearchParams();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const closeModal = () => setIsEditUserModalOpen(false);

  /**
   * Show edit user modal if admin is redirected to /settings/users from request license notification
   */
  const shouldAllocateLicense = searchParams.get("allocateLicense") === "true";
  const licenseAllocationUserId = searchParams.get("userId");
  useEffect(() => {
    if (shouldAllocateLicense && userAccountAllocation.User.ID === licenseAllocationUserId) {
      setIsEditUserModalOpen(true);
    }
  }, [licenseAllocationUserId, shouldAllocateLicense, userAccountAllocation.User.ID]);

  return (
    <>
      <IconButton ref={ref} aria-haspopup="true" data-testid="user-row-action-button" onClick={() => setShowMenu(true)} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid="user-row-action-menu"
        anchorEl={ref.current}
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem
            aria-label="Manage user"
            data-testid="edit-user"
            onClick={() => {
              setIsEditUserModalOpen(true);
              setShowMenu(false);
            }}
          >
            <ListItemIcon>
              <EditAttributes fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Manage user</Typography>
          </MenuItem>
          {/* TODO(FND-31) */}
          {/* <MenuItem aria-label="Upgrade" data-testid="upgrade">
            <ListItemIcon>
              <UpgradeIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Upgrade</Typography>
          </MenuItem> */}
        </MenuList>
      </Menu>
      {isEditUserModalOpen && <EditUserModal userAccountAllocation={userAccountAllocation} closeModal={closeModal} />}
    </>
  );
};

export default UserRowAction;
