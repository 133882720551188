import React, { useMemo } from "react";
import { Lock } from "@mui/icons-material";
import { Box } from "@mui/material";

import { AreaDrawPanel, AreaPanel, AssessPanel, ScoringPanel } from "fond/cityPlanner";
import { ArchitectureBase, MultiProject } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AccordionStepper, AccordionStepperPane, NonIdealState, StackedNavigation } from "fond/widgets";

import { PlanPanel } from "../PlanPanel";

type IProps = {
  /**
   * The project currently being viewed / edited
   */
  multiProject: MultiProject;
  /**
   * The current architecture.
   */
  architecture: ArchitectureBase | null;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
};

const cityPlannerStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "areaDraw",
    name: "Subareas",
    component: <AreaDrawPanel />,
    useDefaultHeader: false,
  },
  {
    id: "scoring",
    name: "Scoring Inputs",
    component: <ScoringPanel />,
    useDefaultHeader: true,
  },
];

const AutoDesignPanel: React.FC<IProps> = ({ architecture, multiProject, readOnly }: IProps) => {
  const accordionPanes: AccordionStepperPane[] = useMemo(
    () => [
      {
        header: "Work area",
        id: "subareas",
        content: <AreaPanel multiProject={multiProject} />,
        isComplete: multiProject.Areas.length > 0,
      },
      {
        header: "Assess",
        id: "assess",
        content: <AssessPanel multiProject={multiProject} />,
        isComplete: !!multiProject.AssessConfiguration,
        optional: true,
      },
      {
        header: "Plan",
        id: "plan",
        content: <PlanPanel multiProject={multiProject} readOnly={readOnly} />,
        isComplete: multiProject.Areas.some((area) => area.Project?.ID),
      },
    ],
    [multiProject, readOnly]
  );

  if (!multiProject) return null;

  return (
    <Box data-testid="city-planner-design-panel" sx={{ height: "100%" }}>
      {!permissionCheck(multiProject.Permission?.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <StackedNavigation
          rootComponent={<AccordionStepper id={multiProject.ID} panes={accordionPanes} autoMove={["architecture"]} />}
          screens={cityPlannerStackedNavigationPanels}
        />
      )}
    </Box>
  );
};

export default AutoDesignPanel;
