// matches a string of the form " (i)" where i is a positive integer.
const re = /^ \(([1-9]\d*)\)$/;

/**
 * Return a name (based on `baseName`) that isn't present in `existingNames`.
 *
 * If `baseName` is not in use, then it will be returned. Otherwise, the returned name will be of the form
 * `${baseName} (1)`, `${baseName} (2)`, etc.
 *
 * @param baseName - The ideal name to use.
 * @param existingNames - An array of existing names to check against.
 * @returns A name that isn't in existingNames.
 */
export const generateUnusedName = (baseName: string, existingNames: string[]): string => {
  // fast path: if the name doesn't overlap already, we're done.
  if (!existingNames.includes(baseName)) {
    return baseName;
  }

  // slow path: find the first name of the form ${baseName} (${x}) that doesn't overlap.
  // Take each name of the form ${baseName} (${x}) with ${x} a positive integer and truncate it to ${x}.
  const usedXs = existingNames
    .map((name) => {
      if (!name.startsWith(baseName)) {
        return null;
      }

      // If the name doesn't match our pattern then it's safe to ignore it.
      const suffix = name.slice(baseName.length);
      const matches = suffix.match(re);
      return matches ? parseInt(matches[1], 10) : null;
    })
    .filter((index): index is number => index !== null);

  // We want to find the smallest positive value of x that is not in `usedXs` - by construction, the name
  // ${baseName} (${x}) will be unused.
  const uniqueUsedXs = [...new Set(usedXs)];
  uniqueUsedXs.sort((a, b) => a - b);

  // In the ordinary case, `uniqueUsedXs` will look something like [1, 2, 3, 4], in which case our first unused number
  // is five. In more exciting cases, it could look like [1, 2, 5000, 5001]. In this case, the first "wrong" value
  // is in the third entry, hence three is the first unused number.
  const gap = uniqueUsedXs.findIndex((value, index) => value !== index + 1);
  const unusedX = gap !== -1 ? gap + 1 : uniqueUsedXs.length + 1;

  return `${baseName} (${unusedX})`;
};
