import { Box, css, Paper, styled } from "@mui/material";

export const Container = styled(Paper)(
  ({ theme }) => css`
    padding: ${theme.spacing(3, 5)};
    margin: ${theme.spacing(4, 0, 5)};
  `
);

Container.defaultProps = {
  elevation: 1,
};

export const ReadOnlyField = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};

    .field-title {
      margin-bottom: ${theme.spacing(1.5)};
      font-weight: 500;
    }
  `
);
