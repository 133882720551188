import React from "react";

import { HandShakeIcon, PlannerElement, PlannerLogo } from "fond/svg_icons";

import { Content, CustomButton, ErrorMessage, Logo, SubText } from "./subscriptionErrorMessage.styles";

interface IProps {
  /**
   * Description of the missing subscription
   */
  description?: React.ReactNode;
}

const SubscriptionErrorMessage: React.FC<IProps> = ({ description }) => (
  <Content>
    <Logo>
      <PlannerElement />
      <PlannerLogo />
    </Logo>
    <ErrorMessage component="h3" variant="h6">
      {description ?? (
        <>
          Your
          <span>&nbsp;plan&nbsp;</span>
          doesn’t support this feature
        </>
      )}
    </ErrorMessage>
    <SubText component="h4" variant="h7">
      Unlock this and many more Premium Planning Features.
    </SubText>
    <CustomButton color="primary" variant="contained" href="mailto:bn.sales@biarrinetworks.com">
      Talk to Sales
    </CustomButton>
    <SubText component="h4" variant="h7">
      <HandShakeIcon />
      &nbsp; Book a quick meeting to upgrade your plan
    </SubText>
  </Content>
);

export default SubscriptionErrorMessage;
