import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";

import { formatNumber } from "fond/utils/number";

import ScoreCellRenderer from "../cellRenderer/scoreCellRenderer";

export const rankingColumns: ColDef[] = [
  {
    headerName: "",
    colId: "scoreIcon",
    field: "Score",
    width: 24,
    sortable: false,
    resizable: false,
    suppressHeaderMenuButton: true,
    cellRenderer: ScoreCellRenderer,
    cellStyle: { padding: 0 },
  },
  {
    headerName: "Name",
    field: "Name",
    flex: 1,
    resizable: false,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Score",
    field: "Score",
    width: 100,
    resizable: false,
    suppressHeaderMenuButton: true,
    sort: "desc",
    valueFormatter: (params: ValueFormatterParams) => (params.value === -1 ? "-" : formatNumber(params.value, 0)),
  },
];
