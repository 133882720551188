import React from "react";
import { AttachMoney, CalendarToday, Home, Store } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

import { NO_PHASE } from "fond/constants";
import { FullReport } from "fond/types";
import { formatCurrency } from "fond/utils/currency";
import { formatFractionPercent } from "fond/utils/number";

import GridCard from "../GridCard";

import { PaperRightIconWrapper } from "../report.styles";

interface IProps {
  report: FullReport;
}

const SummaryCards: React.FC<IProps> = ({ report: { Npv, BuildOrderMap, Schedules, CoveragePassings, BreakEven } }) => {
  const { palette } = useTheme();
  const positiveValueColor = palette.success.main;
  const negativeValueColor = palette.error.main;

  // Get the last phase ignoring the "NO_PHASE" phase.
  const phases = BuildOrderMap?.Phases;
  let lastPhase: string | undefined;
  if (phases) {
    lastPhase = phases.at(-1);
    if (lastPhase === NO_PHASE) {
      lastPhase = phases.at(-2);
    }
  }
  const serviceLocationsConnected = Schedules?.Hhc.find(
    (schedule) => schedule.AddressType === "All" && schedule.Phase === lastPhase
  )?.CumulativeTotal;
  const summaryDetails = [
    {
      key: "Npv",
      title: "NPV",
      color: Npv && Npv >= 0 ? positiveValueColor : negativeValueColor,
      icon: <AttachMoney />,
      data: formatCurrency(Npv),
    },
    {
      key: "ServiceLocationsConnected",
      title: "Service locations connected",
      icon: <Home />,
      data: (serviceLocationsConnected && serviceLocationsConnected >= 0 ? Math.floor(serviceLocationsConnected) : "-").toLocaleString(),
    },
    { key: "CoveragePassings", title: "Market coverage (passing)", icon: <Store />, data: formatFractionPercent(CoveragePassings) },
    { key: "BreakEven", title: "Time to break even", icon: <CalendarToday />, data: BreakEven || "N/A" },
  ];

  return (
    <>
      {summaryDetails.map(({ key, title, color, icon, data }) => (
        <GridCard key={key} breakpoints={{ lg: 3, sm: 6, xs: 12 }}>
          <Box height={105} display="flex" flexDirection="column" justifyContent="space-around" p={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="content" fontSize={12}>
                {title}
              </Typography>
              <PaperRightIconWrapper>{icon}</PaperRightIconWrapper>
            </Box>
            <Box pl={2}>
              <Typography color={color} variant="h3" component="span" fontWeight={700}>
                {data}
              </Typography>
            </Box>
          </Box>
        </GridCard>
      ))}
    </>
  );
};

export default SummaryCards;
