import { Box, Button, styled, Typography } from "@mui/material";

const DIALOG_HEIGHT = 388;
const DIALOG_WIDTH = 632;

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: ${DIALOG_HEIGHT}px;
  width: ${DIALOG_WIDTH}px;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2.5)}`};

  & .swiper {
    width: 100%;
    flex: 1;
  }
`;

export const DialogContent = styled(Box)`
  position: relative;
  flex: 1;
`;

export const Slide = styled(Box)<{ active: boolean }>`
  display: flex;
  column-gap: 42px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(4)}`};
  background: ${({ theme }) => theme.palette.common.white};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s;
  z-index: ${({ active }) => (active ? 2 : -1)};

  & > div {
    flex: 1;
  }
`;

export const BodyText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.biarri.secondary.grey};
` as typeof Typography;

export const DialogActions = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
`;

export const StepImage = styled(Box)<{ image: string }>`
  background: url(${({ image }) => image}) center no-repeat;
  background-size: contain;
`;

export const TextButton = styled(Button)`
  color: ${({ theme }) => theme.palette.biarri.primary.grey};
` as typeof Button;

export const SlidePagination = styled(Box)<{ active: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 6px;
  background: ${({ active, theme }) => (active ? theme.palette.primary.main : theme.palette.biarri.primary.grey)};
  cursor: pointer;
`;
