import React from "react";
import { useSelector } from "react-redux";

import { Store } from "fond/types";

import MainWorkspaceBreadcrumbs from "./MainWorkspaceBreadcrumbs";
import RecentBreadcrumbs from "./RecentBreadcrumbs";
import SharedBreadcrumbs from "./SharedBreadcrumbs";
import StarredBreadcrumbs from "./StarredBreadcrumbs";

const Breadcrumbs: React.FC = () => {
  const { filters } = useSelector((state: Store) => state.projects);
  const breadcrumbDisplay = {
    workspace: <MainWorkspaceBreadcrumbs />,
    shared: <SharedBreadcrumbs />,
    recent: <RecentBreadcrumbs />,
    starred: <StarredBreadcrumbs />,
  };
  return breadcrumbDisplay[filters];
};

export default Breadcrumbs;
