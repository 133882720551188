import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import { StackablePanelHeader, StackablePanelHeaderTitle } from "./StackedNavigation.styles";

interface IProps {
  onBackButtonClick: () => void;
  title: string;
  titleAlignment?: "left" | "center";
}

const StackedNavigationHeader: React.FC<IProps> = ({ onBackButtonClick, title, titleAlignment = "left" }) => (
  <StackablePanelHeader data-testid="stacked-navigation-header">
    <IconButton data-testid="stacked-navigation-back-btn" size="small" onClick={onBackButtonClick}>
      <ArrowBack color="primary" fontSize="small" />
    </IconButton>
    <Box flex="1" px={1} minWidth={0} title={title}>
      <StackablePanelHeaderTitle textAlign={titleAlignment}>{title}</StackablePanelHeaderTitle>
    </Box>
    {/** when title alignment is center, below placeholder is placed opposite of the back button */}
    {titleAlignment === "center" && <Box width="30px" />}
  </StackablePanelHeader>
);

export default StackedNavigationHeader;
