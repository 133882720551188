import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

interface IProps {
  headerTitle: string;
  headerIcon: SvgIconComponent;
  content: React.ReactNode | string;
  tooltipText: string;
}

const SummaryItem: React.FC<IProps> = ({ headerTitle, headerIcon: Icon, content, tooltipText }) => (
  <Tooltip title={tooltipText} followCursor>
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography variant="content" fontSize={12} lineHeight={1.2}>
          {headerTitle}
        </Typography>
        <Icon fontSize="small" color="secondary" sx={{ ml: 2.5 }} />
      </Box>
      {typeof content === "string" ? (
        <Typography variant="h3" component="span" fontWeight={700} whiteSpace="nowrap">
          {content}
        </Typography>
      ) : (
        content
      )}
    </Box>
  </Tooltip>
);

export default SummaryItem;
