import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { InfoOutlined, Lock } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import Architecture from "fond/architecture/Architecture";
import { LayerGroupIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import {
  cancelLayerOperation,
  closeArchitectureModal,
  confirmLayerOperation,
  isSolveActive as getIsSolveActive,
  Modals,
  openArchitectureModal,
  StatusTypes,
} from "fond/project/redux";
import { Architecture as _Architecture, Project, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AccordionStepper, AccordionStepperPane, ConfirmModal, NonIdealState, StackedNavigation } from "fond/widgets";

import { selectReportsByVersionId, useGetReportsQuery, useGetVersionStatusQuery } from "../../api";
import SolvePanel from "../solve/SolvePanel";
import { getCurrentProject } from "..";

import ReportPanel from "./ReportPanel/ReportPanel";
import ArchitecturePanel from "./ArchitecturePanel";
import AreaSelect from "./AreaSelectPanel";
import Draw from "./DrawPanel";
import ImportData from "./ImportDataPanel";
import InputDataPanel from "./InputDataPanel";
import InputMethodsPanel from "./InputMethodsPanel";
import PolygonSelect from "./PolygonSelectPanel";
import UploadPanel from "./UploadPanel";

import { Badge } from "./AutoDesignPanel.styles";

type IProps = {
  /**
   * The project currently being viewed / edited
   */
  project: Project;
  /**
   * The current architecture.
   */
  architecture: _Architecture | null;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
};

interface RouteParams {
  folderId: string;
}

export const autoDesignStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "addresses",
    name: "Addresses",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inAddress} />,
  },
  {
    id: "undergroundPath",
    name: "Underground Path",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inStreet} />,
  },
  {
    id: "aerialSpanAndPoles",
    name: "Aerial Span and Poles",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.spanPole} />,
  },
  {
    id: "centralOffice",
    name: "Central Office",
    component: <InputMethodsPanel layerGroupId={LayerGroupIds.inExchange} />,
  },
  {
    id: "area-select",
    name: "Area Select",
    component: <AreaSelect />,
    useDefaultHeader: false,
  },
  {
    id: "upload",
    name: "Upload",
    component: <UploadPanel />,
    useDefaultHeader: false,
  },
  {
    id: "draw",
    name: "Draw on Map",
    component: <Draw />,
    useDefaultHeader: false,
  },
  {
    id: "draw-polygon",
    name: "Draw Polygon",
    component: <PolygonSelect />,
    useDefaultHeader: false,
  },
  {
    id: "import-data",
    name: "Import data",
    component: <ImportData />,
  },
  {
    id: "use-existing-polygon",
    name: "Use existing polygon",
    component: <PolygonSelect />,
    useDefaultHeader: false,
  },
];

/**
 * Current behaviour:
 *
 * It's not possible to enter a step beyond the current step.
 *
 * "Active" means the currently-opened step
 * "Current" means the furthest step which the user has not yet completed
 *
 * The colour of a step is determined by the following rules. If a step matches
 * more than one description, the first is used.
 * 1. Active step is blue ("active colour")
 * 2. Completed step is green ("completed colour")
 * 3. Current step is white-ish with a blue border ("current colour")
 * 3. Disabled step is grey
 */

/*
 * Upload behaviour:
 *
 * - If no file is uploaded, go straight to the upload form.
 * - If there is a file uploaded but no design exists, go to "file exists" form.
 * - If there is a file uploaded and a design exists, the "confirm replace design" modal.
 *   If the user accepts, go straighht to the upload form (the "file exists" form would be
 *   redundant in this case).
 */

const AutoDesignPanel: React.FC<IProps> = ({ project, architecture, readOnly }: IProps) => {
  const dispatch = useAppDispatch();
  const HasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);
  const modal = useSelector((state: Store) => state.project.modal);
  const confirmMessage: "replaceDesign" | "replaceLayer" | "unsavedEdits" = useSelector((state: Store) =>
    state.project.confirm != null ? state.project.confirm.message : null
  );
  const isSolveActive = useSelector((state: Store) => getIsSolveActive(state));
  const { Permission: projectPermission } = project;
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });

  useGetReportsQuery(undefined);
  const versionReports = useSelector((store: Store) => selectReportsByVersionId(store, versionId));

  const handleOnChangeArchitectureClick = useCallback(() => dispatch(openArchitectureModal()), [dispatch]);
  const handleOnConfirmLayerOperation = () => dispatch(confirmLayerOperation());
  const handleOnCancelLayerOperation = () => dispatch(cancelLayerOperation());
  const handleOnArchitectureModalClose = () => dispatch(closeArchitectureModal());

  const accordionPanes: AccordionStepperPane[] = useMemo(
    () => [
      {
        header: "Architecture",
        id: "architecture",
        content: (
          <div style={{ textAlign: "left" }}>
            <ArchitecturePanel
              architecture={architecture}
              readOnly={readOnly}
              onChangeClick={handleOnChangeArchitectureClick}
              isMultiProjectArea={Boolean(project.MultiProjectArea)}
            />
          </div>
        ),
        isComplete: architecture !== null,
      },
      {
        header: "Input data",
        id: "inputData",
        content: <InputDataPanel readOnly={readOnly} />,
        isComplete: versionStatus?.Status !== StatusTypes.NotReady,
      },
      // The solve tab pane includes the solve panel. This naming is a little odd and could be improved.
      {
        header: "Design",
        id: "design",
        content: (
          <div>
            <SolvePanel readOnly={readOnly} />
          </div>
        ),
        isComplete: versionStatus?.Status === StatusTypes.Complete && !versionStatus.IsDirty,
        isLoading: isSolveActive,
      },
      {
        header: "Report",
        id: "report",
        content: (
          <div>
            <ReportPanel reports={versionReports} project={project} versionId={versionId} readOnly={readOnly} />
          </div>
        ),
        isComplete: versionReports.length > 0,
        rightAdornment: <Badge>New</Badge>,
      },
    ],
    [
      architecture,
      handleOnChangeArchitectureClick,
      isSolveActive,
      project,
      readOnly,
      versionId,
      versionReports,
      versionStatus?.IsDirty,
      versionStatus?.Status,
    ]
  );

  if (HasCustomLayerConfig) {
    return <NonIdealState size="small" icon={<InfoOutlined />} description={<>There are no design tools available.</>} />;
  }

  const confirmModals: { [key: string]: { header: string; message: string; confirmText?: string } } = {
    replaceDesign: {
      header: "Remove current design?",
      message:
        "There is already a design present in this project. Changing the input data or architecture will remove it. Are you sure you want to do this?",
    },
    replaceLayer: {
      header: "Replace data?",
      message: "You have already uploaded data for this layer. Proceeding with Area Select will replace it. Are you sure you want to do this?",
    },
    unsavedEdits: {
      header: "Discard edits?",
      message: "You have unsaved changes to the layer you are currently editing. Proceeding will discard them. Are you sure you want to do this?",
      confirmText: "Discard",
    },
  };

  const confirmModal = modal === Modals.confirm ? confirmMessage && confirmModals[confirmMessage] : null;

  return (
    <Box data-testid="auto-design-panel" sx={{ height: "100%" }}>
      {confirmModal ? (
        <ConfirmModal
          className="confirm-remove-current-design"
          open
          header={confirmModal.header}
          confirmText={confirmModal.confirmText}
          content={
            <div className="content">
              <Typography variant="body2">{confirmModal.message}</Typography>
            </div>
          }
          onConfirm={() => {
            mixpanel.track("Accepted confirm override");
            handleOnConfirmLayerOperation();
          }}
          onCancel={() => {
            mixpanel.track("Cancelled confirmed override");
            handleOnCancelLayerOperation();
          }}
        />
      ) : (
        modal === Modals.architecture && (
          <Architecture onClose={() => handleOnArchitectureModalClose()} project={project} architecture={architecture} />
        )
      )}

      {!permissionCheck(projectPermission.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <StackedNavigation
          rootComponent={<AccordionStepper id={project.ID} panes={accordionPanes} autoMove={["architecture"]} />}
          screens={autoDesignStackedNavigationPanels}
        />
      )}
    </Box>
  );
};

export default AutoDesignPanel;
