import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Config } from "final-form";
import arrayMutators from "final-form-arrays";
import { orderBy } from "lodash";

import { LoadingButton } from "ui";

import { selectAllFolders, selectCurrentAllocation, selectPlannerProjectsWithinAccount, useGetProjectsQuery } from "fond/api";
import { DropzoneField, ProjectVersionField, TextField } from "fond/form/fields";
import { Report } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, licenseCheck } from "fond/utils/permissions";
import { requireAll, required } from "fond/utils/validation";
import { validFileName } from "fond/widgets/Dropzone/utils";

import { hasCreateProjectReportPermission } from "../ReportSettings/ReportSettingsForm";
import { ReportImportFormData } from "../types";

import { fileMap } from "./files";
import RequiredFiles from "./RequiredFiles";

import { Container } from "../ReportSettings/reportSettings.styles";
import { Disclaimer, ReportConfigurationSection } from "../ReportSettings/reportSettingsForm.styles";

interface IProps {
  initialValues: Partial<ReportImportFormData>;
  onSubmit: Config<ReportImportFormData>["onSubmit"];
  onUpdateDetails?(values: ReportImportFormData): void;
  onCancel?(): void;
  testId?: string;
  report?: Report;
}

const ImportSettingsForm: React.FC<IProps> = ({ initialValues, onSubmit, onUpdateDetails, onCancel, testId, report }) => {
  useGetProjectsQuery(undefined);
  const allProjects = useSelector(selectPlannerProjectsWithinAccount);
  const folders = useSelector(selectAllFolders);
  const projects = useMemo(() => orderBy(allProjects, "CreatedAt", "desc").filter(hasCreateProjectReportPermission), [allProjects]);
  const currentAllocation = useAppSelector(selectCurrentAllocation);
  const hasValidLicense = licenseCheck(currentAllocation, Actions.REPORT_GENERATE);

  return (
    <Box data-testid={testId}>
      <Form<ReportImportFormData>
        initialValues={initialValues}
        validateOnBlur
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography color="GrayText">
                Ensure to input and review all data into the fields below before generating a report. These estimates serve as benchmarks for
                evaluating return on investments, but they do not ensure market success or specific revenue receipts. Use them as a tool to assess the
                costs and potential outcomes of your investments.
              </Typography>
              <Typography variant="h5" fontWeight={700} sx={{ mt: 8 }}>
                Report details
              </Typography>
              <Container>
                <Box maxWidth={300}>
                  <TextField name="Name" required label="Report name" fullWidth validate={required} />
                </Box>
                <Box mt={2}>
                  <TextField name="Description" label="Description" fullWidth multiline rows={3} />
                </Box>
              </Container>
              <Grid container>
                <Grid item xs>
                  <Typography variant="h5" fontWeight={700}>
                    Configuration
                  </Typography>
                </Grid>
              </Grid>
              <ReportConfigurationSection className="report-configuration-section">
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    Project source
                  </Typography>
                  <Box mt={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <ProjectVersionField projects={projects} folders={folders} projectValidator={required} versionValidator={required} />
                  </Box>
                </Container>
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    Cost to serve
                  </Typography>
                  <Box my={2} display="flex" columnGap={6}>
                    <DropzoneField
                      name="CTSFiles"
                      acceptedFiles={{
                        "application/json": [".json"],
                      }}
                      fullWidth
                      message="Drag and drop files or click to open explorer."
                      dropzoneValidator={validFileName(fileMap.CTSFiles.map(({ file }) => file))}
                      validate={requireAll(fileMap.CTSFiles.length)}
                    />
                  </Box>
                  <RequiredFiles type="CTSFiles" />
                </Container>
                <Container>
                  <Typography variant="h6" fontWeight={700}>
                    CFO
                  </Typography>
                  <Box my={2} display="flex" columnGap={6}>
                    <DropzoneField
                      name="CFOFiles"
                      acceptedFiles={{
                        "application/json": [".json", ".geojson"],
                        "text/csv": [".csv"],
                      }}
                      fullWidth
                      message="Drag and drop files or click to open explorer."
                      dropzoneValidator={validFileName(fileMap.CFOFiles.map(({ file }) => file))}
                      validate={requireAll(fileMap.CFOFiles.length)}
                    />
                  </Box>
                  <RequiredFiles type="CFOFiles" />
                </Container>
              </ReportConfigurationSection>
              <Disclaimer>
                <Typography>By clicking "Generate report", I agree that all data is correct and reviewed as instructed above.</Typography>
                <Box>
                  <Button type="button" data-testid="report-cancel-button" color="primary" onClick={onCancel} sx={{ mr: 1 }} disabled={submitting}>
                    Cancel
                  </Button>
                  <LoadingButton type="submit" data-testid="report-save-button" color="primary" loading={submitting} disabled={!hasValidLicense}>
                    Generate report
                  </LoadingButton>
                </Box>
              </Disclaimer>
            </form>
          );
        }}
        mutators={{ setValue, ...arrayMutators }}
      />
    </Box>
  );
};

export default ImportSettingsForm;
