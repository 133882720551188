import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Alert, Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectFoldersForAddingProject, useUpdateProjectMutation } from "fond/api";
import { HOME_FOLDER_ID, HOME_FOLDER_LABEL, HOME_FOLDER_OPTION } from "fond/constants";
import { Autocomplete } from "fond/form/fields";
import { AnyObject, Folder, HomeFolder, ProjectPreview, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { required } from "fond/utils/validation";
import { Modal } from "fond/widgets";

interface IFormData {
  FolderId: string;
}

interface IProps {
  project: ProjectPreview;
  onClose(): void;
}

const MoveProjectDialog: React.FC<IProps> = ({ project, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const folders = useSelector((state: Store) => selectFoldersForAddingProject(state, project));
  const canMove = permissionCheck(project?.Permission.Level, Actions.PROJECT_MOVE);
  const [updateProject] = useUpdateProjectMutation();

  // folderOptions adds home route that allows user to move a project back to home screen
  // It sets the ID to "home" rather than null because TS will only allow for a string
  const folderOptions = useMemo(() => {
    return [HOME_FOLDER_OPTION, ..._.sortBy(folders, ["Path", "Name"])];
  }, [folders]);

  /**
   * Note that we need to use closure to trigger the submit of the form.
   * This is due to the submit button existing in the modal actions rather than in the <form />.
   *
   * See https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
   */
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit if validation passes)
    submit();
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const onSubmit = async (values: IFormData) => {
    const folderId = values.FolderId;
    setSaving(true);

    try {
      await updateProject({ ID: project.ID, FolderID: folderId === HOME_FOLDER_ID ? null : folderId }).unwrap();
      enqueueSnackbar("The Project has been moved");
    } catch {
      enqueueSnackbar("Project move failed. Please try again...");
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <Modal
      open
      header="Move this project"
      data-testid="move-folder-modal"
      content={
        !canMove ? (
          <Box mb={2}>
            <Alert severity="info">You do not have permission to move this project.</Alert>
          </Box>
        ) : (
          <>
            <Box mb={2} data-testid="move-alert-message">
              <Alert severity="warning">Moving a project to a new location has the potential to change who can access & edit this project.</Alert>
            </Box>
            <Form<IFormData>
              onSubmit={onSubmit}
              initialValues={{ FolderId: project.FolderID || HOME_FOLDER_ID }}
              render={({ handleSubmit }) => {
                submit = handleSubmit;
                return (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <Autocomplete
                        name="FolderId"
                        required
                        label="Folder"
                        fullWidth
                        options={folderOptions}
                        getOptionLabel={(option) => option.Name}
                        getOptionValue={(option) => option?.ID || HOME_FOLDER_ID}
                        renderOption={(props, option: Folder | HomeFolder) => (
                          <li {...props} key={option.ID}>
                            <Box data-testid="move-folder-item" display="flex" flexDirection="column">
                              <Typography>{option.Name}</Typography>
                              <Typography variant="caption">
                                {`${project.Account.Name} > ${HOME_FOLDER_LABEL} ${option.Path.length ? " > " : ""} ${option.Path.join(" > ")}`}
                              </Typography>
                            </Box>
                          </li>
                        )}
                        filterOptions={(options, state) =>
                          options.filter((option) => option.Name.toLowerCase().includes(state.inputValue.toLowerCase()))
                        }
                        validate={required}
                      />
                    </Box>
                  </form>
                );
              }}
            />
          </>
        )
      }
      actions={
        <>
          <Button data-testid="move-folder-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          {canMove && (
            <LoadingButton data-testid="move-folder-save-button" color="primary" onClick={handleOnClick} loading={saving}>
              Update
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default MoveProjectDialog;
