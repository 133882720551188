import React from "react";

import { PageContent } from "fond/widgets";

import { Page } from "../constants";
import Navigation from "../Navigation";

import ImportReportForm from "./ImportReportForm";

const ImportReport: React.FC = () => {
  return (
    <PageContent
      windowTitle="Import Report - Settings"
      title="Settings"
      mainContent={<ImportReportForm />}
      navigation={<Navigation page={Page.SETTINGS} isCreatingReport />}
      testId="import-report-page"
    />
  );
};

export default ImportReport;
