import React from "react";
import { Form, FormSpy } from "react-final-form";
import { GridOptions } from "@ag-grid-community/core";
import { Undo } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton, Table, TableBody, TableCell, TableHead, TableRow } from "ui";

import { multiProjectsSlice, useGetMultiProjectQuery, useLazyGetMultiProjectQuery, useUpdateMultiProjectMutation } from "fond/api";
import { MultiProjectArea } from "fond/types";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { AgGrid, BlockSpinner, useStackedNavigationContext } from "fond/widgets";

import { rankingColumns } from "./rankingColumns";
import ScoringInputs from "./ScoringInputs";

interface IFormData {
  AddressDensityImportance: number;
  AddressUnderservedProportionImportance: number;
}

const ScoringPanel: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const versionId = useAppSelector((state) => state.project.versionId);
  const { data: multiProject } = useGetMultiProjectQuery(versionId);
  const [getMultiProject, { isFetching }] = useLazyGetMultiProjectQuery();
  const [updateMultiProject, { isLoading }] = useUpdateMultiProjectMutation();
  const { goBack } = useStackedNavigationContext();

  const initialValue = multiProject?.AssessConfiguration || {
    AddressDensityImportance: 0.5,
    AddressUnderservedProportionImportance: 0.5,
  };

  const gridOptions: GridOptions<MultiProjectArea> = {
    animateRows: true,
    editType: "fullRow",
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    singleClickEdit: true,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "normal",
    getRowId: (params) => params.data.ID,
  };

  const handleBack = async () => {
    // Manually refresh the multiproject data to restore to saved version.
    await getMultiProject(versionId, false).unwrap();
    goBack();
  };

  const handleOnReset = async () => {
    await getMultiProject(versionId, false).unwrap();
  };

  const handleOnSave = async (values: IFormData) => {
    if (multiProject) {
      try {
        await updateMultiProject({ ID: multiProject.ID, AssessConfiguration: values });
        enqueueSnackbar("Scoring inputs have been updated.");
        goBack();
      } catch (e) {
        enqueueSnackbar("Scoring update failed. Please try again...");
      }
    }
  };

  const handleOnSubmit = (values: IFormData) => {
    if (multiProject) {
      dispatch(
        multiProjectsSlice.util.updateQueryData("getMultiProject", multiProject.ID, (draft) => {
          draft.AssessConfiguration = values;
        })
      );
    }
  };

  if (!multiProject) {
    return <BlockSpinner />;
  }

  return (
    <Box>
      <Form<IFormData>
        initialValues={initialValue}
        onSubmit={handleOnSave}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} data-testid="assess-scoring-form" id="assess-scoring-form">
            <Table variant="ag-grid-compact">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell width={100}>Importance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ScoringInputs />
              </TableBody>
            </Table>

            {/* We use FormSpy to submit the form values on every update */}
            <FormSpy
              subscription={{ values: true, pristine: true }}
              onChange={({ pristine, values }) => {
                if (!pristine) {
                  handleOnSubmit(values as IFormData);
                }
              }}
            />
          </form>
        )}
      />

      <Typography variant="h6" sx={{ mt: 2, fontSize: 14, fontWeight: 500 }}>
        Rankings
      </Typography>
      <AgGrid
        containerProps={{
          height: 300,
        }}
        columnDefs={rankingColumns}
        rowData={multiProject?.Areas || null}
        size="compact"
        variant="outlined"
        gridOptions={gridOptions}
        autoSizeColumns={false}
      />
      <Divider sx={{ mt: 3 }} />
      <Button
        fullWidth
        sx={{ my: 0.5, justifyContent: "left" }}
        startIcon={<Undo sx={{ height: 20 }} />}
        disabled={isFetching}
        onClick={handleOnReset}
      >
        <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
          Reset
        </Typography>
      </Button>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
        <LoadingButton color="primary" size="small" onClick={handleBack} variant="text" loading={isFetching}>
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          size="small"
          type="submit"
          sx={{ ml: 1, px: 2 }}
          data-testid="finish-button"
          form="assess-scoring-form"
          loading={isLoading}
        >
          Apply
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ScoringPanel;
