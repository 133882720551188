import { useContext, useEffect, useRef } from "react";
import { usePrevious } from "react-use";

import { getLayerStyleOrderIDs } from "fond/layers/functions";
import { MapContext } from "fond/map/MapProvider";
import { MapboxStyleLayer } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

export interface IProps {
  /**
   * The ID of an existing layer to insert the new layer before, resulting in the new layer appearing visually beneath the existing layer.
   */
  beforeId?: string;
  /**
   * The ID to use when making the mapbox source
   */
  sourceId: string;

  /**
   * The mapbox source for this component
   */
  source: any; // GeoJSONSourceRaw | VectorSource; // { tiles: string[]; type: string; promoteId?: string } | { data: any; type: string };

  /**
   * All styles that the current project references.
   */
  styles: LayerStyle[];

  /**
   * For FOND Planner projects we pass each layer individually to SourceAndLayers
   * rather than one component for all layers due to the requirement of the source
   * being specific to each layer
   */
  layer?: LayerConfig | SublayerConfig;

  /**
   * A flat list (in order) of the layers and sublayers to be rendered onto the map
   */
  layers: Array<LayerConfig | SublayerConfig>;

  /**
   * A mapping of layer & sublayer IDs to booleans designating whether the
   * particular layers should be shown or not. Any keys that don't correspond to the layer ID
   * or any of the sublayer IDs will be ignored.
   */
  layerVisibilities?: { [layerId: string]: boolean };
  /**
   * Callback function for determining if a layer is visible.
   */
  isVisible(id: string, layerView: { [key: string]: boolean }): boolean;
}

/**
 * Manages a Mapbox source and a set of layers that refer to it.
 *
 * We have some awkward terminology mismatches here.
 *
 * - a FOND Layer maps to a Mapbox source
 * - the `styles` map to Mapbox layers
 * - Sublayers map to Mapbox layers
 *
 * We have a <SourceAndLayers> component rather than a <Source> component
 * containing <Layer> components as children, because for Mapbox we need to
 * cleanup layers before the sources that contain them, but React does not
 * unmount children before unmounting their parents.
 */
const SourceAndLayers = ({ beforeId, layer, layers, source, sourceId, styles, layerVisibilities = {}, isVisible }: IProps): null => {
  const { map } = useContext(MapContext);
  const mapLayers = layer ? [layer] : layers;
  const prevStyleIds = usePrevious(getLayerStyleOrderIDs(mapLayers)) || [];
  const prevLayerVisibilities: { [layerId: string]: boolean } = usePrevious(layerVisibilities) || {};
  // We need to maintain a ref to the layerVisibilities & source & styles so that inside
  // the mapbox event styledataloading we reference the current values
  const sourceRef = useRef(source);
  const stylesRef = useRef(styles);
  const mapLayersRef = useRef(mapLayers);
  const visibilitiesRef = useRef(layerVisibilities);

  useEffect(() => {
    mapLayersRef.current = mapLayers;
    stylesRef.current = styles;
    add();
    handleStyleRemoval();
    handleStyleOrderIDsChange();
  }, [mapLayers, styles]);

  useEffect(() => {
    add();
    map?.on("styledataloading", () => {
      // When the user switches from streets <-> satellite, all our sources /
      // layers are removed from the map, so we need to re-add them. But Mapbox
      // is a bit overenthusiastic in sending the styledata event, so we use
      // this `once` call (once for each styledataloading) to avoid infinite
      // loops.
      // See https://stackoverflow.com/a/66329525
      map.once("styledata", () => {
        add();
      });
    });

    return () => {
      // cleanup - remove source and layers
      removeAll();
    };
  }, []);

  /**
   * Monitor any layer visibility changes & determine if layer or sublayer
   * visibility needs to be updated for the related styles.
   */
  useEffect(() => {
    visibilitiesRef.current = layerVisibilities;
    setVisibility();
  }, [layerVisibilities]);

  /**
   * Monitor for source data changes & update the existing source if required.
   */
  useEffect(() => {
    sourceRef.current = source;
    const existingSource = map?.getSource(sourceId);
    if (existingSource && existingSource.type === "geojson" && source.type === "geojson") {
      if (source?.data?.features) {
        existingSource.setData(source.data);
      }
    }
  }, [(source as any).data?.features]);

  /**
   * Adds a mapbox layer (style) to the map
   */
  const addMapboxLayer = ({
    styleId,
    layerKey,
    visible,
    before,
    filter,
  }: {
    styleId: string;
    layerKey: string;
    visible: boolean;
    before?: string;
    filter?: any[];
  }) => {
    const style = stylesRef.current.find((s) => s.ID === styleId);

    if (map && style && !map.getLayer(styleId)) {
      if (source.type === "vector") {
        map?.addLayer(
          {
            id: styleId,
            source: sourceId,
            "source-layer": layerKey,
            ...style.MapboxStyle,
            ...(filter ? { filter } : {}),
          } as MapboxStyleLayer,
          before
        );
      } else {
        map?.addLayer(
          {
            id: styleId,
            source: sourceId,
            ...style.MapboxStyle,
            ...(filter ? { filter } : {}),
          } as MapboxStyleLayer,
          before
        );
      }
      map?.setLayoutProperty(styleId, "visibility", visible ? "visible" : "none");
    }
  };

  /**
   * Adds the source and styles for a layer & its sublayers to the map.
   *
   * Note we reverse the layers before adding them so that
   * a layer at the top of the layer config is added
   * last, so appears on top in the map.
   */
  const add = () => {
    if (!map?.getSource(sourceId)) {
      map?.addSource(sourceId, sourceRef.current);
    }

    [...mapLayersRef.current].reverse().forEach((item) => {
      [...item.Styles].forEach((styleId, index) => {
        const insertBefore = index > 0 ? item.Styles[index - 1] : beforeId;
        addMapboxLayer({
          styleId,
          layerKey: item.Key,
          visible: isVisible(item.ID, visibilitiesRef.current),
          before: insertBefore,
          filter: item.Type === "SUBLAYER" ? item.FilterConfiguration?.Mapbox : undefined,
        });
      });
    });
  };

  /**
   * Remove the source and layers from the map
   */
  const removeAll = () => {
    mapLayers.forEach((item) => {
      item?.Styles.forEach(removeMapboxLayer);
    });

    if (map?.getSource(sourceId)) {
      map?.removeSource(sourceId);
    }
  };

  /**
   * Removes a specific layer from the map
   */
  const removeMapboxLayer = (id: string) => {
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }
  };

  /**
   * Determine if we need to remove any styles that have been removed
   * from the list of styles.
   */
  const handleStyleRemoval = () => {
    const newStyleIds: string[] = styles.map((style) => style.ID);
    prevStyleIds.forEach((id) => {
      if (!newStyleIds.includes(id)) {
        removeMapboxLayer(id);
      }
    });
  };

  /**
   * If the order of styles changes we need to determine
   * which item needs to be removed & inserted back into the
   * style stack using the "befor" option provided in addMapboxLayer
   */
  const handleStyleOrderIDsChange = () => {
    // Removal of styles does not need to be handled
    // And comparison on first render when prevStyleIds are not yet set should be skipped
    if (styles.length < prevStyleIds.length || (prevStyleIds.length === 0 && styles.length > 0)) return;

    const changedStyleIds: string[] = [];
    getLayerStyleOrderIDs(mapLayers).forEach((id, index) => {
      if (id !== prevStyleIds[index]) {
        changedStyleIds.push(id);
      }
    });

    // For now we simply remove and add all styles onto the map.
    // To avoid flickering we should look to change detection & remove
    // and re-insert only the styles that changed order
    if (changedStyleIds.length > 0) {
      removeAll();
      add();
    }
  };

  /**
   * Set the layer & sublayer visibility
   */
  const setVisibility = () => {
    mapLayers.forEach((item) => {
      const isLayerVisible = isVisible(item.ID, visibilitiesRef.current);
      const prevLayerVisible = isVisible(item.ID, prevLayerVisibilities);

      if (isLayerVisible !== prevLayerVisible) {
        item.Styles.forEach((styleId) => {
          map?.setLayoutProperty(styleId, "visibility", isLayerVisible ? "visible" : "none");
        });
      }
    });
  };

  return null;
};

export default SourceAndLayers;
