import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { getFolderAncestry } from "fond/api";
import { Folder, Store } from "fond/types";

interface RouteParams {
  folderId: string;
}

interface UseBreadcrumbsReturn {
  folderId: string | undefined;
  reverseAncestors: Folder[];
  handleOnClickNavigate: (url: string) => () => void;
}

export const useBreadcrumbs = (): UseBreadcrumbsReturn => {
  const { folderId } = useParams<keyof RouteParams>();
  const ancestors = useSelector((state: Store) => getFolderAncestry(state, folderId));
  const reverseAncestors = useMemo(() => [...ancestors].reverse(), [ancestors]);
  const navigate = useNavigate();

  const handleOnClickNavigate = (url: string) => () => {
    navigate(url);
  };

  return { folderId, reverseAncestors, handleOnClickNavigate };
};
