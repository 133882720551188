import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";

import { FullReport } from "fond/types";

import GridCard from "../GridCard";

import CostBreakdownChart from "./CostBreakdownChart";
import CostPerServiceLocationChart from "./CostPerServiceLocationChart";
import FinalPositionTable from "./FinalPositionTable";
import Interpretation from "./Interpretation";
import OverallPositionChart from "./OverallPositionChart";
import SummaryCards from "./SummaryCards";

interface IProps {
  report: FullReport;
}

const ReportOverviewPageContent: React.FC<IProps> = ({ report }) => (
  <Grid container spacing={2.5}>
    <Interpretation report={report} />
    <SummaryCards report={report} />
    <GridCard
      breakpoints={{ lg: 7.5, xs: 12 }}
      title="Overall position"
      headerRightElement={
        <Button data-testid="revenue-page-button" size="small" component={Link} to={`/reports/${report.ID}/revenue`}>
          View Revenue page
        </Button>
      }
    >
      <Box>
        <OverallPositionChart data={report.Schedules?.Cashflow ?? []} />
      </Box>
    </GridCard>
    <GridCard breakpoints={{ lg: 4.5, xs: 12 }} title="Final position">
      <Box py={2}>
        <FinalPositionTable report={report} />
      </Box>
    </GridCard>
    <GridCard
      breakpoints={{ lg: 7.5, xs: 12 }}
      title="Cost breakdown"
      headerRightElement={
        <Button data-testid="cost-page-button" size="small" component={Link} to={`/reports/${report.ID}/cost`}>
          View Cost page
        </Button>
      }
    >
      <Box>
        <CostBreakdownChart totalCost={report.TotalConstructionCost} data={report.IndexedCostSummary?.SharedCost ?? []} />
      </Box>
    </GridCard>
    <GridCard breakpoints={{ lg: 4.5, xs: 12 }} title="Cost per service location">
      <Box>
        <CostPerServiceLocationChart passings={report.Passings} totalConstructionCost={report.TotalConstructionCost ?? 0} />
      </Box>
    </GridCard>
  </Grid>
);

export default ReportOverviewPageContent;
