import { NotificationImportant as NotificationImportantIcon } from "@mui/icons-material";
import { Box, TableCell, TableRow } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { SelectField, TextField } from "fond/form/fields";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Make the table header more compact by minimising vertical space.
  display: "table",
  tableLayout: "fixed",
  width: "100%",
  borderBottom: `1px solid ${grey[300]}`,
  paddingLeft: theme.spacing(2),
  "&.selectedLayer": {
    backgroundColor: theme.palette.biarri.secondary.skyBlue,
  },
  // Add background color for even rows.
  "&:nth-of-type(even):not(.selectedLayer)": {
    backgroundColor: theme.palette.action.hover,
  },
  // Add top border for the first row.
  "&:first-of-type": {
    borderTop: `1px solid ${grey[300]}`,
  },
  // Remove border for the last row.
  "&:last-child": {
    border: 0,
  },
}));

export const BodyCell = styled(TableCell)(({ theme, width }) => ({
  // Make the table rows more compact by minimising cell padding. Disable borders for a cleaner compact look.
  padding: "1px 16px",
  marginLeft: theme.spacing(5),
  border: "unset",
  width,
  height: 34,
}));

export const LayerBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${grey[300]}`,
  borderRadius: theme.shape.borderRadius,
}));

export const LayerBoxHeader = styled(Box)(({ theme }) => ({
  backgroundColor: grey[200],
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`,
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

export const SelectedText = styled(Typography)(({ theme }) => ({
  color: blue[500],
  fontSize: 14,
}));

export const StyledCaption = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.biarri.primary.darkGrey,
}));

export const HeaderLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
}));

export const StyledCellLabel = styled(Typography)(({ theme }) => ({
  "& .selectedLayer": {
    color: blue[500],
  },
  fontSize: 14,
  padding: 0,
  margin: 0,
}));

export const StyledSelectField = styled(SelectField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: 32,
  },
  "& .MuiSelect-select": {
    fontSize: 14,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: 32,
    fontSize: 14,
  },
  "& .MuiTextField-root": {
    margin: 0,
  },
}));

export const StyledNotificationImportantIcon = styled(NotificationImportantIcon)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.biarri.primary.darkGrey,
  marginBottom: theme.spacing(0.25),
  marginRight: theme.spacing(0.5),
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.biarri.primary.darkGrey,
}));
