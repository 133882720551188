import React, { useState } from "react";
import { FormRenderProps } from "react-final-form";
import { Email as EmailIcon, Lock, Person } from "@mui/icons-material";
import { Alert, Box, Grid, Typography } from "@mui/material";

import { LoadingButton } from "ui";

import { MIN_PASSWORD_LENGTH, TOS_URL } from "fond/constants";
import { CheckboxField, TextField } from "fond/form/fields";
import { CognitoResponseCode } from "fond/utils";
import { compose, minChars, required, validEmailFormat } from "fond/utils/validation";
import { SystemErrorDialog, VisibilityAdornment } from "fond/widgets";
import { PageCentred } from "fond/widgets/PageCentred";

import SignInAdornment from "./SignInAdornment";

interface IProps extends Pick<FormRenderProps, "handleSubmit" | "submitting" | "submitError"> {
  cognitoResponseCode: string | null;
  setCognitoResponseCode: (val: string | null) => void;
}

/**
 * A sign up form which uses @aws-amplify/auth. When a signup is succesful, the
 * form is substituted for a message asking the user to check their email for a
 * verification link.
 */
const SignUpForm: React.FC<IProps> = ({ handleSubmit, submitting, submitError, cognitoResponseCode, setCognitoResponseCode }) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <PageCentred
      title="Sign up"
      description={<Typography sx={{ mr: 1 }}>Create a new account for free.</Typography>}
      rightAdornment={<SignInAdornment />}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="firstName"
              label="First name"
              validate={required}
              InputProps={{
                startAdornment: <Person color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastName"
              label="Last name"
              validate={required}
              InputProps={{
                startAdornment: <Person color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email"
              validate={compose(required, validEmailFormat)}
              InputProps={{
                startAdornment: <EmailIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Password"
              autoComplete="off"
              type={hidePassword ? "password" : "text"}
              validate={compose(required, minChars(MIN_PASSWORD_LENGTH))}
              showPasswordStrength
              InputProps={{
                startAdornment: <Lock color="action" />,
                endAdornment: <VisibilityAdornment isHidden={hidePassword} update={setHidePassword} />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              name="termsOfServiceAgreement"
              label={
                <Typography>
                  I accept the{" "}
                  <a rel="noopener noreferrer" href={TOS_URL} target="_blank">
                    Terms of Service
                  </a>
                </Typography>
              }
              validate={(value?: boolean) =>
                value ? undefined : "Please confirm that you have read and agree to the Terms of Service policy of this site."
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton loading={submitting} type="submit" size="large" fullWidth variant="contained" color="primary" data-testid="sign-up-button">
              CREATE ACCOUNT
            </LoadingButton>
          </Grid>
        </Grid>

        {submitError && (
          <Box mt={2}>
            <Alert data-testid="create-account-error-message" severity="error">
              {submitError}
            </Alert>
          </Box>
        )}

        <SystemErrorDialog
          open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
          networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
          onClose={() => {
            setCognitoResponseCode(null);
          }}
        />
      </form>
    </PageCentred>
  );
};

export default SignUpForm;
