import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Line, LineChart, ResponsiveContainer, Tooltip as RechartTooltip, TooltipProps, XAxis } from "recharts";

import { MapContext } from "fond/map/MapProvider";

import { Tooltip } from "../CameraExpressionField/cameraExpressionField.styles";

interface IProps {
  value: number;
  onChange(value: number): void;
}

const ZoomChart: React.FC<IProps> = ({ value, onChange }: IProps) => {
  const { map } = useContext(MapContext);
  const [currentZoom, setCurrentZoom] = useState<number>(0);

  useEffect(() => {
    map?.on("zoom", handleZoomChange);
    handleZoomChange();
    return () => {
      map?.off("zoom", handleZoomChange);
    };
  }, [map]);

  const handleZoomChange = useCallback(() => {
    setCurrentZoom(Math.floor(map?.getZoom() || 0));
  }, [map]);

  const chartData = useMemo(() => {
    return [
      {
        zoom: value,
        value: 0,
      },
      {
        zoom: currentZoom,
        current: 0,
      },
    ];
  }, [value, currentZoom]);

  const renderTooltip = ({ payload }: TooltipProps<any, "name">): JSX.Element => {
    return <Tooltip>{`${payload?.[0]?.payload.zoom}`}</Tooltip>;
  };
  return (
    <Box data-testid="zoom-chart">
      <ResponsiveContainer width="100%" height={50}>
        <LineChart data={chartData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <XAxis
            dataKey="zoom"
            interval="preserveStartEnd"
            type="number"
            tickCount={10}
            tickSize={8}
            ticks={[0, 4, 8, 12, 16, 20]}
            scale="linear"
            fontSize={9}
          />
          <RechartTooltip content={renderTooltip} isAnimationActive={false} />
          <Line
            type="linear"
            dataKey="value"
            stroke="#2196F3"
            yAxisId={0}
            strokeWidth={1.5}
            isAnimationActive={false}
            dot={{ r: 5, fill: "#2196F3", stroke: "white", strokeWidth: 1 }}
            activeDot={{
              r: 8,
              onClick: () => {
                map?.zoomTo(value);
              },
            }}
          />
          <Line
            type="linear"
            dataKey="current"
            stroke="#01161F"
            yAxisId={0}
            strokeWidth={1.5}
            isAnimationActive={false}
            dot={{
              r: 3.5,
              fill: "#01161F",
              stroke: "white",
              strokeWidth: 1,
            }}
            activeDot={{
              r: 5,
              onClick: () => {
                onChange(currentZoom);
              },
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ZoomChart;
