import { FullMultiReport, MultiReport, ReportConfiguration as TReportConfiguration } from "fond/types";

import { apiSlice } from "./apiSlice";

export type GetMultiReportResponse = {
  MultiReports: MultiReport[];
};

/**
 * MultiReport API Slice
 */
export const multiReportsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMultiReport: build.query<FullMultiReport, string>({
      query: (multiReportId) => `/v2/multi-reports/${multiReportId}`,
      providesTags: (result) => (result ? [{ type: "MultiReport", id: result.ID }] : []),
    }),
    getMultiReports: build.query({
      query: (multiProjectId?: string) => ({
        url: "/v2/multi-reports",
        params: multiProjectId ? { multi_project_id: multiProjectId } : undefined,
      }),
      transformResponse: (response: GetMultiReportResponse) => response?.MultiReports || [],
      providesTags: (result) =>
        result
          ? [...result.map((multiReport) => ({ type: "MultiReport" as const, id: multiReport.ID })), { type: "MultiReport", id: "LIST" }]
          : [{ type: "MultiReport", id: "LIST" }],
    }),
    createMultiReport: build.mutation<
      MultiReport,
      {
        Name: string;
        Description: string | null;
        MultiProjectID: string;
        ReportConfiguration: Partial<TReportConfiguration>;
      }
    >({
      query: ({ Name, Description, MultiProjectID, ReportConfiguration }) => ({
        url: "/v2/multi-reports",
        method: "POST",
        body: {
          Name,
          Description,
          MultiProjectID,
          ReportConfiguration,
        },
      }),
      invalidatesTags: [
        { type: "MultiReport", id: "LIST" },
        { type: "Folder", id: "LIST" },
      ],
    }),
    generateMultiReport: build.mutation<FullMultiReport, string>({
      query: (multiReportId: string) => ({
        url: `/v2/multi-reports/${multiReportId}/generate`,
        method: "POST",
      }),
      onQueryStarted: async (multiReportId, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(multiReportsSlice.util.upsertQueryData("getMultiReport", multiReportId, data));
        } catch {
          // Do nothing
        }
      },
      invalidatesTags: [
        { type: "MultiReport", id: "LIST" },
        { type: "Report", id: "LIST" },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetMultiReportQuery, useGetMultiReportsQuery, useCreateMultiReportMutation, useGenerateMultiReportMutation } = multiReportsSlice;
