import update from "immutability-helper";
import _ from "lodash";

import {
  AttachmentActionTypes,
  DELETE_ATTACHMENT_SUCCESS,
  GET_ATTACHMENTS_SUCCESS,
  UPDATE_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_ABORT,
  UPLOAD_ATTACHMENT_FAILURE,
  UPLOAD_ATTACHMENT_PROGRESS_UPDATE,
  UPLOAD_ATTACHMENT_REQUEST,
  UPLOAD_ATTACHMENTS_REQUEST,
} from "fond/redux/attachments";
import { AttachmentEntityType, AttachmentsState } from "fond/types";
import { reduceReducers } from "fond/utils";

const initialState: AttachmentsState = {
  isOpen: false,
  items: {
    Feature: null,
    Project: null,
  },
  uploadStatus: {},
};

const findAttachmentEntitytype = (state: AttachmentsState, attachmentId: string): AttachmentEntityType => {
  if (!state.items.Feature && !state.items.Project) {
    return "Project";
  }
  if (state.items.Feature?.find((item) => item.ID === attachmentId)) {
    return "Feature";
  }
  return "Project";
};

export const attachmentsReducer = reduceReducers((state = initialState, action: AttachmentActionTypes.ReducerActions): AttachmentsState => {
  switch (action.type) {
    case GET_ATTACHMENTS_SUCCESS: {
      return update(state, {
        items: {
          $set: {
            ...state.items,
            [action.entityType ?? "Project"]: action.payload.Items,
          },
        },
        uploadStatus: { $set: {} },
      });
    }
    case UPDATE_ATTACHMENT_SUCCESS: {
      const entityKey = findAttachmentEntitytype(state, action.attachment.ID);
      return update(state, {
        items: {
          $set: {
            ...state.items,
            [entityKey]: [...(state.items[entityKey]?.filter((item) => item.ID !== action.attachment.ID) || []), action.attachment],
          },
        },
      });
    }
    case DELETE_ATTACHMENT_SUCCESS: {
      const entityKey = findAttachmentEntitytype(state, action.attachment.ID);
      return update(state, {
        items: {
          $set: {
            ...state.items,
            [entityKey]: [...(state.items[entityKey]?.filter((item) => item.ID !== action.attachment.ID) || [])],
          },
        },
      });
    }
    case UPLOAD_ATTACHMENTS_REQUEST: {
      return update(state, {
        uploadStatus: {
          $merge: _.keyBy(
            action.files.map((file) => ({
              name: file.name,
              progress: 0,
              isError: false,
              isAborted: false,
              request: null,
            })),
            "name"
          ),
        },
      });
    }
    case UPLOAD_ATTACHMENT_REQUEST:
      return update(state, {
        uploadStatus: {
          [action.file.name]: {
            request: { $set: action.XHRRequest },
          },
        },
      });
    case UPLOAD_ATTACHMENT_FAILURE:
      return update(state, {
        uploadStatus: {
          [action.file.name]: {
            isError: { $set: true },
          },
        },
      });
    case UPLOAD_ATTACHMENT_PROGRESS_UPDATE:
      return update(state, {
        uploadStatus: {
          [action.file.name]: {
            progress: { $set: action.percentComplete },
          },
        },
      });
    case UPLOAD_ATTACHMENT_ABORT:
      return update(state, {
        uploadStatus: {
          [action.file.name]: {
            isAborted: { $set: true },
          },
        },
      });
    default:
      return state;
  }
});
