import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { ValidationMessage } from "fond/form";
import { FieldState } from "fond/types";

enum ValueType {
  ABSOLUTE = "absolute",
  PERCENTAGE = "percent",
}

const customStyles = (theme: Theme) => {
  return createStyles({
    textField: {
      width: "6em",
    },
    rightSelect: {
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.75),
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The helper text content.
   */
  helpText?: string;
  /**
   * The label content
   */
  label: string;
  /**
   * A text description of what type of value is being enetered (i.e. Absolute / Percentage value)
   */
  quantity: string;
  /**
   * The current value
   */
  value: { AbsoluteValue?: string; PercentValue?: string };
  /**
   * Indicates the current widget state and is used to applied
   * appropriate visual styles to indicate that state (e.g. error / warning)
   */
  widgetState: FieldState;
  /**
   * Callback function for when the value or type of value changes
   */
  onChange(args: { value?: number; AbsoluteValue?: string; PercentValue?: string }): void;
}

/**
 * This component is used for the tier 1 and tier 2 spare ports.
 *
 * It shows a text field followed by a dropdown with options for selecting
 * between percent spare or absolute spare.
 *
 * The values it works with are objects of the form `{AbsoluteValue: number}`
 * or `{PercentValue: number}`.
 */

const SpareWidget: React.FC<IProps> = ({ classes, helpText, label, value, quantity, onChange, widgetState }: IProps) => {
  const handleOnNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (value.AbsoluteValue != null) {
      onChange({ ...value, AbsoluteValue: event.currentTarget.value });
    } else {
      onChange({ ...value, PercentValue: event.currentTarget.value });
    }
  };

  const handleOnUnitChange = (event: SelectChangeEvent) => {
    if (event.target.value === ValueType.PERCENTAGE) {
      onChange({ PercentValue: value.AbsoluteValue });
    } else {
      onChange({ AbsoluteValue: value.PercentValue });
    }
  };

  const error = widgetState === "error";

  return (
    <FormGroup row>
      <Grid container>
        <Grid item>
          <FormControl margin="dense">
            <FormLabel required error={error} sx={{ mb: 1 }}>
              {label}
            </FormLabel>
            <OutlinedInput
              data-testid="number"
              error={error}
              margin="dense"
              size="small"
              value={value.AbsoluteValue != null ? value.AbsoluteValue : value.PercentValue}
              onChange={handleOnNumberChange}
              inputProps={{
                "aria-label": label,
              }}
              className={classes.textField}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl margin="dense">
            <FormLabel sx={{ mb: 1 }}> </FormLabel>
            <Select
              data-testid="select"
              label={label}
              value={value.AbsoluteValue != null ? ValueType.ABSOLUTE : ValueType.PERCENTAGE}
              onChange={handleOnUnitChange}
              className={classes.rightSelect}
              // variant='outlined' on a Select does not work:
              // https://github.com/mui-org/material-ui/issues/14203
              // But this does what variant='outlined' should do.
              input={<OutlinedInput size="small" />}
            >
              <MenuItem value={ValueType.PERCENTAGE}>{`% of ${quantity} remain spare`}</MenuItem>
              <MenuItem value={ValueType.ABSOLUTE}>{`${quantity} remain spare`}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <ValidationMessage state={widgetState} message={helpText} />
    </FormGroup>
  );
};

export default withStyles(customStyles)(SpareWidget);
