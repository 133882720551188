import React, { useState } from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";

import { hydrateLayer, selectDraftConfigEntities, selectLayerConfigById, selectVersionMlcId, useUpdateSublayerConfigMutation } from "fond/api";
import { LayerHeader } from "fond/styleEditor";
import { FilterConfiguration, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppSelector } from "fond/utils/hooks";

import { SublayerFilterField } from "../SublayerFilterField";

import { Tab, Tabs } from "../FieldSelection/fieldSelection.styles";
import { Form } from "../StyleEditor/styleEditor.styles";
import { Container, TabPanel } from "./sublayerEditor.styles";

type TabType = "filter";

interface IProps {
  sublayerConfig: SublayerConfig;
}

interface IFormData {
  FilterConfiguration: FilterConfiguration | null;
}

const SublayerEditor: React.FC<IProps> = ({ sublayerConfig }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState<TabType>("filter");
  const parentLayerConfig = useAppSelector((state) => selectLayerConfigById(state, sublayerConfig.ParentID));
  const mapLayerConfigId = useAppSelector(selectVersionMlcId);
  const [updateSublayer] = useUpdateSublayerConfigMutation();
  const draft = useSelector(selectDraftConfigEntities);

  const handleOnSubmit = useDebouncedCallback(async (values: IFormData) => {
    const { FilterConfiguration: filters } = values;
    const sameValue = isEqual(sublayerConfig.FilterConfiguration, values.FilterConfiguration);

    if (
      sameValue ||
      (filters !== null &&
        sublayerConfig.FilterConfiguration?.Type !== "other" &&
        ((filters.Type === "expression" && (!filters.Operator || !filters.Values)) ||
          (filters.Type === "group" && !filters.Expressions?.every((expression) => expression.Operator && expression?.Values))))
    ) {
      return;
    }

    try {
      const sublayer = hydrateLayer(draft, { ...sublayerConfig, FilterConfiguration: values.FilterConfiguration }) as SublayerConfig;
      await updateSublayer({ sublayer, mapLayerConfigId });
    } catch {
      enqueueSnackbar("Failed to save draft changes", { variant: "error" });
    }
  }, 500);

  return (
    <Container>
      <LayerHeader layerConfig={sublayerConfig} />
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        <Tab value="filter" label="Filter" />
      </Tabs>

      <FinalForm<IFormData>
        initialValues={sublayerConfig}
        onSubmit={handleOnSubmit}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} sx={{ overflow: "hidden" }}>
              <TabPanel>
                {tabValue === "filter" && (
                  <SublayerFilterField
                    name="FilterConfiguration"
                    attributes={parentLayerConfig?.Type === "LAYER" ? parentLayerConfig.Attributes : []}
                  />
                )}
              </TabPanel>
              {/* We use FormSpy to submit the form values on every update */}
              <FormSpy
                subscription={{ values: true, valid: true, pristine: true }}
                onChange={({ valid, pristine, values }) => {
                  if (valid && !pristine) handleSubmit();
                }}
              />
            </Form>
          );
        }}
      />
    </Container>
  );
};

export default SublayerEditor;
