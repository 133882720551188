import { Feature } from "geojson";

import { LayerGroup, LayerId } from "fond/layers";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

interface BaseStyle {
  id: string;
  sourceId: string;
  styleIDs: string[];
  filter: unknown[] | undefined;
}

/**
 * Do an elementwise addition of two coordinates represented as 2-element arrays.
 */
export const addCoords = (p1: number[], p2: number[]): number[] => {
  return [p1[0] + p2[0], p1[1] + p2[1]];
};

/**
 * Returns a new array that is the same as `arr` but with the `index`th
 * element replaced with `val`.
 */
export const setIndex = (arr: unknown[], index: number, val: unknown): unknown[] => {
  return arr.map((v, j) => {
    return j === index ? val : v;
  });
};

/**
 * Determines if the hub is allowed to be relocated.
 * Currently the user is not allowed to use this mode to move FEEDER hubs.
 */
export const canMoveFeature = (feature: Feature | MapboxDraw.DrawFeature): boolean => {
  return feature.properties?.Type === "HUB" && (feature.properties?.Tier === "DROP" || feature.properties?.Tier === "DISTRIBUTION");
};

/**
 * Creates an array of base point styles for the given layers.
 */
export const getBasePointStyles = (layerConfigs: Array<LayerConfig | SublayerConfig> | undefined, layers: LayerGroup["layers"]): BaseStyle[] => {
  const basePointStyles: BaseStyle[] = [];

  for (let layer of layers) {
    if (layer.geometryType === "Point") {
      const configLayer = layerConfigs?.find((item) => item?.Type === "LAYER" && item.Key === layer.id);

      const addBaseStyles = (item: LayerConfig | SublayerConfig) => {
        if (item.Styles.length > 0) {
          basePointStyles.push({
            id: item.ID,
            sourceId: layer.id,
            styleIDs: item.Styles,
            filter: item.Type === "SUBLAYER" ? item.FilterConfiguration?.Mapbox : undefined,
          });
        }
      };

      if (configLayer) {
        addBaseStyles(configLayer);
        if (configLayer.Type === "LAYER") {
          configLayer.Children.forEach((sublayerId) => {
            const sublayer = layerConfigs?.find((item) => item?.ID === sublayerId);
            if (sublayer) addBaseStyles(sublayer);
          });
        }
      }
    }
  }

  return basePointStyles;
};

/**
 * Creates an array of base line styles for the given layers.
 */
export const getBaseLineStringStyles = (layerConfigs: Array<LayerConfig | SublayerConfig> | undefined, layers: LayerGroup["layers"]): BaseStyle[] => {
  let baseLineStringStyles: BaseStyle[] = [];

  for (let layer of layers) {
    if (layer.geometryType === "LineString") {
      const configLayer = layerConfigs?.find((item) => item?.Type === "LAYER" && item.Key === layer.id);

      const addBaseStyles = (item: LayerConfig | SublayerConfig) => {
        if (item.Styles.length > 0) {
          baseLineStringStyles.push({
            id: item.ID,
            sourceId: layer.id,
            styleIDs: item.Styles,
            filter: item.Type === "SUBLAYER" ? item.FilterConfiguration?.Mapbox : undefined,
          });
        }
      };

      if (configLayer) {
        addBaseStyles(configLayer);
        if (configLayer.Type === "LAYER") {
          configLayer.Children.forEach((sublayerId) => {
            const sublayer = layerConfigs?.find((child) => child?.ID === sublayerId);
            if (sublayer) addBaseStyles(sublayer);
          });
        }
      }
    }
  }

  return baseLineStringStyles;
};

/**
 * Returns a list of style ids (mapbox layer ids) added to mapbox
 * that can be used to target map layers
 */
export const getSnappableStyleIds = (layerConfigs: Array<LayerConfig | SublayerConfig> | undefined, snappableLayerIds: LayerId[]): string[] => {
  const snappableStyleIds: string[] = [];

  layerConfigs?.forEach((item) => {
    if (item?.Type === "LAYER" && snappableLayerIds.includes(item.Key as LayerId)) {
      snappableStyleIds.push(...item.Styles);
      item.Children.forEach((sublayerId) => {
        const sublayer = layerConfigs.find((child) => child?.ID === sublayerId);
        if (sublayer) snappableStyleIds.push(...sublayer.Styles);
      });
    }
  });

  return snappableStyleIds;
};

/**
 * Creates an array of query layer IDs based on the given styles.
 */
export const getQueryLayerIds = (pointStyles: BaseStyle[], lineStringStyles: BaseStyle[]): string[] => {
  let drawLayerIds: string[] = [];

  pointStyles.forEach((point) => {
    point.styleIDs.forEach((id) => {
      drawLayerIds.push(`gl-draw-point-${id}`);
      drawLayerIds.push(`gl-draw-point-${id}-active`);
    });
  });

  lineStringStyles.forEach((line) => {
    line.styleIDs.forEach((id) => {
      drawLayerIds.push(`gl-draw-line-${id}`);
      drawLayerIds.push(`gl-draw-line-${id}-active`);
    });
  });

  return [...drawLayerIds.map((id) => `${id}.hot`), ...drawLayerIds.map((id) => `${id}.cold`)];
};
