import React from "react";
import { useLocation, useNavigate } from "react-router";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Link, Typography } from "@mui/material";

import { PageCentred } from "fond/widgets/PageCentred";

const PaymentFailure: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    // can only be redirected from CheckoutForm after payment failed
    if (!location.state?.HostedInvoiceUrl || !location.state?.Invoice) {
      navigate("/workspace");
    }
  }, [location.state?.HostedInvoiceUrl, location.state?.Invoice, navigate]);

  return (
    <PageCentred windowTitle="Payment failed">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h3" margin={3} data-testid="payment-failed-header">
          Payment failed
        </Typography>

        <ErrorIcon fontSize="large" color="error" />

        <Typography textAlign="center">Apologies, but it appears that the payment was unsuccessful.</Typography>

        <Typography variant="caption" marginTop={1} marginBottom={3}>
          You can change the payment method by clicking the link below:
        </Typography>

        <Link href={location.state?.HostedInvoiceUrl} target="_blank" rel="noopener noreferrer" fontWeight="bold" data-testid="go-to-change-payment">
          Change payment method
        </Link>
      </Box>
    </PageCentred>
  );
};

export default PaymentFailure;
