import { Box, styled, Typography } from "@mui/material";

export const LinearProgressLabels = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const ProgressContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: 10,
  marginBottom: 10,
  marginRight: theme.spacing(1),
  fontWeight: 500,
}));

export const Label = styled(Typography)({
  fontSize: 12,
  textAlign: "left",
});
