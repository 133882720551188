import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";

import {
  getUserPreferenceValue,
  selectAllFolders,
  selectAllProjects,
  selectCurrentAccount,
  selectFolderById,
  useGetAccountsQuery,
  useGetFoldersQuery,
  useGetProjectsQuery,
  useGetUserInvitationsQuery,
  useGetUserMeAccountsQuery,
  useGetUserMeQuery,
  useUpdateUserPreferencesMutation,
} from "fond/api";
import ProjectList from "fond/projects/ProjectList/ProjectList";
import ProjectListHeader from "fond/projects/ProjectListHeader";
import SearchBar from "fond/projects/SearchBar";
import { FinancialAnalyticsIntroTour } from "fond/tours";
import { Folder, ProjectPreview, Report, Store, UserPreferenceKey, UserPreferenceSubKey } from "fond/types";

import { AcceptInvitationPanel } from "./AcceptInvitationPanel";
import { CreateAccountPanel } from "./CreateAccountPanel";

import { Alert } from "./BaseProjectList.styles";

interface RouteParams {
  folderId: string;
}

interface BaseProjectListProps {
  items: (Folder | (ProjectPreview & { LastViewed?: string }) | Report)[];
}

/**
 * A main page content displaying folders and projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const BaseProjectList: React.FC<BaseProjectListProps> = ({ items }) => {
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const { filters } = useSelector((state: Store) => state.projects);
  const { isFetching: isFetchingFolders, isLoading: isLoadingFolders } = useGetFoldersQuery(undefined);
  const { isFetching: isFetchingProjects, isLoading: isLoadingProjects } = useGetProjectsQuery(undefined);
  const { isFetching: isFetchingAccounts } = useGetAccountsQuery();
  const { isFetching: isFetchingMe } = useGetUserMeQuery();
  const { isFetching: isFetchingMeAccounts } = useGetUserMeAccountsQuery();
  const { data: pendingInvites, isLoading: isLoadingInvites } = useGetUserInvitationsQuery();
  const [updatePreference] = useUpdateUserPreferencesMutation();
  const showFinancialAnalyticsIntoTour = useSelector((state: Store) =>
    getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.FINANCIAL_ANALYTICS_INTRO)
  );
  const folders = useSelector(selectAllFolders);
  const projects = useSelector(selectAllProjects);
  const account = useSelector(selectCurrentAccount);

  const hasNoProjects = !isFetchingFolders && projects.length === 0;
  const hasNoFolders = !isFetchingProjects && folders.length === 0;

  const isFetching = isFetchingMeAccounts || isFetchingAccounts || isFetchingMe || isFetchingFolders || isFetchingProjects;
  const isLoading = isLoadingFolders || isLoadingProjects || isLoadingInvites;
  const hasNoAccountNorContent = hasNoProjects && hasNoFolders && !isFetching && !account;
  const hasPendingInvites = pendingInvites && pendingInvites?.length > 0;
  const [searchText, setSearchText] = useState("");

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (value: string) => {
    setSearchText(value.toUpperCase());
  };

  return (
    <>
      {folder ? <Typography style={{ whiteSpace: "pre-line" }}>{folder.Description}</Typography> : <ProjectListHeader />}
      {hasNoAccountNorContent ? (
        <Box my={3} data-testid="no-account-nor-content-section">
          <Alert severity="info" data-testid="no-content-alert">
            In order to gain access to the main workspace and get started with viewing and creating projects, please get in touch with a team member
            to request access to shared content or become a member of an account.
          </Alert>
          {hasPendingInvites ? <AcceptInvitationPanel invitations={pendingInvites} /> : <CreateAccountPanel />}
        </Box>
      ) : (
        <>
          <Box my={3}>
            <SearchBar onChange={handleOnFilterChange} placeholder={`Filter projects ${filters !== "recent" ? "and folders" : ""}`} />
          </Box>
          <Paper square>
            <ProjectList searchText={searchText} items={isLoading ? null : items} folder={folder} isLoading={isFetching} />
          </Paper>
        </>
      )}

      {showFinancialAnalyticsIntoTour && (
        <FinancialAnalyticsIntroTour
          onComplete={() => {
            updatePreference({ Key: UserPreferenceKey.UI_TOUR, Subkey: UserPreferenceSubKey.FINANCIAL_ANALYTICS_INTRO, Value: false });
          }}
        />
      )}
    </>
  );
};

export default BaseProjectList;
