import React, { useMemo } from "react";
import { PlaylistAdd } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { MultiProject } from "fond/types";
import { Actions } from "fond/utils/permissions";

import { Description } from "./ArchitecturePanel.styles";

interface IProps {
  /**
   * The current architecture applied to the multiproject
   */
  multiProject: MultiProject;
  /**
   * Flag indicating that the user is presented with the ability to select / change architectures
   */
  readOnly: boolean;
  /**
   * Callback function for the Change Architecture button
   */
  onChangeClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ArchitecturePanel: React.FC<IProps> = ({ multiProject, readOnly = false, onChangeClick }: IProps) => {
  const canEditArchitecture = usePermissionCheck(Actions.ARCHITECTURE_EDIT, multiProject.Permission?.Level);
  const hasProjects = useMemo(() => multiProject?.Areas.some((area) => area.Project?.ID), [multiProject]);
  const { Architecture } = multiProject;

  return (
    <Box>
      {Architecture && (
        <Box>
          <Typography variant="h6" className="architecture-name">
            {Architecture.Name}
          </Typography>
          <Description className="customScrollbars">
            <Typography variant="caption">{Architecture.Description}</Typography>
          </Description>
        </Box>
      )}
      {!readOnly && !hasProjects && (
        <Box sx={{ textAlign: Architecture == null ? "center" : "left" }}>
          {!Architecture && (
            <Grid container justifyContent="center">
              <Grid item>
                <PlaylistAdd color="action" fontSize="large" />
              </Grid>
              <Grid item>
                <Typography variant="h6">No architecture has been selected.</Typography>
                <Typography variant="body2">
                  {canEditArchitecture
                    ? "Please select an architecture from the available architectures list or create a new architecture."
                    : "You require a license and subscription to create or select an architecture"}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            data-testid="select-architecture-button"
            disabled={!canEditArchitecture}
            onClick={onChangeClick}
            style={{ marginTop: "0.5em" }}
          >
            {Architecture != null ? "Change architecture" : "Select architecture"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ArchitecturePanel;
