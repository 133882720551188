import { Paper, styled, Table as MuiTable, TableContainer as MuiTableContainer } from "@mui/material";

import { fixSsrWarning } from "fond/utils/css";

export const Table = styled(MuiTable)`
  tr:nth-child(even) td ${fixSsrWarning} {
      background-color: #FCFCFC;
    }
  }

  tr:last-child td {
    border: 0;
  }
`;

export const TableContainer = styled(MuiTableContainer)`
  border: 1px solid ${(props) => props.theme.palette.divider};
` as typeof Paper;
