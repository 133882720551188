import { useEffect } from "react";
import { useNavigate } from "react-router";
import { SerializedError } from "@reduxjs/toolkit";

import { useGetReportQuery } from "fond/api";
import { ErrorNavigationState } from "fond/navigation/types";
import { useNavigateError } from "fond/navigation/useNavigateError";
import { FullReport } from "fond/types";

const isSerializedError = (error: any): error is SerializedError => {
  return error.code !== undefined && error.message !== undefined;
};

export const useReportData = (reportId?: string): { report?: FullReport; isLoading: boolean } => {
  const navigateError = useNavigateError();
  const { data: report, isError, isLoading, error } = useGetReportQuery(reportId ?? "", { skip: !reportId });

  useEffect(() => {
    if (reportId && isError) {
      const navState: ErrorNavigationState = { resourceType: "REPORT" };
      if (!isSerializedError(error) && error.status === 404) {
        navigateError("/404", navState);
        return;
      }
      navigateError("/error", navState);
    }
  });

  return { report, isLoading };
};

export const usePollReportStatusHandle = (report?: FullReport): void => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!report) return;
    if (report.Status === null || report.Status?.State !== "COMPLETE") {
      navigate(`/reports/${report.ID}/poll`);
    }
  });
};
