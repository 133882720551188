import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import BulkAttachmentMapping from "fond/bulkAttachments/BulkAttachmentMapping/BulkAttachmentMapping";
import BulkAttachmentUpload from "fond/bulkAttachments/BulkAttachmentUpload/BulkAttachmentUpload";
import { uploadAttachmentAbort } from "fond/redux/attachments";
import { AttachmentEntityType, BulkFileProps, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Modal } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    modal: {
      minHeight: "80vh",
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    modalDataGrid: {
      overflow: "hidden",
      flex: "1",
      display: "flex",
      width: "100%",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  onClose: () => void;
  isOpen: boolean;
  attachmentEntityType?: AttachmentEntityType;
}

/**
 * An Enumeration of views rendered in the modal. Only one view is active at any one time.
 */
enum ModalViews {
  BULK_ATTACHMENTS_MAPPING,
  ATTACHMENTS_UPLOAD,
}

const BulkAttachmentModal: React.FC<IProps> = ({ classes, onClose, isOpen, attachmentEntityType = "Project" }: IProps) => {
  const dispatch = useAppDispatch();
  // const canUpload = usePermissionCheck(uploadAttachmentAction, project.Permission.Level);
  const { selectedFeature } = useSelector((state: Store) => state.project);

  /*
   * Modal view control.
   */
  const [modalView, setModalView] = useState<ModalViews>(ModalViews.BULK_ATTACHMENTS_MAPPING);

  const openUploads = () => setModalView(ModalViews.ATTACHMENTS_UPLOAD);

  const mappingView = modalView === ModalViews.BULK_ATTACHMENTS_MAPPING;
  const uploadsView = modalView === ModalViews.ATTACHMENTS_UPLOAD;

  /*
   * File upload control.
   */
  const [stagedFiles, setStagedFiles] = useState<BulkFileProps[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isReadyToUpload, setIsReadyToUpload] = useState<boolean>(false);

  const abortAllUploads = () => {
    for (const stagedFile of stagedFiles) {
      dispatch(uploadAttachmentAbort(stagedFile.file));
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setModalView(ModalViews.BULK_ATTACHMENTS_MAPPING);
      setStagedFiles([]);
      setIsUploading(false);
      setIsReadyToUpload(false);
    }
  }, [isOpen]);

  const featureId = attachmentEntityType === "Feature" ? (selectedFeature?.featureId as unknown as string) : null;
  return (
    <Modal
      data-testid="bulk-attachment-modal"
      variant="primary"
      open={isOpen}
      onClose={() => onClose()}
      header="Bulk Upload"
      headerIcon={<BurstModeIcon />}
      disableBackdropClick={uploadsView}
      DialogContentProps={{ className: classes.modalContent }}
      PaperProps={{ style: { minHeight: "80vh" } }}
      className={classes.modal}
      content={
        <Box className={classes.modalDataGrid}>
          <Box flex="1" width="100%">
            {mappingView && (
              <BulkAttachmentMapping onAcceptedStage={setStagedFiles} stagedFiles={stagedFiles} setIsReadyToUpload={setIsReadyToUpload} />
            )}
            {uploadsView && <BulkAttachmentUpload files={stagedFiles} onIsUploading={setIsUploading} featureId={featureId} />}
          </Box>
        </Box>
      }
      actions={
        <>
          {mappingView && (
            <>
              <Button color="inherit" data-testid="attachment-modal-staging-cancel-btn" onClick={onClose}>
                Cancel
              </Button>
              <Button
                data-testid="attachment-modal-staging-upload-btn"
                variant="contained"
                color="primary"
                disabled={!isReadyToUpload}
                onClick={openUploads}
              >
                Upload
              </Button>
            </>
          )}
          {uploadsView && (
            <>
              <Button color="inherit" data-testid="attachment-modal-upload-cancel-all-btn" onClick={abortAllUploads} disabled={!isUploading}>
                Cancel All
              </Button>
              <Button data-testid="attachment-modal-upload-close-btn" onClick={onClose} disabled={isUploading}>
                Close
              </Button>
            </>
          )}
        </>
      }
    />
  );
};

BulkAttachmentModal.displayName = "BulkAttachmentModal";
export default withStyles(customStyles)(BulkAttachmentModal);
