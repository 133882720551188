import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { Modal } from "fond/widgets";

interface IProps {
  /**
   * The URL that is being copied
   */
  url: string;
  /**
   * The confirmation modal title
   */
  title: string;
  /**
   * The confirmation modal message
   */
  message: string;
  /**
   * Callback function for when the modal is closing
   */
  onClose(event: React.MouseEvent<EventTarget>): void;
}

const CopyUrl: React.FC<IProps> = ({ url, title, message, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal
      open
      header={title}
      data-testid="share-link-modal"
      content={
        <Box>
          <Typography>{message}</Typography>
          <TextField fullWidth variant="outlined" margin="dense" value={url} inputProps={{ "data-testid": "copy-input" }} />
        </Box>
      }
      actions={
        <>
          <Button data-testid="share-confirmation-cancel-button" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <CopyToClipboard text={url} onCopy={() => enqueueSnackbar("Copied to clipboard.")}>
            <Button data-testid="copy-content-button" variant="contained" color="primary">
              Copy link
            </Button>
          </CopyToClipboard>
        </>
      }
    />
  );
};

export default CopyUrl;
