import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GridApi } from "@ag-grid-community/core";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

import { User } from "fond/types";
import { AgGrid } from "fond/widgets";

import { useUserList } from "./useUserList";

import { Container, UserSearchField } from "./UserList.styles";

const UserList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [userSearch, setUserSearch] = useState("");
  const [gridApi, setGridApi] = useState<GridApi>();
  const [selected, setSelected] = useState<User[]>();
  const { userAccountAllocations, columns, gridOptions } = useUserList();

  const onSelectionChanged = () => {
    const selectedUsers = gridApi?.getSelectedRows();
    setSelected(selectedUsers);
  };

  /**
   * Set search field value and AgGrid filter if admin is redirected to /settings/users from request license notification
   */
  const shouldAllocateLicense = searchParams.get("allocateLicense") === "true";
  const licenseAllocationUserId = searchParams.get("userEmail");
  useEffect(() => {
    if (shouldAllocateLicense && licenseAllocationUserId) {
      setUserSearch(licenseAllocationUserId);
      gridApi?.setGridOption("quickFilterText", licenseAllocationUserId);
    }
  }, [shouldAllocateLicense, licenseAllocationUserId, gridApi]);

  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserSearch(event.target.value);
    gridApi!.setGridOption("quickFilterText", event.target.value ?? "");
  };

  return (
    <Container>
      <UserSearchField
        id="user-search-field"
        data-testid="user-search-field"
        name="search"
        size="small"
        placeholder="Search by keywords"
        value={userSearch}
        onChange={handleOnFilterChange}
        InputProps={{ startAdornment: <SearchIcon /> }}
      />

      {/* TODO(FND-251) add back when actions are supported <UserGridActions selectedUsers={selected ?? []} /> */}
      <Box flexGrow={1} data-testid="user-list">
        <AgGrid
          columnDefs={columns}
          rowData={userAccountAllocations ?? null}
          gridOptions={gridOptions}
          onGridReady={(params) => setGridApi(params.api)}
          onSelectionChanged={onSelectionChanged}
          autoSizeColumns={false}
          variant="outlined"
        />
      </Box>
    </Container>
  );
};

export default UserList;
