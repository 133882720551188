import React, { createContext, createRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { Layout, Model } from "flexlayout-react";

import { useDeleteLayoutMutation, useGetLayoutQuery } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Store } from "fond/types";
import { makeUuid } from "fond/utils";

import { defaultLayout, defaultMapSubLayout, defaultMultiProjectLayout, multiProjectDefaultMapSubLayout } from "./defaultLayout";
import { convertToModel } from "./widgets";

export const LayoutContext = createContext<{
  layoutRef?: any;
  model?: Model;
  mapModel?: Model;
  windowIds: string[];
  setWindowIds: React.Dispatch<React.SetStateAction<string[]>>;
  reset(): void;
}>(undefined!);

interface IProps {
  type: "project" | "multiProject";
  showDesignPanel?: boolean;
  children: React.ReactNode;
}

const LayoutProvider: React.FC<IProps> = ({ type, showDesignPanel, children }: IProps) => {
  // The resourceId can be either a project ID or a multiProject ID depending on what is currently viewed.
  const { projectId: resourceId } = useSelector((state: Store) => state.project);
  const projectId = type === "project" ? resourceId : undefined;
  const multiProjectId = type === "multiProject" ? resourceId : undefined;

  const { data: layout, isLoading, isFetching } = useGetLayoutQuery({ projectId, multiProjectId }, { skip: !resourceId });

  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const userLayout = useRef<Model | undefined>(undefined);
  const mapSubLayout = useRef<Model | undefined>(undefined);

  const layoutRef = createRef<Layout>();
  const [windowIds, setWindowIds] = useState<string[]>([]);
  const [key, setKey] = useState<string>(makeUuid());
  const [deleteLayout] = useDeleteLayoutMutation();

  const defaultLayoutValue = type === "project" ? defaultLayout : defaultMultiProjectLayout;
  const defaultMapSubLayoutValue = type === "project" ? defaultMapSubLayout : multiProjectDefaultMapSubLayout;

  /**
   * Callback function that triggers a reset of the layout to the default
   */
  const reset = useCallback(() => {
    if (resourceId) {
      deleteLayout({ projectId, multiProjectId });
    }
    userLayout.current = convertToModel(defaultLayoutValue, [], showDesignPanel || false);
    mapSubLayout.current = convertToModel(defaultMapSubLayoutValue, []);
    setKey(makeUuid());
    mixpanel.track("Layout: Reverted layout back to default");
  }, [defaultLayoutValue, defaultMapSubLayoutValue, deleteLayout, multiProjectId, projectId, resourceId, showDesignPanel]);

  useEffect(() => {
    if ((projectId || multiProjectId) && !isLoading && !isFetching && isFirstTimeLoading) {
      try {
        userLayout.current = convertToModel(defaultLayoutValue, layout?.layouts, !!showDesignPanel);
        mapSubLayout.current = convertToModel(defaultMapSubLayoutValue, layout?.maps);
      } catch (error) {
        // Reset layout to default if any issue loading it from db.
        mixpanel.track("Layout: there is any issue loading the layout", { layout });
        Sentry.captureException(error);
        reset();
      } finally {
        setIsFirstTimeLoading(false);
      }
    }
  }, [
    defaultLayoutValue,
    projectId,
    multiProjectId,
    isFirstTimeLoading,
    isLoading,
    isFetching,
    layout,
    showDesignPanel,
    defaultMapSubLayoutValue,
    reset,
  ]);

  const contextValue = React.useMemo(
    () => ({
      layoutRef,
      windowIds,
      setWindowIds,
      reset,
      model: userLayout.current,
      mapModel: mapSubLayout.current,
    }),
    [layoutRef, reset, windowIds]
  );

  return (
    <LayoutContext.Provider key={key} value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
