import { Alert as BaseAlert, AlertTitle as BaseAlertTitle, Box, Button, styled } from "@mui/material";

export const UncheckedIndicator = styled(Box)(({ theme }) => ({
  height: 16,
  width: 16,
  borderRadius: "50%",
  background: "#f5f5f5",
}));

export const SelectedAreaDetails = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: flex-start;
    margin: ${theme.spacing(1)} 0 ${theme.spacing(1.5)};

    & > svg {
      height: 24px;
      margin-right: ${theme.spacing(1.5)};
      color: ${theme.palette.secondary.main};
    }
  `
);

export const Alert = styled(BaseAlert)`
  font-size: 12px;

  .muialert-message p: {
    margin-bottom: 1em;
    color: inherit;
  }
`;

export const AlertTitle = styled(BaseAlertTitle)`
  font-size: 14px;
`;

export const DefaultAlert = styled(Alert)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f5f5f5;
`;

export const ConvertToDualContainer = styled(DefaultAlert)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "space-around",
  marginTop: theme.spacing(1.75),
  marginBottom: 0,
}));

export const LinkButton = styled(Button)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  fontSize: 12,
  justifyContent: "left",

  "&&&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
}));
