import { HelpOutline } from "@mui/icons-material";
import { Alert, Box, Button, FormLabel as MuiFormLabel, styled, TableCell as BaseTableCell } from "@mui/material";

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 12,
  lineHeight: "12px",
  "& svg": {
    color: theme.palette.primary.main,
  },
  justifyContent: "flex-start",
  alignItems: "center",
}));

AddButton.defaultProps = {
  variant: "text",
  size: "small",
};

export const SmallButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.grey[400],
  color: theme.palette.text.secondary,
  minWidth: 24,
  width: 24,
  height: 24,
  padding: 0,
  "& svg": {
    width: 16,
    height: 16,
  },
}));

export const TableHeaderCell = styled(BaseTableCell)({
  fontSize: 12,
  fontWeight: 500,
  width: 150,
  minWidth: 100,
  verticalAlign: "top",
  "&:hover svg": {
    display: "inline-flex",
  },
  paddingLeft: 0,
});

export const TableCell = styled(BaseTableCell)({
  fontSize: 12,
  verticalAlign: "top",
});

export const GroupContainer = styled(Box)(({ theme }) => ({
  "& > table:last-child": {
    marginBottom: theme.spacing(3),
  },
}));

export const FieldsHeader = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "0.85rem",
  fontWeight: 500,
}));

export const UnorderedList = styled("ul")({
  paddingLeft: 0,
  listStyle: "none",
});

export const HelpIcon = styled(HelpOutline)(({ theme }) => ({
  display: "none",
  marginLeft: theme.spacing(0.5),
  width: 16,
  height: 16,
}));

export const FieldTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "0.85em",
  color: theme.palette.biarri.primary.red,
  fontWeight: 500,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} 0`,
  "& svg": {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
}));

export const FieldValueWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDefault",
})<{ isDefault: boolean }>(({ theme, isDefault }) => ({
  color: isDefault ? theme.palette.text.disabled : undefined,
  fontStyle: isDefault ? "italic" : undefined,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

export const TextButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "inline-block",
  color: theme.palette.primary.main,
}));

export const FormLabel = styled(MuiFormLabel)(({ theme }) => ({
  display: "block",
  fontSize: 12,
  fontWeight: 700,
  color: theme.palette.biarri.primary.darkGrey,
}));

export const ErrorAlert = styled(Alert)(({ theme }) => ({
  m: 1,
}));

ErrorAlert.defaultProps = {
  severity: "error",
};
