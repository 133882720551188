import React from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Alert, Box, Typography } from "@mui/material";
import { PaymentElement } from "@stripe/react-stripe-js";

import { PaymentMethod } from "fond/types/subscriptions";
import { formatMonth } from "fond/utils/dates";
import { SupportLink } from "fond/widgets";

import PaymentMethodCard from "./PaymentMethodCard";

import { InfoText } from "../Checkout.styles";
import { StyledButton } from "./PaymentMethod.styles";

interface IProps {
  paymentMethod?: PaymentMethod;
  expanded: boolean;
  onChange: () => void;
}

const PaymentMethodDetails: React.FC<IProps> = ({ expanded, paymentMethod, onChange }: IProps) => {
  const type = paymentMethod?.Type;
  const usBankAccount = type === "us_bank_account" && paymentMethod?.UsBankAccount;
  const card = type === "card" && paymentMethod?.Card;
  const hasSavedPayment = usBankAccount || card;

  // Display the payment forms when:
  // 1. There is no existing payment method saved in previous subscriptions.
  // 2. There is an existing payment method but users want to use a different one.
  const showPaymentElement = !hasSavedPayment || expanded;

  // The payment method can only be either a card or an us bank account
  const showUsBankAccount = !showPaymentElement && usBankAccount;
  const showCardPayment = !showPaymentElement && card;

  return (
    <Box marginLeft={2} width="50%" height="100%">
      <Typography fontWeight="bold">Payment method</Typography>
      {showPaymentElement && (
        <>
          <Alert sx={{ marginBottom: 2 }} severity="info">
            If you prefer to use US bank accounts for payments, feel free to reach out to <SupportLink text="FOND support" /> for assistance.
          </Alert>
          <PaymentElement />
        </>
      )}

      {showUsBankAccount && (
        <PaymentMethodCard title={usBankAccount.BankName} onChange={onChange} icon={<AccountBalanceIcon />}>
          <Typography marginTop={1}>**** {usBankAccount.Last4}</Typography>
        </PaymentMethodCard>
      )}

      {showCardPayment && (
        <PaymentMethodCard title={card.Brand} onChange={onChange} icon={<CreditCardIcon />}>
          <Typography marginTop={1}>**** {card.Last4}</Typography>
          <InfoText>
            Expires {formatMonth(card.ExpMonth)}/{card.ExpYear}
          </InfoText>
        </PaymentMethodCard>
      )}

      {hasSavedPayment && showPaymentElement && <StyledButton onClick={onChange}>Cancel</StyledButton>}
    </Box>
  );
};

export default PaymentMethodDetails;
