import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl } from "@mui/material";
import { uniqBy } from "lodash";

import { Dropzone } from "fond/widgets";
import { DropzoneProps } from "fond/widgets/Dropzone/Dropzone";

export type DropzoneFieldProps = Partial<Omit<DropzoneProps, "onDropAccepted" | "validator">> & {
  name: string;
  dropzoneValidator?: DropzoneProps["validator"];
  fieldProps?: Partial<FieldProps<File[], any>>;
  fullWidth?: boolean;
  validate?: any;
};

const DropzoneField: React.FC<DropzoneFieldProps> = (props: DropzoneFieldProps) => {
  const { fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <DropzoneWrapper input={input} meta={meta} name={name} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type DropzoneWrapperProps = Partial<Omit<DropzoneFieldProps, "onChange">> & FieldRenderProps<File[], HTMLElement>;

const DropzoneWrapper: React.FC<DropzoneWrapperProps> = ({
  input: { name, onChange, onBlur, value, ...restInput },
  meta,
  disabled,
  helperText,
  fullWidth,
  dropzoneValidator,
  ...dropzoneProps
}: DropzoneWrapperProps) => {
  /**
   * Appends any new files to the existing file list & performs a unique
   * check based on file name.
   *
   * Note: Newly added duplicate files will override existing ones as default behaviour.
   */
  const handleOnDropAccepted = (files: File[]) => {
    const newValue = uniqBy([...(value || []), ...files].reverse(), "name");
    onChange(newValue);
    onBlur();
  };

  return (
    <FormControl data-testid={`${name}-file-field`} fullWidth={fullWidth}>
      <Dropzone onDropAccepted={handleOnDropAccepted} {...dropzoneProps} validator={dropzoneValidator} />
    </FormControl>
  );
};

export default DropzoneField;
