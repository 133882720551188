import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * A flex box that can be compacted by shifting the flex direction.
 * The CompactableFlexBox takes two parameters - compact and container.
 * The compact parameter will shift the flex direction to take minimise vertical space.
 * The container parameter flexes in the opposite direction to the container children.
 * The following effect is achieved for a container with three child boxes.
 *
 *                      !compact                     compact
 *               +---------------------+     +---------------------+
 *               |+-------------------+|     |+-----++-----++-----+|
 *               ||        </>        ||     || </> || </> || </> ||
 *               |+-------------------+|     |+-----++-----++-----+|
 *               |+-------------------+|     +---------------------+
 *               ||        </>        ||
 *               |+-------------------+|
 *               |+-------------------+|
 *               ||        </>        ||
 *               |+-------------------+|
 *               +---------------------+
 */
export interface CompactableFlexBoxProps {
  container?: boolean | undefined;
  compact: boolean | undefined;
}

export const CompactableFlexBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "compact" && prop !== "container",
})<CompactableFlexBoxProps>(({ theme, container, compact }) => {
  // Styles common to compact and non-compact modes.
  let styles = {
    display: "flex",
    height: container ? "100%" : "unset",
  };

  if (compact) {
    // Containers are row directed with equally spaced children.
    // Children are column directed with center justification for vertical contents alignment.
    return {
      ...styles,
      margin: "auto unset",
      textAlign: "left",
      flexDirection: container ? "row" : "column",
      justifyContent: container ? "space-around" : "center",
    };
  } else {
    // Containers are column directed with center spaced children.
    // Children are row directed with center justification for horizontal contents alignment.
    return {
      ...styles,
      margin: theme.spacing(0.5),
      textAlign: "center",
      flexDirection: container ? "column" : "row",
      justifyContent: "center",
    };
  }
});
