import React from "react";
import { Form } from "react-final-form";
import { Box, Button, FormControl, FormLabel, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { CheckboxField } from "fond/form/fields";
import { NumericField } from "fond/form/fields/NumericField";
import { FieldValue } from "fond/form/fields/TextField/TextField";
import { parseFieldAsNumber } from "fond/form/util";
import { sigmoid } from "fond/utils/number";
import { Modal } from "fond/widgets";

/**
 * Generates an array of numbers based on a sigmoid curve determined by the
 * min, max & number of steps selected by the user.
 */
export const generateTakeRateValue = (min: number, max: number, steps: number, totalSteps: number): number[] => {
  const sigmoidSteps = Math.min(steps, totalSteps);

  // If a steps of 1 is entered we set all values to max.
  if (steps === 1) return new Array(totalSteps).fill(max);

  const newTakeRate = sigmoid(min, max, sigmoidSteps);

  // If the maturity timestep is less than planning horizon fill out the newTakeRate with the max value
  if (totalSteps > steps) {
    newTakeRate.push(...new Array(totalSteps - steps).fill(max));
  }

  return newTakeRate;
};

export interface AdvancedControlForm {
  TakeRateMin: number;
  TakeRateMax: number;
  TakeRate?: number[];
  Timesteps?: number | null;
  ApplyToAll: boolean;
}

interface IProps {
  open: boolean;
  onClose(): void;
  TakeRate: number[];
  TakeRateMin: number;
  TakeRateMax: number;
  PlanningHorizon: number;
  onAdvancedControlSubmit(values: AdvancedControlForm): void;
}

const RevenueAdvancedControlModal: React.FC<IProps> = ({
  open,
  onClose,
  TakeRate,
  TakeRateMin,
  TakeRateMax,
  PlanningHorizon,
  onAdvancedControlSubmit,
}) => {
  const onSubmit = (values: AdvancedControlForm) => {
    onAdvancedControlSubmit({
      ...values,
      TakeRate: generateTakeRateValue(values.TakeRateMin, values.TakeRateMax, values.Timesteps || PlanningHorizon, PlanningHorizon).map(
        (value) => value / 100
      ),
    });
    onClose();
  };

  /**
   * This handles invalid user inputs in min and max fields
   * to prevent chart from rendering values below 0 or above 100
   */
  const normalizeTakeRateValue = (value: FieldValue) => {
    if (Number(value) > 100) return 100;
    if (Number(value) < 0) return 0;
    return Number(value);
  };

  const generateChartData = (values: AdvancedControlForm) => {
    const normalizedValues = {
      ...values,
      TakeRateMin: normalizeTakeRateValue(values.TakeRateMin),
      TakeRateMax: normalizeTakeRateValue(values.TakeRateMax),
    };

    const { TakeRateMin: min, TakeRateMax: max, Timesteps } = normalizedValues;
    return generateTakeRateValue(min, max, Number(Timesteps) || PlanningHorizon, PlanningHorizon).map((rate, index) => ({
      Timestep: `Timestep ${index + 1}`,
      Rate: rate,
    }));
  };

  const defaultTimesteps = PlanningHorizon === TakeRate.length ? TakeRate.findIndex((rate) => rate === TakeRateMax / 100) + 1 : PlanningHorizon;

  return (
    <Modal
      open={open}
      data-testid="revenue-advanced-control-modal"
      variant="primary"
      header="Revenue advanced control"
      onClose={onClose}
      content={
        <Form<AdvancedControlForm>
          initialValues={{ TakeRateMin, TakeRateMax, ApplyToAll: false, Timesteps: defaultTimesteps }}
          onSubmit={onSubmit}
          validateOnBlur
          render={({ handleSubmit, values }) => {
            return (
              <Box>
                <Grid container spacing={2} padding={1.5}>
                  <Grid item xs={8}>
                    <Paper sx={{ overflow: "hidden", height: "100%" }}>
                      <Box py={1} px={2} sx={{ backgroundColor: "common.white" }}>
                        <Typography fontWeight={500}>Take rate</Typography>
                      </Box>
                      <Box p={2}>
                        <Typography variant="caption" fontSize={11} color="biarri.secondary.grey">
                          The take rate to be applied to an address’ revenue at x timestep after passing
                        </Typography>
                        <Box data-testid="take-rate-chart" py={2} px={1}>
                          <ResponsiveContainer minHeight={200} width="100%">
                            <AreaChart data={generateChartData(values)} margin={{ left: 0 }}>
                              <defs>
                                <linearGradient id="linearGradient" x1="0" y1="0" x2="0" y2="1">
                                  <stop stopColor="#2196F3" stopOpacity={0.37} />
                                  <stop offset="88%" stopColor="#2196F3" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <XAxis dataKey="Timestep" tickLine={false} fontSize={10} />
                              <YAxis ticks={[0, 25, 50, 75, 100]} tickLine={false} fontSize={10} />
                              <CartesianGrid strokeDasharray="4 2" />
                              <Tooltip />
                              <Area type="monotone" dataKey="Rate" unit="%" stroke="#2196F3" fillOpacity={1} fill="url(#linearGradient)" />
                            </AreaChart>
                          </ResponsiveContainer>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper sx={{ overflow: "hidden", height: "100%", backgroundColor: "#F8F9FA" }}>
                      <Box py={1} px={2} sx={{ backgroundColor: "biarri.secondary.whiteSmoke" }}>
                        <Typography fontWeight={500}>Range</Typography>
                      </Box>
                      <Box>
                        <form id="advanced-control-range-form" onSubmit={handleSubmit}>
                          <Box data-testid="range-form" display="flex" flexDirection="column" px={1.5} py={1}>
                            <FormControl sx={{ py: 1 }}>
                              <FormLabel sx={{ fontWeight: 400, mb: 1 }}>Min</FormLabel>
                              <NumericField
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                margin="none"
                                max={values.TakeRateMax}
                                min={0}
                                name="TakeRateMin"
                                required
                                parse={(value) => Math.floor(parseFieldAsNumber(value) || 0)}
                                sx={{ width: 105, backgroundColor: "common.white" }}
                              />
                            </FormControl>
                            <FormControl sx={{ py: 1 }}>
                              <FormLabel sx={{ fontWeight: 400, mb: 1 }}>Max</FormLabel>
                              <NumericField
                                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                margin="none"
                                max={100}
                                min={values.TakeRateMin}
                                name="TakeRateMax"
                                required
                                parse={(value) => Math.floor(parseFieldAsNumber(value) || 0)}
                                sx={{ width: 105, backgroundColor: "common.white" }}
                              />
                            </FormControl>
                            <FormControl sx={{ py: 1 }}>
                              <FormLabel sx={{ fontWeight: 400, mb: 1 }}>Time of maturity</FormLabel>
                              <NumericField
                                InputProps={{ endAdornment: <InputAdornment position="end">Timesteps</InputAdornment> }}
                                margin="none"
                                max={PlanningHorizon}
                                min={1}
                                name="Timesteps"
                                sx={{ width: 140, backgroundColor: "common.white" }}
                                parse={(value) => Math.floor(parseFieldAsNumber(value) || 1)}
                              />
                            </FormControl>
                            <Typography variant="caption" fontSize={11} color="biarri.secondary.grey">
                              Time until the maximum is reached
                            </Typography>
                          </Box>
                        </form>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                <CheckboxField name="ApplyToAll" label="Apply to all address types" />
              </Box>
            );
          }}
        />
      }
      actions={
        <>
          <Button data-testid="cancel-changes" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <Button data-testid="save-changes" variant="contained" color="primary" type="submit" form="advanced-control-range-form">
            Save
          </Button>
        </>
      }
    />
  );
};

export default RevenueAdvancedControlModal;
