import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box } from "@mui/material";
import { FORM_ERROR } from "final-form";

import { LoadingButton } from "ui";

import { selectUserId, useCreateAccountMutation, useGetUserMeAccountsQuery } from "fond/api";
import { ACCOUNT_CONTEXT } from "fond/constants";
import { TextField } from "fond/form/fields";
import { CreateAccountRequestBody } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";
import { compose, required } from "fond/utils/validation";

interface IFormData {
  accountName: string;
}

const CreateAccountForm: React.FC = () => {
  const navigate = useNavigate();
  const [createAccount] = useCreateAccountMutation();
  const userId = useSelector(selectUserId) ?? "";
  // refetch user linked accounts on create for admin/allocation checks
  const { refetch } = useGetUserMeAccountsQuery();

  const handleOnSubmit = async ({ accountName }: IFormData) => {
    const accountDetails: CreateAccountRequestBody = {
      Name: accountName,
      Email: null,
      Address: null,
      SystemOfMeasurement: "imperial",
      BomArchRestrictions: null,
      AdminUserID: userId,
    };

    try {
      const response = await createAccount(accountDetails).unwrap();
      const existingAccountContext = getItem(ACCOUNT_CONTEXT);
      refetch();

      setItem(ACCOUNT_CONTEXT, existingAccountContext ? { ...existingAccountContext, [userId]: response.ID } : { [userId]: response.ID });
      navigate("/");
    } catch (error) {
      return { [FORM_ERROR]: "Failed to create account." };
    }
    return {};
  };

  return (
    <Form<IFormData>
      initialValues={{
        accountName: "",
      }}
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} data-testid="create-account-form">
          <Box>
            <Box mb={3}>
              <TextField name="accountName" label="What's your account name?" validate={compose(required)} />
            </Box>
            {submitError && (
              <Box mb={2}>
                <Alert data-testid="create-account-error-message" severity="error">
                  {submitError}
                </Alert>
              </Box>
            )}
            <Box>
              <LoadingButton loading={submitting} data-testid="create-account-button" type="submit" fullWidth variant="contained" color="primary">
                CREATE ACCOUNT
              </LoadingButton>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default CreateAccountForm;
