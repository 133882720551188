import { Box, styled } from "@mui/material";
import { blue } from "@mui/material/colors";

export const FieldLabel = styled(Box)(({ theme }) => ({
  fontWeight: 500,
}));

export const SelectedField = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean; disabled: boolean }>(({ theme, isSelected, disabled }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  cursor: disabled ? "not-allowed" : "pointer",
  color: disabled ? theme.palette.text.disabled : isSelected ? theme.palette.primary.main : undefined,
  fontSize: 12,
  padding: theme.spacing(0.5),
  backgroundColor: isSelected ? blue[50] : undefined,
  "&:hover": {
    backgroundColor: !disabled ? blue[50] : undefined,
  },
  marginTop: theme.spacing(1),
}));
