/**
 * Parse a field value as a number.
 *
 * Will try to convert a string to a number, if it is not possible it will return 0.
 * If the value is already a number, it will return the number.
 *
 * This is useful as a Final Form field parser so that we can ensure that the
 * value is a number when it saved in the form state.
 *
 * @param value - the value to parse.
 * @returns
 */
export const parseFieldAsNumber = (value?: string | number | null): number | undefined => {
  if (value == null || value === "") {
    return undefined;
  }
  if (typeof value === "string") {
    const parsed = Number(value);
    if (Number.isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }
  return value;
};

export const normalizeInteger = (value?: string | null): string => {
  if (value == null || value === "") {
    return "";
  }
  const integersOnly = value.replace(/[^\d]/g, "");
  return integersOnly;
};
