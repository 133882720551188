import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";

import { formatDateString } from "fond/utils/dates";
import { PageCentred } from "fond/widgets/PageCentred";

import { ButtonLink } from "./Confirmation.styles";

const RenewalConfirmation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productCategory = location.state?.productCategory;

  useEffect(() => {
    // can only be redirected from the renewal page
    if (!location.state?.subscriptionId) {
      navigate("/workspace");
    }
  }, [location.state?.subscriptionId, navigate]);

  return (
    <PageCentred windowTitle="Renewal confirmation">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h3" margin={3} data-testid="renewal-confirmed-header">
          Payment confirmed
        </Typography>
        <CheckCircleIcon color="primary" fontSize="large" />
        <Typography textAlign="center" margin={2}>
          Charges commence at the beginning of your new {productCategory} term.
        </Typography>
        <Typography textAlign="center">Starts on {formatDateString(location.state?.startTime)}</Typography>

        <Box sx={{ display: "flex", alignItems: "center", marginTop: 3 }}>
          <Typography variant="caption">Go to</Typography>
          <ButtonLink
            data-testid="go-to-account-settings"
            color="inherit"
            onClick={() => {
              navigate("/settings/account", { state: { tab: productCategory } });
            }}
          >
            Account Settings
          </ButtonLink>
          <Typography variant="caption">to view new details</Typography>
        </Box>
      </Box>
    </PageCentred>
  );
};

export default RenewalConfirmation;
