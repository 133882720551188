import { Box, styled } from "@mui/material";

export const Badge = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  alignContent: "center",
  inset: 0,
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor: "#E1373B",
  fontSize: "0.75rem",
  color: "white",
  borderRadius: 10,
  height: 20,
  minWidth: 20,
}));
