import React, { useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { DEMAND_MODEL_MODIFIED } from "fond/constants";
import { DemandAddressType, DemandModelRule, DemandTier } from "fond/types";
import { setItem } from "fond/utils/localStorage";

import { EditableTableRow, FormHelperText, StaticTableRow, Tooltip } from "./DemandTab.styles";

interface IProps {
  values: DemandModelRule;
  onChange: (values: DemandModelRule) => void;
  deleteRow: (index: string) => void;
  selectedAddressTypes: string[];
  handleUnsetPreviousDefaultAddressType: () => void;
  onlyRow: boolean;
}

const validate = (values: DemandModelRule, selectedAddressTypes: string[]) => {
  let errors: { [key: string]: string } = {};

  // if (!values.AddressType) errors.AddressType = "Cannot be empty";

  if (selectedAddressTypes.includes(values.AddressType)) errors.AddressType = "Duplicate address type";

  return errors;
};

const DemandModelRow: React.FC<IProps> = ({ values, onChange, deleteRow, selectedAddressTypes, handleUnsetPreviousDefaultAddressType, onlyRow }) => {
  const theme = useTheme();
  const { white } = theme.palette.common;
  const [formValues, setFormValues] = useState(values);
  const [isEditing, setEditing] = useState(values.New);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const hasErrors = Object.keys(formErrors).length > 0;

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  useEffect(() => {
    setFormErrors(validate(formValues, selectedAddressTypes));
  }, [formValues, selectedAddressTypes]);

  const updateValue = (key: keyof DemandModelRule, value: string | number | boolean) => {
    setFormValues((currentFormValues) => ({ ...currentFormValues, [key]: value }));
  };

  const handleDefaultAddressTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue("Default", e.target.checked);

    if (formValues.Ignore) updateValue("Ignore", false);
  };

  const handleIgnoreCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue("Ignore", e.target.checked);
  };

  const handleOnSave = () => {
    if (hasErrors) {
      return;
    }

    if (formValues.New) {
      setFormValues((currentFormValues) => ({ ...currentFormValues, New: false }));
    }
    if (formValues.Default && !values.Default) {
      handleUnsetPreviousDefaultAddressType();
    }
    onChange({ ...formValues, New: false });
    setEditing(false);

    // Record that changes were made to the demand model
    setItem(DEMAND_MODEL_MODIFIED, true);
  };

  const renderWithDefaultAddressTypeTooltip = (element: React.ReactElement) => (
    <Tooltip title="Address type not specified in the demand model will use the default setting instead">{element}</Tooltip>
  );

  if (isEditing) {
    return (
      <EditableTableRow data-testid={`edit-demand-model-row-${values.AddressType}`}>
        <TableCell data-testid="demand-address-type">
          <Box position="relative">
            <Autocomplete
              size="small"
              autoSelect
              options={Object.keys(DemandAddressType).filter((option) => !selectedAddressTypes.includes(option))}
              clearOnBlur={false}
              freeSolo
              renderInput={(params) => <TextField {...params} error={!!formErrors.AddressType} />}
              onChange={(_, newValue) => updateValue("AddressType", newValue ?? "")}
              value={formValues.AddressType}
              sx={{ width: 114, backgroundColor: theme.palette.common.white }}
            />
            {formErrors.AddressType && (
              <FormHelperText data-testid="demand-error-helper" error>
                {formErrors.AddressType}
              </FormHelperText>
            )}
          </Box>
        </TableCell>
        <TableCell width="100%" data-testid="demand-description">
          <TextField
            size="small"
            multiline
            value={formValues.Description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateValue("Description", event.target.value)}
            sx={{ width: "100%", backgroundColor: white }}
          />
        </TableCell>
        <TableCell data-testid="demand-tier">
          <Select
            size="small"
            value={formValues.Tier}
            onChange={(event: SelectChangeEvent) => updateValue("Tier", event.target.value)}
            sx={{ width: 188, backgroundColor: white }}
          >
            {Object.values(DemandTier).map((tier) => (
              <MenuItem value={tier} key={tier}>
                {tier}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell data-testid="demand-num-fiber">
          <TextField
            variant="outlined"
            size="small"
            value={formValues.NumFibers}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateValue("NumFibers", +event.target.value)}
            type="number"
            inputProps={{ min: 0 }}
            sx={{ width: 60, backgroundColor: white }}
          />
        </TableCell>
        <TableCell data-testid="demand-set-default">
          <Box width={120} display="flex" flexDirection="column" justifyContent="center">
            {renderWithDefaultAddressTypeTooltip(
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.Default}
                    disabled={!formValues.AddressType || values.Default}
                    onChange={handleDefaultAddressTypeChange}
                  />
                }
                label="Default"
              />
            )}
            <Tooltip title='If you prefer not to import or utilize this address type, please mark it as "Exclude type"'>
              <FormControlLabel
                sx={{ mr: 0 }}
                control={<Checkbox disabled={formValues.Default} checked={formValues.Ignore} onChange={handleIgnoreCheckboxChange} />}
                label="Exclude type"
              />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell data-testid="demand-manage">
          <Box width={148} display="flex" flexDirection="column" px={2} justifyContent="center" gap={1}>
            <Button variant="contained" disabled={hasErrors} onClick={handleOnSave} data-testid="save-row-button">
              Save
            </Button>
            <Button
              data-testid="cancel-row-button"
              onClick={() => {
                if (values.New) deleteRow(values.ID);
                else {
                  setFormValues(values);
                  setEditing(false);
                }
              }}
            >
              Cancel
            </Button>
          </Box>
        </TableCell>
      </EditableTableRow>
    );
  }

  return (
    <StaticTableRow data-testid={`demand-model-row-${values.AddressType}`}>
      <TableCell>
        <Typography width={114}>{values.AddressType || <i>{"<Empty>"}</i>}</Typography>
      </TableCell>
      <TableCell width="100%">
        <Typography width="100%">{values.Description}</Typography>
      </TableCell>
      <TableCell>
        <Typography width={188}>{!values.Ignore ? values.Tier : "-"}</Typography>
      </TableCell>
      <TableCell>
        <Typography width={60}>{!values.Ignore ? values.NumFibers : "-"}</Typography>
      </TableCell>
      <TableCell>
        <Box width={120} display="flex" justifyContent="center">
          {values.Default && renderWithDefaultAddressTypeTooltip(<Chip label="Default" color="primary" data-testid="demand-model-default" />)}
          {values.Ignore && (
            <Tooltip title="Excluded address type will not be imported or used.">
              <Chip label="Exclude type" color="error" data-testid="demand-model-exclude" />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box width={148} display="flex" justifyContent="center" gap={1}>
          <IconButton color="primary" data-testid="edit-row-button" onClick={() => setEditing(true)}>
            <Edit />
          </IconButton>
          <IconButton
            color="primary"
            data-testid="delete-row-button"
            disabled={values.Default && !onlyRow}
            onClick={() => {
              deleteRow(values.ID);
              setItem(DEMAND_MODEL_MODIFIED, true);
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </TableCell>
    </StaticTableRow>
  );
};

export default DemandModelRow;
