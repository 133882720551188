import { Accept, DropzoneProps, FileError } from "react-dropzone";

export const INVALID_EXTENSION = { code: "file-invalid-extension", message: "Invalid extension" };
export const INVALID_FILE_NAME = { code: "file-invalid-filename", message: "Invalid filename" };

/**
 * Checks that the File extension is included within the accept object.
 *
 * Note that currently only relying on MimeType detection within Dropzone
 * is flawed and has bugs. See: https://github.com/react-dropzone/react-dropzone/issues/1232
 *
 * For example:
 *
 * { "application/octet-stream": [".shp"] }
 *
 * will allow the user to upload *.exe. Therefore this validator will
 * act as a secondarly line of defence.
 *
 */
export const validFileExtension =
  (accept?: Accept) =>
  (file: File | DataTransferItem): FileError | null => {
    if (!accept) return null; // Allow all
    if (file instanceof DataTransferItem) return null; // DataTransferItem isn't a file

    const allowedExtensions = Object.values(accept).flat();
    const extension = `.${file.name.split(".").pop()}`.toLowerCase();
    return allowedExtensions.includes(extension) ? null : INVALID_EXTENSION;
  };

/**
 * Validator enforcing that the file must be whitelisted
 */
export const validFileName =
  (acceptedFilenames: string[]) =>
  (file: File): FileError | null => {
    return acceptedFilenames.includes(file.name) ? null : INVALID_FILE_NAME;
  };

/**
 * Combines a custom validator that the user has supplied with the mandatory validFileExtension validator
 */
export const composeValidators = ({
  validator,
  accept,
}: {
  validator?: DropzoneProps["validator"];
  accept?: Accept;
} = {}): (<T extends File>(file: T) => FileError | FileError[] | null) => {
  const validators = (file: File): FileError | FileError[] | null => {
    const errors: FileError[] = [];
    const extensionErrors = accept && validFileExtension(accept)(file);
    const validatorErrors = (accept && validator?.(file)) || [];
    if (extensionErrors) errors.push(extensionErrors);
    if (validatorErrors) {
      if (Array.isArray(validatorErrors)) {
        errors.push(...validatorErrors);
      } else {
        errors.push(validatorErrors);
      }
    }

    return errors.length === 0 ? null : errors;
  };
  return validators;
};
