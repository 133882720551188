import React from "react";
import { List, SelectAll } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

import PropertiesPanel from "fond/project/featureProperties/PropertiesPanel";
import { useAppSelector } from "fond/utils/hooks";

import { ITabProps } from "./factory";

export const Widget: React.FC<ITabProps> = () => {
  const { selectedFeature } = useAppSelector((state) => state.project);
  if (!selectedFeature) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <SelectAll color="action" sx={{ mb: 2 }} />
          <Typography variant="body3">Select a feature to view</Typography>
        </Box>
      </Box>
    );
  }

  return <PropertiesPanel />;
};

export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="Properties" arrow placement="right">
    <List />
  </Tooltip>
);
