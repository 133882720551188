import React, { useMemo } from "react";
import { ColDef, IAggFuncParams } from "@ag-grid-community/core";

import { SchedulesData } from "fond/types";
import { currencyValueFormatter } from "fond/widgets/AgGrid";

import { VisualizationTypes } from "../VisualizationTypes";

import RevenueData from "./RevenueData";
import { transformCost } from "./transforms";

interface IProps {
  data?: SchedulesData["Cost"];
}

const CostSchedule: React.FC<IProps> = ({ data }) => {
  const tags: string[] = useMemo(() => [...new Set<string>(data?.map(({ Tag }) => Tag))], [data]);
  const phases: string[] = useMemo(() => [...new Set<string>(data?.map(({ Phase }) => Phase))], [data]);
  const pivotData = useMemo(() => transformCost(tags, phases, data), [tags, data, phases]);

  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "Tag",
        headerName: "Tag",
        rowGroup: true,
        width: 200,
        minWidth: 200,
        hide: true,
      },
      ...(phases?.map((phase) => ({
        aggFunc: (params: IAggFuncParams) => {
          return params.values[2]; // Index 2 = Cumulative net cost
        },
        field: phase,
        minWidth: 125,
        width: 125,
        valueFormatter: currencyValueFormatter(" "),
      })) || []),
    ],
    [phases]
  );

  return <RevenueData columns={columns} gridOptions={VisualizationTypes.Cost.gridOptions} rowData={pivotData} />;
};

export default CostSchedule;
