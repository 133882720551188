import React from "react";
import { Delete } from "@mui/icons-material";
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { useCustomStyles } from "../FieldFactory";
import { FormLabel } from "../styles";

import {
  ErrorMessage,
  FieldWrapper,
  InlineFieldWrapper,
  MenuItemWithIcon,
  OperationIcon,
  TitleWithAction,
  TransformationContainer,
} from "./textPartsField.styles";

type OperationType = "+" | "-" | "*" | "/" | "%" | "^" | "min" | "max" | "abs" | "ceil" | "floor" | "round" | "sqrt";

type OperationsMap = {
  [key in OperationType]: string;
};

const operationsMap: OperationsMap = {
  "+": "Addition",
  "-": "Subtraction",
  "*": "Multiplication",
  "/": "Division",
  "%": "Remainder",
  "^": "Exponential",
  min: "Minimum",
  max: "Maximum",
  abs: "Absolute value",
  ceil: "Round up",
  floor: "Round down",
  round: "Round off",
  sqrt: "Square root",
};

const operations = Object.keys(operationsMap) as Array<OperationType>;

export interface Transformation {
  Operation: string;
  Value?: number | "";
  MinFractionDigits?: number | "";
  MaxFractionDigits?: number | "";
}

export type Error = {
  Operation?: string;
  Value?: string;
  MinFractionDigits?: string;
  MaxFractionDigits?: string;
};

interface IProps {
  data: Transformation;
  onChange: (value: Transformation) => void;
  deleteTransformation: () => void;
  error?: Error;
}

const Transformation: React.FC<IProps> = ({ data, onChange, deleteTransformation, error }: IProps) => {
  const classes = useCustomStyles();

  const handleOperationChange = (event: SelectChangeEvent) => {
    const {
      target: { value: newValue },
    } = event;

    // Set Value, MinFractionDigits, and MaxFractionDigits as undefined on Operation change
    onChange({ Operation: newValue });
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: "Value" | "MinFractionDigits" | "MaxFractionDigits") => {
    const {
      target: { value: newValue },
    } = event;

    const updatedData = { ...data };
    updatedData[fieldName] = newValue === "" ? "" : +newValue;

    onChange(updatedData);
  };

  return (
    <TransformationContainer>
      <FormControl error={!!error?.Operation} sx={{ width: "100%" }}>
        <FieldWrapper>
          <TitleWithAction>
            <FormLabel sx={{ marginBottom: 0 }}>Operator</FormLabel>
            <IconButton size="small" onClick={() => deleteTransformation()}>
              <Delete sx={{ fontSize: 16 }} />
            </IconButton>
          </TitleWithAction>
          <Select
            inputProps={{ "data-testid": "transformation-operation-select" }}
            className={classes.selectFieldWithIcon}
            size="small"
            margin="none"
            displayEmpty
            value={data.Operation}
            onChange={handleOperationChange}
          >
            <MenuItem value="">Select</MenuItem>
            {operations.map((operation, ind) => (
              <MenuItemWithIcon key={operation} value={operation}>
                <OperationIcon index={ind} />
                {operationsMap[operation]}
              </MenuItemWithIcon>
            ))}
            <MenuItemWithIcon value="number-format">
              <svg width="24" height="16">
                <text x="0" y="12" style={{ fontSize: 10 }}>
                  1.23
                </text>
              </svg>
              Decimal places
            </MenuItemWithIcon>
          </Select>
        </FieldWrapper>
        <ErrorMessage>{error?.Operation}</ErrorMessage>
      </FormControl>
      {["+", "-", "*", "/", "%", "^", "max", "min"].includes(data.Operation) && (
        <FormControl error={!!error?.Value}>
          <InlineFieldWrapper>
            <FormLabel>Value: </FormLabel>
            <TextField
              inputProps={{ "data-testid": "operation-value-input" }}
              type="number"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleValueChange(event, "Value")}
              value={data.Value}
              classes={{
                root: classes.textFieldRoot,
              }}
              sx={{ flex: "1" }}
              error={!!error?.Value}
            />
          </InlineFieldWrapper>
          <ErrorMessage>{error?.Value}</ErrorMessage>
        </FormControl>
      )}
      {data.Operation === "number-format" && (
        <>
          <FormControl error={!!error?.MinFractionDigits}>
            <InlineFieldWrapper>
              <FormLabel>Min fraction digit: </FormLabel>
              <TextField
                inputProps={{ "data-testid": "operation-min-fraction-input" }}
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleValueChange(event, "MinFractionDigits")}
                value={data.MinFractionDigits}
                classes={{
                  root: classes.textFieldRoot,
                }}
                sx={{ flex: "1" }}
                error={!!error?.MinFractionDigits}
              />
            </InlineFieldWrapper>
            <ErrorMessage>{error?.MinFractionDigits}</ErrorMessage>
          </FormControl>
          <FormControl error={!!error?.MaxFractionDigits}>
            <InlineFieldWrapper>
              <FormLabel>Max fraction digit: </FormLabel>
              <TextField
                inputProps={{ "data-testid": "operation-max-fraction-input" }}
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleValueChange(event, "MaxFractionDigits")}
                value={data.MaxFractionDigits}
                classes={{
                  root: classes.textFieldRoot,
                }}
                sx={{ flex: "1" }}
                error={!!error?.MaxFractionDigits}
              />
            </InlineFieldWrapper>
            <ErrorMessage>{error?.MaxFractionDigits}</ErrorMessage>
          </FormControl>
        </>
      )}
    </TransformationContainer>
  );
};

export default Transformation;
