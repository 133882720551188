import React, { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Action, Actions, BorderNode, Layout as FlexLayout, Model, TabNode, TabSetNode } from "flexlayout-react";

import { useUpdateLayoutMutation } from "fond/api";
import { LayoutUpdateRequestBody } from "fond/api/layoutSlice";
import mixpanel from "fond/mixpanel";
import { Store } from "fond/types";

import { iconFactory, titleFactory, widgetFactory } from "./widgets/factory";
import FloatingWindows from "./FloatingWindows";
import { LayoutContext } from "./LayoutProvider";
import { convertFromModel } from "./widgets";

import "./theme.scss";

type LayoutAction = {
  description: string;
  getTargetId: (action: Action) => string;
};

const layoutActions: { [key: string]: LayoutAction } = {
  FlexLayout_AddNode: {
    description: "Layout: Added Node",
    getTargetId: (action: Action) => action.data.json.id,
  },
  FlexLayout_DeleteTab: {
    description: "Layout: Deleted Tab",
    getTargetId: (action: Action) => action.data.node,
  },
  FlexLayout_MoveNode: {
    description: "Layout: Moved Node",
    getTargetId: (action: Action) => action.data.fromNode,
  },
  FlexLayout_MaximizeToggle: {
    description: "Layout: Maximize/Minimize toggled",
    getTargetId: (action: Action) => action.data.node,
  },
};

interface IProps {
  type: "project" | "multiProject";
}

const Layout: React.FC<IProps> = ({ type }: IProps) => {
  const { layoutRef, model, mapModel, setWindowIds, windowIds } = useContext(LayoutContext);

  // The resourceId can be either a project ID or a multiProject ID depending on what is currently viewed.
  const { projectId: resourceId } = useSelector((state: Store) => state.project);
  const projectId = type === "project" ? resourceId : undefined;
  const multiProjectId = type === "multiProject" ? resourceId : undefined;

  const username = useSelector((state: Store) => state.cognito.user?.username);
  const [updateLayout] = useUpdateLayoutMutation();
  const containerRef = useRef<HTMLDivElement>(null);
  const floating = useRef<string[]>([]);

  /**
   * Callback function for when the layout model changes.
   * For example the user moves tabs or changes layout widths.
   */
  const handleModelChange = (newModel: Model) => {
    // Save user layout only if the user is logged in.
    if (username && resourceId) {
      const layouts = convertFromModel(newModel);
      const maps = mapModel ? convertFromModel(mapModel) : [];
      const layoutUpdateRequestBody: LayoutUpdateRequestBody = {
        ProjectID: projectId,
        MultiProjectID: multiProjectId,
        Layouts: layouts,
        Maps: maps,
      };
      updateLayout(layoutUpdateRequestBody);
    }
  };

  /**
   * Handles the customisation of the Tab actions show to the right of the TabSet
   * We use a custom floating mechanic to allow for Mui Styles to be rendered
   * correctly into the popout.  The flexlayout-react approach does not handle the
   * dynamic nature of the mui classNames.
   */
  const handleOnRenderTabSet = (tabSetNode: TabSetNode | BorderNode, renderValues: any) => {
    const selectedNode = tabSetNode.getSelectedNode() as TabNode;
    if (selectedNode && selectedNode.getConfig()?.enableFloat) {
      const id = selectedNode.getId();
      const isFloating = floating.current.includes(id);

      if (!isFloating) {
        renderValues.buttons.push(
          // Button style from the flexlayout-react package
          <button
            aria-label="Show selected tab in floating window"
            key={`float_${id}`}
            type="button"
            title="Show selected tab in floating window"
            className="flexlayout__border_toolbar_button flexlayout__border_toolbar_button-float"
            onClick={handleOnFloat(id)}
          >
            <svg viewBox="0 0 24 24" fill="gray" style={{ width: "1em", height: "1em", display: "flex", alignItems: "center" }}>
              <path d="M0 0h24v24H0z" fill="none" />
              <path stroke="gray" d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z" />
            </svg>
          </button>
        );
      }
    }
  };

  /**
   * Handles the opening of a node & closing its non floating parent
   */
  const handleOnFloat = (id: string) => () => {
    setWindowIds([...windowIds, id]);
    if (model) {
      model.doAction(Actions.selectTab(id));
      mixpanel.track("Layout: Floating Window opened", { id });
    }
  };

  /**
   * Handles the tracking of select layout changes with mixpanel
   */
  const handleActionTracking = (action: Action) => {
    if (action.type in layoutActions) {
      const { description, getTargetId } = layoutActions[action.type];
      mixpanel.track(description, { id: getTargetId(action) });
    }
    return action;
  };

  return (
    <Box ref={containerRef}>
      {model && (
        <FlexLayout
          ref={layoutRef}
          model={model}
          onModelChange={handleModelChange}
          factory={widgetFactory}
          font={{ size: "12px" }}
          titleFactory={titleFactory}
          iconFactory={iconFactory}
          onRenderTabSet={handleOnRenderTabSet}
          popoutURL="/widget"
          onAction={handleActionTracking}
        />
      )}
      {containerRef.current && model && <FloatingWindows model={model} />}
    </Box>
  );
};

export default Layout;
