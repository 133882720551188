/**
 * Return if the current application stage is in `stages`.
 *
 * The current stage is defined by the `REACT_APP_STAGE` environment variable.
 * If this variable is not set, we assume the local stage.
 */

export const isAppStageIn = (stages: string[]): boolean => stages.includes(process.env.REACT_APP_STAGE ?? "local");

/**
 * Return if the current application stage is `stage`.
 *
 * See `isAppStageIn` for details on how the current stage is determined.
 */
export const isAppStage = (stage: string): boolean => isAppStageIn([stage]);

/**
 * Return if the current application stage is a development stage.
 */
export const isAppDevStage = (): boolean => isAppStageIn(["local", "staging", "demo", "dev"]);
