import { FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { apiSlice } from "fond/api/apiSlice";
import { Dataset } from "fond/types";

type GetDatasetsResponse = {
  Datasets: Dataset[];
};

type DatasetFeaturesWithinResponse = {
  Collection: FeatureCollection;
};

export const datasetSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDatasets: build.query<Dataset[], string>({
      query: (AccountID) => ({
        url: "/v2/datasets",
        params: { account_id: AccountID },
      }),
      transformResponse: (response: GetDatasetsResponse) => response.Datasets,
    }),
    featuresWithinDataset: build.mutation<FeatureCollection, { datasetId: string; boundingBox: Polygon | MultiPolygon; accountId: string }>({
      query: ({ datasetId, boundingBox, accountId }) => ({
        url: `/v2/datasets/${datasetId}/features-within`,
        method: "POST",
        body: { bounding_box: boundingBox },
        params: { account_id: accountId },
      }),
      transformResponse: (response: DatasetFeaturesWithinResponse) => response.Collection,
    }),
  }),
});

export const { useGetDatasetsQuery, useFeaturesWithinDatasetMutation } = datasetSlice;
