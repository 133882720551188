import { createEntityAdapter } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

import { apiSlice } from "fond/api/apiSlice";
import { BaseIcon, Icon } from "fond/types/icon";

type GetBaseIconsResponse = {
  Icons: BaseIcon[];
};

export const iconsAdapter = createEntityAdapter<Icon>({
  selectId: (entity: Icon): string => entity.ID,
});
const iconInitialState = iconsAdapter.getInitialState();

/**
 * Icons API Slice
 */
export const iconSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getIcons: build.query<BaseIcon[], undefined>({
      query: () => `/v2/icons?width=32&height=32`,
      transformResponse: (response: GetBaseIconsResponse) => {
        return sortBy(response.Icons, "Name");
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetIconsQuery } = iconSlice;
