import { Alert as BaseAlert, AlertTitle as BaseAlertTitle, Box, Button as BaseButton, FormHelperText, MenuItem, styled } from "@mui/material";
import operationIcons from "svg_icons/style-editor/operation-icons-sprite.svg";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const Alert = styled(BaseAlert)(({ theme }) => ({
  fontSize: 12,
  padding: `0 ${theme.spacing(1)}`,
  "& .MuiAlert-icon": {
    fontSize: 14,
  },
}));

export const AlertTitle = styled(BaseAlertTitle)(({ theme }) => ({
  fontSize: 12,
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const MenuItemWithIcon = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  paddingLeft: theme.spacing(1.5),

  "& > svg": {
    height: 16,
    minWidth: 20,
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}));

export const FieldItemContainer = styled(Box)(({ theme }) => ({
  width: 200,
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.biarri.primary.lightGrey}`,
  borderRadius: 4,
  marginBottom: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
}));

export const TransformationContainer = styled(FieldItemContainer)`
  width: 175px;
`;

export const TitleWithAction = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const OperationIcon = styled(Box)<{ index: number }>(({ theme, index }) => ({
  height: 10,
  width: 16,
  backgroundImage: `url(${operationIcons})`,
  backgroundPosition: `0 -${index * 10}px`,
  marginRight: theme.spacing(2),
}));

export const InlineFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: 170,

  "& > label": {
    marginBottom: 0,
    marginRight: theme.spacing(1.5),
  },

  "& > .MuiInputBase-root": {
    width: "unset",
    flex: "1",
    overflow: "hidden",
  },
}));

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  fontSize: 10,
  marginLeft: 0,
}));

export const Button = styled(BaseButton, {
  shouldForwardProp: (prop) => prop !== "withMargin",
})<{ withMargin?: boolean }>(({ theme, withMargin }) => ({
  justifyContent: "start",
  fontSize: 12,
  lineHeight: "12px",
  margin: withMargin ? `${theme.spacing(2)} 0` : 0,
  color: theme.palette.text.primary,

  "& svg": {
    color: theme.palette.primary.main,
  },
}));
