import { Box, styled, Typography } from "@mui/material";

import { fixSsrWarning } from "fond/utils/css";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: 1,
  height: "100%",
}));

export const TabPanel = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: `${theme.spacing(3)} ${theme.spacing(5.5)}`,
}));

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: `${theme.spacing(1)} 0`,
  marginBottom: theme.spacing(1.5),
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  alignItems: "center",

  "& > svg": {
    color: theme.palette.common.black,
    opacity: 0.54,
    marginRight: theme.spacing(1),
  },
}));

export const RundownContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(5.5),
}));

export const RundownItem = styled(Box)(({ theme }) => ({
  flex: 1,
  borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
  paddingLeft: theme.spacing(1.5),

  [`&:first-child ${fixSsrWarning}`]: {
    border: 0,
    padding: 0,
  },
}));

export const RundownLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 7,
  color: theme.palette.biarri.secondary.grey,
}));

export const RundownValue = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
})) as typeof Typography;

export const InfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: theme.spacing(1),
  marginTop: theme.spacing(0.75),
  background: "#efefef",
  borderRadius: 4,

  "& > svg": {
    width: 12,
    margin: theme.spacing(0.75),
  },
}));

export const InfoContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: `0 ${theme.spacing(1)}`,
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "0.625rem",
}));

export const InfoMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "0.625rem",
  color: theme.palette.biarri.secondary.grey,
}));
