export const textAnchors = [
  {
    value: "center",
    displayValue: "Center",
  },
  {
    value: "left",
    displayValue: "Left",
  },
  {
    value: "right",
    displayValue: "Right",
  },
  {
    value: "top",
    displayValue: "Top",
  },
  {
    value: "bottom",
    displayValue: "Bottom",
  },
  {
    value: "top-left",
    displayValue: "Top left",
  },
  {
    value: "top-right",
    displayValue: "Top right",
  },
  {
    value: "bottom-left",
    displayValue: "Bottom left",
  },
  {
    value: "bottom-right",
    displayValue: "Bottom right",
  },
];
