import React from "react";
import { Info } from "@mui/icons-material";
import { Box, Card, CardContent, Popover, Typography } from "@mui/material";
import tooltipCardImg from "svg_icons/report/horizon-value-tootip.png";

const HorizonColumnHeader: React.FC = (props) => {
  const [tooltipAnchor, setTooltipAnchor] = React.useState<HTMLElement | null>(null);
  const iconRef = React.useRef<HTMLElement | null>(null);
  const handleTooltipOpen = () => {
    setTooltipAnchor(iconRef.current);
  };

  const handleTooltipClose = () => {
    setTooltipAnchor(null);
  };

  return (
    <>
      <Box className="ag-header-cell-label" display="flex" alignItems="center">
        <Typography className="ag-header-cell-text">Horizon</Typography>
        <Box ref={iconRef} data-testid="horizon-column-header-info-icon" onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
          <Info sx={{ ml: 1 }} fontSize="small" color="action" />
        </Box>
      </Box>
      <Popover
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        open={Boolean(tooltipAnchor)}
        anchorEl={tooltipAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleTooltipClose}
      >
        <Card data-testid="horizon-column-header-tooltip" sx={{ maxWidth: 450, background: "#1D2C34" }}>
          <img src={tooltipCardImg} height="220" width="450" alt="" />
          <CardContent>
            <Typography fontSize={12} color="white">
              Horizon Value: Represents the value of maintaining the subscriber's revenue stream beyond the Planning Period. This value will be
              discounted by the discount rate to the start date. This is also referred to as "Terminal Value" or "Residual Value".
            </Typography>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default HorizonColumnHeader;
