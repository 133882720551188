import { Box, IconButton as BaseIconButton, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  height: 52,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.biarri.primary.midnight,
  color: "white",
  padding: theme.spacing(1),
  paddingLeft: 0,
  fontSize: theme.typography.h5.fontSize,
}));

export const Title = styled(Box)(({ theme }) => ({}));

export const IconButton = styled(BaseIconButton)(({ theme }) => ({
  color: "white",
  marginRight: theme.spacing(1),
}));
