import { createEntityAdapter } from "@reduxjs/toolkit";

import { Attribute, ConfigAttribute, Configuration } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { apiSlice } from "./apiSlice";
import { draftSlice } from "./draftSlice";

type GetAttributesResponse = {
  Items: Attribute[];
};

export const attributesAdapter = createEntityAdapter<Attribute>({
  selectId: (entity: Attribute): string => entity.ID,
});
const attributesInitialState = attributesAdapter.getInitialState();

/**
 * Attributes API Slice
 */
export const attributesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    updateConfigAttribute: build.mutation<ConfigAttribute, { mapLayerConfigId: string; attribute: ConfigAttribute }>({
      query: ({ attribute }) => ({
        url: `/v2/attribute-configurations/${attribute.ID}`,
        method: "PUT",
        body: attribute,
      }),
      onQueryStarted: async ({ mapLayerConfigId, attribute }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              const layerConfig = draft.Data.entities[attribute.LayerConfigurationID] as LayerConfig | SublayerConfig;
              if (layerConfig && layerConfig?.Type !== "SUBLAYER") {
                const attributeIndex = layerConfig.Attributes?.findIndex((layerAttribute) => layerAttribute.ID === attribute.ID);
                if (layerConfig.Attributes && attributeIndex !== undefined) {
                  layerConfig.Attributes[attributeIndex] = attribute;
                }
              }
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useUpdateConfigAttributeMutation } = attributesSlice;
