import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import _ from "lodash";

import { AttachmentUploadStatus } from "fond/types";
import { CircularProgressWithLabel } from "fond/widgets";

const customStyles = (theme: Theme) => {
  return createStyles({
    spinner: {
      width: 220,
      margin: "auto",
      textAlign: "center",
    },
    uploading: {
      color: theme.palette.primary.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    failure: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    cancelled: {
      color: theme.palette.action.disabled,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /*
   * The list of files being uploaded.
   */
  files: File[];
  /*
   * The attachments state 'uploadStatus' pass through.
   */
  fileUploadStatus: {
    [key: string]: AttachmentUploadStatus;
  };
}

const AttachmentUploadTotalProgress: React.FC<IProps> = ({ classes, files, fileUploadStatus }: IProps) => {
  const [totalProgressStatus, setTotalProgressStatus] = useState({ progress: 0, className: classes.warning, message: "pending" });
  const [fileSizesByName, setFileSizesByName] = useState<{ [key: string]: number } | undefined>(undefined);
  const [totalFileSize, setTotalFileSize] = useState<number | null>(null);

  /*
   * Determine the total progress spinner by calculating the total expected file upload size if not already known.
   * Update the total progress value and state each time the files upload status' change.
   */
  useEffect(() => {
    // Calculate the total file size is not known.
    if (totalFileSize == null || !fileSizesByName) {
      const fileSizesByNameMap = _.mapValues(_.keyBy(files, "name"), "size");
      setFileSizesByName(fileSizesByNameMap);
      setTotalFileSize(_.sum(_.values(fileSizesByNameMap)));
      return;
    }
    // Calculate the total progress.
    const uploadedSize = _.sum(files.map((file: File) => 0.01 * (fileUploadStatus[file.name]?.progress || 0) * fileSizesByName[file.name]));
    const progress = (totalFileSize !== 0 ? uploadedSize / totalFileSize : 1) * 100;

    // Count the file upload status'. If any file is still uploading the total progress status is 'Uploading'.
    let statusCount = { success: 0, error: 0, cancel: 0, uploading: 0 };
    for (const status of _.values(fileUploadStatus)) {
      if (status.isError) {
        statusCount.error += 1;
      } else if (status.isAborted) {
        statusCount.cancel += 1;
      } else if (status.progress === 100) {
        statusCount.success += 1;
      } else {
        setTotalProgressStatus({ progress, className: classes.uploading, message: "Uploading..." });
        return;
      }
    }

    // If no file is uploading then display the total status as complete, cancelled or failed if all file status' match.
    // If there is a mix of status' (some complete, some didn't) then display a warning with appropriate message.
    if (statusCount.success === files.length) {
      setTotalProgressStatus({ progress, className: classes.success, message: "Upload complete." });
    } else if (statusCount.cancel === files.length) {
      setTotalProgressStatus({ progress, className: classes.cancelled, message: "Upload cancelled." });
    } else if (statusCount.error === files.length) {
      setTotalProgressStatus({ progress, className: classes.failure, message: "Upload failed." });
    } else {
      setTotalProgressStatus({
        progress,
        className: classes.warning,
        message: `Uploaded ${statusCount.success} of ${files.length} files.`,
      });
    }
  }, [fileUploadStatus]);

  return (
    <Box className={classes.spinner}>
      <CircularProgressWithLabel className={totalProgressStatus.className} size={90} value={totalProgressStatus.progress} />
      <Box className={totalProgressStatus.className}>
        <Typography>{totalProgressStatus.message}</Typography>
      </Box>
    </Box>
  );
};

AttachmentUploadTotalProgress.displayName = "AttachmentUploadTotalProgress";
export default withStyles(customStyles)(AttachmentUploadTotalProgress);
