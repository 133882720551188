/**
 * A numeric field differs from a strict number input (<input type="number">) in that it allows for a more flexible input pattern.
 * It sets the inputMode and pattern so we get numeric field behaviours, but does not show the number input spinner.
 */

import { useForm } from "react-final-form";
import { FieldValidator } from "final-form";

import TextField, { FieldValue, TextFieldProps } from "fond/form/fields/TextField/TextField";
import { parseFieldAsNumber } from "fond/form/util";
import { compose, maximum, minimum, numeric, required } from "fond/utils/validation";

const NUMERIC_PATTERN = "^(?![^\\d\\-\\.])-?\\d*\\.?\\d*(e|e-)?\\d*[^\\D]$";

export type NumericFieldProps = TextFieldProps & {
  /**
   * Additional validators to apply to the field beyond the default numeric validation
   */
  extraValidators?: FieldValidator<FieldValue>[];
  /**
   * When provided will parse the value on blur and set the field value to the parsed value.
   *
   * We do it on blur because Final Form's parse prop will parse on every keystroke which
   * makes it impossible to type non-numerical characters, which may be necessary to represent
   * a number, such as a decimal point.
   *
   * This function should return a number or undefined if the value cannot be parsed.
   *
   * By default will parse the field as a number.
   */
  parse?(value: string): number | undefined;
  /**
   * When true the field will not be parsed on blur even if a parse function is provided.
   */
  noParse?: boolean;
  /**
   * Validates that the value is greater than or equal to this number
   */
  min?: number;
  /**
   * Validates that the value is less than or equal to this number
   */
  max?: number;
};

export const NumericField = (props: NumericFieldProps): JSX.Element => {
  const formApi = useForm();
  const { extraValidators, min, max, parse, noParse, required: isRequired, inputProps, ...restProps } = props;
  const additionalProps: { onBlur?: TextFieldProps["onBlur"]; required?: boolean } = {};
  const defaultValidators: FieldValidator<FieldValue>[] = [numeric];
  if (isRequired) {
    defaultValidators.unshift(required); // put required at the front of the validation array
  }
  if (min !== undefined) {
    defaultValidators.push(minimum(min));
  }
  if (max !== undefined) {
    defaultValidators.push(maximum(max));
  }

  if (!noParse) {
    const parseFn = parse || parseFieldAsNumber;
    additionalProps.onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      formApi.change(restProps.name, parseFn(event.target.value));
    };
  }
  // only pass along the required prop if it is explicitly set AND there is a label to show it on
  // We're already validating our field as required, so we don't need to pass it on other than to show
  // the indicator on the label.
  if (restProps.label != null) {
    additionalProps.required = isRequired;
  }

  const filteredExtraValidators: FieldValidator<FieldValue>[] = extraValidators ? extraValidators.filter((x) => x != null) : [];
  const validators = defaultValidators.concat(filteredExtraValidators);

  return (
    <TextField
      {...restProps}
      {...additionalProps}
      inputProps={{ inputMode: "numeric", pattern: NUMERIC_PATTERN, ...inputProps }}
      validate={compose(...validators)}
    />
  );
};
