import { forwardRef, useState } from "react";
import { Alert, Box, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { basename } from "fond/fileValidation";
import { MimeTypeIcon, RenameModal } from "fond/widgets";

import AttachmentStagingItemAction from "./AttachmentStagingListItemAction";
import { FileStatus } from "./attachmentValidation";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    margin: "0 10px",
    padding: "0px 10px",
    width: 215,
  },
  fileStatusActions: {
    textAlign: "right",
  },
}));

interface IProps {
  file: File;
  /*
   * The validation status of the file.
   */
  status: FileStatus;
  /**
   * Callback function for when the remove staged file button is clicked.
   */
  onRemove(file: File): void;
  /**
   * Callback function for when the rename staged file button is clicked.
   */
  onRename(file: File, name: string): Promise<void>;
  /**
   * Callback function for when the overwrite staged file button is clicked.
   */
  onOverwrite(file: File): void;
}

const AttachmentStagingListItem = forwardRef<HTMLDivElement, IProps>(({ file, status, onRemove, onRename, onOverwrite }: IProps, ref) => {
  const classes = useStyles();
  const [renaming, setRenaming] = useState(false);

  return (
    <>
      {renaming && (
        <RenameModal
          title="Rename Attachment"
          message="Enter a name for this attachment."
          defaultValue={basename(file.name)}
          onRename={(formData) => onRename(file, formData.name)}
          onClose={() => setRenaming(false)}
        />
      )}
      <div data-testid="attachment-staging-list-item" ref={ref}>
        <ListItem divider>
          <ListItemIcon>
            <MimeTypeIcon mimeType={file.type} />
          </ListItemIcon>
          <ListItemText primary={file.name} />
          <>
            {status === FileStatus.NO_EXTENSION && (
              <Alert data-testid="attachment-staging-list-item-alert-missing-ext" className={classes.alert} severity="error">
                Missing extension
              </Alert>
            )}
            {status === FileStatus.DROP_CONFLICT && (
              <Alert data-testid="attachment-staging-list-item-alert-duplicate" className={classes.alert} severity="error">
                Duplicated filename
              </Alert>
            )}
            {status === FileStatus.ATTACHMENT_CONFLICT && (
              <Alert data-testid="attachment-staging-list-item-alert-existing" className={classes.alert} severity="warning">
                Filename already exists
              </Alert>
            )}
          </>
          <Box minWidth={200} className={classes.fileStatusActions}>
            <AttachmentStagingItemAction
              data-testid="attachment-staging-list-item-overwrite"
              title="Overwrite"
              action={() => onOverwrite(file)}
              disabled={status !== FileStatus.ATTACHMENT_CONFLICT}
            />
            <AttachmentStagingItemAction
              data-testid="attachment-staging-list-item-rename"
              title="Rename"
              action={() => setRenaming(true)}
              disabled={status === FileStatus.NO_EXTENSION}
            />
            <AttachmentStagingItemAction data-testid="attachment-staging-list-item-remove" title="Remove" action={() => onRemove(file)} />
          </Box>
        </ListItem>
      </div>
    </>
  );
});

AttachmentStagingListItem.displayName = "AttachmentStagingListItem";
export default AttachmentStagingListItem;
