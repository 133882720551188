import { useEffect, useState } from "react";
import { Box, Button, LinearProgress, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import MimeTypeIcon from "fond/widgets/MimeTypeIcon";

const customStyles = (theme: Theme) => {
  return createStyles({
    actionsAndStatus: {
      marginLeft: theme.spacing(1),
    },
    uploadingMessage: {
      color: theme.palette.primary.main,
    },
    uploadingBar: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    uploadingBarBackground: {
      backgroundColor: blue[50],
    },
    successMessage: {
      color: theme.palette.success.main,
    },
    successBar: {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
    successBarBackground: {
      backgroundColor: green[50],
    },
    failureMessage: {
      color: theme.palette.error.main,
    },
    failureBar: {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    failureBarBackground: {
      backgroundColor: red[50],
    },
    pendingMessage: {
      color: theme.palette.warning.main,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /*
   * The file being uploaded.
   */
  file: File;
  /*
   * The progress of the uploaded file.
   */
  progress: number;
  /*
   * Is the upload in an error state.
   */
  error: boolean;
  /**
   * Callback function for when an in progress upload is cancelled.
   */
  onAbort(file: File): void;
  /**
   * Callback function for when a failed or cancelled upload is retried.
   */
  onRetry(file: File): void;
}

interface StatusConfig {
  /*
   * The completed progress bar class.
   */
  progressBarClass: string;
  /*
   * The progress bar background class.
   */
  progressBackgroundClass: string;
  /*
   * The upload status message class.
   */
  messageClass: string | null;
  /*
   * The upload status message content.
   */
  message: string | null;
}

const AttachmentUploadListItem = ({ classes, file, progress, error, onAbort, onRetry }: IProps) => {
  enum UploadStatus {
    FAILED,
    IN_PROGRESS,
    PENDING,
    SUCCESS,
  }

  const [status, setStatus] = useState<UploadStatus>(UploadStatus.PENDING);

  /*
   * Determine the upload status on progress or error flag change.
   */
  useEffect(() => {
    if (error) {
      setStatus(UploadStatus.FAILED);
    } else if (progress === 0) {
      setStatus(UploadStatus.PENDING);
    } else if (progress === 100) {
      setStatus(UploadStatus.SUCCESS);
    } else {
      setStatus(UploadStatus.IN_PROGRESS);
    }
  }, [error, progress]);

  /*
   * Configures the component view depending on the status.
   */
  const statusConfigs = {
    [UploadStatus.PENDING]: {
      progressBarClass: classes.uploadingBar,
      progressBackgroundClass: classes.uploadingBarBackground,
      messageClass: classes.pendingMessage,
      message: "pending",
    },
    [UploadStatus.IN_PROGRESS]: {
      progressBarClass: classes.uploadingBar,
      progressBackgroundClass: classes.uploadingBarBackground,
      messageClass: "", // Shows the 'retry' button instead
      message: "",
    },
    [UploadStatus.SUCCESS]: {
      progressBarClass: classes.successBar,
      progressBackgroundClass: classes.successBarBackground,
      messageClass: classes.successMessage,
      message: "success",
    },
    [UploadStatus.FAILED]: {
      progressBarClass: classes.uploadingBar,
      progressBackgroundClass: classes.uploadingBarBackground,
      messageClass: "", // Shows the 'retry' button instead
      message: "",
    },
  };

  return (
    <ListItem data-testid="attachment-upload-list-item" divider>
      <ListItemIcon>
        <MimeTypeIcon mimeType={file.type} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={<Typography>{file.name}</Typography>}
        secondary={
          <Box pt={0.5}>
            <LinearProgress
              classes={{ barColorPrimary: statusConfigs[status].progressBarClass, colorPrimary: statusConfigs[status].progressBackgroundClass }}
              variant="determinate"
              value={progress}
            />
          </Box>
        }
      />
      <Box className={classes.actionsAndStatus}>
        {status === UploadStatus.FAILED && (
          <Button data-testid="attachment-upload-list-item-retry" onClick={() => onRetry(file)}>
            Retry
          </Button>
        )}
        {status === UploadStatus.PENDING && (
          <Typography data-testid="attachment-upload-list-item-pending" className={statusConfigs[status].messageClass}>
            {statusConfigs[status].message}
          </Typography>
        )}
        {status === UploadStatus.IN_PROGRESS && (
          <Button data-testid="attachment-upload-list-item-cancel" onClick={() => onAbort(file)}>
            Cancel
          </Button>
        )}
        {status === UploadStatus.SUCCESS && (
          <Typography data-testid="attachment-upload-list-item-success" className={statusConfigs[status].messageClass}>
            {statusConfigs[status].message}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
};

AttachmentUploadListItem.displayName = "AttachmentUploadListItem";
export default withStyles(customStyles)(AttachmentUploadListItem);
