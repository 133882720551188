import { Box, css, styled } from "@mui/material";

import { fixSsrWarning } from "fond/utils/css";

export const PaperRightIconWrapper = styled(Box)(
  ({ theme }) => css`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.common.white};

    & > svg {
      height: 20px;
    }
  `
);

export const PaperHeader = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.palette.divider};
    min-height: 54px;

    & > h6.MuiTypography-root:first-child ${fixSsrWarning} {
      line-height: 32px;
    }
  `
);

export const MapContainer = styled(Box)(
  ({ theme }) => `
      position: relative;
      border-radius: ${theme.shape.borderRadius}px;
    `
);
