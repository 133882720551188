import { Mutator } from "final-form";

/** Sets a form value */

export const setValue: Mutator<any, any> = ([name, value], state, { changeValue }) => {
  changeValue(state, name, () => value);
};

/** Clears a form value */
export const clear: Mutator<any> = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};
