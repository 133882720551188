import React from "react";
import { Link, Typography } from "@mui/material";

import { Question } from "./Checkout.styles";

const SalesContact: React.FC = () => (
  <Question>
    <Typography fontWeight="bold">Got a question?</Typography>
    <Typography>
      Our sales team is here to assist you.{" "}
      <Link color="primary" href="mailto:bn.sales@biarrinetworks.com" target="_blank" rel="noopener noreferrer">
        Get in touch
      </Link>{" "}
      with us now.
    </Typography>
  </Question>
);

export default SalesContact;
