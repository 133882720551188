import React from "react";
import { Box, Typography } from "@mui/material";
import { AddressElement } from "@stripe/react-stripe-js";
import { StripeAddressElementOptions } from "@stripe/stripe-js";

import { BillingDetails } from "fond/types/subscriptions";

import { useAddressHandlers } from "./hooks/useAddressHandlers";
import CountryAlertContainer from "./CountryAlertContainer";

interface IProps {
  billingDetails?: BillingDetails;
}

const OrganisationDetails: React.FC<IProps> = ({ billingDetails }: IProps) => {
  const { defaultAddressElementOptions, showCountryWarningMessage, setShowCountryWarningMessage, onAddressChange } = useAddressHandlers();
  const address = billingDetails?.Address;
  const addressElementOptions: StripeAddressElementOptions = {
    ...defaultAddressElementOptions,
    contacts: [
      {
        name: defaultAddressElementOptions.defaultValues?.name ?? "",
        address: {
          line1: address?.Line1 || "",
          line2: address?.Line2 || "",
          city: address?.City || "",
          state: address?.State || "",
          postal_code: address?.PostalCode || "",
          country: address?.Country || "US",
        },
      },
    ],
  };

  return (
    <Box width="50%">
      <Typography fontWeight="bold">Organization</Typography>
      <AddressElement options={addressElementOptions} onChange={onAddressChange} />
      {showCountryWarningMessage && <CountryAlertContainer onClick={() => setShowCountryWarningMessage(false)} />}
    </Box>
  );
};

export default OrganisationDetails;
