import { CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from "amazon-cognito-identity-js";
import camelCase from "lodash/camelCase";

import { getImpersonatedUser } from "fond/impersonate";

/**
 * Check if the cognito user in local storage is currently logged in.
 *
 * @return {Promise<boolean>} true if the current user in local storage is logged in, false if not
 */
export function isLoggedIn(): Promise<boolean> {
  return hasValidSession(getCurrentUser());
}

/**
 * Get the cognito user pool bound to the application.
 *
 * @returns {CognitoUserPool} the cognito user pool bound to the application
 */
export function getUserPool(): CognitoUserPool {
  const poolData: ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? "",
    // Used for specifying a Cognito endpoint override. Can be left blank for default behavior.
    endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
  };
  const userPool = new CognitoUserPool(poolData);
  return userPool;
}

export function signOut(): void {
  const currentUser = getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
  }
}

/**
 * Get the current user of the application from the local storage.
 *
 * @returns {CognitoUser} the user retrieved from local storage
 */
export function getCurrentUser(): CognitoUser | null {
  const cognitoUser = getUserPool().getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.getSession(() => null);
  }

  if (cognitoUser != null) {
    const impersonatedUser = getImpersonatedUser();
    if (impersonatedUser != null) {
      // @ts-expect-error: aws-amplify typing is wrong
      cognitoUser.username = impersonatedUser;
    }
  }

  return cognitoUser;
}

/**
 * Get the cognito user with the specified email.
 * @param {string} email address
 * @returns {CognitoUser} the user with the specified email
 */
export function getUserWithEmail(email: string): CognitoUser {
  return new CognitoUser({
    Username: email,
    Pool: getUserPool(),
  });
}

/**
 * Check if the supplied user has a valid session (is logged in).
 *
 * @param {CognitoUser} a cognito user
 * @return {Promise<Boolean>} resolves to `true` if cognitoUser has a valid session, false if not
 */
export function hasValidSession(cognitoUser: CognitoUser | null): Promise<boolean> {
  return new Promise((resolve, _) => {
    if (cognitoUser != null && !!cognitoUser.getSession) {
      const callback = (...[err, session]: [null, CognitoUserSession] | [Error, null]) =>
        err === null ? resolve(session.isValid()) : resolve(false);
      cognitoUser.getSession(callback);
    }
    resolve(false);
  });
}

/**
 * Returns the JWT access token for the cognito user (providing they have a valid session).
 * @param cognitoUser
 * @returns {Promise<string>} the JWT token
 */

export function getToken(cognitoUser: CognitoUser | null): Promise<string> {
  return new Promise((resolve, reject) => {
    // `getSession` is usually synchronous but occasionally it needs to do a
    // refresh which is asynchronous.
    cognitoUser?.getSession((...[err, session]: [null, CognitoUserSession] | [Error, null]) =>
      session ? resolve(session.getAccessToken().getJwtToken()) : reject(err)
    );
  });
}

/**
 * The user attributes may not have come through to state when components are rendered.
 *
 * This code has been shifted into redux such that user attributes can be accessed from state.cognito.userAttrs
 * the returned attributes are the standard cognito user pool attributes, with camelcase syntax
 * see: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
 *
 * some of the returned properties are
 * {string} email
 * {string} familyName
 * {string} givenName
 * {string} organization
 * {string} subscription
 * {string} errorMsg
 *
 */
export function getAllAttrs(user: CognitoUser): Promise<object> {
  return new Promise(async (resolve, reject) => {
    if (await hasValidSession(user)) {
      user.getUserAttributes((err, result) => {
        if (!err) {
          let userAttrs: { [key: string]: string } = {};
          result?.forEach((a) => {
            userAttrs[camelCase(a.getName())] = a.getValue();
          });
          resolve(userAttrs);
        } else {
          reject(err);
        }
      });
    } else {
      reject(new Error("No session"));
    }
  });
}
