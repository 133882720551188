import { Box, Container, Paper, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import background from "svg_icons/background_centred.svg";

import { FONDLogo } from "fond/svg_icons";

export const PageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",

  [theme.breakpoints.up("sm")]: {
    backgroundImage: `url(${background})`,
    backgroundSize: "2200px 2200px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
}));

export const Content = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(7),
  width: "80vw",
  maxWidth: "500px",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

Content.defaultProps = {
  elevation: 1,
};

export const Description = styled(Box)(({ theme }) => ({
  color: grey[700],
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(4),
}));

export const Logo = styled(FONDLogo)({
  height: 60,
});

export const IconContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const Title: React.FC<React.ComponentProps<typeof Typography>> = (props) => <Typography variant="h2" {...props} />;

export const TopBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Top = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    paddingLeft: "10vw", // 10 on each side to add up to 20 (same as Content)
    paddingRight: "10vw",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}));
