import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MonetizationOn, PriceChange, VerticalSplit, ViewComfy } from "@mui/icons-material";
import { Box } from "@mui/material";

import { useGetReportQuery } from "fond/api";
import { NavigationContainer, NavigationItem } from "fond/widgets";

import { Page } from "./constants";

import { StyledDivider } from "./navigation.styles";

interface IProps {
  page: Page;
  isCreatingReport?: boolean;
}

const ReportNavigation: React.FC<IProps> = ({ page, isCreatingReport = false }) => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();
  const { data: report } = useGetReportQuery(reportId ?? "", { skip: !reportId });

  const handleOnClick = (target: string) => {
    if (target === page || isCreatingReport) return;
    navigate(`/reports/${reportId}/${target}`);
  };

  return (
    <NavigationContainer testId="report-navigation" showAcccount={false}>
      {!isCreatingReport && (
        <Box pt={1}>
          <NavigationItem
            selected={page === Page.OVERVIEW}
            onClick={() => handleOnClick(Page.OVERVIEW)}
            testId="navigation-item-overview"
            icon={<ViewComfy />}
            title="Overview"
          />
          <NavigationItem
            selected={page === Page.COST}
            onClick={() => handleOnClick(Page.COST)}
            testId="navigation-item-cost"
            icon={<MonetizationOn />}
            title="Cost"
          />
          <NavigationItem
            selected={page === Page.REVENUE}
            onClick={() => handleOnClick(Page.REVENUE)}
            testId="navigation-item-revenue"
            icon={<PriceChange />}
            title="Revenue"
          />
          <StyledDivider />
        </Box>
      )}
      <Box py={1}>
        <NavigationItem
          selected={page === Page.SETTINGS}
          onClick={() => handleOnClick(Page.SETTINGS)}
          testId="navigation-item-settings"
          icon={<VerticalSplit />}
          title="Settings"
          disabled={!isCreatingReport && (!report || report.Type === "financial_analytics_imported")}
        />
      </Box>
    </NavigationContainer>
  );
};

export default ReportNavigation;
