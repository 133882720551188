import { useMemo } from "react";
import { TreeItem } from "react-complex-tree";

import { InferredIcon } from "fond/map/Legend";
import { Configuration } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { TreeItemType } from "./SortableList";

import { Label, Row } from "./sortableList.styles";

interface IProps {
  config: Configuration;
  title: string;
  item: TreeItem<TreeItemType>;
  getItemVisibility(item: TreeItem<TreeItemType>): boolean;
}

const ItemTitle = ({ config, item, getItemVisibility, title }: IProps): JSX.Element => {
  const {
    data,
    data: { Type },
  } = item;

  const isVisible = useMemo(() => getItemVisibility(item), [item, getItemVisibility]);

  return (
    <Row isVisible={isVisible} className={`rct-tree-item-title-${data.Type}`}>
      {Type !== "GROUP" && Type !== "MapLayerConfig" && <InferredIcon entity={data as LayerConfig | SublayerConfig | LayerStyle} config={config} />}
      <Label italic={data.Type === "SUBLAYER" && data.FilterConfiguration?.Type === "other"}>{title}</Label>
    </Row>
  );
};

export default ItemTitle;
