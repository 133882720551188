import { Box, Button as BaseButton, FormHelperText, MenuItem, Select, styled, TextField as BaseTextField } from "@mui/material";
import operatorIcons from "svg_icons/style-editor/filter-operator-icons-sprite.svg";

export const MainContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)}`,
  height: "100%",
  overflowY: "auto",
}));

export const CustomHelperText = styled(FormHelperText)`
  font-size: 10;
`;

export const SelectWithIcon = styled(Select<string>)(({ theme }) => ({
  fontSize: 12,
  width: 170,
  background: theme.palette.common.white,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiOutlinedInput-input": {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: "28px",
  },
  "& .MuiSelect-select > svg": {
    height: 16,
    minWidth: 20,
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

export const MenuItemWithIcon = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  paddingLeft: theme.spacing(1.5),
  "& > svg": {
    height: 16,
    minWidth: 20,
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}));

export const Button = styled(BaseButton)<{ withMargin?: boolean }>(({ theme, withMargin }) => ({
  justifyContent: "start",
  fontSize: 12,
  lineHeight: "12px",
  margin: withMargin ? `${theme.spacing(2)} 0` : 0,
  color: theme.palette.text.primary,
  "& svg": {
    color: theme.palette.primary.main,
  },
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.biarri.primary.lightGrey}`,
  borderRadius: 4,
  marginBottom: theme.spacing(1.5),
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const InlineFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  margin: `${theme.spacing(1)} 0`,
  "& > label": {
    marginBottom: 0,
    marginRight: theme.spacing(1.5),
  },
  "& > .MuiInputBase-root": {
    width: 200,
    height: 28,
    overflow: "hidden",
  },
}));

export const OperatorIcon = styled(Box)<{ index: number }>(({ theme, index }) => ({
  height: 10,
  width: 16,
  backgroundImage: `url(${operatorIcons})`,
  backgroundPosition: `0 -${index * 10}px`,
  backgroundRepeat: "no-repeat",
  marginRight: theme.spacing(2),
}));

export const TextField = styled(BaseTextField)(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.common.white,
  margin: 0,
  "& .MuiOutlinedInput-root": {
    height: 28,
    paddingRight: 0,
  },
  "& .MuiOutlinedInput-input": {
    fontSize: 12,
    padding: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  "& .MuiInputAdornment-root p": {
    fontSize: 12,
  },
  "& .MuiInputAdornment-root": {
    marginRight: theme.spacing(1),
  },
}));
