import { useState } from "react";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { StripeAddressElementChangeEvent, StripeAddressElementOptions } from "@stripe/stripe-js";
import { useDebouncedCallback } from "use-debounce";

import { selectCurrentAccount } from "fond/api";

import { IFormData } from "../../CheckoutForm";

interface UseAddressHandlersReturn {
  defaultAddressElementOptions: StripeAddressElementOptions;
  showCountryWarningMessage: boolean;
  setShowCountryWarningMessage: React.Dispatch<React.SetStateAction<boolean>>;
  onAddressChange: (event: StripeAddressElementChangeEvent) => void;
}

export const ACCEPTED_COUNTRIES = ["US"];

export const useAddressHandlers = (): UseAddressHandlersReturn => {
  const form = useForm<IFormData>();
  const accountName = useSelector(selectCurrentAccount)?.Name;
  const [showCountryWarningMessage, setShowCountryWarningMessage] = useState(false);
  const defaultAddressElementOptions: StripeAddressElementOptions = {
    mode: "billing",
    blockPoBox: true,
    allowedCountries: [...ACCEPTED_COUNTRIES],
    display: { name: "organization" },
    defaultValues: {
      name: accountName,
    },
  };

  const handleAddressUpdate = useDebouncedCallback((newValue: StripeAddressElementChangeEvent["value"]["address"]) => {
    form.change("address", newValue);
  }, 500);

  const onInvalidCountryChange = (country: string) => {
    form.change("country", country);
    setShowCountryWarningMessage(!ACCEPTED_COUNTRIES.includes(country));
  };

  const onAddressChange = (event: StripeAddressElementChangeEvent) => {
    const { address } = event.value;
    onInvalidCountryChange(address.country);

    if (event.complete) {
      // extract potentially complete and valid address
      if (ACCEPTED_COUNTRIES.includes(address.country)) handleAddressUpdate(address);
    } else {
      form.change("address", undefined);
    }
  };

  return { defaultAddressElementOptions, showCountryWarningMessage, setShowCountryWarningMessage, onAddressChange };
};
