import React, { useContext } from "react";
import { useParams } from "react-router";
import { Actions, BorderNode, Layout as FlexLayout, Model, TabSetNode } from "flexlayout-react";
import { ReactComponent as Maximise } from "svg_icons/maximise.svg";
import { ReactComponent as Minimise } from "svg_icons/minimise.svg";

import mixpanel from "fond/mixpanel";
import { Widget as WidgetType } from "fond/types";

import { LayoutContext } from "../LayoutProvider";

// import { LayoutUpdateRequestBody, useUpdateLayoutMutation } from "fond/api";
import { iconFactory, ITabProps, titleFactory, widgetFactory } from "./factory";
// import { convertFromModel } from "./helper";

interface RouteParams {
  cityId?: string;
  uuid?: string;
}

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  const { model, mapModel } = useContext(LayoutContext);
  const { cityId, uuid: projectId } = useParams<keyof RouteParams>();
  // const [updateLayout] = useUpdateLayoutMutation();

  /**
   * Callback function for when the layout model changes.
   *
   * For example the user moves tabs or changes layout widths.
   */
  const handleModelChange = (newModel: Model) => {
    // Once we have the ability to have projects open with multiple maps
    // we will need to save the sub layout model changes to DB.
    // if (username && projectId) {
    //   const layouts = model ? convertFromModel(model) : [];
    //   const maps = convertFromModel(newModel);
    //   const layoutUpdateRequestBody: LayoutUpdateRequestBody = {
    //     ProjectID: projectId,
    //     Layouts: layouts,
    //     Maps: maps,
    //   };
    //   updateLayout(layoutUpdateRequestBody);
    // }
  };

  const handleOnRenderTabSet = (tabSetNode: TabSetNode | BorderNode, renderValues: any) => {
    if (model) {
      const subLayoutNode = model.getNodeById(WidgetType.MapLayout) as TabSetNode;
      const isMax = subLayoutNode.isMaximized();

      renderValues.buttons.push(
        // Button style from the flexlayout-react package
        <button
          key={`min_max_${subLayoutNode.getId()}`}
          type="button"
          title={`${isMax ? "Minimize" : "Maximize"} tabset`}
          className="flexlayout__tab_toolbar_button"
          onClick={handleOnMinMax}
        >
          {isMax ? <Minimise /> : <Maximise />}
        </button>
      );
    }
  };

  /**
   * Handles toggling the minimising and maximising of the Map Layout
   */
  const handleOnMinMax = () => {
    if (model) {
      const subLayoutNode = model.getNodeById(WidgetType.MapLayout);

      if (subLayoutNode) {
        model.doAction(Actions.maximizeToggle(subLayoutNode.getId()));
        mixpanel.track("Layout: Maximize/Minimize toggled", { id: subLayoutNode.getId() });
      }
    }
  };

  // SubLayout is shared between projects & city planner projects
  // If neither IDs exist draw nothing.
  if (!projectId && !cityId) return null;

  return mapModel ? (
    <FlexLayout
      font={{ size: "12px" }}
      onModelChange={handleModelChange}
      onRenderTabSet={handleOnRenderTabSet}
      model={mapModel}
      factory={widgetFactory}
      titleFactory={titleFactory}
      iconFactory={iconFactory}
    />
  ) : null;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => null;
