import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import uniqBy from "lodash/uniqBy";

import { SEARCH_KEY } from "fond/constants";
import { useAccountSort } from "fond/hooks/useAccountSort";
import { useContents } from "fond/hooks/useContents";
import { updateSharedAccount } from "fond/projects/redux";
import { AccountBase, Store } from "fond/types";
import { useAppDispatch, useQueryParams } from "fond/utils/hooks";

import Avatar from "../Avatar";

import CollapsibleNavigationItem, { CollapsibleItem } from "./CollapsibleNavigationItem";

import { EllipsisTypography } from "./collapsibleNavigationItem.styles";
import { AccountFilterButton } from "./sharedWithMeNavigationItem.styles";

const TOOLTIP_ENTER_DELAY = 500;

interface SharedWithMeNavigationItemProps extends Omit<React.ComponentProps<typeof CollapsibleNavigationItem>, "nestedItems"> {
  onClick: () => void;
}

const SharedWithMeNavigationItem: React.FC<SharedWithMeNavigationItemProps> = ({ onClick, selected, ...rest }: SharedWithMeNavigationItemProps) => {
  const navigate = useNavigate();
  const selectedAccount = useSelector((state: Store) => state.projects.account);
  const { sharedItems } = useContents();
  const searchQuery = useQueryParams(SEARCH_KEY);
  const dispatch = useAppDispatch();
  const uniqueAccounts = uniqBy(
    sharedItems.map((item) => item.Account),
    "Name"
  );
  const { sortedAccounts } = useAccountSort(uniqueAccounts);

  const handleOnNestedItemClick = (account: AccountBase) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate(`/shared?account=${account.ID}`);
    dispatch(updateSharedAccount(account.ID));
  };

  const nestedItems: CollapsibleItem[] = sortedAccounts.map((account) => ({
    key: account.ID,
    element: (
      <Tooltip title={account.Name} placement="right" enterDelay={TOOLTIP_ENTER_DELAY}>
        <AccountFilterButton onClick={handleOnNestedItemClick(account)} selected={account.ID === selectedAccount && !searchQuery}>
          <Avatar name={account.Name} variant="rounded" sx={{ width: 32, height: 32 }} />
          <EllipsisTypography fontWeight={account.ID === selectedAccount && !searchQuery ? 500 : undefined}>{account.Name}</EllipsisTypography>
        </AccountFilterButton>
      </Tooltip>
    ),
  }));

  return <CollapsibleNavigationItem selected={selected} onClick={onClick} nestedItems={nestedItems} {...rest} />;
};

export default SharedWithMeNavigationItem;
