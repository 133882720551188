import React, { SyntheticEvent, useState } from "react";
import { useLocation } from "react-router";
import { Tab, Tabs } from "@mui/material";

import AccountDetails from "./AccountDetails";
import LicenseDetails from "./LicenseDetails";
import SubscriptionDetails from "./SubscriptionDetails";

import { Container, TabContent } from "../common.styles";

const AccountManagement: React.FC = () => {
  const location = useLocation();

  const tabContents = [
    { label: "Details", stateLocation: "details", value: 0, component: <AccountDetails /> },
    { label: "Subscriptions", stateLocation: "subscription", value: 1, component: <SubscriptionDetails /> },
    { label: "User License", stateLocation: "license", value: 2, component: <LicenseDetails /> },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(tabContents.find((content) => content.stateLocation === location.state?.tab)?.value || 0);

  const handleOnTabSelect = (_: SyntheticEvent<Element, Event>, value: number) => {
    setSelectedTabIndex(value);
  };

  return (
    <Container data-testid="account-management">
      <Tabs onChange={handleOnTabSelect} value={selectedTabIndex}>
        {tabContents.map((tab) => (
          <Tab key={tab.label} label={tab.label} value={tab.value} data-testid={`tab-${tab.value}`} />
        ))}
      </Tabs>
      <TabContent>{tabContents[selectedTabIndex].component}</TabContent>
    </Container>
  );
};

export default AccountManagement;
