import styled from "@emotion/styled";
import { green, grey, red } from "@mui/material/colors";
import ToggleButton from "@mui/material/ToggleButton";

export const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== "hue",
})<{ hue: typeof red | typeof grey | typeof green }>(({ hue }) => ({
  flex: "0 0 auto",

  "&.Mui-selected": {
    backgroundColor: hue[400],
    "&:hover": {
      backgroundColor: hue[600],
    },

    "&:disabled": {
      opacity: 0.5,
    },
  },
}));
