import React, { useMemo } from "react";
import { ColDef, IAggFuncParams } from "@ag-grid-community/core";

import { Schedules, SchedulesData } from "fond/types";
import { currencyValueFormatter } from "fond/widgets/AgGrid";

import { VisualizationTypes } from "../VisualizationTypes";

import RevenueData from "./RevenueData";
import { addressTypeTransformer } from "./transforms";

interface IProps {
  data?: SchedulesData[Schedules.Revenue];
}

const RevenueSchedule: React.FC<IProps> = ({ data }) => {
  const addressTypes: string[] = useMemo(() => [...new Set<string>(data?.map(({ AddressType }) => AddressType))], [data]);
  const phases: string[] = useMemo(() => [...new Set<string>(data?.map(({ Phase }) => Phase))], [data]);
  const pivotData = useMemo(
    () => addressTypeTransformer<SchedulesData[Schedules.Revenue]>(Schedules.Revenue, addressTypes, phases, data),
    [addressTypes, data, phases]
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "AddressType",
        headerName: "AddressType",
        rowGroup: true,
        width: 200,
        minWidth: 200,
        hide: true,
      },
      ...(phases?.map((phase) => ({
        aggFunc: (params: IAggFuncParams) => {
          return params.values[2]; // Index 2 = Cumulative net revenue
        },
        field: phase,
        minWidth: 125,
        width: 125,
        valueFormatter: currencyValueFormatter(),
      })) || []),
    ],
    [phases]
  );

  return <RevenueData columns={columns} gridOptions={VisualizationTypes.Revenue.gridOptions} rowData={pivotData} />;
};

export default RevenueSchedule;
