import { FC } from "react";
import { Box, Grid, ListItem } from "@mui/material";

import { May2023Terms } from "./May2023Terms";

import { Paragraphy, Subtitle, Text, Title } from "./TOSTerms.styles";

const TOSTerms: FC = () => {
  const terms = May2023Terms;

  return (
    <Box>
      <Title>{terms.title}</Title>

      {terms.introduction.map((intro) => {
        return <Paragraphy key={intro}>{intro}</Paragraphy>;
      })}

      {terms.sections.map((section) => {
        return (
          <Box key={section.subtitle}>
            <Subtitle>{section.subtitle}</Subtitle>

            {section.text?.map((sectionText) => {
              return (
                <ListItem key={sectionText}>
                  <Text>{sectionText}</Text>
                </ListItem>
              );
            })}

            {section.clauses?.map((clause) => {
              return (
                <ListItem key={clause.index}>
                  <Grid container>
                    <Grid item xs={1}>
                      <Text>{clause.index}</Text>
                    </Grid>

                    <Grid item xs={11}>
                      {clause.text.map((clauseText) => {
                        return <Text key={clauseText}>{clauseText}</Text>;
                      })}

                      {clause.subclauses?.map((subclause) => {
                        return (
                          <ListItem key={subclause.index}>
                            <Grid container>
                              <Grid item xs={1.5}>
                                <Text>{subclause.index}</Text>
                              </Grid>

                              <Grid item xs={10.5}>
                                <Text>{subclause.text}</Text>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default TOSTerms;
