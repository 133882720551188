import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface SystemErrorDialogProps {
  open: boolean;
  networkError: boolean;
  onClose: () => void;
}

function SystemErrorDialog({ open, networkError, onClose }: SystemErrorDialogProps): React.ReactElement {
  return (
    <Dialog open={open}>
      <div>
        <DialogTitle>{networkError ? "Network error" : "Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>
              {networkError
                ? "It looks like you have encountered a network error. Please check that you're connected to the internet and try again"
                : "It looks like we have hit an unexpected problem. Please try again in a moment."}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SystemErrorDialog;
