import { Chip as MuiChip, styled } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";

export const Chip = styled(MuiChip)(({ theme }) => ({
  width: 90,
  justifyContent: "flex-start",

  "&.status-COMPLETE": {
    backgroundColor: green[50],
    color: theme.palette.success.dark,
    "& svg": {
      color: "inherit",
    },
  },
  "&.status-IN_PROGRESS": {
    backgroundColor: blue[50],
    color: theme.palette.primary.dark,
    "& svg": {
      color: theme.palette.primary.light,
    },
  },
  "&.status-ERROR": {
    backgroundColor: red[50],
    color: theme.palette.error.dark,
    "& svg": {
      color: theme.palette.error.light,
    },
  },
}));
