import React, { useEffect, useState } from "react";
import { Cancel as DismissIcon, RestartAlt as RetryIcon } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";

interface LinearProgressWithLabelProps extends LinearProgressProps {
  title?: string;
  subtext?: string;
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The progress value.
   */
  value?: number;
  /**
   * Callback function to handle the dismiss of a completed progress bar.
   */
  onDismiss?(): void;
  /**
   * Callback function to handle the retry of the operation.
   */
  onRetry?(): void;
  progressBarMarginRight?: number;
}

/**
 * LinearProgressWithLabel component.
 * Implementation taken from the react documentation: https://mui.com/material-ui/react-progress/#linear-with-label
 */
const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({
  "data-testid": dataTestid = "linear-progress-with-label",
  title,
  subtext,
  value,
  onDismiss,
  onRetry,
  progressBarMarginRight,
  ...rest
}: LinearProgressWithLabelProps) => {
  const [showDismiss, setShowDismiss] = useState(false);
  const [showRetry, setShowRetry] = useState(false);

  /**
   * Show the dismiss & retry buttons if callbacks are defined.
   */
  useEffect(() => {
    if (onDismiss !== undefined) {
      setShowDismiss(true);
    } else {
      setShowDismiss(false);
    }

    if (onRetry !== undefined) {
      setShowRetry(true);
    } else {
      setShowRetry(false);
    }
  }, [onDismiss, onRetry]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Box sx={{ flex: "1" }}>
        {title && <Typography variant="h7">{title}</Typography>}
        <LinearProgress variant="determinate" value={value} {...rest} />
        {subtext && (
          <Typography variant="h7" color="primary">
            {subtext}
          </Typography>
        )}
      </Box>
      {value !== undefined && (
        <Box width="30px" ml={1} mr={progressBarMarginRight ?? 4.5}>
          {!showRetry && <Typography variant="h7" color="text.secondary">{`${Math.round(value)}%`}</Typography>}
        </Box>
      )}
      <Box display="flex" justifyContent="end">
        {showRetry && (
          <Tooltip title="Retry">
            <IconButton size="small" onClick={onRetry}>
              <RetryIcon sx={{ fontSize: "16px", color: "primary.main" }} />
            </IconButton>
          </Tooltip>
        )}
        {showDismiss && (
          <Tooltip title="Dismiss">
            <IconButton size="small" onClick={onDismiss}>
              <DismissIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
