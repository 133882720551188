import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IMessage, NotificationBell, NovuProvider, PopoverNotificationCenter } from "@novu/notification-center";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectCurrentAccount, useGetAccountUsersQuery, useGetNotificationSubscriberQuery } from "../api";

import "./Notifications.css";
import { notificationCenterStyles, notificationCenterTheme, WhiteNotificationBell } from "./NotificationInbox.styles";

type NotificationPayloadActor = {
  FirstName: string;
  LastName: string;
  subscriberId: string;
};

const novuApplicationIdentifier = process.env?.REACT_APP_NOVU_APP_ID;

const Footer: React.FC = () => {
  return null;
};

const NotificationInbox: React.FC = () => {
  const { data } = useGetNotificationSubscriberQuery();
  const navigate = useNavigate();
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: users } = useGetAccountUsersQuery(selectedAccount?.ID ?? skipToken);

  if (!data || !novuApplicationIdentifier) {
    return null;
  }

  const onNotificationClick = (message: IMessage) => {
    /**
     * TODO: Remove this if block once the request license notification has the appropriate message.cta.data.url
     */
    if (message?.templateIdentifier === "request-license") {
      const requestorId = (message.payload.Actor as NotificationPayloadActor).subscriberId;
      const userEmail = users?.find(({ User }) => User.ID === requestorId)?.User.Email ?? "";
      const urlParams = new URLSearchParams({ allocateLicense: "true", userId: requestorId, userEmail });
      navigate(`/settings/users?${urlParams}`);
    }

    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  };

  // Called when the 'action' (read: button) is clicked on a notification. This behaves identically
  // to just clicking the notification itself, however we could implement more advanced logic here.
  const onActionClick = (templateIdentifier: string, type: string, message: IMessage) => {
    onNotificationClick(message);
  };

  return (
    <NovuProvider
      subscriberId={data.Subscriber.ID}
      subscriberHash={data.Subscriber.Hash}
      applicationIdentifier={novuApplicationIdentifier}
      styles={notificationCenterStyles}
    >
      <PopoverNotificationCenter
        colorScheme="light"
        onNotificationClick={onNotificationClick}
        onActionClick={onActionClick}
        footer={() => <Footer />}
        theme={{ light: notificationCenterTheme, dark: notificationCenterTheme }}
      >
        {({ unseenCount }) => (
          <WhiteNotificationBell>
            <NotificationBell unseenCount={unseenCount} />
          </WhiteNotificationBell>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};

export default NotificationInbox;
