import React, { useEffect } from "react";
import { Alert, Box, FormHelperText, LinearProgress, Paper, Typography } from "@mui/material";

import { AsyncOperationState } from "fond/async/redux";
import { inputLayerGroups } from "fond/layers";
import { useAppSelector } from "fond/utils/hooks";
import { useStackedNavigationContext } from "fond/widgets";

import { PolygonState } from "../polygon/redux";
import { POLYGON_SELECT_UPLOAD_ID } from "../redux";

const ImportData: React.FC = () => {
  const { activeLayerGroupId } = useAppSelector((state) => state.project);
  const { polygonState } = useAppSelector((state) => state.project.polygon);
  const uploads = useAppSelector((state) => state.project.projects[state.project.projectId].uploads);
  const { clear } = useStackedNavigationContext();

  const activeLayerName = activeLayerGroupId && inputLayerGroups[activeLayerGroupId]?.name;

  const upload = uploads[POLYGON_SELECT_UPLOAD_ID];

  const statusMessageMapping = {
    [AsyncOperationState.executing]: "Uploading data",
    [AsyncOperationState.success]: "Success",
    [AsyncOperationState.failure]: "Upload failed",
  };

  useEffect(() => {
    if (polygonState !== PolygonState.upload && upload.status === AsyncOperationState.success) {
      clear();
    }
  }, [clear, polygonState, upload.status]);

  return (
    <Box data-testid="import-data-panel" px={1}>
      <Typography fontWeight="500">{activeLayerName}</Typography>
      <Paper variant="outlined" sx={{ py: 2, px: 2.75, my: 2 }}>
        {upload.status === AsyncOperationState.executing && (
          <Typography variant="body1" fontWeight={500}>
            In progress
          </Typography>
        )}
        <LinearProgress color={upload.status === AsyncOperationState.failure ? "error" : "primary"} />
        <FormHelperText color="primary" error={upload.status === AsyncOperationState.failure}>
          {statusMessageMapping[upload.status]}
        </FormHelperText>
      </Paper>
      <Alert severity="info">FOND is now processing your data, and it will be visible on the map shortly.</Alert>
    </Box>
  );
};

export default ImportData;
