import { AlignHorizontalCenter, DeviceHub, HolidayVillage, PowerInput } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { EntityState } from "@reduxjs/toolkit";

import { GroupConfig, Layer as LayerType, LayerId as _LayerId, LayerIds as _LayerIds } from "fond/types";
import { LayerConfig, LayerStyle } from "fond/types/ProjectLayerConfig";
import { iterItems } from "fond/utils";

export const InputMethods = {
  upload: "upload",
  polygonSelect: "polygonSelect",
  enterManually: "enterManually",
};

export const inAddress = "input_layers/demand";
export const inStreet = "input_layers/ug_path";
export const inParcel = "input_layers/parcel";
export const inSpan = "input_layers/aer_path";
export const inPole = "input_layers/aer_points";

// TODO: Imports should be updated to use fond/types/layer instead of fond/layers for LayerIds and LayerId
export const LayerIds = _LayerIds;
export type LayerId = _LayerId;

export const FondServiceLayerIds = {
  inParcel: "inParcel",
  inAddress: "inAddress",
  inStreet: "inStreet",
  inSpan: "inSpan",
  inPole: "inPole",
};

export const LayerGroupIds = {
  inAddress: "inAddress",
  inStreet: "inStreet",
  inExchange: "inExchange",
  spanPole: "spanPole",
};

export const fondServiceLayerIdsToLayerIds = {
  [FondServiceLayerIds.inAddress]: LayerIds.inAddress,
  [FondServiceLayerIds.inParcel]: LayerIds.inParcel,
  [FondServiceLayerIds.inStreet]: LayerIds.inStreet,
  [FondServiceLayerIds.inSpan]: LayerIds.inSpan,
  [FondServiceLayerIds.inPole]: LayerIds.inPole,
};

export const toFondServiceLayers = (layers: any) => {
  return {
    Layers: {
      [FondServiceLayerIds.inAddress]: layers[LayerIds.inAddress],
      [FondServiceLayerIds.inParcel]: layers[LayerIds.inParcel],
      [FondServiceLayerIds.inStreet]: layers[LayerIds.inStreet],
      [FondServiceLayerIds.inSpan]: layers[LayerIds.inSpan],
      [FondServiceLayerIds.inPole]: layers[LayerIds.inPole],
    },
  };
};

/**
 * An "input layer" (above) corresponds to a single layer in the Mapbox sense.
 *
 * An input layer group is a set of layers that the user will upload / edit together as
 * a single unit. For example spans and poles are edited together so they form
 * one input layer group. Currently all the other input layer groups are single layers.
 *
 * The `draw` property has three fields:
 *   - initialModeWithFeatures -- when we enter draw mode on this layer group and
 *     there are existing features, enter this mode.
 *   - initialModeNoFeatures -- when we enter draw mode on this layer group and there
 *     are no existing features, enter this mode.
 *   - selectMode -- when in draw mode on this layer group, enter this mode
 *     when the user clicks the 'select' (pen) button.
 */

interface Layer {
  id: LayerId;
  path: string;
  solverParameter: string;
  name: string;
  nameSingular: string;
  geometryType: string;
  isVisible?: boolean;
}

export interface LayerGroup {
  id: string;
  name: string;
  nameSingular: string;
  layers: Layer[];
  inputMethods: any[];
  draw: any;
  snappableLayerIds: LayerId[];
  snappableStyleIds?: string[];
  /**
   * These are the layers you want to be selectable for editing (moving etc.). This is used
   * within snapping to exclude those layers that are snappable but not selectable.
   */
  selectableLayerIds?: string[];
  defaultDrawOpts?: any;
  clobbersDesign?: boolean;
}

const inputLayers: { [key in LayerId]?: Layer } = {
  "input_layers/ug_path": {
    id: "input_layers/ug_path",
    path: "preprocessor/inputs/streets.geojson",
    solverParameter: "street_geometry",
    name: "Underground path",
    nameSingular: "Underground path",
    geometryType: "LineString",
  },
  "input_layers/aer_path": {
    id: "input_layers/aer_path",
    path: "preprocessor/inputs/spans_geometry.geojson",
    solverParameter: "span_geometry",
    name: "Aerial spans",
    nameSingular: "Aerial span",
    geometryType: "LineString",
  },
  "input_layers/aer_points": {
    id: "input_layers/aer_points",
    path: "preprocessor/inputs/pole_geometry.geojson",
    solverParameter: "pole_geometry",
    name: "Poles",
    nameSingular: "Pole",
    geometryType: "Point",
  },
  "input_layers/central_office": {
    id: "input_layers/central_office",
    path: "tier_3_solve/inputs/t3_hub_geometry.geojson",
    solverParameter: "t3_hub_geometry",
    name: "Central offices",
    nameSingular: "Central office",
    geometryType: "Point",
    isVisible: true,
  },
  "input_layers/demand": {
    id: "input_layers/demand",
    path: "preprocessor/inputs/in_demand_point.geojson",
    solverParameter: "address_geometry",
    name: "Addresses",
    nameSingular: "Address",
    geometryType: "Point",
    isVisible: true,
  },
  "input_layers/parcel": {
    id: "input_layers/parcel",
    path: "preprocessor/inputs/parcel_geometry.geojson",
    solverParameter: "parcel_geometry",
    name: "Parcels",
    nameSingular: "Parcel",
    geometryType: "Polygon",
    isVisible: true,
  },
  "output/hub": {
    id: "output/hub",
    name: "Hub",
    nameSingular: "Hub",
    geometryType: "Point",
    path: "tier_3_solve/outputs/hub.geojson",
    solverParameter: "hub",
  },
};

function getLayer(layerId: LayerId): Layer {
  if (inputLayers[layerId] == null) {
    throw new Error(`Layer ${layerId} not found`);
  }
  return inputLayers[layerId]!;
}

export function layerIdToSolverParameter(layerId: LayerId): string {
  return getLayer(layerId).solverParameter;
}

export function solverParameterToLayerId(solverParameter: string): string {
  for (let [, layer] of iterItems(inputLayers)) {
    if (layer.solverParameter === solverParameter) {
      return layer.id;
    }
  }
  throw new Error(`No layer ID for ${solverParameter}`);
}

export const inputLayerGroups: Record<string, LayerGroup> = {
  inStreet: {
    ...getLayer("input_layers/ug_path"),
    id: "inStreet",
    layers: [getLayer("input_layers/ug_path")],
    inputMethods: [InputMethods.polygonSelect, InputMethods.upload, InputMethods.enterManually],
    draw: {
      initialModeWithFeatures: "simple_select",
      initialModeNoFeatures: "draw_line_string",
      selectMode: "simple_select",
    },
    // We can snap streets to poles as well as streets.
    snappableLayerIds: ["input_layers/ug_path", "input_layers/aer_points"],
    selectableLayerIds: ["input_layers/ug_path"],
    defaultDrawOpts: { layerId: "input_layers/ug_path" },
    clobbersDesign: true,
  },
  spanPole: {
    name: "Aerial span and poles",
    id: "spanPole",
    nameSingular: "Span / Pole",
    inputMethods: [InputMethods.polygonSelect, InputMethods.upload, InputMethods.enterManually],
    layers: [getLayer("input_layers/aer_path"), getLayer("input_layers/aer_points")],
    draw: {
      initialModeWithFeatures: "span_pole_select",
      initialModeNoFeatures: "draw_spans",
      selectMode: "span_pole_select",
    },
    snappableLayerIds: ["input_layers/aer_path", "input_layers/aer_points", "input_layers/ug_path"],
    selectableLayerIds: ["input_layers/aer_path", "input_layers/aer_points"],
    defaultDrawOpts: { pointLayerId: "input_layers/aer_points", lineLayerId: "input_layers/aer_path" },
    clobbersDesign: true,
  },
  inExchange: {
    ...getLayer("input_layers/central_office"),
    id: "inExchange",
    layers: [getLayer("input_layers/central_office")],
    inputMethods: [InputMethods.upload, InputMethods.enterManually],
    draw: {
      initialModeWithFeatures: "simple_select",
      initialModeNoFeatures: "draw_point",
      selectMode: "simple_select",
    },
    snappableLayerIds: ["input_layers/central_office"],
    selectableLayerIds: ["input_layers/central_office"],
    defaultDrawOpts: { layerId: "input_layers/central_office" },
    clobbersDesign: true,
  },
  inAddress: {
    ...getLayer("input_layers/demand"),
    id: "inAddress",
    layers: [getLayer("input_layers/demand")],
    inputMethods: [InputMethods.polygonSelect, InputMethods.upload, InputMethods.enterManually],
    draw: {
      initialModeWithFeatures: "simple_select",
      initialModeNoFeatures: "draw_point",
      selectMode: "simple_select",
    },
    snappableLayerIds: ["input_layers/demand"],
    selectableLayerIds: ["input_layers/demand"],
    defaultDrawOpts: { layerId: "input_layers/demand" },
    clobbersDesign: true,
  },
  hub: {
    id: "hub",
    name: "Hubs",
    nameSingular: "Hub",
    layers: [getLayer("output/hub")],
    inputMethods: [],
    draw: {
      initialModeWithFeatures: "hub_select",
      initialModeNoFeatures: "hub_select",
      selectMode: "hub_select",
    },
    snappableLayerIds: ["output/hub", "output/fibre_cable"],
    selectableLayerIds: ["output/hub"],
    clobbersDesign: false,
  },
};

export const inputLayerGroupList = [
  { id: "addresses", name: "Addresses", icon: HolidayVillage, group: inputLayerGroups.inAddress },
  { id: "undergroundPath", name: "Underground path", icon: PowerInput, group: inputLayerGroups.inStreet },
  { id: "aerialSpanAndPoles", name: "Aerial span and poles", icon: AlignHorizontalCenter, group: inputLayerGroups.spanPole },
  { id: "centralOffice", name: "Central office", icon: DeviceHub, group: inputLayerGroups.inExchange },
];

/**
 * Generate an ID for mapbox layers from a "base ID" and an index.
 *
 * This is used because we allow multiple styles for each source (see style.js `styles` object),
 * and each style is a separate mapbox layer. Each of these layer needs a unique ID,
 * but we also wish to be able to get the first layer by its "base ID", e.g. "inStreets".
 * The index is simply the index of the style in the array of styles.
 *
 * It's not totally clear why we need this, but it seems it is only
 * `SourceAndLayers` that relies on there being a layer without the index in
 * the ID.
 */
export function generateMapboxLayerId(baseId: string, index: number): string {
  return index === 0 ? baseId : `${baseId}-${index}`;
}

export enum LayerIDs {
  HIGHLIGHT = "layer-comments-polygon",
  POINT = "layer-comments-point",
  LINE = "layer-comments-lineString",
  ARROW = "layer-comments-arrowLine",
}

export enum LayerConfigIDs {
  HIGHLIGHT = "comments-polygon",
  POINT = "comments-point",
  LINE = "comments-lineString",
  ARROW = "comments-arrowLine",
}

export const CommentLayerConfigIDs: string[] = Object.values(LayerConfigIDs);

/**
 * The Collaboration comment layer definitions
 */
export const commentLayers: LayerType[] = [
  {
    ID: LayerIDs.HIGHLIGHT,
    Type: "CommentLayer",
    LayerKey: "",
    Version: "",
    Geometry: "Polygon",
    Configuration: {
      ID: LayerConfigIDs.HIGHLIGHT,
      Geometry: "Polygon",
      Group: "",
      Label: "",
      LayerKey: "",
      IsVisible: true,
    },
    Attributes: [],
  },
  {
    ID: LayerIDs.POINT,
    Type: "CommentLayer",
    LayerKey: "",
    Version: "",
    Geometry: "Point",
    Configuration: {
      ID: LayerConfigIDs.POINT,
      Geometry: "Point",
      Group: "",
      Label: "",
      LayerKey: "",
      IsVisible: true,
    },
    Attributes: [],
  },
  {
    ID: LayerIDs.LINE,
    Type: "CommentLayer",
    LayerKey: "",
    Version: "",
    Geometry: "LineString",
    Configuration: {
      ID: LayerConfigIDs.LINE,
      Geometry: "LineString",
      Group: "",
      Label: "",
      LayerKey: "",
      IsVisible: true,
    },
    Attributes: [],
  },
  {
    ID: LayerIDs.ARROW,
    Type: "CommentLayer",
    LayerKey: "",
    Version: "",
    Geometry: "LineString",
    Configuration: {
      ID: LayerConfigIDs.ARROW,
      Geometry: "LineString",
      Group: "",
      Label: "",
      LayerKey: "",
      IsVisible: true,
    },
    Attributes: [],
  },
];

/**
 * The Collaboration comment layer config definitions - We define these as an
 * EntityState to be consistent with the layerConfig format used throughout
 * the application.
 */
export const commentLayerConfigs: EntityState<LayerConfig> = {
  ids: CommentLayerConfigIDs,
  entities: {
    [LayerConfigIDs.HIGHLIGHT]: {
      ID: LayerConfigIDs.HIGHLIGHT,
      Label: "Highlighted areas",
      IsVisible: true,
      Styles: ["comments-polygon-line-style", "comments-polygon-fill-style"],
      Position: 0,
      Type: "CommentConfig",
      GeometryType: "Point",
      GlobalPosition: 0,
      ParentID: "projectComments",
      Key: "comments-source",
      MaxZoomLevel: 24,
      MinZoomLevel: 0,
      Children: [],
    },
    [LayerConfigIDs.POINT]: {
      ID: LayerConfigIDs.POINT,
      Label: "Pinned locations",
      IsVisible: true,
      Styles: ["comments-point-style"],
      Position: 0,
      Type: "CommentConfig",
      GeometryType: "Point",
      GlobalPosition: 0,
      ParentID: "projectComments",
      Key: "comments-source",
      MaxZoomLevel: 24,
      MinZoomLevel: 0,
      Children: [],
    },
    [LayerConfigIDs.LINE]: {
      ID: LayerConfigIDs.LINE,
      Label: "Line",
      IsVisible: true,
      Styles: ["comments-lineString-style"],
      Position: 0,
      Type: "CommentConfig",
      GeometryType: "Point",
      GlobalPosition: 0,
      ParentID: "projectComments",
      Key: "comments-source",
      MaxZoomLevel: 24,
      MinZoomLevel: 0,
      Children: [],
    },
    [LayerConfigIDs.ARROW]: {
      ID: LayerConfigIDs.ARROW,
      Label: "Arrow",
      IsVisible: true,
      Styles: ["comments-arrowLine-1-style", "comments-arrowLine-2-style", "comments-arrowLine-3-style", "comments-arrowLine-4-style"],
      Position: 0,
      Type: "CommentConfig",
      GeometryType: "Point",
      GlobalPosition: 0,
      ParentID: "projectComments",
      Key: "comments-source",
      MaxZoomLevel: 24,
      MinZoomLevel: 0,
      Children: [],
    },
  },
};

/**
 * The GroupConfig that comments are listed under in the legend.
 */
export const commentGroupConfig: GroupConfig = {
  ID: "projectComments",
  Type: "GROUP",
  Label: "Project Comments",
  Key: null,
  Children: commentLayerConfigs.ids as string[],
  IsVisible: true,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
};

export const commentStyles: LayerStyle[] = [
  {
    ID: "comments-point-style",
    Name: "comments-point",
    GlobalPosition: 0,
    ConfigurationID: LayerConfigIDs.POINT,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "circle",
      filter: ["==", ["get", "Type"], "point"],
      paint: {
        "circle-color": ["case", ["==", ["get", "State"], "resolved"], green[600], "#E1373B"],
        "circle-radius": 8,
        "circle-stroke-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", "#FFFFFF"],
        "circle-stroke-width": 2,
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-polygon-line-style",
    Name: "comments-polygon-line",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.HIGHLIGHT,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      filter: ["==", ["get", "Type"], "polygon"],
      paint: {
        "line-dasharray": [2, 2],
        "line-width": 2,
        "line-color": [
          "case",
          ["all", ["==", ["feature-state", "isSelected"], true]],
          "#FFFF00",
          ["all", ["==", ["get", "State"], "resolved"]],
          green[600],
          ["all", ["==", ["get", "State"], "open"]],
          "#E1373B",
          // below is a catch all
          "#888888",
        ],
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-polygon-fill-style",
    Name: "comments-polygon-fill",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.HIGHLIGHT,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: ["==", ["get", "Type"], "polygon"],

      paint: {
        "fill-opacity": 0.1,
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", "#888888"],
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-lineString-style",
    Name: "comments-lineString",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.LINE,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      filter: ["==", ["get", "Type"], "lineString"],
      paint: {
        "line-dasharray": [2, 2],
        "line-width": 2,
        "line-color": [
          "case",
          ["all", ["==", ["feature-state", "isSelected"], true]],
          "#FFFF00",
          ["all", ["==", ["get", "State"], "resolved"]],
          green[600],
          ["all", ["==", ["get", "State"], "open"]],
          "#E1373B",
          // below is a catch all
          "#888888",
        ],
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-arrowLine-1-style",
    Name: "comments-arrowLine-1",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.ARROW,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      filter: ["==", ["get", "Type"], "arrowLine"],
      type: "line",
      paint: {
        "line-dasharray": [2, 2],
        "line-width": 2,
        "line-color": [
          "case",
          ["all", ["==", ["feature-state", "isSelected"], true]],
          "#FFFF00",
          ["all", ["==", ["get", "State"], "resolved"]],
          green[600],
          ["all", ["==", ["get", "State"], "open"]],
          "#E1373B",
          // below is a catch all
          "#888888",
        ],
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-arrowLine-2-style",
    Name: "comments-arrowLine-2",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.ARROW,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      filter: ["==", ["get", "Type"], "arrowLine"],
      type: "symbol",
      paint: {
        // TODO: Once we have icon sdf support we can remove these styles and instead control the color of the icon
        "icon-opacity": ["case", ["all", ["==", ["get", "State"], "open"], ["!=", ["feature-state", "isSelected"], true]], 1, 0],
      },
      layout: {
        "icon-image": "open-arrow",
        "symbol-placement": "line",
        "icon-allow-overlap": true,
        "symbol-spacing": 40,
        "icon-size": 0.6,
        visibility: "visible",
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-arrowLine-3-style",
    Name: "comments-arrowLine-3",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.ARROW,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      filter: ["==", ["get", "Type"], "arrowLine"],
      type: "symbol",
      paint: {
        // TODO: Once we have icon sdf support we can remove these styles and instead control the color of the icon
        "icon-opacity": ["case", ["all", ["==", ["get", "State"], "resolved"], ["!=", ["feature-state", "isSelected"], true]], 1, 0],
      },
      layout: {
        "icon-image": "resolved-arrow",
        "symbol-placement": "line",
        "icon-allow-overlap": true,
        "symbol-spacing": 40,
        "icon-size": 0.6,
        visibility: "visible",
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: "comments-arrowLine-4-style",
    Name: "comments-arrowLine-4",
    GlobalPosition: 1,
    ConfigurationID: LayerConfigIDs.ARROW,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      filter: ["==", ["get", "Type"], "arrowLine"],
      type: "symbol",
      paint: {
        // TODO: Once we have icon sdf support we can remove these styles and instead control the color of the icon
        "icon-opacity": ["case", ["all", ["==", ["feature-state", "isSelected"], true]], 1, 0],
      },
      layout: {
        "icon-image": "selected-arrow",
        "symbol-placement": "line",
        "icon-allow-overlap": true,
        "symbol-spacing": 40,
        "icon-size": 0.6,
        visibility: "visible",
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
];

export const commentsConfig: Array<GroupConfig | LayerConfig | LayerStyle> = [
  commentGroupConfig,
  ...(Object.values(commentLayerConfigs.entities) as LayerConfig[]),
  ...commentStyles,
];

/**
 * The GroupConfig that comments are listed under in the legend.
 */
const multiProjectGroupConfigs: GroupConfig[] = [
  {
    ID: "multiProjectGroupSubAreas",
    Type: "GROUP",
    Label: "Subareas",
    Key: null,
    Children: [],
    IsVisible: true,
    GlobalPosition: 1,
    Position: 1,
    ParentID: null,
    RootID: null,
  },
];

export const multiProjectConfig: Array<GroupConfig | LayerConfig | LayerStyle> = multiProjectGroupConfigs;
