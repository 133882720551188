import React from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, useGetAccountSubscriptionsQuery } from "fond/api";
import { useUpgradePlanner } from "fond/hooks/useUpgradePlanner";
import { HeaderBox, UsageBox } from "fond/settings/AccountManagement/common.styles";
import SubscriptionInfoList from "fond/settings/AccountManagement/SubscriptionInfoList";
import SubscriptionUsage from "fond/settings/AccountManagement/SubscriptionUsage";
import PricingTableButton from "fond/views/Payment/PricingTable/PricingTableButton";

import RenewButton from "./RenewButton";

const SubscriptionDetails: React.FC = () => {
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(selectedAccount?.ID ?? skipToken, { refetchOnMountOrArgChange: true });
  const { canUpgrade } = useUpgradePlanner();

  return canUpgrade ? (
    <>
      <Typography variant="h3" marginBottom={2}>
        Limited access to your account.
      </Typography>
      <Typography marginBottom={3}>
        Looks like you don't have an active subscription yet. Upgrade to a planner subscription and get access to all our premium features.
      </Typography>

      <PricingTableButton
        renderButton={(onClick) => (
          <Button variant="contained" onClick={onClick}>
            Upgrade now
          </Button>
        )}
      />
    </>
  ) : (
    <>
      <HeaderBox>
        <Typography variant="h3" mt={2} data-testid="subscription-header">
          Subscription Details
        </Typography>
        <RenewButton category="subscription" />
      </HeaderBox>
      <UsageBox>
        <SubscriptionUsage subscriptions={subscriptions} />
      </UsageBox>
      <SubscriptionInfoList subscriptions={subscriptions} />
    </>
  );
};

export default SubscriptionDetails;
