import { Grid, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FeatureCollection } from "geojson";

import { getFeatureProperties } from "fond/project/addressTypes/helper";

import { Alert, AlertTitle } from "../panels/InputDataPanel.styles";

interface IProps {
  featureCollection: FeatureCollection;
  identifyUnknownAddressTypes: (featureCollection: FeatureCollection, propertyField: string) => void;
}

const MissingAddressTypeFieldAlert: React.FC<IProps> = ({ featureCollection, identifyUnknownAddressTypes }: IProps) => {
  const featureProperties = getFeatureProperties(featureCollection);

  return (
    <Alert severity="warning" data-testid="address-type-alert">
      <AlertTitle data-testid="alert-header">Address type field not found</AlertTitle>
      <>
        <Typography variant="body3">Specify the Address type field on your data to classify addresses in the demand model.</Typography>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Select
            sx={{ width: "100%", height: 40, backgroundColor: (theme) => theme.palette.common.white, my: 1 }}
            defaultValue=""
            data-testid="address-item-list"
          >
            {featureProperties.map((property: string) => (
              <MenuItem value={property} key={property} onClick={() => identifyUnknownAddressTypes(featureCollection, property)}>
                {property}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </>
    </Alert>
  );
};

export default MissingAddressTypeFieldAlert;
