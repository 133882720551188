import React, { useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Alert, IconButton, Tooltip, Typography } from "@mui/material";

import { ResourceEntity } from "fond/types";
import { bulkPermissionCheck } from "fond/utils/permissions";
import { ConfirmModal } from "fond/widgets";

interface IProps {
  /**
   * The current datagrid row item selection.
   */
  selectedItems: ResourceEntity[];
  /**
   * Callback function for when the delete button is clicked.
   */
  onDelete?(items: ResourceEntity[]): void;
}

const ProjectListToolbarActions: React.FC<IProps> = ({ selectedItems, onDelete }: IProps) => {
  const [confirmModal, showConfirmModal] = React.useState<boolean>(false);
  const [deletableItems, nonDeletableItems] = useMemo(() => bulkPermissionCheck(selectedItems, "DELETE"), [selectedItems]);

  return (
    <>
      {/* 
        // TODO - Future additional actions
        {onMove && (<></>)} 
      */}
      {onDelete && (
        <>
          <Tooltip title="Delete">
            <span>
              <IconButton aria-label="delete" onClick={() => showConfirmModal(true)} color="primary" data-testid="delete-button">
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
          <ConfirmModal
            data-testid="confirm-delete-modal"
            header="Delete"
            confirmText="Delete"
            open={confirmModal}
            onConfirm={() => {
              onDelete(deletableItems);
            }}
            onCancel={() => showConfirmModal(false)}
            disabled={deletableItems.length === 0}
            content={
              <div className="content">
                <Typography variant="body2">Are you sure you want to delete the selected items?</Typography>
                {nonDeletableItems.length > 0 && (
                  <Alert severity="warning" sx={{ mt: 1 }}>
                    The following will not be deleted as you do not have the appropriate permissions:
                    <ul>
                      {nonDeletableItems.map((item) => (
                        <li key={item.ID}>{item.EntityType === "project" ? item.ProjectName : item.Name}</li>
                      ))}
                    </ul>
                  </Alert>
                )}
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default ProjectListToolbarActions;
