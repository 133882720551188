import { Box, FormHelperText, styled } from "@mui/material";

export const ConformMessage = styled(FormHelperText)({
  marginLeft: 0,
  fontSize: 10,
});

export const Strength = styled(Box)(({ theme }) => ({
  position: "relative",
  height: 3,
  background: "#DADADA",
  marginTop: theme.spacing(1),
  borderRadius: 2,
  "&:before, &:after": {
    content: '""',
    height: "inherit",
    background: "transparent",
    display: "block",
    borderColor: theme.palette.common.white,
    borderStyle: "solid",
    borderWidth: `0 ${theme.spacing(1)} 0`,
    position: "absolute",
    width: `calc(20% + ${theme.spacing(1)})`,
    zIndex: 10,
  },
  "&:before": {
    left: "calc(20% - 3px)",
  },
  "&:after": {
    right: "calc(20% - 3px)",
  },
}));

export const Meter = styled(Box)<{ strength?: number }>(({ strength }) => {
  const indicator: { [key: number]: string } = {
    0: "#BA0004",
    1: "#E1373B",
    2: "#FFA242",
    3: "#85C188",
    4: "#4CAF50",
  };

  return {
    backgroundColor: strength !== undefined ? indicator[strength] : "transparent",
    height: "inherit",
    position: "absolute",
    width: strength !== undefined ? `${(strength + 1) * 20}%` : 0,
    borderRadius: "inherit",
    transition: "width 0.5s ease-in-out, background 0.25s",
  };
});

export const Labels = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
