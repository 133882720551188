import React, { useState } from "react";
import { Form } from "react-final-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Lock } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { AWSError } from "aws-sdk";

import { LoadingButton } from "ui";

import { TextField } from "fond/form/fields";
import { CognitoResponseCode } from "fond/utils";
import { required } from "fond/utils/validation";
import { SystemErrorDialog } from "fond/widgets";

interface IFormData {
  Password: string;
}

const ForgotPasswordSubmitForm: React.FC = () => {
  const navigate = useNavigate();
  const [cognitoResponseCode, setCognitoResponseCode] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const code = searchParams.get("code");

  const handleOnSubmit = async ({ Password }: IFormData) => {
    return new Promise((resolve) => {
      if (email && code) {
        Auth.forgotPasswordSubmit(email, code, Password)
          .then(() => {
            Auth.signIn(email, Password).then(() => {
              resolve("success");
              navigate("/");
            });
          })
          .catch((error: AWSError) => {
            resolve({
              Password: error.message,
            });
            setCognitoResponseCode(error.code);
          });
      }
    });
  };

  return (
    <>
      <SystemErrorDialog
        open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
        networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
        onClose={() => {
          setCognitoResponseCode(null);
        }}
      />

      <Form<IFormData>
        initialValues={{
          Password: "",
        }}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                {cognitoResponseCode === CognitoResponseCode.EXPIRED_CODE ? (
                  <>
                    <Alert severity="error" sx={{ mt: 4 }}>
                      <AlertTitle>Expired</AlertTitle>
                      The forgotten password link you were emailed has expired.
                    </Alert>
                    <Button color="primary" component={Link} to="/forgotpassword" sx={{ mt: 4 }}>
                      Request a new password link
                    </Button>
                  </>
                ) : (
                  <>
                    <Box>
                      <TextField
                        name="Password"
                        type="password"
                        label="New Password"
                        showPasswordStrength
                        validate={required}
                        InputProps={{
                          startAdornment: <Lock color="action" />,
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 6 }}>
                      <LoadingButton
                        loading={submitting}
                        data-testid="reset-password-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Change password and sign in
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          );
        }}
      />

      <Box sx={{ mt: 4, textAlign: "right" }}>
        <Button color="primary" component={Link} to="/signin">
          Back to Log In
        </Button>
      </Box>
    </>
  );
};

export default ForgotPasswordSubmitForm;
