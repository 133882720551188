import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { IconButton, IconButtonProps, IconProps } from "@mui/material";

interface IProps extends ICellRendererParams {
  icon: React.ElementType<IconProps>;
  iconButtonProps?: IconButtonProps;
  iconProps?: object;
}

const IconButtonCellRenderer: React.FC<IProps> = memo(({ value, icon: Icon, iconProps, iconButtonProps }: IProps) => {
  return (
    <IconButton size="small" {...iconButtonProps}>
      <Icon {...iconProps} />
    </IconButton>
  );
});
IconButtonCellRenderer.displayName = "IconButtonCellRenderer";

export default IconButtonCellRenderer;
