import React, { useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Lock } from "@mui/icons-material";
import { Alert, Box } from "@mui/material";
import { AWSError } from "aws-sdk";
import { FORM_ERROR } from "final-form";

import { LoadingButton } from "ui";

import { changePassword } from "fond/cognito/redux";
import { MIN_PASSWORD_LENGTH } from "fond/constants";
import { TextField } from "fond/form/fields";
import { AppThunkDispatch, Store } from "fond/types";
import { compose, equal, minChars, notEqual, required } from "fond/utils/validation";
import { VisibilityAdornment } from "fond/widgets";

interface IFormData {
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}

const ChangePasswordForm: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const user = useSelector((state: Store) => state.cognito.user);
  const [hideCurrent, setHideCurrent] = useState(true);
  const [success, setSuccess] = useState(false);
  const [hideNew, setHideNew] = useState(true);
  const [hideConfirm, setHideConfirm] = useState(true);

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ CurrentPassword, NewPassword }: IFormData) => {
    return new Promise((resolve) => {
      dispatch<Promise<Record<string, string>>>(changePassword(user, CurrentPassword, NewPassword))
        .then(() => {
          setSuccess(true);
          resolve({});
        })
        .catch((error: AWSError) => {
          if (error.code === "NotAuthorizedException") {
            resolve({ CurrentPassword: error.message });
          } else {
            // Fallback is to raise a form error
            resolve({ [FORM_ERROR]: error.message });
          }
          setSuccess(false);
        });
    });
  };

  return (
    <Form<IFormData>
      initialValues={{
        CurrentPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      }}
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitting, submitError, form: { restart } }) => {
        return (
          <form
            onSubmit={async (event?: React.SyntheticEvent) => {
              const result = await handleSubmit(event);
              if (result && Object.keys(result).length === 0) {
                // On successful submit reset the form
                restart();
              }
            }}
          >
            <Box sx={{ width: 475 }}>
              <Box>
                <TextField
                  name="CurrentPassword"
                  label="Your current password"
                  type={hideCurrent ? "password" : "text"}
                  validate={required}
                  InputProps={{
                    startAdornment: <Lock color="action" />,
                    endAdornment: <VisibilityAdornment isHidden={hideCurrent} update={setHideCurrent} />,
                  }}
                />
              </Box>

              <FormSpy
                subscription={{
                  values: true,
                }}
                render={({ values }) => (
                  <>
                    <Box mt={2}>
                      <TextField
                        name="NewPassword"
                        label="New password"
                        type={hideNew ? "password" : "text"}
                        validate={compose(
                          required,
                          minChars(MIN_PASSWORD_LENGTH),
                          notEqual(values.CurrentPassword, "Your new password must be different to your current password.")
                        )}
                        InputProps={{
                          startAdornment: <Lock color="action" />,
                          endAdornment: <VisibilityAdornment isHidden={hideNew} update={setHideNew} />,
                        }}
                        showPasswordStrength
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                        name="ConfirmPassword"
                        label="Confirm new password"
                        type={hideConfirm ? "password" : "text"}
                        validate={equal(values.NewPassword, "Confirmation password must match new password.")}
                        InputProps={{
                          startAdornment: <Lock color="action" />,
                          endAdornment: <VisibilityAdornment isHidden={hideConfirm} update={setHideConfirm} />,
                        }}
                      />
                    </Box>
                  </>
                )}
              />
              {submitError && (
                <Box mt={2}>
                  <Alert data-testid="change-password-error-message" severity="error">
                    {submitError}
                  </Alert>
                </Box>
              )}
              {success && (
                <Box mt={2}>
                  <Alert data-testid="change-password-success-message" severity="success">
                    Your password has been changed.
                  </Alert>
                </Box>
              )}
              <Box mt={2}>
                <LoadingButton data-testid="change-password-button" variant="contained" color="primary" type="submit" loading={submitting}>
                  Change password
                </LoadingButton>
              </Box>
            </Box>
          </form>
        );
      }}
    />
  );
};

export default ChangePasswordForm;
