import { get } from "lodash";

import { PlannerFeatures } from "fond/types";

/**
 * Find the preferred label from the fieldLabels based on the planner feature and the path.
 */
export const getPreferredLabel = (plannerFeature: PlannerFeatures | undefined, path: string[]): string | null => {
  if (!plannerFeature) return null;
  const preferredLabels = fieldLabels[plannerFeature];
  return get(preferredLabels, path);
};

const fieldLabels: { [feature in PlannerFeatures]: any } = {
  FlexNap: {
    Tier1: {
      Hubs: {
        Title: "Drop Terminals",
        Sizes: "Terminal port count",
        Type: "Terminal type",
        SplitRatio: "Terminal split ratio",
      },
    },
    Tier2: {
      ConnectorizedDropTerminals: {
        Title: "Consolidated Terminal Tails",
        Enabled: "Use consolidated terminal tails",
        TailsPerSplice: "Number of tails per splice location",
      },
      Hubs: {
        Title: "Distribution Hubs/Cabinets",
        Sizes: "Hub/Cabinet port counts",
        UnsplitPorts: "Unsplit/Pass-through fiber ports",
      },
    },
  },
};
