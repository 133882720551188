import { LayerFilterOption } from "fond/map/Legend/Adornments/LayerFilter";
import { ConfigurationUpsert } from "fond/types";
import { FilterConfiguration, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { FamilialLayerConfig, groupConfigTemplate, layerConfigTemplate, sublayerTemplate } from "./configuration";

export enum OverlayIds {
  ROOT = "multiProjectOverlayGroup",
  FCC = "multiProjectFccGroup",
}

export enum FccLayerId {
  ATT_SERVICE_TYPE = "fcc-att_service_type-service-types",
  FCC_FIBER_FOOTPRINT = "fcc-fiber-footprint",
  FCC_MAX_DOWNLOAD = "fcc-max-download",
  TMOB_SERVICE_TYPE = "fcc-tmob_service_type-service-types",
  VERIZON_SERVICE_TYPE = "fcc-verizon_service_type-service-types",
}

export const fccLayerFilterOptions: LayerFilterOption[] = [
  { ID: "Broadband", Label: "Broadband", Type: "Category" },
  { ID: FccLayerId.FCC_FIBER_FOOTPRINT, Label: "Fiber Footprint", Type: "Option" },
  { ID: FccLayerId.FCC_MAX_DOWNLOAD, Label: "Max Download Speeds", Type: "Option" },
  { ID: "Coverage", Label: "Coverage", Type: "Category" },
  { ID: FccLayerId.ATT_SERVICE_TYPE, Label: "AT&T Coverage", Type: "Option" },
  { ID: FccLayerId.TMOB_SERVICE_TYPE, Label: "T-Mobile Coverage", Type: "Option" },
  { ID: FccLayerId.VERIZON_SERVICE_TYPE, Label: "Verizon Coverage", Type: "Option" },
];

export const defaultFccExcludedLayerIds = [
  FccLayerId.FCC_FIBER_FOOTPRINT,
  FccLayerId.ATT_SERVICE_TYPE,
  FccLayerId.VERIZON_SERVICE_TYPE,
  FccLayerId.TMOB_SERVICE_TYPE,
];

const key = "census-tracts";

const fccMaxDownloadBuckets: { id: string; label: string; color: string; filter: FilterConfiguration }[] = [
  {
    id: "fcc-max-download-0-25",
    label: "0 - 25",
    color: "#d53e4f",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 0], ["<", ["get", "max_download"], 25]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-25-100",
    label: "25 - 100",
    color: "#f96a00",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 25], ["<", ["get", "max_download"], 100]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-100-500",
    label: "100 - 500",
    color: "#f5c802",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 100], ["<", ["get", "max_download"], 500]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-500-1000",
    label: "500 - 1000",
    color: "#c9e816",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 500], ["<", ["get", "max_download"], 1000]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-1000+",
    label: "1000+",
    color: "#4dd53e",
    filter: {
      Mapbox: [">=", ["get", "max_download"], 1000],
      Type: "expression",
    },
  },
];

const technologies = [
  { technology: "Fiber", color: "#80b1d3" },
  { technology: "Cable", color: "#bebada" },
  { technology: "Power", color: "#b3de69" },
  { technology: "Satellite", color: "#fdb462" },
  { technology: "Wireless", color: "#fb8072" },
  { technology: "DSL", color: "#8dd3c7" },
  { technology: "Copper", color: "#ffffb3" },
];

/**
 * Create the FCC overlay configuration.
 */
export const fccOverlayConfiguration = (): ConfigurationUpsert => {
  const { config: fiberFootprintLayerConfig, descendants: fiberFootprintDescendants } = createFiberFootprintLayer();
  const { config: maxDownloadLayerConfig, descendants: maxDownloadDescendants } = createMaxDownloadLayer();
  const { config: attLayerConfig, descendants: attDescendants } = createProviderLayer("AT&T Coverage", "att_service_type");
  const { config: tmobLayerConfig, descendants: tmobDescendants } = createProviderLayer("T-Mobile Coverage", "tmob_service_type");
  const { config: verizonLayerConfig, descendants: verizonDescendants } = createProviderLayer("Verizon Coverage", "verizon_service_type");

  // Assemble everything together into one group.
  const layerConfigs = [fiberFootprintLayerConfig, maxDownloadLayerConfig, attLayerConfig, tmobLayerConfig, verizonLayerConfig];
  const descendants = [...fiberFootprintDescendants, ...maxDownloadDescendants, ...attDescendants, ...tmobDescendants, ...verizonDescendants];

  // Create the groups
  const fccGroup = groupConfigTemplate(OverlayIds.FCC, layerConfigs, "National Broadband Map", true, OverlayIds.ROOT);
  const overlayGroup = groupConfigTemplate(OverlayIds.ROOT, [fccGroup], "Overlays", false);

  // Assemble everything together
  return [...descendants, ...layerConfigs, overlayGroup, fccGroup];
};

/**
 * Create the provider (e.g. AT&T, T-Mobile) layer having service type sublayers.
 */
const createProviderLayer = (label: string, providerField: string): FamilialLayerConfig => {
  const layerConfigId = `fcc-${providerField}-service-types`;

  const descendants: (LayerStyle | SublayerConfig)[] = technologies.flatMap(({ technology, color }) => {
    const id = `${layerConfigId}-${technology}`;
    const filter = {
      Mapbox: ["in", technology, ["get", providerField]],
      Type: "expression" as const,
    };
    const styles = maxDownloadStyles(id, color, filter, 0.8);
    const subLayer = sublayerTemplate(id, key, layerConfigId, technology, filter, [...styles]);
    return [...styles, subLayer];
  });

  const config = layerConfigTemplate(
    layerConfigId,
    key,
    label,
    [],
    descendants.filter(({ Type }) => Type === "SUBLAYER").map(({ ID }) => ID),
    OverlayIds.FCC
  );

  return { config, descendants };
};

/**
 * Create the max download speed layer.
 */
const createMaxDownloadLayer = (): FamilialLayerConfig => {
  // Use the buckets to make sublayers for the max download speed layer
  const descendants = fccMaxDownloadBuckets.flatMap((bucket) => {
    const styles = maxDownloadStyles(bucket.id, bucket.color, bucket.filter, 0.3);
    const sublayerConfig = sublayerTemplate(bucket.id, key, FccLayerId.FCC_MAX_DOWNLOAD, bucket.label, bucket.filter, styles);
    return [...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    FccLayerId.FCC_MAX_DOWNLOAD,
    key,
    "Max Download Speed",
    [],
    fccMaxDownloadBuckets.map((bucket) => bucket.id),
    OverlayIds.FCC
  );

  return { config, descendants };
};

const maxDownloadStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity ?? 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];

/**
 * Create the Fiber Footprint layer.
 */
const createFiberFootprintLayer = (): FamilialLayerConfig => {
  const descendants = fiberFootprintStyles(FccLayerId.FCC_FIBER_FOOTPRINT, "#4dd53e");
  const config = layerConfigTemplate(FccLayerId.FCC_FIBER_FOOTPRINT, key, "Fiber Footprint", [...descendants], [], OverlayIds.FCC);

  return { config, descendants };
};

const fiberFootprintStyles = (layerId: string, color: string): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: "fcc-fiber-served-polygon-fill",
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: ["==", ["get", "fiber_served"], "True"],
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 0.3],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];
