import * as React from "react";
import { DescriptionOutlined, FolderZipOutlined, Image, InsertDriveFileOutlined } from "@mui/icons-material";
import { SvgIconOwnProps } from "@mui/material";

interface IProps {
  /**
   * The mimetype of the file whose icon is to be displayed.
   */
  mimeType: string;
  color?: SvgIconOwnProps["color"];
  fontSize?: SvgIconOwnProps["fontSize"];
  defaultIcon?: React.ReactNode;
}

const MimeTypeIcon: React.FC<IProps> = ({ mimeType, color = "primary", fontSize = "medium", defaultIcon }: IProps) => {
  let type;
  let subtype;
  const splitMimeType = mimeType.split("/");
  if (splitMimeType.length === 2) {
    [type, subtype] = splitMimeType;
  }

  /**
   * TODO: Neaten up the implementation but keep it easy to add new mime type icons.
   *
   * Andrew has suggested keeping a lookup mapping and implement such that the changes may be contained within that mapping.
   * For example, using something like:
   *
   * const iconLookup = {
   *   "text/*": Description,
   *   "image/*": Image,
   *   "application/zip": FolderZipOutlined,
   *   "application/pdf": DescriptionOutlined
   *   "application/*": Folder,
   *   "*": InsertDriveFile
   * }
   */
  switch (type) {
    case "text":
      switch (subtype) {
        default:
          return <DescriptionOutlined color={color} fontSize={fontSize} />;
      }
    case "image":
      switch (subtype) {
        default:
          return <Image color={color} fontSize={fontSize} />;
      }
    case "application":
      switch (subtype) {
        case "zip":
        case "x-zip-compressed": {
          return <FolderZipOutlined color={color} fontSize={fontSize} />;
        }
        case "pdf": {
          return <DescriptionOutlined color={color} fontSize={fontSize} />;
        }
        default:
          return defaultIcon ?? <InsertDriveFileOutlined color={color} fontSize={fontSize} />;
      }
    default:
      return defaultIcon ?? <InsertDriveFileOutlined color={color} fontSize={fontSize} />;
  }
};

export default MimeTypeIcon;
