export const GET_ATTACHMENTS_REQUEST = "GET_ATTACHMENTS_REQUEST";
export const GET_ATTACHMENTS_SUCCESS = "GET_ATTACHMENTS_SUCCESS";
export const GET_ATTACHMENTS_FAILURE = "GET_ATTACHMENTS_FAILURE";
export const UPDATE_ATTACHMENTS_REQUEST = "UPDATE_ATTACHMENTS_REQUEST";
export const UPDATE_ATTACHMENT_SUCCESS = "UPDATE_ATTACHMENT_SUCCESS";
export const UPDATE_ATTACHMENT_FAILURE = "UPDATE_ATTACHMENT_FAILURE";
export const DELETE_ATTACHMENTS_REQUEST = "DELETE_ATTACHMENTS_REQUEST";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE";
export const UPLOAD_ATTACHMENTS_REQUEST = "UPLOAD_ATTACHMENTS_REQUEST";
export const UPLOAD_ATTACHMENT_ABORT = "UPLOAD_ATTACHMENT_ABORT";
export const UPLOAD_ATTACHMENT_REQUEST = "UPLOAD_ATTACHMENT_REQUEST";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";
export const UPLOAD_ATTACHMENT_FAILURE = "UPLOAD_ATTACHMENT_FAILURE";
export const UPLOAD_ATTACHMENT_PROGRESS_UPDATE = "UPLOAD_ATTACHMENT_PROGRESS_UPDATE";
export const DOWNLOAD_ATTACHMENTS_REQUEST = "DOWNLOAD_ATTACHMENTS_REQUEST";
export const DOWNLOAD_ATTACHMENTS_SUCCESS = "DOWNLOAD_ATTACHMENTS_SUCCESS";
export const DOWNLOAD_ATTACHMENTS_FAILURE = "DOWNLOAD_ATTACHMENTS_FAILURE";

export * from "./actions";
export * from "./reducer";
export * as AttachmentActionTypes from "./types";
