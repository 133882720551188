import { Button as MuiButton, Paper as MuiPaper, styled, Theme, ToggleButton as MuiToggleButton } from "@mui/material";

export const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
}));

const buttonStyles = ({ theme }: { theme: Theme }) => ({
  color: theme.palette.biarri.secondary.darkGrey,
  border: "none",
  height: 32,
  width: 32,
  minWidth: 32,
  padding: 0,

  "& svg": {
    width: 24,
  },

  "&&&": {
    borderRadius: `${theme.shape.borderRadius}px`,
  },

  "&.MuiButton-root:hover, &.MuiToggleButton-root:hover": {
    color: theme.palette.primary.main,
  },

  "&.MuiToggleButton-root.Mui-disabled": {
    border: "none",
  },

  "&&.Mui-selected, &&.Mui-selected.MuiButton-root:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
});

export const Button = styled(MuiButton)(buttonStyles);

export const ToggleButton = styled(MuiToggleButton)(buttonStyles);
