import { Typography } from "@mui/material";

import { ConfirmModal } from "fond/widgets";

interface IProps {
  setModalOpen: (status: boolean) => void;
  onConfirm: () => void;
}

const DemandConfirmUpdate: React.FC<IProps> = ({ setModalOpen, onConfirm }: IProps): JSX.Element => {
  return (
    <ConfirmModal
      data-testid="confirm-changes-dialogbox"
      className="confirm-remove-current-design"
      open
      header="Confirm update"
      content={
        <div className="content">
          <Typography variant="body2">You've made changes to the demand model.</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Saving this architecture will re-apply the demand model & <strong>overwrite</strong> any existing manual edits.
          </Typography>
        </div>
      }
      onConfirm={onConfirm}
      onCancel={() => {
        setModalOpen(false);
      }}
    />
  );
};

export default DemandConfirmUpdate;
