import React from "react";
import { useSelector } from "react-redux";
import { Alert, AlertTitle, Button, Paper } from "@mui/material";

import { useCustomWorkflow } from "fond/project/panels/SmallDesign/useCustomWorkflow";
import { Store } from "fond/types";
import { LinearProgressWithLabel } from "fond/widgets";

const GenerateDesignPanel: React.FC = () => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { workflowState, workflowProgress, runWorkflow } = useCustomWorkflow(versionId, "design_generation");

  const handleGenerateDesign = async () => {
    if (!runWorkflow) {
      return;
    }
    runWorkflow();
  };

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
        onClick={handleGenerateDesign}
        disabled={!runWorkflow || workflowState === "IN_PROGRESS"}
      >
        Generate Design
      </Button>
      {workflowState === "IN_PROGRESS" && (
        <Paper variant="outlined" sx={{ py: 2, px: 2.75, mt: 2 }}>
          <LinearProgressWithLabel
            color="primary"
            value={(workflowProgress ?? 0) * 100}
            progressBarMarginRight={0}
            title="Generating design"
            subtext="Processing..."
          />
        </Paper>
      )}
      {workflowState === "COMPLETE" && (
        <Alert severity="success" sx={{ mt: 4 }}>
          Design complete
        </Alert>
      )}
      {workflowState === "ERROR" && (
        <Alert severity="error" sx={{ mt: 4 }}>
          <AlertTitle>Design generation failed</AlertTitle>A design could not be generated.
        </Alert>
      )}
    </>
  );
};

export default GenerateDesignPanel;
