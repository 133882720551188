const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

/*
 * Default material design snackbar settings.
 */
const defaultOptions = {
  autoHideDuration: 4000,
  anchorOrigin: { vertical: "bottom", horizontal: "center" },
};

/**
 * Adds a notification to be displayed.
 *
 * @param string message
 * @param {*} options an object containing any of the props listed here: https://material-ui.com/api/snackbar/ i.e.
 *  { autoHideDuration: 10000, anchorOrigin: {...} } and so on.
 */
export function addNotification(message, options = {}) {
  return {
    type: ADD_NOTIFICATION,
    message,
    options,
  };
}

/**
 * Remove the notification with this key from the store.
 */
export function removeNotification(key) {
  return {
    type: REMOVE_NOTIFICATION,
    key,
  };
}

export function reducer(state = [], action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      let key = new Date().getTime() + Math.random(); // each notification is given a unique key.
      return [
        {
          message: action.message,
          options: { ...defaultOptions, ...action.options },
          key,
        },
        ...state,
      ];

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.key !== action.key);

    default:
      return state;
  }
}
