import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Actions, Model, TabNode } from "flexlayout-react";

import { widgetFactory } from "./widgets/factory";
import { LayoutContext } from "./LayoutProvider";
import { FloatingWindow } from "./Window";

interface IProps {
  model: Model;
}

const FloatingWindows: React.FC<IProps> = ({ model }: IProps) => {
  const { windowIds, setWindowIds } = useContext(LayoutContext);
  // We need a reference here to allow the current value to be
  // accessed within the handleOnClose
  const windowIdsRef = useRef(windowIds);

  useEffect(() => {
    windowIdsRef.current = windowIds;
  }, [windowIds]);

  /**
   * Callback for handling the closing of a popout
   */
  const handleOnClose = useCallback((id: string) => {
    model.doAction(Actions.selectTab(id));
    setWindowIds(windowIdsRef.current.filter((nodeId) => nodeId !== id));
  }, []);

  /**
   * Callback for handling the load event of the popup window
   */
  const handleSetWindow = (id: string, window: Window) => {
    const node = model.getNodeById(id) as TabNode;
    node.getExtraData().window = window;
  };

  /**
   * Calculates the bounding box & opening position of the floating window
   */
  const getScreenRect = (node: TabNode) => {
    const rect = node.getRect().clone();
    const currentWindow = window.document.defaultView;
    if (currentWindow) {
      const navHeight = Math.min(80, currentWindow.outerHeight - currentWindow.innerHeight);
      const navWidth = Math.min(80, currentWindow.outerWidth - currentWindow.innerWidth);
      rect.x = rect.x + currentWindow.screenX + navWidth;
      rect.y = rect.y + currentWindow.screenY + navHeight;
    }
    return rect;
  };

  return (
    <>
      {windowIds.map((id) => {
        const node = model.getNodeById(id) as TabNode;
        if (!node) return null;

        return (
          <FloatingWindow
            key={node.getId()}
            id={node.getId()}
            url="/widget"
            title={node.getName()}
            onCloseWindow={handleOnClose}
            onSetWindow={handleSetWindow}
            rect={getScreenRect(node)}
          >
            {widgetFactory(node, true)}
          </FloatingWindow>
        );
      })}
    </>
  );
};

export default FloatingWindows;
