import React from "react";
import { useField } from "react-final-form";

import { STYLE_EDITOR_MAX_ZOOM, STYLE_EDITOR_MIN_ZOOM } from "fond/constants";
import { TextField } from "fond/form/fields";
import { compose, maximum, minimum } from "fond/utils/validation";

import { useCustomStyles } from "../FieldFactory";

import ZoomChart from "./ZoomChart";

import { Container } from "./minMaxZoomField.styles";

interface IProps {
  defaultValue?: string;
  fieldSpec: any;
  name: string;
}

const MinMaxZoomField: React.FC<IProps> = ({ defaultValue = undefined, fieldSpec, name }: IProps) => {
  const {
    input: { value, onChange },
  } = useField(name);
  const classes = useCustomStyles();

  return (
    <Container data-testid={`${name}-minmaxZoom-field`}>
      Zoom Range
      <ZoomChart value={value || defaultValue} onChange={onChange} />
      <TextField
        key={name}
        name={name}
        autoFocus
        type="number"
        inputProps={{
          min: fieldSpec?.minimum,
          max: fieldSpec?.maximum,
        }}
        validate={compose(minimum(STYLE_EDITOR_MIN_ZOOM), maximum(STYLE_EDITOR_MAX_ZOOM))}
        className={classes.textField}
        classes={{
          root: classes.textFieldRoot,
        }}
        placeholder={`${defaultValue}`}
        fieldProps={{
          parse: (val) => (!val ? null : parseFloat(val)),
        }}
      />
    </Container>
  );
};

export default MinMaxZoomField;
