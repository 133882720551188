import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: 1,
  height: "100%",
}));

export const TabPanel = styled(Box)(({ theme }) => ({
  height: "100%",
  overflow: "hidden",
}));
