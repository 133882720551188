import React, { createRef, RefObject, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import _ from "lodash";

import { relativeDate } from "fond/utils/dates";
import { useOnScreen } from "fond/utils/hooks";
import { BlockSpinner } from "fond/widgets";

import DataGridCell from "./DataGridCell";
import DataGridRowGroup from "./DataGridRowGroup";
import DataGridToolbar from "./DataGridToolbar";

const ROW_HEIGHT = 53;
const INFINITE_SCROLL_PAGE_SIZE = 50;

export type SortDirection = "asc" | "desc";

export interface ColDef {
  /**
   * Set the alignment of the column.
   * @default "left"
   */
  align?: "left" | "right";
  /**
   * The column identifier. It's used to map with [[RowData]] values.
   */
  field: string;
  /**
   * The title of the column rendered in the column header cell.
   */
  headerName?: string;
  /**
   * Sets the padding within the table cell
   */
  padding?: "none" | "normal" | "checkbox";
  /**
   * Set the width of the column.
   * @default 100
   */
  width?: number | string;
  /**
   * If `true`, the column is sortable.
   * @default true
   */
  sortable?: boolean;
  /**
   * Sort the rows in a specific direction.  Note that we only support single column sorting at this stage
   */
  sortDirection?: SortDirection;
  /**
   * Allows the value of the cell to be dynamically set rather than based off a set row value
   */
  valueGetter?(row: RowModel): string | number;
  /**
   * Allows the value of the cell to be formatted when displayed to the user
   */
  valueFormatter?(value: string | number): string | number;
  /**
   * Allows the rendering of the cell value to be customised
   */
  renderCell?(row: RowModel): React.ReactNode;
}

const customStyles = (theme: Theme) => {
  return createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      flex: "1 1 100%",
    },
    headerText: {
      whiteSpace: "nowrap",
    },
    tableContainer: {
      flex: "1",
      overflowY: "auto",
    },
  });
};

/**
 * The key value object representing the data of a row.
 */
export interface RowModel {
  [key: string]: any;
}

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Determines if rows can be selected
   * @default false
   */
  allowRowSelection?: boolean;
  /**
   * Adjusts the height of the grid to the number of rows currently being show.
   * If set to false empty rows will be displayed to match the "Rows per page" size
   * @default true
   */
  autoHeight?: boolean;
  /**
   * Defines the columns within the table
   */
  columns: ColDef[];
  /**
   * Displays when the DataGrid is empty.
   */
  emptyStateComponent?: React.ReactNode;
  /**
   * Displays a BlockSpinner when loading.
   */
  loading?: boolean;
  /**
   * Determines if pagination should be used or infinite scrolling
   * @default true
   */
  paging?: boolean;
  /**
   * Determines if the Date Grouping headers should be shown
   * @default false
   */
  showDateGroups?: boolean;
  /**
   * The Toolbar title text content. Currently only shows if allowRowSelection is true
   */
  title?: string;
  /**
   * Toolbar content to be displayed above the datagrid
   */
  toolbar?: React.ReactNode;
  /**
   * The number of rows per page
   * @default 25
   */
  rowsPerPage?: number;
  /**
   * Sets options in the 'rows per page' pagination dropdown.
   * @default [5, 10, 25]
   */
  rowsPerPageOptions?: number[];
  /**
   * Sets the rows of type RowProps
   */
  rows: RowModel[];
  /**
   * Sets the actions in the selection toolbar when allowRowSelection is enabled.
   */
  selectionToolbarActions?: React.ReactNode;
  /**
   * Allows the datagrid orderBy to be customised.
   */
  orderBy?(rows: RowModel[], fieldName: string, direction: SortDirection): RowModel[];
  /**
   * Callback function for when a cell is clicked
   */
  onCellClick?(params: { row: RowModel; column: ColDef }): void;
  /**
   * Callback function for when a row selection is made.
   */
  onRowSelection?(selection: string[]): void;
  /**
   * The search text resulting to filtered items
   */
  searchText?: string;
}

/**
 * See https://material-ui.com/components/tables/#sorting-amp-selecting
 */
const DataGrid: React.FC<IProps> = ({
  allowRowSelection = false,
  autoHeight = true,
  classes,
  columns,
  emptyStateComponent = <Typography variant="caption">We couldn't find anything!</Typography>,
  loading = false,
  onCellClick,
  onRowSelection,
  orderBy = _.orderBy,
  paging = true,
  rows,
  rowsPerPage: defaultPageSize = 25,
  rowsPerPageOptions = [5, 10, 25],
  selectionToolbarActions = null,
  showDateGroups = false,
  title = "",
  toolbar,
  searchText,
}: IProps) => {
  const sortBy = columns.find((column) => column.sortDirection);
  const [orderDirection, setOrderDirection] = useState<SortDirection>(sortBy?.sortDirection || "asc");
  const [orderByField, setOrderByField] = useState<string>(sortBy?.field || "");
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [loadingRef, setLoadingRef] = useState<RefObject<HTMLElement>>(createRef<HTMLDivElement>());
  const numSelected = selected.length;
  const rowCount = rows.length;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const displayedDateGroups = new Set();

  /**
   * Monitor the onScreen hook for infinite loading
   * Note that for infinite loading to work correct the page size needs to be larger
   * than the users window otherwise the onScreen flag does not switch states
   */
  const onLoadingRefChange = (node: HTMLElement | null) => {
    if (loadingRef?.current !== node) {
      setLoadingRef({ ...loadingRef, current: node });
    }
  };

  useOnScreen(loadingRef, (entry) => {
    if (!paging && entry.isIntersecting && rowsPerPage < rows.length) {
      setRowsPerPage(rowsPerPage + INFINITE_SCROLL_PAGE_SIZE);
    }
  });

  useEffect(() => {
    if (selected.length > 0) {
      const rowIds = rows.map((row) => row.ID);
      setSelected(selected.filter((id) => rowIds.includes(id)));
    }
  }, [rows]);

  useEffect(() => {
    if (onRowSelection) {
      onRowSelection(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [searchText]);

  /**
   * Helper function to support getting object properties based on dot notation
   * e.g. field = "Account.Name"
   */
  const getDescendantProp = (obj: any, path: string) => path.split(".").reduce((acc, part) => acc && acc[part], obj);

  /**
   * Renders the cell content within a DataGridCell based on if the ColDef includes a renderCell()
   */
  const renderCell = (params: { column: ColDef; row: RowModel }) => {
    const { column, row } = params;
    const value = column.valueGetter?.(row) !== undefined ? column.valueGetter?.(row) : getDescendantProp(row, column.field);
    const formatted = column.valueFormatter?.(value) || value;

    return (
      <DataGridCell
        data-testid="datagrid-cell"
        key={`${row.ID}_${column.field}`}
        padding={allowRowSelection ? "none" : column.padding}
        align={column.align}
        onClick={() => onCellClick?.({ row, column })}
      >
        {column.renderCell ? column.renderCell(row) : formatted}
      </DataGridCell>
    );
  };

  /**
   * Renders a Row Group component used when showDateGroups is true.
   * Row items are placed into headers based on a date (e.g. Today / Yesterday / Last Week etc)
   */
  const renderRowGroups = (date: string) => {
    const label = relativeDate(`${date}`);

    if (!displayedDateGroups.has(label)) {
      displayedDateGroups.add(label);
      return <DataGridRowGroup colSpan={columns.length + 1} title={label} />;
    }

    return null;
  };

  /**
   * Callback function for when a row is clicked / selected / deselected
   */
  const handleOnRowClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (allowRowSelection) {
      const selectedIndex = selected.indexOf(id);
      const newSelections: string[] = [...selected];

      if (selectedIndex === -1) {
        // Add row to selections
        newSelections.push(id);
      } else if (selectedIndex > -1) {
        // Remove row from selections
        newSelections.splice(selectedIndex, 1);
      }

      setSelected(newSelections);
    }
  };

  /**
   * Callback function for when the table header Select All checkbox is clicked
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => `${row.ID}`);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  /**
   * Callback function for when the user changes the page
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /**
   * Callback function for when the user changes the page size
   */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Handles the change in column sort
   */
  const handleRequestSort = (property: Extract<keyof RowModel, string>) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderByField === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderByField(property);
  };

  /**
   * Indicates if the current item is selected
   */
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <Box position="relative" display="flex" flexDirection="column" height="100%">
      {allowRowSelection ? (
        <DataGridToolbar title={title} selected={numSelected} actions={selectionToolbarActions} />
      ) : (
        <>{toolbar && <Toolbar className={classes.toolbar}>{toolbar}</Toolbar>}</>
      )}
      {
        /* Display a spinner when loading. */
        loading && (
          <BlockSpinner
            containerProps={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              backgroundColor: "rgba(255,255,255, 0.5)",
              zIndex: 1000,
            }}
          />
        )
      }
      <TableContainer className={classNames(classes.tableContainer, "customScrollbars")}>
        <Table>
          <TableHead>
            <TableRow>
              {allowRowSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "Select all rows" }}
                  />
                </TableCell>
              )}
              {columns.map(({ field, headerName, sortable = true, width = 100, align = "left" }) => (
                <TableCell key={field} width={width} align={align}>
                  {sortable ? (
                    <TableSortLabel
                      active={orderByField === field}
                      direction={orderByField === field ? orderDirection : "asc"}
                      onClick={handleRequestSort(field)}
                    >
                      <span className={classes.headerText}>{headerName}</span>
                      {orderByField === field ? (
                        <span className={classes.visuallyHidden}>{orderDirection === "desc" ? "sorted descending" : "sorted ascending"}</span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <span className={classes.headerText}>{headerName}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              /* Display the empty state component if loading completes and there are no rows. */ !loading && rows.length === 0 && (
                <TableRow style={{ height: ROW_HEIGHT * (autoHeight ? 1 : emptyRows) }}>
                  <TableCell colSpan={columns.length + (allowRowSelection ? 1 : 0)} style={{ textAlign: "center" }}>
                    {emptyStateComponent}
                  </TableCell>
                </TableRow>
              )
            }

            {
              /* Sort and display each table row when loaded. */
              orderBy(rows, orderByField, orderDirection)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowModel) => {
                  const isItemSelected = isSelected(row.ID);

                  return (
                    <React.Fragment key={row.ID}>
                      {showDateGroups && renderRowGroups(row.LastModified as string)}
                      <TableRow
                        hover
                        onClick={(event) => handleOnRowClick(event, row.ID)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        data-testid="datagrid-row"
                      >
                        {allowRowSelection && (
                          <TableCell padding="checkbox">
                            <Checkbox data-testid="datagrid-selection-checkbox" color="primary" checked={isItemSelected} />
                          </TableCell>
                        )}
                        {columns.map((column) => renderCell({ column, row }))}
                      </TableRow>
                    </React.Fragment>
                  );
                })
            }

            {
              /* Fill any empty rows with whitespace to make up the rowsPerPage row height. */
              !loading && emptyRows > 0 && !autoHeight && rows.length > 0 && (
                <TableRow style={{ height: ROW_HEIGHT * (rows.length > 0 ? emptyRows : emptyRows - 1) }}>
                  <TableCell colSpan={columns.length + (allowRowSelection ? 1 : 0)} />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      {paging ? (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{ MenuProps: { id: "pagination-select-menu" } }}
        />
      ) : (
        <>
          {rowsPerPage <= rows.length ? (
            <div ref={onLoadingRefChange}>
              <BlockSpinner />
            </div>
          ) : (
            <Box display="flex" justifyContent="center" py={2}>
              <Typography variant="caption">No more rows</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default withStyles(customStyles)(DataGrid);
