import { extname } from "fond/fileValidation";
import { BulkFileProps, FeatureDetails } from "fond/types";

export enum FileStatus {
  READY,
  NO_EXTENSION,
  NOT_ALLOWED,
  DROP_CONFLICT,
  ATTACHMENT_CONFLICT,
  CANNOT_ACCEPT,
}

export interface mappingValidationOutput {
  isValid: boolean;
  validatedFile: ValidatedMappingFile;
}

export interface ValidatedMappingFile {
  file: File;
  status: FileStatus;
}

export interface validationOutput {
  isValid: boolean;
  validatedFiles: ValidatedFile[];
}

export interface ValidatedFile {
  file: File;
  status: FileStatus;
  isFirstInvalid: boolean;
  error: string[];
  featureDetails?: FeatureDetails;
}

export interface MappingData {
  featurePropertyKey: string;
  filename: string;
  featurePropertyValue: string;
  layerName: string;
  description?: string;
}

export const mappingFileConfig = {
  headers: [
    {
      name: "LayerName",
      inputName: "layerName",
      required: true,
      requiredError: function requiredError(headerName: string, rowNumber: number, columnNumber: number): string {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
    {
      name: "FeaturePropertyKey",
      inputName: "featurePropertyKey",
      required: true,
      requiredError: function requiredError(headerName: string, rowNumber: number, columnNumber: number): string {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
    {
      name: "FeaturePropertyValue",
      inputName: "featurePropertyValue",
      required: true,
      requiredError: function requiredError(headerName: string, rowNumber: number, columnNumber: number): string {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
    {
      name: "Description",
      inputName: "description",
      required: false,
    },
    {
      name: "Filename",
      inputName: "filename",
      required: true,
      requiredError: function requiredError(headerName: string, rowNumber: number, columnNumber: number): string {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
  ],
  isHeaderNameOptional: false,
};

export const validateMappingFile = (file: File): mappingValidationOutput => {
  let isValid = true;
  let status;
  const extension = extname(file.name);
  if (!extension) {
    status = FileStatus.NO_EXTENSION;
  } else if (extension !== "csv") {
    status = FileStatus.NOT_ALLOWED;
  } else {
    status = FileStatus.READY;
  }

  if (isValid && status !== FileStatus.READY) {
    isValid = false;
  }

  return {
    isValid,
    validatedFile: {
      file,
      status,
    },
  };
};

export const validateFiles = (files: BulkFileProps[], mappingData: MappingData[], versionId: string): validationOutput => {
  const stagedFiles = files.map((file: BulkFileProps) => file.file);

  let isValid = true;
  const validatedFiles = stagedFiles.map((file, index) => {
    const mappingDataForFile = mappingData.find((item) => item.filename === file.name);
    let status: FileStatus;
    let error: string[] = [];

    // Check for duplicates by comparing with all other files in the staged list
    const isDuplicate = stagedFiles.some((targetFile, i) => i !== index && targetFile.name === file.name);

    if (!extname(file.name)) {
      status = FileStatus.NO_EXTENSION;
    } else if (isDuplicate) {
      status = FileStatus.DROP_CONFLICT;
      error.push("Duplicate file detected");
    } else if (!mappingDataForFile) {
      status = FileStatus.CANNOT_ACCEPT;
      error.push("File not found in mapping file");
    } else {
      status = FileStatus.READY;
    }

    let isFirstInvalid = false;
    if (isValid && status !== FileStatus.READY) {
      isValid = false;
      isFirstInvalid = true;
    }

    const validatedFile: ValidatedFile = {
      file,
      status,
      isFirstInvalid,
      error,
    };

    if (mappingDataForFile) {
      validatedFile.featureDetails = {
        ProjectVersionID: versionId,
        LayerKey: mappingDataForFile.layerName,
        PropertyKey: mappingDataForFile.featurePropertyKey,
        PropertyValue: mappingDataForFile.featurePropertyValue,
      };
    }

    return validatedFile;
  });

  return {
    isValid,
    validatedFiles,
  };
};
