import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Add as AddIcon } from "@mui/icons-material";
import { Divider, FormHelperText } from "@mui/material";

import { AttributeType } from "fond/types/attributes";

import { FormLabel } from "../styles";

import TextPartsItem from "./TextPartsItem";
import validate from "./validate";

import { Button, Container, FieldWrapper } from "./textPartsField.styles";

export interface Attribute {
  name: string;
  type: AttributeType | null;
}

interface Transformation {
  Operation: string;
  Value?: number | "";
  MinFractionDigits?: number | "";
  MaxFractionDigits?: number | "";
  Position?: number;
}

export interface Value {
  Literal: string | null;
  AttributeName: string | null;
  Transformations: Transformation[];
  Position?: number;
}

interface IProps {
  attributes?: Attribute[];
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
}

type TextPartsFieldWrapperProps = FieldRenderProps<Value[], HTMLElement>;

const TextPartsFieldWrapper: React.FC<TextPartsFieldWrapperProps> = ({
  input: { name, onChange, value },
  meta: { error },
  attributes,
}: TextPartsFieldWrapperProps) => {
  const addLabelValue = () => {
    const newLabelValue: Value = { Literal: null, AttributeName: null, Transformations: [] };
    onChange([...value, newLabelValue]);
  };

  const handleLabelValueChange = (index: number, newValue: Value) => {
    const updatedValue = [...value];
    updatedValue[index] = newValue;
    onChange(updatedValue);
  };

  const removeLabelValue = (index: number) => {
    const updatedValue = [...value];
    updatedValue.splice(index, 1);

    onChange(updatedValue);
  };

  const textValuePreview = value && value.map((item) => item.Literal || (item.AttributeName && `{Attr: ${item.AttributeName}}`) || "").join("");

  return (
    <Container className="customScrollbars" data-testid={`${name}-textParts-field`}>
      <FormLabel sx={{ marginBottom: 0 }}>Label Value</FormLabel>
      <FormHelperText>Label your layer with plain text or from an attribute value</FormHelperText>
      {value &&
        value.map((labelValue, index) => (
          <TextPartsItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data={labelValue}
            attributes={attributes}
            onChange={(newValue: Value) => handleLabelValueChange(index, newValue)}
            deleteLabelValue={() => removeLabelValue(index)}
            error={error && error[index]}
          />
        ))}
      <Button data-testid="add-label-btn" startIcon={<AddIcon />} size="small" onClick={() => addLabelValue()} withMargin>
        Add label value
      </Button>
      <Divider sx={{ my: 1 }} />
      <FieldWrapper>
        <FormLabel>
          Preview:&nbsp;
          <span data-testid="label-value-preview">{textValuePreview}</span>
        </FormLabel>
      </FieldWrapper>
    </Container>
  );
};

const TextPartsField: React.FC<IProps> = ({ name, fieldProps, attributes = [], ...rest }: IProps) => (
  <Field
    name={name}
    render={({ input, meta }) => <TextPartsFieldWrapper input={input} meta={meta} name={name} attributes={attributes} {...rest} />}
    validate={validate}
    {...fieldProps}
  />
);

export default TextPartsField;
