import { WarningAmber } from "@mui/icons-material";
import { Alert as BaseAlert, IconButton, styled } from "@mui/material";

export const Alert = styled(BaseAlert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: 20,
  width: "100%",
}));

Alert.defaultProps = {
  icon: <WarningAmber />,
  severity: "warning",
};

export const ShowWarningButton = styled(IconButton)(({ theme }) => ({
  // We use absolute positioning to place the icon next to the "Overview" header
  position: "absolute",
  top: -4,
  left: 125,
}));
