import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { INVITATION_ID_KEY, INVITATION_TOKEN_KEY } from "fond/constants";
import { useQueryParams } from "fond/utils/hooks";

import { NavbarButtonHelper } from "../signIn/signInForm.styles";

const SignInAdornment: React.FC = () => {
  const invitationId = useQueryParams(INVITATION_ID_KEY);
  const inviteToken = useQueryParams(INVITATION_TOKEN_KEY);
  // keeping the invite details in the URL so that we can correctly link back to signup if necessary
  const signInUrl = invitationId && inviteToken ? `/signin?${INVITATION_ID_KEY}=${invitationId}&${INVITATION_TOKEN_KEY}=${inviteToken}` : "/signin";

  return (
    <>
      <NavbarButtonHelper>Already have an account?</NavbarButtonHelper>
      <Button variant="outlined" component={Link} to={signInUrl} data-testid="sign-in-link" sx={{ ml: 2, textTransform: "uppercase" }}>
        Log in
      </Button>
    </>
  );
};

export default SignInAdornment;
