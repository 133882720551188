import React, { useMemo, useState } from "react";
import { AnyObject, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { AddBox as AddBoxIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import {
  selectLayerLabelByLayerKey,
  selectLayersByVersionId,
  selectVersionsByProjectId,
  useCreateExportMutation,
  useGetAccountModulesQuery,
  useGetLayersQuery,
  useGetVersionStatusQuery,
} from "fond/api";
import { CheckboxField } from "fond/form/fields";
import { LayerId, LayerIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project/redux";
import { ExportFormats, getExportFormat, Layer, LayerWithLabel, Project, SplitByTier, Store, Version } from "fond/types";
import { accountModuleCheck, Actions } from "fond/utils/permissions";
import { required } from "fond/utils/validation";
import { Message as MessageWidget, Modal } from "fond/widgets";

import {
  BodyCell,
  BoldText,
  GreyText,
  HeaderLabel,
  LayerBox,
  LayerBoxHeader,
  SelectedText,
  StyledCaption,
  StyledCellLabel,
  StyledNotificationImportantIcon,
  StyledSelectField,
  StyledTableRow,
  StyledTextField,
} from "./NewExportModal.styles";

const useCustomStyles = makeStyles((theme: Theme) => ({
  selectLabel: {
    fontSize: 14,
    marginBottom: 4,
  },
  textLabel: {
    marginBottom: 4,
  },
}));

interface IFormData {
  version: string;
  name: string;
  layers: string[];
  format: string;
  description: string;
  bom: boolean;
  spliceTables: boolean;
  fiberCableSplit: boolean;
  hubSplit: boolean;
  spliceSplit: boolean;
  unitOfMeasurement: string;
  comments: boolean;
}

interface NewExportModalProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Used in unit tests to set the select field to native.
   */
  nativeSelect?: boolean;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Callback function to be executed after an new export is register.
   */
  onPostExport?(): void;
}

const NewExportModal: React.FC<NewExportModalProps> = ({
  "data-testid": dataTestid = "new-export-modal",
  nativeSelect = false,
  onClose,
  onPostExport,
}: NewExportModalProps) => {
  const classes = useCustomStyles();
  const { enqueueSnackbar } = useSnackbar();

  const commentsPresent = useSelector((state: Store): boolean => (state.comments.items ? Object.keys(state.comments.items).length > 0 : false));

  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const { data: accountModules } = useGetAccountModulesQuery(project?.Account.ID ?? skipToken);

  const versions = useSelector((state: Store): Version[] => (project && selectVersionsByProjectId(state, project.ID)) || []);
  const versionOptions = versions && [...versions].map((item) => ({ value: item.ID, displayValue: item.Name }));

  // Only show version select when the project has multiple versions
  const showVersionSelect = versions.length > 1;

  const [selectedVersion, setSelectedVersion] = useState<Version | undefined>(showVersionSelect ? undefined : versions[0]);

  // Version status only required for planner projects
  const { data: status } = useGetVersionStatusQuery(selectedVersion?.ID ?? skipToken, { skip: project.HasCustomLayerConfig });
  const hasSolution = status?.HasSolution;

  // Only account with splice tables module can export splice tables.
  const canExportSpliceTables = accountModuleCheck(accountModules, Actions.PROJECT_EXPORT_SPLICE_TABLES);

  const { isLoading: isLayersLoading } = useGetLayersQuery(selectedVersion?.ID || "", {
    skip: !selectedVersion?.ID,
    refetchOnMountOrArgChange: true,
  });
  const layerOptions = useSelector(
    (state: Store): Layer[] => (!isLayersLoading && selectedVersion && selectLayersByVersionId(state, selectedVersion.ID)) || []
  ).filter((layer) => layer.Type === "Layer");
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);

  // Load layer configs for the selected version.
  const layerLabelByLayerKey = useSelector((state: Store) => (selectedVersion?.ID ? selectLayerLabelByLayerKey(state, selectedVersion?.ID) : {}));

  const numSelectedLayer = selectedLayers.length;
  const numTotalLayer = layerOptions.length;
  const numFillUpEmptyRow = Math.max(5 - layerOptions.length, 0);

  const [createExport, { isLoading: isSaving, isError }] = useCreateExportMutation();
  const [errorMsg, setErrorMsg] = useState<null | string>(null);

  const exportFormatOptions = [
    {
      value: ExportFormats.Geojson,
      displayValue: getExportFormat(ExportFormats.Geojson),
    },
    {
      value: ExportFormats.Shapefile,
      displayValue: getExportFormat(ExportFormats.Shapefile),
    },
    {
      value: ExportFormats.Kml,
      displayValue: getExportFormat(ExportFormats.Kml),
    },
    {
      value: ExportFormats.Tab,
      displayValue: getExportFormat(ExportFormats.Tab),
    },
  ];

  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit if validation passes)
    submit();
  };

  const onSubmit = async (values: IFormData) => {
    if (selectedLayers.length === 0 && !values.comments) {
      setErrorMsg("Please select at least one layer.");
    } else {
      const { name, version, format, description, bom, spliceTables, fiberCableSplit, hubSplit, spliceSplit, comments } = values;
      const params = {
        VersionID: version || versions[0].ID,
        Name: name,
        Format: format,
        Description: description,
        BOM: bom,
        SystemOfMeasurement: project.SystemOfMeasurement,
        SpliceTables: spliceTables,
        Comments: comments,
        FiberCableSplit: fiberCableSplit,
        HubSplit: hubSplit,
        SpliceSplit: spliceSplit,
        Layers: selectedLayers,
      };

      createExport(params)
        .unwrap()
        .then((response) => {
          onClose();
          if (onPostExport) {
            onPostExport();
          }
          mixpanel.track("Created export", {
            accountId: project.Account.ID,
            bom,
            exportId: response.ID,
            format,
            fiberCableSplit,
            hubSplit,
            spliceSplit,
            spliceTables,
            comments,
          });
          enqueueSnackbar(`Created export '${values.name}'.`, { variant: "success" });
        });
    }
  };

  const handleVersionSelect = (event: SelectChangeEvent) => {
    if (event.target.value) {
      const newVersionId = event.target.value;
      setSelectedVersion(versions.find((item) => item.ID === newVersionId));
      setSelectedLayers([]);
    }
  };

  const handleLayerSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedLayers([...selectedLayers, event.target.value]);
      setErrorMsg(null);
    } else {
      setSelectedLayers(selectedLayers.filter((item) => item !== event.target.value));
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedLayers(layerOptions.map((item) => item.LayerKey));
      setErrorMsg(null);
    } else {
      setSelectedLayers([]);
    }
  };

  // Sort layers by label.
  const sortedLayers = useMemo(() => {
    const copiedLayers = [...layerOptions];
    const layersWithLabel: LayerWithLabel[] = copiedLayers.map((item) => {
      return { ...item, Label: layerLabelByLayerKey[item.LayerKey] || item.LayerKey };
    });
    return layersWithLabel.sort((a, b) => a.Label.localeCompare(b.Label));
  }, [layerOptions]);

  return (
    <Modal
      open
      onClose={onClose}
      header="New Export"
      headerIcon={<AddBoxIcon />}
      data-testid={dataTestid}
      content={
        <Box>
          {isError && (
            <Box mb={2} data-testid="export-error">
              <MessageWidget type="error">There was an issue creating the export. Please try again.</MessageWidget>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{
              format: ExportFormats.Shapefile,
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  {showVersionSelect && (
                    <Box mb={2}>
                      <StyledSelectField
                        name="version"
                        labelId="version"
                        label="Select Version"
                        data-testid="export-version-select"
                        required
                        hideError
                        validate={required}
                        sxProps={{ minWidth: 160 }}
                        nativeSelect={nativeSelect}
                        options={versionOptions}
                        labelClasses={{ root: classes.selectLabel }}
                        onCustomChange={handleVersionSelect}
                      />
                    </Box>
                  )}
                  <LayerBox>
                    {selectedVersion ? (
                      <LayerBoxHeader>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            sx={{ height: 32 }}
                            label={
                              <Box maxWidth={200}>
                                <HeaderLabel>{selectedVersion.Name}</HeaderLabel>
                              </Box>
                            }
                            control={
                              <Checkbox
                                size="small"
                                data-testid="export-select-all-layers"
                                checked={numTotalLayer > 0 && numTotalLayer === numSelectedLayer}
                                indeterminate={numSelectedLayer > 0 && numSelectedLayer < numTotalLayer}
                                onChange={handleSelectAll}
                              />
                            }
                          />
                          <StyledCaption>{`(${numTotalLayer} Layers)`}</StyledCaption>
                        </Box>
                        <SelectedText>{`${numSelectedLayer} Selected`}</SelectedText>
                      </LayerBoxHeader>
                    ) : (
                      <LayerBoxHeader>
                        <Box display="flex" alignItems="center">
                          <Checkbox size="small" />
                          <HeaderLabel sx={{ pr: 1 }}>Name</HeaderLabel>
                          <StyledCaption>(Layers)</StyledCaption>
                        </Box>
                      </LayerBoxHeader>
                    )}

                    <TableContainer className={classNames("customScrollbars")} sx={{ height: 176, overflow: "auto" }}>
                      <Table stickyHeader size="small">
                        <TableBody>
                          {sortedLayers.map(
                            (layer: LayerWithLabel) =>
                              layer.LayerKey && (
                                <StyledTableRow key={layer.ID} className={selectedLayers.includes(layer.LayerKey) ? "selectedLayer" : undefined}>
                                  <BodyCell>
                                    <FormControlLabel
                                      sx={{ height: 32 }}
                                      label={
                                        <StyledCellLabel className={selectedLayers.includes(layer.LayerKey) ? "selectedLayer" : undefined}>
                                          {layer.Label}
                                        </StyledCellLabel>
                                      }
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={layer.LayerKey}
                                          checked={selectedLayers.includes(layer.LayerKey)}
                                          onChange={handleLayerSelect}
                                        />
                                      }
                                    />
                                  </BodyCell>
                                  {[LayerIds.hub, LayerIds.splice, LayerIds.fibreCable].includes(layer.LayerKey as LayerId) && (
                                    <BodyCell>
                                      <CheckboxField
                                        sxProps={{ height: 32, ".MuiFormControlLabel-label": { fontSize: 12 } }}
                                        name={SplitByTier[layer.LayerKey]}
                                        size="small"
                                        color="primary"
                                        label="Separate by tier"
                                        disabled={!selectedLayers.includes(layer.LayerKey)}
                                      />
                                    </BodyCell>
                                  )}
                                </StyledTableRow>
                              )
                          )}
                          {[...Array(numFillUpEmptyRow)].map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <StyledTableRow key={index}>
                              <BodyCell />
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </LayerBox>
                  {errorMsg && (
                    <FormHelperText data-testid="export-layer-select-error" error>
                      {errorMsg}
                    </FormHelperText>
                  )}
                  <Box pt={2}>
                    <Grid container display="flex" alignItems="center" justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        <StyledTextField
                          name="name"
                          inputProps={{ "data-testid": "export-name" }}
                          required
                          hideError
                          fullWidth
                          label="Name"
                          margin="dense"
                          validate={required}
                          labelClasses={{ root: classes.textLabel }}
                          placeholder="Export Name"
                          sx={{ marigin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledSelectField
                          name="format"
                          data-testid="export-file-format"
                          labelId="export-format"
                          label="Layer File Format"
                          fullWidth
                          required
                          hideError
                          validate={required}
                          nativeSelect={nativeSelect}
                          options={exportFormatOptions}
                          labelClasses={{ root: classes.selectLabel }}
                          menuStyle={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pt={1}>
                    <StyledTextField
                      inputProps={{ "data-testid": "export-description" }}
                      labelClasses={{ root: classes.textLabel }}
                      style={{ marginTop: 0 }}
                      name="description"
                      label="Description (Optional)"
                      placeholder="Enter description"
                    />
                  </Box>
                  <Box pt={2}>
                    <Box display="flex" alignItems="center">
                      <BoldText variant="body2" display="block">
                        Add to Export
                      </BoldText>
                      {!hasSolution && !project.HasCustomLayerConfig && (
                        <Box display="flex" alignItems="center" pl={1}>
                          <StyledNotificationImportantIcon />
                          <GreyText variant="caption">You need to generate a design first to enable this option</GreyText>
                        </Box>
                      )}
                    </Box>
                    <Box display="flex">
                      {!project.HasCustomLayerConfig && (
                        <>
                          <Box>
                            <CheckboxField
                              data-testid="export-add-bom"
                              sxProps={{ ".MuiFormControlLabel-label": { fontSize: 14 } }}
                              name="bom"
                              size="small"
                              color="primary"
                              label="Material & Costs"
                              disabled={!hasSolution}
                            />
                          </Box>
                          <Box>
                            {canExportSpliceTables && (
                              <CheckboxField
                                data-testid="export-add-splice-tables"
                                sxProps={{ ".MuiFormControlLabel-label": { fontSize: 14 } }}
                                name="spliceTables"
                                size="small"
                                color="primary"
                                label="Splice Tables"
                                disabled={!hasSolution}
                              />
                            )}
                          </Box>
                        </>
                      )}
                      <Box>
                        <CheckboxField
                          data-testid="export-comments"
                          sxProps={{ ".MuiFormControlLabel-label": { fontSize: 14 } }}
                          name="comments"
                          size="small"
                          color="primary"
                          label="Comments"
                          disabled={!commentsPresent}
                        />
                      </Box>
                    </Box>
                  </Box>
                </form>
              );
            }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="cancel-button" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton data-testid="export-button" variant="contained" color="primary" onClick={handleOnClick} loading={isSaving}>
            Export
          </LoadingButton>
        </>
      }
    />
  );
};

export default NewExportModal;
