import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { rootReducer } from "fond/store";

import { headersCreator } from "./index";

export type RootState = ReturnType<typeof rootReducer>;

export const fondServiceURL = `${process.env.REACT_APP_FOND_SERVICE_DOMAIN}`;

// initialize an empty api service that we inject endpoints into
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: fondServiceURL, prepareHeaders: (headers, api) => headersCreator.createHeaders(headers, api) }),
  endpoints: () => ({}),
  keepUnusedDataFor: 60,
  tagTypes: [
    "Account",
    "Architecture",
    "CustomWorkflowExecutions",
    "Export",
    "Exports",
    "FeatureTotals",
    "Folder",
    "Follow",
    "MapLayerConfig",
    "MentionSuggestions",
    "MultiProject",
    "MultiReport",
    "Permission",
    "Permissions",
    "Project",
    "Projects",
    "RenewStatus",
    "User",
    "UserPreference",
    "Users",
    "Version",
    "Versions",
    "VersionStatus",
    "Layers",
    "Layer",
    "Layout",
    "Layouts",
    "Draft",
    "UserAccountAllocation",
    "VersionRootConfiguration",
    "ShareSuggestions",
    "Subscription",
    "Invitations",
    "Report",
  ],
});
