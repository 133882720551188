import React from "react";
import { Box, Button, FormHelperText, Paper, Typography } from "@mui/material";

import { AsyncOperationState } from "fond/async/redux";
import { UploadType } from "fond/types";
import { LinearProgressWithLabel } from "fond/widgets";

import { Alert, AlertTitle } from "../InputDataPanel.styles";

interface IProps {
  upload: UploadType;
  inputLayer: string;
  onDismissUploadError: () => void;
}

const UploadProgress: React.FC<IProps> = ({ upload, inputLayer, onDismissUploadError }) => (
  <Box px={1}>
    {upload.uploadComplete && (
      <Alert severity="info" sx={{ mb: 2 }}>
        <AlertTitle>The upload is complete</AlertTitle>
        <Typography variant="body3">FOND is now processing your data. It will be visible on the map shortly.</Typography>
      </Alert>
    )}
    <Paper variant="outlined" sx={{ py: 4, px: 2.75, my: 2 }}>
      <LinearProgressWithLabel
        color={upload.status === AsyncOperationState.failure ? "error" : "primary"}
        value={upload.percent}
        progressBarMarginRight={0}
        sx={{ "& .MuiLinearProgress-bar": { background: "linear-gradient(90deg, rgba(72,205,255,1) 0%, rgba(33,150,243,1) 100%)" } }}
      />
      {upload.status !== AsyncOperationState.failure ? (
        <FormHelperText color="primary">{upload.uploadComplete ? "Processing" : "Uploading"}</FormHelperText>
      ) : (
        <FormHelperText error>
          Sorry, there was a problem uploading this file. {upload.failureMessage ? upload.failureMessage : "Please try again"}
        </FormHelperText>
      )}
    </Paper>
    {upload.status === AsyncOperationState.failure && (
      <Button color="primary" onClick={() => onDismissUploadError()}>
        Try again
      </Button>
    )}
  </Box>
);

export default UploadProgress;
