import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, selectCurrentAllocation, useGetAccountSubscriptionsQuery } from "fond/api";
import { PermissionLevel, SubscriptionStatus } from "fond/types";
import { Actions, isAllowed as isAllowedCheck } from "fond/utils/permissions";

export const usePermissionCheck = (action: Actions, permission?: PermissionLevel): boolean => {
  const currentAllocation = useSelector(selectCurrentAllocation);
  const currentAccount = useSelector(selectCurrentAccount);

  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAccount?.ID ?? skipToken);
  // only check permissions against active subscriptions
  const currentSubscription = subscriptions?.find((subscription) => subscription.Status === SubscriptionStatus.Active);

  const isAllowed = !!permission && isAllowedCheck(action, permission, currentAllocation, currentSubscription);

  return isAllowed;
};
