import React, { useMemo } from "react";
import { useFormState } from "react-final-form";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CameraExpressionField } from "fond/styleEditor";
import { StyleField } from "fond/types";

import { FieldFactory } from "../FieldFactory";
import { getDefaultSpecValue } from "../helper";

interface IProps {
  field: StyleField;
  fieldSpec?: any;
}

export const useCustomStyles = makeStyles((theme: Theme) => ({}));

const Field: React.FC<IProps> = ({ field, fieldSpec }: IProps) => {
  const { values } = useFormState();
  const { fieldName } = field;
  const defaultValue = useMemo(() => getDefaultSpecValue(fieldSpec, fieldName), [fieldName]);
  const hasInterpolateValue = values[`${field.fieldName}Interpolate`];

  return (
    <>
      {hasInterpolateValue ? (
        <CameraExpressionField name={`${fieldName}Interpolate`} field={field} fieldSpec={fieldSpec} defaultValue={defaultValue} />
      ) : (
        <FieldFactory fieldName={fieldName} formFieldName={fieldName} fieldSpec={fieldSpec} defaultValue={defaultValue} />
      )}
    </>
  );
};

export default Field;
