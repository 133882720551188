import React from "react";
import { Error as ErrorIcon, Event as EventIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Subscription, SubscriptionStatus } from "fond/types";
import { formatDateString } from "fond/utils/dates";

import { Container, StyledBox, StyledIcon, StyledText } from "./subscriptionPeriod.styles";

interface SubscriptionPeriodProps {
  subscription: Subscription;
}

const SubscriptionPeriod: React.FC<SubscriptionPeriodProps> = ({ subscription }: SubscriptionPeriodProps) => {
  const theme = useTheme();
  const startDate = formatDateString(subscription.StartTime);
  const endDate = formatDateString(subscription.EndTime);
  const expired = subscription.Status === SubscriptionStatus.Expired;

  return (
    <Container elevation={1}>
      <StyledBox>
        {expired ? (
          <>
            <StyledIcon color="error">
              <ErrorIcon data-testid="subscription-period-expired-icon" />
            </StyledIcon>
            <StyledText color={theme.palette.biarri.secondary.grey}>Subscription expired:</StyledText>
            <StyledText color={theme.palette.secondary.main}>{endDate}</StyledText>
          </>
        ) : (
          <>
            <StyledIcon color="primary">
              <EventIcon data-testid="subscription-period-active-icon" />
            </StyledIcon>
            <StyledText color={theme.palette.biarri.secondary.grey}>{`Subscription period: ${startDate} - ${endDate}`}</StyledText>
          </>
        )}
        <StyledText color={theme.palette.biarri.secondary.grey}>(UTC)</StyledText>
      </StyledBox>
    </Container>
  );
};

export default SubscriptionPeriod;
