import React from "react";
import Button from "@mui/material/Button";

import Modal from "./Modal";

type ConfirmModalProps = React.ComponentProps<typeof Modal> & {
  confirmText?: string;
  disabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({ confirmText = "OK", disabled = false, onConfirm, onCancel, ...rest }) => (
  <Modal
    actions={
      <>
        <Button color="inherit" onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={onConfirm} data-testid="confirm-button" disabled={disabled}>
          {confirmText}
        </Button>
      </>
    }
    {...rest}
  />
);

export default ConfirmModal;
