export type fieldName = "CTSFiles" | "CFOFiles";
export const fileMap: Record<fieldName, Array<{ title: string; file: string }>> = {
  CTSFiles: [
    {
      title: "Indexed Cost Summary",
      file: "indexed_cost_summary.json",
    },
    {
      title: "Bin Ranges",
      file: "bin_ranges.json",
    },
  ],
  CFOFiles: [
    {
      title: "Service Locations",
      file: "service_location.geojson",
    },
    {
      title: "Phases",
      file: "phases.json",
    },
    {
      title: "HHP Schedule",
      file: "hhp_schedule.csv",
    },
    {
      title: "HHC schedule",
      file: "hhc_schedule.csv",
    },
    {
      title: "Revenue schedule",
      file: "revenue_schedule.csv",
    },
    {
      title: "Cost schedule",
      file: "cost_schedule.csv",
    },
    {
      title: "Cashflow schedule",
      file: "cashflow_schedule.csv",
    },
    {
      title: "Final position",
      file: "final_position.csv",
    },
    {
      title: "Construction schedule",
      file: "construction_schedule.csv",
    },
  ],
};
