import React, { memo, MouseEventHandler } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { Link } from "./styles";

interface LinkCellRendererProps extends ICellRendererParams {
  href?: string;
  onClick?(data: unknown): (MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>) | undefined;
  download?: boolean;
}

export const LinkCellRenderer: React.FC<LinkCellRendererProps> = memo(({ onClick, href, value, data, download }: LinkCellRendererProps) => (
  <Link onClick={onClick?.(data)} href={href} color="primary" download={download}>
    {value}
  </Link>
));

LinkCellRenderer.displayName = "LinkCellRenderer";

interface DownloadLinkCellRendererProps extends LinkCellRendererProps {
  hrefConstructor(params: ICellRendererParams): string;
}

export const DownloadLinkCellRenderer = memo(({ hrefConstructor, ...params }: DownloadLinkCellRendererProps) => {
  const href = hrefConstructor(params);
  return <LinkCellRenderer {...params} href={href} download />;
});

DownloadLinkCellRenderer.displayName = "DownloadLinkRenderer";

export default LinkCellRenderer;
