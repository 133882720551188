import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Folder, Project } from "fond/types";

import { CellWrapper } from "./rowMenuRenderer.styles";

interface IProps extends ICellRendererParams<Folder | Project> {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: Folder | Project): void;
}

const RowMenuCellRenderer: React.FC<IProps> = memo(({ data, onClick }: IProps) => (
  <CellWrapper>
    <IconButton
      aria-haspopup="true"
      aria-label="Menu Button"
      data-testid="project-list-item-menu-button"
      onClick={(e) => onClick(e, data)}
      size="small"
    >
      <MoreVert />
    </IconButton>
  </CellWrapper>
));

RowMenuCellRenderer.displayName = "RowMenuCellRenderer";

export default RowMenuCellRenderer;
