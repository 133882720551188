import { Box, Paper, styled, SvgIcon, Typography } from "@mui/material";

export const Container = styled(Paper)(({ theme }) => ({
  height: 36,
  width: "100%",
  padding: theme.spacing(2),
}));

export const StyledBox = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  paddingRight: theme.spacing(0.5),
}));

StyledText.defaultProps = {
  variant: "subtitle2",
};

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "0.85rem",
  marginRight: theme.spacing(1),
}));
