import React from "react";
import { SubMentionComponentProps } from "@draft-js-plugins/mention/lib/Mention";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

const customStyles = (theme: Theme) => {
  return createStyles({
    mention: {
      color: theme.palette.primary.main,
    },
  });
};

interface ComponentProps extends SubMentionComponentProps {
  children: React.ReactNode;
}

type IProps = ComponentProps & WithStyles<typeof customStyles>;

const MentionComponent: React.FC<IProps> = ({ classes, children }: IProps) => {
  return (
    <span>
      @<span className={classes.mention}>{children}</span>
    </span>
  );
};

export default withStyles(customStyles)(MentionComponent);
