import React, { useState } from "react";

import { FondStyleType, StyleField, StyleFieldName } from "fond/types";

import { config } from "../config/layout";
import { FieldSelection } from "../FieldSelection";

type FormValues = {
  [key in StyleFieldName]: unknown;
};

export interface IDefaultStyleFormData extends FormValues {
  Type: FondStyleType;
}

// Filter out the color field for all styles as the color will instead be
// set by the color ramp.
const fieldFilter = (field: StyleField) => !["CircleColor", "LineColor", "FillColor"].includes(field.fieldName);

interface IProps {
  type: FondStyleType;
}

/**
 * Component to allow defining of the default values for a style.
 */
const StyleSettings: React.FC<IProps> = ({ type }: IProps) => {
  const defaultField = config[type][0].fields.filter(fieldFilter)[0];
  const [selectedField, setSelectedField] = useState(defaultField);

  return <FieldSelection onSelect={(field) => setSelectedField(field)} selectedField={selectedField} type={type} fieldFilter={fieldFilter} />;
};

export default StyleSettings;
