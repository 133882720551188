import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "fond/utils/hooks";

const Invitation: React.FC = () => {
  const navigate = useNavigate();
  const { invitationId } = useParams();
  const isLoggedIn = useAppSelector((state) => state.cognito.isLoggedIn);

  useEffect(() => {
    const redirectState = { state: { invitationId } };
    const path = isLoggedIn ? "/" : "/signin";
    navigate(path, redirectState);
  }, [invitationId, isLoggedIn]);

  return null;
};

export default Invitation;
