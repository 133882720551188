import React from "react";
import { Form } from "react-final-form";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useUpdateVersionMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { AnyObject, Version } from "fond/types";
import { required } from "fond/utils/validation";
import { Message as MessageWidget, Modal } from "fond/widgets";

interface IFormData {
  Name: string;
}

interface IProps {
  /**
   * The Version being edited
   */
  version: Version;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Optional Callback function for when the form is submitted
   */
  onSubmit?(values: IFormData): void;
}

const EditVersionModal: React.FC<IProps> = ({ version, onClose, onSubmit }: IProps) => {
  const [updateVersion, { isLoading: isSaving, isError }] = useUpdateVersionMutation();
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit is validation passes)
    submit();
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name }: IFormData) => {
    updateVersion({ ID: version.ID, Name })
      .unwrap()
      .then(() => {
        onClose();
        enqueueSnackbar(`Version successfully renamed to "${Name}"`, {
          key: `version_rename_${version.ID}`,
          action: (
            <Button
              onClick={() => {
                closeSnackbar(`version_rename_${version.ID}`);
                updateVersion({ ID: version.ID, Name: version.Name });
              }}
            >
              Undo
            </Button>
          ),
        });
      });
  };

  return (
    <Modal
      open
      header="Edit this version"
      data-testid="edit-version-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <MessageWidget type="error">There was an issue updating this version. Please try again.</MessageWidget>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{ Name: version.Name }}
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  <Box maxWidth={300}>
                    <TextField name="Name" required label="Name" fullWidth validate={required} />
                  </Box>
                </form>
              );
            }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="edit-version-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="edit-version-save-button" color="primary" onClick={handleOnClick} loading={isSaving}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditVersionModal;
