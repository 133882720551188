import zxcvbn from "zxcvbn";

/**
 * A password strength estimation.
 *
 * 0 # too guessable: risky password. (guesses < 10^3)
 * 1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
 * 2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
 * 3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
 * 4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
 */
export const analysePassword = (password?: string): zxcvbn.ZXCVBNScore | undefined => {
  if (password === undefined) {
    return undefined;
  } else {
    const result = zxcvbn(password);
    return result.score;
  }
};
