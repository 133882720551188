/**
 * Impersonate feature is documented at:
 *   https://biarri-wiki.atlassian.net/wiki/spaces/FIBRE/pages/489685058
 */

export const getImpersonatedUser = (): string | null => {
  return window.localStorage.getItem("impersonate");
};

export { Impersonate, stopImpersonating } from "./Impersonate";
