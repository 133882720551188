import React from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { Action, Body, BodyText, CloseButton, Container, Header, LockIcon, StyledButton, Title } from "./navigationPopup.styles";

interface NavigationPopupProps {
  /**
   * Title of the popup
   */
  title: string;
  /**
   * Description of the popup
   */
  description: string | React.ReactNode;
  /**
   * Action text of the popup
   */
  actionText?: string;
  /**
   *
   * Callback function fired when click the action button
   */
  onClick?: () => void;
}

const NavigationPopup: React.FC<NavigationPopupProps> = ({ title, description, actionText, onClick }: NavigationPopupProps) => {
  return (
    <Container data-testid="navigation-popup">
      <Body>
        <Box>
          <Header>
            <LockIcon />
            <Title>{title}</Title>
          </Header>
          <BodyText>{description}</BodyText>
          {actionText && (
            <Action>
              <StyledButton variant="contained" onClick={onClick}>
                {actionText}
              </StyledButton>
            </Action>
          )}
        </Box>

        <CloseButton onClick={() => {}} size="small">
          <CloseIcon />
        </CloseButton>
      </Body>
    </Container>
  );
};

export default NavigationPopup;
