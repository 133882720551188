import { Alert, Box, Button, styled, SvgIcon } from "@mui/material";
import { PaymentElement } from "@stripe/react-stripe-js";

export const StyledPaymentMethod = styled(PaymentElement)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const CountryAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),

  display: "flex",
  alignItems: "center",

  "& .MuiAlert-message": {
    width: "75%",
  },
}));

export const CardBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  padding: `0 ${theme.spacing(2)}`,
  border: "1px solid #e6e6e6",
  minHeight: 94,
}));

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "1.15rem",
  marginRight: theme.spacing(1),
  color: theme.palette.biarri.primary.darkGrey,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.biarri.secondary.stripeBlue,
  padding: 0,
  minHeight: 0,
  minWidth: 0,
}));
