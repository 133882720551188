import { isEqual } from "lodash";

import { selectLayerByVersionAndLayerKey, selectLayerVersionImportStatus } from "fond/api";
import { DesignLayerIds, ImportStatus, Layer } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";

export type DesignLayers = Map<DesignLayerIds, LayerStatus>;

export type LayerStatus = { layer: Layer | undefined; status: ImportStatus | undefined };

/**
 * Gets the layer and import status information for each of the design layers used
 * within a small design.
 *
 * @param versionId the current versionId being requested
 * @returns A map containing the layer & import status of each Design Layer
 */
export const useGetDesignLayers = (versionId: string): DesignLayers => {
  const layerStatus = useAppSelector((state) => {
    const map = new Map<DesignLayerIds, LayerStatus>();

    Object.values(DesignLayerIds).forEach((layerId) => {
      map.set(layerId, {
        layer: selectLayerByVersionAndLayerKey(state, { versionId, layerId }),
        status: selectLayerVersionImportStatus(state, { versionId, layerId }),
      });
    });

    return map;
  }, isEqual);

  return layerStatus;
};

/**
 * Gets the layer and import status information for each of the design layers used
 * within a small design.
 *
 * @param versionId the current versionId being requested
 * @param layerId the id of the design layer information to return
 * @returns A map containing the layer & import status of each Design Layer
 */
export const useGetDesignLayer = (versionId: string, layerId: DesignLayerIds): LayerStatus =>
  useAppSelector(
    (state) => ({
      layer: selectLayerByVersionAndLayerKey(state, { versionId, layerId }),
      status: selectLayerVersionImportStatus(state, { versionId, layerId }),
    }),
    isEqual
  );
