import React, { useCallback } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { MentionData } from "@draft-js-plugins/mention";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { convertToRaw, EditorState, RawDraftContentState } from "draft-js";

import { StyleOverrideProp } from "fond/utils/draftEditor";
import { Editor } from "fond/widgets";

export type EditorFieldProps = {
  /**
   * Determines if the field should take focus automatically on mount
   */
  autoFocus?: boolean;
  /**
   * Css style overrides
   */
  style?: StyleOverrideProp;
  /**
   * A collection of users that are able to be @mentioned
   */
  mentionsData?: MentionData[];
  /**
   * A collection of tags that are able to be #tagged
   */
  hashtagData?: MentionData[];
  /**
   * The Field Name
   */
  name: string;
  /**
   * Placeholder content for the field.
   */
  placeholder?: string;
  /**
   * React final form field props.
   */
  fieldProps?: Partial<FieldProps<any, any>>;
  /**
   * A collection of validation functions to be applied to the field
   */
  validate?: any;
  /**
   * The current value of the Editor
   */
  value?: RawDraftContentState;
};

const EditorField: React.FC<EditorFieldProps> = (props: EditorFieldProps) => {
  const { name, fieldProps, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <EditorWrapper input={input} meta={meta} name={name} {...rest} />}
      validate={validate}
      {...fieldProps}
    />
  );
};

type EditorWrapperProps = FieldRenderProps<RawDraftContentState, HTMLElement>;

const EditorWrapper: React.FC<EditorWrapperProps> = ({
  autoFocus,
  input: { name, onChange, onFocus, value, ...restInput },
  meta,
  mentionsData,
  hashtagData,
  label,
  placeholder,
  required,
  helperText,
  style,
}: EditorWrapperProps) => {
  const hasError = meta.error && meta.touched;

  /**
   * Handles the onchange event within the editor
   */
  const onEditorStateChange = useCallback((state: EditorState) => onChange(convertToRaw(state.getCurrentContent())), []);

  return (
    <FormControl fullWidth data-testid={`${name}-form-field`}>
      {(label || required) && (
        <FormLabel required={required} error={hasError}>
          {label}
        </FormLabel>
      )}

      <Editor
        autoFocus={autoFocus}
        rawContent={value}
        style={style}
        data-testid={`${name}-input`}
        onChange={onEditorStateChange}
        onFocus={onFocus}
        placeholder={placeholder}
        mentionsData={mentionsData}
        hashtagData={hashtagData}
      />

      {(hasError || helperText) && <FormHelperText error={hasError}>{meta.touched ? meta.error : helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(EditorField);
