import React from "react";
import { useNavigate } from "react-router";
import LinkIcon from "@mui/icons-material/Link";
import { Button } from "@mui/material";

import { Folder } from "fond/types";

interface IProps {
  folder?: Folder;
}

const ViewButton: React.FC<IProps> = ({ folder }: IProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (folder) navigate(`/city/${folder.MultiProject?.ID}`);
  };

  if (!folder) return null;

  return (
    <Button startIcon={<LinkIcon />} variant="contained" color="primary" onClick={handleOnClick}>
      View project
    </Button>
  );
};

export default ViewButton;
