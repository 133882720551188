import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateNewFolder, Delete, Edit, Redo, Share as ShareIcon } from "@mui/icons-material";
import { Fade, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAllocation, selectFolderById, useGetAccountSubscriptionsQuery } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import Share from "fond/share/Share";
import { ProjectsView, Store, SubscriptionStatus } from "fond/types";
import { Actions, licenseCheck, permissionCheck } from "fond/utils/permissions";

import DeleteFolder from "./DeleteFolder";
import EditFolder from "./EditFolder";
import MoveFolderDialog from "./MoveFolder";
import NewFolder from "./NewFolder";

interface IProps {
  anchorEl?: HTMLDivElement | null;
  filters: ProjectsView;
  folderId?: string;
  open: boolean;
  onClose(event: React.MouseEvent<EventTarget>): void;
}

type FolderModal = "new" | "edit" | "move" | "delete" | "share";
const FolderMenu: React.FC<IProps> = ({ anchorEl = null, filters, folderId, open, onClose }: IProps) => {
  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const [showModal, setShowModal] = useState<FolderModal>();
  const currentAccountAllocation = useSelector(selectCurrentAllocation);

  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAccountAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);

  const canAddFolder = usePermissionCheck(Actions.FOLDER_ADD, currentFolder?.Permission.Level);
  const canEditFolder = usePermissionCheck(Actions.FOLDER_EDIT, currentFolder?.Permission.Level);
  const canMoveFolder = usePermissionCheck(Actions.FOLDER_MOVE, currentFolder?.Permission.Level);
  const canDeleteFolder = usePermissionCheck(Actions.FOLDER_DELETE, currentFolder?.Permission.Level);

  const handleOnOpen = (modal: FolderModal) => (event: React.MouseEvent<EventTarget>) => {
    onClose(event);
    setShowModal(modal);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onClose}
        TransitionComponent={Fade}
        data-testid="breadcrumb-item-menu"
      >
        <MenuItem
          onClick={handleOnOpen("new")}
          data-testid="new-button"
          disabled={
            (filters === "shared" && !currentFolder) ||
            (filters === "workspace" && !(licenseCheck(currentAccountAllocation, Actions.FOLDER_ADD) && hasActiveSubscription)) ||
            (currentFolder && !canAddFolder)
          }
        >
          <ListItemIcon>
            <CreateNewFolder />
          </ListItemIcon>
          New Folder
        </MenuItem>
        <MenuItem onClick={handleOnOpen("edit")} disabled={!folderId || !canEditFolder} data-testid="edit-button">
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem aria-label="Move" data-testid="move-button" onClick={handleOnOpen("move")} disabled={!folderId || !canMoveFolder}>
          <ListItemIcon>
            <Redo fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Move</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleOnOpen("share")}
          disabled={!folderId || (currentFolder && !permissionCheck(currentFolder.Permission.Level, Actions.FOLDER_SHARE_VIEW))}
          data-testid="share-button"
        >
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem color="danger" onClick={handleOnOpen("delete")} disabled={!folderId || !canDeleteFolder} data-testid="delete-button">
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography>Delete</Typography>
        </MenuItem>
      </Menu>
      {showModal === "new" && <NewFolder onClose={() => setShowModal(undefined)} parentId={folderId} />}
      {showModal === "edit" && currentFolder && <EditFolder onClose={() => setShowModal(undefined)} folder={currentFolder} />}
      {showModal === "move" && currentFolder && <MoveFolderDialog folder={currentFolder} onClose={() => setShowModal(undefined)} />}
      {showModal === "delete" && currentFolder && <DeleteFolder onClose={() => setShowModal(undefined)} folder={currentFolder} />}
      {showModal === "share" && currentFolder && <Share resource={currentFolder} onClose={() => setShowModal(undefined)} />}
    </>
  );
};

export default FolderMenu;
