import React, { useEffect, useMemo } from "react";
import { useField } from "react-final-form";
import { useSelector } from "react-redux";
import { usePreviousDistinct } from "react-use";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationCityIcon from "@mui/icons-material/LocationCityOutlined";
import { Box, ListItemIcon, Typography } from "@mui/material";
import { FieldValidator } from "final-form";

import { selectVersionsByProjectId, useGetVersionsQuery } from "fond/api";
import { Autocomplete } from "fond/form/fields";
import { BaseMultiProject, Folder, Project, Store, Version } from "fond/types";
import { getPath } from "fond/utils/folder";

interface IProps {
  /**
   * The list of projects/multiproject the user has access to
   */
  projects: (Project | BaseMultiProject)[];
  /**
   * The list of folders the user has access to, used to display the project path
   */
  folders: Folder[];
  /**
   * Whether the autocomplete fields are disabled
   */
  disabled?: boolean;
  /**
   * Form validators
   */
  projectValidator?: FieldValidator<Project | undefined>;
  versionValidator?: FieldValidator<Version | undefined>;
}

const OPTIONS_LIMIT = 25;

/**
 * Tests whether two version lists are the same
 * @param prev - previous version list
 * @param next - next version list
 * @returns
 */
function versionListsAreSame(prev?: Version[], next?: Version[]) {
  if (!prev && !next) {
    return true;
  }
  if (!prev || !next || prev?.length !== next?.length) {
    return false;
  }

  return prev.every((item, index) => item.ID === next[index].ID);
}

const ProjectVersionField: React.FC<IProps> = ({ projects, folders, disabled = false, projectValidator, versionValidator }) => {
  const {
    input: { onChange, onBlur, value: versionFormValue },
  } = useField<Version | "">("Version");
  const {
    input: { value: projectFormValue },
  } = useField<Project | BaseMultiProject | "">("Project");

  // If the value is an empty string, we want to explicitly set the value to null to avoid the autocomplete component throwing a warning
  // about the empty value not matching any options.
  const project = useMemo(() => (projectFormValue === "" ? null : projectFormValue), [projectFormValue]);
  const version = useMemo(() => (versionFormValue === "" ? null : versionFormValue), [versionFormValue]);

  const prevProjectID = usePreviousDistinct(project?.ID);

  const { isLoading, isFetching } = useGetVersionsQuery((project as Project)?.ID, { skip: !project?.ID || project?.EntityType !== "project" });
  const versions = useSelector((state: Store): Version[] => (project && selectVersionsByProjectId(state, project.ID)) || [], versionListsAreSame);

  const getProjectName = (project?: Project | BaseMultiProject) => {
    if (!project) {
      return "";
    }
    return project.EntityType === "project" ? project.ProjectName : project.Name;
  };

  const handleProjectChange = () => {
    // As soon as the project changes, we want to clear the version as it is no longer valid.
    onChange(null);
  };

  useEffect(() => {
    if (versions?.length > 0 && prevProjectID !== project?.ID) {
      // When the project changes have gone through their formstate lifecycle, we can update the version
      // to the first one in the list, which _should_ correspond to the latest version
      // but only if the currently selected version isn't in the list.
      if (!versions.some((v) => v.ID === version?.ID)) {
        onChange(versions[0]);
        // We blur after change to trigger validation on the field
        onBlur();
      }
    }
  }, [project, prevProjectID, versions, version?.ID, onChange, onBlur]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <Box sx={{ flex: 1, mr: 1 }}>
        <Autocomplete
          name="Project"
          required
          label="Project"
          placeholder="Select project"
          fullWidth
          options={projects}
          getOptionLabel={(option) => getProjectName(option)}
          isOptionEqualToValue={(option, value) => option.ID === value.ID}
          value={project}
          renderOption={(props, option: Project | BaseMultiProject) => {
            const folderId = option?.EntityType === "project" ? option.FolderID : option?.Folder?.ID || null;
            const folderPath = folderId ? getPath(folders, folderId) : [];
            return (
              <li {...props} key={option.ID}>
                <ListItemIcon>
                  {option.EntityType === "project" ? (
                    <DescriptionIcon fontSize="small" color="primary" />
                  ) : (
                    <LocationCityIcon fontSize="small" color="primary" />
                  )}
                </ListItemIcon>
                <Box data-testid="copy-project-item" display="flex" flexDirection="column">
                  <Typography>{getProjectName(option)}</Typography>
                  <Typography variant="caption">
                    {`${option.Account.Name} > Home ${folderPath.length ? " > " : ""} ${folderPath.join(" > ")}`}
                  </Typography>
                </Box>
              </li>
            );
          }}
          filterOptions={(options, state) =>
            options.filter((option) => getProjectName(option).toLowerCase().includes(state.inputValue.toLowerCase())).slice(0, OPTIONS_LIMIT)
          }
          validate={projectValidator}
          onChange={handleProjectChange}
          size="small"
          disabled={disabled}
        />
      </Box>
      <Box sx={{ flex: 1, ml: 1 }}>
        <Autocomplete
          name="Version"
          required={project?.EntityType !== "multi_project"}
          label="Version"
          placeholder="Select version"
          fullWidth
          options={versions || []}
          getOptionLabel={(option) => option?.Name || ""}
          isOptionEqualToValue={(option, value) => option.ID === value.ID}
          value={version}
          renderOption={(props, option: Version) => {
            return (
              <li {...props} key={option.ID}>
                <Box data-testid="version-item" display="flex" flexDirection="column">
                  <Typography>{option.Name}</Typography>
                </Box>
              </li>
            );
          }}
          filterOptions={(options, state) => options.filter((option) => option.Name.toLowerCase().includes(state.inputValue.toLowerCase()))}
          validate={project?.EntityType === "multi_project" ? undefined : versionValidator}
          loading={isLoading || isFetching}
          size="small"
          disabled={disabled || project?.EntityType === "multi_project"}
        />
      </Box>
    </Box>
  );
};

export default ProjectVersionField;
