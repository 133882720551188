import { styled } from "@mui/material/styles";

import { ExportIcon, ImportIcon } from "fond/svg_icons";

/**
 * The import project menu item logo style.
 */
export interface StyledImportIconProps {}

export const StyledImportIcon = styled(ImportIcon)<StyledImportIconProps>(({ theme }) => ({
  fill: theme.palette.biarri.primary.darkGrey,
  padding: theme.spacing(0.2),
}));

/**
 * The export project menu item logo style.
 */
export interface StyledExportIconProps {}

export const StyledExportIcon = styled(ExportIcon)<StyledExportIconProps>(({ theme }) => ({
  fill: theme.palette.biarri.primary.darkGrey,
  padding: theme.spacing(0.2),
}));
