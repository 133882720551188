import { LayoutNode, LayoutNodeResponse } from "fond/types";

import { apiSlice } from "./apiSlice";

export type LayoutResponse = {
  Layouts: LayoutNodeResponse[];
  Maps: LayoutNodeResponse[];
};

export type LayoutUpdateRequestBody = {
  ProjectID?: string;
  MultiProjectID?: string;
  Layouts: LayoutNode[];
  Maps: LayoutNode[];
};

/**
 * Layout API Slice
 */
export const layoutSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLayout: build.query({
      query: (params: { projectId?: string; multiProjectId?: string }) =>
        params.projectId ? `/v2/layouts?project_id=${params.projectId}` : `/v2/layouts?multi_project_id=${params.multiProjectId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: "Layouts", id: "Layouts" },
              { type: "Layouts", id: arg.projectId },
              { type: "Layouts", id: arg.multiProjectId },
            ]
          : [],
    }),
    updateLayout: build.mutation({
      query: (data: LayoutUpdateRequestBody) => ({
        url: `/v2/layouts`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Layouts", id: "Layouts" },
        { type: "Layouts", id: arg.ProjectID },
        { type: "Layouts", id: arg.MultiProjectID },
      ],
    }),
    deleteLayout: build.mutation<undefined, { projectId?: string; multiProjectId?: string }>({
      query: (params) => ({
        url: params.projectId ? `/v2/layouts?project_id=${params.projectId}` : `/v2/layouts?multi_project_id=${params.multiProjectId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Layouts", id: "Layouts" },
        { type: "Layouts", id: arg.projectId },
        { type: "Layouts", id: arg.multiProjectId },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetLayoutQuery, useUpdateLayoutMutation, useDeleteLayoutMutation } = layoutSlice;
