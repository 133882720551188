import { passing_draw_polygon as passingMode } from "mapbox-gl-draw-passing-mode";

import { MODE_NAME, PASSING_MODE_NAME } from "./constants";
import cutPolygonMode from "./polygonCut";

const modes = {
  [PASSING_MODE_NAME]: passingMode,
  [MODE_NAME]: cutPolygonMode,
};

export default modes;
