import React, { useCallback, useContext, useEffect, useRef } from "react";
import { featureCollection } from "@turf/helpers";
import { isEqual } from "lodash";
import { FeatureIdentifier } from "mapbox-gl";

import { MapContext } from "fond/map/MapProvider";
import { getCurrentProjectHighlights } from "fond/project";
import { EditMode, MapboxStyleLayer } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppSelector } from "fond/utils/hooks";

interface HighlightedFeaturesProps {
  editMode: EditMode;
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  styles?: LayerStyle[];
}

/**
 * A hook that monitors any features selected on the map & elevates (copies) those
 * features into a mapbox layer that sits above all other layers.
 *
 * This allows selected features that normally are hidden under other layers to be visible.
 */
export const useHighlightedFeatures = ({ editMode, layerConfigs, styles }: HighlightedFeaturesProps): void => {
  const highlightedFeatures = useAppSelector((state) => getCurrentProjectHighlights(state.project), isEqual);
  const { map } = useContext(MapContext);
  const styleIdsRef = useRef(new Set<string>());

  /**
   * Handles setting the features isSelected feature-state
   * @param {FeatureIdentifier[]} features the features which will be marked as "isSelected".
   */
  const setSelected = useCallback(
    ({ features }: { features: FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[] }) => {
      features.forEach((feature) => {
        map?.setFeatureState(
          {
            source: feature.source,
            id: feature.id,
          },
          { isSelected: true }
        );
      });
    },
    [map]
  );

  /**
   * Moves all existing style layers related to highlighting to the top.
   * This is required to prevent new layers being added above the hightlights.
   */
  const moveLayers = useCallback(() => {
    if (map) {
      for (const id of styleIdsRef.current) {
        map.moveLayer(id);
      }
    }
  }, [map]);

  useEffect(() => {
    // Do nothing is an edit mode is set
    if (editMode !== "none") return;

    layerConfigs?.forEach((layer) => {
      const sourceId = `${layer.ID}_selected`;
      const existingSource = map?.getSource(sourceId);

      if (map) {
        if (!existingSource) {
          // Add a new mapbox source that will contain the features that should be highlighted
          map.addSource(sourceId, {
            type: "geojson",
            data: featureCollection([]),
          });
        } else if (existingSource && existingSource.type === "geojson") {
          // Create a copy of the features to highlight.
          const filteredFeatures = highlightedFeatures
            .filter((feature) => feature.sourceLayer === layer.Key || feature.source === layer.Key)
            .map(({ type, geometry, properties }, index) => ({
              id: index,
              type,
              geometry,
              properties,
              source: sourceId,
            }));

          if (filteredFeatures.length > 0) {
            // Add the features to the "highlight" source & set them as selected.
            moveLayers();
            existingSource.setData(featureCollection(filteredFeatures));
            setSelected({ features: filteredFeatures });
          } else {
            // Remove all highlighted features
            existingSource.setData(featureCollection([]));
          }
        }
      }

      // For each original style we add a copied version to mapbox with the source of data
      // the "highlight" source.
      layer.Styles.forEach((styleId) => {
        const style = styles?.find((s) => s.ID === styleId);
        const newStyleId = `${styleId}_highlight`;
        const filter = layer.Type === "SUBLAYER" ? layer.FilterConfiguration?.Mapbox : undefined;

        if (style) {
          styleIdsRef.current.add(newStyleId);

          map?.addLayer({
            id: newStyleId,
            source: sourceId,
            ...style.MapboxStyle,
            ...(filter ? { filter } : {}),
          } as MapboxStyleLayer);
        }
      });
    });
  }, [layerConfigs, highlightedFeatures, map, styles, setSelected, moveLayers, editMode]);
};
