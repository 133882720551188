import chroma from "chroma-js";

import { ColorGradient } from "fond/types";

const variants: Record<ColorGradient, string[]> = {
  Reds: ["#FFBFBF", "#DD0000"],
  Blues: ["#A8D5FF", "#004493"],
  Greens: ["#C7E9C0", "#006E2C"],
  Spectral: ["#D7191D", "#F3F358", "#2A83BA"],
  Azure: ["#FFF6BC", "#41B6C4", "#253494"],
  Magma: ["#4A1079", "#FD3D42", "#FDEBAC"],
  Tranquil: ["#7FC97F", "#FEC086", "#386CB0"],
  Pastel: ["#b3e2cd", "#cbd5e8", "#fdebac"],
};

export const generateColors = (variant?: ColorGradient, count = 1): string[] => {
  if (variant) return chroma.scale(variants[variant]).colors(count);
  // Fallback is random colors
  return Array.from({ length: count }, () => chroma.random().hex());
};

/**
 * A color palette ordered by preference that provides a human distinct
 * set of colors.
 *
 * Note: these are specifically ordered in this manner so that we can interate
 * through the palette as new layers are added.
 */
export const layerPalette: string[] = [
  "#00B0FF",
  "#00C853",
  "#FDD835",
  "#FF8F00",
  "#F06292",
  "#E040FB",
  "#E53935",
  "#26A69A",
  "#80DEEA",
  "#8BC34A",
  "#FF4081",
  "#5E35B1",
  "#8C9EFF",
  "#1E88E5",
  "#FF6D00",
  "#B71C1C",
  "#BA68C8",
  "#FF3D00",
  "#0D47A1",
  "#00695C",
];
