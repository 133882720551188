import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";

import { NoContentIcon } from "fond/svg_icons";

import { MessagePanel, NoContentMessage } from "./BaseProjectList.styles";

export const CreateAccountPanel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MessagePanel as={Paper} data-testid="create-account-panel">
      <NoContentMessage data-testid="no-content-message">
        <NoContentIcon />
        <Box maxWidth={500}>
          <Typography variant="h3">No content?</Typography>
          <Typography variant="body1" fontWeight={700}>
            It looks like you are not part of an account & have not been shared content.
          </Typography>
          <Typography maxWidth={400}>
            If you're the first person from your company using FOND, let's create a shared workspace for others to join.
          </Typography>
        </Box>
      </NoContentMessage>
      <Box display="flex" flex="1" pr={5} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ maxWidth: 230 }}
          onClick={() => navigate("/createaccount")}
          data-testid="create-account-button"
        >
          Create account
        </Button>
      </Box>
    </MessagePanel>
  );
};
