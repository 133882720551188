import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Category, CloudUpload, Polyline } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";

import { useGetVersionQuery } from "fond/api";
import { inputLayerGroups, LayerGroupIds, LayerIds } from "fond/layers";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { StackedNavigationButton, useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { selectDrawExistingOption, selectDrawNewOption, selectUploadOption } from "../polygon/redux";
import { closePolygonSelect, getCurrentProjectData, hasLayer, polygonSelect } from "../redux";

import { Alert, AlertTitle } from "./InputDataPanel.styles";

const AreaSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const { open, goBack } = useStackedNavigationContext();
  const [isReplacing, setIsReplacing] = useState(false);
  const [hasData, setHasData] = useState(false);

  const { versionId } = useAppSelector((state) => state.project);
  const { activeLayerGroupId } = useAppSelector((state) => state.project);
  const data = useAppSelector((state) => getCurrentProjectData(state.project));
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });

  const layerIds = useMemo(() => {
    if (activeLayerGroupId === LayerGroupIds.inStreet) {
      return [LayerIds.inStreet];
    } else if (activeLayerGroupId === LayerGroupIds.spanPole) {
      return [LayerIds.inSpan, LayerIds.inPole];
    }

    return [LayerIds.inAddress];
  }, [activeLayerGroupId]);

  const beginPolygonSelect = useCallback(() => {
    // inAddress is the layer ID in the auto design panel, but inParcel is what
    // the polygon select logic expects.
    const payload = { layerIds: layerIds.map((id) => (id === LayerIds.inAddress ? LayerIds.inParcel : id)) };
    dispatch(polygonSelect(payload));
  }, [dispatch, layerIds]);

  useEffect(() => {
    const hasExistingData = layerIds.some((layerId) => hasLayer(data, layerId));
    setHasData(hasExistingData);
    if (!hasExistingData) {
      beginPolygonSelect();
    }
  }, [activeLayerGroupId, beginPolygonSelect, data, layerIds]);

  const handleBack = () => {
    dispatch(closePolygonSelect());
    goBack();
  };

  const onDrawPolygonButtonClick = () => {
    dispatch(selectDrawNewOption());
    open("draw-polygon");
  };

  const onUseExistingPolygon = () => {
    dispatch(selectDrawExistingOption(version?.UploadedArea));
    open("use-existing-polygon");
  };

  const onUploadPolygon = () => {
    dispatch(selectUploadOption());
    open("draw-polygon");
  };

  const onReplace = () => {
    setIsReplacing(true);
    beginPolygonSelect();
  };

  if (!isReplacing && hasData) {
    return (
      <>
        <StackedNavigationHeader onBackButtonClick={handleBack} title="Area Select" />
        <Box px={1} data-testid="replace-data-alert-box">
          <Typography fontWeight="500" mb={2}>
            {inputLayerGroups[activeLayerGroupId].name}
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle data-testid="replace-data-header">Replace data</AlertTitle>
            <Typography variant="body3" component="p" mb={1}>
              You have already uploaded data for this layer. Proceeding with Area Select will replace it.
            </Typography>
            <Typography variant="body3" component="p">
              Are you sure you want to do this?
            </Typography>
          </Alert>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button color="primary" size="small" onClick={handleBack} data-testid="cancel-replace-button">
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={onReplace} sx={{ ml: 1, px: 2 }} data-testid="replace-button">
              Replace
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Area Select" />
      <Box px={1} data-testid="polygon-select-panel">
        <Typography fontWeight="500">Ways to select an area</Typography>
        {version?.UploadedArea && (
          <StackedNavigationButton
            data-testid="use-existing-polygon-button"
            icon={<Category />}
            title="Use existing polygon"
            subtitle="Reuse the existing polygon area"
            onClick={onUseExistingPolygon}
          />
        )}
        <StackedNavigationButton
          data-testid="draw-new-polygon-button"
          icon={<Polyline />}
          title="Draw polygon"
          subtitle="Draw a new polygon on the map"
          onClick={onDrawPolygonButtonClick}
        />
        <StackedNavigationButton
          data-testid="upload-polygon-button"
          icon={<CloudUpload />}
          title="Upload"
          subtitle="Upload a polygon area"
          onClick={onUploadPolygon}
        />
      </Box>
    </>
  );
};

export default AreaSelect;
