import { ComponentProps } from "react";
import { Box, MenuItem as BaseMenuItem, styled, Tooltip as MuiTooltip } from "@mui/material";

export const Tooltip = styled(({ className, ...rest }: ComponentProps<typeof MuiTooltip>) => (
  <MuiTooltip classes={{ tooltip: className }} {...rest} />
))(
  ({ theme }) => `
  background-color: white;
  border-radius: 4px;
  color: ${theme.palette.biarri.primary.darkGrey};
  padding: ${theme.spacing(1)} 0;
  box-shadow: ${theme.custom.shadows.default};

  .MuiTooltip-popper &.MuiTooltip-tooltipPlacementLeft {
    margin-right: ${theme.spacing(0.5)};
  }
`
);

export const MenuItem = styled(BaseMenuItem)`
  justify-content: space-between;
`;

export const MenuItemContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
