import { Box, Button, Menu as MuiMenu, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.biarri.secondary.darkCarbon,
  width: "100%",
  display: "flex",
  flexDirection: "row",
}));

export const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  borderRadius: 0,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: "#516b7a",
  },
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}));

export const Menu = styled(MuiMenu)(({ theme }) => ({
  pointerEvents: "none",
  "& .MuiMenuItem-root": {
    pointerEvents: "auto",
  },
}));
