import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, ListItemIcon, MenuItem } from "@mui/material";

import { selectExportsByProjectId, useGetExportsQuery } from "fond/api";
import ExportModal from "fond/export/ExportModal";
import { getCurrentProject } from "fond/project";
import { Export, ExportStatus, Project, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

import { StyledExportIcon } from "./ProjectMenuItem.styles";

interface ExportMenuItemProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Callback on modal close request.
   */
  handleClose: () => void;
}

/**
 * The ExportMenuItem may be used by permitted users to open the import modal from the project menu/
 */
const ExportMenuItem: React.FC<ExportMenuItemProps> = ({ "data-testid": dataTestid = "export-menu-item", handleClose }: ExportMenuItemProps) => {
  const [exportModal, showExportModal] = useState(false);
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const canViewExport = permissionCheck(project?.Permission.Level, Actions.PROJECT_EXPORT_VIEW);

  const { isLoading, isFetching } = useGetExportsQuery(project?.ID);
  const exports = useSelector((state: Store): Export[] | undefined => project && selectExportsByProjectId(state, project.ID));
  const [numExports, setNumExports] = useState(0);

  const handleClick = () => {
    if (canViewExport) {
      showExportModal(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (!isLoading && !isFetching && exports) {
      setNumExports(exports.filter((item: Export) => item.Status.Value === ExportStatus.Complete).length);
    }
  }, [isLoading, isFetching, exports]);

  return (
    <>
      <MenuItem data-testid={dataTestid} onClick={handleClick} disabled={!canViewExport}>
        <ListItemIcon>
          <StyledExportIcon />
        </ListItemIcon>
        Export
        <Badge sx={{ pl: 3 }} badgeContent={numExports} color="error" />
      </MenuItem>
      {exportModal && (
        <ExportModal
          onClose={() => {
            showExportModal(false);
          }}
        />
      )}
    </>
  );
};

export default ExportMenuItem;
