import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  flexDirection: "row",
}));

export const Panel = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  width: 240,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "4px 0px 4px -3px rgb(0 0 0 / 5%)",
  borderRight: `1px solid ${theme.palette.divider}`,
}));

export const Popout = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 240,
  bottom: 0,
  width: 360,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "4px 0px 4px -3px rgb(0 0 0 / 5%)",
}));
