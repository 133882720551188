import { FormHelperText as BaseFormHelperText, styled, TableRow } from "@mui/material";
import BaseTooltip, { TooltipProps } from "@mui/material/Tooltip";

export const StaticTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTypography-root ": {
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));

export const EditableTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  height: 112,
}));

export const Tooltip = styled(({ className, ...props }: TooltipProps) => <BaseTooltip {...props} classes={{ popper: className }} />)`
  & .MuiTooltip-tooltip {
    max-width: 260px;
    text-align: center;
  }
`;

export const FormHelperText = styled(BaseFormHelperText)`
  position: absolute;
  left: 0;
  bottom: -20px;
  text-wrap: nowrap;
`;
