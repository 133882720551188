export enum ColorGradient {
  Reds = "Reds",
  Blues = "Blues",
  Greens = "Greens",
  Spectral = "Spectral",
  Azure = "Azure",
  Magma = "Magma",
  Tranquil = "Tranquil",
  Pastel = "Pastel",
}
