import React from "react";
import { Alert, AlertTitle, Button, Paper } from "@mui/material";

import { DesignLayerIds, ImportStatus, Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { LinearProgressWithLabel, StackedNavigationButton, SvgIcon, useStackedNavigationContext } from "fond/widgets";

import { useCustomWorkflow } from "./useCustomWorkflow";
import { useGetDesignLayer } from "./useGetDesignLayers";

interface BoundaryPanelProps {
  project: Project;
}

const BoundaryPanel: React.FC<BoundaryPanelProps> = ({ project }: BoundaryPanelProps) => {
  const versionId = useAppSelector((state) => state.project.versionId);
  const boundaryLayer = useGetDesignLayer(versionId, DesignLayerIds.INPUT_LAYER_BOUNDARY);
  const ugLayer = useGetDesignLayer(versionId, DesignLayerIds.INPUT_LAYER_UG_PATH);
  const { workflowState, workflowProgress, runWorkflow } = useCustomWorkflow(versionId, "base_data");
  const { open } = useStackedNavigationContext();
  const isImportingBoundary =
    boundaryLayer.status !== undefined && boundaryLayer.status !== ImportStatus.COMPLETE && boundaryLayer.status !== ImportStatus.ERROR;
  const isGeneratingBaseData = workflowState === "STARTING" || workflowState === "IN_PROGRESS";

  const handleOnClick = () => {
    open("boundaryDraw");
  };

  const handleGenerateBoundaryData = async () => {
    if (!runWorkflow) {
      return;
    }
    runWorkflow();
  };

  return isImportingBoundary ? (
    <Paper variant="outlined" sx={{ py: 2, px: 2.75 }}>
      <LinearProgressWithLabel color="primary" variant="indeterminate" title="Generating boundary data" subtext="Processing..." />
    </Paper>
  ) : (
    <>
      <StackedNavigationButton
        data-testid="boundary-method-navigation"
        icon={<SvgIcon icon="polygon" />}
        title="Boundary"
        onClick={handleOnClick}
        sx={{ m: 0 }}
        disabled={!!boundaryLayer.layer || isGeneratingBaseData}
      />
      <Button
        fullWidth
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
        disabled={!boundaryLayer.layer || !!ugLayer.layer || !runWorkflow || isGeneratingBaseData || workflowState === "COMPLETE"}
        onClick={handleGenerateBoundaryData}
      >
        Generate Base Data
      </Button>
      {isGeneratingBaseData && (
        <Paper variant="outlined" sx={{ py: 2, px: 2.75, mt: 2 }}>
          <LinearProgressWithLabel
            color="primary"
            value={(workflowProgress ?? 0) * 100}
            progressBarMarginRight={0}
            title="Generating base data"
            subtext="Processing..."
          />
        </Paper>
      )}
      {workflowState === "ERROR" && (
        <Alert severity="error" sx={{ mt: 4 }}>
          <AlertTitle>Base data generation failed</AlertTitle>
          Base data could not be generated.
        </Alert>
      )}
    </>
  );
};

export default BoundaryPanel;
