import React from "react";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Add, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import { TextField } from "fond/form/fields";
import { required } from "fond/utils/validation";

import { useCustomStyles } from "../FieldFactory";
import { AddButton } from "../styles";

interface IProps {
  name: string;
  inputProps: any;
}

const FieldNumArray: React.FC<IProps> = ({ name, inputProps }: IProps) => {
  const classes = useCustomStyles();
  const {
    mutators: { push, remove },
  } = useForm();

  /**
   * Handles inserting a new value
   */
  const handleOnAdd = () => {
    push(name, 0);
  };

  return (
    <Box data-testid={`${name}-numFieldArray-field`}>
      <FieldArray name={name}>
        {({ fields }) => {
          return fields.map((fieldName, index) => (
            <Box key={`${name}.${index}`} sx={{ width: 80, display: "flex", justifyContent: "space-between", mb: 0.5 }}>
              <TextField
                name={`${name}.${index}`}
                type="number"
                size="small"
                classes={{
                  root: classes.textFieldRoot,
                }}
                inputProps={inputProps}
                validate={required}
                hideError
              />
              <IconButton
                onClick={() => {
                  remove(name, index);
                }}
                size="small"
                data-testid="numFieldArray-field-delete-button"
              >
                <Delete sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Box>
          ));
        }}
      </FieldArray>
      <AddButton startIcon={<Add />} onClick={handleOnAdd} data-testid="numFieldArray-field-button">
        Add
      </AddButton>
    </Box>
  );
};

export default FieldNumArray;
