import React from "react";
import { Home as HomeIcon } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { PlannerSubscription } from "fond/types";

import InsightsCard from "./InsightsCard";

import { GreyText, RowContainer } from "../common.styles";

interface InsightsTotalPremsProps {
  subscription: PlannerSubscription;
}

const InsightsTotalPrems: React.FC<InsightsTotalPremsProps> = ({ subscription: { Quota, QuotaUsage } }: InsightsTotalPremsProps) => {
  const theme = useTheme();
  const percentage = Math.min(Math.round((QuotaUsage / Quota) * 100), 100);
  const exceeded = percentage === 100;
  const LOW_CREDIT_THRESHOLD = 90;

  return (
    <InsightsCard
      title={exceeded ? "PREM COUNT EXCEEDED" : "PREMISES USED"}
      color={exceeded ? "warning" : "primary"}
      value={QuotaUsage}
      icon={<HomeIcon />}
      content={
        <Box>
          <Box pb={1}>
            <LinearProgress
              data-testid="total-prems-linear-progress"
              variant="determinate"
              color={percentage >= LOW_CREDIT_THRESHOLD ? "error" : "primary"}
              value={percentage}
            />
          </Box>
          <RowContainer>
            <GreyText>{`${(Quota ?? 0).toLocaleString()} Addresses`}</GreyText>
            <Typography variant="subtitle2" color={exceeded ? theme.palette.secondary.main : theme.palette.biarri.primary.darkGrey}>
              {`${percentage}%`}
            </Typography>
          </RowContainer>
        </Box>
      }
    />
  );
};

export default InsightsTotalPrems;
