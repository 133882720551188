import React, { useEffect, useState } from "react";
import { Cancel as CancelIcon, Check as CheckIcon, Error as ErrorIcon, Schedule as ScheduleIcon } from "@mui/icons-material";
import { Box, CircularProgress, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { StatusTypes } from "fond/project/redux";
import theme from "fond/theme";
import { SolveStepDisplay, WorkflowStep } from "fond/types";
import { enumerate } from "fond/utils";
import { getColor } from "fond/widgets/colors";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "left",
  },
  workflowStep: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderRadius: "0.2em",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    position: "relative",
    color: "#999",
    backgroundColor: getColor("grey", "veryLight"),
  },
  running: {
    color: "#222 !important",
    backgroundColor: getColor("yellow"),
  },
  idle: {
    color: "#999",
    backgroundColor: getColor("grey", "veryLight"),
  },
  complete: {
    color: "#444",
    backgroundColor: getColor("green", "tertiary"),
  },
  terminated: {
    color: "white",
    backgroundColor: getColor("red", "secondary"),
  },
  cancelling: {
    color: "#999",
    backgroundColor: getColor("grey", "veryLight"),
  },
  cancelled: {
    color: "#999",
    backgroundColor: getColor("red", "secondary"),
  },

  icon: { position: "absolute", right: 10, top: 6, fontSize: 15, color: "inherit", width: "15px !important", height: "15px !important" },
}));

interface IProps {
  workflow: WorkflowStep[];
}

const SolveProgress: React.FC<IProps> = ({ workflow }: IProps) => {
  const classes = useStyles();

  const stepLabels = {
    Preprocessor: "Analyzing the problem",
    Tier_1_Solve: "Determining drop hub locations",
    Tier_2_Solve: "Determining distribution hub locations",
    Tier_3_Solve: "Connecting to feeder hubs",
    FlexNapPostprocessor: "Processing FlexNAPs",
    BsOutput: "Generating output layers",
    Splicing: "Creating splice tables",
    BOM: "Counting materials and costs",
    CostToServe: "Calculating cost to serve",
  };

  const statusDisplay = {
    [StatusTypes.Running]: {
      className: classes.running,
      icon: CircularProgress,
    },
    [StatusTypes.Idle]: {
      className: classes.idle,
      icon: ScheduleIcon,
    },
    [StatusTypes.Complete]: {
      className: classes.complete,
      icon: CheckIcon,
    },
    [StatusTypes.Terminated]: {
      className: classes.terminated,
      icon: ErrorIcon,
    },
    [StatusTypes.Cancelling]: {
      className: classes.cancelling,
      icon: CancelIcon,
    },
    [StatusTypes.Cancelled]: {
      className: classes.cancelled,
      icon: CancelIcon,
    },
  };

  const [stepDisplays, setStepDisplays] = useState<SolveStepDisplay[] | []>([]);

  useEffect(() => {
    const display = [];
    if (workflow) {
      for (let [i, step] of enumerate(workflow)) {
        let status = step.Status;
        if (status === StatusTypes.Idle) {
          if (i === 0 || workflow[i - 1].Status === StatusTypes.Complete) {
            status = StatusTypes.Running;
          }
        }

        display.push({
          id: step.ID,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          text: stepLabels[step.ID],
          icon: statusDisplay[status]?.icon,
          className: statusDisplay[status]?.className,
        });

        if (status === StatusTypes.Terminated) {
          break;
        }
      }
    }
    setStepDisplays(display);
  }, [workflow]);

  return (
    <Box data-testid="solve-progress-container" className={classes.container}>
      {stepDisplays &&
        stepDisplays.map((stepDisplay: SolveStepDisplay) => (
          <Box
            data-testid={`solve-progress-${stepDisplay.id}`}
            key={stepDisplay.id}
            className={classNames(classes.workflowStep, stepDisplay.className)}
          >
            {stepDisplay.text}
            <Icon component={stepDisplay.icon} className={classes.icon} />
          </Box>
        ))}
    </Box>
  );
};

SolveProgress.displayName = "SolveProgress";
export default SolveProgress;
