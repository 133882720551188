import { common } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { ExportIcon } from "fond/svg_icons";

/**
 * The modal logo style.
 */
export const StyledExportIcon = styled(ExportIcon)(({ theme }) => ({
  fill: common.white,
}));
