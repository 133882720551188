import React, { useRef } from "react";
import { ColDef, GridApi, GridOptions, GridReadyEvent } from "@ag-grid-community/core";
import { SaveAlt } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import mixpanel from "fond/mixpanel";
import { AgGrid } from "fond/widgets";

import GridCard from "../../GridCard";

interface IProps {
  columns: ColDef[];
  rowData: Record<string, string | number | undefined>[];
  gridOptions: GridOptions;
}

const RevenueData: React.FC<IProps> = ({ columns, rowData, gridOptions }) => {
  const gridApi = useRef<GridApi | null>();

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  /**
   * Triggers the download of the AgGrid data in excel format
   */
  const onDownloadData = () => {
    mixpanel.track("Report", "Revenue", "Exported via datagrid");
    gridApi.current?.exportDataAsExcel();
  };

  return (
    <GridCard
      breakpoints={{ xs: 12 }}
      title="Data"
      headerRightElement={
        <IconButton size="small" onClick={onDownloadData}>
          <SaveAlt fontSize="inherit" />
        </IconButton>
      }
    >
      <Box p={1}>
        <Box sx={{ overflow: "auto", display: "flex" }}>
          <AgGrid
            columnDefs={columns}
            rowData={rowData}
            autoSizeColumns={false}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            variant="outlined"
          />
        </Box>
      </Box>
    </GridCard>
  );
};

export default RevenueData;
