import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { Fade, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { deleteReply } from "fond/redux/comments";
import { Reply, Store } from "fond/types";

import DeleteConfirmation from "./DeleteConfirmation";

interface IProps {
  /**
   * The anchor element the menu should be relatively positioned
   */
  anchorEl?: HTMLButtonElement | null;
  /**
   * The reply that the menu relates to
   */
  reply: Reply;
  /**
   * Flag indicating if the menu should be open
   */
  open: boolean;
  /**
   * Callback function for when the menu closes
   */
  onClose(event: React.MouseEvent<EventTarget>): void;
  /**
   * Callback function to initialise editing
   */
  onStartEdit(): void;
}

const ReplyMenu: React.FC<IProps> = ({ anchorEl = null, reply, open, onClose, onStartEdit }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();
  const [deleteModal, showDeleteModal] = useState(false);

  /**
   * handles the closing of the menu
   */
  const handleMenuOnClose = (event: any) => {
    event.stopPropagation();
    onClose(event);
    showDeleteModal(false);
  };

  /**
   * Handles opening the delete confirmation modal
   */
  const handleConfirmDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    showDeleteModal(true);
  };

  /**
   * Handles opening the delete confirmation modal
   */
  const handleOnStartEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose(event);

    // We use a timeout to allow the edit mode to focus on the textbox field
    setTimeout(() => {
      onStartEdit();
    }, 0);
  };

  /**
   * Handles the deletion of the reply
   */
  const onDelete = () => {
    return dispatch(deleteReply(reply)).then(() => {
      enqueueSnackbar("The reply has been deleted.");
    });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleMenuOnClose}
        TransitionComponent={Fade}
        data-testid="reply-item-menu"
      >
        <MenuItem data-testid="edit-reply-button" onClick={handleOnStartEdit}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem color="danger" onClick={handleConfirmDelete} data-testid="delete-reply-button">
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      {deleteModal && (
        <DeleteConfirmation
          onClose={handleMenuOnClose}
          onDelete={onDelete}
          title="Delete your reply"
          message="Are you sure you wish to delete this reply?"
        />
      )}
    </>
  );
};

export default ReplyMenu;
