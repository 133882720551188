import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { ExportLayerWithLabel, LayerWithLabel } from "fond/types";
import { Modal } from "fond/widgets";

export const TextBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  marginRight: theme.spacing(3),
  border: `1px solid ${theme.palette.biarri.secondary.whiteSmoke}`,
  borderRadius: theme.shape.borderRadius,
  maxHeight: 112,
  overflow: "auto",
}));

interface IProps {
  outputLayers: LayerWithLabel[];
  isFlexNap: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ArchitectureFlexNapWarningModal: React.FC<IProps> = ({ outputLayers, open, isFlexNap, onClose, onConfirm }: IProps) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      data-testid="architecture-warning-modal"
      variant="secondary"
      header="FlexNAP has been toggled"
      content={
        <Box className="customScrollbars">
          <Typography>
            This update will change the architecture to <strong>{isFlexNap ? "" : "not"}</strong> use FlexNAPs. The following output layers will be
            deleted:
          </Typography>
          <Box pt={1} sx={{ maxHeight: 120 }} className="customScrollbars">
            <TextBox bgcolor={theme.palette.biarri.secondary.skyBlue}>
              {outputLayers.map((layer: ExportLayerWithLabel) => (
                <Typography key={layer.ID} variant="body2" display="block">
                  {layer.Label}
                </Typography>
              ))}
            </TextBox>
          </Box>
        </Box>
      }
      actions={
        <>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm} autoFocus>
            OK
          </Button>
        </>
      }
    />
  );
};

export default ArchitectureFlexNapWarningModal;
