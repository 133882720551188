import React from "react";
import { Star } from "@mui/icons-material";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

import { Folder, ProjectPreview, Report } from "fond/types";

import { useStarred } from "./hooks/useStarred";

interface IProps {
  onMenuClose(event: React.MouseEvent<EventTarget>): void;
  resource: ProjectPreview | Folder | Report;
}

const StarMenuItem: React.FC<IProps> = ({ onMenuClose, resource }) => {
  const { starred, addToStarred, removeFromStarred } = useStarred();
  const isStarred = starred.includes(resource.ID);
  const label = !isStarred ? "Add to Starred" : "Remove from Starred";

  return (
    <MenuItem
      aria-label={label}
      onClick={(event: React.MouseEvent) => {
        if (isStarred) {
          removeFromStarred(resource);
        } else {
          addToStarred(resource);
        }
        onMenuClose(event);
      }}
      data-testid="starred-button"
    >
      <ListItemIcon>
        <Star />
      </ListItemIcon>
      <Typography variant="inherit">{label}</Typography>
    </MenuItem>
  );
};

export default StarMenuItem;
