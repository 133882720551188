import React from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { selectFolderById, selectFoldersByParentId, useGetFoldersQuery, useUpdateMultiProjectMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { MultiProject } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { generateUnusedName } from "fond/utils/naming";
import { Actions } from "fond/utils/permissions";
import { OnClickInput } from "fond/widgets";

interface IProps {
  multiProject: MultiProject;
}

const CityPlannerProjectName: React.FC<IProps> = ({ multiProject }) => {
  const { enqueueSnackbar } = useSnackbar();

  useGetFoldersQuery(undefined);
  const multiProjectFolder = useAppSelector((state) => selectFolderById(state, multiProject.Folder.ID));
  const folderSiblings = useAppSelector((state) => selectFoldersByParentId(state, multiProjectFolder?.ParentID ?? null));
  const folderSiblingNames = folderSiblings.map((f) => f.Name.toLowerCase());
  const canRename = usePermissionCheck(Actions.CITY_PLANNER_RENAME, multiProject?.Permission?.Level);

  const [updateMultiProject] = useUpdateMultiProjectMutation();

  /**
   * Callback function for handling the saving of project name
   */
  const handleOnCommit = (value: string) => {
    const newName = value || generateUnusedName("Untitled City Planner project", folderSiblingNames);

    if (folderSiblingNames.includes(value.toLowerCase())) {
      enqueueSnackbar("An item with this name already exists in this folder. Try another name.");
      return;
    }

    if (multiProject && newName !== multiProject.Name) {
      updateMultiProject({ ID: multiProject.ID, Name: newName })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Project renamed.");
        })
        .catch(() => {
          enqueueSnackbar("Project rename failed. Please try again...");
        });
    }
  };

  return (
    <Box marginRight={0.5}>
      <OnClickInput value={multiProject.Name} onCommit={handleOnCommit} readOnly={!canRename} />
    </Box>
  );
};

export default CityPlannerProjectName;
