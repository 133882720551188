import React, { useCallback, useRef } from "react";
import {
  ControlledTreeEnvironment,
  ControlledTreeEnvironmentProps,
  IndividualTreeViewState,
  StaticTreeDataProvider,
  Tree as ComplexTree,
  TreeEnvironmentRef,
  TreeItem,
  TreeItemIndex,
  TreeRef,
  UncontrolledTreeEnvironment,
  UncontrolledTreeEnvironmentProps,
} from "react-complex-tree";

import Item, { RenderItemProps } from "./Item";
import ItemArrow from "./ItemArrow";

import "react-complex-tree/lib/style-modern.css";
import "./Tree.scss";

interface CommonProps<T> {
  id?: string;
  items: Record<TreeItemIndex, TreeItem<T>>;
  getItemClass?(item: TreeItem<T>): string | undefined;
  getItemTitle?(item: TreeItem<T>): string;
  viewState?: IndividualTreeViewState;
  /**
   * Determines if the Tree should have its state and behavior controlled by the parent component.
   * @default "uncontrolled"
   */
  mode: "controlled" | "uncontrolled";
}

type ConditionalProps<T> =
  | ({ mode: "uncontrolled" } & Omit<UncontrolledTreeEnvironmentProps<T>, "viewState" | "getItemTitle" | "dataProvider" | "children">)
  | ({ mode: "controlled" } & Omit<ControlledTreeEnvironmentProps<T>, "viewState" | "getItemTitle">);

type IProps<T> = CommonProps<T> & ConditionalProps<T>;

const getTitle = (item: TreeItem<never>) => item.data;

/**
 * Back navigation button used to indicate the user will be taken back to a previous step.
 */
function TreeInner<T>(
  {
    id = "tree-1",
    items,
    getItemClass,
    getItemTitle = getTitle,
    viewState = {
      focusedItem: undefined,
      expandedItems: [],
      selectedItems: [],
    },
    mode = "uncontrolled",
    ...props
  }: IProps<T>,
  environmentRef: React.ForwardedRef<TreeEnvironmentRef<T>>
) {
  const tree = useRef<TreeRef>(null);
  const dataProvider = new StaticTreeDataProvider(items);

  const renderItem = useCallback(
    (renderProps: RenderItemProps<T>) => {
      return <Item {...renderProps} getItemClass={getItemClass} renderDepthOffset={props.renderDepthOffset} />;
    },
    [getItemClass, props.renderDepthOffset]
  );

  return (
    <>
      {mode === "uncontrolled" && (
        <UncontrolledTreeEnvironment<T>
          ref={environmentRef}
          renderItemArrow={ItemArrow}
          {...props}
          canSearch={false}
          canRename={false}
          dataProvider={dataProvider}
          getItemTitle={getItemTitle}
          renderItem={renderItem}
          viewState={{
            [id]: viewState,
          }}
        >
          <ComplexTree ref={tree} treeId={id} rootItem="root" />
        </UncontrolledTreeEnvironment>
      )}
      {mode === "controlled" && (
        <ControlledTreeEnvironment<T>
          items={items}
          ref={environmentRef}
          renderItemArrow={ItemArrow}
          renderItem={renderItem}
          {...props}
          canSearch={false}
          canRename={false}
          getItemTitle={getItemTitle}
          viewState={{
            [id]: viewState,
          }}
        >
          <ComplexTree ref={tree} treeId={id} rootItem="root" />
        </ControlledTreeEnvironment>
      )}
    </>
  );
}

const Tree = React.forwardRef(TreeInner) as <T>(
  props: IProps<T> & { ref?: React.MutableRefObject<TreeEnvironmentRef<T> | undefined> }
) => ReturnType<typeof TreeInner>;

export default Tree;
