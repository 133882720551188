import { TreeInformation, TreeItem, TreeItemRenderContext } from "react-complex-tree";
import { ChevronRight } from "@mui/icons-material";

import { ExpandIcon } from "./Tree.styles";

interface IProps<T> {
  item: TreeItem<T>;
  context: TreeItemRenderContext;
  info: TreeInformation;
}

const ItemArrow = <T,>({ item, context, info }: IProps<T>): JSX.Element => {
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (item.isFolder) {
      context.toggleExpandedState();
    }
  };

  return (
    <div {...context.arrowProps} onClick={handleOnClick} data-testid="expand-icon" style={{ width: "var(--rct-arrow-container-size)" }}>
      <ExpandIcon isOpen={context.isExpanded === true}>{item.isFolder && <ChevronRight />}</ExpandIcon>
    </div>
  );
};

export default ItemArrow;
