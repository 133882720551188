import React, { useCallback, useContext, useEffect, useState } from "react";

import { MergeIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const CombineButton: React.FC<IButtonProps> = () => {
  const { drawControl, map } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on the number of selected features.
   */
  const handleOnSelect = useCallback(() => {
    setIsDisabled((drawControl.current?.getSelectedIds().length || 0) < 2);
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnClick = () => {
    drawControl.current?.combineFeatures();
  };

  return (
    <ToolbarButton title="Combine features" onClick={handleOnClick} disabled={isDisabled}>
      <MergeIcon />
    </ToolbarButton>
  );
};

export default CombineButton;
