import React from "react";
import { AgChartOptions } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";

import { seriesCurrencyTooltip } from "../util";

interface IProps {
  data: any[];
}

const OverallPositionChart: React.FC<IProps> = ({ data }) => {
  const options: AgChartOptions = {
    data,
    series: [
      {
        type: "line",
        xKey: "Phase",
        yKey: "CumulativeNetPosition",
        yName: "Cumulative Net Position",
        tooltip: seriesCurrencyTooltip(["CumulativeNetPosition"]),
      },
    ],
    height: 340,
    padding: { top: 36, bottom: 36, left: 42, right: 42 },
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          fontSize: 10,
        },
      },
      {
        type: "number",
        position: "left",
        title: {
          text: "Cumulative net position",
          fontSize: 12,
        },
        label: {
          format: "~s",
          formatter: (params) => params.formatter!(params.value).replace("G", "B"),
        },
      },
    ],
  };

  return <AgChartsReact options={options} />;
};

export default OverallPositionChart;
