import React, { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetVersionCostBinRangesQuery, useGetVersionStatusQuery } from "fond/api";
import { allZeroCostToServeRanges } from "fond/utils/costToServeTransformers";

import { CloseButton, StyledAlert } from "./Legend.styles";

interface IProps {
  versionId?: string;
}

const LegendWarning: React.FC<IProps> = ({ versionId }: IProps) => {
  const { data: versionStatus } = useGetVersionStatusQuery(versionId ?? skipToken);
  // only try to get the cost to serve ranges if the version has a complete design
  const { data: costToServeRanges } = useGetVersionCostBinRangesQuery(versionStatus?.Status === "Complete" ? (versionId ?? skipToken) : skipToken);
  const isAllZero = costToServeRanges ? allZeroCostToServeRanges(costToServeRanges) : false;
  const [show, setShow] = useState(true);

  const onClose = () => {
    setShow(false);
  };

  if (!isAllZero || !show) return null;
  return (
    <StyledAlert severity="warning">
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Typography fontSize={12}>Add unit costs to your Bill of Materials (BOM) to make use of Cost to Serve.</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end">
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </Box>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default LegendWarning;
