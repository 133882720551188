import React, { useState } from "react";
import { useField } from "react-final-form";
import { Box, Typography } from "@mui/material";

import { SliderField } from "fond/form/fields";

import { getImportanceValue } from "./helper";

interface ScoringInputFormProps {
  id: string;
}

const ScoringInputForm: React.FC<ScoringInputFormProps> = ({ id }: ScoringInputFormProps) => {
  const {
    input: { value },
  } = useField(id);

  const [currentValue, setCurrentValue] = useState<number>(value);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <SliderField
        name={id}
        size="small"
        min={0}
        max={1}
        step={0.25}
        sx={{ width: 100, marginLeft: 1, marginRight: 1, padding: "8px 0" }}
        track={false}
        marks
        valueLabelDisplay="off"
        onChange={(_, newValue) => {
          setCurrentValue(newValue as number);
        }}
      />
      <Typography variant="caption" color="primary" sx={{ fontSize: 11 }}>
        {getImportanceValue(currentValue)}
      </Typography>
    </Box>
  );
};

export default ScoringInputForm;
