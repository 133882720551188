import * as React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectFoldersByParentId, useUpdateMultiProjectMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { TextFieldValue } from "fond/form/fields/TextField";
import { Folder, Store } from "fond/types";
import { compose, required } from "fond/utils/validation";
import { Message as MessageWidget, Modal } from "fond/widgets";

interface IFormData {
  Name: string;
  Description: string;
}

interface IProps {
  /**
   * The City Planner Folder being edited
   */
  cityPlannerFolder: Folder;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Optional Callback function for when the form is submitted
   */
  onSubmit?(values: IFormData): void;
}

const EditCityPlannerModal: React.FC<IProps> = ({ cityPlannerFolder, onClose, onSubmit }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const siblings = useSelector((state: Store) => {
    return selectFoldersByParentId(state, cityPlannerFolder.ParentID);
  });
  const [updateMultiProject, { isLoading: isSaving, isError }] = useUpdateMultiProjectMutation();

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name, Description }: IFormData) => {
    if (cityPlannerFolder.MultiProject?.ID) {
      try {
        await updateMultiProject({ ID: cityPlannerFolder.MultiProject?.ID, Name, Description });
        enqueueSnackbar("The city planner project has been updated.");
        onClose();
      } catch (e) {
        enqueueSnackbar("Project updates failed. Please try again...");
      }
    }
  };

  /**
   * Validates that the new city planner name is currently not in use
   * @param value New Name value
   */
  const uniqueName = (value: TextFieldValue) => {
    const newName = value?.toString().toLowerCase();
    const existingFolders = siblings.some(
      (existingFolder: Folder) => existingFolder.ID !== cityPlannerFolder.ID && existingFolder.Name.toLowerCase() === newName
    );
    if (existingFolders) {
      return "This name is already in use. Please use another.";
    }
    return undefined;
  };

  return (
    <Modal
      open
      header="Edit this city planner"
      data-testid="edit-city-planner-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <MessageWidget type="error">There was an issue updating this city planner project. Please try again.</MessageWidget>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{ Name: cityPlannerFolder.Name, Description: cityPlannerFolder.Description }}
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id="edit-city-planner-form">
                <Box maxWidth={300}>
                  <TextField name="Name" required label="Name" fullWidth validate={compose(required, uniqueName)} />
                </Box>
                <Box mt={2}>
                  <TextField name="Description" label="Description" fullWidth multiline rows={3} />
                </Box>
              </form>
            )}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="edit-city-planner-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="edit-city-planner-save-button" color="primary" loading={isSaving} type="submit" form="edit-city-planner-form">
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditCityPlannerModal;
