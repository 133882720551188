import React from "react";
import { Step } from "react-joyride";
import { useSelector } from "react-redux";
import { Link, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getCurrentProject } from "fond/project";
import { Store, Tour } from "fond/types";
import { SupportLink } from "fond/widgets";

import BaseTour from "./GuidedTour";

interface IProps {
  onComplete(): void;
}

const useCustomStyles = makeStyles((theme: Theme) => ({
  chip: {
    fontSize: 12,
    height: 16,
    fontWeight: 500,
  },
  list: {
    fontSize: 14,
  },
}));

const LayoutTour: React.FC<IProps> = ({ onComplete }: IProps) => {
  const classes = useCustomStyles();
  const hasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);

  const steps: Step[] = [
    {
      title: "Welcome to your first project",
      target: ".flexlayout__border_left",
      content: (
        <Typography variant="body2" gutterBottom>
          A project is a place that stores all of the data for a single network design & provides a visualization of that design.
        </Typography>
      ),
      placement: "center",
      showSkipButton: false,
    },
    {
      title: "What's on the page",
      target: "[data-testid='legend']",
      content: (
        <>
          <Typography variant="body2">When viewing a project there are several key widgets to help you:</Typography>
          <ul className={classes.list}>
            <li>Map</li>
            <li>Legend</li>
            <li>Design Tools</li>
            <li>Comments</li>
            <li>Features List</li>
            <li>Error Log</li>
            {hasCustomLayerConfig && <li>Versions</li>}
          </ul>
          <Typography variant="body2">
            For more information on what you can do within a project please visit our{" "}
            <Link href="https://fondhelp.biarrinetworks.com" target="_blank" rel="noopener noreferrer">
              Knowledge Base
            </Link>
            .
          </Typography>
        </>
      ),
      placement: "left",
    },
    {
      title: "Want more space?",
      target: "[data-layout-path='/c1/ts0/tabstrip'] .flexlayout__tab_toolbar",
      content:
        "If you are in need of more space on the screen there is the ability for widgets to be maximized. If that is not enough, you can undock the widget into its own floating window and then move onto a second screen.",
      placement: "left",
    },
    {
      title: "Topbar Menu",
      target: "[data-testid='project-menu-bar']",
      content:
        "The topbar menu contains several actions that can be used to modify how a project is viewed, as well as the ability to manage project attachments, design files and the Bill of Materials report.",
      placement: "bottom",
    },
    {
      title: "Sharing a Project",
      target: "[data-testid='top-bar-right']",
      content:
        "You can share the project with other users in order to collaborate on a design. You can also access your account details & logout when required.",
      placement: "bottom",
    },
    {
      title: "Knowledge Base",
      target: "body",
      content: (
        <Typography variant="body2" gutterBottom>
          For further information on creating, viewing or downloading projects please visit our{" "}
          <Link color="primary" href="https://fondhelp.biarrinetworks.com" target="_blank" rel="noopener noreferrer">
            Knowledge Base
          </Link>{" "}
          or feel free to <SupportLink text="contact us" /> with your queries.
        </Typography>
      ),
      placement: "center",
      showSkipButton: false,
      showProgress: false,
    },
  ];

  return (
    <BaseTour
      run
      tourKey={Tour.PROJECT_INTRO}
      disableCloseOnEsc
      disableOverlayClose
      steps={steps}
      continuous
      showSkipButton
      showProgress
      onComplete={onComplete}
      onSkip={onComplete}
    />
  );
};

export default LayoutTour;
