import TextField, { TextFieldProps } from "@mui/material/TextField";

export default function NumericInput({ inputProps, ...other }: TextFieldProps): React.ReactElement {
  return (
    <TextField
      // Slightly better than type="number"; see:
      // https://next.material-ui.com/components/text-fields/#type-quot-number-quot
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9.]*",
        ...inputProps,
      }}
      {...other}
    />
  );
}
