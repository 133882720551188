import React, { useContext, useState } from "react";

import { MagnetIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const SnappingButton: React.FC<IButtonProps> = () => {
  const { drawControl } = useContext(MapContext);
  const [snap, setSnap] = useState(drawControl.current?.options?.snap || false);

  const handleOnChange = () => {
    const toggle = !!drawControl.current?.options?.snap;

    setSnap(!toggle);
    if (drawControl.current?.options?.snap !== undefined) drawControl.current.options.snap = !toggle;
  };

  return (
    <ToolbarToggleButton title={`${snap ? "Disable" : "Enable"} snapping`} value="snap" onChange={handleOnChange} selected={snap}>
      <MagnetIcon />
    </ToolbarToggleButton>
  );
};

export default SnappingButton;
