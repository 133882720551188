import { createEntityAdapter, createSelector, Dictionary } from "@reduxjs/toolkit";
import { isEqual, omit } from "lodash";
import { createSelectorCreator, defaultMemoize } from "reselect";

import {
  ConfigAttribute,
  Configuration,
  ConfigurationHydrated,
  GroupConfig,
  GroupConfigHydrated,
  MapLayerConfig,
  Optional,
  SomeRequired,
  Store,
  Style,
  StyleWithoutID,
} from "fond/types";
import {
  FilterConfiguration,
  LayerConfig,
  LayerConfigHydrated,
  LayerStyle,
  SublayerConfig,
  SublayerConfigHydrated,
} from "fond/types/ProjectLayerConfig";

import { apiSlice } from "./apiSlice";
import { selectVersionMlcId, transformGetVersionConfigResponse } from "./versionsSlice";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const draftConfigEntityAdapter = createEntityAdapter<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>({
  selectId: (item) => item.ID,
});

const initialConfigData: Configuration = {
  ID: "",
  Key: "",
  SourceID: "",
  MapChildren: [],
  Data: draftConfigEntityAdapter.getInitialState(),
  Type: "MapLayerConfig",
};

/**
 * Transforms the {@link LayerStyle} type used within the FOND UI to the {@link Style} type
 * required by the serve routes.
 */
export const transformStylePayload = (
  style: Optional<LayerStyle, "MapboxStyle">,
  index?: number,
  trimIds?: boolean
): Optional<Style, "MapboxStyle"> =>
  ({
    ...omit(style, "RawStyles", "GlobalPosition", ...(trimIds ? ["ID", "ConfigurationID"] : [])),
    ...style.RawStyles,
    ...(index !== undefined ? { Position: index } : {}),
  }) as Optional<Style, "MapboxStyle">;

export const transformStyleToLayerStyle = (style: Style | StyleWithoutID, index?: number): LayerStyle => {
  const { ID, ConfigurationID, ConfigurationType, GlobalPosition, Name, MaxZoom, MapboxStyle, MinZoom, Position, ...rawStyles } = style;

  return {
    ConfigurationID,
    ConfigurationType,
    GlobalPosition,
    ID,
    MapboxStyle,
    Name,
    Position,
    Type: "STYLE",
    RawStyles: rawStyles,
  } as LayerStyle;
};

/**
 * Transforms the {@link Configuration} type used within the FOND UI to the {@link ConfigurationHydrated} types
 * required by the backend.
 */
export const hydrateConfiguration = (config: SomeRequired<Configuration, "Data" | "MapChildren">, trimIds?: boolean): ConfigurationHydrated => {
  const draft = config.Data.entities;

  const mappedChildren = config.MapChildren.map((childId) => {
    const child = draft[childId];
    if (child?.Type === "GROUP") {
      return hydrateGroup(draft, child, trimIds);
    }
    if (child?.Type === "LAYER") {
      return hydrateLayer(draft, child, undefined, trimIds);
    }

    // Only groups and layers can appear at the root level of the configuration, so this should never happen.
    return null;
  }).filter((_config): _config is GroupConfigHydrated | LayerConfigHydrated => _config !== null);

  return { MapChildren: mappedChildren };
};

/**
 * Transforms the {@link ConfigAttribute} types used within the FOND UI to the form required by the backend.
 */
export const hydrateAttributeConfiguration = (attribute: ConfigAttribute, trimIds?: boolean): ConfigAttribute => {
  return omit(attribute, trimIds ? ["ID", "LayerConfigurationID"] : []);
};

/**
 * Transforms the {@link FilterConfiguration} into the appropriate form for the backend.
 */
export const hydrateFilterConfiguration = (filter: FilterConfiguration, trimIds?: boolean): FilterConfiguration => {
  return {
    ...filter,
    Values: filter?.Values?.map((value) => ({
      ...value,
      Attribute: hydrateAttributeConfiguration(value.Attribute, trimIds),
    })),
  };
};

/**
 * Transforms the {@link LayerConfig} and {@link SublayerConfig} types used within the FOND UI to the
 * {@link LayerConfigHydrated} and {@link SublayerConfigHydrated} types required by the serve routes.
 */
export const hydrateLayer = (
  draft: Dictionary<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>,
  layer: LayerConfig | SublayerConfig,
  layerIndex?: number,
  trimIds?: boolean
): LayerConfigHydrated | SublayerConfigHydrated => {
  if (layer.Type === "LAYER") {
    return {
      ...omit(layer, trimIds ? ["ID", "ParentID", "RootID"] : []),
      ...(layerIndex !== undefined ? { Position: layerIndex } : {}),
      Attributes: layer.Attributes?.map((attribute) => hydrateAttributeConfiguration(attribute, trimIds)),
      Children: layer.Children.map((childId, childIndex) => {
        return hydrateLayer(draft, draft[childId] as LayerConfig | SublayerConfig, childIndex, trimIds) as SublayerConfigHydrated;
      }),
      Styles: layer.Styles.map((styleId, styleIndex) => transformStylePayload(draft[styleId] as LayerStyle, styleIndex, trimIds)),
    } as LayerConfigHydrated;
  } else if (layer.Type === "SUBLAYER") {
    return {
      ...omit(
        {
          ...layer,
          FilterConfiguration: layer.FilterConfiguration !== null ? hydrateFilterConfiguration(layer.FilterConfiguration, trimIds) : null,
        },
        "GeometryType",
        "Key",
        // For sublayers using the dynamic filtering option the API will not accept
        // the Mapbox property being included (other Sublayers require this).
        ...(layer.FilterConfiguration?.Type === "other" ? ["FilterConfiguration.Mapbox"] : []),
        ...(trimIds ? ["ID", "ParentID", "RootID"] : [])
      ),
      ...(layerIndex !== undefined ? { Position: layerIndex } : {}),
      Styles: layer.Styles.map((styleId, styleIndex) => transformStylePayload(draft[styleId] as LayerStyle, styleIndex, trimIds)),
    } as unknown as SublayerConfigHydrated;
  } else {
    throw new Error("Invalid layer type");
  }
};

/**
 * Transforms the {@link GroupConfig} type used within the FOND UI to the {@link GroupConfigHydrated} type
 * required by the serve routes.
 */
export const hydrateGroup = (
  draft: Dictionary<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>,
  group: GroupConfig,
  trimIds?: boolean
): GroupConfigHydrated =>
  ({
    ...omit(group, trimIds ? ["ID", "RootID", "ParentID"] : []),
    Children: group.Children.map((childId, childIndex) => {
      const child = draft[childId];
      if (child?.Type === "GROUP") return hydrateGroup(draft, child, trimIds);
      return hydrateLayer(draft, child as LayerConfig, childIndex, trimIds);
    }),
  }) as GroupConfigHydrated;

export const draftSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDraft: build.query<Configuration, string>({
      query: (versionConfigId) => `/v2/root-configurations/${versionConfigId}/draft`,
      transformResponse: transformGetVersionConfigResponse,
      providesTags: (result, error, arg) => (result ? [{ type: "Draft", id: arg }] : []),
    }),
    createDraft: build.mutation({
      query: (versionConfigId) => ({
        url: "/v2/root-configurations/draft",
        method: "POST",
        body: { SourceID: versionConfigId },
      }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: "Draft", id: arg }] : []),
    }),
    getRootConfig: build.query<Configuration, string>({
      query: (rootConfigId) => `/v2/root-configurations/${rootConfigId}`,
      transformResponse: transformGetVersionConfigResponse,
      providesTags: (result, error, rootConfigId) => (result ? [{ type: "Draft", id: rootConfigId }] : []),
    }),
    updateRootConfig: build.mutation({
      query: ({
        versionConfig,
        trimIds,
      }: {
        versionConfig: SomeRequired<Configuration, "ID" | "Data" | "MapChildren">;
        trimIds?: boolean;
        versionId?: string;
      }) => ({
        url: `/v2/root-configurations/${versionConfig.ID}`,
        method: "PUT",
        body: hydrateConfiguration(versionConfig, trimIds),
      }),
      invalidatesTags: (result, error, { versionConfig: { ID }, versionId }) =>
        result
          ? [
              { type: "Draft" as const, id: ID },
              // If this root configuration is known to be associated with a version, then
              // invalidate the relevant data for that version.
              ...(versionId
                ? [
                    { type: "Version" as const, id: versionId },
                    { type: "VersionRootConfiguration" as const, id: versionId },
                    { type: "Layers" as const, id: versionId },
                    { type: "FeatureTotals" as const, id: versionId },
                  ]
                : []),
            ]
          : [],
    }),
    publishDraft: build.mutation({
      query: ({ draftId }: { draftId: string; versionId: string }) => ({
        url: `/v2/root-configurations/${draftId}/publish`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { draftId, versionId }) =>
        result
          ? [
              { type: "Draft", id: draftId },
              // Publishing a draft on a version invalidates a bunch of data relating to that version.
              { type: "Version", id: versionId },
              { type: "VersionRootConfiguration", id: versionId },
              { type: "Layers", id: versionId },
              { type: "FeatureTotals", id: versionId },
            ]
          : [],
    }),
    deleteDraft: build.mutation({
      query: (draftId) => ({
        url: `/v2/root-configurations/${draftId}`,
        method: "DELETE",
      }),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetDraftQuery,
  useCreateDraftMutation,
  useLazyGetRootConfigQuery,
  useUpdateRootConfigMutation,
  usePublishDraftMutation,
  useDeleteDraftMutation,
} = draftSlice;

/**
 * Selectors
 */
export const selectDraftData = createDeepEqualSelector([(state) => state, selectVersionMlcId], (state: Store, mapLayerConfigId) => {
  if (!mapLayerConfigId) return initialConfigData;
  const data = createSelector(draftSlice.endpoints.getDraft.select(mapLayerConfigId), (draftResult) => draftResult.data)(state);
  return data ?? initialConfigData;
});

export const {
  selectAll: selectAllDraftConfigs,
  selectById: selectAllDraftConfigEntitiesById,
  selectEntities: selectDraftConfigEntities,
} = draftConfigEntityAdapter.getSelectors((state: Store) => selectDraftData(state).Data);

export const selectAllDraftGroups = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "GROUP") as GroupConfig[];
});

export const selectAllLayerConfigs = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "LAYER") as LayerConfig[];
});

export const selectAllSublayerLayerConfigs = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "SUBLAYER") as LayerConfig[];
});

export const selectAllDraftStyles = createSelector(selectAllDraftConfigs, (entities) => {
  return entities.filter((item) => item.Type === "STYLE") as LayerStyle[];
});

export const selectLayerConfigById = createSelector([selectAllLayerConfigs, (state: Store, id?: string) => id], (layerConfigs, id) => {
  return layerConfigs.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
});

export const selectSublayerConfigById = createSelector([selectAllSublayerLayerConfigs, (state: Store, id?: string) => id], (layerConfigs, id) => {
  return layerConfigs.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
});

export const selectDraftStyleById = createSelector([selectAllDraftStyles, (state: Store, id?: string) => id], (styles, id) => {
  return styles.find((style) => style.ID === id) as LayerStyle;
});

/**
 * Returns the Sublayer within a layerConfig that has a FilterConfiguration Type set to "other"
 */
export const selectSublayerOtherSiblingByParentId = createSelector([selectAllDraftConfigs, (state: Store, id: string) => id], (all, id) => {
  const parent = all.find((layerConfig) => layerConfig.ID === id) as LayerConfig;
  const otherSublayer: SublayerConfig | null = parent?.Children.reduce<SublayerConfig | null>((value, childId) => {
    const child = all.find(({ ID }) => ID === childId) as SublayerConfig;
    return value || (child.FilterConfiguration?.Type === "other" ? child : null);
  }, null);

  return otherSublayer;
});

/**
 * Selector that returns the current entities ancestors
 */
export const selectAncestors = createSelector(
  [selectDraftData, (state: Store, entityId: string) => entityId],
  ({ Data, ID: mclConfigID }, entityId) => {
    const ancestors: Array<MapLayerConfig | GroupConfig | LayerConfig | SublayerConfig | LayerStyle> = [];
    const getAncestorId = (item: GroupConfig | LayerConfig | SublayerConfig | LayerStyle) => {
      switch (item.Type) {
        case "LAYER":
        case "CommentConfig":
          return item.ParentID || mclConfigID;
        case "SUBLAYER":
          return item.ParentID;
        case "STYLE":
          return item.ConfigurationID;
        case "GROUP":
          return item.RootID;
        default:
          throw new Error("Invalid item type");
      }
    };
    const getAncestor = (itemId: string) => {
      const item = Data.entities[itemId];
      if (!item) return;
      const ancestorId = getAncestorId(item);
      if (ancestorId) {
        if (itemId !== entityId) ancestors.push(item);
        getAncestor(ancestorId);
      }
    };
    getAncestor(entityId);

    return ancestors;
  }
);

export const selectDraftEntityChildrenById = createSelector([selectDraftConfigEntities, (state: Store, id: string) => id], (entities, id) => {
  const children: Array<GroupConfig | LayerConfig | SublayerConfig | LayerStyle> = [];
  const parent = entities[id] as GroupConfig | LayerConfig;

  const add = (entity?: GroupConfig | LayerConfig | SublayerConfig | LayerStyle) => {
    if (!entity) return;
    if (parent.ID !== entity.ID) children.push(entity);

    if (entity.Type === "GROUP") {
      entity.Children.forEach((childId) => {
        add(entities[childId]);
      });
    } else if (entity.Type === "LAYER") {
      [...entity.Children, ...entity.Styles].forEach((childId) => {
        add(entities[childId]);
      });
    } else if (entity.Type === "SUBLAYER") {
      entity.Styles.forEach((childId) => {
        add(entities[childId]);
      });
    }
  };

  add(parent);

  return children;
});

export const selectLayerConfigByChildId = createSelector(selectAncestors, (ancestors) => ancestors.find((item) => item.Type === "LAYER"));

/**
 * Returns a flat list of layers and sublayers in the correct order based
 * on their root, group & layer ordering.
 *
 */
export const selectAllDraftConfigsInOrder = createSelector(selectDraftData, (configuration) => orderLayersByConfiguration(configuration));

/**
 * Returns a list of layers in the order they should appear within the legend based on the
 * parent/child relationship defined within the configuration.
 * @param config the source version configuration.
 * @param options Allows for the ignoreExcluded flag to be set, indicating if layers marked as "Exclude" should be included.
 * @returns An ordered collection of layers & sublayers
 */
export const orderLayersByConfiguration = (
  { Data, MapChildren }: Configuration,
  options: { ignoreExcluded: boolean } = { ignoreExcluded: false }
): Array<LayerConfig | SublayerConfig> => {
  const result: Array<LayerConfig | SublayerConfig> = [];

  // Iterate through the LayerConfigs children & include them into the result
  const iterLayerConfig = (layer: LayerConfig) => {
    if (layer && (!options.ignoreExcluded || !layer.Exclude)) {
      result.push(layer);
      layer.Children.forEach((childId) => {
        const sublayer = Data.entities[childId] as SublayerConfig;
        if (sublayer) result.push(sublayer);
      });
    }
  };

  // Iterate through the groupConfigs (supporting nested groups that is possible via fond_cli commands)
  const iterGroupConfig = (group: GroupConfig) => {
    group.Children.forEach((childId) => {
      const childEntity = Data.entities[childId] as LayerConfig | GroupConfig;
      if (childEntity && childEntity.Type === "LAYER") iterLayerConfig(childEntity);
      if (childEntity && childEntity.Type === "GROUP") iterGroupConfig(childEntity);
    });
  };

  MapChildren.forEach((rootChildId) => {
    const rootChild = Data.entities[rootChildId];
    if (rootChild?.Type === "GROUP" && "Children" in rootChild) {
      rootChild.Children.forEach((childId) => {
        const child = Data.entities[childId] as LayerConfig | GroupConfig;
        if (child.Type === "LAYER") {
          iterLayerConfig(child);
        } else if (child.Type === "GROUP") {
          iterGroupConfig(child);
        }
      });
    } else if (rootChild?.Type === "LAYER") {
      const layer = Data.entities[rootChildId] as LayerConfig;
      iterLayerConfig(layer);
    }
  });

  return result;
};
