import React, { useState } from "react";
import { Form } from "react-final-form";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { AWSError } from "aws-sdk";
import { FORM_ERROR } from "final-form";

import { useUpdateInvitationMutation } from "fond/api";
import { INVITATION_ID_KEY, INVITATION_TOKEN_KEY } from "fond/constants";
import { CognitoResponseCode } from "fond/utils";
import { useQueryParams } from "fond/utils/hooks";

import ResendEmail from "./ResendEmail";
import SignUpForm from "./SignUpForm";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termsOfServiceAgreement: boolean;
}

const SignUp: React.FC = () => {
  const [cognitoResponseCode, setCognitoResponseCode] = useState<string | null>(null);
  const invitationId = useQueryParams(INVITATION_ID_KEY);
  const inviteToken = useQueryParams(INVITATION_TOKEN_KEY);
  const [updateInvitation] = useUpdateInvitationMutation();

  const handleOnSubmit = async ({ firstName, lastName, email, password }: IFormData) => {
    const lowercaseEmail = email.toLowerCase();
    return new Promise((resolve) => {
      Auth.signUp({
        username: lowercaseEmail,
        password,
        attributes: {
          email: lowercaseEmail,
          given_name: firstName,
          family_name: lastName,
        },
      })
        .then(async () => {
          if (invitationId && inviteToken) {
            try {
              await updateInvitation({
                invitation: { ID: invitationId, InvitationStatus: "accepted" },
                userType: "new",
                token: inviteToken,
              });
            } catch (error) {
              Sentry.captureException(error);
            }
          }
          resolve(undefined);
        })
        .catch((error: AWSError) => {
          setCognitoResponseCode(error.code);

          if (error.code === CognitoResponseCode.USERNAME_EXISTS_EXCEPTION) {
            resolve({ email: error.message });
          }

          if (
            error.code === CognitoResponseCode.INVALID_PASSWORD_EXCEPTION ||
            (error.code === CognitoResponseCode.INVALID_PARAMETER && error.message.indexOf("password") > -1)
          ) {
            resolve({
              password:
                "To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 8 characters.",
            });
          }

          // Fallback is to raise a form error
          resolve({ [FORM_ERROR]: error.message });
        });
    });
  };

  return (
    <Form<IFormData>
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        termsOfServiceAgreement: false,
      }}
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitting, submitError, submitSucceeded, values }) =>
        submitSucceeded ? (
          <ResendEmail email={values.email} />
        ) : (
          <SignUpForm
            handleSubmit={handleSubmit}
            submitting={submitting}
            submitError={submitError}
            cognitoResponseCode={cognitoResponseCode}
            setCognitoResponseCode={setCognitoResponseCode}
          />
        )
      }
    />
  );
};

export default SignUp;
