import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.biarri.secondary.whiteSmoke,
}));

export const Content = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "0 0 4px 4px",
  padding: theme.spacing(1),
}));

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: theme.typography.fontWeightMedium,
  paddingLeft: theme.spacing(1),
  cursor: "move",
  "& svg": {
    width: 16,
    height: 16,
  },
}));
