import React from "react";
import { Box, CircularProgress, CircularProgressProps, SxProps } from "@mui/material";

import styles from "./BlockSpinner.module.scss";

interface IProps {
  containerProps?: SxProps;
  spinnerProps?: CircularProgressProps;
}

const BlockSpinner: React.FC<IProps> = ({ containerProps, spinnerProps }: IProps) => {
  return (
    <Box className={styles.container} sx={containerProps} data-testid="loading-spinner">
      <CircularProgress className={styles.spinner} {...spinnerProps} />
    </Box>
  );
};

export default BlockSpinner;
