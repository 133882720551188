import React, { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { skipToken } from "@reduxjs/toolkit/query";
import { isEqual } from "lodash";

import { useGetVersionConfigQuery, versionsSlice } from "fond/api";
import { useAppDispatch } from "fond/utils/hooks";
import localStorage from "fond/utils/localStorage";

/**
 * Hook that updates a cached versionConfig by marking layers as "Excluded".
 * Excluded layers will not be added to the map or legend.
 * @param versionId the id of the versionConfig to be modified
 * @param excludedLayerIds the ids of the layers to be marged as Excluded
 */
export const useFilterLayers = (
  versionId: string | undefined,
  defaultValues?: string[] | undefined
): {
  excludedLayerIds: string[];
  setExcludedLayerIds: React.Dispatch<React.SetStateAction<string[]>>;
} => {
  const dispatch = useAppDispatch();
  const { data } = useGetVersionConfigQuery(versionId ?? skipToken);
  const [excludedLayerIds, setExcludedLayerIds] = useState<string[]>(
    localStorage.getItem(`state.project.projects[${versionId}].layerFilter`, defaultValues || [])
  );
  const prevLayerIds = usePrevious(excludedLayerIds);

  useEffect(() => {
    if (data && versionId && !isEqual(prevLayerIds, excludedLayerIds)) {
      const exclusions = excludedLayerIds ?? (localStorage.getItem(`state.project.projects[${versionId}].layerFilter`, []) as string[]);
      dispatch(
        versionsSlice.util.updateQueryData("getVersionConfig", versionId, (draft) => {
          draft.Data.ids.forEach((id) => {
            if (draft.Data.entities[id]?.Type === "LAYER") {
              draft.Data.entities[id].Exclude = exclusions.includes(String(id));
            }
          });
        })
      );
    }
  }, [excludedLayerIds, dispatch, data, versionId, prevLayerIds]);

  return { excludedLayerIds, setExcludedLayerIds };
};
