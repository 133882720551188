import React, { useCallback, useContext, useEffect, useState } from "react";

import { SplitPolygon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const SplitButton: React.FC<IButtonProps> = ({ mode }) => {
  const { drawControl, map } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on if a single Multipolygon is selected.
   */
  const handleOnSelect = useCallback(() => {
    const selectedFeatures = drawControl.current.getSelected().features;

    // Confirm that the selected feature is a multiPolygon but also have multiple coordinates.
    if (selectedFeatures.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnChange = () => {
    if (mode === "split_polygon_passing_draw_line_string") {
      drawControl.current?.changeMode("simple_select");
      map?.fire("draw.modechange", { type: "draw.modechange", mode: "simple_select" });
    } else {
      drawControl.current?.changeMode("split_polygon");
    }
  };

  const selected = mode === "split_polygon_passing_draw_line_string";
  return (
    <ToolbarToggleButton title="Split features" value="split" selected={selected} onChange={handleOnChange} disabled={!selected && isDisabled}>
      <SplitPolygon />
    </ToolbarToggleButton>
  );
};

export default SplitButton;
