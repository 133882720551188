import { IRowNode } from "@ag-grid-community/core";

/**
 * Comparator for ordering strings (case insensitive)
 */
const stringComparator = (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isInverted: boolean): number => {
  return valueA.localeCompare(valueB);
};

export default stringComparator;
