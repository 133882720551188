import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import uniqBy from "lodash/uniqBy";

import { selectFolderById } from "fond/api";
import { useContents } from "fond/hooks/useContents";
import { useBreadcrumbs } from "fond/projects/Breadcrumbs/hooks/useBreadcrumbs";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { updateSharedAccount } from "../redux";

import { CrumbedChip, Crumbs } from "./breadcrumbs.styles";

const SharedBreadcrumbs: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { folderId, reverseAncestors } = useBreadcrumbs();
  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const { account: filteredAccountId } = useSelector((state: Store) => state.projects);
  const userEmail = useSelector((state: Store) => state.cognito.user?.username);
  const { sharedItems } = useContents();
  const uniqueAccounts = uniqBy(
    sharedItems.map((item) => item.Account),
    "Name"
  );
  const selectedAccount = uniqueAccounts.find((account) => account.ID === filteredAccountId) ?? currentFolder?.Account;
  // ancestors without permissions does not get returned by the folder
  const firstSharedFolder = reverseAncestors.findIndex((ancestor) => ancestor.Creator !== userEmail);
  // remove any ancestors above the first shared folder from breadcrumbs
  const sharedAncestors = firstSharedFolder !== -1 ? reverseAncestors.slice(firstSharedFolder) : reverseAncestors;

  const handleSharedClick = (accountId?: string) => () => {
    const path = accountId ? `/shared?account=${accountId}` : "/shared/";
    navigate(path);
    dispatch(updateSharedAccount(accountId));
  };

  return (
    <Crumbs>
      <CrumbedChip label="Shared with me" onClick={handleSharedClick()} />
      {selectedAccount && <CrumbedChip label={selectedAccount.Name} onClick={handleSharedClick(selectedAccount.ID)} />}
      {sharedAncestors.slice(0, -1).map((folder) => (
        <CrumbedChip key={folder.ID} label={folder.Name} onClick={() => navigate(`/folders/${folder.ID}`)} />
      ))}

      {!!sharedAncestors.length && <CrumbedChip label={reverseAncestors[reverseAncestors.length - 1].Name} />}
    </Crumbs>
  );
};

export default SharedBreadcrumbs;
