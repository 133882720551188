import React from "react";

import { CrumbedChip, Crumbs } from "./breadcrumbs.styles";

const RecentBreadcrumbs: React.FC = () => {
  return (
    <Crumbs>
      <CrumbedChip label="Recent" />
    </Crumbs>
  );
};

export default RecentBreadcrumbs;
