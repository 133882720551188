import { ProjectData, ProjectState, UploadType } from "fond/types";

function getCurrentProjectProp<T extends keyof ProjectData>(state: ProjectState, prop: T): ProjectData[T] {
  return state.projectId != null && state.projects[state.projectId] != null ? state.projects[state.projectId][prop] : null;
}

export function getCurrentProject(state: ProjectState) {
  return getCurrentProjectProp(state, "project");
}

export function getCurrentProjectData(state: ProjectState) {
  return getCurrentProjectProp(state, "data");
}

export function getCurrentProjectView(state: ProjectState) {
  return getCurrentProjectProp(state, "view");
}

export function getCurrentProjectLayerFeatureTotals(state: ProjectState) {
  return getCurrentProjectProp(state, "layerFeatureTotals");
}

export function getCurrentProjectHighlights(state: ProjectState) {
  return getCurrentProjectProp(state, "highlightedFeatures");
}

export function getCurrentProjectUploads(state: ProjectState): { [layerKey: string]: UploadType } {
  return getCurrentProjectProp(state, "uploads");
}
