import { Box, Paper, styled } from "@mui/material";

export const Container = styled(Paper)({
  boxShadow: `0px 1px 2px 1px rgba(0, 0, 0, 0.05)`,
});

export const TabContent = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: `${theme.spacing(7)} ${theme.spacing(9)}`,
}));

export const UserManagementTabContent = styled(TabContent)(({ theme }) => ({
  paddingTop: theme.spacing(2.25),
}));
