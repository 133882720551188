import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterChangedEvent, FilterModel, FirstDataRenderedEvent, GridApi, GridReadyEvent } from "@ag-grid-community/core";
import { AttachMoney, MultipleStop, SaveAlt, Straighten, TrendingDown, TrendingUp } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AgNodeClickEvent } from "ag-charts-community";

import mixpanel from "fond/mixpanel";
import { CostBreakdown, CTSType, FullReport } from "fond/types";
import { convertFeetToMeters } from "fond/utils";
import { formatCurrency } from "fond/utils/currency";
import { PaperRightIconWrapper } from "fond/views/Report/report.styles";

import GridCard from "../GridCard";

import ReportCostChart, { MAX_DRILL_DEPTH } from "./ReportCostChart/ReportCostChart";
import { NavigationItem } from "./ReportChartNavigation";
import ReportCostData from "./ReportCostData";
import { ReportCostMap } from "./ReportCostMap";
import ReportCostPassingsWarning from "./ReportCostPassingsWarning";

interface IProps {
  report: FullReport;
}

const ReportCostPageContent: React.FC<IProps> = ({ report }: IProps) => {
  const gridApi = useRef<GridApi<Omit<CostBreakdown, "CostBins">> | null>();
  const [depth, setDepth] = useState(0);
  const filterModel = useRef<FilterModel>({});
  let averageCostPerDistance = report.AverageCostPerDistance;
  // The average cost per distance is calculated by dividing the total construction cost
  // by the total path length in meters. To convert the metric to feet,  would need to
  // divide ~3.28 because the length is used as a divisor
  if (averageCostPerDistance && report.SystemOfMeasurement === "imperial") {
    averageCostPerDistance = convertFeetToMeters(report.AverageCostPerDistance);
  }
  const [activeBins, setActiveBins] = useState<string[] | undefined>(undefined);
  const [costToServeMethod, setCostToServeMethod] = useState<CTSType>("SharedCost");
  const totalPassingCount = report.Passings?.SharedCost?.reduce((sum, { PassingCount }) => sum + PassingCount, 0);

  const [rowData, setRowData] = useState<Omit<CostBreakdown, "CostBins">[] | undefined>();
  const [navigation, setNavigation] = useState<NavigationItem[]>([
    {
      label: "Total Costs",
      filter: {},
    },
  ]);

  /**
   * Takes the user up a single depth.  This is called when the
   * user clicks on the chart but not on a segment, allowing for navigating
   * back up the data structure.
   */
  const drillUp = useCallback(() => {
    if (depth === 0) return;

    if (depth > 0) {
      setNavigation((prev) => prev.slice(0, -1));
    }

    const newModel = {
      ...gridApi.current!.getFilterModel(),
    };

    if (depth === 3) {
      delete newModel.RuleID;
    }

    if (depth === 2) {
      delete newModel.GroupID;
    }

    if (depth === 1) {
      delete newModel.Tags;
    }

    setDepth((prev) => prev - 1);
    gridApi.current?.setFilterModel(newModel);
  }, [depth]);

  /**
   * Takes the user down a single depth & redraws the chart with the new
   * columns / legend (based on the segment the user clicked on).
   */
  const drillDown = useCallback(
    (e: AgNodeClickEvent<Record<string, string>>) => {
      if (depth === MAX_DRILL_DEPTH) return;

      const newModel = {
        ...gridApi.current!.getFilterModel(),
        [e.datum.ColumnID]: {
          filterType: "set",
          values: [e.datum.Key],
        },
      };

      // Get the latest filterModel and store that to allow for navigation
      // and to build the compounding filtermodel
      setNavigation((prev) => [
        ...prev,
        {
          label: e.datum.Key,
          filter: {
            [e.datum.ColumnID]: {
              filterType: "set",
              values: [e.datum.Key],
            },
          },
        },
      ]);

      setDepth((prev) => prev + 1);
      gridApi.current?.setFilterModel(newModel);
    },
    [depth]
  );

  useEffect(() => {
    const newModel = {
      ...gridApi.current?.getFilterModel(),
      CostBins: {
        filterType: "set",
        values: activeBins,
      },
    };
    gridApi.current?.setFilterModel(newModel);
  }, [activeBins, costToServeMethod, report.IndexedCostSummary]);

  useEffect(() => {
    // Reset the active bins if the user changes the Cost to Serve method.
    setActiveBins(undefined);
  }, [costToServeMethod, report.IndexedCostSummary]);

  /**
   * Navigates the user back to a specific depth.  Used when the user clicks
   * on a breadcrumb item.
   * @param valuesToRemove
   */
  const navigateBackTo = (valuesToRemove: number) => {
    setNavigation((prev) => prev.slice(0, valuesToRemove));

    const newDepth = depth + valuesToRemove;
    setDepth(newDepth);

    // Update the grid reference
    const newModel = {
      ...gridApi.current!.getFilterModel(),
    };

    if (newDepth <= 1) {
      delete newModel.GroupID;
    }

    if (newDepth === 0) {
      delete newModel.Tags;
    }

    gridApi.current?.setFilterModel(newModel);
  };

  /**
   * Monitor the AgGrid initialization.
   */
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    // By default AgGrid mounts the popup to document.body however for
    // this page this results in the popup size calculation causing small popups.
    params.api.setGridOption("popupParent", undefined);
    gridApi.current?.setGridOption("rowData", report.IndexedCostSummary?.[costToServeMethod]);
  };

  /**
   * Monitor the AgGrid for when it first renders the data & create the require charts.
   */
  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    setRowData(getGridData());
  };

  /**
   * Monitor the AgGrid for filter changes
   */
  const onFilterChanged = (event: FilterChangedEvent) => {
    filterModel.current = event.api.getFilterModel();
    setRowData(getGridData());
    mixpanel.track("Report", "Cost", `Filtering datagrid by ${event.columns[0].getColDef().headerName}`);
  };

  /**
   * Triggers the download of the AgGrid data in excel format
   */
  const onDownloadData = () => {
    mixpanel.track("Report", "Cost", "Exported via datagrid");
    gridApi.current?.exportDataAsExcel();
  };

  const getGridData = (): Omit<CostBreakdown, "CostBins">[] => {
    const data: Omit<CostBreakdown, "CostBins">[] = [];
    gridApi.current?.forEachNodeAfterFilter((rowNode) => {
      if (rowNode.aggData) {
        const { GroupID, RuleID, Tags, TotalCost, Count, Unit } = rowNode.aggData;
        data.push({
          RuleID: RuleID[0],
          GroupID: GroupID[0],
          Tags: Tags[0],
          TotalCost,
          Count,
          Unit,
        });
      }
    });
    return data;
  };

  const singularMeasurementUnit = report.SystemOfMeasurement === "imperial" ? "foot" : "meter";
  const pluralMeasurementUnit = report.SystemOfMeasurement === "imperial" ? "feet" : "meters";

  return (
    <Grid container spacing={2.5} justifyContent="stretch">
      <ReportCostPassingsWarning report={report} />
      <GridCard breakpoints={{ lg: 3, sm: 6, xs: 12 }}>
        <Tooltip
          title="The total cost to “Construct” the network. It is the sum of all costs which were calculated in the Bill of Materials."
          followCursor
        >
          <Box height={105} display="flex" flexDirection="column" justifyContent="space-around" p={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="content" fontSize={12}>
                Total construction cost
              </Typography>
              <PaperRightIconWrapper>
                <AttachMoney />
              </PaperRightIconWrapper>
            </Box>
            <Box my={1} pl={2}>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatCurrency(report.TotalConstructionCost)}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </GridCard>
      <GridCard breakpoints={{ lg: 3, sm: 6, xs: 12 }}>
        <Tooltip
          title={`The Average cost per linear ${singularMeasurementUnit}. It is the Total Construction Cost divided by the total ${pluralMeasurementUnit} found in the design project`}
          followCursor
        >
          <Box height={105} display="flex" flexDirection="column" justifyContent="space-around" p={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="content" fontSize={12}>
                Avg. cost per linear {singularMeasurementUnit}
              </Typography>
              <PaperRightIconWrapper>
                <Straighten />
              </PaperRightIconWrapper>
            </Box>
            <Box my={1} pl={2}>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatCurrency(averageCostPerDistance)}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </GridCard>
      <GridCard breakpoints={{ lg: 6, xs: 12 }}>
        <Box display="flex" height={105} gap={10} px={3} py={2.5}>
          <Tooltip
            title="The Average cost per passing. It is the Total Construction Cost divided by the total number of passings found in the design project."
            followCursor
          >
            <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="content" fontSize={12} lineHeight={1.2}>
                  Avg. cost per passing
                </Typography>
                <MultipleStop fontSize="small" color="secondary" />
              </Box>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatCurrency(totalPassingCount && totalPassingCount > 0 ? (report?.TotalConstructionCost || 0) / totalPassingCount : null)}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip
            title="The lowest “SharedCost” value found for a service location. This is a local approximation of the average cost to pass an address."
            followCursor
          >
            <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="content" fontSize={12} lineHeight={1.2}>
                  Lowest cost passing
                </Typography>
                <TrendingDown fontSize="small" color="secondary" />
              </Box>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatCurrency(report.Passings?.SharedCost?.at(0)?.PassingMinCost)}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip
            title="The highest “SharedCost” value found for a service location. This is a local approximation of the average cost to pass an address."
            followCursor
          >
            <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="content" fontSize={12} lineHeight={1.2}>
                  Highest cost passing
                </Typography>
                <TrendingUp fontSize="small" color="secondary" />
              </Box>
              <Typography variant="h3" component="span" fontWeight={700}>
                {formatCurrency(report.Passings?.SharedCost?.at(-1)?.PassingMaxCost)}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </GridCard>
      <ReportCostChart
        depth={depth}
        data={rowData}
        onDrillDown={drillDown}
        onDrillUp={drillUp}
        totalCost={report.TotalConstructionCost || 0}
        navigation={navigation}
        navigateBackTo={navigateBackTo}
      />
      <ReportCostMap
        report={report}
        method={costToServeMethod}
        activeBins={activeBins}
        setActiveBins={setActiveBins}
        onMethodChange={setCostToServeMethod}
      />
      <GridCard
        breakpoints={{ xs: 12 }}
        title="Data"
        headerRightElement={
          <IconButton size="small" onClick={onDownloadData}>
            <SaveAlt fontSize="inherit" />
          </IconButton>
        }
      >
        <Box flex={1}>
          <ReportCostData onGridReady={onGridReady} onFirstDataRendered={onFirstDataRendered} onFilterChanged={onFilterChanged} />
        </Box>
      </GridCard>
    </Grid>
  );
};

export default ReportCostPageContent;
