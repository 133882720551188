import React, { useContext } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";

import { MoveIcon } from "fond/svg_icons";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarToggleButton from "./ToolbarToggleButton";

const SelectButton: React.FC<IButtonProps> = () => {
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    drawControl.current.changeMode("simple_select");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "simple_select" } as DrawModeChangeEvent);
  };

  return (
    <ToolbarToggleButton title="Select feature" value="simple_select" onClick={handleOnClick}>
      <MoveIcon />
    </ToolbarToggleButton>
  );
};

export default SelectButton;
