import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentAllocation } from "fond/api";
import ErrorPage from "fond/errorPage/ErrorPage";
import { PageContent } from "fond/widgets";

import { Page } from "../constants";
import Navigation from "../Navigation";
import { reportWindowTitle } from "../util";

import CreateReportForm from "./CreateForm";

const CreateReportPage: React.FC = () => {
  const currentAllocation = useSelector(selectCurrentAllocation);

  // check if the user has a license before allowing them to create a report.
  if (!currentAllocation?.License) {
    return (
      <ErrorPage
        title="No license assigned"
        heading="No license assigned"
        message="You do not have a license assigned on this account and cannot create a report."
      />
    );
  } else {
    return (
      <PageContent
        windowTitle={reportWindowTitle("Settings")}
        title="Settings"
        mainContent={<CreateReportForm />}
        navigation={<Navigation page={Page.SETTINGS} isCreatingReport />}
        testId="create-report-page"
      />
    );
  }
};

export default CreateReportPage;
