import React from "react";
import { Star, StarOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { useStarred } from "fond/projects/ItemMenu/hooks/useStarred";
import { Folder, Project, Report } from "fond/types";

import { IconButton } from "./topbar.styles";

interface IProps {
  entity: Project | Report | Folder;
}

const StarButton: React.FC<IProps> = ({ entity }) => {
  const { starred, addToStarred, removeFromStarred } = useStarred();

  const isEntityStarred = starred.includes(entity.ID);

  return (
    <Tooltip title={isEntityStarred ? "Remove from Starred" : "Add to Starred"}>
      <IconButton
        data-testid="starred-button"
        size="small"
        onClick={() => {
          if (isEntityStarred) removeFromStarred(entity);
          else addToStarred(entity);
        }}
      >
        {isEntityStarred ? <Star /> : <StarOutline />}
      </IconButton>
    </Tooltip>
  );
};

export default StarButton;
