import React, { useState } from "react";
import { CheckCircleOutline, DragHandle, RemoveCircleOutline } from "@mui/icons-material";
import { Box, ListItemIcon, MenuItem, Select, SelectChangeEvent, TableCell, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { useDebouncedCallback } from "use-debounce";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import NumberField from "fond/map/Field/NumberField";
import { getCurrentProject } from "fond/project";
import { useAppSelector } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";

import { PropertyNameCell, TableRow } from "./propertiesPanel.styles";

interface IProps {
  /**
   * The current feature properties being displayed
   */
  properties: Record<string, string | number>;
  /**
   * Callback function that handles the change in value
   */
  onChange(values: Record<string, string | number>): void;
}

export const enum TrafficLightValue {
  avoid = "avoid",
  neutral = "neutral",
  prefer = "prefer",
}

interface TrafficLightData {
  cost: number;
  text: string;
  icon: React.ReactElement;
}

export const trafficLightData: { [key in TrafficLightValue]: TrafficLightData } = {
  [TrafficLightValue.neutral]: { cost: 1, text: "Neutral", icon: <DragHandle fontSize="small" htmlColor={grey[400]} /> },
  [TrafficLightValue.avoid]: { cost: 10, text: "Avoid", icon: <RemoveCircleOutline fontSize="small" htmlColor={red[400]} /> },
  [TrafficLightValue.prefer]: { cost: 0.25, text: "Prefer", icon: <CheckCircleOutline fontSize="small" htmlColor={green[400]} /> },
};

const PathPreferenceEdit: React.FC<IProps> = ({ properties, onChange }: IProps) => {
  const [customValue, setCustomValue] = useState(properties.CostFactor);
  const project = useAppSelector((state) => getCurrentProject(state.project));
  const canEdit = usePermissionCheck(Actions.PLANNER_PROJECT_EDIT, project?.Permission.Level);

  /**
   * Callback function for handling the checked change event
   */
  const handleOnChange = (value: string) => {
    onChange({ CostFactor: value });
  };

  const onChangeDebounced = useDebouncedCallback(handleOnChange, 500);

  const handleOnCustomValueChange = (value: string) => {
    setCustomValue(value);
    onChangeDebounced(value);
  };

  const hasCustomValue =
    properties.CostFactor != null &&
    !Object.values(trafficLightData).some((trafficLight) => trafficLight.cost.toString() === properties.CostFactor.toString());

  return (
    <TableRow data-testid="feature-property-path-preference">
      <PropertyNameCell>Preference:</PropertyNameCell>
      <TableCell>
        {hasCustomValue ? (
          <NumberField value={customValue} onChange={handleOnCustomValueChange} disabled={!canEdit} />
        ) : (
          <Select
            data-testid="preference-select"
            value={String(properties.CostFactor)}
            onChange={(event: SelectChangeEvent) => handleOnChange(event.target.value)}
            size="small"
            inputProps={{ sx: { background: "white" }, "data-testid": "preference-select-input" }}
            disabled={!canEdit}
          >
            {[TrafficLightValue.neutral, TrafficLightValue.avoid, TrafficLightValue.prefer].map((trafficLightValue) => {
              const { cost, icon, text } = trafficLightData[trafficLightValue];
              return (
                <MenuItem key={trafficLightValue} value={cost.toString()}>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon>{icon}</ListItemIcon>
                    <Typography fontSize="inherit" sx={{ ml: 1 }}>
                      {text}
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PathPreferenceEdit;
