import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { Swab } from "ui";

import { getScoreColor } from "../helper";

import { CellWrapper } from "./scoreCellRenderer.styles";

const ScoreCellRenderer: React.FC<ICellRendererParams> = memo((params: ICellRendererParams) => {
  const color = getScoreColor(params.value);

  return (
    <CellWrapper>
      <Swab color={color || "#000"} />
    </CellWrapper>
  );
});

ScoreCellRenderer.displayName = "ScoreCellRenderer";

export default ScoreCellRenderer;
