import { Button, ButtonProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

interface IProps extends ButtonProps {
  color?: ButtonProps["color"];
  disabled?: ButtonProps["disabled"];
  fullWidth?: ButtonProps["fullWidth"];
  /**
   * If `true`, the component displays a loading spinner and is disabled.
   * @dafault false
   */
  loading?: boolean;
  variant?: ButtonProps["variant"];
}

const useStyles = makeStyles({
  loadingButtonContainer: {
    display: "inline-block",
    position: "relative",
    lineHeight: 0,
  },
  loadingButtonSpinnerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingButtonSpinner: {
    flex: "0 0 auto",
    lineHeight: 0,
  },
  fullW: {
    width: "100%",
  },
});

/**
 * Button used to fire actions & indicate to the user something is loading in the background.
 */
const LoadingButton: React.FC<IProps> = ({ loading = false, children, className, fullWidth, disabled, onClick, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.loadingButtonContainer, className, {
        [classes.fullW]: fullWidth,
      })}
    >
      <Button variant="contained" fullWidth={fullWidth} disabled={loading || disabled} onClick={onClick} {...rest}>
        {children}
      </Button>
      {loading && (
        <div className={classNames(classes.loadingButtonSpinnerContainer, classes.fullW)}>
          <CircularProgress size={24} className={classes.loadingButtonSpinner} />
        </div>
      )}
    </div>
  );
};

export default LoadingButton;
