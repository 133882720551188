import React from "react";
import { TableCell } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      padding: `0 ${theme.spacing(2)}`,
    },
    overflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Set the alignment of the column.
   * @default "left"
   */
  align?: "left" | "right";
  /**
   * The content to render within the cell
   */
  children: React.ReactNode;
  /**
   * Callback function fired when a cell is clicked
   */
  onClick?(): void;
  /**
   * Sets the padding for the cell
   * @default default
   */
  padding?: "none" | "checkbox" | "normal";
}

/**
 * A DataGrid Cell component that provides ellipsis functionality to content within
 * Used to support responsive rendering of wide tables
 */
const DataGridCell: React.FC<IProps> = ({ classes, children, padding, align = "left", onClick }: IProps) => (
  <TableCell padding={padding} onClick={onClick} style={{ position: "relative", height: 53 }}>
    <div className={classes.root} style={{ justifyContent: align === "right" ? "flex-end" : "flex-start" }}>
      <div className={classes.overflow}>{children}</div>
    </div>
  </TableCell>
);

export default withStyles(customStyles)(DataGridCell);
