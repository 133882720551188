import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Delete as DeleteIcon, Download as DowloadIcon, Info as InfoIcon, Layers as LayersIcon } from "@mui/icons-material";
import { Box, IconButton, Popover, Table, TableBody, TableContainer, TableHead } from "@mui/material";

import { selectVersionsByProjectId } from "fond/api";
import mixpanel from "fond/mixpanel";
import { getCurrentProject } from "fond/project/redux";
import { ZipFolderIcon } from "fond/svg_icons";
import { Export, Project, Store, Version } from "fond/types";
import { formatDateString } from "fond/utils/dates";
import { Actions, permissionCheck } from "fond/utils/permissions";

import ExportInfoPanel from "../../ExportInfoPanel";

import DeleteExportModal from "./DeleteExportModal";

import { BodyCell, HeaderCell, ModifiedTableRow } from "../ExportTable.styles";
import { NoWrapTooltip } from "./completeExportTable.styles";

interface CompleteExportTableProps {
  exports: Export[];
}

const CompleteExportTable: React.FC<CompleteExportTableProps> = ({ exports }: CompleteExportTableProps) => {
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const versions = useSelector((state: Store): Version[] => (project && selectVersionsByProjectId(state, project.ID)) || []);
  const [deleteExport, setDeleteExport] = useState<Export | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const canEditExport = permissionCheck(project?.Permission.Level, Actions.PROJECT_EXPORT_EDIT);

  /**
   * Toggle on/off the popover for selected export.
   */
  const onView = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseView = () => {
    setAnchorEl(null);
  };

  const handleOnDownloadClick = (exportId: string) => () => {
    mixpanel.track("Download export", { exportId });
  };

  return (
    <Box data-testid="export-table-complete">
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{ display: "block", overflow: "auto" }}>
            <ModifiedTableRow>
              <HeaderCell>File Name</HeaderCell>
              {project.HasCustomLayerConfig && <HeaderCell width={160}>Version</HeaderCell>}
              <HeaderCell width={120}>Date</HeaderCell>
              <HeaderCell width={160}>Manage</HeaderCell>
            </ModifiedTableRow>
          </TableHead>
          <TableBody className="customScrollbars" sx={{ display: "block", overflow: "auto", maxHeight: 216 }}>
            {exports.map((item: Export) => (
              <ModifiedTableRow key={item.ID}>
                <BodyCell>
                  <Box display="flex" alignItems="center">
                    <Box pr={1}>{item.Layers.length === 1 ? <LayersIcon color="error" /> : <ZipFolderIcon />}</Box>
                    {item.Name || `FOND-${project?.ProjectName}-${item.Format}`}
                  </Box>
                </BodyCell>
                {project.HasCustomLayerConfig && <BodyCell width={160}>{versions.find((version) => version.ID === item.VersionID)?.Name}</BodyCell>}
                <BodyCell width={120}>{item.CompletedAt && formatDateString(item.CompletedAt)}</BodyCell>
                <BodyCell width={160}>
                  <IconButton id={item.ID} onClick={onView}>
                    <InfoIcon color="primary" />
                  </IconButton>
                  <Popover
                    id={item.ID}
                    open={anchorEl?.id === item.ID}
                    anchorEl={anchorEl}
                    onClose={onCloseView}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ExportInfoPanel exportItem={item} onClose={onCloseView} />
                  </Popover>
                  <a href={item.Url} onClick={handleOnDownloadClick(item.ID)} aria-label="download">
                    <IconButton>
                      <DowloadIcon color="primary" data-testid="download-button" />
                    </IconButton>
                  </a>
                  <NoWrapTooltip title={canEditExport ? "" : "You do not have the necessary permissions to do this action"}>
                    <span>
                      <IconButton onClick={() => setDeleteExport(item)} disabled={!canEditExport} aria-label="Delete">
                        <DeleteIcon color={canEditExport ? "primary" : "disabled"} />
                      </IconButton>
                    </span>
                  </NoWrapTooltip>
                </BodyCell>
              </ModifiedTableRow>
            ))}
            {deleteExport && <DeleteExportModal exportItem={deleteExport} closeModalCallback={() => setDeleteExport(undefined)} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompleteExportTable;
