import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Typography } from "@mui/material";

import theme from "fond/theme";
import { UserAccountAllocationResponse } from "fond/types";
import { Modal } from "fond/widgets";

import EditUserForm from "./EditUserForm";

interface IProps {
  userAccountAllocation: UserAccountAllocationResponse;
  closeModal(): void;
}

const editUserFormId = "edit-user-form";

const EditUserModal: React.FC<IProps> = ({ userAccountAllocation, closeModal }) => {
  return (
    <Modal
      open
      onClose={closeModal}
      data-testid="edit-user-modal"
      header="User Details"
      content={
        <>
          <Box display="flex" alignItems="center" gap="1rem" mb="10px">
            {/* <Avatar name={name} variant="circular" /> // TODO add when information is available */}
            <Avatar sx={{ bgcolor: theme.palette.biarri.primary.red }}>
              <PersonIcon />
            </Avatar>
            <Box>
              <Typography variant="h6" data-testid="edit-user-email">
                {userAccountAllocation.User.Email}
              </Typography>
              {/* <UserSubtext variant="subtitle1">{user.Email}</UserSubtext> // TODO add when name is added */}
            </Box>
          </Box>
          {/* <UserLastActivity>last activity date: to be added</UserLastActivity> // TODO add when information is available */}
          <EditUserForm formId={editUserFormId} userAccountAllocation={userAccountAllocation} onSubmitComplete={closeModal} />
        </>
      }
      actions={
        <>
          <Button data-testid="cancel-button" color="primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button data-testid="apply-button" form={editUserFormId} color="primary" type="submit" variant="contained">
            Apply
          </Button>
        </>
      }
    />
  );
};

export default EditUserModal;
