import { Box, styled } from "@mui/material";

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "colors",
})<{ colors: string[] }>(
  ({ colors }) => `
    background: linear-gradient(90deg, ${colors.join(",")});
    border-radius: 2px;
  `
);

Container.defaultProps = {
  width: 35,
  height: 15,
};
