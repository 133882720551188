import { cloneElement, Component } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Delete, DriveFileRenameOutline } from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { find } from "lodash";
import PropTypes from "prop-types";

import { selectAllVersionLayers, selectAllVersionStyles } from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import { makeFondMapboxDraw, meta } from "fond/draw";
import directSelect from "fond/draw/modes/planner/direct_select";
import drawLineString from "fond/draw/modes/planner/draw_line_string";
import drawPoint from "fond/draw/modes/planner/draw_point";
import drawPole from "fond/draw/modes/planner/draw_pole";
import drawSpans from "fond/draw/modes/planner/draw_spans";
import hubSelect from "fond/draw/modes/planner/hub_select";
import simpleSelect from "fond/draw/modes/planner/simple_select";
import spanPoleSelect from "fond/draw/modes/planner/span_pole_select";
import { getBaseLineStringStyles, getBasePointStyles, getQueryLayerIds, getSnappableStyleIds } from "fond/draw/modes/planner/utils";
import { inputLayerGroups, LayerGroupIds, LayerIds } from "fond/layers";
import { hideIfEditing } from "fond/map/styles";
import { closeEditing, dismissUploadError, editingRevert, editingSetDirty, saveEditing, setFeatureEditorConfirm } from "fond/project/redux";
import { connect2, sum } from "fond/utils";
import { ConfirmModal, ErrorModal } from "fond/widgets";

import DrawSpanAndPoleButton from "./DrawSpanAndPoleButton";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { IconButton, MainContainer, Paper, ToggleButton } from "./FeatureEditor.styles";

const {
  lib: { mapEventToBoundingBox },
} = MapboxDraw;

export function getMapboxDrawParameters(map, layerConfigs, layerGroup, dataLayers, project, styles) {
  const selectedColor = "#ff5832";

  const midpointStyle = {
    id: "gl-draw-line-vertex-inactive",
    filter: [
      "all",
      ["==", "$type", "Point"],
      [
        "any",
        ["==", "active", "false"],
        // This style isn't actually specific to midpoints but this
        // filter works anyway.
        ["==", "meta", "midpoint"],
      ],
    ],
    type: "circle",
    paint: {
      "circle-radius": 5,
      "circle-color": selectedColor,
    },
  };

  const layerIds = layerGroup.layers.map((l) => l.id);

  const basePointStyles = getBasePointStyles(layerConfigs, layerGroup.layers);
  let drawStyles = [];
  basePointStyles.forEach(({ id, styleIDs, sourceId, filter }) => {
    styleIDs.forEach((ID, styleIndex) => {
      const style = styles.find((s) => s.ID === ID);
      const { type, paint, layout } = style.MapboxStyle;

      drawStyles.push({
        id: `gl-draw-point-${ID}-active`,
        filter: [
          "all",
          ["==", ["get", "meta:type"], "Point"],
          ["==", ["get", "active"], "true"],
          ["==", ["get", "meta"], "feature"],
          ["==", ["get", "layerId"], sourceId],
          ...(filter ? [filter] : []),
        ],
        type: "circle",
        paint: {
          "circle-radius": 12,
          "circle-color": "yellow",
        },
      });

      drawStyles.push({
        id: `gl-draw-point-${ID}`,
        type,
        filter: [
          "all",
          ["in", ["get", "meta"], ["literal", ["feature", meta.drawing_point]]],
          ["==", ["get", "layerId"], sourceId],
          ...(filter ? [filter] : []),
        ],
        layout: {
          ...(layout || {}),
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-ignore-placement": true,
        },
        paint: paint || {},
      });
    });
  });

  const baseLineStringStyles = getBaseLineStringStyles(layerConfigs, layerGroup.layers, project, styles);
  baseLineStringStyles.forEach(({ id, styleIDs, sourceId, filter }) => {
    styleIDs.forEach((ID) => {
      const style = styles.find((s) => s.ID === ID);
      const { type, paint, layout } = style.MapboxStyle;

      drawStyles.push({
        id: `gl-draw-line-${ID}`,
        type,
        filter: [
          "all",
          ["==", ["get", "active"], "false"],
          ["==", ["get", "meta:type"], "LineString"],
          ["!=", ["get", "mode"], "static"],
          ...(filter ? [filter] : []),
        ],
        paint,
        layout,
      });

      drawStyles.push({
        id: `gl-draw-line-${ID}-active`,
        filter: [
          "all",
          ["==", ["get", "active"], "true"],
          ["==", ["get", "meta:type"], "LineString"],
          ["in", ["get", "meta"], ["literal", ["feature", meta.drawing_line]]],
        ],
        type: "line",
        paint: {
          "line-color": selectedColor,
          "line-width": 2.5,
        },
      });
    });

    drawStyles = drawStyles.concat(
      multipleLayers(
        {
          id: `gl-draw-line-${id}-vertex-active`,
          filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"]],
          type: "circle",
        },
        [
          {
            paint: {
              "circle-radius": 7,
              "circle-color": "white",
            },
          },
          {
            paint: {
              "circle-radius": 5,
              "circle-color": selectedColor,
            },
          },
        ]
      )
    );
  });

  if (baseLineStringStyles.length > 0 && layerGroup.id === LayerGroupIds.inStreet) {
    drawStyles.push(midpointStyle);
  }

  let layers = {};
  for (let layerId of [...layerIds, ...layerGroup.snappableLayerIds]) {
    layers[layerId] = dataLayers[layerId];
  }

  const snapOverPointStyle = {
    type: "circle",
    paint: {
      "circle-radius": 4,
      "circle-color": "#bbd5ed",
      "circle-stroke-width": 2,
      "circle-stroke-color": "#8ba2b7",
      "circle-opacity": hideIfEditing,
      "circle-stroke-opacity": hideIfEditing,
    },
  };

  const snapOverLineStyle = {
    type: "line",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#1489ef",
      "line-width": 2.5,
      "line-opacity": hideIfEditing,
    },
  };

  const queryLayerIds = getQueryLayerIds(basePointStyles, baseLineStringStyles);
  const snappableStyleIds = getSnappableStyleIds(layerConfigs, layerGroup.snappableLayerIds);

  return {
    styles: drawStyles,

    snapping:
      layerGroup.snappableLayerIds != null
        ? {
            queryLayerIds: [...queryLayerIds, ...(snappableStyleIds || layerGroup.snappableLayerIds)],
            snappableLayerIds: layerGroup.snappableLayerIds,
            snappableStyleIds,
            pointStyle: snapOverPointStyle,
            lineStyle: snapOverLineStyle,
            selectableLayerIds: layerGroup.selectableLayerIds,
          }
        : null,

    layerGroup,
    ...layerGroup.defaultDrawOpts,
    layers,
    existingFeatureLayerIds: snappableStyleIds || layerGroup.snappableLayerIds,

    map,
    displayControlsDefault: false,
    controls: {},
    clickBuffer: 5,

    modes: {
      ...MapboxDraw.modes,
      simple_select: simpleSelect,
      direct_select: directSelect,
      hub_select: hubSelect,
      draw_point: drawPoint,
      draw_line_string: drawLineString,
      draw_spans: drawSpans,
      draw_pole: drawPole,
      span_pole_select: spanPoleSelect,
    },
  };
}

function multipleLayers(common, layers) {
  return layers.map((layer, i) => {
    return {
      ...common,
      id: `${common.id}-${i}`,
      ...layer,
    };
  });
}

class _FeatureEditor extends Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    setDrawControl: PropTypes.func.isRequired,
    project: PropTypes.object,
    data: PropTypes.object,
    layerGroup: PropTypes.object,
    layerConfigs: PropTypes.any,
    styles: PropTypes.array,
    inputLayerGroupId: PropTypes.string,
    isDirty: PropTypes.bool,
    confirming: PropTypes.oneOf(["close", "revert", "clearAllChanges", null]),
    uploadStatus: PropTypes.string, // AsyncOperationState

    children: PropTypes.any,

    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onRevert: PropTypes.func,
    onClose: PropTypes.func,
    setConfirming: PropTypes.func,
    onDismissSaveError: PropTypes.func,

    // `onReady` and `makeFondMapboxDraw` are used for testing. `MapboxDraw` lets the
    // caller pass a custom `MapboxDraw` constructor (eg. if you want to assign
    // it to a local variable in a side effect so the caller can trigger events
    // on it).
    onReady: PropTypes.func,
    makeFondMapboxDraw: PropTypes.func,
  };

  static defaultProps = {
    makeFondMapboxDraw,
    uploadStatus: null,
    children: null,
    onReady: () => null,
  };

  constructor(props) {
    super(props);
    this.mapboxDraw = null;
    this.state = {
      activeMode: null,
      isDeleteButtonActive: false,
    };
  }

  componentDidMount() {
    this.mount(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.layerGroup.id !== prevProps.layerGroup.id) {
      this.unmount();
      this.mount(this.props);
    }
  }

  componentWillUnmount() {
    this.unmount();
  }

  onToggleChange = (event, mode) => {
    // If the currently active toggle button is clicked the mode is `null`. We don't need to do anything anyway.
    if (mode != null && mode !== "") {
      this.setMode(mode, this.props.layerGroup.defaultDrawOpts);
    }
  };

  setMode = (mode, opts) => {
    this.mapboxDraw.changeMode(mode, { ...opts, project: this.props.project });
    this.setState({ activeMode: mode });
  };

  setDefaultDrawModeForLayerGroup(props) {
    const numFeatures = sum(
      props.layerGroup.layers.map((layer) => {
        const dataLayer = props.data.layers[layer.id];
        if (dataLayer == null) {
          throw new Error(`data for ${layer.id} not found`);
        }
        return dataLayer.features.length;
      })
    );

    let mode;
    if (numFeatures > 0) {
      mode = props.layerGroup.draw.initialModeWithFeatures;
    } else {
      mode = props.layerGroup.draw.initialModeNoFeatures;
    }

    this.setMode(mode, props.layerGroup.defaultDrawOpts);
  }

  mouseEventHandler = (event) => {
    processMouseEvent(
      event,
      this.props.map,
      this.mapboxDraw,
      this.props.data.layers,
      this.props.layerGroup,
      this.props.project,
      this.props.layerConfigs
    );
  };

  handleEdit = () => {
    this.props.onEdit();
  };

  handleSelectionChange = (selection) => {
    this.setState({ isDeleteButtonActive: selection.features.length > 0 });
  };

  handleModeChange = (event) => {
    // To keep the buttons in sync with the actual Mapbox Draw state, we need
    // to *both* update the button state when the user hits the select/draw
    // point buttons (since the 'draw.modechange' event is not fired when the
    // mode is changed explicitly), and also listen to the 'draw.modechange'
    // event for when Mapbox Draw itself updates the mode (it sets the mode
    // back to select mode when the user places a point).
    this.setState({ activeMode: event.mode });
  };

  handleDeleteClick = () => {
    // If a vertex in a line string is selected, `trash` will delete just that vertex,
    // otherwise it will delete the whole feature.
    this.mapboxDraw.trash();

    /**
     * Based on this part of the docs (https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawselectionchange),
     * invoking above draw.trash() should automatically call draw.selectionchange(), but it doesn't seem to be the case.
     * This results to delete button's disabled prop not being updated accordingly.
     * Below line manually trigger it instead.
     */
    this.handleSelectionChange(this.mapboxDraw.getSelected());
  };

  handleConfirm = () => {
    if (this.props.confirming === "close") {
      this.mapboxDraw.revertAll();

      // It's still not well understood how this works, but Mapbox throws
      // errors if we `setFeatureState` on features (which `revertAll` does)
      // and then remove layers attached to those features (which `onClose` does
      // indirectly via this component's `componentWillUnmount` which removes
      // Mapbox Draw).  The `setTimeout` is a workaround for that.
      // It's the same issue that's also worked around in `clearFeatures` in
      // `src/fond/map/redux.js`.
      setTimeout(() => {
        this.props.onClose();
      }, 0);
    } else if (this.props.confirming === "revert") {
      this.mapboxDraw.revertAll();
      this.props.setConfirming(null);
      this.props.onRevert();
    } else if (this.props.confirming === "clearAllChanges") {
      this.mapboxDraw.revertAll();
      this.props.setConfirming(null);
    } else {
      throw new Error("Unrecognised confirm state");
    }
  };

  handleCancel = () => {
    this.props.setConfirming(null);
  };

  async mount(props) {
    /**
     * Figure out what layers to set up with Mapbox Draw. First we figure out the
     * "base styles", which are the styles for the layer(s) we are editing. For example
     * if we're editing spans & poles then the base point style is the pole style and
     * the base line style is the spans style.
     *
     * Also determine the layers to snap to based on the layer group, and
     * whether to highlight midpoints of lines (currently only active when
     * editing underground path).
     *
     * In later work we will move this into `makeFondMapboxDraw` in
     * `src/fond/draw/index.js`
     */
    this.mapboxDraw = await props.makeFondMapboxDraw(
      getMapboxDrawParameters(props.map, props.layerConfigs, props.layerGroup, props.data.layers, props.project, props.styles)
    );

    // Make mapboxDraw accessible as a global variable so we can play with it
    // using dev tools.
    if (typeof window !== "undefined") {
      window.mapboxDraw = this.mapboxDraw;
    }

    props.setDrawControl(this.mapboxDraw);

    props.map.on("mousedown", this.mouseEventHandler);
    props.map.on("draw.modechange", this.handleModeChange);
    props.map.on("draw.create", this.handleEdit);
    props.map.on("draw.update", this.handleEdit);
    props.map.on("draw.delete", this.handleEdit);
    props.map.on("draw.selectionchange", this.handleSelectionChange);

    this.setDefaultDrawModeForLayerGroup(props);

    if (props.onReady != null) {
      props.onReady();
    }
  }

  unmount() {
    this.props.setDrawControl(
      new MapboxDraw({
        displayControlsDefault: false,
        controls: {},
      })
    );

    this.props.map.off("mousedown", this.mouseEventHandler);
    this.props.map.off("draw.modechange", this.handleModeChange);
    this.props.map.off("draw.create", this.handleEdit);
    this.props.map.off("draw.update", this.handleEdit);
    this.props.map.off("draw.delete", this.handleEdit);
    this.props.map.off("draw.selectionchange", this.handleSelectionChange);

    this.mapboxDraw?.remove();
  }

  render() {
    if (this.props.uploadStatus === AsyncOperationState.failure) {
      return <ErrorModal onClose={() => this.props.onDismissSaveError()} />;
    }

    if (this.props.layerGroup == null) {
      throw new Error("layerGroup is null");
    }
    if (this.props.layerConfigs == null) {
      throw new Error("layerConfigs is null");
    }

    const layerGroupSettings = {
      [inputLayerGroups.inAddress.id]: {
        heading: "Draw addresses",
        buttons: [
          <SelectButton key="simple_select" value="simple_select" />,
          <PenButton key="draw_point" value="draw_point" layerId={LayerIds.inAddress} nameSingular="Address" />,
        ],
        hasDeleteButton: true,
      },
      [inputLayerGroups.inStreet.id]: {
        heading: "Draw underground path",
        buttons: [
          <SelectButton key="simple_select" value="simple_select" />,
          <PenButton key="draw_line_string" value="draw_line_string" layerId={LayerIds.inStreet} nameSingular="Underground path" />,
        ],
        hasDeleteButton: true,
      },
      [inputLayerGroups.inExchange.id]: {
        heading: "Draw central offices",
        buttons: [
          <SelectButton key="simple_select" value="simple_select" />,
          <PenButton key="draw_point" value="draw_point" layerId={LayerIds.inExchange} nameSingular="Central office" />,
        ],
        hasDeleteButton: true,
      },
      [inputLayerGroups.spanPole.id]: {
        heading: "Draw spans & poles",
        buttons: [
          <SelectButton key="span_pole_select" value="span_pole_select" />,
          <DrawSpanAndPoleButton key="draw_span_or_pole" selected={this.state.activeMode} onToggleChange={this.onToggleChange} />,
        ],
        hasDeleteButton: true,
      },
      [inputLayerGroups.hub.id]: {
        heading: "Move hubs",
        buttons: [],
        hasDeleteButton: false,
      },
    };

    const { heading, buttons, hasDeleteButton } = layerGroupSettings[this.props.layerGroup.id];

    return (
      <MainContainer data-testid="drawing-tools-overlay">
        {this.props.confirming != null && (
          <ConfirmModal
            open
            content="Are you sure you want to discard your changes?"
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
            data-testid="confirm-revert"
          />
        )}
        <Paper elevation={3}>
          <ToggleButtonGroup exclusive size="small" orientation="vertical" value={this.state.activeMode} onChange={this.onToggleChange}>
            {buttons.map((button, i) => {
              // Done this way because ToggleButtons must be direct children of the ToggleButtonGroup.
              return cloneElement(button, { key: i });
            })}
          </ToggleButtonGroup>
          {hasDeleteButton && (
            <IconButton
              data-testid="delete-button"
              title="Delete the selected item"
              disabled={!this.state.isDeleteButtonActive}
              onClick={this.handleDeleteClick}
              sx={{ width: 32, height: 32, borderRadius: "4px", "&:hover": { color: (theme) => theme.palette.primary.main } }}
            >
              <Delete />
            </IconButton>
          )}
        </Paper>
      </MainContainer>
    );
  }
}

export { _FeatureEditor };

export const SelectButton = (props) => (
  <ToggleButton title="Select an existing item" {...props}>
    <SvgIcon fontSize="small">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 10.6667V5.33333H10.6667V10.6667H5.33333V13.3333H10.6667V18.6667H13.3333V13.3333H18.6667V10.6667H13.3333V10.6667ZM12 0L16 5.33333H8L12 0V0ZM0 12L5.33333 8V16L0 12V12ZM24 12L18.6667 16V8L24 12V12ZM12 24L16 18.6667H8L12 24V24Z"
      />
    </SvgIcon>
  </ToggleButton>
);

export const PenButton = ({ layerId, nameSingular, ...props }) => (
  <ToggleButton data-testid={`place-new-${layerId}-mode`} title={`Place a new ${nameSingular}`} {...props}>
    <DriveFileRenameOutline />
  </ToggleButton>
);

PenButton.propTypes = {
  layerId: PropTypes.string.isRequired,
  nameSingular: PropTypes.string.isRequired,
};

/**
 * There are two ways to use `<FeatureEditor>`:

    1: <FeatureEditor
      map={map}
      layerGroup={a layer group object}
    />

    or

    2: <FeatureEditor
      map={map}
      inputLayerGroupId='layer group id'  // eg. 'inAddress'
    />

    With #2, the layerGroup is looked up from `inputLayers` in
    `fond/layers.js`, and the upload status is hooked up to the uploads for the
    current project looked up by the layer group's ID.
 */
const FeatureEditor = connect2(
  function mapStateToProps(state, ownProps) {
    const { project, data, uploads } = state.project.projects[state.project.projectId];
    let layerGroup;
    let upload;
    if (ownProps.inputLayerGroupId != null) {
      layerGroup = inputLayerGroups[ownProps.inputLayerGroupId];
      upload = uploads[layerGroup.id];
    } else {
      layerGroup = ownProps.layerGroup;
    }
    if (layerGroup == null) {
      throw new Error("Did not find a layerGroup");
    }

    const layerConfigs = selectAllVersionLayers(state, state.project.versionId);
    const styles = selectAllVersionStyles(state, state.project.versionId);

    return {
      layerGroup,
      project,
      layerConfigs,
      styles,
      data,
      uploadStatus: upload != null ? upload.status : null,
      isDirty: state.project.editing.isDirty,
      confirming: state.project.editing.confirm,
    };
  },
  function mapDispatchToProps(dispatch, ownProps, stateProps) {
    return {
      onClose: () => dispatch(closeEditing()),
      onSave: (geojson, featuresToClear) => dispatch(saveEditing(geojson, featuresToClear)),
      onDismissSaveError: () => dispatch(dismissUploadError(stateProps.layerGroup.id)),
      onEdit: () => dispatch(editingSetDirty()),
      onRevert: () => dispatch(editingRevert()),
      setConfirming: (param) => dispatch(setFeatureEditorConfirm(param)),
    };
  }
)(_FeatureEditor);

/**
 * Given a Mapbox `mousedown` event, figure out if it's a mousedown event on an
 * appropriate feature that should be pulled into Mapbox Draw and interacted
 * with. If it is, figure out the correct mode to open and call the `enter`
 * method on that mode.
 *
 * @param {a Mapbox mouse event} event
 * @param {a Mapbox GL instance} map
 * @param {a FOND Mapbox Draw instance (created by makeFondMapboxDraw)} mapboxDraw
 * @param {a mapping of layer IDs to GeoJSON FeatureCollections} layers
 * @param {a layer group (from layers.js)} layerGroup
 */
export function processMouseEvent(event, map, mapboxDraw, layers, layerGroup, project, layerConfigs) {
  const box = mapEventToBoundingBox(event, mapboxDraw.options.clickBuffer);
  const features = map.queryRenderedFeatures(box);

  const layerModes = {
    [LayerIds.inAddress]: simpleSelect,
    [LayerIds.inExchange]: simpleSelect,
    [LayerIds.inStreet]: directSelect,
    [LayerIds.inSpan]: spanPoleSelect,
    [LayerIds.inPole]: spanPoleSelect,
    [LayerIds.hub]: hubSelect,
  };

  for (let feature of features) {
    // We refer to the feature.properties.layerId rather than
    // feature.layer.id to correctly reference the layers sourceId
    // as when editing a sublayer (i.e. inAddress) the feature.layer.id
    // will instead be the sublayer id
    let featureLayerId = feature.properties.layerId;

    if (layerGroup.layers.some((l) => l.id === featureLayerId)) {
      if (!mapboxDraw.isEditingFeature(feature.id)) {
        if (layerModes[featureLayerId] != null) {
          layerModes[featureLayerId].enter(mapboxDraw, {
            event,
            feature: find(layers[featureLayerId].features, (f) => f.id === feature.id),
            ...layerGroup.defaultDrawOpts,
            layerId: featureLayerId,
            styleId: feature.layer.id,
            project,
            layerConfigs,
          });
        }
        break;
      }
    }
  }
}

export default FeatureEditor;
