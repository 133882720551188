import React from "react";
import { Paper } from "@mui/material";

import { DesignLayerIds, ImportStatus, Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { LinearProgressWithLabel, StackedNavigationButton, SvgIcon, useStackedNavigationContext } from "fond/widgets";

import { useGetDesignLayer } from "./useGetDesignLayers";

interface ConnectionsPanelProps {
  project: Project;
}

const ConnectionsPanel: React.FC<ConnectionsPanelProps> = ({ project }: ConnectionsPanelProps) => {
  const versionId = useAppSelector((state) => state.project.versionId);
  const importStatus = useGetDesignLayer(versionId, DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS).status;
  const { open } = useStackedNavigationContext();

  const handleOnClick = () => {
    open("connectionsDraw");
  };

  return importStatus !== undefined && importStatus !== ImportStatus.COMPLETE && importStatus !== ImportStatus.ERROR ? (
    <Paper variant="outlined" sx={{ py: 2, px: 2.75, mt: 2 }}>
      <LinearProgressWithLabel color="primary" variant="indeterminate" title="Generating connection points" subtext="Processing..." />
    </Paper>
  ) : (
    <StackedNavigationButton
      data-testid="connection-method-navigation"
      icon={<SvgIcon icon="polygon" />}
      title="Connection Points"
      onClick={handleOnClick}
      sx={{ m: 0 }}
    />
  );
};

export default ConnectionsPanel;
