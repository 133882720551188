import React, { forwardRef, ForwardRefRenderFunction, useCallback, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/PriorityHigh";
import { Box, Collapse, IconButton, Paper, SnackbarContent as MaterialSnackbarContent, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { OptionsObject, SnackbarContent, useSnackbar } from "notistack";

type IProps = OptionsObject & {
  id: string | number;
  message: string | React.ReactNode;
  title?: string;
  collapsibleContent?: React.ReactElement;
  customIcon?: React.ReactElement;
  // notistack supports either React.ReactNode | (key: string) => React.REactNode however Mui does not
  action?: React.ReactNode;
};

type Variant = {
  icon: React.ReactElement;
  color: string;
  title: string;
  actionText: string;
};

type VariantMap = {
  [key: string]: Variant;
};

type StyleProps = {
  variant: Variant;
  withCollapse: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {},
  mainContainer: {
    display: "flex",
    width: 380,
    padding: "12px",
    alignItems: "center",
    borderRadius: "4px",
    paddingBottom: ({ withCollapse }) => (withCollapse ? 0 : 12),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    borderRadius: "50%",
    background: ({ variant }) => variant.color,
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > *": {
      color: theme.palette.primary.contrastText,
    },
  },
  snackbarBody: {
    flex: "1",
    paddingLeft: "12px",
  },
  messageTitle: {
    fontSize: 14,
    paddingRight: "12px",
  },
  messageBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .MuiButtonBase-root": {
      minWidth: "unset",
    },
  },
  messageText: {
    fontSize: 12,
  },
  actionText: {
    color: theme.palette.success.main,
  },
  closeButton: {
    padding: 0,
    height: 15,
    width: 15,
    alignSelf: "flex-start",

    "& > svg": {
      fontSize: 12,
    },
  },
}));

const variantMap: VariantMap = {
  success: {
    icon: <CheckIcon />,
    color: "linear-gradient(180deg, #24CF7F 0%, #4CAF50 100%)",
    title: "Success",
    actionText: "Done!",
  },
  warning: {
    icon: <WarningIcon />,
    color: "linear-gradient(180deg, #FF841F 0%, #ED6C02 100%)",
    title: "Warning",
    actionText: "",
  },
  error: {
    icon: <WarningIcon />,
    color: "linear-gradient(180deg, #FF841F 0%, #ED6C02 100%)",
    title: "Error",
    actionText: "",
  },
  info: {
    icon: (
      <Typography variant="h4" textAlign="center">
        i
      </Typography>
    ),
    color: "linear-gradient(180deg, #48CDFF 0%, #2196F3 50%)",
    title: "Info",
    actionText: "",
  },
  default: {
    icon: <span />,
    color: "",
    title: "",
    actionText: "",
  },
};

const SnackMessage: ForwardRefRenderFunction<HTMLDivElement, IProps> = ({ id, message, ...options }, ref) => {
  const variant = options?.variant && options.variant in variantMap ? options.variant : "default";
  const customIcon = options?.customIcon;
  const variantDetails = variantMap[variant];
  const classes = useStyles({ variant: variantDetails, withCollapse: !!options?.collapsibleContent });
  const { closeSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  return (
    <SnackbarContent ref={ref} className={classes.root} data-testid="snackbar">
      {variant === "default" ? (
        <Paper elevation={6}>
          <MaterialSnackbarContent data-testid="snackbar-message" message={message} action={options?.action} />
        </Paper>
      ) : (
        <Paper elevation={6}>
          <Box className={classes.mainContainer}>
            <div className={classes.iconContainer}>{customIcon || <Box className={classes.icon}>{variantDetails.icon}</Box>}</div>
            <Box className={classes.snackbarBody}>
              <Typography variant="h6" component="p" className={classes.messageTitle}>
                {options?.title || variantDetails.title}
              </Typography>
              <Box className={classes.messageBody}>
                <Typography variant="caption" className={classes.messageText} data-testid="snackbar-message">
                  {message}
                </Typography>
                <>
                  {options?.action}
                  {!options?.action && (
                    <Typography variant="subtitle2" className={classes.actionText}>
                      {variantDetails.actionText}
                    </Typography>
                  )}
                </>
              </Box>
            </Box>
            <IconButton onClick={handleDismiss} size="small" className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          {options?.collapsibleContent && (
            <>
              <Collapse in={expanded} timeout="auto">
                {options.collapsibleContent}
              </Collapse>
              <IconButton
                onClick={handleExpandClick}
                size="small"
                sx={{ width: "100%", padding: 0, borderRadius: 0, ...(expanded ? { transform: "rotate(180deg)" } : {}) }}
              >
                <ExpandMoreIcon fontSize="inherit" />
              </IconButton>
            </>
          )}
        </Paper>
      )}
    </SnackbarContent>
  );
};
const SnackMessageWithRef = forwardRef<HTMLDivElement, IProps>(SnackMessage);
export default forwardRef<HTMLDivElement, IProps>(SnackMessage);
