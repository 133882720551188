export default function SpinningCircle(): React.ReactElement {
  const color = "#0e729d";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="spinning-circle" width="33px" height="33px" viewBox="0 0 66 66" data-testid="spinning-circle">
      <circle className="spinning-circle__path" fill="transparent" strokeWidth={4} cx={33} cy={33} r={30} stroke="url(#gradient)" />
      <linearGradient id="gradient">
        <stop offset="50%" stopColor={color} stopOpacity={1} />
        <stop offset="65%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="spinning-circle__dot"
        width={5}
        height={5}
        viewBox="0 0 66 66"
        x={37}
        y={1.5}
        fill="transparent"
      >
        <circle className="spinning-circle__path" fill={color} cx={33} cy={33} r={30} />
      </svg>
    </svg>
  );
}
