import MapboxDraw from "@mapbox/mapbox-gl-draw";
import _ from "lodash";

import { LayerIds } from "fond/layers";
import { until } from "fond/utils";

import { addStoreMethods } from "./store";

/**
 * We run into problems if we use meta: 'feature' for transient features
 * (transient features being eg. the vertices of a new span the user is
 * currently in the process of drawing but that has not been committed yet; so
 * it's not yet a 'real' span). If we have a feature with meta: 'feature' and
 * we delete it (like we do when we convert a linestring of spans into a set of
 * individual line segments and then delete the original), a
 * `queryRenderedFeatures` done very soon after the feature is deleted may
 * still return the feature, which will cause Mapbox Draw to crash when it
 * cannot find the feature in its own store. So by using a different meta
 * value, we are telling Mapbox Draw not to look for the features in question.
 */
export const meta = {
  drawing_point: "drawing_point",
  drawing_line: "drawing_line",
  drawing_pole: "drawing_pole",
};

/**
 * Creates what is effectively a superclass of MapboxDraw that supports pole +
 * span editing (and more generally, editing of layer groups) in a relatively
 * performant manner.
 *
 * @param {Object} options
 *    This is a combination of inbuilt Mapbox Draw options, third-party
 *    snapping-related options, and our own options.
 *
 *    Our options (all required):
 *
 *      `map`: the Mapbox Map object.
 *      `layers`: A mapping of layer IDs to `FeatureCollection`s.
 *        eg. `{inSpan: <feature collection>, inPole: <feature collection>}`
 *        These are the features that will be considered interactable.
 *      `layerGroup`: An input layer group from `src/fond/layers.js`
 *        eg. `inputLayerGroups.spanPole`.
 *
 *    Snapping options:
 *
 *      `snapping`: (optional) (see snap_to.js)
 *
 *    For options `defaultMode`, `keybindings`, `touchEnabled`, `clickBuffer`,
 *    `touchBuffer`, `boxSelect`, `displayControlsDefault`, `styles`, `modes`,
 *    `controls`, `userProperties`, see the Mapbox Draw docs at
 *    https://github.com/mapbox/mapbox-gl-draw/blob/master/docs/API.md.  Note
 *    that all of these options are optional.
 */
export async function makeFondMapboxDraw(options) {
  const { map, layers, layerGroup, ...mapboxDrawOptions } = options;

  // Pull in all the data from the sources in the layer group, and if there
  // are additional snappable layers, include those too.
  const sourceIds = _.uniq([...layerGroup.layers.map((l) => l.id), ...(_.get(mapboxDrawOptions, ["snapping", "snappableLayerIds"]) || [])]);
  const draw = new MapboxDraw(mapboxDrawOptions);

  /**
   * Central to our modified Mapbox Draw are two items we add to the context:
   *
   * - layers:
   *     - This is a mapping of layer ids to `FeatureCollection`s.
   *     - These are the initial features that exist at the start of editing.
   *     - They are not added to the Mapbox Draw layers until they are interacted with.
   *     - When a feature is interacted with, a new copy is created in the Mapbox Draw layer,
   *       and the original feature is "disabled", by using setFeatureState. The original
   *       feature is not removed or modified, so we can revert an entire editing session
   *       still using `layers`.
   * - featureLookup:
   *     - This is a mapping of feature ids (which are unique across all layers), to
   *       {layerId: 'layer id', isEditing: bool, isDeleted: bool|undefined} maps.
   *       If `isEditing` is false, that means the feature has not been interacted with yet,
   *       and its most up-to-date copy will exist in `layers`. If `isEditing` is true,
   *       the feature has been interacted with, and its most up-to-date copy will exist
   *       in the Mapbox Draw store. `getFeatureById` below abstracts this logic.
   */
  draw.initLookup = function () {
    this.ctx.layers = layers;

    this.ctx.featureLookup = {};
    for (let sourceId of sourceIds) {
      if (layers[sourceId] != null) {
        for (let feature of layers[sourceId].features) {
          if (typeof feature.id !== "number") {
            throw new Error("We currently require all features to have numeric ids");
          }
          if (feature.properties.id !== feature.id) {
            throw new Error("We currently require properties.id to be the same as .id");
          }
          if (feature.properties.layerId == null) {
            throw new Error("We require all features to have a layerId");
          }
          this.ctx.featureLookup[feature.id] = {
            layerId: sourceId,
            isEditing: false,
            isDeleted: false,
          };
        }
      }
    }
  };

  addStoreMethods(draw);
  map.addControl(draw);

  draw.initLookup();

  // Otherwise the `addLayer` calls below will fail.
  await until(() => map.getSource("mapbox-gl-draw-hot") != null);

  // List of {params: <layer params>, before: <before source id>}.
  const mapLayers = [];

  /**
   * Use layers and feature state to efficiently highlight unsnapped poles.
   */
  if (layerGroup.id === "spanPole") {
    for (let source of [LayerIds.inPole, "mapbox-gl-draw-hot", "mapbox-gl-draw-cold"]) {
      mapLayers.push({
        params: {
          id: `unsnapped-pole-${source}`,
          source: source,
          type: "circle",
          paint: {
            "circle-color": "red",
            "circle-radius": 7,
            "circle-opacity": [
              "case",
              ["boolean", ["feature-state", "isEditing"], false],
              0,
              ["boolean", ["feature-state", "isSnapped"], true],
              0,
              0.7,
            ],
          },
        },
        before: mapboxDrawOptions.styles.length > 0 ? `${mapboxDrawOptions.styles[0].id}.cold` : null,
      });
    }
  }

  for (let sourceId of ["mapbox-gl-draw-cold", ...sourceIds]) {
    const nullFilter = ["any"]; // Don't match anything
    mapLayers.push({
      params: {
        ...options.snapping.lineStyle,
        filter: nullFilter,
        id: `gl-draw-line-snap-${sourceId}`,
        source: sourceId,
      },
    });
    mapLayers.push({
      params: {
        ...options.snapping.pointStyle,
        filter: nullFilter,
        id: `gl-draw-point-snap-${sourceId}`,
        source: sourceId,
      },
    });
  }

  let isInitialised = false;
  function addLayers() {
    for (let layer of mapLayers) {
      map.addLayer(layer.params, layer.before);
    }
    if (isInitialised) {
      // If we get here a second time, it's probably because the user changed
      // the map style while edit mode was open (ie. switched from map view to
      // satellite view or vice versa). When this happens, all the sources are
      // removed and re-added and hence all the feature states are gone, so we
      // need to re-add them.
      _.forEach(draw.ctx.featureLookup, (featureLookupItem, id) => {
        const { layerId, ...featureState } = featureLookupItem;
        map.setFeatureState({ source: layerId, id: id }, featureState);
      });
    }
    if (layerGroup.id === "spanPole") {
      draw.refreshUnsnappedPoles();
    }
    isInitialised = true;
  }

  addLayers();

  // The `setup.addLayers` function is hooked up to be called when the map style changes,
  // so make it additionally call our `addLayers` function so we preserve feature states
  // when the style changes.
  const setupAddLayers = draw.ctx.setup.addLayers;
  draw.ctx.setup.addLayers = () => {
    setupAddLayers();
    addLayers();
  };

  const { onRemove } = draw;
  draw.onRemove = function () {
    for (let layer of mapLayers) {
      if (map.getLayer(layer.params.id)) {
        map.removeLayer(layer.params.id);
      }
    }
    onRemove.call(this);
  };

  draw.remove = function () {
    removeMapboxDraw(this);
  };

  return draw;
}

/**
 * If the `mapboxDraw` instance was created by `makeFondMapboxDraw` above (ie. in
 * editing) then we can find the map instance through the exposed `.ctx`
 * property of the Mapbox Draw instance. If it wasn't created through
 * `makeFondMapboxDraw` (eg. for polygon select), then we don't have `.ctx` so we
 * need to pass in the map manually.
 */
export function removeMapboxDraw(mapboxDraw, map = null) {
  if (map == null) {
    map = mapboxDraw.ctx.map;
  }

  map.removeControl(mapboxDraw);

  // There is a bug in Mapbox Draw whereby the mouse cursor is not restored
  // on removing the map controls, so we have to do that ourselves.
  // https://github.com/mapbox/mapbox-gl-draw/issues/764
  map.getContainer().classList.remove("mouse-add");
}
