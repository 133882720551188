/* eslint-disable max-classes-per-file */
import EventEmitter from "event-emitter";
import _ from "lodash";

import { makeQueryString } from "fond/utils";

export default class XHRWrapper {
  /**
   * We use `fetch` for most of our requests but as of when this was written,
   * fetch didn't play well with progress bars for long uploads, so we use
   * XHR for that instead.
   *
   * This class hides a bunch of idosyncracies in the `XMLHTTPRequest` API,
   * such as an arbitrary reliance on the ordering of various calls.
   *
   * We also call the 'error' callback if the response code isn't 2xx.
   *
   * Usage:
   *
       let xhr = new XHRWrapper({
         method: 'GET',
         url: 'http://example.com/blah',
         query: {key: 'value'}, // optional
         headers: {'x-header-1': 'value'}, // optional
         data: {
           'key1': 'value1',
           'file1', new File(['file contents'], 'filename.txt')
         } // optional
       });
       xhr.on('progress', (e) => console.log(e.loaded, e.total));
       xhr.on('load', () => console.log("finished!"));
       xhr.on('error', (e) => console.log("error", e));
       xhr.on('abort', () => console.log("cancelled by user"));
       xhr.send();
   */
  constructor({
    method, // HTTP request method ('GET', 'POST' etc)
    url, // URL to make the request to
    query = {}, // If nonempty will be encoded as query string
    data = {}, // POST data. Keys are strings, values are strings or `File` objects
    headers = {}, // HTTP request headers
  }) {
    let self = this;
    this.eventEmitter = new EventEmitter();

    let xhr = (this.xhr = new XMLHttpRequest());

    this.data = new FormData();
    _.forEach(data, (val, key) => this.data.append(key, val));

    xhr.upload.addEventListener("progress", (event) => {
      this.eventEmitter.emit("progress", event);
      if (event.loaded === event.total) {
        this.eventEmitter.emit("uploadComplete", event);
      }
    });
    // eslint-disable-next-line func-names
    xhr.addEventListener("load", function (event) {
      // `this` is the `XMLHttpRequest` object, `self` is the `XHRWrapper` object.
      /* eslint yoda: ["error", "never", { "exceptRange": true }] */
      if (200 <= event.target.status && event.target.status < 300) {
        self.eventEmitter.emit("load", event, this.responseText);
      } else {
        self.eventEmitter.emit("error", event, this.responseText);
      }
    });
    xhr.addEventListener("error", (event) => {
      this.eventEmitter.emit("error", event);
    });
    xhr.addEventListener("abort", (event) => {
      this.eventEmitter.emit("abort", event);
    });

    let queryString;
    if (!_.isEmpty(query)) {
      queryString = `?${makeQueryString(query)}`;
    } else {
      queryString = "";
    }
    xhr.open(method, url + queryString, true);

    _.forEach(headers, (value, key) => {
      xhr.setRequestHeader(key, value);
    });
  }

  send() {
    this.xhr.send(this.data);
  }

  abort() {
    this.xhr.abort();
  }

  on(eventName, callback) {
    this.eventEmitter.on(eventName, callback);
  }
}

export class DummyXHRWrapper {
  constructor({ fail = false } = {}) {
    this.fail = fail;
    this.eventEmitter = new EventEmitter();
  }

  on(eventName, callback) {
    this.eventEmitter.on(eventName, callback);
  }

  send() {
    if (!this.fail) {
      this.eventEmitter.emit("load");
    } else {
      this.eventEmitter.emit("error");
    }
  }

  abort() {
    this.eventEmitter.emit("abort");
  }
}
