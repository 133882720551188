import React from "react";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import mixpanel from "fond/mixpanel";
import ProjectSettings from "fond/projectSettings/ProjectSettings";
import { PermissionLevel } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";

interface IProps {
  permissionLevel: PermissionLevel;
  handleClose: () => void;
}

const Settings: React.FC<IProps> = ({ permissionLevel, handleClose }: IProps) => {
  const [isProjectSettingsModalOpen, setProjectSettingsModalOpen] = React.useState(false);
  const canEdit = permissionCheck(permissionLevel, Actions.PROJECT_EDIT);

  /**
   * Handles opening the Project Settings modal
   */
  const handleClick = () => {
    if (canEdit) {
      setProjectSettingsModalOpen(true);
      mixpanel.track("Opened project settings modal");
    }
    handleClose();
  };

  return (
    <>
      <MenuItem data-testid="settings-button" onClick={handleClick} disabled={!canEdit}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        Settings
      </MenuItem>
      <ProjectSettings isOpen={isProjectSettingsModalOpen} onClose={() => setProjectSettingsModalOpen(false)} />
    </>
  );
};

export default Settings;
