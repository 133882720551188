import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { Box, Typography, useTheme } from "@mui/material";

import { SubscriptionTier } from "fond/types/stripe";
import { getCurrencyFromStripe } from "fond/utils/currency";

import PopularLabel from "./PopularLabel";

import { CurrentChip, PricingTableItemButton } from "./PricingTable.styles";

interface PricingTableItemProps {
  subscriptionTier: SubscriptionTier;
  onClick: () => void;
  current?: boolean;
  selected?: boolean;
  popular?: boolean;
}

const PricingTableItem: React.FC<PricingTableItemProps> = ({ subscriptionTier, onClick, current, selected, popular }) => {
  const theme = useTheme();
  const yearlyCost = subscriptionTier.Product.Prices.find((price) => price.PaymentFrequency === "yearly")?.CostCents ?? 0;
  const formattedCost = getCurrencyFromStripe(yearlyCost);

  const icon = current || selected ? <CheckCircleIcon color={selected ? "primary" : "disabled"} /> : <PanoramaFishEyeIcon color="disabled" />;

  return (
    <Box>
      <Box visibility={popular ? undefined : "hidden"}>
        <PopularLabel />
      </Box>
      <PricingTableItemButton mostPopular={popular} selected={selected} onClick={onClick} aria-label={subscriptionTier.Product.Title}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={4}>
          <Typography textAlign="left">{subscriptionTier.Product.Title.toUpperCase()}</Typography>
          {current && <CurrentChip />}
        </Box>
        <Box display="flex" columnGap={1}>
          {icon}
          <Box>
            <Typography variant="h4" color={theme.palette.common.black}>
              {subscriptionTier.BaseQuota.toLocaleString()}
            </Typography>
            <Typography color={theme.palette.biarri.secondary.grey}>Prems/year</Typography>
          </Box>
        </Box>
        <Typography color={theme.palette.common.black} variant="h6" marginTop={1}>
          {formattedCost}
        </Typography>
      </PricingTableItemButton>
    </Box>
  );
};

export default PricingTableItem;
