import React, { useMemo, useRef } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Add, Delete } from "@mui/icons-material";
import { Box, FormHelperText, IconButton } from "@mui/material";

import { STYLE_EDITOR_MAX_ZOOM, STYLE_EDITOR_MIN_ZOOM } from "fond/constants";
import { SliderField, TextField } from "fond/form/fields";
import { StyleField } from "fond/types";
import { makeUuid } from "fond/utils";

import { FieldFactory, useCustomStyles } from "../FieldFactory";
import { getDefaultSpecValue } from "../helper";
import { AddButton } from "../styles";

import ZoomChart from "./ZoomChart";

import { Container, Stop, StopDetails, StopHeader, StopTitle } from "./cameraExpressionField.styles";

type Value = Array<number | string>;

interface IProps {
  name: string;
  defaultValue: any;
  field: StyleField;
  fieldSpec: any;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
}

/**
 * This field supports the creation of a Zoom Function which
 * allow the appearance of a map feature to change with map’s zoom level.
 *
 * Zoom functions can be used to create the illusion of depth and control data density.
 * Each stop is an array with two elements: the first is a zoom level and the second is a function output value.
 *
 * https://docs.mapbox.com/mapbox-gl-js/style-spec/other/
 */
const CameraExpressionField: React.FC<IProps> = ({ defaultValue, name, fieldProps, fieldSpec, validate, field, ...rest }: IProps) => (
  <Field
    name={name}
    render={({ input, meta }) => (
      <CameraExpressionFieldWrapper input={input} meta={meta} name={name} fieldSpec={fieldSpec} field={field} {...rest} defaultValue={defaultValue} />
    )}
    validate={validate}
    {...fieldProps}
  />
);

type CameraExpressionFieldWrapperProps = FieldRenderProps<Value, HTMLElement>;

const CameraExpressionFieldWrapper: React.FC<CameraExpressionFieldWrapperProps> = ({
  input: { name, onChange, value },
  field,
  fieldSpec,
  defaultValue,
  meta,
}: CameraExpressionFieldWrapperProps) => {
  const classes = useCustomStyles();
  const stops = [...Array(value.length / 2).keys()];
  const ids = useRef<string[]>(stops.map(() => makeUuid()) || []);
  const hasError = useMemo(() => !value.filter((v, index, Arr) => index % 2 === 0).every((v, i, a) => !i || a[i - 1] < v), [value]);

  /**
   * Handles adding a new stop value
   */
  const handleOnAdd = () => {
    const newValue = [...value];
    ids.current.push(makeUuid());
    const lastStop = [...value].splice(-2);
    newValue.push(Math.min(Number(lastStop[0]) + 1, 24), getDefaultSpecValue(fieldSpec));
    onChange(newValue);
  };

  /**
   * Handles the removing of the stop value
   */
  const handleOnRemove = (valueIndex: number, idIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    const newValue = [...value];
    newValue.splice(valueIndex, 2);
    ids.current.splice(idIndex, 1);

    if (newValue.length === 0) {
      onChange(null);
    } else {
      onChange(newValue);
    }
  };

  const renderStops = () => {
    let index = 0;
    const elements: JSX.Element[] = [];
    for (let i = 0; i < value.length; i += 2) {
      elements.push(
        <Stop key={ids.current[index]} error={hasError}>
          <StopHeader>
            <StopTitle>
              <Box flexGrow={1}>Zoom: </Box>
              <SliderField
                name={`${name}[${i}]`}
                size="small"
                min={STYLE_EDITOR_MIN_ZOOM}
                max={STYLE_EDITOR_MAX_ZOOM}
                step={1}
                sx={{ width: 75, marginLeft: 1, marginRight: 1 }}
                track={false}
                valueLabelDisplay="auto"
              />
              <TextField
                name={`${name}[${i}]`}
                type="number"
                fullWidth={false}
                className={classes.numArrayRoot}
                classes={{
                  root: classes.textFieldRoot,
                }}
                InputProps={{ sx: { width: 45 } }}
                inputProps={{
                  min: STYLE_EDITOR_MIN_ZOOM,
                  max: STYLE_EDITOR_MAX_ZOOM,
                }}
                fieldProps={{
                  parse: (val) => (!val ? STYLE_EDITOR_MIN_ZOOM : Math.min(Math.max(parseFloat(val), STYLE_EDITOR_MIN_ZOOM), STYLE_EDITOR_MAX_ZOOM)),
                }}
              />
            </StopTitle>

            <IconButton onClick={handleOnRemove(i, index)} size="small" data-testid={`remove-${index}`}>
              <Delete sx={{ width: 16, height: 16 }} />
            </IconButton>
          </StopHeader>
          <StopDetails>
            <FieldFactory fieldName={field.fieldName} formFieldName={`${name}[${i + 1}]`} fieldSpec={fieldSpec} defaultValue={defaultValue} />
          </StopDetails>
        </Stop>
      );
      index += 1;
    }

    return elements;
  };

  return (
    <Container data-testid={`${name}-input`}>
      Zoom Range
      <ZoomChart data={value} fieldLabel={field.label} />
      {renderStops()}
      <AddButton startIcon={<Add />} onClick={handleOnAdd} data-testid="add">
        Add range
      </AddButton>
      {hasError && <FormHelperText error={hasError}>Zoom range must be in ascending order</FormHelperText>}
    </Container>
  );
};

export default CameraExpressionField;
