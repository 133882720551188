import React from "react";
import { Videocam } from "@mui/icons-material";
import { Button } from "@mui/material";
import image1 from "svg_icons/financial-analytics-tour/slide-1.svg";
import image2 from "svg_icons/financial-analytics-tour/slide-2.svg";
import image3 from "svg_icons/financial-analytics-tour/slide-3.svg";
import image4 from "svg_icons/financial-analytics-tour/slide-4.svg";

import BasicTour from "./BasicTour";

import { BodyText } from "./BasicTour.styles";

type Slides = Array<{
  image: string;
  title: string;
  content: React.ReactNode;
}>;

const slides: Slides = [
  {
    image: image1,
    title: "Explore FOND's Newest Features!",
    content: (
      <>
        <BodyText component="p">Get ready to explore FOND's latest enhancements! We've fine-tuned your experience with impactful upgrades.</BodyText>
        <br />
        <BodyText>Ready for a quick tour?</BodyText>
      </>
    ),
  },
  {
    image: image2,
    title: "Introducing Financial Planner!",
    content: (
      <>
        <BodyText component="p">This tool allows you to generate a Business Case Analysis based on your design.</BodyText>
        <br />
        <Button startIcon={<Videocam />} component="a" href="https://youtu.be/nCX-DUkqmbo" target="_blank">
          Watch video
        </Button>
      </>
    ),
  },
  {
    image: image3,
    title: "Planner Extended Design Time",
    content: (
      <BodyText component="p">
        FOND's Extended Design Time enables intricate designs and efficient workflows by choosing "Express", "Balanced" or "Extended" in a ready
        Planner Project.
      </BodyText>
    ),
  },
  {
    image: image4,
    title: "Bill Of Materials Categorization",
    content: (
      <BodyText component="p">
        This new feature enables categorization of BOM line items into "Material", "Labor" or custom labels for clearer cost reporting and
        transparency.
      </BodyText>
    ),
  },
];

interface IProps {
  onComplete(): void;
}

const FinancialAnalyticsIntro: React.FC<IProps> = ({ onComplete }) => <BasicTour onComplete={onComplete} slides={slides} name="ui-improvements" />;

export default FinancialAnalyticsIntro;
