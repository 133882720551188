import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

export const TextBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  marginRight: theme.spacing(3),
  border: `1px solid ${theme.palette.biarri.secondary.whiteSmoke}`,
  borderRadius: theme.shape.borderRadius,
  maxHeight: 112,
  overflow: "auto",
}));

export const StyledInfoPanel = styled(Box)(({ theme }) => ({
  width: 280,
  paddingBottom: theme.spacing(2),
  overflow: "auto",
  "& .MuiSvgIcon-root": {
    fontSize: 16,
    verticalAlign: "middle",
  },
  "& .MuiTypography-root ": {
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
}));
