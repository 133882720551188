import React from "react";
import { Typography } from "@mui/material";

import SignInAdornment from "fond/signUp/SignInAdornment";
import VerifyEmailForm from "fond/signUp/VerifyEmailForm";
import { PageCentred } from "fond/widgets/PageCentred";

/**
 * The top level component for the verifyemail route. Presents the VerifyEmailForm in a SignInContainer.
 */
const VerifyEmail: React.FC = () => {
  return (
    <PageCentred
      title="Verify email"
      description={<Typography sx={{ mr: 1 }}>Verifying your email address.</Typography>}
      rightAdornment={<SignInAdornment />}
    >
      <VerifyEmailForm />
    </PageCentred>
  );
};

export default VerifyEmail;
