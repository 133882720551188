import { Home } from "@mui/icons-material";
import { keyframes, styled, TableRow as BaseTableRow } from "@mui/material";
import { deepPurple } from "@mui/material/colors";

import { staticUrl } from "fond/svg_icons";
import { fixSsrWarning } from "fond/utils/css";

export const TableRow = styled(BaseTableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& > td > svg": {
    display: "block",
    margin: "auto",
    height: 18,
    width: 18,
  },
}));

const rotate1 = keyframes`
  100% {
    transform: rotate(60deg);
  }
`;

const rotate2 = keyframes`
  0% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(120deg);
  }
`;

const rotate3 = keyframes`
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

export const SemiDonutChart = styled("ul")`
  --donut-size: 240px;
  --donut-thickness: 36px;

  position: relative;
  width: var(--donut-size);
  height: calc(var(--donut-size) / 2);
  overflow: hidden;
  list-style-type: none;

  &::after {
    content: ${staticUrl(<Home fontSize="large" />)};
    position: absolute;
    left: 50%;
    bottom: 0px;
    height: 40px;
    width: 40px;
    opacity: 54%;
    transform: translateX(-50%);
  }
`;

export const SemiDonutChartSlice = styled("li")`
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: var(--donut-thickness) solid;
  border-top: none;
  border-bottom-left-radius: calc(var(--donut-size) / 2);
  border-bottom-right-radius: calc(var(--donut-size) / 2);
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: linear;

  &:nth-child(1) ${fixSsrWarning} {
    z-index: 3;
    border-color: ${deepPurple.A100};
    animation-name: ${rotate1};
  }
  &:nth-child(2) ${fixSsrWarning} {
    z-index: 2;
    border-color: ${deepPurple.A200};
    animation-name: ${rotate2};
    animation-delay: 0.2s;
  }
  &:nth-child(3) ${fixSsrWarning} {
    z-index: 1;
    border-color: ${deepPurple.A700};
    animation-name: ${rotate3};
    animation-delay: 0.4s;
  }
`;
