import React, { useEffect, useState } from "react";
import { WarningAmber } from "@mui/icons-material";
import { AlertTitle, Tooltip } from "@mui/material";

import { FullReport } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";

import { Alert, ShowWarningButton } from "./Interpretation.styles";

interface IProps {
  report: FullReport;
}

type InterpretationType = "NPV" | "NO_INSTALL" | "FIRST_PHASE";

/**
 * Determines if the report is showing an net profit value < 0
 */
export const isUnprofitable = (report: FullReport): boolean => {
  const { Npv } = report;
  return Number(Npv) < 0;
};

/**
 * Determines if the report is indicating that nothing will be installed
 */
export const isNothingInstalled = (report: FullReport): boolean => {
  const { CoveragePassings } = report;
  return CoveragePassings === 0;
};

/**
 * Determines if the report is indicating that everything will be installed within the first phase
 */
export const isFirstPhase = (report: FullReport): boolean => {
  const { BuildOrderMap, CoveragePassings, Schedules } = report;
  const firstPhase = BuildOrderMap?.Phases?.[0];
  return (
    !!firstPhase &&
    CoveragePassings === 1 &&
    !Schedules?.Hhp.reduce((result, next) => {
      return result || (next.AddressType === "All" && next.Phase !== firstPhase && next.NewPassings > 0);
    }, false)
  );
};

const Interpretation: React.FC<IProps> = ({ report }: IProps) => {
  const [tests, setTests] = useState<Record<InterpretationType, boolean>>({
    NPV: false,
    NO_INSTALL: false,
    FIRST_PHASE: false,
  });

  const [collapse, setCollapse] = useState<Record<InterpretationType, boolean>>(
    getItem(`${report.ID}_interpretations`, {
      NVP: false,
      NO_INSTALL: false,
      FIRST_PHASE: false,
    })
  );

  useEffect(() => {
    setTests({
      NPV: isUnprofitable(report),
      NO_INSTALL: isNothingInstalled(report),
      FIRST_PHASE: isFirstPhase(report),
    });
  }, [report]);

  useEffect(() => {
    setItem(`${report.ID}_interpretations`, collapse);
  }, [collapse, report.ID]);

  return (
    <>
      {Object.values(collapse).some((value) => value) && (
        <Tooltip title="Show interpretations of this report">
          <ShowWarningButton
            onClick={() => {
              setCollapse({
                NPV: false,
                NO_INSTALL: false,
                FIRST_PHASE: false,
              });
            }}
          >
            <WarningAmber color="warning" />
          </ShowWarningButton>
        </Tooltip>
      )}
      {tests.NPV && !collapse.NPV && (
        <Alert
          onClose={() => {
            setCollapse({
              ...collapse,
              NPV: true,
            });
          }}
        >
          <AlertTitle>Interpretation</AlertTitle>
          Your project might be unprofitable due to a negative NPV, which indicates potential losses. However, a project may also appear unprofitable
          if your input data is incorrect. To address this, consider the following:
          <ol>
            <li>
              <b>Extend the planning period:</b> You may be ending the planning too early, missing from potential subscription revenue from new
              connections.
            </li>
            <li>
              <b>Use terminal value inputs:</b> Account for subscription revenues obtained after the planning period.
            </li>
            <li>
              <b>Review revenue inputs:</b> Ensure ARPU and take rates are appropriate for the addresses in your design.
            </li>
            <li>
              <b>Check project costs:</b> Ensure costs (BOM) are not overinflated.
            </li>
            <li>
              <b>Review resource allocation inputs:</b> Ensure they are not overly restrictive and allow for enough addresses to be passed.
            </li>
          </ol>
        </Alert>
      )}

      {tests.NO_INSTALL && !collapse.NO_INSTALL && (
        <Alert
          onClose={() => {
            setCollapse({
              ...collapse,
              NO_INSTALL: true,
            });
          }}
        >
          <AlertTitle>Interpretation</AlertTitle>
          Why is nothing being installed? Consider these key factors:
          <ol>
            <li>
              <b>Resource Allocation:</b>
              <ul>
                <li>Review fibre, address, path, time, and monetary budgets.</li>
                <li>If requirements exceed the planning period's allowance, no feasible installation can occur.</li>
              </ul>
            </li>
            <li>
              <b>Revenue Considerations:</b> Ensure non-zero revenues and take rates for addresses.
            </li>
          </ol>
        </Alert>
      )}

      {tests.FIRST_PHASE && !collapse.FIRST_PHASE && (
        <Alert
          onClose={() => {
            setCollapse({
              ...collapse,
              FIRST_PHASE: true,
            });
          }}
        >
          <AlertTitle>Interpretation</AlertTitle>
          Why is everything being installed in the first phase? consider the following factors:
          <ol>
            <li>
              <b>Resource Allocation:</b>
              <ul>
                <li>Review your budgets and ensure they realistically limit the installation run rate.</li>
                <li>If there are no constraints, the CFO may choose to build everything to maximize revenue from passed addresses.</li>
              </ul>
            </li>
            <li>
              <b>Cost Considerations:</b> Ensure non-zero revenues and take rates for addresses.
              <ul>
                <li>Verify that costs defined in the BOM (Bill of Materials) are realistic.</li>
                <li>If costs are not set or are too low, the CFO will build as much as possible within other installation budgets.</li>
              </ul>
            </li>
          </ol>
        </Alert>
      )}
    </>
  );
};

export default Interpretation;
