import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Folder } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Divider, LinearProgress, Typography } from "@mui/material";

import { LoadingButton } from "ui";

import { useCarveMultiProjectMutation } from "fond/api";
import { CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN } from "fond/constants";
import { MultiProject } from "fond/types";

import { SubareaList } from "../SubareaList";

import { ViewButton } from "./CarvePanel.styles";

enum Status {
  // Projects have been created
  COMPLETE = "complete",
  // There was an error during the creation process
  ERROR = "error",
  // The projects are being created
  PENDING = "pending",
  // The projects are ready to be created
  READY = "ready",
}

interface CarvePanelProps {
  multiProject: MultiProject;
}

const CarvePanel: React.FC<CarvePanelProps> = ({ multiProject }: CarvePanelProps) => {
  const navigate = useNavigate();
  const [carveMultiProject] = useCarveMultiProjectMutation();
  const hasProjects = useMemo(() => multiProject?.Areas.some((area) => area.Project?.ID), [multiProject]);
  const [status, setStatus] = useState<Status>(hasProjects ? Status.COMPLETE : Status.READY);
  const hasExceededMaxSubareaCount = multiProject.Areas.length > CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN;

  const handleOnClick = () => {
    if (multiProject && !hasProjects) {
      setStatus(Status.PENDING);
      carveMultiProject({ ID: multiProject.ID, Actions: [{ Type: "generate_design" as const }] })
        .unwrap()
        .then(() => {
          setStatus(Status.COMPLETE);
        })
        .catch(() => {
          setStatus(Status.ERROR);
        });
    }
  };

  const handleOnViewButtonClick = () => {
    navigate(`/cities/${multiProject?.Folder.ID}`);
  };

  return (
    <>
      {status === Status.READY && (
        <>
          <Typography variant="subtitle2">No plan generated</Typography>
          <Typography variant="caption" sx={{ mb: 1 }}>
            The following subareas will have projects created.
          </Typography>
          <Box sx={{ mt: 1 }}>
            <SubareaList multiProject={multiProject} />
          </Box>
        </>
      )}

      {status === Status.PENDING && (
        <>
          <Alert severity="info">
            <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Creating subarea projects</AlertTitle>
          </Alert>
          <Box sx={{ m: 2 }}>
            <LinearProgress variant="indeterminate" sx={{ mb: 0.5 }} />
            <Typography variant="caption">Processing</Typography>
          </Box>
        </>
      )}

      {status === Status.COMPLETE && (
        <>
          <Alert severity="success">
            <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Subareas created</AlertTitle>
          </Alert>
          <Box sx={{ m: 1 }}>
            <Typography variant="caption">All subarea projects have been created</Typography>
          </Box>
          <Divider />
          <ViewButton startIcon={<Folder color="primary" />} onClick={handleOnViewButtonClick} fullWidth>
            View city planner projects
          </ViewButton>
          <Divider />
        </>
      )}

      {status === Status.ERROR && (
        <Alert severity="error">
          <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Error creating subarea projects</AlertTitle>
          Something went wrong with creating the subarea projects. Please try again.
        </Alert>
      )}

      {hasExceededMaxSubareaCount && (
        <Alert sx={{ mt: 2 }} severity="error">
          <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Too many subareas</AlertTitle>
          Projects can be created for up to {CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN} subareas but {multiProject.Areas.length} are currently
          selected. Please reduce the number of subareas selected and try again.
        </Alert>
      )}

      {status !== Status.COMPLETE && (
        <LoadingButton
          onClick={handleOnClick}
          fullWidth
          variant="contained"
          disabled={status === Status.PENDING || hasExceededMaxSubareaCount}
          loading={status === Status.PENDING}
          sx={{ mt: 1 }}
        >
          Create Projects
        </LoadingButton>
      )}
    </>
  );
};

export default CarvePanel;
