import { Box, styled } from "@mui/material";

export const ExpandIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>(({ isOpen }) => ({
  position: "relative",
  transition: "transform linear 0.1s",
  transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
  "& svg": {
    fontSize: "1rem",
  },
  width: "var(--rct-arrow-container-size)",
  height: "var(--rct-arrow-container-size)",
  cursor: "var(--rct-cursor)",
  marginRight: "calc(-1 * var(--rct-arrow-container-size) + var(--rct-arrow-padding))",
  zIndex: 1,
  marginLeft: 4,
}));
