import { Folder, PermissionLevel } from "fond/types";

/**
 * Generates a collection of parent names based on the ParentID nesting of folders
 * @param folders a collection of Folders
 * @param folderId the current folder
 */
export const getPath = (folders: Folder[], folderId?: string | null, requiredPermission?: PermissionLevel[]): string[] => {
  const names: string[] = [];

  const getFolder = (id: string) => {
    const parent: Folder | undefined = folders.find((f: Folder) => f.ID === id);
    if (parent && (!requiredPermission || requiredPermission.includes(parent.Permission.Level))) {
      names.unshift(parent.Name);
      if (parent.ParentID) {
        getFolder(parent.ParentID);
      }
    }
  };

  if (folderId) getFolder(folderId);

  return names;
};
