import { Box, styled } from "@mui/material";

export const MapLegendBox = styled(Box)`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  max-height: calc(100% - 180px);
  overflow: hidden;
`;

export const MapLegendContainer = styled(Box)(
  ({ theme }) => `
      flex: 1;
      background-color: rgba(255, 255, 255, 0.95);
      border-radius: ${theme.shape.borderRadius}px;
      padding: ${theme.spacing(1)};
      overflow-y: auto;
      overflow-x: hidden;
    `
);

export const MapCalloutContainer = styled(Box)(
  ({ theme }) => `
      background-color: rgba(255, 255, 255, 0.95);
      border-radius: ${theme.shape.borderRadius}px;
      padding: ${theme.spacing(1)};
    `
);

export const Button = styled("button")`
  &.rct-tree-item-button {
    margin: 0 0 2px 2px;
    padding: 0;
  }
`;
