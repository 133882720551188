import React from "react";
import { useSelector } from "react-redux";
import { AddBox } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

import { selectVersionMlcId, useCreateGroupConfigMutation, useGetDraftQuery } from "fond/api";
import { FilterStyles } from "fond/styleEditor";
import { GroupConfig } from "fond/types";
import { makeUuid } from "fond/utils";

import { Actions, Container, IconButton } from "./listHeader.styles";

export const ListHeader: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const mapLayerConfigId = useSelector(selectVersionMlcId);
  const { data } = useGetDraftQuery(mapLayerConfigId);
  const [createGroupConfig] = useCreateGroupConfigMutation();

  const handleOnAddGroup = () => {
    if (data) {
      const ID = makeUuid();
      const newGroupConfig: GroupConfig = {
        ID,
        Label: "Untitled Group",
        IsVisible: true,
        ParentID: null,
        Type: "GROUP",
        Children: [],
        RootID: data.ID,
        GlobalPosition: 0,
        Position: 0,
        Key: null,
      };

      createGroupConfig({ newGroupConfig, mapLayerConfigId }).catch(() => {
        enqueueSnackbar("Failed to save draft changes", { variant: "error" });
      });
    }
  };

  return (
    <Container>
      <FilterStyles />
      <Actions>
        <Tooltip title="Add Group">
          <IconButton onClick={handleOnAddGroup} data-testid="style-group-add">
            <AddBox color="primary" />
          </IconButton>
        </Tooltip>
      </Actions>
    </Container>
  );
};

export default ListHeader;
