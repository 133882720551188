import { ValueFormatterParams } from "@ag-grid-community/core";

/**
 * valueRenderer that formats a user profile
 */
const userValueFormatter =
  (currentUsername: string) =>
  (params: ValueFormatterParams): string => {
    return params.value === currentUsername ? "me" : params.value;
  };

export default userValueFormatter;
