import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ResizableBox } from "fond/widgets";

/**
 * Resizable box with column directed flex contents, tinted background and drop shadow.
 */
export interface ResizableFlexBoxProps {}

export const ResizableFlexBox = styled(ResizableBox)<ResizableFlexBoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.custom.shadows.default,
  borderRadius: "4px",
  border: `1px solid ${theme.palette.biarri.primary.midnight}18`,
}));

export const Indicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<{ bgColor: string }>(({ theme, bgColor }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  background: bgColor,
  height: 30,
  width: 30,

  "& > *": {
    color: theme.palette.primary.contrastText,
  },
}));
