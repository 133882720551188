import React from "react";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";

import { GoBackButton } from "ui";

import CreateAccountForm from "fond/accounts/CreateAccountForm";
import { CreateAccountIcon } from "fond/svg_icons";
import { PageCentred } from "fond/widgets/PageCentred";

/**
 * The top level component for the createaccount route. Presents the CreateAccountForm.
 */
const CreateAccount: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate(-1);
    event.preventDefault();
  };

  return (
    <PageCentred
      title="Set up your account"
      description={<Typography>A collaborative space where members can create and share design projects.</Typography>}
      icon={
        <>
          <GoBackButton onClick={handleGoBackClick} />
          <CreateAccountIcon />
        </>
      }
    >
      <CreateAccountForm />
    </PageCentred>
  );
};

export default CreateAccount;
