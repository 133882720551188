import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import pick from "lodash/pick";

import { Architecture, Store } from "fond/types";

import { apiSlice } from "./apiSlice";

export type GetArchitecturesResponse = {
  Architectures: Architecture[];
};

/*
 * Pyload required to create an Architecture.
 */
type CreateArchitecture = Pick<
  Architecture,
  | "Name"
  | "Description"
  | "Demand"
  | "NumberOfTiers"
  | "HasAttemptedSave"
  | "IsConfigured"
  | "IsFlexNap"
  | "BOM"
  | "DefaultPlacement"
  | "Tier1"
  | "Tier2"
  | "Tier3"
>;

export const architecturesAdapter = createEntityAdapter<Architecture>({
  selectId: (entity: Architecture): string => entity.ID,
  // Keep the "all IDs" array sorted based on createdAt (desc)
  sortComparer: (a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime(),
});
const architecturesInitialState = architecturesAdapter.getInitialState();

/**
 * Architectures API Slice
 */
export const architecturesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getArchitectures: build.query({
      query: () => `/v2/architectures`,
      transformResponse: (response: GetArchitecturesResponse) => architecturesAdapter.setAll(architecturesInitialState, response.Architectures),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "Architecture" as const, id })), { type: "Architecture", id: "LIST" }]
          : [{ type: "Architecture", id: "LIST" }],
    }),
    createArchitecture: build.mutation<Architecture, { AccountID: string } & CreateArchitecture>({
      query: (architecture) => ({
        url: "/v2/architectures",
        method: "POST",
        body: pick(architecture, [
          "AccountID",
          "Name",
          "Description",
          "Demand",
          "NumberOfTiers",
          "HasAttemptedSave",
          "IsConfigured",
          "IsFlexNap",
          "BOM",
          "DefaultPlacement",
          "Tier1",
          "Tier2",
          "Tier3",
        ]),
      }),
      invalidatesTags: [{ type: "Architecture", id: "LIST" }],
    }),
    updateArchitecture: build.mutation<Architecture, Partial<Architecture>>({
      query: (architecture: Architecture) => ({
        url: `/v2/architectures/${architecture.ID}`,
        method: "PATCH",
        body: pick(architecture, [
          "Name",
          "Description",
          "Demand",
          "NumberOfTiers",
          "HasAttemptedSave",
          "IsConfigured",
          "IsFlexNap",
          "BOM",
          "DefaultPlacement",
          "Tier1",
          "Tier2",
          "Tier3",
        ]),
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Architecture", id: arg.ID }],
    }),
    deleteArchitecture: build.mutation<undefined, Architecture>({
      query: ({ ID, ...architecture }) => ({
        url: `/v2/architectures/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Architecture", id: arg.ID }],
    }),
  }),
  overrideExisting: false,
});

/**
 * Endpoint Hooks
 */
export const { useGetArchitecturesQuery, useCreateArchitectureMutation, useDeleteArchitectureMutation, useUpdateArchitectureMutation } =
  architecturesSlice;

/**
 * Selectors
 */
const selectArchitecturesResult = architecturesSlice.endpoints.getArchitectures.select(undefined);
const selectArchitecturesData = createSelector(selectArchitecturesResult, (architecturesResult) => architecturesResult.data);

export const { selectAll: selectAllArchitectures, selectById: selectArchitectureById } = architecturesAdapter.getSelectors(
  (state: Store) => selectArchitecturesData(state) ?? architecturesInitialState
);
