import React, { PropsWithChildren } from "react";
import { Box, ToggleButtonProps, Tooltip } from "@mui/material";

import { ToggleButton } from "./toolbar.styles";

interface IProps {
  title: string;
}

const ToolbarToggleButton: React.FC<PropsWithChildren<IProps & ToggleButtonProps>> = ({ title, children, ...props }) => {
  return (
    <Tooltip title={title} placement="right">
      <Box component="span" sx={{ "&:not(:last-child)": { mb: 0.5 } }}>
        <ToggleButton {...props}>{children}</ToggleButton>
      </Box>
    </Tooltip>
  );
};

export default ToolbarToggleButton;
