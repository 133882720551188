import React from "react";
import { AlarmOn, AttachMoney, MultipleStop, Percent } from "@mui/icons-material";
import { Table, TableBody, TableCell, Typography, useTheme } from "@mui/material";

import { FullReport } from "fond/types";
import { formatCurrency } from "fond/utils/currency";
import { formatDuration, formatMonthsAsYears } from "fond/utils/dates";
import { formatFractionPercent } from "fond/utils/number";

import { TableRow } from "./reportOverview.styles";

interface IProps {
  report: FullReport;
}

function formatPlanningHorizon(PlanningHorizon: number, StepFrequency?: string) {
  if (StepFrequency === "monthly") {
    return formatMonthsAsYears(PlanningHorizon);
  }
  if (StepFrequency === "quarterly") {
    return formatMonthsAsYears(PlanningHorizon * 3);
  }
  return formatDuration(PlanningHorizon, "year");
}

const FinalPositionTable: React.FC<IProps> = ({ report }) => {
  const { palette } = useTheme();
  const { ReportConfiguration, Irr, Roi, Npv, NetCost, NetRevenue, CoveragePassings, CoverageConnections, CoverageFootprint } = report;
  const { PlanningHorizon, StepFrequency, DiscountRate } = ReportConfiguration ?? {};

  const positiveValueColor = palette.success.main;
  const negativeValueColor = palette.error.main;

  const rows = [
    {
      key: "PlanningHorizon",
      name: "Planning period",
      value: PlanningHorizon && formatPlanningHorizon(PlanningHorizon, StepFrequency),
      icon: <AlarmOn color="primary" />,
    },
    { key: "StepFrequency", name: "Reporting frequency", value: StepFrequency, icon: <AlarmOn color="primary" /> },
    { key: "DiscountRate", name: "Discount rate", value: formatFractionPercent(DiscountRate), icon: <Percent color="primary" /> },
    {
      key: "NetRevenue",
      name: "Net Revenue",
      value: <Typography>{formatCurrency(NetRevenue)}</Typography>,
      icon: <AttachMoney color="success" />,
    },
    { key: "NetCost", name: "Net Cost", value: formatCurrency(NetCost), icon: <AttachMoney color="success" /> },
    {
      key: "Npv",
      name: "NPV",
      value: (
        <Typography color={Npv && Npv > 0 ? positiveValueColor : negativeValueColor} fontWeight={700}>
          {formatCurrency(Npv)}
        </Typography>
      ),
      icon: <AttachMoney color="success" />,
    },
    { key: "Irr", name: "IRR", value: formatFractionPercent(Irr), icon: <Percent color="primary" /> },
    { key: "DiscountedROI", name: "ROI", value: formatFractionPercent(Roi), icon: <Percent color="primary" /> },
    { key: "CoveragePassings", name: "Coverage - Passings", value: formatFractionPercent(CoveragePassings), icon: <MultipleStop color="error" /> },
    {
      key: "CoverageConnections",
      name: "Coverage - Connections",
      value: formatFractionPercent(CoverageConnections),
      icon: <MultipleStop color="error" />,
    },
    { key: "CoverageFootprint", name: "Coverage - Footprint", value: formatFractionPercent(CoverageFootprint), icon: <MultipleStop color="error" /> },
  ];

  return (
    <Table size="small" sx={{ tableLayout: "fixed" }}>
      <TableBody>
        {rows.map(({ key, name, value, icon }) => (
          <TableRow key={key}>
            <TableCell padding="checkbox">{icon}</TableCell>
            <TableCell align="left">{name}</TableCell>
            <TableCell width={160}>{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FinalPositionTable;
