import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";

import { selectDraftStyleById } from "fond/api";
import { MapContext } from "fond/map/MapProvider";
import { StylesPopup } from "fond/styleEditor";
import { Store } from "fond/types";
import { LayerStyle } from "fond/types/ProjectLayerConfig";

const FeatureStyleHandler: React.FC = () => {
  const { map } = useContext(MapContext);
  const selectStyle = useSelector((state: Store) => (id: string) => selectDraftStyleById(state, id));
  const selectedId = useSelector((state: Store) => state.styles.settings.selectedId);

  const [lngLat, setLngLat] = useState<mapboxgl.LngLatLike | undefined>();
  const [styles, setStyles] = useState<LayerStyle[]>([]);

  const handleOnClose = () => {
    setLngLat(undefined);
  };

  /**
   * Handles the click event for the map.
   * Callback is used to update the function when map or editMode changes
   */
  const handleOnMapClick = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      // Locate features within a certain boundary of where the user clicked
      const queryBbox: [mapboxgl.PointLike, mapboxgl.PointLike] = [
        [event.point.x - 5, event.point.y - 5],
        [event.point.x + 5, event.point.y + 5],
      ];

      // Get all unique features within the boundary
      const features = uniqBy(
        map?.queryRenderedFeatures(queryBbox).filter((feature: mapboxgl.MapboxGeoJSONFeature) => feature.properties?.layerId != null),
        "layer.id"
      );

      const selectedStyles: LayerStyle[] = [];
      features.forEach((feature) => {
        const style = selectStyle(feature.layer.id);
        if (style) {
          selectedStyles.push(style);
        }
      });

      if (features.length >= 1) {
        setStyles(uniqBy(selectedStyles, "ID"));
        setLngLat(event.lngLat);
      } else {
        handleOnClose();
      }
    },
    [map, selectStyle]
  );

  useEffect(() => {
    map?.on("click", handleOnMapClick);

    return () => {
      map?.off("click", handleOnMapClick);
    };
  }, [handleOnMapClick, map]);

  useEffect(() => {
    handleOnClose();
  }, [selectedId]);

  return <>{lngLat && <StylesPopup onClose={handleOnClose} lngLat={lngLat} styles={styles} />}</>;
};

export default FeatureStyleHandler;
