import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

const customStyles = (theme: Theme) => {
  return createStyles({
    root: {
      background: "none",
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The placeholder text content
   * @default "Search"
   */
  placeholder?: string;
  /**
   * Callback function for when the search criteria changes
   */
  onChange(searchText: string): void;
  /**
   * Custom search icon, defaults to mui SearchIcon magnifying glass
   * @default <SearchIcon />
   */
  icon?: React.ReactNode;
}

/**
 * A search bar component which calls an action every time the user presses a key.
 */
const SearchBar: React.FC<IProps> = ({ classes, placeholder = "Search", onChange, icon }: IProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.currentTarget.value);
  };

  return (
    <TextField
      fullWidth
      variant="filled"
      margin="dense"
      name="search"
      size="small"
      placeholder={placeholder}
      onChange={handleInputChange}
      InputProps={{
        className: classes.root,
        startAdornment: icon ?? <SearchIcon style={{ marginRight: "0.5em" }} />,
      }}
      data-testid="projects-search-bar"
    />
  );
};

export default withStyles(customStyles)(SearchBar);
