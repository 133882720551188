import styled from "@emotion/styled";
import { FormHelperText, Select, SelectProps } from "@mui/material";

import { SystemsOfMeasurement } from "fond/types";

export const ShortSelect = styled((props: SelectProps<`${SystemsOfMeasurement}`>) => <Select {...props} />)(() => ({
  width: 150,
  paddingLeft: 0.5,
}));

export const SelectHelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
}));
