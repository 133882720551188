import React from "react";
import { useField } from "react-final-form";
import { Add } from "@mui/icons-material";
import { Button, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";

import { OverheadCost } from "fond/types";
import { makeUuid } from "fond/utils";

import OverheadCostAllocationRow from "./OverheadCostAllocationRow";

import { TableCell, TableHeadCell, TableRow } from "./OverheadCostAllocation.styles";

interface IProps {
  disabled: boolean;
}

const OverheadCostAllocation: React.FC<IProps> = ({ disabled }) => {
  const {
    input: { onChange, value: overheadCostAllocation },
  } = useField("ReportConfiguration.CostConfiguration.Costs");

  const addRow = () => onChange([...overheadCostAllocation, { ID: makeUuid(), Name: null, Cost: { Type: null } }]);
  const deleteRow = (index: number) => {
    const clone = [...overheadCostAllocation];
    clone.splice(index, 1);
    onChange(clone);
  };

  return (
    <TableContainer sx={{ borderRadius: 1, border: (theme) => `1px solid ${theme.palette.divider}`, my: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow className="table-head-row">
            <TableHeadCell width={210}>
              <Typography>Name</Typography>
            </TableHeadCell>
            <TableHeadCell width={245}>
              <Typography>Methodology</Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography>Cost ($)</Typography>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {overheadCostAllocation &&
            overheadCostAllocation.map((values: OverheadCost & { ID: string }, index: number) => (
              <OverheadCostAllocationRow key={values.ID} values={values} index={index} deleteRow={() => deleteRow(index)} disabled={disabled} />
            ))}
          <TableRow>
            <TableCell colSpan={3}>
              <Button disabled={disabled} onClick={addRow} size="small" fullWidth startIcon={<Add fontSize="inherit" />} role="button">
                Add cost
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverheadCostAllocation;
