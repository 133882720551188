import React from "react";
import { Avatar as MuiAvatar, AvatarProps, styled } from "@mui/material";

interface IProps extends AvatarProps {
  /**
   * Defined the maximum intials used from the name prop.
   */
  maxInitials?: number;
  /**
   * The name used to determine the initials if children have not been passed
   */
  name?: string;
}

/**
 * Helper function that returns the initials of the passed name.  If a maxInitials is
 * passed the value will be limited to the number set.
 */
function defaultInitials(name: string, maxInitials?: number) {
  return name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v)
    .slice(0, maxInitials)
    .join("")
    .toUpperCase();
}

const Avatar: React.FC<IProps> = ({ children, maxInitials, name = "", ...rest }: IProps) => {
  return <MuiAvatar {...rest}>{children || defaultInitials(name, maxInitials)}</MuiAvatar>;
};

export const MediumAvatar = styled(Avatar)(() => ({
  height: "34px",
  width: "34px",
}));

export default Avatar;
