import { FC } from "react";
import { Location, useLocation } from "react-router";
import { Link, Typography } from "@mui/material";
import { capitalize } from "lodash";

import { HELP_CENTER_URL } from "fond/constants";
import ErrorPage from "fond/errorPage/ErrorPage";
import { PageNotFoundIcon } from "fond/svg_icons";
import { ResourceType } from "fond/types";

type LocationState = {
  resourceType?: ResourceType;
};

/**
 * The component to show in the case that the URL can't be matched to a component
 * by the router in index.js, i.e. a 404.
 */
const ResourceNotFound: FC = () => {
  const location = useLocation() as Location<LocationState>;

  let resourceType = "page";
  if (location?.state?.resourceType && ResourceType?.[location.state.resourceType]) {
    resourceType = ResourceType[location.state.resourceType];
  }

  const message = `We're sorry, we can't find the ${resourceType} you're looking for or you may not have access to the ${resourceType} you're trying to access.`;
  const title = `${capitalize(resourceType)} not found`;

  return (
    <ErrorPage
      title={title}
      icon={<PageNotFoundIcon />}
      heading={title}
      message={
        <Typography data-testid="error-not-found" component="div">
          <p>{message}</p>
          <p>
            If you still need help, visit our&nbsp;
            <Link color="primary" target="_blank" rel="noopener noreferrer" href={HELP_CENTER_URL}>
              <b>Help Center</b>
            </Link>
            .
          </p>
        </Typography>
      }
    />
  );
};

export default ResourceNotFound;
