import React, { createContext, PropsWithChildren } from "react";
import { HistoryState, useHistoryState } from "@uidotdev/usehooks";

interface UndoRedoProviderProps<S> extends PropsWithChildren {
  initialState?: S;
}

export const HistoryContext = createContext<HistoryState<any>>(undefined!);

const HistoryProvider = <S,>({ children, initialState }: UndoRedoProviderProps<S>): JSX.Element => {
  const history = useHistoryState<S>(initialState);

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
};

export default HistoryProvider;
