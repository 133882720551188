import { StyleConfig } from "fond/types";

export const config: StyleConfig = {
  line: [
    {
      groupLabel: "Line",
      fields: [
        {
          fieldName: "LineColor",
          label: "Color",
          mapboxKey: "line-color",
        },
        {
          fieldName: "LineOpacity",
          label: "Opacity",
          mapboxKey: "line-opacity",
        },
        {
          fieldName: "LineWidth",
          label: "Width",
          mapboxKey: "line-width",
        },
        {
          fieldName: "LineCap",
          label: "Cap",
          mapboxKey: "line-cap",
        },
        {
          fieldName: "LineJoin",
          label: "Join",
          mapboxKey: "line-join",
        },
        {
          fieldName: "LineOffset",
          label: "Offset",
          mapboxKey: "line-offset",
        },
        {
          fieldName: "LineDasharray",
          label: "Dash array",
          mapboxKey: "line-dasharray",
        },
        {
          fieldName: "LineGapWidth",
          label: "Gap width",
          mapboxKey: "line-gap-width",
        },
      ],
    },
    {
      groupLabel: "Visiblity",
      fields: [
        {
          fieldName: "MinZoom",
          label: "Minimum zoom",
          mapboxKey: "minzoom",
        },
        {
          fieldName: "MaxZoom",
          label: "Maximum zoom",
          mapboxKey: "maxzoom",
        },
      ],
    },
  ],
  circle: [
    {
      groupLabel: "Point",
      fields: [
        {
          fieldName: "CircleRadius",
          label: "Radius",
          mapboxKey: "circle-radius",
        },
        {
          fieldName: "CircleColor",
          label: "Color",
          mapboxKey: "circle-color",
        },
        {
          fieldName: "CircleOpacity",
          label: "Opacity",
          mapboxKey: "circle-opacity",
        },
        {
          fieldName: "CircleStrokeColor",
          label: "Stroke color",
          mapboxKey: "circle-stroke-color",
        },
        {
          fieldName: "CircleStrokeWidth",
          label: "Stroke width",
          mapboxKey: "circle-stroke-width",
        },
        {
          fieldName: "CircleStrokeOpacity",
          label: "Stroke opacity",
          mapboxKey: "circle-stroke-opacity",
        },
        {
          fieldName: "CircleTranslate",
          label: "Translate",
          mapboxKey: "circle-translate",
        },
      ],
    },
    {
      groupLabel: "Visiblity",
      fields: [
        {
          fieldName: "MinZoom",
          label: "Minimum zoom",
          mapboxKey: "minzoom",
        },
        {
          fieldName: "MaxZoom",
          label: "Maximum zoom",
          mapboxKey: "maxzoom",
        },
      ],
    },
  ],
  // Will be the Font Icon currently not implemented
  icon: [
    {
      groupLabel: "Icon",
      fields: [
        {
          fieldName: "FontIconCode",
          label: "Icon",
          mapboxKey: "text-field",
        },
        {
          fieldName: "FontIconColor",
          label: "Color",
          mapboxKey: "text-color",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconOpacity",
          label: "Opacity",
          mapboxKey: "text-opacity",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconSize",
          label: "Size",
          mapboxKey: "text-size",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconRotate",
          label: "Rotate",
          mapboxKey: "text-rotate",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconHaloColor",
          label: "Halo color",
          mapboxKey: "text-halo-color",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconHaloWidth",
          label: "Halo width",
          mapboxKey: "text-halo-width",
          requirements: {
            FontIconCode: "*",
          },
        },
      ],
    },
    {
      groupLabel: "Placement",
      fields: [
        {
          fieldName: "FontIconSymbolPlacement",
          label: "Placement",
          mapboxKey: "symbol-placement",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconRotationAlignment",
          label: "Alignment",
          mapboxKey: "text-rotation-alignment",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconIgnorePlacement",
          label: "Ignore placement",
          mapboxKey: "text-ignore-placement",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconAllowOverlap",
          label: "Allow overlap",
          mapboxKey: "text-allow-overlap",
          requirements: {
            FontIconCode: "*",
          },
        },
        {
          fieldName: "FontIconKeepUpright",
          label: "Keep upright",
          mapboxKey: "text-keep-upright",
          requirements: {
            FontIconRotationAlignment: ["map"],
            FontIconSymbolPlacement: ["line", "line-center"],
          },
        },
        {
          fieldName: "FontIconOffset",
          label: "Offset",
          mapboxKey: "text-offset",
          requirements: {
            FontIconCode: "*",
          },
        },
      ],
    },
    {
      groupLabel: "Visiblity",
      fields: [
        {
          fieldName: "MinZoom",
          label: "Minimum zoom",
          mapboxKey: "minzoom",
        },
        {
          fieldName: "MaxZoom",
          label: "Maximum zoom",
          mapboxKey: "maxzoom",
        },
      ],
    },
  ],
  symbol: [
    {
      groupLabel: "Icon",
      fields: [
        {
          fieldName: "IconImageID",
          label: "Image",
          mapboxKey: "icon-image",
        },
        {
          fieldName: "IconOpacity",
          label: "Opacity",
          mapboxKey: "icon-opacity",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconSize",
          label: "Size",
          mapboxKey: "icon-size",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconRotate",
          label: "Rotate",
          mapboxKey: "icon-rotate",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconOffset",
          label: "Offset",
          mapboxKey: "icon-offset",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconTextFit",
          label: "Text Fit",
          mapboxKey: "icon-text-fit",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconTextFitPadding",
          label: "Text fit padding",
          mapboxKey: "icon-text-fit-padding",
          requirements: {
            IconImageID: "*",
            IconTextFit: ["height", "width", "both"],
          },
        },
      ],
    },
    {
      groupLabel: "Text",
      fields: [
        {
          fieldName: "TextFields",
          label: "Field",
          mapboxKey: "text-field",
        },
        {
          fieldName: "TextColor",
          label: "Color",
          mapboxKey: "text-color",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextOpacity",
          label: "Opacity",
          mapboxKey: "text-opacity",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextFont",
          label: "Font",
          mapboxKey: "text-font",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextSize",
          label: "Size",
          mapboxKey: "text-size",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextTransform",
          label: "Transform",
          mapboxKey: "text-transform",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextPadding",
          label: "Padding",
          mapboxKey: "text-padding",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextAnchor",
          label: "Anchor",
          mapboxKey: "text-anchor",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextOffset",
          label: "Text offset",
          mapboxKey: "text-offset",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextRotate",
          label: "Text rotate",
          mapboxKey: "text-rotate",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextAllowOverlap",
          label: "Allow overlap",
          mapboxKey: "text-allow-overlap",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextIgnorePlacement",
          label: "Ignore placement",
          mapboxKey: "text-ignore-placement",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextLetterSpacing",
          label: "Letter spacing",
          mapboxKey: "text-letter-spacing",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextLineHeight",
          label: "Line height",
          mapboxKey: "text-line-height",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextMaxWidth",
          label: "Max width",
          mapboxKey: "text-max-width",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextHaloColor",
          label: "Halo color",
          mapboxKey: "text-halo-color",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextHaloWidth",
          label: "Halo width",
          mapboxKey: "text-halo-width",
          requirements: {
            TextFields: "*",
          },
        },
        {
          fieldName: "TextHaloBlur",
          label: "Halo blur",
          mapboxKey: "text-halo-blur",
          requirements: {
            TextFields: "*",
          },
        },
      ],
    },
    {
      groupLabel: "Placement",
      fields: [
        {
          fieldName: "SymbolPlacement",
          label: "Placement",
          mapboxKey: "symbol-placement",
        },
        {
          fieldName: "IconRotationAlignment",
          label: "Alignment",
          mapboxKey: "icon-rotation-alignment",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "SymbolSpacing",
          label: "Spacing",
          mapboxKey: "symbol-spacing",
          requirements: {
            SymbolPlacement: ["line"],
          },
        },
        {
          fieldName: "IconAllowOverlap",
          label: "Allow overlap",
          mapboxKey: "icon-allow-overlap",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconIgnorePlacement",
          label: "Ignore placement",
          mapboxKey: "icon-ignore-placement",
          requirements: {
            IconImageID: "*",
          },
        },
        {
          fieldName: "IconKeepUpright",
          label: "Keep upright",
          mapboxKey: "icon-keep-upright",
          requirements: {
            IconRotationAlignment: ["map"],
            SymbolPlacement: ["line", "line-center"],
          },
        },
      ],
    },
    {
      groupLabel: "Visiblity",
      fields: [
        {
          fieldName: "MinZoom",
          label: "Minimum zoom",
          mapboxKey: "minzoom",
        },
        {
          fieldName: "MaxZoom",
          label: "Maximum zoom",
          mapboxKey: "maxzoom",
        },
      ],
    },
  ],
  fill: [
    {
      groupLabel: "Fill",
      fields: [
        {
          fieldName: "FillColor",
          label: "Color",
          mapboxKey: "fill-color",
        },
        {
          fieldName: "FillOutlineColor",
          label: "1px Stroke",
          mapboxKey: "fill-outline-color",
        },
        {
          fieldName: "FillOpacity",
          label: "Opacity",
          mapboxKey: "fill-opacity",
        },
      ],
    },
    {
      groupLabel: "Visiblity",
      fields: [
        {
          fieldName: "MinZoom",
          label: "Minimum zoom",
          mapboxKey: "minzoom",
        },
        {
          fieldName: "MaxZoom",
          label: "Maximum zoom",
          mapboxKey: "maxzoom",
        },
      ],
    },
  ],
};
