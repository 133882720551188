import React, { useMemo } from "react";
import { ColDef } from "@ag-grid-community/core";

import { SchedulesData } from "fond/types";
import { currencyValueFormatter } from "fond/widgets/AgGrid";

import { VisualizationTypes } from "../VisualizationTypes";

import RevenueData from "./RevenueData";
import { transformCashflow } from "./transforms";

interface IProps {
  data?: SchedulesData["Cashflow"];
}

const OverallPosition: React.FC<IProps> = ({ data }) => {
  const pivotData = useMemo(() => transformCashflow(data), [data]);
  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "Key",
        headerName: "",
        pinned: "left",
        cellStyle: { fontWeight: 500, fontSize: 13 },
      },
      ...(data?.map((item) => ({
        field: item.Phase,
        minWidth: 125,
        width: 125,
        valueFormatter: currencyValueFormatter(),
      })) || []),
    ],
    [data]
  );

  return <RevenueData columns={columns} gridOptions={VisualizationTypes.Cashflow.gridOptions} rowData={pivotData} />;
};

export default OverallPosition;
