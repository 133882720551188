import React from "react";
import { useSelector } from "react-redux";
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { Divider, Fade, ListItemIcon, ListSubheader, Menu, MenuItem } from "@mui/material";
import { includes } from "lodash";

import { CommentFilters, CommentImportance, CommentState, Store, Version } from "fond/types";
import { NestedMenuItem } from "fond/widgets";

import { getCurrentProject } from "..";

const resolutions: Array<{ status: CommentState; title: string }> = [
  {
    status: "open",
    title: "Open",
  },
  {
    status: "resolved",
    title: "Resolved",
  },
];

type ImportanceMenuRow = { value: CommentImportance | null; title: string };

const importanceMenuRows: ImportanceMenuRow[] = [
  {
    value: "blocker",
    title: "Blocker",
  },
  {
    value: "critical",
    title: "Critical",
  },
  {
    value: "major",
    title: "Major",
  },
  {
    value: "minor",
    title: "Minor",
  },
  {
    value: "trivial",
    title: "Trivial",
  },
  {
    value: null,
    title: "None",
  },
];

interface IProps {
  /**
   * The anchor element the menu should be relatively positioned
   */
  anchorEl: HTMLButtonElement | null;
  filterBy: CommentFilters;
  versions: Version[];
  onChange(filters: CommentFilters): void;
  onClose(): void;
}

const FilterMenu: React.FC<IProps> = ({ anchorEl, filterBy, versions, onChange, onClose }: IProps) => {
  const hasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);

  const getCheckStatus = (commentState: CommentState) => {
    if (includes(filterBy.State, commentState)) return <CheckBox color="primary" />;
    return <CheckBoxOutlineBlankOutlined />;
  };

  const getCheckImportance = (importance: CommentImportance | null) => {
    if (includes(filterBy.Importance, importance)) return <CheckBox color="primary" />;
    return <CheckBoxOutlineBlankOutlined />;
  };

  const getCheckVersion = (id: string) => {
    if (includes(filterBy.Version, id)) return <CheckBox color="primary" />;
    return <CheckBoxOutlineBlankOutlined />;
  };

  const newFilters = { ...filterBy };

  return (
    <Menu
      anchorEl={anchorEl}
      open
      elevation={2}
      onClose={onClose}
      TransitionComponent={Fade}
      data-testid="comment-filter-menu"
      transformOrigin={{ horizontal: "left", vertical: -50 }}
    >
      <ListSubheader>Filter by</ListSubheader>
      <NestedMenuItem
        data-testid="comment-filter-resolution"
        label="Resolution"
        parentMenuOpen
        MenuProps={{
          open: true,
          MenuListProps: { dense: true },
        }}
      >
        {resolutions.map((resolution) => (
          <MenuItem
            key={resolution.status}
            value={resolution.status}
            data-testid="comment-filterby-menuitem"
            onClick={() => {
              if (includes(newFilters.State, resolution.status)) {
                newFilters.State.splice(newFilters.State.indexOf(resolution.status), 1);
              } else {
                newFilters.State.push(resolution.status);
              }

              onChange(newFilters);
            }}
          >
            <ListItemIcon>{getCheckStatus(resolution.status)}</ListItemIcon>
            {resolution.title}
          </MenuItem>
        ))}
      </NestedMenuItem>
      <Divider />
      <NestedMenuItem
        data-testid="comment-filter-importance"
        label="Importance"
        parentMenuOpen
        MenuProps={{
          open: true,
          MenuListProps: { dense: true },
        }}
      >
        {importanceMenuRows.map((importanceMenuRow) => (
          <MenuItem
            key={importanceMenuRow.value}
            value={importanceMenuRow.value as string}
            data-testid="comment-filterby-menuitem"
            onClick={() => {
              if (includes(newFilters.Importance, importanceMenuRow.value)) {
                newFilters.Importance.splice(newFilters.Importance.indexOf(importanceMenuRow.value), 1);
              } else {
                newFilters.Importance.push(importanceMenuRow.value);
              }

              onChange(newFilters);
            }}
          >
            <ListItemIcon>{getCheckImportance(importanceMenuRow.value)}</ListItemIcon>
            {importanceMenuRow.title}
          </MenuItem>
        ))}
      </NestedMenuItem>
      {hasCustomLayerConfig && [
        <Divider key="version-divider" />,
        <NestedMenuItem
          key="comment-filter-version"
          data-testid="comment-filter-version"
          label="Version"
          parentMenuOpen
          MenuProps={{
            open: true,
            MenuListProps: { dense: true },
          }}
        >
          {versions.map((version) => (
            <MenuItem
              key={version.ID}
              value={version.ID}
              data-testid="comment-filterby-menuitem"
              onClick={() => {
                if (includes(newFilters.Version, version.ID)) {
                  newFilters.Version.splice(newFilters.Version.indexOf(version.ID), 1);
                } else {
                  newFilters.Version.push(version.ID);
                }

                onChange(newFilters);
              }}
            >
              <ListItemIcon>{getCheckVersion(version.ID)}</ListItemIcon>
              {version.Name}
            </MenuItem>
          ))}
        </NestedMenuItem>,
      ]}
    </Menu>
  );
};

export default FilterMenu;
