import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { InfoOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, LinearProgress, Typography } from "@mui/material";

import { selectCurrentRole } from "fond/api";
import { PlannerSubscription } from "fond/types";

import { Label, LinearProgressLabels, ProgressContainer } from "./usageProgress.styles";

interface IProps {
  subscription: PlannerSubscription;
}

const UsageProgress: React.FC<IProps> = ({ subscription: { Quota, QuotaUsage } }: IProps) => {
  const navigate = useNavigate();
  const isAdmin = useSelector(selectCurrentRole) === "admin";
  const progress = Math.min(Math.floor((QuotaUsage / Quota) * 100), 100);

  return (
    <>
      <Box sx={{ display: "flex", my: 2 }}>
        <InfoOutlined color="action" sx={{ mr: 0.5 }} />
        <ProgressContainer data-testid="progress">
          <LinearProgress variant="determinate" value={progress} color={progress < 100 ? "primary" : "error"} />
          <LinearProgressLabels>
            <Label data-testid="prem-usage" color="text.secondary">
              {`${QuotaUsage.toLocaleString()} of ${Quota.toLocaleString()}`}
            </Label>
            <Label data-testid="prem-percentage" color="text.secondary">{`${progress}%`}</Label>
          </LinearProgressLabels>
          <Label sx={{ mt: 1, color: (theme) => theme.palette.biarri.secondary.grey }}>Unique prems used from your current subscription.</Label>
        </ProgressContainer>
      </Box>
      {QuotaUsage >= Quota && (
        <Alert severity="error" sx={{ textAlign: "left" }} data-testid="exceeded" icon={false}>
          <AlertTitle>You have exceeded your prem quota.</AlertTitle>
          <Typography>
            The ability to generate designs has been disabled. To access this, consider upgrading your subscription or purchasing a prem bundle.
          </Typography>
          {isAdmin ? (
            <Button
              data-testid="manage-subscription-button"
              color="inherit"
              onClick={() => {
                navigate("/settings/account", { state: { tab: "subscription" } });
              }}
            >
              MANAGE SUBSCRIPTION
            </Button>
          ) : (
            <Typography>Please contact your administrator for further assistance</Typography>
          )}
        </Alert>
      )}
    </>
  );
};

export default UsageProgress;
