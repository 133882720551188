import { Box, Chip, ChipProps, styled } from "@mui/material";

export const GenericContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const HeaderBox = styled(GenericContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const UsageBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.biarri.primary.lightGrey}`,
  borderRadius: 4,
  marginBottom: theme.spacing(3),
}));

export const StatusChip = styled(Chip)(({ theme }) => ({
  height: theme.spacing(3),

  "& .MuiChip-label": {
    padding: `0 ${theme.spacing(1.25)}`,
  },
}));

export const ActiveChip: React.FC<ChipProps> = (props: ChipProps) => <StatusChip label="Active" color="success" variant="outlined" {...props} />;

export const CancelledChip: React.FC<ChipProps> = (props: ChipProps) => <StatusChip label="Cancelled" color="error" variant="outlined" {...props} />;

export const ExpiredChip: React.FC<ChipProps> = (props: ChipProps) => <StatusChip label="Expired" color="error" variant="outlined" {...props} />;

export const UnpaidChip: React.FC<ChipProps> = (props: ChipProps) => <StatusChip label="Unpaid" color="warning" variant="outlined" {...props} />;

export const UpcomingChip: React.FC<ChipProps> = (props: ChipProps) => <StatusChip label="Upcoming" color="info" variant="outlined" {...props} />;
