import MapboxStyle from "./MapboxStyle";

/**
 * In Mapbox studio (https://studio.mapbox.com/) we have two
 * "styles" set up.  One for streets, and one for satellite.
 */
export const BASE_MAP_STREETS = new MapboxStyle({ id: "ckuqljjd3bqqj17lo04a6bhgd" }); // https://studio.mapbox.com/styles/biarrinetworks/ckuqljjd3bqqj17lo04a6bhgd
export const BASE_MAP_SATELLITE = new MapboxStyle({ id: "ckuqlmqskiara17l7vv7cd495" }); // https://studio.mapbox.com/styles/biarrinetworks/ckuqlmqskiara17l7vv7cd495
export const BASE_MAP_HYBRID = new MapboxStyle({ id: "ckwono5bg0dnx14nwtg8es5n9" }); // https://studio.mapbox.com/styles/biarrinetworks/ckwono5bg0dnx14nwtg8es5n9
export const BASE_MAP_MONOCHROME = new MapboxStyle({ id: "ckvt3pae61esj14u728c5lom9" }); // https://studio.mapbox.com/styles/biarrinetworks/ckvt3pae61esj14u728c5lom9

/**
 * Internal abstract tags for the map styles a user can select from.
 *
 * 'map' corresponds to BASE_MAP_STREETS
 * 'satellite' corresponds to BASE_MAP_SATELLITE
 * 'hybrid' corresponds to BASE_MAP_HYBRID
 * 'monochrome' corresponds to BASE_MAP_MONOCHROME
 */
export const MAP_STYLES = {
  map: "map",
  satellite: "satellite",
  hybrid: "hybrid",
  monochrome: "monochrome",
};

/**
 * A list of all map style options.
 */
export const MAP_STYLES_OPTIONS = [MAP_STYLES.map, MAP_STYLES.satellite, MAP_STYLES.hybrid, MAP_STYLES.monochrome];

export const getMapStyle = (style) => {
  switch (style) {
    case MAP_STYLES.map:
      return BASE_MAP_STREETS;

    case MAP_STYLES.satellite:
      return BASE_MAP_SATELLITE;

    case MAP_STYLES.hybrid:
      return BASE_MAP_HYBRID;

    case MAP_STYLES.monochrome:
      return BASE_MAP_MONOCHROME;

    default:
      return BASE_MAP_STREETS;
  }
};

export const findMapStyleWithId = (id) => {
  switch (id) {
    case BASE_MAP_STREETS.id:
      return "map";

    case BASE_MAP_SATELLITE.id:
      return "satellite";

    case BASE_MAP_HYBRID.id:
      return "hybrid";

    case BASE_MAP_MONOCHROME.id:
      return "monochrome";

    default:
      return "map";
  }
};

// Information on Mapbox expressions: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/

export const hideIfEditing = ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1];

export const polygonStyle = {
  type: "fill",
  layout: {},
  paint: {
    "fill-color": "orange",
    "fill-opacity": 0.35,
  },
};

export const polygonErrorStyle = {
  paint: {
    "fill-color": "red",
    "fill-opacity": 0.5,
  },
};
