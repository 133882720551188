import { Box, styled } from "@mui/material";
import Color from "color";

const Swab = styled(Box, {
  shouldForwardProp: (prop) => prop !== "strokeColor",
})<{
  color: string;
  strokeColor?: string;
  opacity?: number;
  strokeOpacity?: number;
  strokeWidth?: number;
  dashed?: boolean;
}>(({ theme, color, strokeColor = "#FFF", strokeOpacity, strokeWidth = 1, opacity, dashed }) => {
  const opacityColor = opacity !== null && opacity !== undefined ? Color(color).alpha(opacity).toString() : color;
  const strokeOpacityColor: string | undefined =
    strokeOpacity !== null && strokeOpacity !== undefined ? Color(strokeColor).alpha(strokeOpacity).toString() : strokeColor;
  return {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: opacityColor,
    borderRadius: theme.shape.borderRadius,
    border: `${strokeWidth}px ${dashed ? "dashed" : "solid"} ${strokeOpacityColor || opacityColor}`,
    backgroundClip: "content-box",
  };
});

export default Swab;
