import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(isToday);

/**
 * data filter comparator
 */
const dateFilterComparator = (valueA: string, valueB: string): number => {
  if (dayjs.utc(valueA).isSame(dayjs.utc(valueB))) return 0;
  return dayjs.utc(valueA).isAfter(dayjs.utc(valueB)) ? -1 : 1;
};

export default dateFilterComparator;
