import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";

import { NavigateError } from "fond/navigation/NavigateError";
import { CognitoResponseCode } from "fond/utils";
import { SystemErrorDialog } from "fond/widgets";

const VerifyEmailForm: React.FC = () => {
  const navigate = useNavigate();
  const [cognitoResponseCode, setCognitoResponseCode] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const code = searchParams.get("code");

  useEffect(() => {
    if (email && code) {
      const lowercaseEmail = email.toLowerCase();
      Auth.confirmSignUp(lowercaseEmail, code)
        .then(() => {
          setVerificationSuccess(true);
        })
        .catch((err) => {
          if (err.code === CognitoResponseCode.EXPIRED_CODE) {
            Auth.resendSignUp(lowercaseEmail);
          }
          setCognitoResponseCode(err.code);
        });
    }
  }, [code, email]);

  // if there is no email or code in the url, the link is broken and we can't recover
  if (!(email && code)) {
    return <NavigateError errorMessage="We could not verify your email address as both the email and verification code were missing." />;
  }

  return (
    <>
      <SystemErrorDialog
        open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
        networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
        onClose={() => {
          navigate(0); // In the event of a cognito error, we need to refresh the page to attempt confirmation again.
        }}
      />

      {cognitoResponseCode === CognitoResponseCode.EXPIRED_CODE && (
        <Typography align="center">The verification link you were emailed has expired. A new link has been emailed to you.</Typography>
      )}

      {cognitoResponseCode === CognitoResponseCode.INCORRECT_CODE && (
        <>
          <Alert severity="error" sx={{ mt: 4 }}>
            <AlertTitle>Invalid code</AlertTitle>
            The email verification failed. Please request a new link & try again.
          </Alert>
          <Button color="primary" component={Link} to="/forgotpassword" sx={{ mt: 4 }}>
            Request a new password link
          </Button>
        </>
      )}

      {verificationSuccess && (
        <>
          <Alert severity="success" sx={{ mt: 4 }}>
            <AlertTitle>Email verified!</AlertTitle>
            Thanks! Your email has been verified. You can now sign in.
          </Alert>
          <Button color="primary" component={Link} to="/signin" sx={{ mt: 4 }}>
            Back to sign in
          </Button>
        </>
      )}
    </>
  );
};

export default VerifyEmailForm;
