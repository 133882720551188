import React, { useCallback, useMemo } from "react";

import { useGetMultiReportsQuery } from "fond/api";
import Architecture from "fond/architecture/Architecture";
import { ArchitecturePanel, CarvePanel, ReportPanel } from "fond/cityPlanner";
import { closeArchitectureModal, Modals, openArchitectureModal } from "fond/project/redux";
import { MultiProject } from "fond/types";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { AccordionStepper, AccordionStepperPane } from "fond/widgets";

interface PlanPanelProps {
  multiProject: MultiProject;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
}

const PlanPanel: React.FC<PlanPanelProps> = ({ multiProject, readOnly }: PlanPanelProps) => {
  const dispatch = useAppDispatch();
  const { data: multiReports } = useGetMultiReportsQuery(multiProject.ID);

  const modal = useAppSelector((state) => state.project.modal);

  const handleOnArchitectureModalClose = () => dispatch(closeArchitectureModal());
  const handleOnChangeArchitectureClick = useCallback(() => dispatch(openArchitectureModal()), [dispatch]);

  const accordionPanes: AccordionStepperPane[] = useMemo(
    () => [
      {
        header: "Architecture",
        id: "architecture",
        content: (
          <div style={{ textAlign: "left" }}>
            <ArchitecturePanel multiProject={multiProject} readOnly={readOnly} onChangeClick={handleOnChangeArchitectureClick} />
          </div>
        ),
        isComplete: multiProject.Architecture !== null,
      },
      {
        header: "Planner",
        id: "subareas",
        content: <CarvePanel multiProject={multiProject} />,
        isComplete: multiProject.Areas.some(({ Project }) => Project),
      },
      {
        header: "Reports",
        id: "reports",
        content: <ReportPanel multiProject={multiProject} multiReports={multiReports} />,
        isComplete: !!multiReports && multiReports.length > 0,
      },
    ],
    [handleOnChangeArchitectureClick, multiProject, multiReports, readOnly]
  );

  return (
    <>
      <AccordionStepper id={`${multiProject.ID}-plan`} panes={accordionPanes} autoMove={["architecture"]} variant="outlined" />
      {modal === Modals.architecture && (
        <Architecture onClose={() => handleOnArchitectureModalClose()} project={multiProject} architecture={multiProject.Architecture} />
      )}
    </>
  );
};

export default PlanPanel;
