import { Middleware } from "redux";

import { ProjectAction } from "fond/project/redux";
import { SET_SORT_ORDER } from "fond/redux/comments";
import { Store } from "fond/types";
import localStorage from "fond/utils/localStorage";

/**
 * Middleware what allows us to save portions of the redux store to
 * the Windows localStorage.  This can then be used to rehydrate state
 * on initial load.
 */
export const localStorageMiddleware: Middleware<any, Store> = (storeApi) => (next) => (action) => {
  const result = next(action);
  const state = storeApi.getState();

  // Save the Project Layer visibility state
  if (
    action.type === ProjectAction.SET_LAYER_VISIBILITY ||
    action.type === ProjectAction.SET_LAYERS_VISIBILITY ||
    action.type === ProjectAction.TOGGLE_LAYER_VISIBILITY ||
    action.type === ProjectAction.UPDATE_PROJECT_VIEW
  ) {
    const { projectId } = action;
    const { view } = state.project.projects[projectId];
    localStorage.setItem(`state.project.projects[${projectId}].view`, view);
  }

  // Save the Comment sort order
  if (action.type === SET_SORT_ORDER) {
    const { sortOrder } = action;
    localStorage.setItem(`state.comments.sortOrder`, sortOrder);
  }

  return result;
};
