import { apiSlice } from "./apiSlice";

/**
 * BOM API Slice
 */
export const bomSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBomTemplateCategories: build.query({
      query: () => `/v2/bom-template-categories`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetBomTemplateCategoriesQuery } = bomSlice;
