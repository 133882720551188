import React from "react";
import { AnyObject, Form } from "react-final-form";
import { Colorize, FiberManualRecord, LinearScale, PanoramaWideAngle, Star, TextFields } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { ListField, TextField } from "fond/form/fields";
import { FondStyleType } from "fond/types";
import { required } from "fond/utils/validation";
import { Modal } from "fond/widgets";

export interface IFormData {
  name: string;
  type: FondStyleType;
}

interface IProps {
  onClose(): void;
  onSubmit(values: IFormData): void;
}

const NewStyle: React.FC<IProps> = ({ onClose, onSubmit }: IProps) => {
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  const handleOnCancel = () => {
    onClose();
  };
  const handleOnSubmit = () => {
    submit();
  };

  const listOptions = [
    {
      value: "line",
      label: "Line",
      description: "A stroked line.",
      icon: <LinearScale sx={{ transform: "rotate(90deg)" }} color="primary" />,
    },
    {
      value: "fill",
      label: "Fill",
      description: "A filled polygon with an optional stroked border.",
      icon: <PanoramaWideAngle color="primary" />,
    },
    {
      value: "symbol",
      label: "Symbol",
      description: "An icon or a text label.",
      icon: <TextFields color="primary" />,
    },
    {
      value: "icon",
      label: "Icon",
      description: "An icon.",
      icon: <Star color="primary" />,
    },
    {
      value: "circle",
      label: "Circle",
      description: "A filled circle.",
      icon: <FiberManualRecord color="primary" />,
    },
  ];

  return (
    <Modal
      open
      headerIcon={<Colorize />}
      header="Add a style"
      data-testid="new-style-modal"
      content={
        <Box data-testid="new-style-tab">
          <Form<IFormData>
            initialValues={{
              type: "line",
              name: "Style",
            }}
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Box>
                      <TextField name="name" required label="Name" fullWidth validate={required} placeholder="Enter the style name..." />
                    </Box>
                    <Box data-testid="new-style-items">
                      <ListField name="type" options={listOptions} />
                    </Box>
                  </Box>
                </form>
              );
            }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="new-style-cancel-button" color="primary" onClick={handleOnCancel} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="new-style-save-button" color="primary" type="submit" onClick={handleOnSubmit}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default NewStyle;
