import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import Share from "fond/share/Share";
import { Project, Report } from "fond/types";

interface IProps {
  entity: Project | Report;
}

const ShareButton: React.FC<IProps> = ({ entity }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  return (
    <Box>
      <Button data-testid="top-bar-share-button" size="small" variant="contained" color="primary" onClick={() => setShowShareModal(true)}>
        Share
      </Button>
      {showShareModal && <Share resource={entity} onClose={() => setShowShareModal(false)} />}
    </Box>
  );
};

export default ShareButton;
