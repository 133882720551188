import { Box, Link as MuiLink, styled } from "@mui/material";

export const Link = styled(MuiLink)(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

export const MimeTypeIconWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
`;
