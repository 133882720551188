import { ListSubheader as BaseListSubheader, Paper as BasePaper, styled, TextField as BaseTextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

export const Paper = styled(BasePaper)`
  width: 90%;
  display: flex;
  margin: 7px auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & .MuiAutocomplete-listbox {
    width: 100%;
    padding: 0;
    max-height: unset;
  }
` as typeof BasePaper;

export const TextField = styled(BaseTextField)(
  ({ theme }) => `
    width: 490px;
    height: 35px;
    background: ${theme.palette.common.white};
    border-radius: 20px;
    border: 0;

    & > .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
      border: 0;
      padding: 0 20px;
      font-size: 0.875rem;
    }
    & > .Mui-focused fieldset,  {
      border: 0;
    }
    & .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 0;
    }

    & > .MuiOutlinedInput-root:hover {
      outline: 0;
      & fieldset {
        border: 0;
      }
      &:hover fieldset {
        border: 0;
      }
    }

    & input {
      height: 35px;
      line-height: 35px;
      padding: 0;
      padding-left: ${theme.spacing(1.5)};
      outline: 0;
    }

    & button.MuiIconButton-root {
      padding: 0;
    }
  `
);

export const ListSubheader = styled(BaseListSubheader)`
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1.15px;
  line-height: 1.5;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(0.5)}`};
  &:first-of-type {
    padding-top: 12.5px;
  }
` as typeof BaseListSubheader;

export const ListItemButton = styled("li")`
  &.MuiAutocomplete-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 7px 16px;

    &.Mui-focused {
      background-color: #f5f5f5;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
  & svg {
    height: 20px;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
  & > span {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const RowName = styled(Typography)`
  font-size: 0.875rem;
`;

export const SeeAllResultsButton = styled(ListItemButton)`
  &.MuiAutocomplete-option {
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    padding: 0.5rem;
    color: ${blue[600]};
  }
`;
