import React from "react";
import { Alert, AlertTitle, Button, Snackbar, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    boxShadow: theme.shadows[1],
  },
}));

interface IProps {
  /**
   * The severity of the snack bar.
   */
  severity?: "info" | "error";

  /**
   * Icon, only required when it is an info snackbar.
   */
  icon?: JSX.Element;

  /**
   * Alert title, only required when it is an info snackbar.
   */
  title?: string;

  /**
   * Alert content, only required when it is an info snackbar.
   */
  content?: string;

  /**
   * The onClick action.
   */
  onClick?: () => void;
}

const SnackbarContent: React.FC<IProps> = ({ severity = "info", icon, title = "", content = "", onClick }: IProps) => {
  const classes = useStyles();

  return (
    <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
      <Alert
        severity={severity}
        icon={icon}
        action={
          <Button color="inherit" size="small" onClick={onClick}>
            Cancel
          </Button>
        }
      >
        {!!title && <AlertTitle>{title}</AlertTitle>}
        {content}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarContent;
