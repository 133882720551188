import { Architecture, BomCategories, DemandTier, SystemsOfMeasurement, Tier1HubType, Tier2CableType } from "fond/types";

import { rules } from "./rules";

type Rule = {
  condition: (val: any, arch: any, state: Architecture, systemOfMeasurement: SystemsOfMeasurement) => boolean;
  state: string;
  message: string;
};

/**
 * Convenience function to say that `rule` only applies if `func(state)`
 * returns true.
 */
export const applyRuleIf = (func: (arch: any, state: Architecture) => boolean, rule: Rule): any => {
  return {
    ...rule,
    condition: (val: any, arch: any, state: Architecture, systemOfMeasurement: SystemsOfMeasurement) => {
      return func(arch, state) && rule.condition(val, arch, state, systemOfMeasurement);
    },
  };
};

/**
 * Apply all the rules in the `rules` array conditionally (see `applyRuleIf`).
 */
export const applyRulesIf = (func: (arch: any, state: Architecture) => boolean, rulesToApply: Rule[]): any => {
  return rulesToApply.map((rule: any) => applyRuleIf(func, rule));
};

export const isUsingConnectorizedDropTerminals = (state: Architecture): boolean => {
  return state.Tier2.ConnectorizedDropTerminals.Enabled;
};

const isThreeTier = (state: Architecture): boolean => {
  return state.NumberOfTiers === 3;
};

/**
 * fieldRules maps the shape of a UI arch to Rules objects.
 */
export const fieldRules = {
  Name: [
    {
      condition: (val: string, state: Architecture): boolean => val === "" && state.HasAttemptedSave,
      state: "error",
      message: "Please enter an architecture name",
    },
  ],
  NumberOfTiers: [
    {
      // Validate based on any distribution tiers set in Demand Model (if set)
      condition: (val: number, state: Architecture): boolean =>
        state.NumberOfTiers === 2 && Boolean(state.Demand?.DemandConfigurations.some((row) => row.Tier === DemandTier.FEEDER)),
      state: "error",
      message: "Cannot be set to 2 while the Demand Model references the 'Feeder' tier type.",
    },
  ],
  Tier1: {
    Cables: {
      Sizes: [
        ...rules.nonEmptyList("Please enter at least one cable size"),
        {
          condition: (val: number[], state: Architecture): boolean => {
            if (state.Tier1.Cables.ExpandAddresses === false) {
              return Math.max(...val) < Math.max(...state.Tier1.Hubs.Sizes);
            }
            return false;
          },
          state: "error",
          message: "At least one cable size must be greater than (or equal to) the largest hub port count.",
        },
      ],
    },
    Hubs: {
      Spare: rules.spare((state: Architecture) => state.Tier1.Hubs.Sizes, "port count"),
      Sizes: [
        ...rules.nonEmptyList("Please enter at least one port count"),
        {
          condition: (val: number[]): boolean => val.some((v) => v > 32),
          state: "error",
          message: "Drop hub sizes must not be greater than 32",
        },
        {
          condition: (val: number[], state: Architecture): boolean => val.every((v) => v < state.Tier1.Hubs.SplitRatio),
          state: "error",
          message: "Drop hub sizes cannot be less than the split ratio.",
        },
        {
          condition: (val: number[], state: Architecture): boolean => val.some((v) => v % state.Tier1.Hubs.SplitRatio !== 0),
          state: "error",
          message: "All drop hub sizes must be divisible by the split ratio.",
        },
        {
          condition: (val: number[]): boolean => val.some((v) => v > 12),
          state: "warning",
          message: "Drop hub sizes greater than 12 may greatly increase the time taken to generate a design",
        },
        {
          condition: (val: number[], state: Architecture): boolean =>
            val.some((v) => ![4, 8, 12].includes(v)) && state.Tier2.Cables.Type === Tier2CableType.Rpx,
          state: "error",
          message: "Hub sizes must be a subset of 4, 8, 12 to use the RPX distribution cable type.",
        },
      ],
      Type: [
        {
          condition: (val: string, state: Architecture): boolean => val === Tier1HubType.LongSpanEvolv && state.Tier1.Hubs.AllowDirectHomeConnect,
          state: "error",
          message: "Long Span Evolv cannot be used with Direct Home Connect.",
        },
        {
          condition: (val: string, state: Architecture): boolean =>
            val === Tier1HubType.LongSpanEvolv && ![Tier2CableType.Rpx, Tier2CableType.Figure8].includes(state.Tier2.Cables.Type),
          state: "error",
          message: "Long Span Evolv can only be used the RPX and Figure 8 distribution cable types.",
        },
      ],
      AllowDirectHomeConnect: [
        {
          condition: (val: boolean, state: Architecture): boolean => val && state.Tier1.Hubs.Type === Tier1HubType.LongSpanEvolv,
          state: "error",
          message: "Direct Home Connect cannot be used with the Long Span Evolv drop hub type.",
        },
        {
          condition: (val: boolean, state: Architecture): boolean => val && state.Tier2.Cables.Type === Tier2CableType.Rpx,
          state: "error",
          message: "Direct Home Connect cannot be used with the RPX distribution cable type.",
        },
      ],
    },
    DropRules: {
      DropLength: [
        ...rules.nonNegativeNumber,
        ...rules.maxLength({
          maxFeet: 2000,
          maxMeters: 600,
          state: "error",
          messageFunc: (length: number) => `Values greater than ${length} are not allowed`,
        }),
        ...rules.maxLength({
          maxFeet: 1000,
          maxMeters: 300,
          state: "warning",
          messageFunc: (length: number) =>
            `Values greater than ${length} may greatly increase ` +
            "the time taken to generate a design, especially if the area is densely populated",
        }),
      ],
      PolesPerDrop: rules.nonNegativeInteger,
      PitsPerDrop: rules.nonNegativeInteger,
    },
  },
  Tier2: {
    ConnectorizedDropTerminals: {
      MaxTailLength: applyRulesIf(isUsingConnectorizedDropTerminals, [
        ...rules.nonNegativeNumber,
        ...rules.maxLength({
          maxFeet: 2000,
          maxMeters: 600,
          state: "warning",
          messageFunc: (length: number) => `Tail lengths over ${length} may greatly increase the time taken to generate a design`,
        }),
      ]),
      TailsPerSplice: applyRulesIf(isUsingConnectorizedDropTerminals, [
        ...rules.positiveInteger,
        {
          condition: (val: number) => val > 12,
          state: "error",
          message: "Designs with more than 12 tails to a splice can be very complex and so are restricted",
        },
      ]),
      TailCableSizes: applyRulesIf(isUsingConnectorizedDropTerminals, rules.nonEmptyList("Please enter at least one cable size")),
    },
    Cables: {
      Sizes: [
        ...rules.nonEmptyList("Please enter at least one cable size"),
        {
          condition: (val: number[], state: Architecture): boolean =>
            val.some((v) => ![24, 48, 72, 96, 144].includes(v)) && state.Tier2.Cables.Type === Tier2CableType.Rpx,
          state: "error",
          message: "Cable sizes must be a subset of 24, 48, 72, 96, and 144 to use the RPX distribution cable type.",
        },
      ],
      Spare: rules.spare((state: Architecture) => state.Tier2.Cables.Sizes, "cable size"),
      Type: [
        {
          condition: (val: Tier2CableType, state: Architecture): boolean =>
            ![Tier2CableType.Rpx, Tier2CableType.Figure8].includes(val) && state.Tier1.Hubs.Type === Tier1HubType.LongSpanEvolv,
          state: "error",
          message: "Only RPX and Figure 8 can be used with the Long Span Evolv drop hub type.",
        },
        {
          condition: (val: Tier2CableType, state: Architecture): boolean => val === Tier2CableType.Rpx && state.Tier1.Hubs.AllowDirectHomeConnect,
          state: "error",
          message: "RPX cannot be used with Direct Home Connect",
        },
      ],
    },
    Hubs: {
      Sizes: rules.nonEmptyList("Please enter at least one port count"),
      Spare: rules.spare((state: Architecture) => state.Tier2.Hubs.Sizes, "port count"),
      UnsplitPorts: [
        ...rules.nonNegativeInteger,
        {
          // Validate based on any distribution tiers set in Demand Model (if set)
          condition: (val: number, state: Architecture): boolean =>
            Boolean(state.Demand?.DemandConfigurations.some((row) => row.Tier === DemandTier.DISTRIBUTION_BYPASS_SPLITTERS && row.NumFibers > val)),
          state: "error",
          message:
            "Value must be no less than the maximum NumFibers value set within the Demand Model for Tier type 'Distribution bypass splitters'.",
        },
      ],
    },
    Topology: {
      ParallelCableThreshold: rules.nonNegativeNumber,
      LoopingCableThreshold: rules.nonNegativeNumber,
    },
  },
  Tier3: {
    Cables: {
      Sizes: rules.nonEmptyList("Please enter at least one cable size"),
      Spare: rules.spare((state: Architecture) => state.Tier3.Cables.Sizes, "cable size"),
    },
    Hubs: {
      Sizes: rules.nonEmptyList("Please enter at least one demand count"),
    },
    Topology: {
      ParallelCableThreshold: applyRulesIf(isThreeTier, rules.nonNegativeNumber),
      LoopingCableThreshold: applyRulesIf(isThreeTier, rules.nonNegativeNumber),
    },
  },
  BOM: [
    {
      condition: (bom: { Categories: BomCategories[] }): any => {
        if (bom != null) {
          for (let category of bom.Categories || []) {
            for (let row of category.Rows) {
              if (!(Number(row.Cost) >= 0)) {
                return true;
              }
            }
          }
        }
        return false;
      },
      state: "error",
      message: "Must be a non-negative number",
    },
  ],
};
