import MapboxDraw, { DrawCustomMode } from "@mapbox/mapbox-gl-draw";

const {
  modes,
  lib: {
    CommonSelectors: { isFeature, isShiftMousedown },
  },
} = MapboxDraw;

const simpleSelect: DrawCustomMode = {
  ...modes.simple_select,

  /**
   * We customise the onMouseDown event to allow the user to drag an unselected
   * Point Feature without first selecting it.
   */
  onMouseDown: function (state, event) {
    state.initialDragPanState = this.map.dragPan.isEnabled();
    if (isFeature(event)) {
      const featureId = event.featureTarget.properties?.id;
      const { type } = this.getFeature(featureId);

      if (type !== "Point" || isShiftMousedown(event)) {
        // For all other feature types we default to the original onMouseDown
        return modes.simple_select.onMouseDown?.call(this, state, event);
      }
      this.clickOnFeature?.(state, event);
      return this.startOnActiveFeature?.(state, event);
    }
    if (this.drawConfig.boxSelect && isShiftMousedown(event)) return this.startBoxSelect?.(state, event);
    return undefined;
  },
};

export default simpleSelect;
