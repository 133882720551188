import React, { useCallback, useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const DeleteButton: React.FC<IButtonProps> = () => {
  const { map, drawControl } = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Monitor when the selectionchange event occurs so that we can disabled
   * this feature based on the number of selected features.
   */
  const handleOnSelect = useCallback(() => {
    setIsDisabled((drawControl.current.getSelectedIds().length || 0) === 0);
  }, [drawControl]);

  useEffect(() => {
    map?.on("draw.selectionchange", handleOnSelect);

    return () => {
      map?.off("draw.selectionchange", handleOnSelect);
    };
  }, [map, handleOnSelect]);

  const handleOnClick = () => {
    if (drawControl.current.getSelectedPoints().features.length > 0) {
      drawControl.current.trash();
    } else {
      drawControl.current.delete(drawControl.current.getSelectedIds());
    }

    map?.fire("draw.delete");
    map?.fire("draw.selectionchange");
  };

  return (
    <ToolbarButton title="Delete Feature" onClick={handleOnClick} disabled={isDisabled}>
      <DeleteIcon />
    </ToolbarButton>
  );
};

export default DeleteButton;
