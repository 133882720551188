import React from "react";

interface IProps {
  base64String: string | undefined;
  width: string; // eg width="12"
  height: string; // eg height="12"
  className?: string;
}

const Image: React.FC<IProps> = ({ base64String, className, width, height }: IProps) => {
  if (base64String == null) {
    // if the base64String hasn't loaded for some reason (null or undefined), just display a white space.
    return <div />;
  }
  return <img src={`data:image/png;base64, ${base64String}`} width={width} height={height} alt="" className={className} data-testid="image-icons" />;
};

export default Image;
