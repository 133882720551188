import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

import { StyledSubtitle, StyledTitle } from "../common.styles";
import { ChartSection, TotalPremsBanner } from "./insightsChart.styles";

interface InsightsChartProps {
  /**
   * The title text typography.
   */
  title: string;
  /**
   * The subtitle text typography.
   */
  subtitle: string;
  /**
   * The content of the chart.
   */
  content?: ReactNode;
  totalUsage: number;
}

const InsightsChart: React.FC<InsightsChartProps> = ({ title, subtitle, totalUsage, content = null }: InsightsChartProps) => {
  return (
    <ChartSection data-testid="account-total-usage-chart">
      <StyledTitle>{title}</StyledTitle>
      <StyledSubtitle variant="body1">{subtitle}</StyledSubtitle>
      <TotalPremsBanner variant="outlined">
        <Typography variant="h3" component="p" fontWeight={700} data-testid="usage-count-total">
          {totalUsage.toLocaleString()}
        </Typography>
        <Typography variant="h7" component="span" data-testid="usage-count-subheader">
          Total premises used
        </Typography>
      </TotalPremsBanner>
      {content}
    </ChartSection>
  );
};

export default InsightsChart;
