import { useState } from "react";
import * as React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, MenuProps, Typography } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { Folder } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { replaceAllSpecialCharacters } from "fond/utils/strings";

import DeleteCityPlannerModal from "../CityPlanner/DeleteCityPlannerModal";
import EditCityPlannerModal from "../CityPlanner/EditCityPlannerModal";

import StarMenuItem from "./StarMenuItem";

interface CityPlannerRowMenuProps extends Pick<MenuProps, "anchorEl"> {
  /**
   * The city planner folder the menu actions relate to
   */
  cityPlannerFolder: Folder;
  onMenuClose(event: React.MouseEvent<EventTarget>): void;
  open?: boolean;
}

type FolderModal = "edit" | "delete" | "share";

const CityPlannerRowMenu: React.FC<CityPlannerRowMenuProps> = ({
  cityPlannerFolder,
  onMenuClose,
  anchorEl,
  open = true,
}: CityPlannerRowMenuProps) => {
  const [showModal, setShowModal] = useState<FolderModal>();
  const canEditCityPlannerFolder = usePermissionCheck(Actions.CITY_PLANNER_EDIT, cityPlannerFolder?.Permission.Level);
  const canDeleteCityPlannerFolder = usePermissionCheck(Actions.FOLDER_DELETE, cityPlannerFolder?.Permission.Level);

  if (!cityPlannerFolder) return null;

  /**
   * Generic Callback function that updates any of the modal open states and closes the menu
   */
  const openModal = (modalType: FolderModal) => (event: React.MouseEvent<EventTarget>) => {
    onMenuClose(event);
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(undefined);
  };

  return (
    <Menu
      data-testid={`project-list-item-menu-${replaceAllSpecialCharacters(cityPlannerFolder.Name, "")}`}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl && open}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <MenuItem aria-label="Edit Folder" onClick={openModal("edit")} data-testid="edit-folder-button" disabled={!canEditCityPlannerFolder}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <Typography variant="inherit">Edit</Typography>
      </MenuItem>

      {/* 
      
      // TODO - Future ticket

      <MenuItem
        aria-label="Share City Planner"
        onClick={openModal("share")}
        data-testid="share-folder-button"
        disabled={!permissionCheck(cityPlannerFolder.Permission.Level, Actions.FOLDER_SHARE_VIEW)}
      >
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <Typography variant="inherit">Share</Typography>
      </MenuItem> */}

      <MenuItem
        aria-label="Delete City Planner project"
        onClick={openModal("delete")}
        data-testid="delete-city-planner-button"
        disabled={!canDeleteCityPlannerFolder}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <Typography variant="inherit">Delete</Typography>
      </MenuItem>
      <StarMenuItem onMenuClose={onMenuClose} resource={cityPlannerFolder} />
      {showModal === "edit" && <EditCityPlannerModal onClose={closeModal} cityPlannerFolder={cityPlannerFolder} />}
      {showModal === "delete" && <DeleteCityPlannerModal onClose={closeModal} cityPlannerFolder={cityPlannerFolder} />}
      {/* 
        // TODO - Future ticket
        {showModal === "share" && <Share resource={cityPlannerFolder} onClose={closeModal} />} 
      */}
    </Menu>
  );
};

export default CityPlannerRowMenu;
