import { Button as MuiButton, styled } from "@mui/material";

export const Button = styled(MuiButton)(({ theme }) => ({
  justifyContent: "flex-start",
  fontSize: 12,
  lineHeight: "12px",
  color: theme.palette.text.primary,
  "&:not(.Mui-disabled) svg": {
    color: theme.palette.primary.main,
  },
}));

Button.defaultProps = {
  size: "small",
  fullWidth: true,
};
