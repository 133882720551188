import { LayerFilterOption } from "fond/map/Legend/Adornments/LayerFilter";
import { ConfigurationUpsert, MultiProjectArea } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";
import { layerPalette } from "fond/utils/colors";

import { scoringPalette } from "../helper";

import { FamilialLayerConfig, groupConfigTemplate, layerConfigTemplate, sublayerTemplate } from "./configuration";

export enum SubareaLayerId {
  ROOT = "multiProjectAreaGroup",
  BY_NAME = "multiProjectAreaNameLayer",
  BY_SCORE = "multiProjectAreaScoreLayer",
  BOUNDARY = "multiProjectAreaBoundaryLayer",
}

export const defaultSubareaExcludedLayerIds = [SubareaLayerId.BY_NAME, SubareaLayerId.BOUNDARY];

export const subareaFilterOptions: LayerFilterOption[] = [
  { ID: "categorizeBy", Label: "Categorize by", Type: "Category" },
  { ID: SubareaLayerId.BOUNDARY, Label: "Uncategorized", Type: "Option" },
  { ID: SubareaLayerId.BY_SCORE, Label: "Score", Type: "Option" },
  { ID: SubareaLayerId.BY_NAME, Label: "Name", Type: "Option" },
];

const key = "";

/**
 * Create the Subarea configuration.
 */
export const subareaConfiguration = (areas: MultiProjectArea[]): ConfigurationUpsert => {
  const { config: byScoreLayerConfig, descendants: byScoreDescendants } = createByScoreLayer();
  const { config: byNameLayerConfig, descendants: byNameDescendants } = createByNameLayer(areas);
  const { config: boundaryConfig, descendants: boundaryDescendants } = createBoundaryLayer();

  // Assemble everything together into one group.
  const layerConfigs = [byNameLayerConfig, byScoreLayerConfig, boundaryConfig];
  const descendants = [...byNameDescendants, ...byScoreDescendants, ...boundaryDescendants];

  // Create the groups
  const overlayGroup = groupConfigTemplate(SubareaLayerId.ROOT, layerConfigs, "Subareas", true);

  // Assemble everything together
  return [...descendants, ...layerConfigs, overlayGroup];
};

/**
 * Creates a layer with sublayers based on score ranges.
 *
 * - Subareas (categorized by score)
 *   - [0 - 20]
 *   - [20 - 40]
 *   - [40 - 60]
 *   - [60 - 80]
 *   - [80 - 100]
 */
const createByScoreLayer = (): FamilialLayerConfig => {
  // Use the buckets to make sublayers for the sublayers
  const descendants = byScoreBuckets.flatMap(({ ID, Color, Filter, Label }) => {
    const styles = layerStyles(ID, Color, Filter, 0.3);
    const label = labelTemplate(`${ID}-label`, Filter);
    const sublayerConfig = sublayerTemplate(ID, key, SubareaLayerId.BY_SCORE, Label, Filter, [label, ...styles]);
    return [label, ...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    SubareaLayerId.BY_SCORE,
    key,
    "Subareas (categorized by score)",
    [],
    pickIDs(byScoreBuckets),
    SubareaLayerId.ROOT
  );

  return { config, descendants };
};

/**
 * Creates a layer with sublayers based on names.
 *
 * - Subareas (categorized by name)
 *   - Name 1
 *   - Name 2
 *   - Name 3
 *
 * @params {areas} The subares within the project.
 */
const createByNameLayer = (areas: MultiProjectArea[]): FamilialLayerConfig => {
  // Use the areas to make sublayers for the sublayers
  const sublayerIds: string[] = [];
  const descendants = areas.flatMap(({ ID, Name }, index) => {
    const color = layerPalette[index % layerPalette.length];
    const sublayerId = `subarea-by-name-${ID}`;
    const filter: FilterConfiguration = { Mapbox: ["==", ["get", "boundaryId"], ID], Type: "expression" };
    const styles = layerStyles(sublayerId, color, filter, 0.3);
    const label = labelTemplate(`${sublayerId}-label`, filter);
    const sublayerConfig = sublayerTemplate(sublayerId, key, SubareaLayerId.BY_NAME, Name, filter, [label, ...styles]);
    sublayerIds.push(sublayerId);
    return [label, ...styles, label, sublayerConfig];
  });

  const config = layerConfigTemplate(SubareaLayerId.BY_NAME, key, "Subareas (categorized by name)", [], sublayerIds, SubareaLayerId.ROOT);

  return { config, descendants };
};

/**
 * Creates a layer without categorization.
 *
 * - Subareas (uncategorized)
 */
const createBoundaryLayer = (): FamilialLayerConfig => {
  const descendants = [labelTemplate(SubareaLayerId.BOUNDARY), ...layerStyles(SubareaLayerId.BOUNDARY, "grey", undefined, 0.1)];
  const config = layerConfigTemplate(SubareaLayerId.BOUNDARY, key, "Subareas (uncategorized)", descendants, [], SubareaLayerId.ROOT);

  return { config, descendants };
};

const layerStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity !== undefined ? opacity : 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-stroke`,
    Name: `${layerId}-polygon-stroke`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "line-width": 1,
        "line-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1],
        "line-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", "black"],
      },
    },
    RawStyles: {
      Type: "line",
      LineOpacity: 1,
      LineColor: "black",
      LineWidth: 2,
    },
    Type: "STYLE",
  },
];

const labelTemplate = (layerId: string, filter?: FilterConfiguration): LayerStyle => ({
  ID: `${layerId}-polygon-label`,
  Name: `${layerId}-polygon-label`,
  GlobalPosition: 2,
  ConfigurationID: layerId,
  ConfigurationType: "LAYER",
  Position: 0,
  MapboxStyle: {
    ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
    type: "symbol",
    layout: {
      "text-field": ["get", "name"],
      "text-size": 12,
    },
    paint: {
      "text-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1],
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  },
  RawStyles: {},
  Type: "STYLE",
});

const byScoreBuckets: { ID: string; Label: string; Color: string; Filter: FilterConfiguration }[] = [
  {
    ID: "subarea-by-score-0-20",
    Label: "0 - 20",
    Color: scoringPalette[0],
    Filter: {
      Mapbox: ["all", [">=", ["get", "score"], 0], ["<=", ["get", "score"], 20]],
      Type: "group",
    },
  },
  {
    ID: "subarea-by-score-20-40",
    Label: "20 - 40",
    Color: scoringPalette[20],
    Filter: {
      Mapbox: ["all", [">", ["get", "score"], 20], ["<=", ["get", "score"], 40]],
      Type: "group",
    },
  },
  {
    ID: "subarea-by-score-40-60",
    Label: "40 - 60",
    Color: scoringPalette[40],
    Filter: {
      Mapbox: ["all", [">", ["get", "score"], 40], ["<=", ["get", "score"], 60]],
      Type: "group",
    },
  },
  {
    ID: "subarea-by-score-60-80",
    Label: "60 - 80",
    Color: scoringPalette[60],
    Filter: {
      Mapbox: ["all", [">", ["get", "score"], 60], ["<=", ["get", "score"], 80]],
      Type: "group",
    },
  },
  {
    ID: "subarea-by-score-80-100",
    Label: "80 - 100",
    Color: scoringPalette[100],
    Filter: {
      Mapbox: [">", ["get", "score"], 80],
      Type: "expression",
    },
  },
  {
    ID: "subarea-by-score-none",
    Label: "No score",
    Color: scoringPalette.NA,
    Filter: {
      Mapbox: ["!", ["any", ["has", "score"]]],
      Type: "expression",
    },
  },
];
