import MapboxDraw, { DrawCustomMode, DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";

const { constants: Constants, modes } = MapboxDraw;

/*
 * A modified draw_point mode that stays in draw_point when a point is created,
 * rather than moving back to simple_select and requiring the user to re-enter
 * draw point mode to create the next point.
 */
function addPoint(this: DrawCustomModeThis, state: unknown, e: MapboxDraw.MapMouseEvent | MapboxDraw.MapTouchEvent): void {
  const point = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    },
  });

  this.addFeature(point);
  this.map.fire("draw.create", { features: [point.toGeoJSON()] });
}

const drawPoint: DrawCustomMode = {
  ...modes.draw_point,

  onClick: function (this, state, e) {
    addPoint.call(this, state, e);
  },

  onTap: function (this, state, e) {
    addPoint.call(this, state, e);
  },
};

export default drawPoint;
