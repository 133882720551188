/**
 * Refers to a Mapbox style in the biarrinetworks account..
 * The main purpose of this is to separate a stylesheet id (like "cj3l1uvo500092roh3ads10c8")
 * from other details of the style like whether it's in draft mode or not.
 * We set the current style of a Mapbox map by passing the full URL of the
 * style to the Mapbox `setStyle` function, but when we query the current style from the map
 * we only get its ID.
 */
export default class MapboxStyle {
  id: string;

  /**
   * Whether to use draft mode or not. Draft mode uses less (no?) caching, so
   * updates from Mapbox Studio are reflected more immediately, but use of draft
   * mode in production is encouraged for performance reasons.
   */
  draft: boolean;

  constructor({ id, draft = false }: { id: string; draft: boolean }) {
    this.id = id;
    this.draft = draft;
  }

  get url(): string {
    let url = `mapbox://styles/biarrinetworks/${this.id}`;
    if (this.draft) {
      url += "/draft";
    }
    return url;
  }
}
