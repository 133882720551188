import { apiSlice } from "fond/api/apiSlice";
import { Configuration, Optional, Style } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { draftConfigEntityAdapter, draftSlice, transformStylePayload } from "./draftSlice";
import { transformStyleResponse } from "./versionsSlice";

/**
 * Styles API Slice
 */
export const styleSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createStyleConfig: build.mutation<LayerStyle, { mapLayerConfigId: string; newStyleConfig: Optional<LayerStyle, "MapboxStyle"> }>({
      query: ({ newStyleConfig }) => ({
        url: "/v2/style-configurations",
        method: "POST",
        body: transformStylePayload(newStyleConfig),
      }),
      transformResponse: (response: Style) => transformStyleResponse(response),
      onQueryStarted: async ({ mapLayerConfigId }, { dispatch, queryFulfilled }) => {
        const { data: newStyleConfig } = await queryFulfilled;
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            // Insert the new style into the collection & the draft cache
            if (draft.Data) {
              draftConfigEntityAdapter.upsertOne(draft.Data, newStyleConfig);

              const parent = { ...draft.Data.entities[newStyleConfig.ConfigurationID] } as LayerConfig | SublayerConfig;
              if (parent) {
                draftConfigEntityAdapter.upsertOne(draft.Data, {
                  ...parent,
                  Styles: [...parent.Styles, newStyleConfig.ID],
                });
              }
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateStyleConfig: build.mutation<LayerStyle, { mapLayerConfigId: string; style: Optional<LayerStyle, "MapboxStyle"> }>({
      query: ({ style }) => ({
        url: `/v2/style-configurations/${style.ID}`,
        method: "PUT",
        body: transformStylePayload(style),
      }),
      transformResponse: (response: Style) => transformStyleResponse(response),
      onQueryStarted: async ({ mapLayerConfigId }, { dispatch, queryFulfilled }) => {
        const { data: newStyleConfig } = await queryFulfilled;
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            // Update the style within the collection & the draft cache
            if (draft.Data) {
              draftConfigEntityAdapter.upsertOne(draft.Data, newStyleConfig);
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteStyleConfig: build.mutation<any, { mapLayerConfigId: string; style: LayerStyle }>({
      query: ({ style }) => ({
        url: `/v2/style-configurations/${style.ID}`,
        method: "DELETE",
      }),
      onQueryStarted: async ({ mapLayerConfigId, style }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            // Delete the style from the collection & the draft cache
            if (draft.Data) {
              draftConfigEntityAdapter.removeOne(draft.Data, style.ID);

              const parent = { ...draft.Data.entities[style.ConfigurationID] } as LayerConfig | SublayerConfig;
              if (parent) {
                draftConfigEntityAdapter.upsertOne(draft.Data, {
                  ...parent,
                  Styles: [...parent.Styles.filter((id: string) => id !== style.ID)],
                });
              }
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useCreateStyleConfigMutation, useDeleteStyleConfigMutation, useUpdateStyleConfigMutation } = styleSlice;
