import React from "react";
import { Colorize } from "@mui/icons-material";
import { Button } from "@mui/material";

import { FondStyleType } from "fond/types";
import { Modal } from "fond/widgets";

import StyleSettings from "./StyleSettings";

interface IProps {
  type: FondStyleType;
  onClose(): void;
}

const StyleSettingsModal: React.FC<IProps> = ({ type, onClose }: IProps) => {
  return (
    <Modal
      data-testid="style-settings-modal"
      open
      headerIcon={<Colorize />}
      header="Style settings"
      content={<StyleSettings type={type} />}
      actions={
        <Button data-testid="close-button" color="primary" type="submit" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default StyleSettingsModal;
