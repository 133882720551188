import { Box, IconButton, styled, TextField, Typography } from "@mui/material";

import { SelectField } from "fond/form/fields";

export const SelectionHeader = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));
SelectionHeader.defaultProps = { variant: "h2" };

export const SelectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.biarri.secondary.whiteSmoke,
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  padding: `${theme.spacing(1.75)} ${theme.spacing(3)}`,
}));

export const PlannerSelectField = styled(SelectField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const LicenseTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    padding: `0 ${theme.spacing(1)}`,
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    paddingLeft: 0,
    // We don't want the spinners that appear by default for number type text field.
    "-moz-appearance": "textfield",
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.biarri.secondary.activeBlue,
  borderRadius: 4,
}));
