import React from "react";
import { Category, Dashboard, DoNotDisturbOn } from "@mui/icons-material";
import { SelectChangeEvent } from "@mui/material";

import { FilterConfiguration } from "fond/types/ProjectLayerConfig";

import { MenuItemWithIcon, SelectWithIcon } from "./sublayerFilterField.styles";

interface IProps {
  value: string;
  onChange: (value?: string | null) => void;
}

const GroupFilterSelect: React.FC<IProps> = ({ value, onChange }) => {
  const handleOnFilterMatchChange = (event: SelectChangeEvent<FilterConfiguration["Operator"]>) => {
    const { value: newValue } = event.target;
    // Assuming value.Type === "group"
    onChange(newValue);
  };

  return (
    <SelectWithIcon data-testid="group-filter-select" value={value} onChange={handleOnFilterMatchChange} size="small" margin="none" displayEmpty>
      <MenuItemWithIcon value="none">
        <DoNotDisturbOn />
        No filters match
      </MenuItemWithIcon>
      <MenuItemWithIcon value="all">
        <Dashboard />
        All filters match
      </MenuItemWithIcon>
      <MenuItemWithIcon value="any">
        <Category />
        Any filters match
      </MenuItemWithIcon>
    </SelectWithIcon>
  );
};

export default GroupFilterSelect;
