import { Box, Button, styled, Typography } from "@mui/material";

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled(Box)`
  position: relative;
  height: 56px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};

  & > svg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const CustomButton = styled(Button)`
  margin: ${({ theme }) => `${theme.spacing(3.75)} 0 ${theme.spacing(2.5)}`};
`;

export const ErrorMessage = styled(Typography)`
  font-size: 0.875rem;
  line-height: 21px;

  & > span {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
` as typeof Typography;

export const SubText = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.biarri.primary.grey};
` as typeof Typography;
