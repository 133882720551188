import React, { useState } from "react";
import { Edit } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";

import { useUpdateProjectMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { PermissionLevel, Project } from "fond/types";
import { Actions } from "fond/utils/permissions";
import { required } from "fond/utils/validation";
import { RenameModal } from "fond/widgets";

interface IFormData {
  /**
   * The Name of the current project
   */
  name: string;
}

interface IProps {
  project: Project;
  permissionLevel: PermissionLevel;
  handleClose: () => void;
}

const Rename: React.FC<IProps> = ({ project, permissionLevel, handleClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [renameModal, showRenameModal] = useState(false);
  const [updateProject] = useUpdateProjectMutation();

  const canRename = usePermissionCheck(Actions.PROJECT_RENAME, permissionLevel);

  const handleClick = () => {
    if (canRename) {
      showRenameModal(true);
      handleClose();
    }
  };

  /**
   * Callback function for handling the saving of project name
   */
  const handleSubmit = async (value: IFormData) => {
    if (value.name !== project?.ProjectName) {
      updateProject({ ID: project?.ID, ProjectName: value.name })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Project renamed.");
        })
        .catch(() => {
          enqueueSnackbar("Project rename failed. Please try again...");
        })
        .finally(() => {
          showRenameModal(false);
        });
    }
  };

  return (
    <>
      <MenuItem data-testid="rename-button" onClick={handleClick} disabled={!canRename}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        Rename
      </MenuItem>
      {renameModal && (
        <RenameModal
          data-testid="rename-rename-project-modal"
          title="Rename project"
          defaultValue={project?.ProjectName || "Untitled project"}
          onRename={(formData) => handleSubmit(formData)}
          onClose={() => {
            showRenameModal(false);
          }}
          onValidate={required}
        />
      )}
    </>
  );
};

export default Rename;
