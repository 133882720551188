import { EntityType, Permission, PermissionLevel } from "fond/types";

import { apiSlice } from "./apiSlice";

export type GetPermissionsResponse = {
  Items: Permission[];
};

/**
 * Permissions API Slice
 */
export const permissionSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query({
      query: ({ type, id }: { type: EntityType; id: string }) => `/v2/permissions?resource_type=${type}&resource_id=${id}&expanded=true`,
      transformResponse: (response: GetPermissionsResponse) => response?.Items || [],
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map((permission) => ({ type: "Permission" as const, id: permission.ID })),
              { type: "Permission", id: "LIST" },
              // A tag is provided for the EntityID so that if the entity changes they can invalidate
              { type: "Permission", id: arg.id },
            ]
          : [{ type: "Permission", id: "LIST" }],
    }),
    createPermission: build.mutation<Permission, any>({
      query: (permission) => ({
        url: "/v2/permissions",
        method: "POST",
        body: permission,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Permission", id: "LIST" }],
    }),
    updatePermission: build.mutation<Permission, { ID: string; Level: PermissionLevel }>({
      query: ({ ID, Level }) => ({
        url: `/v2/permissions/${ID}`,
        method: "PUT",
        body: { Level },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Permission", id: "LIST" }],
    }),
    deletePermission: build.mutation<undefined, string>({
      query: (ID) => ({
        url: `/v2/permissions/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Permission", id: "LIST" }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetPermissionsQuery, useUpdatePermissionMutation, useCreatePermissionMutation, useDeletePermissionMutation } = permissionSlice;

/**
 * Selectors
 */
