import { CognitoAction } from "fond/cognito/redux";

export const RedirectPathAction = {
  UPDATE_PATH: "UPDATE_PATH",
};

export function updateRedirectPath(path) {
  return {
    type: RedirectPathAction.UPDATE_PATH,
    redirectPath: path,
  };
}

export function reducer(state = "/", action) {
  switch (action.type) {
    case RedirectPathAction.UPDATE_PATH:
      return action.redirectPath;

    case CognitoAction.LOGGED_OUT:
      return "/";

    default:
      return state;
  }
}
