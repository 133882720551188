import React, { createRef, useEffect, useMemo } from "react";
import { Box, Divider, List, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EntityId } from "@reduxjs/toolkit";

import { useGetArchitecturesQuery } from "fond/api/architecturesSlice";
import { Architecture } from "fond/types";
import { BlockSpinner } from "fond/widgets";

import ArchitectureListItem from "./ArchitectureListItem";
import { selectArchitecture, useArchitectureEditorContext } from "./context";

const useCustomStyles = makeStyles<Theme, { isLoading: boolean }>((theme: Theme) => ({
  leftPaneScroll: {
    height: "100%",
    overflowY: ({ isLoading }) => (isLoading ? "hidden" : "scroll"),
    position: "relative",
  },
  leftPaneList: {
    padding: 0,
  },
  loadingOverlay: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
  },
}));

const ArchitectureList: React.FC = () => {
  /**
   * Desired behaviour:
   * 1. On loading the panel, make sure the initially-selected arch is centered.
   * 2. On creating a new item, make sure it is visible.
   * 3. Don't mess with the scroll position when the user changes the selection
   *    just by clicking an item.
   *
   * To differentiate between #2 and #3 we have the redux store keep track of
   * the 'last list operation'.
   *
   * We use a ref callback so we can always reference the DOM element containing
   * the currently-selected architecture *after* it's been rendered.
   */
  const [{ widgetArchitecture, selectedArchitectureID }, dispatch] = useArchitectureEditorContext();
  const { data: architectures, isLoading, isFetching } = useGetArchitecturesQuery(undefined);
  const classes = useCustomStyles({ isLoading: isFetching || isLoading });
  const containerRef = createRef<HTMLDivElement>();
  const selectedRef = createRef<HTMLDivElement>();

  // When a selection changes we need to determine if we need to
  // scroll the newly selected item into view.
  // For example when deleting an architecture we select the top of the list
  useEffect(() => {
    const container = containerRef.current;
    const selected = selectedRef.current;

    if (selected && container && selected.offsetTop < container.scrollTop - container.clientHeight) {
      selectedRef.current?.scrollIntoView();
    }
  }, [selectedRef.current, isFetching, isLoading]);

  // useMemo is primarily used to improve rendering performance related
  // to the user typing with the form fields.  useMemo prevents re-renders
  // of the list as the widgetArchitecture state changes.
  return useMemo(
    () => (
      <>
        {isFetching && (
          <Box className={classes.loadingOverlay} data-testid="architecture-list-loading-spinner">
            <BlockSpinner />
          </Box>
        )}
        <div className={classes.leftPaneScroll} data-testid="base-architectures" ref={containerRef}>
          {isLoading ? (
            <BlockSpinner />
          ) : (
            <List dense className={classes.leftPaneList}>
              {/* If widgetArchitecture exists without an ID it means a new architecture is being created */}
              {widgetArchitecture?.ID === null && <ArchitectureListItem arch={widgetArchitecture} ref={selectedRef} isSelected />}
              {architectures?.ids.map((id: EntityId, i: number) => {
                const arch = architectures?.entities[id] as Architecture;

                if (!arch) return null;

                return (
                  <React.Fragment key={arch.ID}>
                    <ArchitectureListItem
                      arch={arch}
                      ref={id === selectedArchitectureID ? selectedRef : null}
                      isSelected={id === selectedArchitectureID}
                      onClick={() => {
                        dispatch(selectArchitecture(arch.ID));
                      }}
                    />
                    {i < architectures.ids.length - 1 && <Divider component="div" />}
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </div>
      </>
    ),
    [selectedArchitectureID, architectures, isFetching, isLoading, widgetArchitecture?.ID]
  );
};

export default ArchitectureList;
