/* eslint-disable no-underscore-dangle */
import MapboxDraw, { DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

import { MapboxDrawExtended, Project } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";

import snapTo, { clearSnapStyling, withSnapping } from "./snap_to";

const {
  lib: {
    CommonSelectors: { isFeature },
  },
  modes,
} = MapboxDraw;

interface DrawCustomModeState {}
interface DrawCustomModeOptions {}
interface DrawCustomModeExtension {
  onMouseDown(this: DrawCustomModeThis & this, state: DrawCustomModeState, e: MapboxDraw.MapMouseEvent): void;
  enter(
    draw: MapboxDrawExtended,
    opts: {
      event: MapboxDraw.MapMouseEvent;
      feature: Feature;
      layerConfigs: Array<LayerConfig | SublayerConfig>;
      layerId: string;
      project: Project;
      styleId: string;
    }
  ): void;
}

/**
 * Experimental: by default, Mapbox Draw doesn't let you click + drag a feature
 * around with one click; you have to click it, let go, and then drag it, which
 * is a little confusing. This custom mode removes the need for the extra click.
 */
const SimpleSelect = withSnapping<DrawCustomModeState, DrawCustomModeOptions, DrawCustomModeExtension>({
  ...modes.simple_select,

  /**
   * Custom event handler that is fired by the FeatureEditor on mousedown
   */
  enter: function (mapboxDraw, opts) {
    mapboxDraw.ctx.map.setFeatureState({ source: opts.layerId, id: opts.feature.id }, { isEditing: true });
    mapboxDraw.add({
      type: "FeatureCollection",
      features: [opts.feature],
    });
    mapboxDraw.changeMode("simple_select", {
      layerId: opts.layerId,
      featureId: opts.feature.id,
      project: opts.project,
      layerConfigs: opts.layerConfigs,
    });

    // Now we've set up the mode, start the drag as if we'd started it when the
    // mode was already active (otherwise the user would have to click again).
    // We can't do this inside `onSetup` because it needs to be done after the
    // render, which Mapbox Draw executes only after onSetup is finished.
    mapboxDraw.ctx.events.getCurrentMode().mousedown(opts.event);
  },

  onMouseDown: function (state, event): void {
    if (event.featureTarget?.state?.isEditing) {
      // If the featureTarget is being edited & the user clicks on
      // the hidden original feature we ignore the event.
      return;
    }
    if (event.featureTarget != null && !(event.featureTarget.id in this._ctx.store._features)) {
      // If we click a non-editing feature while in simple_select mode,
      // `onMouseDown` will be triggered before `enter`. But it's `enter` that
      // we want to actually do the work; we're not ready to work with the
      // event here.
      return;
    }

    if (isFeature(event)) {
      const featureLookupItem = this._ctx.api.getFeatureById(event.featureTarget.id);
      // We need to confirm that a feature was found prior to attempting to display it
      if (featureLookupItem) {
        this._ctx.api.pullInFeature(featureLookupItem.layerId, featureLookupItem.feature);
      } else {
        console.error(`Feature could not be found: ${event.featureTarget.id}`);
        return;
      }
      this.clickOnFeature?.(state, event);
      this.startOnActiveFeature?.(state, event);
    }
  },

  onTrash: function (state): void {
    const selected = this.getSelected()[0];
    if (selected != null) {
      this._ctx.api.markFeatureDeleted(selected.id);
    }
  },

  snap: function (this: DrawCustomModeThis, state, e) {
    // In simple_select we don't actually snap features to other features, but
    // we do use the snapping functionality to highlight features on mouseover.
    // That's why we only call `snapTo` only when we are *not* dragging.
    if (e.originalEvent.buttons === 0) {
      return snapTo(e, this._ctx);
    } else {
      clearSnapStyling(this._ctx);
      return e;
    }
  },
});

export default SimpleSelect;
