type SubClause = {
  index: string;
  text: string[];
};

type Clause = SubClause & { subclauses?: SubClause[] };

/**
 * The type of the terms of service
 */
type TermsofService = {
  title: string;
  introduction: string[];
  sections: {
    subtitle: string;
    text?: string[];
    clauses?: Clause[];
  }[];
};

/**
 * The content of the May 2023 Terms of Service.
 * A placeholder for now, need to update this after the new terms of service is available.
 */
export const May2023Terms: TermsofService = {
  title: "BIARRI NETWORKS TERMS OF SERVICE",
  introduction: [
    "PLEASE CAREFULLY READ THE TERMS AND CONDITIONS OF THESE TERMS OF SERVICE. BY USING THIS SERVICE, YOU (“you”) UNDERSTAND AND AGREE THAT YOU ARE BINDING YOURSELF TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT AND THAT THIS AGREEMENT WILL BE A LEGAL AND BINDING CONTRACT BETWEEN YOU AND BIARRI NETWORKS. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT PROCEED.",
    "The following terms and conditions constitute a legally binding contract (the “Agreement”) that governs the Biarri Networks’ Subscription Services (“Subscription Services”) provided by Biarri Networks (“Biarri Networks,” “we,” “our”) to you (“you” or “yours”).",
    "The Subscription Services are offered subject to your acceptance, without modification, of all of the terms and conditions contained herein. YOUR USE OF THE SUBSCRIPTION SERVICES CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO BE BOUND BY THIS AGREEMENT AND OUR TERMS OF SERVICE. If you do not agree to this Agreement, do not use the Subscription Services and/or our website www.biarrinetworks.com (the “Site”).",
    "If you use the Subscription Services only for your personal use or on a trial basis, you are considered a “User.” If you pay for the Subscription Services or use the Subscription Services to execute orders or deliver products to third parties, you are considered a “Subscriber.”",
  ],
  sections: [
    {
      subtitle: "1. Use of Subscription Services",
      text: [
        "Biarri Networks grants you a limited, non-exclusive, non-transferable, and revocable license to use the Subscription Services, platforms, interfaces, and reports subject to this Agreement.",
      ],
    },
    {
      subtitle: "2. Prohibited Uses of Subscription Services",
      text: [
        "You acknowledge and agree that you will not, nor will you attempt to or allow any other person to do any of the following restricted activities listed below. If you do, or if we suspect that you have, then we reserve the right to suspend or terminate your use of the Subscription Services. You will not:",
        "(a) use the Subscription Services for any illegal purpose, in furtherance of illegal activity, or in any manner that does not comply with all applicable laws, regulations, rules, ordinances, and other legal requirements of all applicable jurisdictions;",
        "(b) use the Subscription Services in any manner that is, or upload any content that is, unlawful, harmful, threatening, abusing, harassing, stalking, threatens violence, defamatory, vulgar, obscene, or infringes, misappropriates, or otherwise violates another’s intellectual property, proprietary, or privacy rights;",
        "(c) upload any data or content that is not necessary to provide the Subscription Services including, but not limited to, personal identifiable information (PII);",
        "(d) make available any data that is unlawful, harassing, threatening, harmful, defamatory, libelous, abusive, violent, obscene, vulgar, hateful, or racially or ethnically offensive;",
        "(e) post or transmit unsolicited messages, junk mail, spam, chain letters, or material that might reasonably be considered offensive;",
        "(f) use the Subscription Services to impersonate others in a manner that does or is intended to mislead, confuse, or deceive others; ",
        "(g) use the Subscription Services to disseminate any viruses, worms, malicious code, or other program designed to interrupt, damage, or limit any software, hardware, and/or data;",
        "(h) remove, circumvent, disable, damage or otherwise interfere with security features of the Subscription Services, including any technical measures we may use to prevent or restrict unauthorized access to the Subscription Services, features that prevent or restrict use or copying of any content accessible through the Subscription Services, or features that enforce limitations on use of the Subscription Services;",
        "(i) intentionally interfere with or damage operation of the Subscription Services, including by selling, transferring, or allowing another person to access your account password, to use your account, or to use the Subscription Services via your account;",
        "(j) modify, reproduce, copy, adapt, alter, improve, translate, or create derivative works from the Subscription Services;",
        "(k) reverse engineer, adapt, modify, create derivative works of, make additional copies of, separate, develop, decompile, disassemble, or otherwise attempt to derive the source code for the Subscription Services;",
        "(l) merge the Subscription Services with other software; ",
        "(m) sublicense, lease, rent, loan, transfer, or otherwise make available the Subscription Services to any third party; ",
        "(n) use the Subscription Services to develop a product that is similar to the Subscription Services;",
        "(o) use the Subscription Services to compete with or assist a competitor of Biarri Networks, its affiliates, its partners, or its parent corporate entity, in developing or offering competing services including, but not limited to, the Subscription Services;",
        "(p) commercialize your use of the Subscription Services, with the exception of output generated through the use of the Subscription Services; or",
        "(q) remove, alter, or obscure any trademark, trade name, logo, slogan, branding, symbol, label, or copyright or other proprietary notice on any portion of the Subscription Services.",
        "Biarri Networks reserves the right to revoke your license if Biarri Networks determines, in its sole discretion and although not listed above, that your activity is causing interference with the Subscription Services or an actual harm or risk of harm to the Subscription Services or other parties, or that the license should be revoked for any other reason.",
      ],
    },
    {
      subtitle: "3. Fees for Use of Subscription Services",
      clauses: [
        {
          index: "3.1",
          text: [
            "Subscriber Fees and Rights. Subscription Services are billed in advance on either a quarterly or annual basis. The fees are non-refundable and no refunds or credits will be granted for partial months of service, downgrade refunds, or refunds for months unused with an open account.",
          ],
        },
        {
          index: "3.2",
          text: [
            "User Accounts; Free Trials. If a User receives free access or a trial or evaluation subscription to the Subscription Services (a “Trial Subscription”), then User may use the Solutions in accordance with the terms and conditions of this Agreement for up to 250 premises and for no longer than a period of seven (7) days (“Trial Period”), or as otherwise agreed upon in writing by Biarri Networks. Trial Subscriptions are permitted solely for Client’s use to determine whether to purchase a full subscription to the Subscription Services. User may not use a Trial Subscription for any other purpose, including for competitive analysis. At the end of the Trial Period, the Trial Subscription will expire and User will have the option to purchase a full subscription to the Subscription Services. If User purchases a full subscription, all of the terms and conditions in this Agreement will apply to such purchase and the use of the Subscription Services. Biarri Networks reserves the right to terminate a Trial Subscription at any time for any reason. The Subscription Services may have a mechanism that limits access to such Subscription Services during the Trial Period and Biarri Networks may otherwise restrict certain product functionality during the Trial Period. User shall not attempt to circumvent any such mechanism or restriction. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, DURING THE TRIAL PERIOD THE SUBSCRIPTION SERVICES ARE PROVIDED “AS IS” AND BIARRI NETWORKS WILL HAVE NO WARRANTY OR OTHER OBLIGATIONS WITH RESPECT TO TRIAL SUBSCRIPTIONS UNLESS OTHERWISE SPECIFIED.",
          ],
          subclauses: [
            {
              index: "3.2.1",
              text: [
                "Support. Biarri Networks will attempt to respond to email support questions as soon as reasonably practical. However, User acknowledges and agrees that Biarri Networks shall have no obligation to provide technical support for Trial Subscriptions. Free online support materials are available at: https://fondhelp.biarrinetworks.com.",
              ],
            },
          ],
        },
      ],
    },
    {
      subtitle: "4. Taxes",
      text: [
        "All fees specified on an order exclude any foreign or domestic governmental taxes or charges of any kind that may be applicable to this Agreement. You will (i) pay Biarri Networks applicable taxes (excluding Biarri Networks’ income taxes) listed on a relevant invoice and (ii) withhold all applicable local taxes, which may be in addition to the total fees due on the order.",
      ],
    },
    {
      subtitle: "5. User Data",
      text: [
        "The Subscription Services may allow the submission of content and/or materials by you (“User Data”). When you submit User Data to Biarri Networks, you hereby grant to Biarri Networks a non-exclusive, royalty-free, worldwide license to use your User Data through the Subscription Service. You shall be solely responsible for your own User Data and the consequences of transmitting that User Data. Biarri Networks assumes no responsibility whatsoever in connection with or arising from User Data. Biarri Networks does not endorse and has no control over the content of User Data submitted by other users of the Subscription Services. Biarri Networks reserves the right to prevent you from submitting User Data and to restrict or remove User Data for any reason at any time.",
      ],
    },
    {
      subtitle: "6. Data Protection and Privacy",
      clauses: [
        {
          index: "6.1",
          text: [
            "Data Protection. Biarri Networks agrees to use commercially reasonable technical and organizational measures designed to prevent unauthorized access, use, alteration, or disclosure of the User Data. Biarri Networks shall have no responsibility for errors in transmission, unauthorized third-party access, or other causes beyond Biarri Networks’ control. Further, notwithstanding anything to the contrary herein, Biarri Networks is not responsible for any data provided as an output by the Subscription Services after such data is transmitted, copied, extracted, or removed from Biarri Networks’ servers.",
          ],
        },
        {
          index: "6.2",
          text: [
            "Protection of Personal Data. You may disclose personal information of its employees, directors, officers, customers or suppliers, and/or other users to Biarri Networks in performance of this Agreement and/or through use of the Subscription Services. Biarri Networks agrees to process personal data only for the purpose of performing this Agreement and in accordance with Your instructions and applicable laws.",
            "The terms “controller,” “process,” “processor,” and “personal data” have the meanings given to them in the European Union (EU) General Data Protection Regulation (GDPR). The parties acknowledge that, pursuant to the EU GDPR and applicable data protection Laws, You are the controller and data exporter and Biarri Networks is the processor and data importer.",
            "To the extent permitted by Laws, Biarri Networks will forward You all relevant data subject access requests and any other requests from data subjects.",
          ],
        },
      ],
    },
    {
      subtitle: "7. Privacy Policy",
      text: [
        "Biarri Networks’ Privacy Policy, available at https://biarrinetworks.com/-biarri-privacy-policy-, is hereby incorporated into this Agreement in its entirety. Please read this Privacy Policy carefully for disclosures relating to the collection, use, and disclosure of your personal information, User Data, and any other information you disclose to Biarri Networks. By using the Subscription Services, you consent to Biarri Networks’ collection and use of your personal information and User Data as described in the current version of the Privacy Policy in place at the time when that data was transmitted.",
      ],
    },
    {
      subtitle: "8. Reservation of Rights",
      text: [
        "The Subscription Services and all content, visual interfaces, information, graphics, design, compilation, computer code, products, software, trademarks, service marks, trade names, and services included in the Subscription Services or accessible through the Subscription Services are the property of Biarri Networks or its subsidiaries or affiliated companies and/or third-party licensors. Except for the express license granted to you above, no right, title, interest or license to the Subscription Services is granted to you, whether by implication or otherwise. You do not have any right to use the names, trademarks, logos, or brands of Biarri Networks or its subsidiaries or affiliated companies, whether in part, in modified form, or otherwise.",
        "IF YOU ARE USING THE SUBSCRIPTION SERVICE AS A USER OR AS PART OF A BETA, PREVIEW, TRIAL, OR EVALUATION, YOU WILL NOT DISTRIBUTE ANY SCREEN SHOTS, PICTURES, OR OTHER REPRESENTATIONS OF THE SUBSCRIPTION SERVICES OR ANY OF ITS FEATURES OUTSIDE OF THE ENTERPRISE FOR WHICH YOU ARE PARTICIPATING IN THE BETA, PREVIEW, TRIAL, OR EVALUATION.",
      ],
    },
    {
      subtitle: "9. Modifications",
      text: [
        "Biarri Networks reserves the right to modify this Agreement from time to time. If this Agreement is modified, altered, or changed, you will be asked to agree to any such modifications, alterations, and/or changes by clicking “I accept” (or other similar terminology indicating your acceptance) to those modified terms. Alternatively, by continuing to use the Subscription Services, you agree to the modified, altered, and/or changed terms. If you do not agree to those modified, altered, and/or changed terms, you must immediately cease using the Subscription Services.",
      ],
    },
    {
      subtitle: "10. Government End Users",
      text: [
        "If the Subscription Service is being acquired or used by any governmental entity, including the United States Government, then the following provision applies: The Subscription Service is being provided as commercial software only and such governmental entity may only use, distribute, and duplicate such software in accordance with and subject to the provisions of DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14 (ALT III), with respect to the United States Government, or, as applicable, any similar state or local statutes pertaining to the acquisition of goods and services. Title to the Subscription Services, including all features and functionality thereof, shall at all times remain with Biarri Networks regardless of the use of any federal, state, or other governmental funds in its development.",
      ],
    },
    {
      subtitle: "11. Export Control",
      text: [
        "You shall not export or re-export the Subscription Services, or any reports or information generated via the use of the Subscription Services, to those countries, persons, or entities prohibited from receiving exports from the United States. You agree to comply with all United States and foreign laws related to use of the Subscription Services, including the export and importation of the Subscription Services. By using the Subscription Services and entering into this Agreement, you represent and warrant that: (a) you are not located in a country that is subject to a United States Government embargo, or that has been designated by the United States Government as a “terrorist supporting” country; and (b) you are not listed on any United States Government list of prohibited or restricted parties.",
      ],
    },
    {
      subtitle: "12. 	Violations; Termination",
      clauses: [
        {
          index: "12.1",
          text: [
            "You agree that Biarri Networks may terminate or suspend your account or your access to the Subscription Services without prior notice and without liability. Your right to use the Subscription Services and this Agreement will terminate immediately in the event that you are in breach of any provision of this Agreement. You may terminate this Agreement at any time by, for example, deactivating your account. Upon termination of this Agreement, you will immediately stop using the Subscription Services. ",
            "Reasons Biarri Networks may terminate or suspend your account or your access to the Subscription Services include, but are not limited to: ",
            "(a) Biarri Networks’ good faith belief that you have violated one or more terms or conditions of this Agreement;",
            "(b) Biarri Networks’ determination that you have infringed the copyright, or any other intellectual property right, privacy right, or other proprietary right, of a third party; and/or",
            "(c) extended periods of inactivity.",
            "These remedies are in addition to any other remedies Biarri Networks may have at law or in equity.",
          ],
        },
        {
          index: "12.2",
          text: [
            "Biarri Networks will retain User Data (defined in Section 5, above) only as long as is necessary to provide the Subscription Services, or as required to comply with legal and/or regulatory obligations. Biarri Networks will remove User Data from its systems when it is no longer needed. In no event shall Biarri Networks store User Data for more than twelve (12) months after termination of the Subscription Services. ",
          ],
        },
      ],
    },
    {
      subtitle: "13. Consumer Rights",
      text: [
        "You may have the benefit of consumer guarantees and warranties given under statute (“Mandatory Terms”). Where any statute applies to this Agreement to give you the benefit of Mandatory Terms, and that statute does not permit Biarri Networks to exclude or limit the application of those Mandatory Terms (or would render void any attempt to do so), then those Mandatory Terms apply to this Agreement for your benefit, and nothing in this Agreement excludes or limits those Mandatory Terms or liability for breach of them. This applies to all of the terms of this Agreement, including any terms in relation to those sections entitled “DISCLAIMERS; NO WARRANTIES”; “INDEMNIFICATION; HOLD HARMLESS”; AND “LIMITATION OF LIABILITY AND DAMAGES.”",
      ],
    },
    {
      subtitle: "14. Disclaimers; No Warranties",
      text: [
        "THE SUBSCRIPTION SERVICES AND ANY THIRD-PARTY SOFTWARE, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONNECTION WITH THE SUBSCRIPTION SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BIARRI NETWORKS, AND ITS SUPPLIERS AND PARTNERS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. BIARRI NETWORKS AND ITS SUPPLIERS AND PARTNERS DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SUBSCRIPTION SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SUBSCRIPTION SERVICES ARE FREE OF HARMFUL COMPONENTS OR THAT THE SUBSCRIPTION SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SUBSCRIPTION SERVICES IS AT YOUR OWN RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR EQUIPMENT OR DEVICE, LOSS OF USE, OR LOSS OF DATA.",
      ],
    },
    {
      subtitle: "15. Indemnification; Hold Harmless",
      text: [
        "You agree to indemnify, defend, and hold harmless Biarri Networks, its affiliated companies, and its suppliers and partners from any and all third party claims, suits, actions, liabilities, losses, costs, damages, and any other expenses, including attorneys’ fees, arising out of or related to (a) your use or misuse of the Subscription Services, (b) any violation of the rights of any other person or entity by you, or (c) any breach or violation by you of this Agreement. Biarri Networks reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Biarri Networks, and you agree to cooperate with the defense of these claims by Biarri Networks.",
      ],
    },
    {
      subtitle: "16. Limitation of Liability and Damages",
      text: [
        "TO THE EXTENT PERMITTED BY APPLICABLE LAW, BIARRI NETWORKS AND ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, AND THIRD-PARTY PARTNERS AND SUPPLIERS SHALL NOT BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, RELIANCE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES RELATING TO YOUR USE OF THE SUBSCRIPTION SERVICES, EVEN IF BIARRI NETWORKS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY APPLICABLE LAW, BIARRI NETWORK’S AGGREGATE LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT OR YOUR USE OF THE SUBSCRIPTION SERVICES, WHETHER IN CONTRACT OR TORT OR OTHERWISE, AND YOUR SOLE AND EXCLUSIVE REMEDY HEREUNDER FOR ALL SUCH DAMAGES, SHALL BE LIMITED TO THE AMOUNT PAID BY YOU UNDER THIS AGREEMENT DURING AN ACTIVE SUBSCRIPTION PERIOD. THE EXISTENCE OF MULTIPLES CLAIMS OR SUITS UNDER OR RELATED TO THIS AGREEMENT OR USE OF THE SERVICE WILL NOT ENLARGE OR EXTEND THIS LIMITATION OF LIABILITY.",
      ],
    },
    {
      subtitle: "17. Copyright",
      text: [
        "If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide the following information to Biarri Networks at the address indicated below: ",
        "(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;",
        "(b) a description of the copyrighted work or other intellectual property that you claim has been infringed;",
        "(c) a description of where the material that you claim is infringing is located in the Subscription Service;",
        "(d) your address, telephone number, and email address;",
        "(e) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and",
        "(f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the copyright or intellectual property owner’s behalf.",
        "Notice of claims of copyright or other intellectual property infringement should be sent to the address specified at the bottom of this Agreement.",
      ],
    },
    {
      subtitle: "18. Miscellaneous",
      text: [
        "This Agreement constitutes the entire agreement between you and Biarri Networks concerning the subject matter hereof and supersedes all prior or contemporaneous oral or written understandings. ",
      ],
      clauses: [
        {
          index: "18.1",
          text: [
            "Governing Law; Jurisdiction; Arbitration. This Agreement will be governed by the laws of the State of New York, without regard to conflicts of law provisions, and you consent to the exclusive jurisdiction of the state and federal courts of New York, United States of America (USA). This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods. EITHER BIARRI NETWORKS OR YOU MAY DEMAND THAT ANY DISPUTE OR CLAIM BETWEEN BIARRI NETWORKS AND YOU ABOUT OR INVOLVING THE SUBSCRIPTION SERVICES MUST BE SETTLED BY ARBITRATION UTILIZING THE DISPUTE RESOLUTION PROCEDURES OF THE AMERICAN ARBITRATION ASSOCIATION (“AAA”) IN NEW YORK, USA, AND, IF SO DEMANDED BY BIARRI NETWORKS OR YOU, BOTH BIARRI NETWORKS AND YOU HEREBY ACKNOWLEDGE AND AGREE TO SUBMIT EXCLUSIVELY TO THE JURISDICTION OF THE AAA IN COLORADO, USA TO RESOLVE THE APPLICABLE DISPUTE OR CLAIM; provided that the foregoing shall not prevent Biarri Networks from seeking injunctive relief in any court of competent jurisdiction.",
          ],
        },
        {
          index: "18.2",
          text: [
            "No Third Party Beneficiaries. No provision of this Agreement is intended, or shall be construed, to confer upon, or give to, any person, or entity, any rights, remedies, or other benefits as a third party beneficiary.",
          ],
        },
        {
          index: "18.3",
          text: [
            "Support/Updates. Biarri Networks may maintain, support, or update the Subscription Services in its sole discretion, but Biarri Networks does not have any obligation under this Agreement to do so. If you have a question, complaint, or claim with respect to the Subscription Services, please contact Biarri Networks at info@biarrinetworks.com. ",
          ],
        },
        {
          index: "18.4",
          text: [
            "Severability. If any provision of this Agreement should be held invalid or unenforceable by a court of competent jurisdiction, such provision will be modified to the extent necessary to render it enforceable without losing its intent or will be severed from this Agreement if no such modification is possible, and all other provisions of this Agreement will remain in full force and effect. ",
          ],
        },
        {
          index: "18.5",
          text: [
            "Waiver. A waiver by either Biarri Networks or you of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.",
          ],
        },
        {
          index: "18.6",
          text: [
            "Assignment. This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned or transferred by Biarri Networks without restriction. ",
          ],
        },
        {
          index: "18.7",
          text: [
            "Survival. The provisions of this Agreement that require or contemplate performance after the termination of this Agreement and all provisions relating to limitation of liability, disclaimers, and indemnification will be enforceable notwithstanding such termination. ",
          ],
        },
        {
          index: "18.8",
          text: [
            "Force Majeure. Biarri Networks will not be in default or be liable to you for any delay, failure in performance, or interruption of the Subscription Services resulting directly or indirectly from any cause beyond its reasonable control. ",
          ],
        },
        {
          index: "18.9",
          text: [
            "Attorneys’ Fees and Costs. Each party shall bear its own costs in bringing any legal action against the other under this Agreement.",
          ],
        },
      ],
    },
  ],
};
