import React, { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AutocompleteGetTagProps, FilterOptionsState } from "@mui/material/useAutocomplete";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormApi } from "final-form";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectCurrentAccount, selectCurrentSubscription, useGetAccountUsersQuery, useInviteUsersBulkMutation } from "fond/api";
import { HTTPErrorResponse } from "fond/types";
import { isValidEmailFormat } from "fond/utils";
import { isEmailDeliverable } from "fond/utils/email";
import { Modal, SupportLink } from "fond/widgets";

type Option = {
  value: string | null;
  label: string;
  deliverable?: boolean;
  existing?: boolean;
};

interface IFormData {
  email: Array<Option>;
  allocateLicense: boolean;
}

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const InviteMembersModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const formRef: React.MutableRefObject<FormApi<IFormData, Partial<IFormData>> | null> = useRef(null);
  const [isValidating, setValidating] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [inviteMembers] = useInviteUsersBulkMutation();
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data: allocations } = useGetAccountUsersQuery(selectedAccount?.ID ?? skipToken);
  const currentSubscription = useSelector((state) => selectedAccount && selectCurrentSubscription(state, selectedAccount.ID));
  const flatUserList = allocations?.map((user) => user.User.Email) || [];

  const licenseCount = (currentSubscription && currentSubscription.LicenseCount) ?? 0;
  const totalAssignedLicenses = allocations?.filter((allocation) => allocation.License).length ?? 0;
  const remainingLicensesCount = licenseCount - totalAssignedLicenses;

  const onFormSubmit = async (values: IFormData) => {
    try {
      setSubmitting(true);
      const invitees = values.email.map((entry) => ({ Email: entry.value || "", License: values.allocateLicense }));
      const accountId = selectedAccount?.ID ?? "";
      await inviteMembers({ accountId, invitees }).unwrap();
      enqueueSnackbar("Invite sent.");
      onClose();
    } catch (error) {
      enqueueSnackbar((error as HTTPErrorResponse).message ?? "Something went wrong. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const filterOptions = (options: Option[], params: FilterOptionsState<Option>) => {
    const { inputValue } = params;
    if (!inputValue) return [];
    // set value = null to serve as flag that the current entry should be dismissed when onChange() is called
    if (!isValidEmailFormat(inputValue)) return [{ label: "Please enter a valid email address", value: null }];
    return [{ value: inputValue, label: `Add "${inputValue}"` }];
  };

  const renderTags = (selected: Option[], getTagProps: AutocompleteGetTagProps) =>
    selected.map((item, index) => {
      const { onDelete } = getTagProps({ index });
      const label = (() => {
        if (typeof item === "string") return item;
        return item.value;
      })();
      return (
        <Chip
          key={item.value}
          data-testid={`selected-${item.value}`}
          sx={{ margin: theme.spacing(0.4) }}
          variant="outlined"
          label={label}
          onDelete={onDelete}
          {...(item.existing || !item.deliverable ? { color: "error" } : {})}
        />
      );
    });

  const handleChange = async (values: readonly (string | Option)[], onChange: (values: Array<Option>) => void) => {
    // dismiss invalid emails on enter key press (typeof entry === "string") and when user click the invalid option
    const selected = values.filter(
      (entry) => (typeof entry === "string" && isValidEmailFormat(entry)) || (typeof entry !== "string" && entry.value !== null)
    );
    const updatedOptions = selected.map(async (entry) => {
      const option = typeof entry === "string" ? { label: entry, value: entry } : { ...entry };
      if (typeof option.existing === "undefined") {
        option.existing = option.value !== null ? flatUserList.includes(option.value) : false;
      }
      if (typeof option.deliverable === "undefined" && !option.existing) {
        setValidating(true);
        option.deliverable = (await isEmailDeliverable(option.value ?? "")).deliverable;
        setValidating(false);
      }
      return option;
    });

    Promise.all(updatedOptions).then((emailAddresses) => {
      onChange(emailAddresses);
    });
  };

  const validate = (email: Array<Option>) => {
    const allocateLicense = formRef.current?.getState().values.allocateLicense;
    if (!email || email.length === 0) return "This is required";
    if (email.length > remainingLicensesCount && allocateLicense) {
      return (
        <span>
          Your invitation list exceeds your license limit. Purchase additional licenses, or free up space by{" "}
          <Link to="/settings/users" target="_blank">
            managing
          </Link>{" "}
          existing ones.
        </span>
      );
    }
    const existingUser = email.find((entry) => entry.existing);
    if (existingUser) return `${existingUser.value} is already an existing user`;
    if (email.some((entry) => !entry.deliverable)) {
      return (
        <span data-testid="invalid-email-helper">
          An email address you entered is not deliverable. If you think this is wrong, please&nbsp;
          <SupportLink text="contact us" />
        </span>
      );
    }
    return undefined;
  };

  return (
    <Modal
      open={isOpen}
      header="Invite members"
      content={
        <Form<IFormData>
          onSubmit={onFormSubmit}
          render={({ handleSubmit, form, values }) => {
            formRef.current = form;
            return (
              <form id="invite-members-form" onSubmit={handleSubmit}>
                <Field
                  name="email"
                  validate={validate}
                  render={({ input, meta }) => {
                    const { onChange, name, value, ...inputRest } = input;
                    const hasError = meta.error && meta.touched;
                    return (
                      <FormControl fullWidth>
                        <FormLabel htmlFor="invitee-email-address" sx={{ mb: theme.spacing(1) }}>
                          Email address
                        </FormLabel>
                        <Autocomplete
                          id="invitee-email-address"
                          data-testid="invite-members-field"
                          size="small"
                          value={value || []}
                          onChange={(event, newValue) => handleChange(newValue, onChange)}
                          blurOnSelect
                          multiple
                          freeSolo
                          options={[]}
                          renderTags={renderTags}
                          filterOptions={filterOptions}
                          renderInput={(params) => (
                            <Box position="relative">
                              <TextField {...params} {...inputRest} size="small" placeholder="Enter an email to invite" />
                              {isValidating && (
                                <Box position="absolute" right="-24px" top="50%" sx={{ transform: "translateY(-50%)" }}>
                                  <CircularProgress size={20} />
                                </Box>
                              )}
                            </Box>
                          )}
                          disabled={isValidating}
                        />
                        <FormHelperText error={hasError}>{hasError && meta.error}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <Field
                  name="allocateLicense"
                  defaultValue={false}
                  render={({ input }) => {
                    const { name, value, ...inputRest } = input;
                    return (
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FormControl sx={{ my: 1 }}>
                          <FormControlLabel
                            label={<FormLabel>Allocate license</FormLabel>}
                            labelPlacement="start"
                            control={<Switch {...inputRest} checked={Boolean(value)} aria-checked={Boolean(value)} name={name} aria-label={name} />}
                            sx={{ ml: 0 }}
                          />
                        </FormControl>
                        <Typography variant="caption" color={theme.palette.biarri.secondary.grey}>
                          Available license: {remainingLicensesCount}
                        </Typography>
                      </Box>
                    );
                  }}
                />
              </form>
            );
          }}
        />
      }
      actions={
        <>
          <Button data-testid="invite-members-cancel-button" onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="invite-members-save-button" type="submit" form="invite-members-form" loading={isSubmitting}>
            Invite
          </LoadingButton>
        </>
      }
    />
  );
};

export default InviteMembersModal;
