import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { isEqual } from "lodash";

import { multiProjectsSlice, useGetMultiProjectStatisticsQuery } from "fond/api";
import { MultiProject } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { calculateScore } from "./helper";

/**
 * Hook that returns a MultiProjectArea collection that includes the client side
 * calculated ranking score.
 */
export const useUpdateSubareaScores = async (multiProject?: MultiProject): Promise<void> => {
  const dispatch = useAppDispatch();
  const { data } = useGetMultiProjectStatisticsQuery(multiProject?.ID ?? skipToken);

  useEffect(() => {
    if (multiProject?.ID && data) {
      const areas =
        multiProject?.Areas.map((area) => {
          let score = -1;
          if (data?.Areas) {
            score = calculateScore(multiProject.AssessConfiguration, area.ID, data.Areas);
          }

          return {
            ...area,
            Score: score,
          };
        }) || [];

      // If area scores have changed we update the local cache
      if (!isEqual(areas, multiProject.Areas)) {
        dispatch(
          multiProjectsSlice.util.updateQueryData("getMultiProject", multiProject.ID, (draft) => {
            draft.Areas = areas;
          })
        );
      }
    }
  }, [data, dispatch, multiProject]);
};
