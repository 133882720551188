import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { fondServiceURL } from "fond/api";
import { useFeaturesWithinDatasetMutation, useGetDatasetsQuery } from "fond/api/datasetSlice";
import { Dataset } from "fond/types";

interface UseDatasetReturns {
  /**
   * The dataset object, if it exists.
   */
  dataset?: Dataset;
  /**
   * The URL to fetch tiles from, if the dataset has them.
   */
  tileUrl?: string;
  /**
   * Whether the dataset is currently being loaded.
   */
  isLoading: boolean;
  /**
   * A function to fetch features within a given bounding box.
   */
  featuresWithin?: (boundingBox: Polygon | MultiPolygon) => Promise<FeatureCollection>;
}

interface UseDatasetProps {
  /**
   * A unique identifier for the dataset, e.g. "fcc-2024".
   */
  key: string;
  /**
   * The account ID this dataset is being requested for.
   * The user must be a member of this account.
   */
  accountId: string | null;
}

/**
 * Hook to interact with a given dataset based on its key.
 */
export const useDataset = ({ key, accountId }: UseDatasetProps): UseDatasetReturns => {
  const { data: datasets, isLoading } = useGetDatasetsQuery(accountId ?? skipToken);
  const dataset = useMemo(() => datasets?.find((ds) => ds.Key === key), [datasets, key]);
  const [fetchFeaturesWithin] = useFeaturesWithinDatasetMutation();

  const tileUrl = useMemo(() => {
    if (!accountId || !dataset || !dataset.TileCollection) {
      return undefined;
    }

    return `${fondServiceURL}/v2/datasets/${dataset.ID}/tiles/{z}/{x}/{y}?account_id=${accountId}`;
  }, [accountId, dataset]);

  const featuresWithin = useMemo(() => {
    if (!dataset || !accountId) {
      return undefined;
    }

    return (boundingBox: Polygon | MultiPolygon) => fetchFeaturesWithin({ datasetId: dataset.ID, boundingBox, accountId }).unwrap();
  }, [dataset, accountId, fetchFeaturesWithin]);

  return { dataset, tileUrl, isLoading, featuresWithin };
};
