import React from "react";
import { AddComment, AddLocation, LinearScale, TrendingFlat } from "@mui/icons-material";
import { Fade, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";

import { CommentType } from "fond/types";
import { SvgIcon } from "fond/widgets";

interface IProps {
  /**
   * The anchor element the menu should be relatively positioned
   */
  anchorEl: HTMLButtonElement | null;
  onClick(mode: "draw_point" | "draw_polygon" | "no_feature" | "draw_line_string", type: CommentType): void;
  onClose(): void;
}

const AddMenu: React.FC<IProps> = ({ anchorEl, onClick, onClose }: IProps) => {
  const handleOnClose = (mode: "draw_point" | "draw_polygon" | "no_feature" | "draw_line_string", type: CommentType) => {
    onClick(mode, type);
    onClose();
  };

  return (
    <Menu
      open
      anchorEl={anchorEl}
      elevation={2}
      onClose={onClose}
      TransitionComponent={Fade}
      data-testid="add-comment-menu"
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      transformOrigin={{ horizontal: "right", vertical: -50 }}
    >
      <MenuItem
        aria-label="add project comment"
        data-testid="add-project-comment"
        onClick={() => {
          handleOnClose("no_feature", "project");
        }}
      >
        <ListItemIcon>
          <AddComment fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Add comment</Typography>
      </MenuItem>

      <MenuItem
        aria-label="add point comment"
        data-testid="add-point-comment"
        onClick={() => {
          handleOnClose("draw_point", "point");
        }}
      >
        <ListItemIcon>
          <AddLocation fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Pin a location</Typography>
      </MenuItem>

      <MenuItem
        aria-label="add polygon comment"
        data-testid="add-polygon-comment"
        onClick={() => {
          handleOnClose("draw_polygon", "polygon");
        }}
      >
        <ListItemIcon>
          <SvgIcon icon="polygon" />
        </ListItemIcon>
        <Typography variant="inherit">Highlight an area</Typography>
      </MenuItem>

      <MenuItem
        aria-label="add line string comment"
        data-testid="add-line-string-comment"
        onClick={() => {
          handleOnClose("draw_line_string", "lineString");
        }}
      >
        <ListItemIcon>
          <LinearScale fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Draw a line</Typography>
      </MenuItem>

      <MenuItem
        aria-label="add arrow line comment"
        data-testid="add-arrow-line-comment"
        onClick={() => {
          handleOnClose("draw_line_string", "arrowLine");
        }}
      >
        <ListItemIcon>
          <TrendingFlat fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Draw an arrow</Typography>
      </MenuItem>
    </Menu>
  );
};

export default AddMenu;
